





































































































import { Component, Watch } from 'nuxt-property-decorator'
import LineUpBase from '~/components/experience-booking/experience-activity/line-up/base/index'
import LineUpStatus from '~/components/experience-booking/experience-activity/line-up/components/line-up-status.vue'
import LineUpBookTips from '~/components/experience-booking/experience-activity/line-up/components/line-up-book-tips.vue'

@Component({
  components: {
    LineUpStatus,
    LineUpBookTips
  }
})
export default class LineUpBottomBar extends LineUpBase {
  @Watch('status')
  statusChange(val: any, oVal: any) {
    if (val === 2 && val !== oVal) {
      this.showModal = true
    }
  }

  @Watch('soldOut')
  soldOutChange(val: any, oVal: any) {
    if (val && val !== oVal) {
      this.showModal = true
    }
  }

  get soldOut() {
    return this.lineUpInfo.sold_out
  }

  get status() {
    return this.lineUpInfo.flash_sale_status.user_status
  }

  showModal: boolean = false
  showDetail() {
    this.showModal = true
  }

  freshLoading: boolean = false

  fresh() {
    clearTimeout(this.timer)
    this.timer = setTimeout(async () => {
      this.freshLoading = true
      await this.getLineUpInfo()
      setTimeout(() => {
        this.freshLoading = false
      }, 1200)
    }, 350)
  }
}
