


















import { Component } from 'nuxt-property-decorator'
import throttle from 'lodash/throttle'
import ActivityNavBase from '~/components/experience-booking/experience-activity/activity-nav/activity-nav-base'

@Component
export default class ActivityNav extends ActivityNavBase {
  throttleScroll = throttle(() => {
    this.initScroll()
    this.setNavTopDistances()
  }, 150)

  setNavTopDistances() {
    const header = document.querySelector('.layout-default_header') as HTMLElement
    const h = header?.offsetHeight || 60
    this.style = {
      top: h + 'px'
    }
  }

  handleTabClick(name: string) {
    const item = this.navList.find((item: any) => item.name === name)
    this.autoScroll = false
    item && this.debounceClickNav(item)
  }

  getSpm(item: any) {
    return `TopLocateTo_LIST?ext=${this.$getExt({ Type: item.title })}&trg=manual`
  }

  beforeDestroy() {
    window.document.removeEventListener('scroll', this.throttleScroll)
  }

  mounted() {
    window.document.addEventListener('scroll', this.throttleScroll)
  }
}

