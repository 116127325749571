import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import { Component, Watch } from 'nuxt-property-decorator'
import { currencySymbolMap } from '~/share/data/currency'
import { formatThousands } from '~/share/utils'
import { languageDayjsLocaleMap } from '~/share/data/datetime'
import PromotionBase from '~/components/experience-booking/experience-activity/promotion/promotion-base'

@Component
export default class PromotionBannerBase extends PromotionBase {
  endDays: number = 0
  countdownObj: any = {}
  warmUpObj: any = {}
  otherPlatformObj: any = {}
  interval: any = null

  get promotionEvent() {
    return this.promotionDetail.promotion_event || {}
  }

  get tempPlatform() {
    return this.klook.platform === 'mobile' ? 'mweb' : 'web'
  }

  get tagName() {
    return this.promotionEvent.tag || ''
  }

  get isInTempPlatform() {
    const eventPlatforms = this.promotionEvent.platforms || []
    return eventPlatforms.includes(this.tempPlatform)
  }

  get isInApp() {
    const eventPlatforms = this.promotionEvent.platforms || []
    return eventPlatforms.includes('app')
  }

  get isOpen() {
    return this.promotionEvent.status === 5
  }

  get showAppDownload() {
    return this.isOpen && !this.isInTempPlatform && this.isInApp
  }

  get showDays() {
    return this.endDays >= 3
  }

  get imageUrl() {
    return this.promotionEvent.image_url || ''
  }

  get dayjsLocale() {
    const language = this.klook.language
    return languageDayjsLocaleMap[language] || language
  }

  @Watch('promotionDetail', { immediate: true })
  handlePromotionDetailChange() {
    if (isEmpty(this.promotionEvent)) {
      return
    }

    // 预热中
    if (this.isWarmUp) {
      this.warmUpObj = this.getWarmUpObj()
      return
    }

    // 促销中(本平台没有)
    if (this.isOpen && !this.isInTempPlatform) {
      this.otherPlatformObj = this.getOtherPlatformObj()
      return
    }

    // 促销中(本平台有)
    if (this.isOpen && this.isInTempPlatform) {
      const now = dayjs()
      const end = dayjs(this.promotionEvent.end_time)
      this.endDays = end.diff(now, 'day') // 会自动 floor

      // 展示倒计时
      if (!this.showDays) {
        this.countdownObj = this.getCountdownObj()

        if (!this.$isServer) {
          this.interval && clearImmediate(this.interval)
          this.interval = setInterval(() => {
            this.countdownObj = this.getCountdownObj()
          }, 1000)
        }
      }
    }
  }

  // 不足2位则补0，小于等于0则显示00
  formatTwoDigits(n: number) {
    if (n <= 0) {
      return '00'
    }

    if (n >= 100) {
      return String(n)
    }

    return `00${n}`.substr(-2)
  }

  getCountdownObj() {
    const now = dayjs()
    const end = dayjs(this.promotionEvent.end_time)
    const hour = end.diff(now, 'hour')
    const minute = end.diff(now, 'minute') % 60
    const second = end.diff(now, 'second') % 60
    const formatedHour = this.formatTwoDigits(hour)
    const formatedMinute = this.formatTwoDigits(minute)
    const formatedSecond = this.formatTwoDigits(second)

    return {
      time: `${formatedHour}:${formatedMinute}:${formatedSecond}`
    }
  }

  getWarmUpObj() {
    const { selling_price, promotion_event } = this.promotionDetail
    const start = dayjs(promotion_event.start_time)
    const end = dayjs(promotion_event.end_time)

    return {
      currency: currencySymbolMap[this.klook.currency],
      amount: formatThousands(selling_price),
      mmm: start.locale(this.dayjsLocale).format('MMM'),
      d: start.locale(this.dayjsLocale).format('D'),
      hhmm: start.format('HH:mm'),
      mmm1: end.locale(this.dayjsLocale).format('MMM'),
      d1: end.locale(this.dayjsLocale).format('D'),
      hhmm1: end.format('HH:mm')
    }
  }

  getOtherPlatformObj() {
    const { selling_price } = this.promotionDetail
    const end = dayjs(this.promotionEvent.end_time)
    const platformMap: Record<string, string> = {
      web: this.$t('16572'),
      mweb: this.$t('16571'),
      app: this.$t('16570')
    }

    return {
      currency: currencySymbolMap[this.klook.currency],
      amount: formatThousands(selling_price),
      platform: (this.promotionEvent.platforms || []).map((p: string) => platformMap[p]).join('&'),
      mmm: end.locale(this.dayjsLocale).format('MMM'),
      d: end.locale(this.dayjsLocale).format('D'),
      hhmm: end.format('HH:mm')
    }
  }

  wrapCountdown(time: string) {
    // 给倒计时加上html
    // <span>Ends in </span><span class="time-deadline">{{ countdownObj.time }}</span>
    const str = this.$t('16558', { time })
    const text = str.replace(time, '')
    return str
      .replace(text, `<span>${text}</span>`)
      .replace(time, `<span class="time-deadline">${time}</span>`)
  }

  beforeDestroy() {
    this.interval && clearImmediate(this.interval)
  }
}
