

























import { Vue, Component, Getter } from 'nuxt-property-decorator'

@Component
export default class CreditLandingEntrance extends Vue {
  @Getter useCredits!: boolean

  static displayName = 'MobileCredits_rebate_landing_entrance'

  get link() {
    return this.$href('/credits/landing')
  }
}
