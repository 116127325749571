









































import { Component, Prop, State, namespace } from 'nuxt-property-decorator'
import PublishInfoBase from '~/components/common/publish-info/publish-info-base'
import PackageCardUnit from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/package-card-list/package-card-unit.vue'
import { Activity } from '~/types/traveller/activity'
import { currencyExchange } from '~/share/utils'
import { getTaggingExtraTrackObj } from '~/components/experience/tagging/index.vue'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    PackageCardUnit
  }
})
export default class PackageCardContent extends PublishInfoBase {
  @State((state) => state.klook.currency) currency!: Data.Platform
  @Prop() packageId!: number
  @Prop() packageTicketType!: number
  @Prop({ default: () => [] }) units!: Activity.unit[]
  @Prop({ default: () => ({}) }) ext!: any
  @ActivityModule.State activityDetail!: any

  private showLimit = 3
  private showMore = true

  get isLinePage() {
    return this.activityDetail?.basic_data?.is_line_page ?? false
  }

  get calcSoldUnits() {
    const filterArr = this.units?.filter((o: any) => !o.isSoldOut) || []
    return filterArr
  }

  get currentUnits() {
    const filterArr = this.calcSoldUnits
    return this.showMore ? filterArr.slice(0, this.showLimit) : filterArr
  }

  handleMore() {
    this.showMore = !this.showMore
  }

  getUnitSpm(unit: Activity.unit, index: number) {
    const sellingPrice = unit.sellingPrice
    const spmObj = {
      ...getTaggingExtraTrackObj(unit?.product_tags?.discount_tags),
      ...this.ext,
      Length: this.units.length,
      Index: index,
      SalesPrice: currencyExchange(sellingPrice, this.currency) ?? sellingPrice
    }

    return `SKUBookBtn_LIST?oid=sku_${unit.skuId}&idx=${index}&len=${this.units.length}&ext=${this.$getExt(
      spmObj
    )}`
  }

  toBook(unit: Activity.unit) {
    if (this.isLinePage || this.isWarming || this.isSuspended || unit.isSoldOut) {
      return
    }

    this.$emit('toBook', unit.skuId)
  }
}
