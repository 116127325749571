


















import { Component, Vue, State } from 'nuxt-property-decorator'

@Component
export default class SeeMore extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
}
