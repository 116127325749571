








import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class PackageDetailFailure extends Vue {
  handleClick() {
    this.$emit('fetch-detail')
  }
}
