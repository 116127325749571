














import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import { Itrial } from '~/components/experience-booking/experience-activity/combo-option/booking-options/type'
import { formatCurrencySymbolPriceThousands } from '~/share/utils'

@Component
export default class Index extends Vue {
  @Prop({ default: {} }) priceDetail!: Itrial
  @Prop({ default: '' }) currencySymbol!: string
  @State klook!: Data.Klook

  get list() {
    return (this.priceDetail.units_info || []).filter((item) => item.count > 0)
  }

  price(val: number) {
    return formatCurrencySymbolPriceThousands(this.klook.currency, val)
  }
}
