









import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'
import DynamicModalMarkdown
  from '~/components/experience-booking/experience-activity/dynamic-component/markdown/dynamic-modal-markdown.vue'
import DynamicCollapseMarkdown
  from '~/components/experience-booking/experience-activity/dynamic-component/markdown/dynamic-collapse-markdown.vue'
import DynamicPlainMarkdown
  from '~/components/experience-booking/experience-activity/dynamic-component/markdown/dynamic-plain-markdown.vue'
// import DynamicPlainMarkdownViewMore
//   from '~/components/experience-booking/experience-activity/dynamic-component/markdown/dynamic-plain-markdown-view-more.vue'
import { renderMarkdownObj } from '~/assets/scripts/markdown'

@Component({
  components: {
    DynamicPlainMarkdown,
    DynamicModalMarkdown,
    DynamicCollapseMarkdown
    // DynamicPlainMarkdownViewMore
  }
})
export default class DynamicMarkdown extends Vue {
  @Prop() content!: ExperienceActivity.IDynamicMarkdown
  // @State(state => state.klook.platform) platform!: Data.Platform

  get title() {
    return this.content?.style?.fold_title || this.$t('1315')
  }

  get renderContent() {
    const markdownContent = this.content.data

    if (!markdownContent || !markdownContent.length) { return [] }

    return markdownContent.map((v) => {
      return renderMarkdownObj(v.render_object)
    })
  }

  get componentName() {
    switch (this.content?.style?.fold_style) {
      case 1:
        return 'DynamicCollapseMarkdown'
      case 2:
        return 'DynamicModalMarkdown'
      default:
        // return this.platform === 'desktop' ? 'DynamicPlainMarkdownViewMore' : 'DynamicPlainMarkdown'
        return 'DynamicPlainMarkdown'
    }
  }
}
