import isEmpty from 'lodash/isEmpty'

export type ActivityId = 4 | 7 | 8 | 9 | 10 | 11
export const activityTypes = {
  attractionsAndShows: 'attractionsAndShows',
  tourAndSightseeing: 'tourAndSightseeing',
  activitiesAndExperiences: 'activitiesAndExperiences',
  bestFoodAndMustEats: 'bestFoodAndMustEats',
  transportAndTravelService: 'transportAndTravelService',
  // 'fnb',
  wifi: 'wifi',
  sim: 'sim',
  ysim: 'ysim',
  gift: 'gift',
  europerail: 'europerail',
  chinarail: 'chinarail',
  transfer: 'transfer',
  hotelVoucher: 'hotelVoucher',
  carRental: 'carRental',
  hotel: 'hotel'
}

type ActivityIdMap = Record<string, string>
export const activityIdMap: ActivityIdMap = {
  1: activityTypes.attractionsAndShows,
  2: activityTypes.tourAndSightseeing,
  3: activityTypes.activitiesAndExperiences,
  4: activityTypes.bestFoodAndMustEats,
  5: activityTypes.transportAndTravelService,
  7: activityTypes.wifi,
  8: activityTypes.sim,
  9: activityTypes.ysim,
  10: activityTypes.gift,
  11: activityTypes.europerail,
  12: activityTypes.chinarail,
  13: activityTypes.transfer,
  14: activityTypes.hotelVoucher,
  15: activityTypes.carRental,
  16: activityTypes.hotel
}

export const verticalTypes = {
  1: 'Attractions & Shows',
  2: 'Tours & Sightseeing',
  3: 'Activities & Experiences',
  4: 'Beats Food & Must Eats',
  5: 'Transport & Travel Service'
}

export function getOperationTagIds(cardTags: any) {
  if (!isEmpty(cardTags)) {
    const tagsMap = {
      editor_choice: 1,
      editorChoice: 1,
      bestseller: 2,
      new_activity: 3,
      newActivity: 3
    }
    return Object.keys(tagsMap).reduce((acc, v) => cardTags[v] ? acc.concat((tagsMap as any)[v]) : acc, [])
  }
  return []
}

// 上报数据的operation tags
export function getOperationTags(cardTags: any) {
  if (!isEmpty(cardTags)) {
    const tagsMap = {
      percentageOff: 'Percentage Off',
      percentage_off: 'Percentage Off',
      skip_the_line: 'Fast Track',
      skipTheLine: 'Fast Track',
      newActivity: 'New',
      new_activity: 'New',
      bestseller: 'Bestseller',
      editorChoice: 'Editor Choice',
      editor_choice: 'Editor Choice'
    }
    return Object.keys(cardTags).reduce((acc, v) => (cardTags as any)[v] ? [...acc, (tagsMap as any)[v]] : acc, [] as string[])
  }
  return ['NA']
}

interface getGiftOtherInfoId {
    deliveryFromId: number;
    deliveryToId: number;
    messageId: number;
    deliveryDateId: number;
}
export function getGiftOtherInfoId(): getGiftOtherInfoId {
  if (process.env.APP_ENV === 'production') {
    return {
      deliveryFromId: 259692,
      deliveryToId: 259693,
      messageId: 259694,
      deliveryDateId: 259695
    }
  } else {
    return {
      deliveryFromId: 142913,
      deliveryToId: 142914,
      messageId: 142915,
      deliveryDateId: 142916
    }
  }
}

// 非促销活动时显示划线价和打折标签的 template id
// attraction: 1  fnb: 4
export const ActivityTemplateIds = [1, 4]

export const redirectPtpActivityMap = {
  51656: '/buses-18/1004-thailand/',
  53132: '/buses-30/1015-singapore/',
  53165: '/buses-31/1019-malaysia/',
  53688: '/rails-24/1014-taiwan/',
  48845: '/ferry-3/1004-thailand/',
  49213: '/ferry-8/1013-vietnam/',
  49218: '/ferry-9/1019-malaysia/',
  49282: '/buses-10/364891-europe/',
  48556: '/rails-6/1010-south-korea/'
}

export const crRedirectMap = {
  13604: {
    from_des: 'shh',
    to_des: 'bjp'
  },
  15244: {
    from_des: 'gzq',
    to_des: 'xja'
  },
  13715: {
    from_des: 'szq',
    to_des: 'gzq'
  },
  13603: {
    from_des: 'bjp',
    to_des: 'shh'
  },
  13908: {
    from_des: 'szh',
    to_des: 'hzh'
  },
  13655: {
    from_des: 'bjp',
    to_des: 'xay'
  },
  13862: {
    from_des: 'glz',
    to_des: 'szq'
  },
  13902: {
    from_des: 'szq',
    to_des: 'xms'
  },
  13911: {
    from_des: 'csq',
    to_des: 'diq'
  },
  13845: {
    from_des: 'vuq',
    to_des: 'seq'
  },
  15714: {
    from_des: 'fzs',
    to_des: 'xja'
  },
  13871: {
    from_des: 'cqw',
    to_des: 'cdw'
  },
  15725: {
    from_des: 'xms',
    to_des: 'xja'
  },
  15811: {
    from_des: 'glz',
    to_des: 'xja'
  },
  15797: {
    from_des: 'shh',
    to_des: 'xja'
  },
  13708: {
    from_des: 'cdw',
    to_des: 'xay'
  },
  15739: {
    from_des: 'csq',
    to_des: 'xja'
  },
  15798: {
    from_des: 'whn',
    to_des: 'xja'
  },
  15814: {
    from_des: 'giw',
    to_des: 'xja'
  },
  15799: {
    from_des: 'zzf',
    to_des: 'xja'
  },
  15815: {
    from_des: 'hzh',
    to_des: 'xja'
  },
  15816: {
    from_des: 'ncg',
    to_des: 'xja'
  },
  15663: {
    from_des: 'bjp',
    to_des: 'xja'
  },
  15507: {
    from_des: 'kmm',
    to_des: 'xja'
  },
  20480: {
    from_des: 'hzh',
    to_des: 'shh'
  },
  20688: {
    from_des: 'gzq',
    to_des: 'zhq'
  },
  20393: {
    from_des: 'gzq',
    to_des: 'shh'
  },
  20547: {
    from_des: 'gzq',
    to_des: 'hzh'
  },
  20406: {
    from_des: 'shh',
    to_des: 'zjh'
  },
  20556: {
    from_des: 'hzh',
    to_des: 'zjh'
  },
  20440: {
    from_des: 'xay',
    to_des: 'shh'
  },
  20637: {
    from_des: 'kmm',
    to_des: 'gzq'
  },
  26723: {
    from_des: '',
    to_des: ''
  }
}

export const erRedirectMsap = {
  17136: {
    from_des: '',
    to_des: ''
  }
}

export function getRailwayRedirectUrl(type: 'er' | 'cr') {
  return function (activityId: string) {
    const redirectMap: any = {
      er: erRedirectMsap,
      cr: crRedirectMap
    }

    const redirectInfo = redirectMap[type][activityId]

    if (!redirectInfo) { return '' }

    const redirectUrlMap: any = {
      er: `/europe-rail/?from_des_code=${redirectInfo.from_des}&to_des_code=${redirectInfo.to_des}`,
      cr: `/china-train-ticket/?from_des_code=${redirectInfo.from_des}&to_des_code=${redirectInfo.to_des}`
    }

    return redirectUrlMap[type]
  }
}

export const getCrRedirectUrl = getRailwayRedirectUrl('cr')
export const getErRedirectUrl = getRailwayRedirectUrl('er')

export const ttdHotelVsStaycationMapping = {
  47981: '426917',
  53337: '588275',
  59484: '262289',
  43564: '254974',
  51819: '426030'
}

export const experienceTemplateIds = [1, 2, 3, 5]

export const cacheRequestActivity = [
  'zh-CN:CN:CNY',
  'zh-CN:BS:CNY',
  'zh-CN:HK:CNY',
  'zh-CN:US:CNY',

  'zh-HK:HK:HKD',
  'zh-HK:HK:USD',
  'zh-HK:HK:CNY',
  'zh-HK:US:HKD',
  'zh-HK:US:USD',
  'zh-HK:CN:CNY',
  'zh-HK:CN:HKD',

  'en-HK:HK:HKD',
  'en-HK:HK:USD',
  'en-HK:US:HKD',
  'en-HK:US:USD',
  'en-HK:CN:HKD',

  'en-SG:SG:SGD',
  'en-SG:US:SGD',
  'en-SG:US:USD',
  'en:SG:SGD',
  'en:US:SGD',
  'en:US:USD',

  'zh-TW:TW:TWD',
  'zh-TW:TW:USD',
  'zh-TW:TW:CNY',
  'zh-TW:US:TWD',
  'zh-TW:US:USD',
  'zh-TW:CN:CNY',
  'zh-TW:CN:TWD',

  'en-MY:MY:MYR',
  'ko:KR:KRW',
  'th:TH:THB',
  'vi:VM:VND',
  'en-PH:PH:PHP'

]
