

















import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class Index extends Vue {
  @Prop() hideDate!: boolean
  @Prop() hideUnit!: boolean

  get calcArr() {
    const arr = new Array(3).fill(0).map((_, i) => i)
    return arr
  }
}
