import { Component, Getter, Watch, Prop } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import BottomBarSettlement from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/attribute-bottom-bar/bottom-bar-settlement.vue'
import ActivityDotFlashing from '~/components/traveller/activity/activity-bottom-bar/mobile/activity-dot-flashing.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import PromotionBase from '~/components/experience-booking/experience-activity/promotion/promotion-base'

@Component({
  components: {
    BottomBarSettlement
  }
})
export default class ActivitySettlement extends PromotionBase {
  @Prop() schedule!: ExperienceActivity.ISchedule
  @Prop() packageId!: number
  @Prop() packageSchedulesState!: boolean
  @Prop() selectedPackage!: ExperienceActivity.IPackage
  @Prop() isActivitySuspended!: boolean
  @Getter useCredits!: boolean
  @Getter isGuestCheckout!: boolean

  minSellPrice = ''
  sellPrice = '-'
  loading = false

  @Watch('stock', { immediate: true })
  stockChange() {
    this.getSellPrice()
  }

  @Watch('selectedPackage')
  currentSelectedPackageChange() {
    this.getSellPrice()
  }

  // 这里要等 promotionDetail 返回后再刷新一次 sellPrice
  // TODO: 这里理论上就不需要监听 currentSelectedPackage 的变化了，但是怕出问题仍然在监听，但是会刷新二次 sellPrice
  @Watch('promotionDetail')
  promotionDetailChange() {
    this.getSellPrice()
  }

  @Watch('date')
  packageSelectedDateChange(val: string) {
    if (val) {
      this.loading = true

      setTimeout(() => {
        this.loading = false
      }, 500)
    }
  }

  @Watch('packageSchedulesState')
  fetchPromotionDetailStatusChange(val: string) {
    this.loading = val === 'fetching'
  }

  // 选择日历日期之后通过schedules接口拿到的库存相关数据来计算展示价格
  // 即选中了日期，使用当日的套餐数据
  // 未选中日期，使用包含所有日期的套餐数据
  get stock() {
    const stocks = this.schedule?.stocks
    if (stocks) {
      const stock = stocks.find(v => v.package_id === this.packageId)

      if (stock) {
        return stock
      }
    }
  }

  getSellPrice() {
    // 如果处于预热中，则显示预热价 original_selling_price
    if (this.isWarmUp) {
      this.minSellPrice = ''
      this.sellPrice = this.promotionDetail?.original_selling_price
      return
    }

    // 此为套餐价
    if (this.stock) {
      const { from_price, to_price } = this.stock

      if (from_price === to_price) {
        this.sellPrice = from_price
        this.minSellPrice = ''
      } else {
        this.minSellPrice = from_price
      }

      return
    }

    if (isEmpty(this.selectedPackage)) {
      this.sellPrice = '-'
      return
    }

    // from_price, to_price 逻辑后端已收拢到 price_show 字段中
    this.sellPrice = ''
    this.minSellPrice = ''
    // const { from_price, to_price } = this.selectedPackage

    // if (from_price === to_price) {
    //   this.sellPrice = from_price
    //   this.minSellPrice = ''
    // } else {
    //   this.minSellPrice = from_price
    // }
  }

  get marketPrice() {
    // 预热价格
    if (this.isWarmUp) {
      const { market_price, original_selling_price } = this.promotionDetail || {}
      return Number(market_price) > Number(original_selling_price) ? market_price : '0'
    }

    if (this.stock) {
      return this.stock.market_price
    }

    if (this.selectedPackage) {
      const { sell_price, market_price } = this.selectedPackage

      return Number(market_price) > Number(sell_price) ? market_price : '0'
    }
    return '0'
  }

  get promotionPrice() {
    return this.promotionDetail?.selling_price || '0'
  }

  get originalPrice() {
    return this.promotionDetail?.original_selling_price || '0'
  }

  // get discount() {
  //   // 这个被干掉了，promotion活动页留
  //   // 只有写死的templateId才显示discount；只有非促销状态才显示discount
  //   if (!this.templateIdList.includes(this.templateId) || this.isInPromotion) {
  //     return ''
  //   }

  //   if (this.stock) {
  //     return (this.stock as any).discountDesc
  //   }

  //   if (this.package) {
  //     return this.package.discountDesc
  //   }

  //   return ''
  // }

  get credits() {
    // 促销或预热的时候不显示积分
    if (this.isInPromotion || this.isWarmUp) {
      return ''
    }

    if (this.selectedPackage) {
      const { credits } = this.selectedPackage

      if (!credits) { return '' }

      return Math.floor(this.selectedPackage.credits)
    }
    return ''
  }

  get isCreditsVisible() {
    return this.useCredits && !this.isGuestCheckout
  }

  get vip() {
    return this.selectedPackage?.credits_tag
  }

  render() {
    if (this.loading) {
      return <ActivityDotFlashing></ActivityDotFlashing>
    }

    return (
      <BottomBarSettlement
        selectedPackage={this.selectedPackage}
        isOnlyOneLine={false}
        isInPromotion={this.isInPromotion}
        isActivitySuspended={this.isActivitySuspended}
        marketPrice={this.marketPrice}
        promotionPrice={this.promotionPrice}
        sellPrice={this.sellPrice}
        originalPrice={this.originalPrice}
        minSellPrice={this.minSellPrice}
        credits={this.credits}
        creditsVisible={this.isCreditsVisible}
        vip={this.vip}
      />
    )
  }
}
