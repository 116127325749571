












































import { Vue, Component, Prop, State, Ref } from 'nuxt-property-decorator'
import { IconClose } from '@klook/klook-icons'

type PlatformIsIf = 'klk-bottom-sheet' | 'klk-drawer' | 'klk-modal' | 'klk-poptip'

@Component({
  components: {
    IconClose
  }
})
export default class Index extends Vue {
  // is = string | klk-bottom-sheet | klk-drawer | klk-
  @Prop({ type: String, default: 'header-left-icon' }) closeType!: string
  @Prop({ type: Boolean, default: false }) hideLeftIcon!: boolean
  @Prop({ type: Boolean, default: false }) showRightIcon!: boolean
  @Prop({ type: String, default: 'klk-bottom-sheet' }) mobileIs!: PlatformIsIf
  @Prop({ type: String, default: 'klk-drawer' }) desktopIs!: PlatformIsIf
  @Prop({ type: Object, default: () => ({ visible: false }) }) syncData!: object
  @Prop({ type: Boolean, default: false }) fullscreen!: boolean
  @State klook!: Data.Klook
  @Ref() drawerHeaderRef!: any
  @Ref() drawerFooterRef!: any

  bodyObj = {
    styles: {
      paddingLeft: '32px',
      paddingRight: '32px',
      paddingTop: '74px',
      paddingBottom: '0'
    }
  }

  get calcBind() {
    const defOptions = this.getDefaultOptions(this.calcIsName)
    const obj = { ...defOptions, ...this.$attrs }
    return obj
  }

  getDefaultOptions(isName = '') {
    if (!isName) {
      return {}
    }
    let obj = {}
    const fullscreen = this.fullscreen ? 'fullscreen' : ''
    switch (isName) {
      case 'klk-bottom-sheet':
        obj = {
          transfer: true,
          class: `exp-common-reset-bottom-sheet hack-zindex-safari ${fullscreen}`,
          title: '',
          showClose: true
        }
        break
      case 'klk-drawer':
        obj = {
          class: 'exp-common-reset-drawer',
          'mask-closable': true,
          direction: 'right'
        }
        break
      case 'klk-modal':
        obj = {
          size: 'normal',
          // 'modal-class': 'exp-common-reset-modal', // 可通过样式覆盖
          closable: true,
          scrollable: true,
          'overlay-closable': true,
          'esc-press-closable': true,
          'show-default-footer': false,
          'append-body': true
        }
        break
      case 'klk-poptip':
        obj = {
          // class: 'exp-common-reset-poptip', // 可通过样式覆盖
          'append-to-body': false,
          trigger: 'hover',
          flip: true,
          preventOverflow: true
        }
        break
      default:
        break
    }
    return obj
  }

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get calcIsName() {
    return this.isDesktop ? this.desktopIs : this.mobileIs
  }

  mounted() {
    this.updateHeaderFooter()
  }

  updateHeaderFooter() {
    const { bodyObj: { styles } } = this
    if (this.drawerHeaderRef?.clientHeight) {
      styles.paddingTop = this.drawerHeaderRef?.clientHeight + 'px'
    }
    if (this.drawerFooterRef?.clientHeight) {
      styles.paddingBottom = this.drawerFooterRef?.clientHeight + 'px'
    }
  }

  closeHander(type = '') {
    if (!type) {
      return
    }
    if (type === this.closeType) {
      this.$set(this.syncData, 'visible', false)
    } else {
      this.$emit(type)
    }
  }
}

export interface MusterDefinedIf {
  title: string,
  desktopIs: string,
  mobileIs: string,
  syncData: any
}

export const getMusterDefined = function (mergeData?: any) {
  const musterData: MusterDefinedIf = {
    title: '',
    desktopIs: 'klk-drawer',
    mobileIs: 'klk-bottom-sheet',
    syncData: {
      visible: false
    },
    ...(mergeData || {})
  }
  return musterData
}
