





















import { Component, Vue, State, Prop } from 'nuxt-property-decorator'

@Component
export default class NoneFilterResult extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop({ default: '' }) title!: string
  @Prop() btnTitle?: string
  @Prop() spm?: string
}
