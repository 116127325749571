




































import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import PackageDetailCollapse from '~/components/common/package-detail-collapse/package-detail-collapse.vue'
import { Activity } from '~/types/traveller/activity'
import ActivityValidity from '~/components/experience-booking/experience-activity/open-date-ticket/activity-validity/activity-validity-new.vue'
import AttractionPreviewTag from '~/components/experience-booking/experience-activity/package-options/attraction-preview/components/attraction-preview-tag.vue'
import PublishAi from '~/components/common/publish-ai/index.vue'

@Component({
  components: {
    AttractionPreviewTag,
    ActivityValidity,
    PackageDetailCollapse,
    PublishAi
  }
})
export default class PackageDetailContent extends Vue {
  @State klook!: Data.Klook;
  @Prop() packageDetail!: Activity.packageDetail;
  @Prop({ default: 'moduleDetail' }) type!: 'slideDetail' | 'moduleDetail';
  @Prop() size!: 'large' | 'small';
  @Prop({ default: true }) expandAll!: boolean;
  @Prop({ default: true }) showPackageName!: boolean;
  @Prop({ default: true }) defaltValue!: number[];
  @Prop({ type: Boolean, default: false }) useImageSwiper!: boolean
  @Prop({ type: Number, default: 0 }) imageCardSwiperType!: number
  @Prop({ type: String, default: '' }) imageGroupStyle!: string

  get calcTags2attribute() {
    // desktop活动页-套餐详情
    const { product_tags } = this.packageDetail as any
    return product_tags?.attribute_tags || []
  }

  get isAiTranslation() {
    return !!this.packageDetail?.is_ai_translation
  }
}
