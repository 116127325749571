import { Vue, Component } from 'nuxt-property-decorator'

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  chosenAttrIds: number[] = []
  userSelectedSpec: any = []

  handleSelectSpec(list: number[]) {
    this.userSelectedSpec = [...(list || [])]
  }

  handleDeleteAttrs(list: number[], specList: any[]) {
    this.chosenAttrIds = this.chosenAttrIds.filter((id) => !list.includes(id))

    // 如果不构成套餐了，那么重置packageId
    if (this.chosenAttrIds?.length !== specList?.length) {
      this.$emit('update:packageId', 0)
    }
  }
}
