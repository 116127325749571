























import { Component, Prop, Vue } from 'nuxt-property-decorator'

interface Header {
  text: string
  color: string
}

interface Cell {
  text: string
  color?: string
  weight?: number
}

interface TableData {
  headers: Header[]
  rows: Cell[][]
}

@Component
export default class TableComponent extends Vue {
  @Prop({ type: String, required: true }) platform!: string
  @Prop({ type: Object, required: true }) tableData!: TableData
  @Prop({ type: String, required: false, default: '' }) type!: string

  get headers() {
    return this.tableData?.headers || []
  }

  get rows() {
    return this.tableData?.rows || []
  }
}
