






import { Component, Vue } from 'nuxt-property-decorator'
import UspBanner from '~/components/traveller/activity/usp-tip/usp-banner.vue'

@Component({
  components: {
    UspBanner
  }
})
export default class SrvUspBanner extends Vue {
}
