






















































































import { Component, mixins } from 'nuxt-property-decorator'
import CombonHeader from './combo-header.vue'
import HeaderTips from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/headerTips/index.vue'
import CardHeader from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/card-header.vue'
import BookingCalendar from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/booking-calendar.vue'
import CombonBookingUnit from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/combonBookingUnit/index.vue'
import CombonBookingBase from '~/components/experience-booking/experience-activity/combo-option/booking-options/combo-booking-base'
import ExpTagging from '~/components/experience/tagging/index.vue'
import CalendarList from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/calendarList/index.vue'
import DrawerFooter from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/drawer-footer/index.vue'
import Skeleton from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/skeleton/index.vue'
import PaymentDetail from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/paymentDetail/index.vue'
import Drawer from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/drawer/index.vue'
import UnitWarn from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/booking-calendar/unitWarn.vue'
import ExpLoadingState from '~/components/experience/loading-state/index.vue'
import Tips from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/discount-tips.vue'
import { BookingOptionsSkeleton } from '~/components/common/skeleton-data/index.js'

@Component({
  components: {
    ExpTagging,
    HeaderTips,
    CardHeader,
    BookingCalendar,
    CombonBookingUnit,
    DrawerFooter,
    CalendarList,
    Skeleton,
    UnitWarn,
    PaymentDetail,
    Drawer,
    CombonHeader,
    ExpLoadingState,
    Tips
  }
})
export default class ComboPackageOptionsBooking extends mixins(CombonBookingBase) {
  BookingOptionsSkeleton = BookingOptionsSkeleton

  clickPkgDetail(isb: boolean) {
    let width = 650
    const ele = document.getElementById('dynamic-drawer-content')
    if (ele) {
      width = ele.clientWidth
    }

    this.$emit('setPkgDetailStatus', this.calcPkgSelectedObj, isb, width)
  }

  handleModalClick(event: Event) {
    // 阻止事件冒泡
    event.stopPropagation()
  }
}
