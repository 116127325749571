






import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import DeviceDetailsContent from '~/components/experience-booking/experience-activity/device-details/comp/content.vue'

@Component({
  components: {
    DeviceDetailsContent
  }
})
export default class Index extends BusinessBase {
  @State klook!: Data.Klook
  static displayName = 'Device_detail'

  get calcList() {
    return this.businessData?.kvs || []
  }
}
