













































import { Component } from 'nuxt-property-decorator'
import LineUpStatus from '../components/line-up-status.vue'
import LineUpBase from '../base/index'
import LineUpBookTips from '../components/line-up-book-tips.vue'

@Component({
  components: {
    LineUpStatus,
    LineUpBookTips
  }
})
export default class ActivityeLineUp extends LineUpBase {
  static displayName = 'DesktopFlash_sale_line_info'

  freshLoading: boolean = false

  fresh() {
    clearTimeout(this.timer)
    this.timer = setTimeout(async () => {
      this.freshLoading = true
      await this.getLineUpInfo()
      setTimeout(() => {
        this.freshLoading = false
      }, 1200)
    }, 350)
  }
}
