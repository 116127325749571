









import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ActRightPriceSkeleton } from '~/components/common/skeleton-data/index.js'

@Component
export default class Loading extends Vue {
  @Prop({ default: '100%' }) loadingWidth!: string

  ActRightPriceSkeleton = ActRightPriceSkeleton
}
