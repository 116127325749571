



























import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class DefaultSkeleton extends Vue {
  @Prop({ type: Number, default: 4 }) itemNum!: number
  @Prop({ type: Number, default: 1 }) rows!: number

  get items() {
    return new Array(this.itemNum).fill(null).map((_v, i) => i)
  }
}

