







import { Vue, Component } from 'nuxt-property-decorator'
import { Prop } from 'vue-property-decorator'

@Component({})
export default class PackageOptionsContent extends Vue {
  @Prop({ type: String }) contentTitle?: string
}
