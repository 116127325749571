
































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import type { PackageDetailType } from '~/components/common/package-detail-v2/type'
import PackageDetailTitle from '~/components/common/package-detail-v2/title.vue'
import PackageDetailIconItems from '~/components/common/package-detail-v2/icon-items.vue'
import PackageDetailSection from '~/components/common/package-detail-v2/sections/index.vue'
import PublishAi from '~/components/common/publish-ai/index.vue'

@Component({
  components: {
    PublishAi,
    PackageDetailTitle,
    PackageDetailIconItems,
    PackageDetailSection
  }
})
export default class PackageDetailStructured extends Vue {
  @Prop({ default: () => null }) packageDetail!: PackageDetailType
  @Prop({ default: false }) showActivityName!: boolean
  @Prop({ default: true }) showPkgName!: boolean

  get isAiTranslation() {
    return this.translateButtonVisible && this.isTranslated
  }

  get translateButtonVisible() {
    return !!this.packageDetail?.enable_translation_button
  }

  get isTranslated() {
    return !this.packageDetail?.translation_status
  }

  get actTitle() {
    return this.showActivityName ? this.packageDetail?.activity_name : ''
  }

  get pkgTitle() {
    return this.packageDetail?.package_name || ''
  }

  get iconItems() {
    return this.packageDetail?.icon_items || []
  }

  get sections() {
    return this.packageDetail?.section_content?.sections || []
    // return this.packageDetail?.section_content?.sections || []
  }
}
