


























import { Component, namespace, State } from 'nuxt-property-decorator'
import KlkTravellerHeader from '@klook/klk-traveller-header'
import '@klook/klk-traveller-header/esm/klkTravellerMobileHeader.css'
import HeaderBase from '~/components/layouts/default/common/header-base'
import eventBus from '~/pages/experience/pay/common/event-bus'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    KlkTravellerHeader
  }
})
export default class DefaultHeader extends HeaderBase {
  @State klook!: Data.Klook
  @ActivityModule.State activityId!: number

  get serverDestinationData() {
    return this.klook.destinationData || null
  }

  get serverAllCategoryList() {
    return this.klook.allCategoryList || []
  }

  get isDestinationListVisible() {
    return this.siteConfig.showSecondaryNav
  }

  get isUserVisible() {
    return this.siteConfig.user_center_path
  }

  get isExpressCheckOut() {
    return this.klook.utilConfig?.f_source_type === 'express_check_out'
  }

  get hideExpressCheckoutActivityLogo() {
    return this.isExpressCheckOut && [67194, 35336].includes(this.activityId)
  }

  get logo() {
    const siteConfig = this.siteConfig
    const cobrand = this.klook.affiliateConf?.cobrand
    return {
      link: siteConfig.noLinkToHomePageLogo ? 'javascript:;' : this.$href('/'),
      url: cobrand?.logo_url,
      height: cobrand?.cobrand_height_mweb
    }
  }

  beforeMount() {
    window.$sentry = this.$sentry
    window.Cookies = this.$cookies
    window.$axios = this.$axios
    window.tracker = {
      gtm: {
        sendGTMCustomEvent: this.$sendGTMCustomEvent.bind(this)
      },
      sendMixpanel: () => {},
      inhouse: this.$inhouse
    }
  }

  mounted() {
    eventBus.$on('refresh-shopping-cart', this.refreshShoppingCart)
  }

  beforeDestroy() {
    eventBus.$off('refresh-shopping-cart')
  }

  refreshShoppingCart() {
    const travellerHeader = this.$refs.travellerHeader as any
    if (travellerHeader) {
      travellerHeader.updateShoppingCart()
    }
  }
}
