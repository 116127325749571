import Vue from 'vue'
import merge from 'lodash/merge'
import ImageViewer from '../components/image-viewer/index.vue'
import apis from '~/share/data/apis'
import { currencySymbolMap } from '~/share/data/currency'

export const formHeaders = { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }

let imgViewerInstance = null
export function isiOS() {
  const u = navigator.userAgent
  return !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}
export const getModalOpt = (option = {}) => {
  const defBind = {
    title: '',
    open: false,
    size: 'large',
    scrollable: true,
    overlayClosable: true,
    closable: true,
    escPressClosable: true,
    showDefaultFooter: false
  }
  return merge({}, defBind, option)
}

export const showImageViewer = (list, start, scopedSlots) => {
  if (!imgViewerInstance) {
    const ImageViewerConstructor = Vue.extend(ImageViewer)
    imgViewerInstance = new ImageViewerConstructor({ el: document.createElement('div') })
    document.body.appendChild(imgViewerInstance.$el)
  }

  imgViewerInstance.$scopedSlots = scopedSlots || {}

  Vue.nextTick(() => {
    imgViewerInstance.list = list
    imgViewerInstance.start = start
  })

  Vue.nextTick(() => (imgViewerInstance.isShow = true))
}

// 点击 催单
export function clickChase2vm(params, sucCb) {
  this.$axios.post(apis.postBookingChase, params, {
    headers: formHeaders,
    loading: true
  }).then(({ data: resp }) => {
    if (resp.success) {
      typeof sucCb === 'function' && sucCb()
    } else {
      this.$alert(resp.error && resp.error.message)
    }
  })
}

export function displayCurrency(currency) {
  return currencySymbolMap[currency] || currency
}
export function formatPriceThousands(price) {
  let tmp
  price = (price || '0').toString()

  if (!price.includes('.')) {
    tmp = price.replace(/(?=(?!(\b))(\d{3})+$)/g, ',')
  } else {
    price = price.split('.')
    tmp = (price[0]).toString().replace(/(?=(?!(\b))(\d{3})+$)/g, ',') + '.' + price[1]
  }

  return tmp
}

export function openDeeplink(href = '', platform = 'desktop') {
  if (!href) { return }
  if (platform === 'desktop') {
    window.open(href)
  } else {
    window.location.href = href
  }
}
