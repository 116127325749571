import Vue, { CreateElement } from 'vue'
// @ts-ignore
import KlkBottomSheet from '@klook/klook-ui/lib/bottom-sheet/index'

type args = {
  parent?: any,
  injectionOptions?: object,
  dialogAttrs?: object,
  dialogClass?: object,
  dialogListeners?: any,
  componentAttrs?: object,
  componentListeners?: object,
}

export default function componentWithBottomSheet(VueComponent: any = null, {
  parent,
  injectionOptions = {},
  dialogAttrs = {},
  dialogClass = {},
  dialogListeners = {},
  componentAttrs = {},
  componentListeners = {}
}: args = {}) {
  if (!VueComponent) {
    throw new Error('必须的参数：VueComponent')
  }

  const BottomSheetCtor = Vue.extend({
    name: 'BottomSheet',
    data() {
      return {
        visible: false,
        componentAttrs
      }
    },
    methods: {
      show() {
        (this as any).visible = true
      },
      close() {
        (this as any).visible = false
      }
    },
    render(h: CreateElement) {
      return h(KlkBottomSheet, {
        on: {
          ...dialogListeners,
          closed: (...args: any[]) => {
            // 传入的方法先执行
            if (dialogListeners.closed) {
              dialogListeners.close.apply(null, args)
            }

            this.$destroy()
            if (this.$el && this.$el.parentElement) {
              this.$el.parentElement.removeChild(this.$el)
            }
          },
          'update:visible': (val: boolean) => {
            (this as any).visible = val
          }
        },
        class: dialogClass,
        attrs: {
          visible: (this as any).visible,
          showClose: true,
          ...dialogAttrs
        }
      }, [
        h(VueComponent, {
          attrs: {
            ...(this as any).componentAttrs
          },
          on: {
            // 方便组件内关闭弹窗
            close: () => {
              (this as any).close()
            },
            ...componentListeners
          }
        })
      ])
    }
  })

  const componentDialog = (new BottomSheetCtor({
    ...(parent ? { parent } : {}), // 直接传parent 无需手动inject
    ...injectionOptions
  })).$mount()
  document.body.appendChild(componentDialog.$el)

  componentDialog.$nextTick(() => {
    (componentDialog as any).show()
  })

  return componentDialog
}
