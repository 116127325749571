






































































import { Component, Prop, mixins } from 'nuxt-property-decorator'
import ExperienceActivityBanner from '~/components/experience-booking/experience-activity/banner/activity-banner.vue'
import ActivityRightPrice
  from '~/components/experience-booking/experience-activity/banner/desktop/activity-right-price/index.vue'
import ActivityRightPriceLoading
  from '~/components/experience-booking/experience-activity/banner/desktop/activity-right-price/loading.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import { ExperienceActivity } from '~/types/experience/activity-section'
import ScrollMixins from '~/components/experience-booking/experience-activity/banner/desktop/scroll-mixins'

@Component({
  components: {
    ExperienceActivityBanner,
    ActivityRightPrice,
    ActivityRightPriceLoading
  }
})
export default class ActivityBanner extends mixins(BusinessBase, ScrollMixins) {
  static displayName = 'DesktopBanner'
  @Prop() basicData!: ExperienceActivity.IBasicData

  isVideoPlaying = false

  swiperOptions = {
    loop: true,
    speed: 800,
    slidesPerView: 'auto',
    centeredSlides: true,
    spaceBetween: 4,
    autoplay: {
      delay: 6000
    },
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev'
    },
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true
    },
    pagination: {
      el: '.activity-swiper-pagination',
      bulletClass: 'activity-swiper-pagination-bullet',
      bulletActiveClass: 'activity-swiper-pagination-bullet-active',
      clickable: true
    },
    disableOnInteraction: false,
    watchSlidesVisibility: true,
    width: 960,
    slidesOffsetBefore: 100
  }

  bannerList: ExperienceActivity.IBannerItem[] = []
  get rightPriceData() {
    return {
      ...this.businessData,
      priceShow: this.businessData?.price?.price_show,
      isActivityPublishInfo: true,
      publishInfo: this.basicData?.publish_info,
      basicData: this.basicData
    }
  }

  changePlaying(isPlay: boolean) {
    this.isVideoPlaying = isPlay
  }

  created() {
    this.bannerList = [this.businessData.banner_list[0]]
  }

  async mounted(): Promise<void> {
    this.bannerList = this.businessData.banner_list
    await null
  }
}
