























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import ActivityIcons from '../activity-icons.vue'
import { Activity } from '~/types/traveller/activity'

@Component({
  components: {
    ActivityIcons
  }
})
export default class MActivityPackageIcons extends Vue {
  @Prop() multiLanguageIcons !: Activity.multiLanguageIcon[]
  @Prop() instant !: boolean
  @Prop() isPresale !: boolean
  @Prop() type !: string
}
