



































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import Describe from './describe.vue'
import { config } from '~/components/experience-booking/experience-activity/pass-guide/config'

@Component({
  components: {
    Describe
  }
})
export default class Passguide extends Vue {
  @Prop() activityId!: number

  visible = false

  open() {
    this.visible = true
  }

  get list() {
    return config
  }
}
