import dayjs from 'dayjs'
import { cloneDeep } from 'lodash'

export function threadFn() {
  self.onmessage = (e: any) => {
    const s: any = undefined // 解决eslint参数校验
    self.postMessage(e.data, s)
  }
}

export function createWorker(fn: Function) {
  const fnStr = `(${fn.toString()})()`
  const workerBlob = new Blob([fnStr])
  const url = window.URL.createObjectURL(workerBlob)
  const worker = new Worker(url)
  return worker
}

export function getSchedulesMap(schedules: any) {
  const obj: any = {}
  for (const index in schedules) {
    const item = schedules[index]
    obj[item.date] = item
  }
  return obj
}

export function isSoldout(d: string, schedulesMap: any) {
  const date = getFormatDate(d)
  const schedule: any = schedulesMap[date]
  // 这里如果选不到日期是disabled，不当做售罄处理
  if (schedule) {
    return schedule.soldout
  }
}

export function isInvalidDate(dateInput: Date, options: any) {
  const dateStr = getFormatDate(dateInput)
  if (isSoldout(dateStr, options?.schedulesMap)) {
    return true
  }
  return isWifiInvalidDate(dateInput, options)
}

export function initDateDisabledCacheData(options: any) {
  const arr = options?.schedules
  if (!arr.length) {
    return {}
  }
  const initedObj = arr.reduce((obj: any, item: any) => {
    const date = new Date(item.date)
    obj[item.date] = isInvalidDate(date, options)
    return obj
  }, {})
  return {
    initedObj
  }
}

export function getLocalDate(day?: string) {
  if (Array.isArray(day)) {
    return day.map((d: string) => {
      const localeDate = dayjs(d || undefined).toString()
      return new Date(localeDate)
    })
  }
  const localeDate = dayjs(day || undefined).toString()
  return new Date(localeDate)
}

export function formatPackageSchedules(schedulesList: any[]) {
  const schedules = cloneDeep(schedulesList || [])
  for (let i = 1; i < schedules.length; i++) {
    const date = schedules[i - 1].date
    const nextDate = dayjs(date).clone().add(1, 'days')
    if (!nextDate.isSame(schedules[i].date, 'day')) {
      schedules.splice(i, 0, {
        date: nextDate.format('YYYY-MM-DD'),
        legend_keys: null,
        selling_price: '0',
        soldout: false,
        stock: 0,
        deleted: true
      })
    }
  }
  return schedules
}

export function getFormatDate(d: string | Date, formatStr?: string) {
  return dayjs(d).format(formatStr || 'YYYY-MM-DD')
}

export function isWifiInvalidDate(dateInput: Date, options: any) {
  const d = dayjs(dateInput)
  const dateStr = d.format('YYYY-MM-DD')
  const { schedules, rangeDateList, minPax, minDays, maxDays, maxDate } = options
  const hasDate = !!schedules?.find((item: any) => item.date === dateStr)
  if (hasDate) {
    return !!schedules.find((item: any) => {
      const date = item.date
      const [start_date, end_date] = rangeDateList
      const startDate = start_date ? dayjs(start_date).clone() : ''
      const endDate = end_date ? dayjs(end_date).clone() : ''

      if (startDate && !endDate) {
        if (d.isAfter(startDate, 'day') && d.isBefore(startDate.clone().add(minDays - 1, 'days'), 'day')) {
          // 新增领取时间与归还时间最小天数的不可选样式
          return true
        }
        // eslint-disable-next-line no-mixed-operators
        return item.deleted && d.isSame(item.date, 'day') || d.isAfter(startDate.clone().add(maxDays - 1, 'days'), 'day') || (item.stock < minPax || !item.stock || item.deleted) && (d.isBefore(startDate, 'day') && d.isBefore(date, 'day') && d.clone().add(minDays, 'days').isAfter(date, 'day') || startDate.isBefore(date, 'day') && d.isAfter(date, 'day'))
      } else if (startDate && endDate) {
        // eslint-disable-next-line
        return item.deleted && d.isSame(item.date, 'day') || (item.stock < minPax || !item.stock || item.deleted) && d.isBefore(date, 'day') && d.clone().add(minDays, 'days').isAfter(date, 'day')
      } else {
        // eslint-disable-next-line no-mixed-operators
        return item.deleted && d.isSame(item.date, 'day') || (item.stock < minPax || !item.stock || item.deleted) && d.isBefore(date, 'day') && d.clone().add(minDays, 'days').isAfter(date, 'day') || d.clone().add(minDays - 1, 'days').isAfter(maxDate, 'day')
      }
    })
  } else {
    return true
  }
}
