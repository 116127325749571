























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import AttractionIncludedFilter from '~/components/experience-booking/experience-activity/package-options/attraction-included/attraction-included-filter.vue'
import AttractionIncludedDesktop from '~/components/experience-booking/experience-activity/package-options/attraction-preview/desktop/attraction-preview-modal.vue'

@Component({
  components: {
    AttractionIncludedFilter,
    AttractionIncludedDesktop
  }
})
export default class AttractionIncluded extends Vue {
  @Prop({ default: 'view' }) mode!: 'view' | 'edit' | 'book'
  @Prop({ default: 'normal' }) size!: 'small' | 'normal'
  // @Prop({ default: 'slideDetail' }) type!: 'slideDetail' | 'moduleDetail';
  @Prop() info!: any
  @Prop() activityId!: number
  @Prop() packageId!: number

  @Prop() title!: string

  popVisible = false
  selectedIdList: number[] = []

  get totalActivityNum() {
    return this.info.activity_total || 0
  }

  handleConfirm(list: number[]) {
    this.selectedIdList = list || []
    this.commit()
  }

  handleToggle(id: number) {
    if (this.selectedIdList.includes(id)) {
      this.selectedIdList = this.selectedIdList.filter(item => item !== id)
    } else {
      this.selectedIdList.push(id)
    }
    this.commit()
  }

  closePop() {
    this.popVisible = false
  }

  commit() {
    this.$emit('select', [...this.selectedIdList])
  }
}
