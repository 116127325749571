




















import { Component, Vue, Prop, Watch, State } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'

@Component
export default class Index extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: () => {} }) item!: any
  @Prop({ default: false }) showStatement!: boolean
  @Prop({ default: false }) showText!: boolean
  @Prop({ default: '16' }) iconSize!: string

  formatLegends: any = []

  get isDesktop() {
    return this.platform === 'desktop'
  }

  @Watch('item', { immediate: true })
  onItemChange(val: any) {
    if (val && !isEmpty(val)) {
      const lengendKeys = val?.legend_keys || []
      if (Array.isArray(lengendKeys) && lengendKeys?.length) {
        const LengendsMap = this.getLegendsMap(this.legends)
        const includeKey = this.formatLegends.map((item: any) => item.key)

        for (const cur of lengendKeys) {
          LengendsMap[cur] && !includeKey.includes(cur) && this.formatLegends.push(LengendsMap[cur])
        }
      } else {
        this.formatLegends = []
      }
    } else {
      this.formatLegends = this.legends || []
    }
  }

  getLegendsMap(val: any) {
    return val.reduce((p: any, o: any) => {
      const { key, color, desc, text } = o
      p[key] = {
        color,
        key,
        desc,
        text
      }
      return p
    }, {})
  }

  handleClick() {
    if (this.isDesktop || !this.showStatement || !this.showText) {
      return
    }
    const { desc = '', text = '' } = this.legends?.[0] || {}
    this.$emit('showStatement', { desc, title: text })
  }
}
