





















































import { Component, Vue, Prop, Watch, Ref, State } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { Schedule } from './types'
import CalenderTips from './calendar-tips.vue'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import { getAvilableDateRange, getLocalDate } from '~/components/experience-booking/experience-activity/package-options/activity-calendar/utils/date-picker'

@Component({
  components: {
    CalenderTips,
    CalendarLengends
  }
})
export default class ActivityDataPicker extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: false }) datePickerVisible!: boolean
  @Prop({ default: '' }) value!: string
  @Prop() schedules!: Schedule[]
  @Prop({ default: true }) tipsVisible!: boolean
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: false }) doublePanel!: boolean
  @Prop({ default: true }) allowTracker!: boolean
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: false }) verticalScroll!: boolean
  @Ref() datePicker!: any
  @Prop({ default: false }) isPackageSchedule!: boolean
  // pass预约页面不需要
  @Prop({ default: false }) isPassReserve!: boolean
  @Prop() packageId!: number
  @Prop({ default: 0 }) calendarDays!: number

  @Watch('datePickerVisible', { immediate: true })
  visibleChange(val: boolean) {
    if (val && process.client) {
      this.$nextTick(() => {
        const selectedElement = this?.$el?.querySelector('.klk-date-picker-date.klk-date-picker-date-selected')
        this.scrollIntoView(selectedElement)

        if (!selectedElement) {
          const availableElement = this?.$el?.querySelector('.klk-date-picker-date:not(.klk-date-picker-date-disabled):not(.klk-date-picker-date-empty)')
          this.scrollIntoView(availableElement)
        }
      })
    }
  }

  @Watch('value', { immediate: true })
  onPackageSelectedDateChanged(val: string = '', oldValue: string = '') {
    if (this.datePicker) {
      const date = val ? this.getLocalDate(val) : this.minDate
      this.setViewDate(date)
    }

    if (val !== oldValue) {
      this.handleChange(val)
    }
  }

  @Watch('minDate', { immediate: true })
  minDateChanged(_: string) {
    this.$nextTick(() => {
      if (this.datePicker && !this.value) {
        this.setViewDate(this.defaultViewDate)
        const t = setTimeout(() => {
          this.setViewDate(this.defaultViewDate)
          clearTimeout(t)
        }, 700)
      }
    })
  }

  get date() {
    return this.value ? this.getLocalDate(this.value) : null
  }

  get schedulesMap(): { [prop: string]: Schedule } {
    return this.schedules?.reduce((acc, v) => ({ ...acc, [v.date]: v }), {})
  }

  get hasLegend() {
    return this.schedules.some((item: any) => item.legend_keys?.length > 0)
  }

  get showBestPriceInfo() {
    return !this.isPackageSchedule || !this.packageId
  }

  setViewDate(val: string | Date) {
    if (!this.doublePanel) {
      this.datePicker && this.datePicker.setViewDate(val)
    }
  }

  pageSpm: string = ''

  inhouseTrack(d: string) {
    if (!this.pageSpm) { return }

    const elem = this.$el.querySelector('#ihouseItem')
    this.$inhouse.updateBinding(elem, {
      ext: JSON.stringify({
        Date: d
      })
    })

    setTimeout(() => {
      this.$inhouse.track('action', elem)
    }, 20)
  }

  scrollIntoView(ele: Element | undefined | null) {
    if (ele) {
      ele.scrollIntoView({
        block: 'start',
        inline: 'start'
      })
    }
  }

  getLocalDate(day?: string) {
    const localeDate = dayjs(day || undefined).toString()
    return new Date(localeDate)
  }

  get defaultViewDate() {
    const item: any = this.schedules?.find((obj: any) => {
      return obj?.time_slots?.some((o2: any) => {
        return o2?.stock
      })
    })
    return item?.date ? this.getLocalDate(item?.date) : this.minDate
  }

  get avilableDateRange() {
    if (this.schedules.length === 0 && this.isPassReserve) {
      return {
        minDate: getLocalDate(dayjs()),
        maxDate: getLocalDate(dayjs().endOf('year'))
      }
    }

    return getAvilableDateRange({
      isPackageSchedule: this.isPackageSchedule,
      packageId: this.packageId,
      schedules: this.schedules,
      calendarDays: this.calendarDays
    })
  }

  get minDate() {
    const { minDate } = this.avilableDateRange
    return minDate
  }

  get maxDate() {
    const { maxDate } = this.avilableDateRange
    return maxDate
  }

  isSoldout(d: string) {
    const date = this.getFormatDate(d)
    const schedule: any = this.schedulesMap[date]

    // pass 预约做了优化，如果timeSlots长度为1，stock为0，表示售磬
    if (this.isPassReserve && schedule) {
      const timeSlots = schedule.time_slots
      const result = timeSlots && timeSlots.length === 1 && timeSlots[0].stock === 0
      return schedule.soldout || result
    }

    // 这里如果选不到日期是disabled，不当做售罄处理
    if (schedule) {
      return schedule.soldout
    }
  }

  formatDay(d: string) {
    return dayjs(d).format('D')
  }

  getFormatDate(d: string) {
    return dayjs(d).format('YYYY-MM-DD')
  }

  getPrice(d: string) {
    return this.getSchedule(d)?.selling_price
  }

  showItemLegend(item: any) {
    return !!item?.legend_keys?.length
  }

  getSchedule(d: string) {
    const date = this.getFormatDate(d)
    return this.schedulesMap[date]
  }

  shouldDateDisabled(d: string) {
    const date = this.getFormatDate(d)
    return !this.getSchedule(d) || this.isSoldout(date)
  }

  handleChange(d: string) {
    const date = d ? this.getFormatDate(d) : ''

    const schedule = this.schedulesMap[date]
    this.$emit('input', date, schedule)
    this.$emit('change', date, schedule)
  }

  handleDateChange(d: string) {
    this.handleChange(d)

    const picker = this.datePicker?.$el as HTMLElement

    const date = d ? this.getFormatDate(d) : ''

    if (this.allowTracker && picker) {
      this.inhouseTrack(date)
    } else {
      this.$emit('inhouse-track', d ? this.getFormatDate(d) : '')
    }
  }

  mounted() {
    const ihtString = document.body.dataset?.spmPage
    const pageSpm = ihtString?.split('?')?.[0] ?? ''
    this.pageSpm = pageSpm + '?trg=manual'
  }
}
