






























import { Component, State, Prop, Watch, Vue } from 'nuxt-property-decorator'

import ExperienceModal from '~/components/common/experience-modal/mobile.vue'
import DataLoader from '~/components/experience-booking/experience-activity/package-options/attraction-preview/components/attraction-preview-detail/loaded-data.vue'
import AttractionIncludedDetail from '~/components/experience-booking/experience-activity/package-options/attraction-preview/components/attraction-preview-detail/index.vue'

@Component({
  components: {
    ExperienceModal,
    DataLoader,
    AttractionIncludedDetail
  }
})
export default class AttractionIncludedPop extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  @Prop({ default: false }) visible!: boolean
  @Prop() activityId!: number
  @Prop() standardActivityId!: number
  @Prop() title!: string
  @Prop() packageId!: number
  @Prop() groupId!: number

  shouldInit = false

  get showBack() {
    return this.showInfo
  }

  get showInfo() {
    return this.standardActivityId > 0
  }

  get popVisible() {
    return this.visible
  }

  set popVisible(val: boolean) {
    this.$emit('update:visible', val)
  }

  @Watch('visible')
  visibleChange(val: boolean) {
    if (val) {
      this.shouldInit = true
      this.sendPageView()
    }
  }

  headerLeftClick() {
    this.close()
  }

  close() {
    this.$emit('closeModal')
  }

  sendPageView() {
    if (process.client) {
      setTimeout(() => this.$inhouse.track('pageview', this.$el, { force: true }), 100)
    }
  }
}
