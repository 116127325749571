











import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import DefaultSectionHeader from '~/components/setion/experience/components/header/header.vue'
import ExpInternalLink from '~/components/experience/internal-link/index.vue'

@Component({
  components: {
    ExpInternalLink,
    DefaultSectionHeader
  }
})
export default class ActivitySeoLink extends BusinessBase {
  @State klook!: Data.Klook
  static displayName = 'Internal_link'

  get calcInternalLink() {
    const { businessData, activitySeoLink } = this
    const obj = {
      title: businessData.title,
      seo_static_link: activitySeoLink
    }
    return obj
  }

  get activitySeoLink() {
    return this.businessData.internal_link
  }
}
