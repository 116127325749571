











import { Component, Vue, Prop, Watch, State } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'
import { PassSkeleton } from '~/components/common/skeleton-data/index.js'

@Component
export default class DataLoader extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform;
  @Prop() activityId!: number;
  @Prop() standardActivityId!: number;
  @Prop() packageId!: number;
  @Prop() groupId!: number;

  loading = false;
  data = null;

  PassSkeleton = PassSkeleton

  get skeletonData() {
    const { PassSkeleton, platform } = this
    return platform === 'desktop' ? PassSkeleton.desktop.detail : PassSkeleton.mobile.detail
  }

  @Watch('standardActivityId', { immediate: true })
  activityIdChange() {
    this.getActivityInfo()
  }

  @Watch('loading')
  scrollTop() {
    if (this.loading && this.platform === 'desktop') {
      // desktop 需要滚动
      const scrollContainer = document.getElementsByClassName('attraction-preview-right')?.[0]
      if (scrollContainer) {
        scrollContainer.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }

  async getActivityInfo() {
    this.loading = true
    const res = await this.$axios.$get(apis.getPassStandardActivityDetailNew, {
      params: {
        pass_activity_id: this.activityId,
        standard_activity_id: this.standardActivityId,
        pass_package_id: this.packageId,
        group_id: this.groupId
      }
    })
    this.loading = false

    if (res.success) {
      this.data = res.result
    }
  }
}
