

















import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class LineUpStatus extends Vue {
  @Prop({ default: '' }) timeCount!: string
  @Prop({ default: false }) isMobile!: boolean
}
