












import { Vue, Component, Prop, State } from 'nuxt-property-decorator'

@Component
export default class Index extends Vue {
  @State klook!: Data.Klook

  @Prop() units!: any[]

  getUnitPrice(unit: any) {
    const str = unit?.price_show?.sale_price || unit?.price_show?.from_price || 0
    return str
  }
}
