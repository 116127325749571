




















































import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import ViewMore from './view-more.vue'
import { renderMarkdownObj } from '~/assets/scripts/markdown'
import DynamicPlainMarkdown from '~/components/experience-booking/experience-activity/dynamic-component/markdown/dynamic-plain-markdown.vue'
import DynamicImage from '~/components/experience-booking/experience-activity/dynamic-component/image/index.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { getModalConfig } from '~/components/experience/load-hander/index.vue'

@Component({
  components: {
    ViewMore,
    DynamicImage,
    DynamicPlainMarkdown
  }
})
export default class tAndA extends Vue {
  @Prop() components!: ExperienceActivity.DynamicComponent
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() activityId!: number | string

  modalObj = getModalConfig('common-medium-config', { scrollable: true })

  visible = false
  viewMoreVisible: boolean = false
  // foldHeight = 88

  get isDesktop() {
    return this.platform === 'desktop'
  }

  get foldHeight() {
    return this.isDesktop ? 120 : 88
  }

  get iconSize() {
    return this.isDesktop ? 72 : 40
  }

  get cClass() {
    return `exp-highlights_${this.platform}`
  }

  get title() {
    for (const component of this.filteredComponents) {
      const style = component?.style as any
      if (style?.fold_title) {
        return style?.fold_title
      }
    }
    return this.$t('1315')
  }

  get filteredComponents() {
    return this.components.filter(component => ['markdown', 'image'].includes(component?.type))
  }

  getComponentName(type: string) {
    const o: any = {
      markdown: 'DynamicPlainMarkdown',
      image: 'DynamicImage'
    }
    return o[type] || ''
  }

  getContent(item: any) {
    if (item?.type !== 'markdown') {
      return item
    }

    const markdownContent = item?.data

    if (!markdownContent || !markdownContent.length) {
      return []
    }

    return markdownContent.map((v: any) => {
      return renderMarkdownObj(v.render_object)
    })
  }

  close() {
    this.visible = false
  }

  viewMore() {
    this.visible = true
  }

  handleViewMore() {
    if (this.viewMoreVisible && !this.isDesktop) {
      this.viewMore()
    }
  }

  initViewMoreVisible() {
    if (process.client) {
      const h = (this.$refs.highlightsWrap as Element)?.scrollHeight
      if (h >= this.foldHeight) {
        this.viewMoreVisible = true
      }
    }
  }

  async mounted() {
    this.initViewMoreVisible()
    await null
  }
}
