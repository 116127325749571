








import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { LowestRateItem } from './mixin'

@Component
export default class ActivityHotelRateItem extends Vue {
  @Prop({ type: Object, default: null }) item!: LowestRateItem
}
