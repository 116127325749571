




















































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import CalendarPopup from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/calendar-popup.vue'
import DesktopCalendar from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/desktop-calendar.vue'
import { CardSwiper } from '~/components/traveller/home-page/card-swiper'
import { getStandardDateFormat } from '~/share/data/datetime'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

export interface Schedule {
  selling_price: number,
  soldout: boolean,
  date: string,
  stocks: {
    from_price: string,
    market_price: string,
    package_id: number,
    selling_price: string,
    stock: number,
    to_price: string
  }[]
  disable?: boolean,
}

export interface CellDateUnit {
  name: string,
  date: string
}

@Component({
  components: {
    DesktopCalendar,
    CalendarPopup,
    CardSwiper
  }
})
export default class PackageOptionsCalendar extends Vue {
  @State klook!: Data.Klook
  @Prop() schedules!: Schedule[]
  @Prop() value!: string
  @Prop() warn!: string

  visible = false
  calendarDate: null | string = null

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get cellDateList() {
    return this.availableDateList.filter((_, i) => i < 3)
  }

  get availableDateList() {
    const res: CellDateUnit[] = [
      {
        name: this.$t('today'),
        date: dayjs().format('YYYY-MM-DD')
      },
      {
        name: this.$t('search_tomorrow'),
        date: dayjs().add(1, 'day').format('YYYY-MM-DD')
      }
    ]

    const availableArr: any[] = (this.schedules || []).filter((o: any) => {
      o.name = this.formatDate(o.date)
      return !o.soldout && !o.disable
    })
    res.forEach((o: CellDateUnit) => {
      const item = availableArr.find((item: any) => {
        return item.date === o.date
      })
      if (item) {
        item.name = o.name
      }
    })
    return availableArr || []
  }

  showDatePicker() {
    this.visible = true
  }

  handleClick(d: string) {
    const date = !d || d === this.value ? null : d
    this.handleDateChange(date)
  }

  handleCalendarDateCancel() {
    this.calendarDate = null
    this.$emit('input', null)
  }

  handleDateChange(d: string | null) {
    const target = this.cellDateList.find((item: CellDateUnit) => item.date === d)
    if (target) {
      this.calendarDate = null
    } else if (!target && d) {
      this.calendarDate = d
    }
    this.$emit('input', d)
  }

  formatDate(d: string) {
    return getStandardDateFormat(
      d,
      this.$t.bind(this),
      this.klook.language,
      3
    )
  }
}
