

















































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import LineUpBottomBar from '../line-up-bottom-bar/index.vue'
import { EasyIntersectionObserver, formatThousands } from '~/share/utils'
import { currencySymbolMap } from '~/share/data/currency'
import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2'

@Component({
  components: {
    LineUpBottomBar
  }
})
export default class BottomBar extends Vue {
  @State klook!: Data.Klook
  @Prop() target!: string
  @Prop() sellingPrice!: string
  @Prop() marketPrice!: string
  @Prop() content!: string
  @Prop() isInPromotion!: boolean
  @Prop({ default: 0 }) threshold!: number | number[]
  // 排队
  @Prop({ default: () => {} }) basicData!: any

  get lineUp() {
    return this.basicData.is_in_flash_sale
  }

  get lineUpBook() {
    return this.basicData.is_line_page
  }

  obServer: EasyIntersectionObserver | null = null

  visible = false
  isIconUp = false

  get displaySellingPrice() {
    if (!this.sellingPrice) {
      return ''
    }

    if (isNaN(Number(this.sellingPrice))) {
      return this.sellingPrice
    }

    return `${currencySymbolMap[this.klook.currency]}${formatThousands(this.sellingPrice)}`
  }

  mounted() {
    this.obServer = new EasyIntersectionObserver(this.target, ([{ isIntersecting, boundingClientRect }]) => {
      this.visible = !isIntersecting

      if (this.visible) {
        const { top } = boundingClientRect
        // top比较小说明在底部相交
        this.isIconUp = top < 0

        document.body.style.paddingBottom = '60px'
      }
    }, {
      threshold: this.threshold
    })
  }

  beforeDestroy() {
    if (this.obServer) {
      this.obServer.disconnect()
      this.obServer = null
    }
  }

  showTarget() {
    this.$emit('click-btn', this.isIconUp)
    setTimeout(() => {
      const packageOptions = document.querySelector(this.target)
      const t = nodeScrollTop(packageOptions)
      scroll2({ scrollY: t - 60 })
    }, 300)
  }
}
