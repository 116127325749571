































































import { Component, State, namespace, mixins } from 'nuxt-property-decorator'
// import apis from '~/share/data/apis'
// import { getTaggingExtraTrackObj } from '~/components/experience/tagging/index.vue'
import PackageCardList from '~/components/experience-booking/experience-activity/package-options/desktop/card-sku-with-detail/pkg-list.vue'
import OpenDateTips from '~/components/experience-booking/experience-activity/open-date-ticket/open-date-tips/index.vue'
import PackageOptionsCalendarWrap from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/calendar/index.vue'
import ExpPkgDetailLoading from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/pkg-detail/loading.vue'
import CommonContentLoading from '~/components/experience-booking/activity/package-detail/status/common-content-loading.vue'
import ExpPkgItem from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/pkg-item.vue'
import PackageOptionsBooking from '~/components/experience-booking/experience-activity/package-options/desktop/card-sku-with-detail/pkg-options-booking.vue'
import eventBus from '~/pages/experience/pay/common/event-bus'
import NoneFilterResult from '~/components/experience-booking/experience-activity/package-options/attraction-included/components/none-filter-result.vue'
import ShoppingCart from '~/components/experience-booking/experience-activity/activity-shopping-cart/index.vue'
// import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2'
// import PackageOptionsSkuBase from '~/components/booking-core/mixins/entry/index-section-sku'
import BookingCoreMixin from '~/components/booking-core/mixins/entry/index-base'
import BookingTrackMixin from '~/components/booking-core/track/index'
import AutoBookingMixin from '~/components/booking-core/mixins/common/auto-booking'
import dataUtils from '~/components/booking-core/utils/data-utils'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    CommonContentLoading,
    ShoppingCart,
    NoneFilterResult,
    ExpPkgDetailLoading,
    PackageOptionsCalendarWrap,
    OpenDateTips,
    ExpPkgItem,
    PackageOptionsBooking,
    PackageCardList
  }
})
export default class DesktopPackageOptionSkuDetail extends mixins(
  BookingCoreMixin,
  BookingTrackMixin,
  AutoBookingMixin
) {
  @ActivityModule.State tagFilter2state!: any
  @ActivityModule.State activityDetail!: any
  @State klook!: Data.Klook

  handleScrollTo(element: any = null) {
    setTimeout(() => {
      const main = document.querySelector('.pkg-detail-wrap .package-detail-content') as HTMLElement
      const elem = main.querySelector(element) as HTMLElement
      if (elem) {
        const offsetTop = elem.offsetTop - 180
        main.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        })
      }
    }, 400)
  }

  // to do
  date: string = ''
  pkgsCacheData: any = {}
  pkgShowMore = {
    init: false,
    len: 8,
    show: false
  }

  get filteredPackageIdList() {
    const { packageList } = this

    return packageList.map((pkg) => pkg.package_id)
  }

  get calcFilterPackagesWithSku() {
    const {
      tagFilter2state: { value: tagFilterValue },
      packageList,
      packagesWithSku,
      packagesWithSkuByDate
    } = this
    let skuArr = packagesWithSkuByDate || packagesWithSku || []
    if (tagFilterValue) {
      skuArr = skuArr.filter((o: any) => {
        const obj = packageList.find((pkg: any) => o.package_id === pkg.package_id)
        const filterTags = obj?.product_tags?.filter_tags
        return filterTags && filterTags.find((tag: any) => tag?.tag_key === tagFilterValue)
      })
    }
    return skuArr
  }

  get showCalendar() {
    return this.packageList?.length > 1
  }

  get bookingCore() {
    return {
      packages: this.packageList,
      date_description: this.dateDescription,
      activity_id: this.activityId,
      template_id: this.templateId,
      is_support_shopping_cart: this.isSupportShoppingCart,
      activity_ticket_type: this.activityTicketType
    }
  }

  pkgDetailObj: any = {
    visible: false,
    direction: 'right',
    pkgData: null
  }

  setPkgDetailStatus(pkgData: any, isb: boolean) {
    this.$set(this.pkgDetailObj, 'pkgData', pkgData)
    this.pkgDetailObj.visible = isb
  }

  getPkgCacheDetail(packageId: number) {
    if (!packageId) {
      return {}
    }
    const cacheDetail = this.pkgsCacheData[packageId] || {}
    return cacheDetail
  }

  getPkgCacheData(key: string, pkgData: any) {
    if (!key) {
      return
    }
    const { package_id } = pkgData || {}
    const cacheData = this.pkgsCacheData[package_id] || {}
    return cacheData[key]
  }

  setPkgCacheData(key: string, pid: any, data: any) {
    if (!key || !pid) {
      return
    }
    const cacheData = this.pkgsCacheData[pid]
    if (!cacheData) {
      this.$set(this.pkgsCacheData, pid, {
        [key]: data
      })
    } else {
      this.$set(cacheData, key, data)
    }
    return data
  }

  updatePkgDetail(pkgData: any, pkgDetail: any) {
    const { package_id } = pkgData
    const cacheData = this.pkgsCacheData[package_id]
    if (!cacheData) {
      this.$set(this.pkgsCacheData, package_id, {
        pkgDetailData: pkgDetail
      })
    } else {
      this.$set(cacheData, 'pkgDetailData', pkgDetail)
    }
  }

  updatePkgId(pid: number) {
    this.updateExpPackageId(pid)
  }

  async mounted() {
    setTimeout(() => {
      eventBus.$emit('setIsDelayFlag2bus', { from: 'unfold_card_mounted' })
    }, 60)
    await this.getActivitySchedulesApiData(this.calcActSchedulesParams)
    this.initAutoBookingMinxin({
      activityId: this.activityId,
      shopId: parseInt(this.$route.query.shop_id as string),
      shoppingCartHandler: this.showShoppingCart
    })
    // const { query } = this.$route
    // const { shop_id: shopId } = query
    // if (shopId) {
    //   const id: number = await this.getShoppingCartList({ shopId })
    //   if (id) {
    //     this.packageId = id
    //   }
    // }
    // const scrollPkg = parseInt(query?.scroll_pkg as string) || 0
    // scrollPkg && this.clickPackageHandler({ package_id: scrollPkg }, true)
  }

  packagesWithSkuByDate: any = null // any[]

  filterSkuPackagesHandler(list: any[]) {
    this.$set(this, 'packagesWithSkuByDate', list || [])
  }

  get bookingCoreData2section() {
    const restBookingCore = dataUtils.getBookingCoreData2vm(this)
    restBookingCore.packagesWithSku = this.calcFilterPackagesWithSku
    return {
      ...restBookingCore,
      packageId: undefined
    }
  }
}
