









import { Component, Vue } from 'nuxt-property-decorator'
import PackageDetailMain from '../package-detail-main.vue'

@Component({
  components: {
    PackageDetailMain
  }
})
export default class PackageDetailSuccess extends Vue {
  mounted() {
    const elem = this.$el as any
    this.$emit('mounted', elem.offsetHeight)
  }
}
