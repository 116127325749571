














import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class LayerHeader extends Vue {
  @Prop() title !: string
  @Prop({ type: String, default: 'mobile-activity#icon-close' }) icon !: string

  close() {
    this.$emit('close')
  }
}
