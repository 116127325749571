import isEmpty from 'lodash/isEmpty'

export function getTrackIdAndKey(list: any[]) {
  const obj: {
    keysList: String[]
    idsList: Number[]
  } = {
    keysList: [],
    idsList: []
  }

  if (!list?.length) {
    return obj
  }

  list.forEach((item: any) => {
    const track = item?.track || {}
    if (!isEmpty(track)) {
      const { tag_id, tag_key } = track
      tag_id && obj.idsList.push(tag_id)
      tag_key && obj.keysList.push(tag_key)
    }
  })

  return obj
}

export function getInhouseExtDataByTags(prop: any) {
  const { discount_tags = [], attribute_tags = [], campaign_tags = [] } = prop || {}
  const tagMaps = [
    {
      key: 'Discount',
      val: discount_tags
    },
    {
      key: 'Attribute',
      val: attribute_tags
    },
    {
      key: 'Campaign',
      val: campaign_tags
    }
  ]

  tagMaps.forEach((item: any) => {
    if (item.val?.length) {
      const { key, val } = item
      const { keysList = [], idsList = [] } = getTrackIdAndKey(val)
      composeInhouseExt(prop, key, idsList, keysList)
    }
  })

  deleteUselessAttr(prop)
}

function deleteUselessAttr(prop: any) {
  ;['attribute_tags', 'discount_tags', 'campaign_tags'].forEach((key) => {
    prop[key] && delete prop[key]
  })
}

export function composeInhouseExt(target: any, prefix: string, idsList: Number[], keysList: String[]) {
  const idSuffix = 'TagIDList'
  const keySuffix = 'TagKeyList'
  const idAttr = getKey(prefix, idSuffix)
  const keyAttr = getKey(prefix, keySuffix)
  target[idAttr] = idsList
  target[keyAttr] = keysList
}

function getKey(prefix: string, suffix: string) {
  return prefix + suffix
}
