
















































































































































































































import { Component, mixins } from 'nuxt-property-decorator'
import PackageOptionsContent
  from '~/components/traveller/activity/activity-package-options/desktop/package-options/package-options-content.vue'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'
import PackageOptionsWarning
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-options-warning.vue'
import ActivityDatePickerDesktop
  from '~/components/experience-booking/experience-activity/package-options/activity-calendar/desktop/index.vue'
import PackageOptionsTime
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-options-time.vue'
import ActivityPromotionInfoDesktop
  from '~/components/experience-booking/experience-activity/package-options/package-promotion/desktop/index.vue'
import BookingOptionsUnitsTips
  from '~/components/traveller/activity/activity-package-options/desktop/package-options/package-options-tips.vue'
import PackageOptionsCounterNew
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-options-counter-new.vue'
import PackageAttrDesktop
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-attr.vue'
import PackageOptionsBtnGroup
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-options-btnGroup.vue'
import ActivityPackageDetail
  from '~/components/traveller/activity/activity-package-options/desktop/package-detail/index.vue'
import NoneFilterResult from '~/components/experience-booking/experience-activity/package-options/attraction-included/components/none-filter-result.vue'
import OpenDateTips from '~/components/experience-booking/experience-activity/open-date-ticket/open-date-tips/index.vue'
import PriceDiscountTips from '~/components/experience/price-slot/discount-tips.vue'
import { getTaggingTrackObj } from '~/components/experience/tagging/index.vue'
import DesktopBookingCoreMixin from '~/components/booking-core/mixins/entry/index-base-desktop'
import BookingTrackMixin from '~/components/booking-core/track/index'
import StructuredPkgDetailMixin from '~/components/booking-core/mixins/common/structured-pkg-detail'
import newPackageDetailShowMixin from '~/components/common/package-detail-v2/show-mixin'

@Component({
  components: {
    PriceDiscountTips,
    OpenDateTips,
    PackageOptionsContent,
    ActivityDatePickerDesktop,
    PackageOptionsTime,
    ActivityPromotionInfoDesktop,
    BookingOptionsUnitsTips,
    PackageOptionsCounterNew,
    PackageAttrDesktop,
    PackageOptionsBtnGroup,
    ActivityPackageDetail,
    NoneFilterResult,
    PackageOptionsWarning,
    WarmingSlogan,
    HeroFilter: () => import('~/components/common/hero-filter/desktop/index.vue')
  }
})

export default class DesktopPackageOptions extends mixins(DesktopBookingCoreMixin, BookingTrackMixin, StructuredPkgDetailMixin, newPackageDetailShowMixin) {
  get calcTaggingTrack() {
    const track = { spm: 'Package_Discount_Section', action: true }
    const list = this.calcPkgSelectedObj?.product_tags?.discount_tags
    const obj = getTaggingTrackObj({
      track,
      list,
      pkg: this.calcPkgSelectedObj
    })
    return obj
  }

  clearAll() {
    this.pkgSelectedDate = ''
    this.chosenAttrIds = []
    this.setExpPkgSelectedTime()
    this.updateExpPackageId()
  }

  get pkgDetailObj() {
    return this.showStructuredPackageDetail ? this.calcStructuredPkgDetailObj : this.calcPkgDetailObj || {}
  }

  get fetchPackageDetailStatus() {
    const pkgDetailApiDefined = this.showStructuredPackageDetail ? this.structuredPackageDetailApiDefined : this.packageDetailApiDefined
    return pkgDetailApiDefined?.reloadConfig?.state
  }
}
