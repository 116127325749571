

























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import AttrDetail from './attr-detail.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    AttrDetail
  }
})
export default class SpecTitle extends Vue {
  @Prop() spec !: ExperienceActivity.ISpec
  @Prop() title!: string

  visible = false

  get btnVisible() {
    return !this.spec.text_type && this.spec.attr.find(v => v.tips)
  }

  handleSelect(attr: any) {
    this.$emit('select', attr, this.spec)
    this.handleClose()
  }

  handleDetailVisible() {
    this.visible = true
  }

  handleClose() {
    this.visible = false
  }
}
