











import { Component, Watch } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import ExpTagging from '~/components/experience/tagging/index.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    ExpTagging
  }
})
export default class Index extends BusinessBase {
  static displayName = 'Activity_attribute_tags'

  get calcTags2attribute() {
    // desktop/mweb活动页-商品属性标签
    return this.businessData?.attribute_tags || []
  }

  get visible() {
    return this.calcTags2attribute?.length > 0
  }

  @Watch('visible', { immediate: true })
  visibleChange(val: boolean) {
    if (!val) {
      this.hideSection()
    }
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }
}
