import { Component, mixins, namespace } from 'nuxt-property-decorator'
import { merge } from 'lodash'
import dataUtils from '~/components/booking-core/utils/data-utils'
import dataTypesUtils from '~/components/booking-core/utils/types'
import BaseExtraMixin from '~/components/booking-core/mixins/common/base-extra'
import CalendarMixin from '~/components/booking-core/mixins/common/calendar'
import HeroFilterMixin from '~/components/booking-core/mixins/common/hero-filter'
import MethodsMixin from '~/components/booking-core/mixins/common/methods'
import PriceCheckMixin from '~/components/booking-core/mixins/common/price-check'

const ActivityModule = namespace('traveller/activity')
const AuthModule = namespace('auth')

@Component({
  components: {
    //
  }
})
export default class Index extends mixins(
  BaseExtraMixin,
  MethodsMixin,
  CalendarMixin,
  HeroFilterMixin,
  PriceCheckMixin
) {
  @AuthModule.State isLoggedIn!: boolean
  @ActivityModule.Getter preSettlementTips2getters!: any
  @ActivityModule.Action getPreSettlementApiData!: Function
  showFilterNone = false
  showTagFilterNone = false
  currentUnits: any[] = []

  initQueryData(options?: any) {
    const defOptions = {
      // isPackageSchedule: false, // 默认使用活动日历数据，暂不支持此场景(需要优先判断活动日历，在判断套餐日历，否则套餐日历接口pid不存在)
      pidKey: 'packageId',
      dateKey: 'pkgSelectedDate'
    }
    const { isPackageSchedule, pidKey, dateKey } = merge({}, defOptions, options || {})
    const { participationDate: date, calcActScheduleList, calcPkgScheduleList } = this
    const vm: any = this
    const pid = vm[pidKey]
    const schedules = isPackageSchedule ? calcPkgScheduleList : calcActScheduleList
    const checkData = this.getParticipationDataAndPidByActStock({ date, pid, schedules }, { isDateHeightLevel: this.$route.query.priority_level === 'date', stocksKey: isPackageSchedule ? 'time_slots' : 'stocks' })

    if (pid && this.readonlyInitPid) {
      this.updateExpPackageId(checkData.pid)
    }
    date && (vm[dateKey] = checkData.date)
  }

  toggleFilterNone(val: boolean) {
    this.showFilterNone = false

    // tag 筛选复用这个方法(hero page 专用)
    this.showTagFilterNone = !!val && this.tagFilterList?.length > 0
  }

  // 活动层级数据 start
  get calcActInDatePacakges() {
    return dataUtils.getActInDatePackages(this.calcActScheduleObj, this.calcActValidPkgList)
  }
  // 活动层级数据 end

  get calcActScheduleObj() {
    const { pkgSelectedDate: date, calcActScheduleList } = this
    if (!date) {
      return null
    } else {
      return calcActScheduleList.find((o: any) => o.date === date)
    }
  }

  get calcScheduleList() {
    const { calcArrangementId, calcPkgScheduleList, calcActScheduleList } = this
    const arr = calcArrangementId ? calcPkgScheduleList : calcActScheduleList
    return arr
  }

  // 套餐层级数据 start
  get calcPkgUnitsObj() {
    if (this.calcArrangementId) {
      const { apiData } = this.packageUnitsApiDefined
      return apiData?.result || {}
    } else {
      const { apiData } = this.packageSchedulesAndUnitsApiDefined
      return apiData?.result || {}
    }
  }
  // 套餐层级数据 end

  // unit层级数据 start
  get calcTotalUnitCount() {
    // 所有已选的预定unit的数量
    return dataUtils.getExpTotalUnitCount(this.currentUnits)
  }

  get calcUnitInventorieObj() {
    return this.calcPkgUnitsObj?.inventories || {}
  }

  async changeExpUnitCount({ priceId, type, currentSelectedPackage, hideLoading, passPreSettlementApi, closeAttractionPop, unitList }: dataTypesUtils.ChangeExpUnitCountIf) {
    const { calcArrangementId } = this
    if (!unitList?.length) {
      return
    }
    const unit = unitList.find((v: any) => v.priceId === priceId)
    if (!unit) {
      return
    }
    typeof closeAttractionPop === 'function' && closeAttractionPop()
    const { count, priceMinPax, required } = unit
    if (type === 'add') {
      if (count === 0) {
        unit.count += Math.max(priceMinPax, 1)
      } else {
        unit.count++
      }
    } else {
      if (count === 0) {
        return
      }

      if (count > priceMinPax) {
        unit.count--
      } else if (!required) {
        unit.count -= priceMinPax
      }
    }
    unitList.forEach((v: any) => {
      v.requireMinPax = !v.required && v.count >= v.priceMinPax && v.priceMinPax > 1 // hack sku 增减操作
    })
    if (!passPreSettlementApi && currentSelectedPackage?.has_discount) {
      const params = {
        hideLoading,
        page_from: 2, // 调用来源，会影响展示的文案.detail:1; booking_option:2; shopping_card: 3
        arrangement_id: calcArrangementId,
        sku_list: unitList.map((o: any) => {
          return {
            sku_id: o.skuId,
            quantity: o.isSoldOut ? 0 : o.count
          }
        }),
        pid: currentSelectedPackage.package_id
      }
      const res = await this.getPreSettlementApiData(params)

      // rate plan 优惠变更提示
      if (res?.result?.rate_plan_hit_budget_not_enough_tips) {
        this.$toast(res?.result.rate_plan_hit_budget_not_enough_tips)
      }
    }
    return true
  }
  // unit层级数据 end
}
