































import { Component, Prop, namespace, State, mixins } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import PackageDetailEmpty from '~/components/experience-booking/activity/package-detail/status/package-detail-empty.vue'
import CommonContentRefresh
  from '~/components/experience-booking/activity/package-detail/status/common-content-refresh.vue'
import PackageDetailLoading from '~/components/experience-booking/activity/package-detail/package-detail-loading.vue'
import PackageDetailMain from '~/components/experience-booking/activity/package-detail/package-detail-main.vue'
import apis from '~/share/data/apis'
import { Activity } from '~/types/traveller/activity'
import PublishAi from '~/components/common/publish-ai/index.vue'
import { PackageDetailStructuredWithStatus } from '~/components/common/package-detail-v2'
import newPackageDetailShowMixin from '~/components/common/package-detail-v2/show-mixin'

import packageDetail = Activity.packageDetail
export type fetchStatus = 'success' | 'failure' | 'fetching'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    PackageDetailEmpty,
    CommonContentRefresh,
    PackageDetailLoading,
    PackageDetailMain,
    PublishAi,
    PackageDetailStructuredWithStatus
  }
})
export default class ExperienceActivityPackageDetail extends mixins(newPackageDetailShowMixin) {
  @State klook!: Data.Klook
  @ActivityModule.State activityDetail!: any
  @Prop() packageId!: number
  @Prop({ type: Boolean, default: false }) isPassActivity!: boolean
  @Prop({ default: true }) expandAll!: boolean
  @Prop({ default: false }) expandItinerarySection!: boolean
  @Prop({ default: true }) hasBottomButton!: boolean
  @Prop({ default: '' }) groupPackName!: string
  @Prop({ type: Boolean, default: false }) fetchLoading!: boolean
  @Prop({ type: Object, default: null }) cacheDetail!: any

  fetchPackageDetailStatus: fetchStatus = 'fetching'
  packageDetail: packageDetail | null = null

  get componentName() {
    const statusMap = {
      success: 'PackageDetailMain',
      failure: 'CommonContentRefresh',
      fetching: 'PackageDetailLoading'
    }

    const name = statusMap[this.fetchPackageDetailStatus]
    if (!this.packageId || !name) {
      return 'PackageDetailEmpty'
    }

    return name
  }

  get translateButtonVisible() {
    return !!this.packageDetail?.enableTranslationButton
  }

  get isTranslated() {
    return !this.packageDetail?.translationButtonStatus
  }

  get isAiTranslation() {
    // if (['ActivityHome', 'ExperienceActivityBookOptions'].includes(this.$route.name || '')) {
    //   return false
    // }

    return this.translateButtonVisible && this.isTranslated
  }

  translateAction() {
    this.getPackageDetail(!this.isTranslated)
  }

  get isLinePage() {
    const basic_data = this.activityDetail?.basic_data ?? {}
    return basic_data.is_line_page || false
  }

  async getPackageDetail(translation: any) {
    this.formatDetail({ status: 'fetching' })

    // 解决二次请求问题
    if (this.cacheDetail) {
      this.formatDetail({ status: 'success', detail: this.cacheDetail })
      return
    }

    const preview = this.$route.params.preview ? 1 : 0
    const packageId = this.packageId
    if (packageId == null) {
      return null
    }
    // 抢购加上 is_line_page 字段
    const is_line_page = this.isLinePage
    let res = null
    const params = {
      preview,
      translation: !!translation,
      package_id_list: packageId,
      publish_status: preview ? 'all' : 'viewable',
      is_line_page: !preview && is_line_page,
      page_from: this.currPkgPageFrom || ''
    }
    const url = this.showStructuredPackageDetail ? apis.getAttractionPackageDetail : apis.activityPackageDetail

    try {
      res = await this.$axios.$get(url, {
        params,
        loading: this.fetchLoading
      })
    } catch (error) {
      this.formatDetail({ status: 'failure' })
      this.$emit('getDetailSuccess', false)
      return
    }

    const { result } = res
    if (res?.success && !isEmpty(result)) {
      const detail = result[0]
      this.formatDetail({ status: 'success', detail })
      const sections = this.packageDetail?.sectionContent?.sections ?? []
      const hasItinerary = !!sections.find((item: any) => item.section_type === 'itinerary')
      this.$emit('getDetailSuccess', hasItinerary, detail)
    } else {
      this.formatDetail({ status: 'failure' })
      this.$emit('getDetailSuccess', false)
    }
  }

  formatDetail(data: { status: fetchStatus; detail?: any }) {
    const { status, detail } = data

    if (status) {
      this.fetchPackageDetailStatus = status
    }

    if (detail) {
      detail.use_section = true
      // 新样式的不要foramt
      this.packageDetail = !this.showStructuredPackageDetail ? {
        activityName: detail.activity_name,
        isPassActivity: this.isPassActivity,
        price_show: detail.price_show,
        has_discount: detail.has_discount,
        redeem_status: detail.redeem_status,
        product_tags: detail.product_tags,
        useSection: detail.use_section,
        sectionContent: detail.section_content,
        usageValidity: detail.usage_validity_render_obj,
        enableTranslationButton: detail.enable_translation_button,
        translationButtonStatus: detail.translation_status,
        packageName: this.groupPackName || detail.package_name,
        packageSubName: detail.package_sub_name,
        packageId: detail.id,
        activityId: detail.activity_id,
        passStandardActivityInfo: detail.pass_standard_activity_info,
        spec: detail.spec,
        sub_category_id: detail.sub_category_id
      } : detail
    }
  }

  mounted() {
    this.getPackageDetail(this.$route.query?.translation === '1')
  }
}
