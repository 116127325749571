























































import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import ActivityCommentItemInGallery
  from '~/components/traveller/activity/activity-reviews/desktop/activity-gallery/activity-comment-item-in-gallery.vue'
import { approximateThousandsNew } from '~/share/utils'
import Logo from '~/components/experience/card-swiper/logo.vue'
import ImageGallery from '~/components/experience-booking/experience-activity/banner/components/image-gallery/index.vue'

interface ImageItem {
  id: number
  image_id: number
  image_url: string
  resize_value: number
}

@Component({
  components: {
    ImageGallery,
    ActivityCommentItemInGallery,
    Logo
  }
})

export default class ActivityGallery extends Vue {
  @State klook!: Data.Klook
  @Prop() activityId!: number
  @Prop() getReviewsImages!: Function
  @Prop() reviewGalleryShouldShow!: boolean
  @Prop() customerImageAmount!: number
  @Prop() reviewGalleryList!: any []
  imageList: any [] = []
  reviews: any [] = []
  currentReview: any = null
  page: number = 1
  pageSize: number = 8
  total: number = 0
  galleryShow: boolean = false
  highlightSwiperSlideIndex: number = 0

  get imageCount() {
    return approximateThousandsNew(this.customerImageAmount)
  }

  async getImages() {
    const images = await this.getReviewsImages({ page: this.page, pageSize: this.pageSize })
    if (images?.imageTotalCount) {
      this.total = images.imageTotalCount
      const o = this.arrayToObj(images.reviewImagesInfo)
      this.reviews = Object.assign(this.reviews, o)
      this.imageList = this.imageList.concat(images.imageList)
      this.page = this.page + 1
    }
  }

  arrayToObj(array: any) {
    const o: any = {}
    array.forEach((item: any) => {
      o[item.review_id] = item
    })
    return o
  }

  highlightSwiperSlide(idx: number) {
    this.highlightSwiperSlideIndex = idx
  }

  showGallery(val: ImageItem) {
    this.galleryShow = true
    this.$nextTick(() => {
      let idx = this.imageList.findIndex((item: any) => {
        return item.image_id === val.image_id
      })
      if (idx === -1) {
        idx = 0
      }
      this.highlightSwiperSlide(idx)
      this.currentReview = this.reviews[this.imageList[idx].reviewId]
    })
  }

  handleBuyNow() {
    this.galleryShow = false
    this.$nextTick(() => {
      this.$emit('buy-now')
    })
  }

  mounted() {
    this.getImages()
  }
}

