














import { Component, Vue, Prop, State, Watch } from 'nuxt-property-decorator'

@Component
export default class AlertComponent extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop({ default: 'success' }) type!: 'success' | 'info' | 'warning' | 'error'
  @Prop({ default: false }) closable!: boolean
  @Prop({ default: false }) visible!: boolean
  @Prop({ default: '' }) content!: string

  @Watch('visible')
  visbileChange(newVal: boolean) {
    if (newVal) {
      this.setBoxStyle()
    }
  }

  setBoxStyle() {
    if (this.platform === 'mobile') {
      return {}
    }
    const target = document.querySelector('.layout-default_header_main') as HTMLElement
    const { left } = target.getBoundingClientRect()
    return {
      right: left + 'px'
    }
  }
}
