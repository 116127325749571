








import { Component, State, Vue, Prop } from 'nuxt-property-decorator'
import { SubSectionComponentOrderedList } from '../type'

@Component({
  components: {
    //
  }
})
export default class OrderedList extends Vue {
  @Prop({ type: Object, default: {} }) data!: SubSectionComponentOrderedList['data']
  @State klook!: Data.Klook

  get content() {
    return this.data?.content || []
  }
}
