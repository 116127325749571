















































import { Vue, Component, Prop, Watch, State } from 'nuxt-property-decorator'
import ReviewFilterSort from '~/components/experience-booking/experience-activity/review/review-filter-sort.vue'
import apis from '~/share/data/apis'

interface FilterButton {
  val: string
  name: string
  disabled: boolean
  spmName: string
}

@Component({
  components: {
    ReviewFilterSort
  }
})
export default class ReviewFilter extends Vue {
  @State klook!: Data.Klook
  @Prop() reviewGalleryShouldShow!: boolean
  @Prop() activityId!: number
  @Prop({ default: false }) hideButton!: boolean

  filterButtonList: FilterButton[] = []
  selectedList: string[] = ['all']
  sortType: number = 0

  get sortData() {
    return [{
      name: this.$t('review_filter.recommend'),
      val: 0
    }, {
      name: this.$t('review_filter.newest'),
      val: 1
    }, {
      name: this.$t('review_filter.rate_high_to_low'),
      val: 2
    }]
  }

  @Watch('reviewGalleryShouldShow', { immediate: true })
  reviewGalleryShouldShowChange(val: boolean) {
    if (!val) {
      this.filterButtonList = this.filterButtonList.filter(item => item.val !== 'img')
    }
  }

  mounted() {
    this.initFilterButtonList()
    this.getReviewFilter()
  }

  initFilterButtonList() {
    this.filterButtonList = [
      {
        name: this.$t('review_filter.all'),
        val: 'all',
        disabled: false,
        spmName: 'filter_all'
      },
      {
        name: this.$t('review_filter.only_image'),
        val: 'img',
        disabled: false,
        spmName: 'filter_photos_only'
      },
      {
        name: '4.0+',
        val: 'aboveFour',
        disabled: false,
        spmName: 'filter_4plus'
      },
      {
        name: '3.0+',
        val: 'aboveThree',
        disabled: false,
        spmName: 'filter_3plus'
      },
      {
        name: '<3.0',
        val: 'belowThree',
        disabled: false,
        spmName: 'filter_lessthan3'
      }
    ]
  }

  async handleFilterButtonClick(val: string) {
    const originalSelectedList = [...this.selectedList]

    // 只有all并且点击all
    if (originalSelectedList.length === 1 && originalSelectedList[0] === 'all' && val === 'all') {
      return
    }

    if (originalSelectedList.includes(val)) {
      // 取消选中
      this.selectedList = originalSelectedList.filter(item => item !== val)
    } else if (val === 'all') {
      // 选中 all
      this.selectedList = ['all']
    } else {
      // 选中其它
      this.selectedList = originalSelectedList.filter(item => item !== 'all')
      this.selectedList.push(val)
    }

    // 如果全部反选了，就自动选上all
    if (this.selectedList.length === 0) {
      this.selectedList = ['all']
    }

    // 只看有图发生变化，则需要请求后端刷新
    const addImg = !originalSelectedList.includes('img') && this.selectedList.includes('img')
    const deleteImage = originalSelectedList.includes('img') && !this.selectedList.includes('img')
    if (addImg || deleteImage) {
      this.$emit('getReviewFilterState', 'fetching')
      await this.getReviewFilter()
      this.$emit('getReviewFilterState', 'success')
    }

    this.onChange()
  }

  getReviewFilter() {
    return this.$axios.$get(apis.reviewFilter, {
      params:
        {
          act_id_list: this.activityId,
          only_image: !!this.selectedList.includes('img')
        }
    })
      .then((res) => {
        if (res.success && res.result) {
          const { star_count_map, star_order_list } = res.result
          const validStarList = star_order_list.filter((item: string) => !!star_count_map[item])
          this.filterButtonList = this.filterButtonList.map((item: FilterButton) => {
            const { val } = item
            const notAboveFour = !validStarList.includes('4') && !validStarList.includes('5')
            const notAboveThree = !validStarList.includes('3') && notAboveFour
            const notBelowThree = !validStarList.includes('1') && !validStarList.includes('2')
            const disabled = (val === 'aboveFour' && notAboveFour) ||
              (val === 'aboveThree' && notAboveThree) ||
              (val === 'belowThree' && notBelowThree) ||
              false
            return { ...item, disabled }
          })
          this.selectedList = this.selectedList.filter((val) => {
            const button = this.filterButtonList.find(item => item.val === val)
            return !button?.disabled
          })
        }
      })
  }

  onChange() {
    const starList = []
    if (this.selectedList.includes('belowThree')) {
      starList.push(...[1, 2])
    }
    if (this.selectedList.includes('aboveThree')) {
      starList.push(...[3, 4, 5])
    }
    if (this.selectedList.includes('aboveFour')) {
      starList.push(...[4, 5])
    }
    if (this.selectedList.includes('all')) {
      starList.push(...[1, 2, 3, 4, 5])
    }
    this.$emit('filter-change', {
      star_num: Array.from(new Set(starList)).sort((a, b) => a - b),
      lang: '',
      sort_type: this.sortType,
      only_image: !!this.selectedList.includes('img')
    })
  }
}
