











































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { IconTriangleUp } from '@klook/klook-icons'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { clearImgSpecialCharacters, getImageInfo } from '~/share/data/image'
import SeoLazyImage
  from '~/components/experience-booking/experience-activity/dynamic-component/image/seo-lazy-image.vue'

interface LongImageData {
  prefix: string
  pre_process: string
  suffix: string
}

@Component({
  components: {
    SeoLazyImage,
    IconTriangleUp
  }
})
export default class DynamicMap extends Vue {
  @State klook!: Data.Klook
  @Prop() content!: ExperienceActivity.IDynamicImages
  @Prop({ type: Number, default: 800 }) baseWidth!: number

  get images() {
    return this.content?.data || []
  }

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  formatLongImageUrl(data: LongImageData) {
    const { prefix, pre_process, suffix } = data
    return {
      ...data,
      image_url: `${prefix}${pre_process}${suffix}`
    }
  }

  imageUrl(val: ExperienceActivity.IDynamicImageData) {
    if (!val || !val.image_url) {
      return ''
    }

    if (!this.isOfficialCdnImage(val.image_url)) {
      return val.image_url
    }

    return this.formatImage(val)
  }

  formatImage(val: ExperienceActivity.IDynamicImageData) {
    const { width, height } = this.formatSize(val.width, val.height)
    const { firstPath, secondPath, fileName, imageType } = this.urlToObject(val.image_url)
    const seoTitle = val.seo_title && this.isActivityPageImage(val.image_url) ? clearImgSpecialCharacters(val.seo_title) : ''
    const waterMark = this.hasWaterMark(val) ? this.waterMarkPosition(val.width) : ''
    const options = [
      'fl_lossy.progressive,q_65',
      val.pre_process ? '' : `c_fill,w_${width},h_${height}`, // 有 c_crop 就不要有 c_fill
      waterMark
    ]
    const arr = [firstPath, ...options, secondPath, fileName, seoTitle].filter(item => !!item)
    const url = arr.join('/') + imageType
    return url
  }

  urlToObject(url: string) {
    const array = url.split('https://res.klook.com/image/upload/')[1].split('/')
    let fileName = getImageInfo(array[array.length - 1])?.name || ''
    fileName = decodeURIComponent(fileName)
    const imageType = this.klook.webp ? '.webp' : '.jpg'
    const firstPath = this.isActivityPageImage(url) ? 'https://res.klook.com/images' : 'https://res.klook.com/image/upload'
    const secondPath = array.slice(0, array.length - 1).join('/')
    return {
      firstPath,
      secondPath,
      fileName,
      imageType
    }
  }

  hasWaterMark(item: ExperienceActivity.IDynamicImageData) {
    // white label 中图片不展示水印
    return item.has_water_mark && !this.klook.utilConfig.whiteLabelUtilConfig
  }

  isOfficialCdnImage(url: string) {
    return url.includes('https://res.klook.com/image/upload/')
  }

  isActivityPageImage(url: string) {
    return url.includes('/activities/')
  }

  formatSize(width: number, height: number) {
    let w = width
    let h = height
    if (w > 1295) {
      h = parseInt(String((1295 * h) / w))
      w = 1295
    }
    return {
      width: w,
      height: h
    }
  }

  imageHeight(width: number, height: number) {
    return (
      this.klook.platform === 'desktop'
        ? this.imageHeightDesktop(width, height)
        : this.imageHeightMobile(width, height)
    )
  }

  imageHeightDesktop(width: number, height: number) {
    const baseWidth = this.baseWidth
    if (baseWidth && width && height) {
      return height * baseWidth / width + 'px'
    }
    return height + 'px'
  }

  imageHeightMobile(width: number, height: number) {
    // return `calc((100% - 32px) * ${height} / ${width})`
    return `calc((100vw - 40px) * ${height} / ${width})`
  }

  waterMarkPosition(width: number) {
    let waterMark = 'w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3'

    if (width <= 1295) {
      const waterWidth = parseInt(String(width / 1295 * 80))
      const waterOffset = parseInt(String(width / 1295 * 15))
      waterMark = `w_${waterWidth},x_${waterOffset},y_${waterOffset},g_south_west,l_Klook_water_br_trans_yhcmh3`
    }
    return waterMark
  }
}
