




























































import { Component, State } from 'nuxt-property-decorator'
import ActivityContactButton from '~/components/traveller/activity/activity-contact/activity-contact-button.vue'
import ChatEntrance from '~/components/ceg/chat-entrance/chat-entrance.vue'
import SectionBase from '~/components/setion/experience/base/section-base'
import eventBus from '~/pages/experience/pay/common/event-bus'

@Component({
  components: {
    ActivityContactButton,
    ChatEntrance
  }
})
export default class ActivityContact extends SectionBase {
  @State(state => state.klook.platform) platform!: Data.Platform

  static displayName = 'Contact'
  isShow = false
  shouldMoveAbove = false

  get activityId() {
    return this.businessData.activity_id
  }

  get isSrvActivity() {
    return this.businessData.is_srv_activity
  }

  get title() {
    return this.businessData.activity_title
  }

  mounted() {
    eventBus.$off('moveContactBallAbove', this.moveContactBallAbove).$on('moveContactBallAbove', this.moveContactBallAbove)
  }

  beforeDestroy() {
    eventBus.$off('moveContactBallAbove', this.moveContactBallAbove)
    eventBus.$off('moveContactBallAbove', this.moveContactBallAbove)
  }

  entranceAppeared(show: boolean) {
    this.isShow = show
    if (!show) {
      this.$emit('hide-section')
    }
  }

  moveContactBallAbove() {
    this.shouldMoveAbove = true
  }
}
