







import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component

export default class BookButton extends Vue {
  @Prop({ default: '' }) content!: string
}
