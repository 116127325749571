











import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { renderMarkdownObj, renderMarkdownStr } from '~/assets/scripts/markdown'
import ActMarkdown from '~/components/experience-booking/experience-activity/markdown/index.vue'
import { isInternalLink } from '~/share/utils'

@Component({
  components: {
    ActMarkdown
  }
})
export default class ActivityMarkdown extends Vue {
  @Prop({ default: null }) content: any
  @Prop({ default: '' }) linearGradient?: string // background-color

  get renderContent() {
    if (typeof this.content === 'string') {
      return renderMarkdownStr(this.content)
    } else {
      return renderMarkdownObj(this.content)
    }
  }

  get linearGradientStyle() {
    const linearGradient = this.linearGradient
    const background = `linear-gradient(180deg, rgba(253, 247, 232, 0) 0%, rgba(253, 247, 232, 0.5) 26%, ${linearGradient} 50%, ${linearGradient} 100%`
    return linearGradient ? { background } : {}
  }

  mounted() {
    const that = this

    const node = (this.$refs.markdown as Vue).$el
    if (!node || !node.querySelectorAll) { return }

    Array.from(node.querySelectorAll('a')).forEach((element) => {
      element.addEventListener('click', function () {
        const url = element.getAttribute('href') || ''

        if (!url) { return }

        const isInternal = isInternalLink(url)

        let data = 'Activity Page|Activity Page Content Link Clicked|External'
        if (isInternal) {
          const matches = url.match(/\/activity\/(\d+)/)
          if (matches && matches[1]) {
            const id = matches[1]

            data = `Activity Page|Activity Page Content Link Clicked|Internal,${id}`
          } else {
            data = 'Activity Page|Activity Page Content Link Clicked|Internal'
          }
        }

        that.$sendGTMCustomEvent(data)
      })
    })
  }
}
