import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IconNext } from '@klook/klook-icons'
import apis from '~/share/data/apis'

@Component({
  components: {
    IconNext
  }
})
export default class PassCalendarBase extends Vue {
  @Prop() packageId!: number
  @Prop() activityId!: number
  @Prop() skuIds!: number[]
  @Prop() render_object!: string

  loading = false
  visible = false

  minDate = ''
  date = ''
  maxDate = ''
  schedules: any[] = []

  async getInfo() {
    this.loading = true
    const res = await this.$axios.$get(apis.getPassStandardschedules, {
      params: {
        package_id: this.packageId,
        sku_ids: (this.skuIds || []).join(',')
      }
    })
    this.loading = false

    if (res.success) {
      if (res?.result?.schedules) {
        this.schedules = res?.result?.schedules || []
        this.date = this.schedules[0].date
      } else {
        // 没有日历的时候，显示开始时间和结束时间
        this.minDate = res?.result.start_date
        this.maxDate = res?.result.end_date
      }
    }
  }

  getSpm() {
    return `DetailsSeeAvailability?oid=package_${this.packageId}`
  }
}
