import { Vue, Prop, Component } from 'nuxt-property-decorator'
import { SectionTypes } from '../types'

type IhtData = {
  spmPrefix: string
}

type SpmOptions = {
  idx?: number | string,
  len?: number | string,
  oid?: string,
  ext?: Record<string, any>,
  trg?: 'manual',
  [key: string]: any
}

@Component
export default class BaseComponent extends Vue {
  @Prop({ type: String, default: 'desktop' }) platform!: string
  @Prop({ type: Object, default: () => ({}) }) moduleData!: any
  @Prop({ type: Object, required: true, default: () => ({}) }) ihtData!: IhtData
  @Prop() hideSection!: Function

  // 额外的数据统一放置
  @Prop({ type: Object, default: () => ({}) }) extraData !: any

  fakePage = 0

  // 处理假分页数据
  fakePaginationHandler() {
    this.moduleData.items = [
      ...this.moduleData.items,
      ...this.moduleData.$_cloneItems.slice(this.fakePage * this.moduleData.one_page_count, (this.fakePage + 1) * this.moduleData.one_page_count)
    ]

    this.fakePage++
    if (this.moduleData.items.length >= this.moduleData.$_cloneItems.length) {
      // 没有下一页删掉title 不显示按钮
      this.moduleData.bottom_button_title = ''
    }
  }

  fakePaginationInit() {
    // 假分页数据处理
    // server 端中更改的data数据会同步到客户端
    this.$set(this.moduleData, '$_cloneItems', Object.freeze([...(this.moduleData.$_cloneItems || this.moduleData.items)] || []))

    this.moduleData.items = []
    this.fakePage = 0
    this.fakePaginationHandler()
  }

  watchOriItems() {
    // 假分页的时候，楼层外可能会重置整个楼层的数据
    if (!this.$isServer) {
      const unwatch = this.$watch('moduleData.$_cloneItems', () => {
        unwatch()
        this.fakePaginationInit()

        //  重新watch
        this.watchOriItems()
      })
    }
  }

  created() {
    // 处理假分页特殊逻辑
    if (this.moduleData.action_type === 'fake_pagination') {
      this.fakePaginationInit()

      this.watchOriItems()
    }
  }

  get defaultContainerData(): SectionTypes.ContainerCommonProps {
    if (this.moduleData) {
      const moduleData = this.moduleData
      const actionType = moduleData.action_type

      if (actionType !== undefined) {
        // 新的结构体
        return {
          title: moduleData.title,
          sub_title: moduleData.sub_title,

          more_title: moduleData.bottom_button_title,
          more_deep_link: moduleData.bottom_button_deep_link,
          more_action_type: actionType,
          more_load_link: moduleData.bottom_button_load_more_link,
          more_spm_module: this.formatSPM(actionType === 'deeplink' ? 'ViewAllBtn' : 'ViewMoreBtn', {
            trg: 'manual'
          }),
          more_load_handler: async () => {
            try {
              const { success, result } = await this.$axios.$get(moduleData.bottom_button_load_more_link)
              if (success) {
                Object.assign(moduleData, {
                  ...result,
                  items: moduleData.items.concat(result.items)
                })
              } else {
                moduleData.action_type = ''
              }
            } catch (e) {
              // 隐藏 view more
              moduleData.action_type = ''
            }
          },
          fake_pagination_handle: () => {
            this.fakePaginationHandler()
          },
          corner_button_title: moduleData.more_title,
          corner_button_deep_link: moduleData.more_deep_link
        }
      }

      // 旧
      return {
        title: moduleData.title,
        sub_title: moduleData.sub_title,
        more_title: moduleData.more_title,
        more_deep_link: moduleData.more_deep_link,
        corner_button_title: moduleData.corner_button_title,
        corner_button_deep_link: moduleData.corner_button_deep_link,
        more_spm_module: this.formatSPM('ViewAllBtn', {
          trg: 'manual'
        })
      }
    }

    return {}
  }

  formatSPM(key: string = '', trackInfo: SpmOptions = {}, isItem = false) {
    const jsonStringify = (val: any) => typeof val === 'object' ? JSON.stringify(val) : val

    const qs = Object.entries(trackInfo).map(([key, val]) => {
      return `${key}=${encodeURIComponent(jsonStringify(val))}`
    }).join('&')

    return [
      isItem ? key : `${this.ihtData.spmPrefix}:${key}`,
      ...qs ? [qs] : []
    ].join('?')
  }
}
