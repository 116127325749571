























































































































































































































import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Component, Watch, mixins } from 'nuxt-property-decorator'
import PackageOptionsContent
  from '~/components/traveller/activity/activity-package-options/desktop/package-options/package-options-content.vue'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'
import PackageOptionsWarning
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-options-warning.vue'
import PackageOptionsLoading
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-options-loading.vue'
import PackageOptionsTime
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-options-time.vue'
import ActivityPromotionInfoDesktop
  from '~/components/experience-booking/experience-activity/package-options/package-promotion/desktop/index.vue'
import BookingOptionsUnitsTips
  from '~/components/traveller/activity/activity-package-options/desktop/package-options/package-options-tips.vue'
import PackageOptionsCounterNew
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-options-counter-new.vue'
import PackageAttrDesktop
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-attr.vue'
import PackageOptionsBtnGroup
  from '~/components/experience-booking/experience-activity/package-options/desktop/package-options-btnGroup.vue'
import ActivityPackageDetail
  from '~/components/traveller/activity/activity-package-options/desktop/package-detail/index.vue'
import NoneFilterResult from '~/components/experience-booking/experience-activity/package-options/attraction-included/components/none-filter-result.vue'
import OpenDateTips from '~/components/experience-booking/experience-activity/open-date-ticket/open-date-tips/index.vue'
import PriceDiscountTips from '~/components/experience/price-slot/discount-tips.vue'
import { getTaggingTrackObj } from '~/components/experience/tagging/index.vue'
import PickupReturnDateTime from '~/components/experience-booking/experience-activity/package-options/desktop/pickup-return-date-time/index.vue'
import ExpDatePicker from '~/components/experience-booking/experience-activity/package-options/desktop/pickup-return-date-time/exp-date-picker.vue'
import RangeDateTimeDesktop from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/range-date-time/desktop.vue'
import { isWifiInvalidDate, getFormatDate, formatPackageSchedules, getLocalDate, createWorker, threadFn } from '~/share/utils-date'
import DesktopBookingCoreMixin from '~/components/booking-core/mixins/entry/index-base-desktop'
import BookingTrackMixin from '~/components/booking-core/track/index'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

@Component({
  components: {
    RangeDateTimeDesktop,
    ExpDatePicker,
    PickupReturnDateTime,
    PriceDiscountTips,
    OpenDateTips,
    PackageOptionsContent,
    PackageOptionsTime,
    ActivityPromotionInfoDesktop,
    BookingOptionsUnitsTips,
    PackageOptionsCounterNew,
    PackageAttrDesktop,
    PackageOptionsBtnGroup,
    ActivityPackageDetail,
    NoneFilterResult,
    PackageOptionsWarning,
    PackageOptionsLoading,
    WarmingSlogan
  }
})
export default class DesktopPackageOptions extends mixins(DesktopBookingCoreMixin, BookingTrackMixin) {
  @Watch('calcSchedules', { deep: true })
  calcSchedulesWatch(v: any) {
    v?.length && this.initData()
  }

  shouldDateDisabledFn(d: Date) {
    const rangeDateList = this.dateObj.list || []
    const { calcSchedules: schedules, maxDate } = this
    const { min_pax: minPax, min_trip: minDays, max_trip: maxDays } = this.calcPkgSelectedObj
    const dateStr = getFormatDate(d)
    if (!rangeDateList?.length || rangeDateList?.length === 2) {
      return this.initObjCache[dateStr]
    }
    return this.isInvalidDate(d, { schedules, rangeDateList, minPax, minDays, maxDays, maxDate })
  }

  isInvalidDate(dateInput: Date, options: any) {
    const dateStr = getFormatDate(dateInput)
    const soldout = this.isSoldout(dateStr)
    if (soldout) {
      return true
    }
    return isWifiInvalidDate(dateInput, options)
  }

  isSoldout(d: string) {
    const date = getFormatDate(d)
    const schedule: any = this.pkgSchedulesMap[date]
    // 这里如果选不到日期是disabled，不当做售罄处理
    if (schedule) {
      return schedule.soldout
    }
  }

  get calcSchedules() {
    const arr = formatPackageSchedules(this.calcPkgScheduleList)
    return arr
  }

  get pkgSchedulesMap(): any {
    return this.calcPkgScheduleList?.reduce((acc: any, v: any) => ({ ...acc, [v.date]: v }), {})
  }

  initObjCache: any = null

  initData() {
    // schedules需要补充未返回日期的处理
    // const { calcSchedules: schedules, maxDate } = this
    // const { min_pax: minPax, min_trip: minDays, max_trip: maxDays } = this.calcPkgSelectedObj
    // const options = { schedules, rangeDateList: [], minPax, minDays, maxDays, maxDate }
    // console.time('initDateDisabledCacheData')
    // const obj = this.initDateDisabledCacheData(options)
    // console.timeEnd('initDateDisabledCacheData')
    // this.initObjCache = obj.initedObj

    const { calcSchedules: schedules, maxDate } = this
    const { min_pax: minPax, min_trip: minDays, max_trip: maxDays } = this.calcPkgSelectedObj
    const options = { schedules, rangeDateList: [], minPax, minDays, maxDays, maxDate }
    let wk: any = createWorker(threadFn)
    wk.onmessage = (e: any) => {
      this.initObjCache = this.initDateDisabledCacheData(e.data).initedObj
      wk.terminate()
      wk = null
    }
    wk.postMessage(options)
  }

  initDateDisabledCacheData(options: any) {
    const arr = options?.schedules
    if (!arr.length) {
      return {}
    }
    const initedObj = arr.reduce((obj: any, item: any) => {
      const date = new Date(item.date)
      obj[item.date] = this.isInvalidDate(date, options)
      return obj
    }, {})
    return {
      initedObj
    }
  }

  get maxDate() {
    const len = this.calcPkgScheduleList?.length
    return len === 0 ? null : getLocalDate(this.calcPkgScheduleList?.[len - 1]?.date)
  }

  showDatePickerHandler(isb: boolean) {
    const { timeObj, calcTimeExtra } = this
    if (isb) {
      if (!calcTimeExtra) {
        return
      }
      this.initTimeObj(timeObj)
    }
  }

  initTimeObj(obj: any) {
    const { calcTimeExtra } = this
    if (calcTimeExtra?.in?.length === 1) {
      const list = calcTimeExtra.in[0]
      obj.in = { ...list, startEndFormatStr: this.getStartEndFormatStr(list) }
    }
    if (calcTimeExtra?.out?.length === 1) {
      const list = calcTimeExtra.out[0]
      obj.out = { ...list, startEndFormatStr: this.getStartEndFormatStr(list) }
    }
    return obj
  }

  getStartEndFormatStr(item: any, linkStr = ' - ') {
    return `${item.start}${linkStr}${item.end}`
  }

  get calcSelectDateTime() {
    const { timeObj, dateObj, formatStandardDate } = this
    const [startDate, endDate] = dateObj.list || []
    const startTime = (timeObj?.in?.startEndFormatStr || '').replace(/\s/g, '')
    const endTime = (timeObj?.out?.startEndFormatStr || '').replace(/\s/g, '')
    const obj = {
      in: {
        startEndFormatStr: startDate ? `${formatStandardDate(startDate)}${startTime ? ', ' + startTime : ''}` : ''
      },
      out: {
        startEndFormatStr: endDate ? `${formatStandardDate(endDate)}${endTime ? ', ' + endTime : ''}` : ''
      }
    }
    return obj
  }

  getTitle2tid(tid: number) {
    const obj: any = {
      7: {
        title: this.calcTimeExtra ? this.$t('15667-Date & time') : this.$t('74126-Date')
      },
      8: {
        title: this.$t('88949-Pick-up date & time')
      }
    }
    return obj[tid]?.title
  }

  rangeConfirmHandler(obj: any) {
    this.pkgSelectedDate = obj.date || ''
    this.wifiSimParams = obj
  }

  changeDatePickerHandler(date: any) {
    const ref: any = this.$refs.pickupReturnDateTimeRef
    this.updateRangeDate(date)
    if (!this.calcTimeExtra && !this.checkRangeDateValidate()) {
      ref?.setDatePicker && ref.setDatePicker(false, () => {
        this.rangeConfirmHandler(this.getWifiParams())
      })
    } else {
      this.rangeConfirmHandler(this.getWifiParams())
    }
  }

  get calcFormattedPriceDays() {
    const x = this.getTotalBookingDays()
    return this.$t('3397', { 0: x })
  }

  getTotalBookingDays() {
    const { dateObj, timeObj } = this
    if (!dateObj.days) {
      return 0
    }
    const increaseIn = timeObj?.in?.increase || 0
    const increaseOut = timeObj?.out?.increase || 0
    return Math.max(dateObj.days + increaseIn + increaseOut, 1)
  }

  getWifiParams() {
    const { dateObj, timeObj } = this
    const [startDate, endDate] = dateObj.list || []
    const specParams = {
      days: this.getTotalBookingDays(),
      date: startDate || '', // YYYY-MM-DD
      start_date: startDate ? `${startDate} 00:00:00` : '', // YYYY-MM-DD HH:mm:ss
      end_date: endDate ? `${endDate} 00:00:00` : '', // YYYY-MM-DD HH:mm:ss
      start_time_type_id: timeObj?.in?.id || 0,
      end_time_type_id: timeObj?.out?.id || 0,
      minStock: dateObj.minStock
    }
    return specParams
  }

  validateSimCardParams() {
    const { timeObj, dateObj, pkgSelectedDate } = this
    if (!pkgSelectedDate || !dateObj.list?.length) {
      return this.$t('88950')
    }
    const isb = this.calcTimeExtra && !timeObj?.in?.id
    return isb && this.$t('88950')
  }

  getSimCardParams({ startDate }: any) {
    const { timeObj } = this
    const specParams = {
      date: startDate || '', // YYYY-MM-DD
      start_date: startDate ? `${startDate} 00:00:00` : '', // YYYY-MM-DD HH:mm:ss
      start_time_type_id: timeObj?.in?.id || 0,
      end_date: '',
      end_time_type_id: 0,
      validateFn: this.validateSimCardParams
    }
    return specParams
  }

  updateRangeDate(dateList: string[]) {
    const { dateObj } = this
    const [startDate, endDate] = dateList || []
    const obj = this.getArrangementIdsAndStocks(startDate, endDate)
    dateObj.list = dateList
    dateObj.minStock = obj.minStock
    dateObj.days = obj.days
    dateObj.stocks = obj.stocks

    this.pkgSelectedDate = this.sectionActData.isSimCard ? startDate : (obj.days ? startDate : '')
  }

  getArrangementIdsAndStocks(startDate: string, endDate: string) {
    const tempArr = this.calcPkgScheduleList?.filter((item: any) => {
      const momentDate = dayjs(item.date)
      return momentDate.isSameOrAfter(dayjs(startDate), 'day') && momentDate.isSameOrBefore(dayjs(endDate), 'day')
    })
    const days = tempArr.length
    const stocks = tempArr.map((x: any) => {
      return x.stock
    })
    const minStock = Math.min(...stocks) || 0
    return {
      days,
      stocks,
      minStock
    }
  }

  clickRangeTimeHandler(key: string, item: any) {
    const { timeObj } = this
    timeObj.key = key
    this.$set(timeObj, key, item)
    this.rangeConfirmHandler(this.getWifiParams())
  }

  get calcTaggingTrack() {
    const track = { spm: 'Package_Discount_Section', action: true }
    const list = this.calcPkgSelectedObj?.product_tags?.discount_tags
    const obj = getTaggingTrackObj({
      track,
      list,
      pkg: this.calcPkgSelectedObj
    })
    return obj
  }

  clearAll() {
    this.initWifiSimData()
    this.tagFilterList = []
    this.pkgSelectedDate = ''
    this.chosenAttrIds = []
    this.setExpPkgSelectedTime()
    this.updateExpPackageId()
  }
}
