










import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ExpSkeleton } from '~/components/common/skeleton-data/index.js'

@Component
export default class Loading extends Vue {
  @Prop({ default: 1 }) xNumber!: number | string
  @Prop({ default: 1 }) yNumber!: number | string

  ExpSkeleton = ExpSkeleton
}
