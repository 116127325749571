

















import { Component } from 'nuxt-property-decorator'
import GuideButton from './guide-button.vue'
import PackageOptionsGuideWrap from './package-options-guide-wrap'
import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2.ts'

@Component({
  components: {
    GuideButton
  }
})
export default class PackageOptionsGuideButton extends PackageOptionsGuideWrap {
  translateToPackageOptions() {
    const calendar = document.querySelector('.package_option')
    const header = document.querySelector('.j-activity-header')
    const nav = document.querySelector('.j-activity-nav-container')

    if (!calendar || !header || !nav) { return }

    const scrollY = nodeScrollTop(calendar) - header.getBoundingClientRect().height - nav.getBoundingClientRect().height

    scroll2({
      scrollY
    })
  }
}
