








/**
 * 内容图片懒加载seo抓取不到，所以加一个noscript标签
 */
import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import Logo from '~/components/experience/card-swiper/logo.vue'

@Component({
  components: {
    Logo
  }
})
export default class SeoLazyImage extends Vue {
  @State klook!: Data.Klook
  @Prop() url!: string
  @Prop({ type: String, default: 'activity-image' }) alt!: string
  @Prop() styles!: any

  width = 0
  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get noscriptHtml() {
    let styles = ''
    if (!isEmpty(this.styles)) {
      styles = Object.keys(this.styles).reduce((acc, v) => `${acc}${v}: ${this.styles[v]};`, '')
    }

    return `<noscript>
      <img class="seo-lazy-image" src="${this.url}" alt="${this.alt}" style="${styles}">
    </noscript>`
  }

  getLogoProps() {
    if (process.client) {
      this.$nextTick(() => {
        const { width } = this.$el.getBoundingClientRect()
        const drawerImgWidth = this.isDesktop ? 616 : document.documentElement.clientWidth - 40

        this.width = (width || drawerImgWidth) / 16 * 3
      })
    }
  }

  mounted() {
    this.getLogoProps()
  }
}
