import { Component, Vue } from 'vue-property-decorator'

@Component
export default class Scrollspy extends Vue {
  comboTabDomObserver: any = null
  comboTabSelector = '.package-option-tabs .klk-tabs-header'
  discountTagSelector = '.discount-tag'

  showAnimation() {
    this.$nextTick(() => {
      setTimeout(() => {
        const discountTagDom = document.querySelector(this.discountTagSelector)
        discountTagDom && discountTagDom.classList.add('tag-flash')
      }, 200)
    })
  }

  createSwiperObserver() {
    if (window && 'IntersectionObserver' in window) {
      const comboTabDom = document.querySelector(this.comboTabSelector)
      if (comboTabDom && !this.comboTabDomObserver) {
        this.comboTabDomObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((e) => {
              if (e.intersectionRatio > 0.5) {
                this.showAnimation()
              }
            })
          },
          {
            threshold: [0, 1]
          }
        )
        this.comboTabDomObserver.observe(comboTabDom)
      }
    }
  }

  destroySwiperObserver() {
    const { comboTabSelector } = this
    if (this.comboTabDomObserver) {
      const comboTabDom = this.$el.querySelector(comboTabSelector)
      comboTabDom && this.comboTabDomObserver.unobserve(comboTabDom)
    }
  }

  async mounted() {
    await this.createSwiperObserver()
  }

  beforeDestroy() {
    this.destroySwiperObserver()
  }
}
