import CouponEntrance from './components/entrance-v3/index.vue'
import CouponEntranceV2 from './components/entrance-v2/index.vue'

import CouponList from './components/coupon-list/list.vue'
import CouponItem from './components/coupon-list/item.vue'
import showDialog from './showDialog'

import { createComp } from "./components/entrance-v4/utils";
import newCouponEntrance from './components/entrance-v4/list.vue'
import newCouponItem from './components/entrance-v4/item.vue'
import newCouponList from './components/entrance-v4/all-list.vue'
import NewShowDialog from './components/entrance-v4/showDialog'
import getTheme from './components/entrance-v4/themeData'

const NewCouponItem = createComp(newCouponItem)
const NewCouponEntrance = createComp(newCouponEntrance)
const NewCouponList = createComp(newCouponList)

export default CouponEntrance

export {
  getTheme,
  NewShowDialog,
  NewCouponItem,
  NewCouponEntrance,
  NewCouponList,
  showDialog,
  CouponEntrance,
  CouponEntranceV2,
  CouponList,
  CouponItem
}
