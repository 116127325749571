


































































































































import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import { debounce } from 'lodash'
import { Component, Prop, Watch, Ref, namespace, mixins, State } from 'nuxt-property-decorator'
// import * as types from '~/store/traveller/activity/types'
import HeaderTips from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/header-tips.vue'
import CardHeader from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/card-header.vue'
import BookingCalendar from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/booking-calendar.vue'
import BookingUnit from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/booking-unit.vue'
import HeaderAlert from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/header-alert.vue'
import SkuBottomBar from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/index.vue'
import PackageTimeslot from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/package-timeslot.vue'
import BookineOptionsSkeleton from '~/pages/experience/booking-options/skeleton.vue'
import ExpLoadingState from '~/components/experience/loading-state/index.vue'
import CalendarBase from '~/components/experience-booking/experience-activity/package-options/base/calendar-base'
import CommonContentLoading
  from '~/components/experience-booking/activity/package-detail/status/common-content-loading.vue'
import { ExperienceActivity } from '~/types/experience/activity-section.js'
import { urlTemplate } from '~/share/utils'
import apis from '~/share/data/apis'
import { Activity } from '~/types/traveller/activity'
import ActivityValidity from '~/components/experience-booking/experience-activity/open-date-ticket/activity-validity/activity-validity-new.vue'
import HasOpenDateTips from '~/components/experience-booking/experience-activity/package-options/desktop/has-open-date-tips.vue'
import ExpTagging from '~/components/experience/tagging/index.vue'
import PriceDiscountTips from '~/components/experience/price-slot/discount-tips.vue'
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'
import SavingPriceDetails from '~/components/experience/saving-price-details/index.vue'
import eventBus from '~/pages/experience/pay/common/event-bus'
import * as types from '~/store/traveller/activity/types.ts'
import ExpBookingProcessMixin from '~/components/experience/booking-process-mixin/index'
import { setNewImageSize } from '~/share/data/image'
import { getOpenTicketAutoSelectedDate } from '~/share/open-ticket'
import PriceCheckMixin from '~/components/booking-core/mixins/common/price-check'

const ActivityModule = namespace('traveller/activity')
const experience = namespace('experience')

export interface openPayload {
  skuId: number
  packageId: number
  date: string
  pkgDate: string // YYYY-MM-DD
  selectedTime?: any
  selectedUnits?: any[]
  packageName?: string;
}

@Component({
  components: {
    SavingPriceDetails,
    PriceDiscountTips,
    ExpTagging,
    ActivityValidity,
    HasOpenDateTips,
    HeaderTips,
    CardHeader,
    BookineOptionsSkeleton,
    ExpLoadingState,
    CommonContentLoading,
    BookingCalendar,
    BookingUnit,
    HeaderAlert,
    SkuBottomBar,
    PackageTimeslot
  }
})
export default class PackageOptionsBooking extends mixins(CalendarBase, ExpBookingProcessMixin, PriceCheckMixin) {
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf
  @Prop() autoTimeSlot!: boolean
  @Prop({ default: '' }) imageUrl!: string
  @Prop({ type: String, default: '' }) fromType!: 'bookNow' | 'addToCart'
  @Prop({ default: 0 }) preview!: number
  @Prop() businessData!: any
  @Prop() showShoppingCart!: Function
  @Prop({ default: 'fetching' }) state!: string
  @Prop({ default: true }) showUsjTimeslots!: boolean
  @Ref() headerAlertRef!: any
  @ActivityModule.State commonLoadingState!: any
  @ActivityModule.State preSettlementApiData!: any
  @ActivityModule.Getter preSettlementTips2getters!: any
  @ActivityModule.Action getPreSettlementApiData!: Function
  @State klook!: Data.Klook

  bsObj = getBottomSheetConfig('fixed-height-config')
  bsObjSavePrice = getBottomSheetConfig({ transfer: false })

  cacheOpt: any = { date: '', selectedUnits: null }

  visible = false
  packageId: number | null = null
  packageSelectedDate = ''
  packageSchedules: ExperienceActivity.IPackageSchedule[] = []
  oldPrevUnits: any = null
  selectedTime: any = null
  preSelectedSkuId: number | null = null
  unitsMap: any = {}
  currentUnits: Activity.unit[] = []
  legends: any = []
  groupPackName = ''
  selectedPackageTimeslot: any = null

  get showPackageTimeslot() {
    return this.showUsjTimeslots && this.currentSelectedPackage?.operations_tag_map?.usj_timeslot && this.fromType !== 'addToCart'
  }

  get packageTimeslotOptions() {
    return {
      guid: '',
      packageId: this.packageId,
      startTime: this.selectedTime?.date,
      skus: (this.currentUnits || []).reduce((accu, curr) => {
        if (curr.count > 0) {
          accu.push({
            sku_id: curr.skuId,
            count: curr.count
          })
        }
        return accu
      }, [] as any[]),
      activityId: this.activityId,
      selectedItem: this.selectedPackageTimeslot || null,
      pageType: 'BookingOptions'
    }
  }

  @Watch('visible')
  visibleWatch(isb: boolean) {
    if (!isb) {
      this.clearPreSettlementData()
    } else {
      this.$nextTick(() => {
        this.$inhouse.track('pageview', '#booking_options_page_spm', { force: true })
      })
    }
    this.$emit('changeVisible', isb)
  }

  @Watch('packageSelectedDate')
  packageSelectedDateWatch(str: string) {
    this.isNewOpenTicket || this.$emit('updateDate', str) // open ticket不用同步日期过滤条件
  }

  closePriceSumary() {
    eventBus.$emit('setShowPriceSummary2bus', false)
  }

  clickSavePrice(isb: boolean, obj: any) {
    const { bsObjSavePrice } = this
    bsObjSavePrice.visible = isb
    this.$set(bsObjSavePrice, 'priceSummary', obj?.priceSummary)
  }

  get calcTags2attribute() { // mweb预订页-旧流程-已选套餐
    return this.currentSelectedPackage?.product_tags?.attribute_tags || []
  }

  get calcTags2discount() { // mweb预订页-旧流程-已选套餐
    const tags = this.currentSelectedPackage?.product_tags?.discount_tags || []
    const list: any = []
    if (tags?.length) {
      tags.forEach((tag: any) => {
        const inner_vars = tag?.inner_vars || {}
        if (!inner_vars?.inner?.derivation_info || !inner_vars?.inner?.derivation_info?.is_derived) {
          // 非倒推/直接打到的
          list.push(tag)
        }
      })
    }

    return list
  }

  get showBottomBar() {
    return this.currentUnits?.length > 0
  }

  @Watch('units', { immediate: true })
  unitsChange(val: Activity.unit[]) {
    this.currentUnits = isEmpty(val) ? [] : val
  }

  fetchPackageUnitsDebounce = debounce(this.fetchPackageUnits, 200)

  @Watch('selectedTime', { immediate: true })
  async selectedTimeChange(val: any, old: any) {
    if (val && val.arrangementId) {
      if (old?.arrangementId && val.arrangementId === old.arrangementId) { return }
      await this.fetchPackageUnitsDebounce()
    }
  }

  get arrangementId() {
    return this?.selectedTime?.arrangementId
  }

  get calcData() {
    const { usage_validity_render_obj } = (this.currentSelectedPackage || {}) as any
    const arr = usage_validity_render_obj || []
    return {
      title: (arr.find((o: any) => o.type === 'title') || {}).content,
      rules: arr.filter((o: any) => o.type === 'item').map((o: any) => o.content)
    }
  }

  get activityTicketType() {
    return this.businessData?.activity_ticket_type
  }

  get isNewOpenTicket() {
    const pkg: any = this.currentSelectedPackage || {}
    return pkg.package_ticket_type === 3
  }

  get packages(): ExperienceActivity.Packages {
    return this.businessData.packages
  }

  get currentSelectedPackage() {
    const { packageId, packages } = this
    if (packageId) {
      return packages.find((v: any) => v.package_id === packageId)
    }
  }

  get pickupDateDescription() {
    return this.businessData.date_description
  }

  get units() {
    const { unitsMap, totalUnitCount, selectedTime } = this
    return this.calcExpUnitsExtra({ unitsMap, totalUnitCount, selectedTime })
  }

  // 所有已选的预定unit的数量
  get totalUnitCount() {
    const { unitsMap } = this
    const { arrangementId } = this.selectedTime || {}

    if (!arrangementId) {
      return 0
    }

    const units = unitsMap[arrangementId]
    if (units) {
      // 所有已选的预定unit的数量
      return units.unitList.reduce((acc: number, v: any) => acc + v.count, 0)
    }
    return 0
  }

  get inventories() {
    const { selectedTime, unitsMap } = this
    const { arrangementId } = selectedTime || {}
    return arrangementId && unitsMap[arrangementId] ? unitsMap[arrangementId].inventories : {}
  }

  get bookingCalendarOptions() {
    return {
      templateId: this.templateId,
      autoTimeSlot: this.autoTimeSlot,
      packageId: this.packageId,
      schedules: this.packageSchedules,
      warn: this.pickupDateDescription,
      selectedTime: this.selectedTime,
      currentSelectedPackage: this.currentSelectedPackage,
      isNewOpenTicket: this.isNewOpenTicket,
      legends: this.legends
    }
  }

  get templateId() {
    return this.businessData?.template_id
  }

  get bookingUnitOptions() {
    return {
      selectedTime: this.selectedTime,
      unitsMap: this.unitsMap,
      preview: this.preview,
      currentSelectedPackage: this.currentSelectedPackage,
      inventories: this.inventories,
      totalUnitCount: this.totalUnitCount,
      disabled: !this.arrangementId
    }
  }

  get bottomBarBinds() {
    const binds = {
      fromType: this.fromType,
      hideDiscountTips: this.bsObjSavePrice?.visible,
      businessData: this.businessData,
      units: this.units,
      currentSelectedPackage: this.currentSelectedPackage,
      selectedTime: this.selectedTime,
      packageSchedules: this.packageSchedules,
      packageSelectedDate: this.packageSelectedDate,
      totalUnitCount: this.totalUnitCount,
      showShoppingCart: this._showShoppingCart
    } as Record<string, any>

    if (this.showPackageTimeslot) {
      binds.extraParams = {
        timeslots: [this.selectedPackageTimeslot]
      }
    }

    return binds
  }

  get calcTotalUnitCount() {
    return this.totalUnitCount
  }

  async open({ packageId, date, skuId, selectedTime, selectedUnits, pkgDate, packageName }: openPayload) {
    this.cacheOpt.date = date || ''
    this.visible = true
    this.packageId = packageId
    this.preSelectedSkuId = skuId
    this.packageSelectedDate = date || pkgDate || ''
    this.selectedTime = selectedTime || null
    this.oldPrevUnits = selectedUnits || null
    this.cacheOpt.selectedUnits = cloneDeep(selectedUnits)
    if (packageName) { this.groupPackName = packageName }
    if (this.packageId) {
      await this.getPackagePriceScheduleUnits(this.packageId)
    }
  }

  async getPackagePriceScheduleUnits(id: number) {
    const { arrangementId } = this.selectedTime || {}
    const result = await this.getPackageSchedulesUnits(id)

    if (!result) {
      return null
    }
    const { schedules, units, legend = [] } = result
    this.packageSchedules = [...(schedules || [])]
    this.legends = legend

    // openticket 会自动选日期
    if (this.isNewOpenTicket) {
      this.autoSetFeasibleDate(!!this.currentSelectedPackage?.auto_select_first_date)
    }
    if (!arrangementId && !isEmpty(units)) {
      this.setPackageUnitMap({ prices: units })
    }
    if (isEmpty(units)) {
      this.unitsMap = {}
    }
  }

  autoSetFeasibleDate(isFirstDate: boolean) {
    this.packageSelectedDate = getOpenTicketAutoSelectedDate({
      isFirstDate,
      packageSchedules: this.packageSchedules,
      currentSelectedPackage: this.currentSelectedPackage,
      packageSelectedDate: this.packageSelectedDate
    })
  }

  // 默认选中逻辑：
  // 第一次打开会默认选中preSelectedSkuId
  // 第一次打开不带日期：默认选中 => （切换日期）继续默认选中 => （切换日期）不默认选中
  // 第一次打开带日期：默认选中 => （切换日期）不默认选中
  // 注意：这里需要和unit里面的handlePlus保持一致
  autoSelectSku(unit: Activity.unit, inventories: any) {
    if (this.preSelectedSkuId === unit.skuId) {
      const { arrangementId, maxPurchased: defaultMaxPurchased } = this.selectedTime || {}
      const { preview, unitsMap, currentSelectedPackage, totalUnitCount } = this
      const { max_pax } = currentSelectedPackage as any
      const { count, priceMinPax, priceMaxPax, inventoryId } = unit
      const maxCount = Math.min(unit.priceMaxPax, defaultMaxPurchased - (totalUnitCount - unit.count))
      const maxPurchased = Math.min(maxCount, Number(inventories[inventoryId]))

      if (preview && !unitsMap[arrangementId]) {
        return
      }

      if (totalUnitCount >= max_pax) {
        this.alert(this.$t('activity.v2.error.package_max', [max_pax]))
        return
      }

      if (count >= priceMaxPax) {
        unit?.maxCount && this.alert(this.$t('activity.error.price_max_v2', [unit.maxCount]))
        return
      }

      if (unit.count >= maxPurchased || (count === 0 && count + priceMinPax > maxPurchased)) {
        this.alert(this.$t('activity.v2.unit_out_of_inventory'))
        return
      }

      if (count < priceMaxPax) {
        unit.count = Math.max(priceMinPax, 1)
        return
      }

      this.alert(this.$t('activity.error.price_max_v2', [priceMaxPax]))
    }
  }

  setPackageUnitMap(data: any) {
    const { prices, inventories = {}, credits_tag } = data
    const { arrangementId = 'default', maxPurchased } = this.selectedTime || {}

    // 必买项总最小购买数量
    const requiredPax = prices
      .filter((v: any) => v.required && (v.price_min_pax || v.min_pax))
      .reduce((acc: number, v: any) => acc + (v.price_min_pax || v.min_pax), 0)

    const unitList = prices.map((v: any) => {
      /**
       * 判断unit是否售罄
       * unit的库存有两种，虚拟库存stock和共享库存（特殊库存）inventories，都是套餐级别的设置
       * inventories的优先级大于stock，如果设置了inventories，stock的值会返回inventories设置的值
       * 如果inventories没有设置则返回stock的值
       * inventories可以设置套餐中单个unit的库存或者多个unit的库存的总和
       * stock是套餐unit的总库存
       * 必买项的最小购买数量理论上不应该小于库存，因为如果是这样，那么这个日期应该是售罄状态而不可选
       * 如果最小购买数量 大于 虚拟库存去除所有必买项的总的最小购买数量，则该unit售罄
       * 如果unit的最小购买数量 大于 特殊库存 则该unit售罄（实际情况是应该判断同一inventory_id的unit是否有库存，交由后端判断）
       */
      const priceMinPax = v.price_min_pax || v.min_pax || 0

      // 非必买项最大库存
      const notRequiredMaxPax = maxPurchased - requiredPax

      let isUnitSoldout: boolean
      if (notRequiredMaxPax < 0) {
        // 必买项超过库存则所有unit售罄，当前日期售罄
        isUnitSoldout = true
      } else {
        // 当前unit最小购买数量 大于 虚拟库存去所有除必买项的总的最小购买数量
        isUnitSoldout = !(v.required && priceMinPax) && Math.max(priceMinPax, 1) > notRequiredMaxPax
      }

      // 特殊库存
      const isInventorySoldOut = Math.max(priceMinPax, 1) > inventories[v.inventory_id]

      const isSoldOut = isUnitSoldout || isInventorySoldOut
      const count = !isSoldOut && v.required ? priceMinPax : 0
      return {
        credits_tag,
        redeem_status: v.redeem_status,
        product_tags: v.product_tags,
        priceMinPax,
        priceMaxPax: v.price_max_pax || v.max_pax || 0,
        count,
        credits: v.credits,
        priceId: v.price_id || v.id,
        sellingPrice: v.selling_price || v.price || 0,
        marketPrice: v.market_price || 0,
        originalPrice: v.original_price || 0,
        promotionDiscount: v.discount || 0,
        priceName: v.price_name || v.name,
        isSoldOut,
        isFree: parseFloat(v.selling_price || v.price) === 0,
        specPriceDesc: v.spec_price && v.spec_price.desc,
        requireBuy: v.required && priceMinPax,
        requireMinPax: !v.required && count >= priceMinPax && priceMinPax > 1,
        required: v.required,
        skuId: v.sku_id,
        srvSkuInfo: v.srv_sku_info,
        inventoryId: v.inventory_id,
        eventId: v.promotion_event ? v.promotion_event.event_id : 0,
        isInPromotion: v.promotion_event ? !!v.promotion_event.event_id : false,
        isDefault: arrangementId === 'default' // default 的时候 pc 端不显示价格(防止价格闪一下)
      }
    })

    // 自动选中
    if (this.preSelectedSkuId) {
      unitList.forEach((unit: Activity.unit) => this.autoSelectSku(unit, inventories))
    }

    // 根据skuId自动选中
    const oldUnitList = this.oldPrevUnits || []
    oldUnitList && unitList.forEach((o: any) => {
      const obj = oldUnitList.find((item: any) => {
        return item.skuId === o.skuId
      })
      if (obj && !o.isSoldOut) {
        o.prevCount = obj.count
      }
    })

    // 取消自动选中
    // if (arrangementId && arrangementId !== 'default') {
    //   this.preSelectedSkuId = null
    //   // 重置 default
    //   if (this.unitsMap?.default?.unitList) {
    //     this.unitsMap.default.unitList.forEach((unit: Activity.unit) => { unit.count = 0 })
    //   }
    // }

    this.unitsMap = {
      // default: this.unitsMap.default, // default和arrangementId对应的count值不一致会抖动
      default: {
        inventories,
        unitList
      },
      [arrangementId]: {
        inventories,
        unitList
      }
    }
    setTimeout(() => {
      const { currentSelectedPackage } = this
      if (currentSelectedPackage?.has_discount && arrangementId !== 'default') {
        const params = {
          hideLoading: true,
          page_from: 2, // 调用来源，会影响展示的文案.detail:1; booking_option:2; shopping_card: 3
          arrangement_id: arrangementId,
          sku_list: unitList.map((o: any) => {
            return {
              sku_id: o.skuId,
              quantity: o.isSoldOut ? 0 : o.count
            }
          })
        }
        this.getPreSettlementApiData(params)
      }
    }, 1)
  }

  clearPreSettlementData(_?: any) {
    this.$store.commit(`traveller/activity/${types.SET_PRE_SETTLEMENT_API_DATA}`, { res: null })
  }

  rangeConfirmHandler(obj: any) {
    this.packageSelectedDate = obj.date || ''
    // this.wifiSimParams = obj
  }

  setPackageTime(data: any) {
    const { arrangementId } = this.selectedTime || {}
    if (arrangementId) {
      this.oldPrevUnits = cloneDeep(this.units)
    }
    this.selectedTime = data
  }

  unitsLoadingState = 'success'
  async fetchPackageUnits() {
    const { arrangementId } = this.selectedTime || {}
    const { preview } = this
    const translation = this.$route.query?.translation === '1'

    if (!arrangementId) {
      return {
        success: false
      }
    }
    this.unitsLoadingState = 'fetching'
    let res: any
    try {
      res = await this.$axios.$get(urlTemplate(apis.getPackageUnits, { arrangementId }), { params: { preview, translation }, regularUrl: '/v1/usrcsrv/arrangements/{*}/units' })
    } catch (error) {
      this.unitsLoadingState = 'failure'
      return
    }

    const { result } = res
    if (res?.success && !isEmpty(result) && !isEmpty(result.prices) && !isEmpty(result.inventories)) {
      this.setPackageUnitMap({ ...result, arrangementId })
      this.unitsLoadingState = 'success'
      return {
        ...res,
        success: true
      }
    } else {
      this.unitsLoadingState = 'failure'
    }
    return {
      success: false
    }
  }

  async changeUnitCount({ priceId, type }: { priceId: number, type: 'add' | 'minus' }) {
    const { unitsMap, selectedTime } = this
    const { arrangementId = 'default' } = selectedTime || {}
    const units = unitsMap[arrangementId]

    if (!units) {
      return
    }

    const { unitList } = units
    const unit = unitList.find((v: any) => v.priceId === priceId)

    if (!unit) {
      return
    }
    const { count, priceMinPax, required } = unit

    if (type === 'add') {
      if (count === 0) {
        unit.count += Math.max(priceMinPax, 1)
      } else {
        unit.count++
      }
    } else {
      if (count === 0) {
        return
      }

      if (count > priceMinPax) {
        unit.count--
      } else if (!required) {
        unit.count -= priceMinPax
      }
    }

    const { currentSelectedPackage } = this
    if (currentSelectedPackage?.has_discount) {
      const params = {
        hideLoading: true,
        page_from: 2, // 调用来源，会影响展示的文案.detail:1; booking_option:2; shopping_card: 3
        arrangement_id: arrangementId,
        sku_list: unitList.map((o: any) => {
          return {
            sku_id: o.skuId,
            quantity: o.isSoldOut ? 0 : o.count
          }
        })
      }
      const res = await this.getPreSettlementApiData(params)

      // rate plan 优惠变更提示
      if (res?.result?.rate_plan_hit_budget_not_enough_tips) {
        this.$toast(res?.result.rate_plan_hit_budget_not_enough_tips)
      }
    }
  }

  alert(text: string) {
    this.headerAlertRef.open(text)
  }

  _showShoppingCart() {
    this.showShoppingCart && this.showShoppingCart()
    this.visible = false
  }

  fixedHackStyle: any = null
  updateInfos(infos: any) {
    if (!infos) { return }
    this.fixedHackStyle = {
      paddingBottom: infos.height + 'px'
    }
  }

  formatPicUrl() {
    const { webp } = this.klook
    return setNewImageSize(this.imageUrl, 'image/upload/', 350, 196, webp)
  }

  handlePackageTimeslotSelect(value: any) {
    this.selectedPackageTimeslot = value
  }
}
