























import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import { Activity } from '~/types/traveller/activity'
import { getStandardDateFormat } from '~/share/data/datetime'

@Component
export default class SelectPackageAttrs extends Vue {
  @State klook!: Data.Klook
  @Prop() amount!: number
  @Prop() isNewOpenTicket!: boolean
  @Prop() currentSelectedPackage!: any
  @Prop() specList!: Activity.spec[]
  @Prop() selectedTime!: { arrangementId: number, stock: number, date: string }

  get specs() {
    if (isEmpty(this.currentSelectedPackage)) {
      this.$emit('invalidPkg')
      return []
    }

    const { spec_attr_id = [] } = this.currentSelectedPackage
    const specs = this.specList?.map((spec) => {
      const { attrs } = spec

      return {
        ...spec,
        attr: attrs?.find(attr => spec_attr_id.includes(attr.id)) || {}
      }
    })

    if (!this.amount) {
      return specs
    }

    return specs.slice(0, this.amount)
  }

  formatTime() {
    const date = this.selectedTime.date
    const formatTime = dayjs(date, 'YYYY-MM-DD hh:mm:ss')
    const valid = !(formatTime.hour() === 0 && formatTime.minute() === 0 && formatTime.second() === 0)
    const type = valid ? 5 : 1
    return getStandardDateFormat(date, this.$t.bind(this), this.klook.language, type)
  }
}
