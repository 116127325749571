






/**
 * icons包括
 * cancelation_type
 * confirmation_type
 * group_type
 * language_desc
 * redemption_type
 * ticket_type
 * voucher_usage
 * */

import { Component, Vue, Prop } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import { Activity } from '~/types/traveller/activity'

@Component
export default class ActivityIcons extends Vue {
  @Prop() multiLanguageIcons !: Activity.multiLanguageIcon[]
  @Prop() instant !: boolean
  @Prop() isPresale !: boolean

  get activityIcons() {
    return this.multiLanguageIcons.reduce?.((acc: any, v: Activity.multiLanguageIcon) => {
      if (v.iconKey === 'confirmation_type') {
        if (this.isPresale) {
          return acc
        }
        if (this.instant) {
          return [...acc, {
            ...v,
            iconKey: 'fast'
          }]
        }
      }

      return [...acc, {
        ...v,
        iconKey: v.iconKey
      }]
    }, [])
  }

  get visible() {
    return !isEmpty(this.activityIcons)
  }
}
