








































import { Component, Vue, Prop } from 'nuxt-property-decorator'

/**
 * 方便无限滚动做的modal，主要取消了bottom-sheet的一些原生样式
 * scrollProps 通过传 loading, done 和 onReachBottom 就可以控制无限滚动了
 */

@Component
export default class InfiniteScrollModalMobile extends Vue {
  @Prop({ default: true }) transfer!: boolean
  @Prop({ default: false }) hideInfiniteScrollBottom!: boolean
  @Prop({ default: null }) scrollProps!: Record<string, any> | null
  @Prop({ default: 2000 }) zIndex!: number

  get loadingError() {
    return this.scrollProps?.loadingError || false
  }

  get useInfiniteScroll() {
    return !!this.scrollProps
  }

  get currentModalProps() {
    return {
      height: '90%',
      ...this.$attrs
    }
  }

  get currentScrollProps() {
    return {
      doneText: this.$t('16268'),
      ...(this.scrollProps || {})
    }
  }
}
