







































































import { Component, State, Prop, Watch } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import { AxiosRequestConfig, CancelTokenSource } from 'axios'
import reduce from 'lodash/reduce'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import { nodeScrollTop, scroll2, scrollToPackageOption } from '~/share/scroll2/scroll2'
import { urlTemplate } from '~/share/utils'
import stars from '~/components/traveller/activity/stars.vue'
import gallery from '~/components/traveller/activity/activity-reviews/desktop/activity-gallery/index.vue'
import ReviewRating from '~/components/experience-booking/experience-activity/recent-review/review-rating.vue'
import ActivityReviewsFilter from '~/components/experience-booking/experience-activity/review/review-filter.vue'
import ActivityCommentItem from '~/components/experience-booking/experience-activity/review/desktop/comment-item.vue'
import apis from '~/share/data/apis'
import ExpSubScore from '~/components/experience/sub-score/index.vue'
import ReviewRatingV2 from '~/components/experience-booking/experience-activity/recent-review/review-rating-v2.vue'

interface ReviewsParams {
  page: number
  pageSize: number
  star_num: number | string
  lang: string
  sort_type: number
  only_image: boolean
}

@Component({
  components: {
    ReviewRatingV2,
    ExpSubScore,
    stars,
    gallery,
    ReviewRating,
    ActivityCommentItem,
    ActivityReviewsFilter
  }
})
export default class ActivityReviews extends BusinessBase {
  @Prop() preview!: boolean
  static displayName = 'DesktopReviews'

  @State klook!: Data.Klook

  get calcIsv2() {
    return this.businessData?.version === 'v2'
  }

  reviews: any [] = []
  total: number = 0
  allReviewsTotal: number = 0
  reviewsParams: ReviewsParams = {
    page: 1,
    pageSize: 8,
    star_num: '',
    lang: '',
    sort_type: 0,
    only_image: false
  }

  visible: boolean = false
  lowerScore: boolean = true
  reviewGalleryShouldShow: boolean = false
  customerImageAmount: number = 0
  reviewGalleryList: any[] = []
  activityReviewsCancelToken: CancelTokenSource | null = null

  get calcSubScoreList() {
    return this.businessData?.act_sub_score || []
  }

  get score() {
    return this.businessData?.score
  }

  get reviewCount() {
    return this.businessData?.show_total
  }

  get activityId() {
    return this.businessData?.activity_id
  }

  get item() {
    return this.businessData.item
  }

  @Watch('item', { immediate: true })
  itemChange(val: string) {
    if (val) {
      this.setActivityReviews(this.businessData)
      this.initVisible()

      if (this.allReviewsTotal === 0) {
        this.hideSection()
      }
    }
  }

  showAllReviews() {
    this.visible = true
    this.lowerScore = false
  }

  async onPageChange() {
    const result = await this.fetchActivityReview(this.reviewsParams)
    this.setActivityReviews(result)
    this.$nextTick(() => {
      const nodeTop = nodeScrollTop(this.$refs.activityReviews)
      scroll2({ scrollY: nodeTop, lockLocation: 105 })
    })
  }

  async handleFilterChange(e: any) {
    this.reviewsParams.star_num = e.star_num ? e.star_num.join() : ''
    this.reviewsParams.lang = e.lang ? e.lang.join() : ''
    this.reviewsParams.sort_type = e.sort_type ? e.sort_type : 0
    this.reviewsParams.only_image = e.only_image ? e.only_image : false
    this.reviewsParams.page = 1
    const result = await this.fetchActivityReview(this.reviewsParams)
    this.setActivityReviews(result)
  }

  initVisible() {
    if (!(this.allReviewsTotal < 10 && this.score < 4)) {
      this.visible = true
      this.lowerScore = false
    }
  }

  setActivityReviews(result: any) {
    const { total, item: reviewList } = result
    if (this.allReviewsTotal === 0) {
      this.allReviewsTotal = total
    }
    this.total = total
    this.reviews = reviewList
  }

  async getReviewsImages(data: { page: number, pageSize: number }) {
    const { activityId } = this
    const res = await this.$axios.$get(urlTemplate(apis.activityReviewGalleryImages, { activityId }), {
      regularUrl: '/v1/usrcsrv/activities/{*}/images/get',
      params: {
        page: data.page,
        limit: data.pageSize
      }
    })

    const { result } = res
    if (res.success && !isEmpty(result)) {
      const { image_total_count: imageTotalCount, review_images_info: reviewImageInfo } = result
      if (imageTotalCount === 0 || isEmpty(reviewImageInfo) || isEmpty(reviewImageInfo)) {
        return {}
      }

      /**
       * 评论reviews和图片image是一对多的关系
       * 通过图片image_id检索review_id，最后通过imageReviewMap得到review的信息
       * reviews的数据保存一份，因为有对数据对操作，这样可以保持所有数据的同步
       * @reviewImagesInfo Array 后端请求数据
       * @imageList Array 图片数组
       * @reviewsMap Object reviews和reviewId的map
       */

      const formatReviews = reduce(reviewImageInfo, (acc, v) => {
        acc.imageList = [...acc.imageList, ...(v?.images ?? []).map((img: any) => ({ ...img, reviewId: v.review_id }))]
        acc.reviewsMap = { ...acc.reviewsMap, [v.review_id]: v }

        return acc
      }, {
        imageList: [] as any[],
        reviewsMap: {}
      })

      return {
        imageTotalCount: result.image_total_count,
        reviewsCount: result.reviews_count,
        reviewImagesInfo: result.review_images_info,
        imageList: formatReviews.imageList,
        reviewsMap: formatReviews.reviewsMap
      }
    }
  }

  async fetchActivityReview({
    page = 1,
    pageSize = 15,
    star_num = '',
    lang = '',
    sort_type = 0,
    only_image = false
  }: ReviewsParams) {
    const axiosConfig: AxiosRequestConfig = {
      params: {
        activity_id: this.activityId,
        page,
        limit: pageSize,
        star_num,
        lang,
        sort_type,
        only_image
      }
    }

    this.activityReviewsCancelToken = this.$axios.CancelToken.source()

    axiosConfig.cancelToken = this.activityReviewsCancelToken?.token

    try {
      const res = await this.$axios.$get(apis.getActivityReviewsList, axiosConfig)

      const { result } = res
      if (res.success && !isEmpty(result)) {
        return result
      }
    } catch (e) {
    }
  }

  // 获取评论中用户上传的图片信息
  async getReviewsImageInfo() {
    const { preview, activityId } = this
    const res = await this.$axios.$get(urlTemplate(apis.activityReviewGallery, { activityId }), { params: { preview }, regularUrl: '/v1/usrcsrv/activities/{*}/images/show' })

    const { result } = res
    if (res.success && !isEmpty(result)) {
      this.customerImageAmount = result.count
      this.reviewGalleryShouldShow = result.should_show
      this.reviewGalleryList = result.image_info
    }
  }

  handleBuyNow() {
    scrollToPackageOption({ lockLocation: 110 })
  }

  async mounted() {
    await Promise.all([this.getReviewsImageInfo()])
  }
}
