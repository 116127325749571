



































import { Vue, Component, State, Prop, Inject } from 'nuxt-property-decorator'
import RecentReviewCardV2 from '~/components/experience-booking/experience-activity/recent-review/recent-review-card-v2.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import CommonBodyMuster, { getMusterDefined, MusterDefinedIf } from '~/components/experience-booking/booking-details-v2/components/common/common-body-muster/index.vue'
import ReviewItem from '~/components/experience-booking/experience-activity/review/mobile/reviews-modal/reviews-item.vue'
import { scrollToPackageOption } from '~/share/scroll2/scroll2'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    ReviewItem,
    CommonBodyMuster,
    RecentReviewCardV2
  }
})
export default class RecentReview extends Vue {
  @State klook!: Data.Klook
  @Prop() version!: string
  @Prop({ default: () => [] }) reviewList!: ExperienceActivity.IReview
  @Inject({ default: (_: any) => _ }) getScrollElBySectionConfig2provide!: Function

  musterData: MusterDefinedIf = getMusterDefined()

  currentReview = {
    visible: false,
    itemData: null
  }

  getReviewCardTrack(i: number) {
    const ext = encodeURIComponent(JSON.stringify({
      rank: i + 1
    }))
    const obj = {
      type: 'module',
      exposure: true,
      action: true,
      spm: 'Review_Card',
      query: {
        ext
      }
    }
    return this.bindIHTrack(obj)
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  seeMoreReviews(item: any) {
    const { musterData, currentReview } = this
    currentReview.itemData = item
    musterData.syncData.visible = true
  }

  handleBuyNow() {
    this.musterData.syncData.visible = false
    setTimeout(() => {
      this.$nextTick(() => {
        scrollToPackageOption(this.getScrollElBySectionConfig2provide())
      })
    }, 500)
  }
}
