

















































































































import isEmpty from 'lodash/isEmpty'
import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import KlkStars from '~/components/traveller/activity/stars.vue'
import ActivityReviewsHelpful from '~/components/traveller/activity/activity-reviews/activity-reviews-helpful.vue'
import SeeMoreWithLines from '~/components/common/see-more-with-lines.vue'
import { getNewFriendlyDateFormat } from '~/share/data/datetime'
import { ExperienceActivity } from '~/types/experience/activity-section'
import ImageViewer from '~/components/traveller/activity/activity-banner/mobile/gallery/image-viewer.vue'
import ReviewUser from '~/components/experience-booking/experience-activity/recent-review/review-user.vue'
import Logo from '~/components/experience/card-swiper/logo.vue'
import PrefixImgNew from '~/components/experience/prefix-img/index-new.vue'

@Component({
  components: {
    PrefixImgNew,
    ActivityReviewsHelpful,
    KlkStars,
    SeeMoreWithLines,
    ImageViewer,
    ReviewUser,
    Logo
  }
})
export default class ReviewsItem extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: Number, default: 8 }) contentMaxLine!: number
  @Prop({ type: Number, default: 2 }) replyMaxLine!: number
  @Prop() version!: string
  @Prop() count!: number
  @Prop() visible!: boolean
  @Prop() review!: ExperienceActivity.IReview

  showTranslationContent: boolean = true
  index = 0
  imageViewerVisible = false

  get calcIsv2() {
    return this.version === 'v2'
  }

  get starText() {
    const rating = Number(this.review?.rating || 0)

    if (rating > 80) {
      return this.$t('review_highly_recommended')
    }

    return rating > 60 ? this.$t('review_good') : ''
  }

  get isSliceImages() {
    if (!this.count) { return }

    const { review_image } = this.review
    if (isEmpty(review_image)) {
      return false
    }

    return this.count < review_image.length
  }

  get reviewsImage() {
    const { review_image } = this.review

    if (isEmpty(review_image)) { return [] }

    return this.isSliceImages ? review_image.slice(0, this.count) : review_image
  }

  get images() {
    if (isEmpty(this.review.review_image)) { return }
    return this.review.review_image.map(v => ({ ...v, src: v.img_url }))
  }

  setAvatarUrl(avatar: string) {
    return 'https://cdn.klook.com/upload/img200X200/' + avatar
  }

  getStandardDateFormat(date: string) {
    return getNewFriendlyDateFormat(
      date,
      this.$t.bind(this),
      this.klook.language
    )
  }

  changeReviewContent() {
    this.showTranslationContent = !this.showTranslationContent
  }

  showImageViewer(index: number) {
    this.index = index
    this.imageViewerVisible = true

    this.$sendGTMCustomEvent('Activity Page|View Photo Item Clicked')
  }

  hideImageViewer() {
    this.imageViewerVisible = false
  }
}
