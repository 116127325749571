//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconCheckCircle from '@klook/klook-icons/lib/IconCheckCircle';
import IconQuestion from '@klook/klook-icons/lib/IconQuestion';
import { $colorTextPlaceholder } from '@klook/klook-ui/lib/utils/design-token-esm';
import componentWithBottomSheet from '../../utils/componentWithBottomSheet.ts';
import openPage from '@klook/klk-traveller-utils/lib/openPage.js';
import componentWithDialog from '../../utils/componentWithDialog.ts';
import TncContent from './tnc-content.vue';
import baseMixin from './baseMixin.js';
import getTheme from './themeData.ts';
// status: not_start/to_redeem/redeemed/to_use/sold_out/expired
export default {
  inheritAttrs: false,
  mixins: [baseMixin],
  components: {
    IconCheckCircle,
    IconQuestion,
  },
  props: {
    couponData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    couponLength: {
      type: Number,
      default: 1,
    },
    couponIdx: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      couponStatus: '',
      useDeeplink: '',
    };
  },
  computed: {
    $colorTextPlaceholder() {
      return $colorTextPlaceholder;
    },
    colorData() {
      return getTheme(this.mode, this.modeColor);
    },
    realCouponStatus() {
      return this.couponStatus || this.couponData.status;
    },
  },
  created() {
    this.$emit('getColorData', this.colorData);
  },
  methods: {
    toUse() {
      openPage({
        url: this.useDeeplink || this.couponData.use_deeplink,
        target: '_blank',
      });
    },
    showTnc() {
      const _this = this;
      const attrs = {
        parent: _this,
        componentAttrs: {
          platform: this.realPlatform,
          couponData: this.couponData || {},
        },
      };
      if (this.realPlatform !== 'desktop' && this.realPlatform !== 'web') {
        componentWithBottomSheet(TncContent, {
          ...attrs,
          dialogAttrs: {
            title: _this.__t('109543'),
            maskClosable: true,
          },
        });
      } else {
        componentWithDialog(TncContent, {
          ...attrs,
          dialogAttrs: {
            title: _this.__t('109543'),
            size: 'large',
            closable: true,
            overlayClosable: true,
            escPressClosable: true,
            scrollable: true,
            width: '680px',
          },
        });
      }
    },
    async redeem() {
      if (await this.realIsLoggedIn()) {
        this.toRedeem();
        return;
      }

      if (this.loginMethod) {
        this.loginMethod().then(() => {
          this.toRedeem();
        });
      } else {
        this.login().then(() => {
          this.toRedeem();
        });
      }
    },

    async login() {
      const { loginWithSDK } = await import('@klook/klook-traveller-login');

      return new Promise((resolve, reject) => {
        loginWithSDK({
          aid: this.aid || window?.Cookies?.get('aid'),
          isMP: this.isMP || this.realKlook.platformMp !== '',
          platform: this.platform || this.realPlatform,
          language: this.language || this.realKlook.language,
          currency: this.currency || this.realKlook.currency,
          market: this.market || this.realKlook.market,
          bizName: this.bizName || 'Platform',
          purpose: this.purpose,
          ...this.loginData,
          cancel: () => {
            this.loginData && this.loginData.cancel && this.loginData.cancel();
            reject(new Error('cancel'));
          },
          success: () => {
            this.loginData &&
              this.loginData.success &&
              this.loginData.success();
            this.$store?.dispatch('auth/getProfile');
            resolve(true);
          },
        }).then(supportLogin => {
          if (!supportLogin) {
            window.location.href = this.$href(
              `/signin/?signin_jump=${encodeURIComponent(window.location.href)}`
            );
          }
        });
      });
    },

    async toRedeem() {
      const data = {
        program_uuid: this.couponData.program_uuid,
      };
      if (this.redeemPage) {
        data.redeem_page = this.redeemPage;
      }
      const res = await this.realAxios.$post(
        '/v2/promosrv/program/manual_redeem',
        data
      );

      const { success, error, result } = res;

      if (error && error.message) {
        //  成功失败都在error.message提示
        this.$toast(error.message);
      }
      if (result.status) {
        this.couponStatus = result.status;
        this.useDeeplink = result.use_deeplink;
        this.$emit('onRedeemStatusUpdate', result.status);
      }
    },
  },
};
