




























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import Nav from './lib'

@Component
export default class ScrollNav extends Vue {
  @Prop({ type: Array, default: () => [] }) navItems!: any[]
  @Prop({ type: String, default: 'current' }) currentClass!: string
  @Prop({ type: Number, default: 80 }) offset!: number

  @Prop({ type: Number, default: 0 }) visiblePosition!: number // 滚动多少高度后显示
  @Prop({ type: Boolean, default: false }) visibleByFirstItem!: boolean // 滚动条高度超过第一个元素显示，优先级高于 visiblePosition

  defaultVisible = false
  currentIndex = 0
  $swiper: any = null

  get swiperOption() {
    return {
      init: true,
      slidesPerView: 'auto',
      freeMode: true,
      observer: true,
      observeParents: true
    }
  }

  get visible() {
    if (this.visiblePosition > 0 || this.visibleByFirstItem) {
      return this.defaultVisible
    }

    return true
  }

  firstItemVisiblePosition = 0

  mounted() {
    if (this.navItems.length) {
      const nav = new Nav(this.$el, {
        offset: this.offset,
        onNavChange: ({ index }: { index: number }) => {
          this.currentIndex = index
        },
        scrollToNav: (offsetPixel: number) => {
          if (this.$swiper) {
            this.$swiper.setTransition(350)
            this.$swiper.setTranslate(0 - offsetPixel)
          }
        },

        // 需要处理默认滚动显示
        ...(this.visiblePosition > 0 || this.visibleByFirstItem) ? {
          onScroll: (top: number) => {
            this.defaultVisible = top > (this.firstItemVisiblePosition || this.visiblePosition)
          }
        } : {},

        // 使用第一个元素高度做定位
        ...this.visibleByFirstItem ? {
          updateFirstItemTop: (val: number) => {
            this.firstItemVisiblePosition = val - this.offset
          }
        } : {}
      })
      this.$once('hook:beforeDestroy', () => {
        nav.destroy()
      })
    }
  }
}

