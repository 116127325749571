




































import { Component, mixins, Ref, namespace, Prop, Provide } from 'nuxt-property-decorator'
import GroupSwiper from './components/group-swiper-warpnav.vue'
import { IPackage_group_info } from '~/components/experience-booking/experience-activity/package-options/mobile/group-package-options/type'
import GroupPackageList from '~/components/experience-booking/experience-activity/package-options/desktop/group-package-options/components/group-package-warplist.vue'
import CalendarBase from '~/components/experience-booking/experience-activity/package-options/base/calendar-base'
import ActivityAutoBookBase from '~/components/experience-booking/experience-activity/package-options/base/activity-auto-book-base'
import ShoppingCart from '~/components/experience-booking/experience-activity/activity-shopping-cart/index.vue'
import PackageOptionsCalendarWrap from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/calendar/index.vue'
import OpenDateTips from '~/components/experience-booking/experience-activity/open-date-ticket/open-date-tips/index.vue'
import ShoppingCartMixins from '~/components/booking-core/mixins/common/shopping-cart'
import dataUtils from '~/components/booking-core/utils/data-utils'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    GroupPackageList,
    GroupSwiper,
    ShoppingCart,
    PackageOptionsCalendarWrap,
    OpenDateTips
  }
})
export default class PackageOptionsGroup extends mixins(
  CalendarBase,
  ActivityAutoBookBase,
  ShoppingCartMixins
) {
  @ActivityModule.State tagFilter2state!: any
  @Ref() activityBookingRef!: any
  @Prop() businessData: any
  @Prop() currentSelectedPackage!: any
  @Prop() basicData!: any

  activeKey = 0

  packagesWithSkuByDate: any = null // any[]

  get dateVisible() {
    const isOpenTicket = this.businessData.activity_ticket_type === 3
    return !isOpenTicket
  }

  get hasSomeOpenDate() {
    const arr = this.packages || []
    return arr.some((o: any) => o.package_ticket_type === 3)
  }

  // @ts-ignore
  get activityId() {
    return this.businessData.activity_id
  }

  get isLinePage() {
    return this.activityDetail?.basic_data?.is_line_page ?? false
  }

  get showOpendateByPackages() {
    return this.packages?.length > 1
  }

  get packagesInfo(): IPackage_group_info[] {
    //  如果有分组信息和描述
    return this.businessData?.package_group_info || []
  }

  // 总列表
  get packages() {
    return this.businessData?.packages || []
  }

  get pickupDateDescription() {
    return this.businessData.date_description
  }

  get isNewOpenTicket() {
    const { activity_ticket_type } = this.businessData || {}
    return activity_ticket_type === 3
  }

  @Provide()
  getCurrPkgDetailVertical() {
    return this.businessData?.vertical || ''
  }

  @Provide()
  // 获取pkg detail版本
  getCurrPkgDetailOption() {
    return this.businessData?.package_detail_option || 0
  }

  setActive(index: number) {
    this.activeKey = index
  }

  get swiperList() {
    const list = (this.businessData?.package_group_info || []).map((item: IPackage_group_info) => ({
      label: item.group.name,
      value: item.group.track_id,
      ...item.group
    }))
    return list
  }

  getBookingCore() {
    const bookingCore = dataUtils.getBookingCoreData2section({
      businessData: this.businessData,
      basicData: this.basicData,
      $route: this.$route
    })

    return {
      ...bookingCore
    }
  }

  setCalendarDate(str: string) {
    this.date = str
  }

  async mounted() {
    await this.getSchedules()
  }
}
