

























import { Vue, Prop, Component } from 'nuxt-property-decorator'

@Component
export default class TextList extends Vue {
  @Prop({ default: [] }) list!: string[]
  @Prop({ default: '' }) title!: string
  @Prop({ default: false }) visible!: boolean
  @Prop({ default: false }) isDesktop!: boolean
  @Prop({ default: false }) width!: number
  @Prop() close!: () => void
}
