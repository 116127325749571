
export const config = [
  {
    src: 'https://res.klook.com/image/upload/step1_gtjupd.svg',
    step: '98695',
    title: '98691',
    contentList: ['98699', '98700']
  },
  {
    src: 'https://res.klook.com/image/upload/step2_v1g7qy.svg',
    step: '98696',
    title: '98692',
    contentList: ['98701']
  },
  {
    src: 'https://res.klook.com/image/upload/step3_honnat.svg',
    step: '98697',
    title: '98693',
    contentList: ['98703', '98704', '98705', '98706']
  },
  {
    src: 'https://res.klook.com/image/upload/step4_xlyal6.svg',
    step: '98698',
    title: '98694',
    contentList: ['98707']
  }
]
