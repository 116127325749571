








import { Vue, Component, Prop } from 'nuxt-property-decorator'
import get from 'lodash/get'
import merge from 'lodash/merge'
import tag from './tag.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import { hexToRgba } from '~/share/utils'

export const dec2hex = function ({ value, item }: any) {
  const v = value || '' // #123456 约定为六位rgb
  let alpha: any = typeof item?.background_alpha === 'number' ? item?.background_alpha : 0
  alpha = parseInt((255 - alpha * 255 / 100) as any) // background_alpha 背景透明度, 0:不透明；100:透明
  const rgba = hexToRgba(v, alpha)
  return rgba
}

const DefStyleClass = 'middle-style'
const tagTypeMaps = [{ key: 1, value: 'middle-style' }, { key: 2, value: 'small-style' }]

const iconKeyMaps: any[] = [
  { key: 'icon', mapKey: 'src' },
  { key: 'desc', mapKey: 'desc' } // 点击后显示的文案
]
const creditsIconKeyMaps: any[] = [
  { key: 'left_icon_url', mapKey: 'src' }
]
const tagKeyMaps: any[] = [
  { key: 'tag_key', mapKey: 'tag_key' },
  { key: 'icon', mapKey: 'leftIcon', deepKeys: creditsIconKeyMaps },
  {
    key: 'type',
    mapKey: 'class',
    getterValue: ({ value }: any) => {
      const d = tagTypeMaps
      return d.find((o: any) => o.key === value)?.value || DefStyleClass
    }
  },
  { key: 'text', mapKey: 'text' },
  { key: 'text_color', mapKey: 'color' },
  { key: 'background_color', mapKey: 'backgroundColor', getterValue: dec2hex },
  // { key: 'background_alpha', mapKey: 'background_alpha' },
  { key: 'border_color', mapKey: 'borderColor' },
  { key: 'radius', mapKey: 'borderRadius', getterValue: ({ value }: any) => { return `${value}px` } },
  { key: 'right_action', mapKey: 'right_icon', deepKeys: iconKeyMaps }
]

@Component({
  components: {
    tag
  }
})
export default class Index extends Vue {
  @Prop({ default: true }) autoTips?: boolean
  @Prop({
    type: Object,
    default: null
  }) track!: any

  @Prop({
    type: Object,
    default: null
  }) pkg!: any

  @Prop({
    type: String,
    default: '' // small-style -> 2 | middle-style -> 1
  }) tagType!: String

  @Prop({
    type: String,
    default: 'auto'
  }) maxWidth!: any

  @Prop({
    type: Number,
    default: 0
  }) line!: number

  @Prop({
    type: Number,
    default: 0
  }) tagHeight!: number

  @Prop({
    type: Array,
    default() {
      return []
    }
  }) list!: any[]

  typeDefined: any = { tagHeight: { 'small-style': 20, 'middle-style': 24 } }

  getSpecClass(item: any) {
    const { tag_key, leftIcon } = item || {}
    return (tag_key === 'credits' && leftIcon?.src) ? 'credits-style' : ''
  }

  get wrapStyle() {
    const { line, tagHeight: customHeight, typeDefined } = this
    const tagType = this.calcTagType
    const gap = tagType === 'small-style' ? 4 : 8
    if (!line) {
      return {}
    }
    const tagHeight = customHeight || typeDefined.tagHeight[tagType as any]
    const maxHeight = `${tagHeight * line + (line - 1) * gap}px`
    return {
      maxHeight
    }
  }

  getMapsItem(item: any, maps: any[]) {
    return maps.reduce((o: any, o2: any) => {
      const { key, mapKey, deepKeys, getterValue } = o2
      if (deepKeys && typeof item[key] === 'object') {
        o[mapKey] = this.getMapsItem(item[key], deepKeys)
      } else {
        const value = item[key]
        o[mapKey] = getterValue ? getterValue({ value, item }) : value
      }
      return o
    }, {})
  }

  get calcTagType() {
    return get(this.calcList, '0.class', this.tagType || DefStyleClass)
  }

  get calcList() {
    const arr = Array.isArray(this.list) ? this.list : []
    return arr.map((item: any) => {
      const obj = this.getMapsItem(item, tagKeyMaps)
      return obj
    })
  }

  getTrackObj(obj: any) {
    return getTaggingTrackObj(obj)
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get calcTrack() {
    const def = {
      type: 'item',
      spm: 'Savings_Detail'
    }
    return def
  }
}

export const getTaggingExtraTrackObj = (list: any[]) => {
  const arr = list || []
  const extra: any = arr.reduce((o: any, o2: any) => {
    o.TagIDList.push(o2?.track?.tag_id || '')
    o.TagKeyList.push(o2?.track?.tag_key || '')
    return o
  }, { TagIDList: [], TagKeyList: [] })
  return extra
}

export const getTaggingTrackObj = ({ track, pkg, list }: any) => {
  const mergeTrack = track || {}
  if (!mergeTrack?.spm) { return }
  const { package_id, packageId } = pkg || {}
  const pid = package_id || packageId
  // console.log(999999999, pid, pkg, list)
  const extra: any = getTaggingExtraTrackObj(list)

  const def = {
    type: 'module',
    spm: '', // 必需外部传入 Package_Product_Tag
    exposure: true,
    action: false,
    query: {
      oid: pid ? `package_${pid}` : undefined,
      ext: encodeURIComponent(JSON.stringify(extra))
      // ext: JSON.stringify(extra) // test code
    }
  }
  return merge(def, mergeTrack)
}

export const getTestTags = (num: number = 9) => {
  const item = {
    type: 1, // tag icon 样式类型, 具体到一个 模块中是写死的。1:middle; 2:small
    text: 'Display tag',
    text_color: '#212121',
    background_color: '#eeeeee',
    background_alpha: 0, // 背景透明度, 0:不透明；100:透明
    border_color: '#eeeeee', // 边框颜色
    radius: 4, // 圆角值
    right_action: {
      icon: '',
      desc: ''
    },
    tag_key: '', // 辅助字段，tag key
    group_keys: ['product_tag', 'promotion_tag'] // tag_key 的父级 key 列表
  }
  const arr = new Array(num).fill(0).map((_: any, i: number) => {
    return {
      ...item,
      tag_key: `key${i}`
    }
  })

  return arr
}
