




















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import SeeMore from './see-more.vue'

@Component({
  components: {
    SeeMore
  }
})
export default class Reminder extends Vue {
  @Prop() data!: any
  @Prop() packageDetail!: any
  @State(state => state.klook.platform) platform!: Data.Platform

  get valid() {
    return this.platform === 'mobile' && this.data
  }

  get components() {
    if (this.data) {
      return this.data?.components?.['0']?.data?.components
    }

    return []
  }

  get spm() {
    return `SeeMorePOI?oid=package_${this.packageDetail.packageId}`
  }
}
