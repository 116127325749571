import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component
export default class CardAttrBase extends Vue {
  @Prop() packages!: ExperienceActivity.Packages
  // 如果选中了日期，则有当前选中日期对应的schedule
  @Prop() schedule!: ExperienceActivity.ISchedule

  // 当前日期下可用的套餐
  get packagesInSchedule() {
    if (!this.schedule?.stocks?.length) { return this.packages }
    return this.schedule.stocks.reduce((acc, v) => {
      const { stock, package_id } = v

      if (stock === 0) { return acc }

      const pkg = this.packages.find(p => p.package_id === package_id)

      if (!pkg) { return acc }

      return [...acc, pkg]
    }, [] as ExperienceActivity.Packages)
  }
}
