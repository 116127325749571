import { Component, Prop, Watch, Vue } from 'nuxt-property-decorator'
import { CancelTokenSource } from 'axios'
import {
  IPackageWithUnitsAndPromotion,
  IPackageItem
} from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/package-card-list/type'
import apiNameMap from '~/share/data/apis'
import { ExperienceActivity } from '~/types/experience/activity-section'

let cancelToken: CancelTokenSource | null = null

//  sku外放 专用mixin
@Component({})
export default class PackageOptionsSkuBase extends Vue {
  @Prop({ default: 0 }) preview!: number
  @Prop() date!: string
  @Prop() packageList!: ExperienceActivity.Packages
  @Prop() packageId!: number
  @Prop() packagesWithSku!: IPackageWithUnitsAndPromotion[]
  // 如果选中了日期，则有当前选中日期对应的schedule
  @Prop() schedule!: ExperienceActivity.ISchedule

  state: string = 'success'
  unavailablePackageList: IPackageItem[] = []
  availablePackageList: IPackageItem[] = []
  selectedPackageList: IPackageItem[] = []

  @Watch('date')
  dateChange() {
    this.getPackageInfo()
  }

  @Watch('packagesWithSku', { immediate: true })
  packagesWithSkuChange(skuArr: any[]) {
    if (Array.isArray(skuArr)) {
      this.initPackages(skuArr)
    }
  }

  // 当前日期下可用的套餐
  get packagesInSchedule() {
    if (!this.schedule?.stocks?.length) {
      return this.packageList
    }
    return this.schedule.stocks.reduce((acc, v) => {
      const { stock, package_id } = v

      if (stock === 0) {
        return acc
      }

      const pkg = this.packageList.find((p) => p.package_id === package_id)

      if (!pkg) {
        return acc
      }

      return [...acc, pkg]
    }, [] as ExperienceActivity.Packages)
  }

  get packageListSku() {
    return this.availablePackageList.concat(this.selectedPackageList).concat(this.unavailablePackageList)
  }

  get itemParams() {
    return this.packagesInSchedule?.map((item) => {
      return {
        actual_price: item.sell_price,
        package_id: item.package_id
      }
    })
  }

  get filteredPackageIdList() {
    return this.packageList.map((pkg) => pkg.package_id)
  }

  get filterPackagesWithSku() {
    const { packageList, availablePackageList } = this
    const skuArr = availablePackageList
      .filter((pkg) => (this.filteredPackageIdList || []).includes(pkg.package_id))
      .filter((o: any) => {
        return packageList.find((o2: any) => {
          return o2.package_id === o.package_id
        })
      })
    return skuArr
  }

  async getPackageInfo() {
    this.state = 'fetching'

    if (cancelToken) {
      cancelToken.cancel('cancel')
    }

    cancelToken = this.$axios.CancelToken.source()

    try {
      const resp = await this.$axios.$post(
        apiNameMap.activityPackageSkuInfoList,
        {
          preview: this.preview ? 1 : 0,
          date: this.date,
          items: this.itemParams
        },
        {
          cancelToken: cancelToken?.token
        }
      )
      if (resp.success) {
        this.$emit('updateSkuPackages', resp?.result?.packages_with_sku || [])
        this.state = 'success'
        return
      }
    } catch (e) {}

    this.state = 'failure'
  }

  private initPackages(packageInfos: IPackageWithUnitsAndPromotion[]) {
    const packagesMap = (packageInfos || []).reduce(
      (acc, v) => ({ ...acc, [v.package_id]: v }),
      {} as { [k: number]: IPackageWithUnitsAndPromotion }
    )
    this.availablePackageList = []
    this.unavailablePackageList = []
    this.selectedPackageList = []
    for (const v of this.packageList) {
      const { package_id } = v
      const info = packagesMap[package_id]
      const formatedPackage = this.formatPackage(v, info)
      const isSelected = this.packageId && info?.package_id === this.packageId
      const isWarmingOrSuspended = [2, 3].includes(v?.publish_info?.publish_status)
      // 当是预热或者暂停销售的时候，需要展示出来(在没有选日期的情况下)
      const isAvailable = info || isWarmingOrSuspended
      // 当选择了日期，并且是暂停销售的话，就需要隐藏
      const shouldHide = this.date && isWarmingOrSuspended

      if (isSelected) {
        this.selectedPackageList = this.selectedPackageList.concat({ ...formatedPackage, status: 'selected' })
      } else if (!shouldHide && isAvailable) {
        this.availablePackageList = this.availablePackageList.concat([
          { ...formatedPackage, status: 'available' }
        ])
      } else {
        this.unavailablePackageList = this.unavailablePackageList.concat({
          ...formatedPackage,
          status: 'unavailableDate'
        })
      }
    }
  }

  formatPackage(pkg: ExperienceActivity.IPackage, info?: IPackageWithUnitsAndPromotion) {
    if (!info) {
      return pkg
    }

    const promotionPrice = info.promotion_price

    return {
      ...pkg,
      credits: info.credits,
      units: this.formatUnits(info.units),
      promotion_price: promotionPrice,
      original_selling_price: promotionPrice?.original_selling_price || '0',
      sell_price: promotionPrice?.selling_price || pkg.sell_price,
      price_show: info?.price_show || pkg.price_show,
      discount_desc: promotionPrice?.discount ? `${promotionPrice.discount}% OFF` : pkg.discount_desc
    }
  }

  formatUnits(units: any) {
    return units.map((v: any) => {
      const priceMinPax = v.price_min_pax || v.min_pax || 0
      const count = v.required ? priceMinPax : 0
      return {
        price_show: v.price_show,
        redeem_status: v.redeem_status,
        product_tags: v.product_tags,
        priceMinPax,
        priceMaxPax: v.price_max_pax || v.max_pax || 0,
        count,
        credits: v.credits,
        priceId: v.price_id || v.id,
        sellingPrice: v.selling_price || v.price || 0,
        marketPrice: v.market_price || 0,
        originalPrice: v.original_price || 0,
        promotionDiscount: v.discount || 0,
        priceName: v.price_name || v.name,
        isSoldOut: !!v.sold_out,
        isFree: parseFloat(v.selling_price || v.price) === 0,
        specPriceDesc: v.spec_price && v.spec_price.desc,
        requireBuy: v.required && priceMinPax,
        requireMinPax: !v.required && count >= priceMinPax && priceMinPax > 1,
        required: v.required,
        skuId: v.sku_id,
        srvSkuInfo: v.srv_sku_info,
        inventoryId: v.inventory_id,
        eventId: v.promotion_event ? v.promotion_event.event_id : 0,
        isInPromotion: v.promotion_event ? !!v.promotion_event.event_id : false,
        availableTime: v.available_time
      }
    })
  }
}
