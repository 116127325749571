import { render, staticRenderFns } from "./activity-markdown.vue?vue&type=template&id=4af2d658&scoped=true&"
import script from "./activity-markdown.vue?vue&type=script&lang=ts&"
export * from "./activity-markdown.vue?vue&type=script&lang=ts&"
import style0 from "./activity-markdown.vue?vue&type=style&index=0&id=4af2d658&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4af2d658",
  null
  
)

export default component.exports