



















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { updateDataSpm } from '~/share/utils'

@Component
export default class Index extends Vue {
  @Prop() contents!: string[]
  @Prop({ default: () => ({}) }) spm!: any
  @Prop({ type: Number, default: 6 }) maxLength!: number
  @Prop({ type: Boolean, default: true }) isFold!: boolean

  formatContent: any = []

  created() {
    this.formatContent = this.showSeeMoreBtn ? this.contents.slice(0, this.maxLength) : this.contents
  }

  get trackInfo() {
    return `${this.spm}&trg=manual&ext=${this.$getExt({
      ClickType: this.isFold ? 'ShowLess' : 'ShowMore'
    })}`
  }

  get valid() {
    return !!this.contents?.length
  }

  get showSeeMoreBtn() {
    return this.valid && this.contents?.length > this.maxLength
  }

  get viewMoreText() {
    const textid = this.isFold ? 'city_page_view_more' : 'collapse_all'
    return this.$t(textid)
  }

  handleClickEvent() {
    const { contents, maxLength } = this
    this.formatContent = this.isFold ? contents : contents.slice(0, maxLength)
    this.isFold = !this.isFold
    this.updateSpm()
  }

  updateSpm() {
    const dom = this.$el.querySelector('.see-more-wrapper_footer')

    if (dom) {
      updateDataSpm('data-spm-module', dom, this.trackInfo)
      setTimeout(() => {
        this.$inhouse.track('action', dom)
      }, 10)
    }
  }
}
