import { render, staticRenderFns } from "./fold.vue?vue&type=template&id=675c7a88&scoped=true&"
import script from "./fold.vue?vue&type=script&lang=ts&"
export * from "./fold.vue?vue&type=script&lang=ts&"
import style0 from "./fold.vue?vue&type=style&index=0&id=675c7a88&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "675c7a88",
  null
  
)

export default component.exports