import { Vue, Component } from 'nuxt-property-decorator'
import { throttle } from 'lodash'
import { nodeScrollTop, windowScrollTop } from '~/share/scroll2/scroll2'
import { getCss } from '~/share/utils'
import eventBus from '~/pages/experience/pay/common/event-bus'
@Component
export default class Index extends Vue {
  throttleHandlerRightPriceVisible = throttle(this.handlerRightPriceVisible, 60)
  isFixedFlag = false
  hidePriceFlag = false
  topHeight = 61 + 47 + 24 // header + nav + pos bottom

  isDelayFlag = false

  get calcFixedStyle() {
    const { topHeight, isFixedFlag, hidePriceFlag, isDelayFlag } = this
    if (isFixedFlag) {
      return {
        visibility: !isDelayFlag || hidePriceFlag ? 'hidden' : 'visible',
        top: `${topHeight}px`,
        position: 'fixed'
      }
    }
    return {
      visibility: !isDelayFlag || hidePriceFlag ? 'hidden' : 'visible',
      top: '24px',
      position: 'relative'
    }
  }

  nodeOldHeight = 0
  hideBaseNode = false
  sectionEls = ['#package_option_card_sku_with_detail', '#package_option', '.js-spec-type-pkg-detail', '#recommend_activity', '#theme_list', '#package_options_group', '#package_options_fnb']

  handlerRightPriceVisible(options?: any) {
    const opt = options || { isHack: false, from: '' }
    if (opt.isHack) {
      this.hackPackageOptions()
    }
    const elList: any[] = this.sectionEls
    let hidePriceFlag = false
    for (const index in elList) {
      hidePriceFlag = this.handlerScrollFn(elList[index])
      if (hidePriceFlag) {
        break
      }
    }
    const statusList: any[] = this.sectionEls
    const isb = statusList.every((key) => {
      return this.handlerRightPriceStatue(key)
    })
    eventBus.$emit('updateRightPriceStatus2bus', isb ? 'default' : '')
    // this.hidePriceFlag = isb ? false : hidePriceFlag
    this.hidePriceFlag = hidePriceFlag
  }

  handlerRightPriceStatue(el: string) {
    const wrapNode = document.querySelector('.js-scroll-right-price-wrap')
    const domBox = document.querySelector(el)
    if (!wrapNode || !domBox) { return true }
    const { topHeight } = this
    const winTop = windowScrollTop()
    const node: any = document.querySelector('.js-scroll-right-price')
    const nodeHeight = parseInt(node.getAttribute('data-init-height')) || 0
    const domTop = nodeScrollTop(domBox)
    const titleNode = domBox.querySelector('.section-header_top')
    const titleTop = nodeScrollTop(titleNode) || domTop
    const isb = winTop + topHeight + nodeHeight < titleTop
    return isb
  }

  handlerScrollFn(el: string) {
    const winTop = windowScrollTop()
    const { topHeight } = this
    try {
      const wrapNode = document.querySelector('.js-scroll-right-price-wrap')
      if (!wrapNode) { return false }
      const wrapTop = nodeScrollTop(wrapNode)
      const isFixedFlag = winTop + topHeight > wrapTop + 24
      if (isFixedFlag) {
        //
      }
      this.isFixedFlag = isFixedFlag

      const node: any = document.querySelector('.js-scroll-right-price')
      const newHeight = parseInt(getCss(node, 'height')) || 0
      const nodeHeight = newHeight || this.nodeOldHeight
      this.nodeOldHeight = nodeHeight

      // if (!winTop) { return false }
      const domBox = document.querySelector(el)
      if (!domBox) { return false }
      const domTop = nodeScrollTop(domBox)
      const domHeight = parseInt(getCss(domBox, 'height')) || 0
      const titleNode = domBox.querySelector('.section-header_top')
      const titleTop = nodeScrollTop(titleNode) || domTop
      const hideBaseNode = wrapTop + nodeHeight > titleTop
      const hideBeforeNode = winTop + topHeight + nodeHeight > titleTop
      const hideAfterNode = winTop + topHeight - domHeight < domTop
      const hidePriceFlag = (isFixedFlag ? hideBeforeNode : hideBaseNode) && hideAfterNode
      return hidePriceFlag
    } catch (error) {
      //
    }
    return false
  }

  isInitFirstFlag = false

  hackPackageOptions() {
    const wrapNode: any = document.querySelector('.js-scroll-right-price-wrap') // banner、banner_atlas
    if (!wrapNode) { return false }
    const node: any = document.querySelector('#package_option')
    if (!node) { return false }
    const wrapTop = nodeScrollTop(wrapNode)
    const wrapHeight = parseInt(getCss(wrapNode, 'height')) || 235
    const nodeTop = nodeScrollTop(node)
    // const titleNode: any = node.querySelector('.section-header')
    // const titleTop = nodeScrollTop(titleNode)

    const hackHeight = wrapTop + wrapHeight - nodeTop
    if (hackHeight) {
      node.style.paddingTop = `${hackHeight}px`
    }
  }

  mounted() {
    document.addEventListener('scroll', this.throttleHandlerRightPriceVisible)
    eventBus.$off('setIsDelayFlag2bus', this.setIsDelayFlag2bus).$on('setIsDelayFlag2bus', this.setIsDelayFlag2bus)
    eventBus.$off('throttleHandlerRightPriceVisible2bus', this.throttleHandlerRightPriceVisible).$on('throttleHandlerRightPriceVisible2bus', this.throttleHandlerRightPriceVisible)
    this.throttleHandlerRightPriceVisible()
  }

  rightPriceReady: boolean = false

  setIsDelayFlag2bus(options: any) {
    const sectionEls = ['#package_option_card_sku_with_detail', '#package_option', '#package_options_group', '#package_options_fnb']
    const { from } = options || {}
    switch (from) {
      case 'right_price_updated':
        this.rightPriceReady = true
        if (sectionEls.every((el: string) => !document.querySelector(el))) {
          this.isDelayFlag = true
        }
        break
      default:
        this.isDelayFlag = true
        break
    }
    this.throttleHandlerRightPriceVisible(options)
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.throttleHandlerRightPriceVisible)
    eventBus.$off('setIsDelayFlag2bus', this.setIsDelayFlag2bus)
    eventBus.$off('throttleHandlerRightPriceVisible2bus', this.throttleHandlerRightPriceVisible)
  }
}
