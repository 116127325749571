


























import { Component, Prop, Vue, State } from 'nuxt-property-decorator'

@Component
export default class Header extends Vue {
  @Prop() title!: string
  @Prop() subtitle!: string
  @Prop() cornerButtonTitle!: string
  @Prop() cornerButtonLink!: string
  @State(state => state.klook.platform) platform!: Data.Platform

  get subtitleVisible() {
    return this.subtitle || this.$slots.subtitle
  }

  get isDesktop() {
    return this.platform === 'desktop'
  }

  get target() {
    return this.isDesktop ? { target: '__blank' } : {}
  }

  get link() {
    return this.cornerButtonLink ? this.$href(this.cornerButtonLink) : 'javascript:void(0);'
  }
}
