































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { Activity } from '~/types/traveller/activity'
import { renderMarkdownObj, renderMarkdownStr } from '~/assets/scripts/markdown'
import Markdown from '~/components/traveller/faq/markdown'

@Component({
  components: {
    Markdown
  }
})
export default class ActivityPromotionInfo extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() promotionInfoList!: Activity.promotionInfo[]

  get promotionTips() {
    return `${this.$t('16573')}${this.$t('16720')} ${this.$t('16574')}`
  }

  getRenderContent(event: any) {
    let result = ''
    const { summary_render_obj: renderObj, start_time: startTime, end_time: endTime } = event

    if (typeof renderObj === 'string') {
      result = renderMarkdownStr(renderObj)
    } else {
      result = renderMarkdownObj(renderObj)
    }

    // 由于获取不了时区，所以用 placeholder 处理
    result = result.replace('{start_time}', dayjs(startTime).format('YYYY-MM-DD HH:mm'))
    result = result.replace('{end_time}', dayjs(endTime).format('YYYY-MM-DD HH:mm'))

    return result
  }

  getTitleObj(sku: any) {
    return {
      0: sku.rule.discount,
      package_name: sku.package_name,
      unit_name: sku.sku_name
    }
  }

  getTimeObj(event: any) {
    const startTime = dayjs(event.start_time)
    const endTime = dayjs(event.end_time)
    return {
      mmm: startTime.format('MMM'),
      d: startTime.format('D'),
      hhmm: startTime.format('HH:mm'),
      mmm1: endTime.format('MMM'),
      d1: endTime.format('D'),
      hhmm1: endTime.format('HH:mm')
    }
  }

  getPlatformText(platforms: any[]) {
    const platformMap: Record<string, string> = {
      web: this.$t('16572'),
      mweb: this.$t('16571'),
      app: this.$t('16570')
    }

    return platforms.map((p: string) => platformMap[p]).join('/')
  }
}
