






























































































import { Component, Prop } from 'nuxt-property-decorator'
import SpecTitle from './spec-title.vue'
import AttrDetailBtn
  from './attr-detail-btn.vue'
import ActivityPackageBtn
  from '~/components/traveller/activity/activity-package-options/mobile/activity-package-btn-new.vue'
import AttrBase from '~/components/experience-booking/experience-activity/package-options/base/attr-base'
import ShowMoreBox from '~/components/experience-booking/experience-activity/show-more-box/index.vue'

type state = 'attrUpdate' | 'dateUpdate' | 'normal'

@Component({
  components: {
    ShowMoreBox,
    SpecTitle,
    ActivityPackageBtn,
    AttrDetailBtn
  }
})
export default class PackageAttributes extends AttrBase {
  @Prop({ default: false }) isAbExperiment!: boolean
  @Prop({ default: false }) isEnableClear!: boolean
  @Prop({ default: 0 }) firstUnselectedSpecId!: number

  moreObj: any = {
    len: 8,
    status: ''
  }

  initStatus(singleAttr: boolean, arr: any) {
    const { moreObj } = this
    if (!singleAttr || (arr && arr.length <= moreObj.len)) { return moreObj.status }
    moreObj.status = 'more'
    return moreObj.status
  }

  getStatusList(isSingleSpec: boolean, list: any) {
    const { moreObj } = this
    const status = moreObj.status || this.initStatus(isSingleSpec, list) // 只初始化一次
    if (status === 'more') {
      return list.filter((_: any, i: number) => i < moreObj.len)
    }
    return list || []
  }

  clickShowMore(status: string) {
    const { moreObj } = this
    moreObj.status = status
  }

  isDisabled(attr: any) {
    if (this.isAbExperiment) { return false }
    return !attr.isScheduleMatch || !attr.isPackageMatch
  }

  handleClear() {
    this.$emit('clear')
  }
}
