import { Component, Prop, State, Vue, Watch } from 'nuxt-property-decorator'
import debounce from 'lodash/debounce'
import { nodeScrollTop, scroll2, scrollHeight, windowScrollTop } from '~/share/scroll2/scroll2'
import { ExperienceActivity } from '~/types/experience/activity-section.js'

export interface IItem {
  'data-meta-name': string
  name: string,
  title: string
}

export interface INavItem extends IItem {}

@Component
export default class ActivityNavBase extends Vue {
  @State klook!: Data.Klook
  @Prop() sections!: ExperienceActivity.Sections

  navShow = false
  lockHeight = 150
  visibleHeight = 540
  list: IItem[] = []
  navList: INavItem[] = []
  activeIndex: number = -1
  style: any = {}
  autoScroll: boolean = true // scroll event or click tab

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get basicData() {
    return this.$attrs['basic-data'] || null
  }

  get isHeroPage() {
    const basicData = this.basicData as any
    const act_product_type = basicData?.act_product_type ?? ''
    return act_product_type === 'hero_page'
  }

  @Watch('list')
  listChange() {
    this.debounceInit()
  }

  debounceInit = debounce(this.init, 500)

  init() {
    this.navList = this.list.filter(v => v.title)
    // 添加overview选项，点击滚动到顶部
    // desktop
    if (this.isDesktop) {
      this.navList.unshift({
        'data-meta-name': 'breadCrumb',
        title: this.$t('overview'),
        name: '#breadCrumb'
      })
    }

    this.initScroll()
  }

  getNode(data: IItem) {
    if (!data?.name) { return }

    const el = `[data-mate-name="${data['data-meta-name']}"]`
    return document.querySelector(el)
  }

  debounceClickNav(data: IItem) {
    const node = this.getNode(data)

    if (!node) { return }

    const top = nodeScrollTop(node)

    // overview 计算不准确，暂时先这样处理
    let lockHeight = this.lockHeight
    if (data.name === '#breadCrumb') {
      lockHeight = 60
    }

    scroll2({
      scrollY: top,
      lockLocation: lockHeight,
      callback: () => {
        this.autoScroll = true
      }
    })
  }

  get reverseNavList() {
    return [...this.navList].reverse()
  }

  initScroll() {
    // Hero Page 隐藏导航栏
    if (this.isHeroPage) {
      return
    }
    const top = windowScrollTop()
    // 页面滑动高度为 540 时展示顶部导航条
    if (top >= this.visibleHeight) {
      this.getActiveIndex()
      this.navShow = true
    } else {
      // 这里改正是在desktop端的时候，在打开modal或者drawer时，导航栏会消失的bug。
      // 组件库为了进行滚动锁定，会强行将window.scrollTop改成0此时windowScrollTop()会返回0
      if (this.isDesktop && top === 0) {
        const html = document.querySelector('html') as HTMLElement
        if (html.classList.contains('klk-lock-body-scroll')) {
          return
        }
      }
      this.navShow = false
    }
  }

  getActiveIndex() {
    if (!this.autoScroll) { return }

    const lastNodeBottom = this.getLastNodeBottom()

    // 第一个nav的顶部到最后一个nav的底部之间
    const scrollTop = windowScrollTop()
    if (scrollTop < lastNodeBottom - this.lockHeight - 4) {
      const index = this.reverseNavList.findIndex((v) => {
        const node = this.getNode(v)

        if (!node) { return false }

        return scrollTop >= nodeScrollTop(node) - this.lockHeight - 4
      })
      this.activeIndex = index > -1 ? this.navList.length - 1 - index : -1
    } else {
      this.activeIndex = -1
    }
  }

  getLastNodeBottom() {
    const lastNav = this.navList[this.navList.length - 1]
    const lastNavNode = this.getNode(lastNav)

    if (!lastNavNode) {
      return scrollHeight()
    }

    return nodeScrollTop(lastNavNode) + lastNavNode.getBoundingClientRect().height
  }

  initTitle() {
    const list = this.sections.reduce((acc: any, section) => {
      const { attr } = section
      const title = attr?.is_navigation ? attr?.nav_title : ''
      if (title) {
        acc.push({
          'data-meta-name': section?.meta?.name,
          name: `.${section?.meta?.name}`,
          title: attr.nav_title
        })
      }
      return acc
    }, [])
    this.list = list
  }

  created() {
    this.$root.$on('nav.delSection', (name: string) => {
      this.list = this.list.filter((v) => v.name !== name)
    })
  }

  mounted() {
    this.initTitle()
    const header = document.querySelector('.default-header')
    if (header && !this.isDesktop) {
      this.lockHeight = header.getBoundingClientRect().height
    }
  }
}
