






import { Component, Vue, State, Prop } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { getStandardDateFormat } from '~/share/data/datetime'

@Component
export default class BookingStartDate extends Vue {
  @Prop() startTime !: string
  @State klook !: Data.Klook

  get recently() {
    return ['today', 'tomorrow'].includes(this.date)
  }

  get date() {
    if (!this.startTime) {
      return 'soldOut'
    }

    const formattedDate = dayjs(this.startTime).format('YYYY-MM-DD')
    if (formattedDate === dayjs().format('YYYY-MM-DD')) {
      return 'today'
    } else if (formattedDate === dayjs().add(1, 'day').format('YYYY-MM-DD')) {
      return 'tomorrow'
    } else {
      return getStandardDateFormat(
        formattedDate,
        this.$t.bind(this),
        this.klook.language
      )
    }
  }
}
