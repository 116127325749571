



















import { Vue, Component, Prop, Getter } from 'nuxt-property-decorator'

@Component
export default class PackageTimeslotFailure extends Vue {
  @Getter isMobile!: boolean
  @Prop({ default: '' }) text!: string
}
