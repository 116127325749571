



























































import { Component, Ref, Vue, Prop } from 'nuxt-property-decorator'
import BannerGallery from './gallery/banner-gallery.vue'
import ExperienceActivityBanner from '~/components/experience-booking/experience-activity/banner/activity-banner.vue'
import ViewMore from '~/components/traveller/activity/activity-banner/mobile/view-more.vue'
import MobilePromotionBanner
  from '~/components/experience-booking/experience-activity/banner/mobile/promotion-banner.vue'

@Component({
  components: {
    ExperienceActivityBanner,
    ViewMore,
    BannerGallery,
    MobilePromotionBanner
  }
})
export default class Banner extends Vue {
  @Ref() banner!: Vue
  @Prop() banner_list!: any[]
  @Prop() activity_id!: number
  @Prop() count!: number
  @Prop({ default: false }) isTaVersion!: boolean

  isVideoPlaying = false
  visible = false
  activeGallery = 'merchant'

  get viewMoreVisible() {
    return this.isTaVersion || this.banner_list?.length > 1
  }

  get swiperOptions() {
    return {
      on: {
        slideChangeTransitionEnd: () => {
          if (!this.moreSlideVisible) { return {} }
          const swiper = (this.banner.$refs.$bannerSwiper as any)?.swiper
          if (!swiper) { return }

          const swiperList = swiper.snapGrid
          if (swiper.activeIndex === (swiperList.length - 2)) {
            swiper.slides[swiperList.length - 1].style.height = swiper.height + 'px'
            swiper.updateSize()
          }
        },
        touchMove: () => {
          if (!this.moreSlideVisible) { return {} }
          const swiper = (this.banner.$refs.$bannerSwiper as any)?.swiper
          if (!swiper) { return }

          const swiperList = swiper.snapGrid
          const swiperLen = swiperList[swiperList.length - 1]
          if (swiper.getTranslate() < -(swiperLen)) {
            if (swiper.isEnd) {
              this.$nextTick(() => {
                this.activeGallery = 'customer'
              })

              setTimeout(() => {
                swiper.slideTo(this.activityBanners.length - 1)
              }, 100)

              this.viewMore()
            }
          }
        }
      }
    }
  }

  get moreSlideVisible() {
    if (this.isTaVersion) {
      return false
    }
    return this.count > 11
  }

  get activityBanners() {
    return this.banner_list?.slice(0, 8)
  }

  changePlaying(isPlay: boolean) {
    this.isVideoPlaying = isPlay
  }

  videoPause() {
    this.isVideoPlaying = false
  }

  handleGalleryClose() {
    this.$nextTick(() => {
      this.activeGallery = 'merchant'
    })
    this.visible = false
  }

  updateType(val: string) {
    this.$nextTick(() => {
      this.activeGallery = val
    })
  }

  viewMore() {
    this.visible = true
  }
}
