



















import { Component, State, Prop } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import AutoAlignContent from '~/components/experience/auto-align-content/index.vue'

@Component({
  components: {
    AutoAlignContent
  }
})
export default class Index extends BusinessBase {
  @State klook!: Data.Klook
  @Prop({ default: () => [] }) list!: any[]

  leftMinWidth = 80

  stylesList = []

  setWidth() {
    const width = this.stylesList.reduce((num: number, obj: any) => {
      const { width } = obj || {}
      if (width > num) {
        return width
      }
      return num
    }, 0)
    if (width > this.leftMinWidth) {
      this.leftMinWidth = width
    }
  }

  updatedHandler(i: number, data: any) {
    this.$set(this.stylesList, i, data)
    this.setWidth()
  }
}
