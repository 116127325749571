import { render, staticRenderFns } from "./combo-warming-slogan.vue?vue&type=template&id=2ff0fe96&scoped=true&"
import script from "./combo-warming-slogan.vue?vue&type=script&lang=ts&"
export * from "./combo-warming-slogan.vue?vue&type=script&lang=ts&"
import style0 from "./combo-warming-slogan.vue?vue&type=style&index=0&id=2ff0fe96&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ff0fe96",
  null
  
)

export default component.exports