










import { Vue, Component, Prop, State } from 'nuxt-property-decorator'

@Component({
  components: {}
})
export default class ActicityValidity extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  @Prop({ type: Array, default: () => [] }) usageValidityRenderObj!: any[]
}
