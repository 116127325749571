


















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import AlertComponent from './alert.vue'
import eventBus from '~/pages/experience/pay/common/event-bus'

interface Position {
  x: number;
  y: number;
  width: number;
  height: number;
}
@Component({
  components: {
    AlertComponent
  }
})
export default class ShoppingCart extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop({ type: Object, default: () => ({}) }) customStyle!: object
  @Prop() target!: HTMLElement
  @Prop() visible!: boolean
  @Prop() position!: DOMRect | HTMLElement

  alertVisible = false
  nav: HTMLElement | null = null

  get cClass() {
    return this.isDesktop
      ? '.experience-layout-header .default-header_shopping-cart .default-header_entry'
      : '.experience-layout-header .shopcart .default-header_icon'
  }

  get isDesktop() {
    return this.platform === 'desktop'
  }

  beforeEnter(el: HTMLElement) {
    const position = this.getPostion()
    const { x, y, width, height } = position
    el.style.left = x + width / 2 - 10 + 'px'
    el.style.top = y + height / 2 - 10 + 'px'
    this.nav = document.querySelector('.page-activity-top-nav-sections') as HTMLElement
  }

  enter(el: HTMLElement, done: Function) {
    // eslint-disable-next-line no-unused-expressions
    el.offsetWidth

    const { x, y } = this.getTargetPosition()
    el.style.left = x + 'px'
    el.style.top = y + 'px'
    el.style.opacity = '0'
    // 兼容处理弹窗被遮住的情况
    this.isDesktop && this.nav && (this.nav.style.zIndex = '100')
    el.addEventListener('transitionend', () => {
      done()
      this.alertVisible = true
    })
  }

  afterEnter(el: HTMLElement) {
    this.$emit('update:visible', false)
    el.style.display = 'none'
    eventBus.$emit('refresh-shopping-cart')
    const timer = setTimeout(() => {
      this.alertVisible = false
      clearTimeout(timer)
      this.isDesktop && this.nav && (this.nav.style.zIndex = '999')
    }, 2000)
  }

  getTargetPosition() {
    const target = this.target ? this.target : document.querySelector(this.cClass) as HTMLElement

    return this.getRectInfo(target)
  }

  getRectInfo(el: HTMLElement) {
    return el.getBoundingClientRect && el.getBoundingClientRect()
  }

  getPostion() {
    let position: Position = { x: 0, y: 0, width: 0, height: 0 }
    if (!this.position && !this.isDesktop) {
      // 移动端以手机左下角为shoppingCart的起始点
      position = { x: 0, y: document.documentElement.clientHeight, width: 30, height: 15 }
    } else if (Object.prototype.toString.call(this.position) === '[object DOMRect]') {
      position = this.position as DOMRect
    } else {
      position = this.getRectInfo(this.position as HTMLElement)
    }
    return position
  }
}
