import { Component, Vue, Prop } from 'nuxt-property-decorator'
import reduce from 'lodash/reduce'
import ImageGallery from './image-gallery.vue'
import ImageGalleryNew from './image-gallery-new.vue'

import { urlTemplate } from '~/share/utils'
import apis from '~/share/data/apis'

@Component({
  components: {
    ImageGallery,
    ImageGalleryNew
  }
})
export default class CustomerImageGallery extends Vue {
  @Prop() activityId!: number
  @Prop() transfer!: boolean
  @Prop({ default: 'ReviewCustomerPhotoTab' }) mtype!: string
  @Prop({ default: false }) notFixed!: boolean
  @Prop({ default: false }) showBuyNow!: boolean
  @Prop({ default: false }) newStyle!: boolean

  page = 1
  pageSize = 8
  images: any[] = []
  loading = false
  loadingError = false
  imageTotalCount = -1
  reviewImagesInfo: any[] = []
  imageReviewsMap: { [prop: string]: any } = []

  // 新图集
  // 结构: 二维数组，每项长度10，最后一项为余数,eg: [[10], [10], [3]]
  done: boolean = false
  imagesGroup: any [] = []
  cacheList: any [] = []

  async getReviewsImages() {
    if (!this.activityId) {
      return
    }

    if (this.imageTotalCount > 0 && this.images?.length >= this.imageTotalCount) {
      this.done = true
      return
    }

    this.loading = true
    this.loadingError = false

    let res = {} as any

    try {
      res = await this.$axios.$get(
        urlTemplate(apis.activityReviewGalleryImages, {
          activityId: this.activityId
        }),
        {
          regularUrl: '/v1/usrcsrv/activities/{*}/images/get',
          params: {
            page: this.page,
            source_from: this.mtype,
            limit: this.pageSize
          }
        }
      )
    } catch (error) {
      /* eslint-disable-next-line */
      console.log('activityReviewGalleryImages error: ', error)
    }

    const { result } = res
    if (res.success && result) {
      const { image_total_count: imageTotalCount, review_images_info: reviewImageInfo } = result
      if (imageTotalCount === 0 || !reviewImageInfo) {
        if (this.newStyle) {
          const group = this.initGroup([], true)
          this.imagesGroup = [...this.imagesGroup, ...group]
        }
        this.done = true
        return {}
      }

      /**
       * 评论reviews和图片image是一对多的关系
       * 通过图片image_id检索review_id，最后通过imageReviewMap得到review的信息
       * reviews的数据保存一份，因为有对数据对操作，这样可以保持所有数据的同步
       * @reviewImagesInfo Array 后端请求数据
       * @imageList Array 图片数组
       * @reviewsMap Object reviews和reviewId的map
       */

      const formatReviews = reduce(
        reviewImageInfo,
        (acc, v) => {
          acc.imageList = [
            ...acc.imageList,
            ...(v?.images ?? []).map((img: any) => ({ ...img, reviewId: v.review_id }))
          ]
          acc.reviewsMap = { ...acc.reviewsMap, [v.review_id]: v }

          return acc
        },
        {
          imageList: [] as any[],
          reviewsMap: {}
        }
      )

      const reviewImages = formatReviews.imageList.map((v: any) => ({
        ...v,
        src: v.img_url
      }))
      this.imageTotalCount = result.image_total_count
      this.images = [...this.images, ...reviewImages]
      this.imageReviewsMap = {
        ...this.imageReviewsMap,
        ...formatReviews.reviewsMap
      }
      this.page += 1

      if (this.newStyle) {
        const group = this.initGroup(reviewImages)
        this.imagesGroup = [...this.imagesGroup, ...group]
        // 首页如果图片少于10，再次获取数据，直到图片足够，或者无数据
        if (this.images.length < 10) {
          this.getReviewsImages()
        }
      }

      this.loading = false
    } else {
      this.loading = false
      this.loadingError = true
    }
  }

  buyNow() {
    this.$emit('buy-now')
  }

  mounted() {
    this.getReviewsImages()
  }

  initGroup(list: any = [], final: boolean = false) {
    const { imageTotalCount: total, images, cacheList } = this
    const group = this.groupImages([...cacheList, ...list])
    const [last = []] = group.slice(-1)

    // 数据加载完成，或者最后一组数据刚好10条则展示全部数据，否则不展示最后一组数据，等下次请求再重新计算
    if (images.length >= total || last.length === 10 || final) {
      this.cacheList = []
      return group
    } else {
      this.cacheList = [...last]
      return group.slice(0, -1)
    }
  }

  groupImages(images: any [] = []) {
    return images.reduce((acc, curr, index) => {
      const id = index
      const idx = parseInt(String(index / 10))
      const data = {
        ...curr,
        id
      }
      if (acc[idx]) {
        acc[idx].push(data)
      } else {
        acc[idx] = [data]
      }
      return acc
    }, [])
  }

  render() {
    const Component = this.newStyle ? 'ImageGalleryNew' : 'ImageGallery'
    return (
      <Component
        images={this.images}
        images-group={this.imagesGroup}
        reviewsMap={this.imageReviewsMap}
        loading={this.loading}
        loadingError={this.loadingError}
        loadImage={this.getReviewsImages}
        transfer={this.transfer}
        notFixed={this.notFixed}
        showBuyNow={this.showBuyNow}
        buyNow={this.buyNow}
        photoType="CustomerPhoto"
        done={this.done}
      />
    )
  }
}
