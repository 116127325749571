




















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'
import ActivityBookingStartDate from '~/components/traveller/activity/start-date/activity-booking-start-date.vue'

@Component({
  components: {
    ActivityBookingStartDate
  }
})
export default class DynamicVerticalIcons extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() icons!: ExperienceActivity.IDynamicIconData[]
  @Prop({ default: true }) availableDateVisible!: boolean

  get cClass() {
    const prefix = 'dynamic-icons'
    return [prefix, `${prefix}_${this.platform}`]
  }
}
