












import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IconNext } from '@klook/klook-icons'
import DesktopPackageDetailButton from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-button.vue'

@Component({
  components: {
    DesktopPackageDetailButton,
    IconNext
  }
})
export default class IconTime extends Vue {
  @Prop({ default: {} }) packageData!: any
  @Prop({ default: true }) isTrackItem!: boolean

  click() {
    this.$emit('clickPkgDetail', true)
  }

  getDetailsBtnTrackInfo() {
    return this.isTrackItem
      ? {
        'data-spm-module': 'PackageDetailsBtn?trg=manual',
        'data-spm-virtual-item': '__virtual'
      }
      : {
        'data-spm-module': `PackageType_LIST?oid=package_${this.packageData.package_id}&idx=0&len=1`,
        'data-spm-virtual-item': '__virtual'
      }
  }
}
