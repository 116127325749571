










import { Component, Vue, Prop } from 'nuxt-property-decorator'
import ActMarkdown from '~/components/experience-booking/experience-activity/markdown/index.vue'

@Component({
  components: {
    ActMarkdown
  }
})
export default class DynamicPlainMarkdown extends Vue {
  @Prop() content!: string[]
}
