import { Component, Prop, Vue, State } from 'nuxt-property-decorator'
import { defalutUlr, defalutDesktopUlr } from './data'
import {
  IPackageItem,
  FNdPackageGroup,
  FnbPackage
} from '~/components/experience-booking/experience-activity/package-options/fnb-package-options/type'

@Component({})
export default class PackageOptionsFnbBase extends Vue {
  @State klook!: Data.Klook
  @Prop() isFnb!: boolean
  @Prop({ default: 0 }) preview!: number
  @Prop({ default: [] }) packageList!: IPackageItem[]
  @Prop({ default: [] }) fnbPackages!: IPackageItem[]
  @Prop() packageId!: number

  showAllStates: { [key: number]: boolean } = {}

  get merrryData(): IPackageItem[] {
    const idToItemMap: { [key: number]: FnbPackage } = {};

    (this.fnbPackages || []).forEach((item) => {
      idToItemMap[item.package_id] = item
    })

    return this.packageList.map((packages) => {
      const fnbPackage = idToItemMap[packages.package_id]
      if (fnbPackage) {
        return { ...packages, ...fnbPackage }
      } else {
        return packages
      }
    })
  }

  get groupedPackages(): FNdPackageGroup[] {
    // 9和11为 other分组，其他按照package_type_name分组
    const OTHER_GROUP = '9-11'
    const groups: { [key: string]: IPackageItem[] } = {};
    // 分组接口只返回已发布的套餐，后来这个需求改成可显示的套餐，等https://ones.klook.io/project/#/team/BtSACWh2/task/96j139CvlJTSa1Lq发布可以解决这个问题
    (this.merrryData || []).forEach((item: IPackageItem) => {
      const key =
        item.package_type === 9 || item.package_type === 11
          ? OTHER_GROUP
          : item.package_type_name || (this.preview === 1 ? this.$t('123082') : '')
      groups[key] = groups[key] || []
      groups[key].push(item)
    })

    // other分组放在最后
    const sortedKeys = Object.keys(groups).sort((a, b) => {
      if (a === OTHER_GROUP) {
        return 1
      }
      if (b === OTHER_GROUP) {
        return -1
      }
      return 0
    })

    return sortedKeys.map((key) => {
      const isSpecialGroup = key === OTHER_GROUP
      const title = isSpecialGroup
        ? groups[key].find((item) => item.package_type === 9 || item.package_type === 11)
            ?.package_type_name || ''
        : key

      return {
        title,
        type: key,
        items: groups[key],
        noIamges: groups[key].every((item) => !this.hasIamge(item))
      }
    })
  }

  toggleShowAll(index: number): void {
    this.$set(this.showAllStates, index, !this.showAllStates[index])
  }

  // 无图展示展位图
  hasIamge(item: IPackageItem): boolean {
    return item.package_images && item.package_images.length > 0
  }

  // 获取图片
  getImageUrl(item: IPackageItem): string {
    const url = this.klook.platform === 'mobile' ? defalutUlr : defalutDesktopUlr
    return item?.package_images?.[0]?.image_url_host || url
  }
}
