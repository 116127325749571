













import { Component, Vue, State, Prop } from 'nuxt-property-decorator'

@Component
export default class NoneSearchResult extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop({ default: '' }) text!: string
  @Prop({ default: '' }) size!: string

  get isSmall() {
    return this.size === 'small' || this.platform === 'mobile'
  }
}
