import xssFilters from 'xss-filters'
import { percent } from '~/share/utils'
import { clearImgSpecialCharacters, getImageName } from '~/share/data/image'
import { getActivityBreadcrumbs, activityBreadcrumbDetail } from '~/components/traveller/activity/activity-breadcrumbs/helper'
import { ExperienceActivity } from '~/types/experience/activity-section'

function getDate(date: string) {
  return date.slice(0, date.indexOf(' '))
}

function getBreadcrumbsVueMeta(json: any) {
  return {
    type: 'application/ld+json',
    json
  }
}

export function activityVideo({
  title,
  description,
  lastUpdatedTime,
  siteName,
  videoUrl,
  bannerImage,
  webp
}: {
  title: string,
  description: string,
  lastUpdatedTime: string,
  siteName: string,
  videoUrl: string,
  bannerImage: string,
  webp: boolean
}) {
  title = xssFilters.inHTMLData(title)
  description = xssFilters.inHTMLData(description)
  siteName = xssFilters.inHTMLData(siteName)
  const contentUrl = xssFilters.inHTMLData(videoUrl)
  const thumbnailName = `${clearImgSpecialCharacters(bannerImage)}${clearImgSpecialCharacters(title)}${webp ? '.webp' : '.jpg'}`
  const thumbnailUrl = `https://res.klook.com/images/fl_lossy.progressive,q_65/c_fill,w_1200,h_630,f_auto/w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/activities/${thumbnailName}`

  return getBreadcrumbsVueMeta({
    '@context': 'http://schema.org',
    '@type': 'VideoObject',
    name: title,
    description,
    thumbnailUrl,
    uploadDate: getDate(lastUpdatedTime),
    publisher: {
      '@type': 'Organization',
      name: siteName,
      logo: {
        '@type': 'ImageObject',
        url: 'https://res.klook.com/image/upload/c_scale,h_60,w_300/v1490601012/280aa487-logo.png',
        width: 300,
        height: 60
      }
    },
    contentUrl
  })
}

interface itemListElementItem {
  '@id': string,
  name: string
}

interface itemListElement {
  '@type': string,
  position: number,
  item: itemListElementItem
}

function getLdJson() {
// 面包屑: 首页 > 国家 > 城市 > cate > tag
  return {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [] as itemListElement[]
  }
}

export function activityMain(detail: activityBreadcrumbDetail) {
  const { cityName, countryName } = detail

  // 这里结构基本不会变
  const itemListElementMap = {
    index: {
      '@type': 'ListItem',
      position: 1,
      item: {}
    },
    country: {
      '@type': 'ListItem',
      position: 2,
      item: {}
    },
    city: {
      '@type': 'ListItem',
      position: cityName === countryName ? 2 : 3,
      item: {}
    },
    frontList0: {
      '@type': 'ListItem',
      position: cityName === countryName ? 3 : 4,
      item: {}
    },
    frontList1: {
      '@type': 'ListItem',
      position: cityName === countryName ? 4 : 5,
      item: {}
    },
    frontList2: {
      '@type': 'ListItem',
      position: cityName === countryName ? 5 : 6,
      item: {}
    }
  }

  const activityBreadcrumbList = getActivityBreadcrumbs(detail)

  const ldJson = getLdJson()
  // 将页面面包屑数据map成seo面包屑数据结构
  const activityBreadcrumbs = activityBreadcrumbList.reduce((acc, v) => {
    const { id, name, path } = v
    acc.itemListElement.push({
      ...itemListElementMap[id] as itemListElement,
      item: {
        '@id': `https://www.klook.com${path}`,
        name
      }
    })
    return acc
  }, ldJson)

  return getBreadcrumbsVueMeta(activityBreadcrumbs)
}

export function activityProduce({
  title,
  activityId,
  seoDescription,
  reviewCount,
  latestBestReview,
  sellingPrice,
  score,
  canonicalPath,
  currency,
  images,
  usageImages,
  bannerImage,
  isNoWatermarkActivity
}: {
  title: string,
  activityId: number,
  seoDescription: string,
  reviewCount: number,
  latestBestReview: { rating: number, author: string, content: string, date: string },
  sellingPrice: number,
  score: number,
  canonicalPath: string,
  currency: string,
  images: any,
  usageImages: any,
  bannerImage: string,
  isNoWatermarkActivity: boolean
}) {
  const waterMark = isNoWatermarkActivity ? '' : '/w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3'
  const fileTitle = clearImgSpecialCharacters(title)
  const bannerImageUrl = `https://res.klook.com/images/fl_lossy.progressive,q_65/c_fill,w_1200,h_630,f_auto/activities/${clearImgSpecialCharacters(bannerImage)}/${fileTitle}.jpg`
  const produceImages = [...(images || []), ...(usageImages || [])].map((v: any) => {
    const fileName = getImageName(v.url || v.image_url)
    return `https://res.klook.com/images/fl_lossy.progressive,q_65/c_fill,w_${v.width},h_${v.height},f_auto${waterMark}/activities/${fileName}/${fileTitle}.jpg`
  })

  const breadcrumbs: any = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: title,
    mpn: activityId,
    sku: activityId,
    description: seoDescription,
    image: [bannerImageUrl, ...produceImages],
    brand: {
      '@type': 'Brand',
      name: 'Klook'
    }
  }

  if (reviewCount > 0) {
    breadcrumbs.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: score,
      reviewCount,
      bestRating: '5',
      worstRating: '1'
    }
  }

  if (latestBestReview) {
    breadcrumbs.review = {
      '@type': 'Review',
      reviewRating: {
        '@type': 'Rating',
        ratingValue: percent(latestBestReview.rating, 2000)
      },
      name: title,
      author: {
        '@type': 'Person',
        name: xssFilters.inHTMLData(latestBestReview.author)
      },
      datePublished: getDate(latestBestReview.date),
      reviewBody: xssFilters.inHTMLData(latestBestReview.content)
    }
  }

  if (sellingPrice > 0) {
    breadcrumbs.offers = {
      '@type': 'Offer',
      url: `https://www.klook.com/${canonicalPath}`,
      priceCurrency: currency,
      price: sellingPrice,
      itemCondition: 'http://schema.org/UsedCondition',
      availability: 'http://schema.org/InStock'
    }
  }

  return getBreadcrumbsVueMeta(breadcrumbs)
}

export function experienceActivityBreadCrumb(breadCrumb: ExperienceActivity.IBreadCrumb[]) {
  const itemListElement = breadCrumb?.map((v, index) => {
    return {
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id': v.deep_link,
        name: v.name
      }
    }
  })

  return getBreadcrumbsVueMeta({
    ...getLdJson(),
    itemListElement
  })
}
