
import { Component, Prop } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import apis from '~/share/data/apis'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({})
export default class LineUpBase extends BusinessBase {
  @Prop() basicData!: ExperienceActivity.IBasicData

  get activityId() {
    return this.basicData.activity_id
  }

  lineUpInfo: any = {
    key: '',
    flash_sale_status: {}
  }

  updateTimer: any
  async getLineUpInfo() {
    const key = this.businessData.key || this.lineUpInfo.key || ''
    const activityId = this.activityId
    const result = await this.$axios.$get(apis.getActivityLineInfo, {
      params: {
        activity_id: activityId,
        key
      }
    })
    if (!result.success) {
      return
    }
    const lineUpInfo = result.result
    // 排队成功后弹出提示
    const needTips = window.sessionStorage.getItem('lineUpTips') || '0'
    if (needTips === '1') {
      this.$toast({
        message: this.$t('78623'),
        duration: 3000
      })
    }
    window.sessionStorage.removeItem('lineUpTips')
    // 调试代码
    // lineUpInfo.flash_sale_status.user_status = 3
    // lineUpInfo.sold_out = true
    // this.canJoin = true
    const timeout = lineUpInfo.next_fresh_time || 60
    clearTimeout(this.updateTimer)
    this.updateTimer = setTimeout(() => {
      this.getLineUpInfo()
    }, timeout * 1000)
    this.lineUpInfo = lineUpInfo
    this.timeCountDown()
    return result
  }

  beforeDestroy() {
    clearInterval(this.timer)
    clearTimeout(this.updateTimer)
  }

  created() {
    this.$nextTick(() => {
      this.getLineUpInfo()
    })
  }

  reload() {
    this.$router.go(0)
  }

  timeCount: string = ''
  timer: any
  canJoin: boolean = false
  timeCountDown() {
    const { current_time } = this.lineUpInfo
    const statusTime = this.getEndTime()
    if (!statusTime) {
      return
    }
    const start = (new Date(statusTime)).getTime()
    const current = (new Date(current_time)).getTime()
    let second = parseInt(((start - current) / 1000) + '')
    // second = 10
    clearInterval(this.timer)
    this.timer = setInterval(() => {
      second--
      this.timeCount = this.getTimeString(second)
      // 提前结束定时任务，防止界面展示出现问题
      if (second < 20) {
        clearTimeout(this.updateTimer)
      }

      if (second <= 0) {
        clearInterval(this.timer)
        if (this.userStatus === 1) {
          this.canJoin = true
          return
        }
        if (this.userStatus === 2) {
          this.reload()
          window.sessionStorage.setItem('lineUpTips', '1')
          return
        }

        if (this.userStatus === 3) {
          this.$toast({
            message: this.$t('78626'),
            duration: 3000
          })
          // 购买倒计时完成后刷新界面
          if (!this.basicData.is_line_page) {
            setTimeout(() => {
              this.reload()
            }, 3000)
            return
          }
          this.lineUpInfo.flash_sale_status.user_status = 1
          this.canJoin = true
        }
      }
    }, 1000)
  }

  getEndTime() {
    const {
      start_time,
      estimated_effective_time,
      flash_sale_status: { user_status },
      expired_time
    } = this.lineUpInfo
    switch (user_status) {
      case 1:
        return start_time
      case 2:
        return estimated_effective_time
      case 3:
        return expired_time
      default :
        return ''
    }
  }

  getTimeString(time: number) {
    const h = this.getString(parseInt(String(time / (60 * 60))))
    const extH = time % (60 * 60)
    const min = this.getString(parseInt(String(extH / 60)))
    const s = this.getString(parseInt(String(extH % 60)))
    if (!this.basicData.is_line_page || this.userStatus === 3) {
      return `${min}:${s}`
    }
    return `${h}:${min}:${s}`
  }

  getString(h: number) {
    const str = String(h)
    return str[1] ? str : `0${str}`
  }
  // get activityId() {}

  get userStatus() {
    const lineUpInfo = this.lineUpInfo
    return lineUpInfo.flash_sale_status.user_status
  }
}
