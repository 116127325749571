import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import KlkExperienceItinerary from '@klook/klook-experience-itinerary'
import '@klook/klook-experience-itinerary/dist/esm/index.css'
// import ExpTagging from '~/components/experience/tagging/index.vue'

@Component
export default class KlookExperienceItinerary extends Vue {
  @State(state => state.klook.language) language!: Data.Language
  @State(state => state.klook.platform) platform!: Data.Platform
  @State(state => state.klook.ipCountry === 'CN' ? 'mapbox' : 'google') mapType!: 'mapbox' | 'google'
  @Prop() itineraryData!: any
  @Prop() size!: 'large' | 'small'
  @Prop() showImageFn!: Function
  @Prop({ default: false }) simplified!: boolean
  @Prop({ default: false }) hackCloseBtn!: boolean
  @Prop({ type: String, default: '' }) componentType!: string
  @Prop({ type: Boolean, default: false }) useImageSwiper!: boolean
  @Prop({ type: Number, default: 248 }) cardSwiperItemWidth!: number
  @Prop({ type: String, default: '' }) imageGroupStyle!: string
  @Prop({ type: Object, default: null }) pickupInfos!: any

  getTranslateFunc() {
    return this.$t.bind(this)
  }

  getAxiosFunc() {
    return this.$axios
  }

  inhouseTrack(event: string, elem: string, opt: any = {}) {
    this.$inhouse.track(event, elem, opt)
  }

  get useItineraryMap() {
    const itineraryMap = this.itineraryData?.pois?.poi_data ?? []
    const isMobile = this.platform === 'mobile'
    const validate = isMobile && Array.isArray(itineraryMap) && itineraryMap.length > 0
    return validate
  }

  // get customComp() {
  //   return {
  //     tagComponent: ExpTagging
  //   }
  // }

  grounpTitleClick(data: any) {
    this.$emit('grounpTitleClick', data)
  }

  handlePickUpClick() {
    this.$emit('pickUpClick')
  }

  render() {
    return (
      <KlkExperienceItinerary
        language={this.language}
        hackCloseBtn={this.hackCloseBtn}
        showImageFn={this.showImageFn}
        itineraryData={this.itineraryData}
        getTranslateFunc={this.getTranslateFunc}
        getAxiosFunc={this.getAxiosFunc}
        platform={this.platform}
        size={this.size}
        useItineraryMap={this.useItineraryMap}
        mapType={this.mapType}
        simplified={this.simplified}
        componentType={this.componentType}
        grounpTitleClick={this.grounpTitleClick}
        inhouseTrack={this.inhouseTrack}
        useImageSwiper={this.useImageSwiper}
        cardSwiperItemWidth={this.cardSwiperItemWidth}
        imageGroupStyle={this.imageGroupStyle}
        onPickUpClick={this.handlePickUpClick}
        pickupInfos={this.pickupInfos}
      />
    )
  }
}
