























import { Vue, Component, State, Prop } from 'nuxt-property-decorator'

@Component
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop() list!: any[]

  configObj = {
    mode: 'determinate',
    'background-color': '#eee',
    color: '#f09b0a'
  }

  get calcList() {
    const arr = (this.list || []).map((item: any) => {
      const { name: text, average_score: value } = item
      const percent = parseFloat(value) / 5 * 100
      const val = value?.toFixed(1)
      return { text, value: val, percent }
    })
    return arr
  }
}
