




















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'

@Component
export default class PackageDetailEmpty extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: 0 }) showGoSpec!: number

  mounted() {
    const elem = this.$el as any
    this.$emit('mounted', elem.offsetHeight)
  }
}
