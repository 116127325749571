









import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { IconStar } from '@klook/klook-icons'

@Component({
  components: {
    IconStar
  }
})
export default class Score extends Vue {
    @Prop() score!: string
    @Prop({ default: 12 }) size!: number
    @State(state => state.klook.platform) platform!: Data.Platform

    get cClass() {
      return `keynote-score_${this.platform}`
    }
}
