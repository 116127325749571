



































































import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { Schedule } from '../../types'
import CalendarHeader from '~/components/experience-booking/activity/activity-calendar/mobile/calendar-header.vue'
import MiniCalendar from '~/components/experience-booking/activity/activity-calendar/mobile/mini-calendar.vue'
import ActivitySkeleton from '~/components/traveller/activity/activity-skeleton.vue'
import PackageDetailFailure
  from '~/components/traveller/activity/activity-package-options/mobile/package-detail/status/package-detail-failure.vue'
import ActivityDataPicker from '~/components/experience-booking/experience-activity/package-options/activity-calendar/activity-date-picker.vue'
import ActivityModal from '~/components/experience-booking/experience-activity/activity-modal/index.vue'

@Component({
  components: {
    CalendarHeader,
    MiniCalendar,
    ActivityDataPicker,
    ActivitySkeleton,
    PackageDetailFailure,
    ActivityModal
  }
})
export default class ActivityMiniCalendar extends Vue {
  @Prop() schedules!: Schedule[]
  @Prop() value!: string
  @Prop({ default: 'fetching' }) state!: string
  @Prop({ default: 14 }) limit!: number
  @Prop() warn!: string

  visible = false

  @Watch('visible')
  datePickerVisibleChange(val: boolean) {
    if (val && process.client) {
      setTimeout(() => {
        this.calculateHeight()
      }, 80)
    }
  }

  showDatePicker() {
    if (this.state !== 'success') {
      return
    }

    this.visible = true
  }

  close() {
    this.visible = false
  }

  get validDates() {
    const days = this.limitDays(this.limit, this.schedules)
    const schedules = this.schedules.slice(0, this.limit)
    const d = days.map((item: string) => {
      const val = schedules.find((day: Schedule) => {
        return item === day.date
      })

      return val || {
        date: item,
        selling_price: '',
        soldout: false,
        stocks: [],
        disable: true
      }
    })
    return d
  }

  // 从第一个可预订日期开始，延后14天
  // 不能晚于schedules的最后一天，所以可能少于14个
  limitDays(limit: number, schedules: Schedule[]) {
    const validDayDate = schedules.find((day: Schedule) => !day.soldout)?.date
    const lastDayDate = schedules[this.schedules.length - 1]?.date
    const arr = []

    if (validDayDate) {
      for (let i = 0; i < limit; i++) {
        const date = dayjs(validDayDate).add(i, 'day')
        if (!date.isAfter(lastDayDate, 'day')) {
          arr.push(date.format('YYYY-MM-DD'))
        }
      }
    } else {
      for (let i = 0; i < limit; i++) {
        arr.push(dayjs().add(i, 'day').format('YYYY-MM-DD'))
      }
    }

    return arr
  }

  handleChange(item: string) {
    if (item === this.value) { return }

    this.close()

    this.$emit('input', item)
  }

  handleReload() {
    this.$emit('reload')
  }

  calculateHeight() {
    const wrapperDom = document?.querySelector('.activity-date-picker-modal .klk-bottom-sheet-body') as any
    const warningDom = document?.querySelector('.activity-date-picker-modal .activity-date-picker_warn') as any
    const pickerDom = document?.querySelector('.activity-date-picker-modal .experience-data-picker') as any
    const wrapperHeight = wrapperDom?.offsetHeight || 0
    const warningHeight = warningDom?.offsetHeight || 0
    const pickerHeight = wrapperHeight - warningHeight - 10

    if (pickerHeight > 300 && pickerDom) {
      pickerDom.style.height = `${pickerHeight}px`
    }
  }
}
