








import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import ActivityViewMore from '~/components/traveller/activity/activity-head-info/activity-view-more.vue'

@Component({
  components: {
    ActivityViewMore
  }
})
export default class ActivitySubtitle extends Vue {
  @State klook!: Data.Klook
  @Prop() height!: number
  @Prop() subtitle!: string
}
