













import { Component, Vue, Prop, State } from 'nuxt-property-decorator'

@Component
export default class PackageDetailTitle extends Vue {
  @Prop() actTitle!: string
  @Prop() pkgTitle!: string
  @State klook!: Data.Klook
}
