







import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IconNext } from '@klook/klook-icons'

@Component({
  components: {
    IconNext
  }
})
export default class MobilePackageDetailButton extends Vue {
  @Prop() customType!: string
  @Prop() text!: string
}
