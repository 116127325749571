













import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import ExpPioList from '~/components/experience-booking/experience-activity/activity-cards-list/poi-theme-list/list.vue'
import ActivityCardsListWrap from '~/components/experience-booking/experience-activity/activity-cards-list/activity-cards-list-wrap.vue'

@Component({
  components: {
    ExpPioList,
    ActivityCardsListWrap
  }
})
export default class ThemeActivity extends BusinessBase {
  static displayName = 'Theme_list'

  get calcPoiList() {
    // return [
    //   { theme_name: 'Explore Phi Phi Island with toursasdfa adf adf asdf adf adf ', theme_name_en: 'Explore Phi Phi Island with tours', city_name: 'Phuket', deep_link: '###', image: { url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/activities/i0yb6ncnh9vhexuyqcxc.png', alt: 'alt' } },
    //   { theme_name: 'Explore Phi Phi Island with tours', theme_name_en: 'Explore Phi Phi Isla', city_name: 'Phuket Phuket Phuket Phuket Phuket Phuket Phuket Phuket Phuket Phuket Phuket Phuket ', deep_link: '###', image: { url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/activities/i0yb6ncnh9vhexuyqcxc.png', alt: 'alt' } },
    //   { theme_name: 'Explore Phi Phi Island with tours', theme_name_en: 'Explore Phi Phi Island with tours', city_name: 'Phuket ad fad fadf adf adf adsf asdf asdf a', deep_link: '###', image: { url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/activities/i0yb6ncnh9vhexuyqcxc.png', alt: 'alt' } },
    //   { theme_name: 'Explore Phi', theme_name_en: 'Explore Phi', city_name: 'Phuket', deep_link: '###', image: { url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/activities/i0yb6ncnh9vhexuyqcxc.png', alt: 'alt' } },
    //   { theme_name: 'Explore Phi Phi Island with tours adf adf adf adf ', theme_name_en: 'Explore Phi Phi Island with tours ad fadf asdf adf adf adf ', city_name: 'Phuket  adf adsf asdf adsf asdf asdf adf asdf asdf ads f', deep_link: '###', image: { url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/activities/i0yb6ncnh9vhexuyqcxc.png', alt: 'alt' } },
    //   { theme_name: 'Explore Phi Phi Island with toursasdfa adf adf asdf adf adf ', theme_name_en: 'Explore Phi Phi Island with tours', city_name: 'Phuket', deep_link: '###', image: { url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/activities/i0yb6ncnh9vhexuyqcxc.png', alt: 'alt' } },
    //   { theme_name: 'Explore Phi Phi Island with tours', theme_name_en: 'Explore Phi Phi Isla', city_name: 'Phuket Phuket Phuket Phuket Phuket Phuket Phuket Phuket Phuket Phuket Phuket Phuket ', deep_link: '###', image: { url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/activities/i0yb6ncnh9vhexuyqcxc.png', alt: 'alt' } },
    //   { theme_name: 'Explore Phi Phi Island with tours', theme_name_en: 'Explore Phi Phi Island with tours', city_name: 'Phuket ad fad fadf adf adf adsf asdf asdf a', deep_link: '###', image: { url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/activities/i0yb6ncnh9vhexuyqcxc.png', alt: 'alt' } },
    //   { theme_name: 'Explore Phi', theme_name_en: 'Explore Phi', city_name: 'Phuket', deep_link: '###', image: { url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/activities/i0yb6ncnh9vhexuyqcxc.png', alt: 'alt' } },
    //   { theme_name: 'Explore Phi Phi Island with tours adf adf adf adf ', theme_name_en: 'Explore Phi Phi Island with tours ad fadf asdf adf adf adf ', city_name: 'Phuket  adf adsf asdf adsf asdf asdf adf asdf asdf ads f', deep_link: '###', image: { url: 'https://res.klook.com/image/upload/fl_lossy.progressive,q_85/activities/i0yb6ncnh9vhexuyqcxc.png', alt: 'alt' } }
    // ]
    return this.businessData.list || []
  }
}
