







































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import ReviewUser from '~/components/experience-booking/experience-activity/recent-review/review-user.vue'
import Logo from '~/components/experience/card-swiper/logo.vue'
import { formatAvatarUrl } from '~/share/data/image'

@Component({
  components: {
    ReviewUser,
    Logo
  }
})
export default class RecentReviewCard extends Vue {
  @State klook!: Data.Klook
  @Prop() avatar!: string
  @Prop() name!: string
  @Prop() utcTime!: string
  @Prop() content!: string
  @Prop() imageList!: any[]
  @Prop({ default: false }) hideImage!: boolean
  @Prop({ default: 4 }) imageSize!: number

  get countText() {
    const len = this.imageList.length
    return len > this.imageSize ? `${len - this.imageSize}+` : ''
  }

  get slicedImageList() {
    return (this.imageList || []).slice(0, this.imageSize)
  }

  getAvatarUrl(avatar: string) {
    return formatAvatarUrl(avatar)
  }
}
