

























import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Logo from '~/components/experience/card-swiper/logo.vue'
import SeoLazyImage
  from '~/components/experience-booking/experience-activity/dynamic-component/image/seo-lazy-image.vue'

@Component({
  components: {
    Logo,
    SeoLazyImage
  }
})
export default class MerchantImage extends Vue {
  @Prop() photoType!: string
  @Prop({ default: () => [] }) images!: any []
  @Prop({ default: 110 / 3 }) logoWidth!: number

  preview(index: number) {
    this.$emit('preview', index)
  }

  imageHeight(width: number, height: number) {
    return `calc((100vw - 40px) * ${height} / ${width})`
  }
}

