















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity'

@Component

export default class PackageCardStatus extends Vue {
  @Prop({ default: 'default' }) status!: ExperienceActivity.PackageCardStatus
}
