//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CouponCard from './item.vue';
import componentWithBottomSheet from '../../utils/componentWithBottomSheet.ts';
import componentWithDialog from '../../utils/componentWithDialog.ts';
import allList from './all-list.vue';
import baseMixin from './baseMixin.js';

export default {
  inheritAttrs: false,
  components: {
    CouponCard,
  },
  mixins: [baseMixin],
  props: {
    couponList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    title: {
      type: String,
      default: '',
    },
    moreTitle: {
      type: String,
      default: '',
    },
    moreDeepLink: {
      type: String,
      default: '',
    },
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots['title-slot'];
    },
  },
  methods: {
    async viewMore() {
      const {result} = await this.realAxios.$get(this.moreDeepLink);
      console.log(result.items)
      if (!result.items.length) {
        return
      }
      const _this = this;
      const attrs = {
        parent: this,
        componentAttrs: {
          platform: this.realPlatform,
          couponList: result.items,
          mode: this.mode,
          modeColor: this.modeColor,
        },
      };
      if (this.realPlatform !== 'desktop' && this.realPlatform !== 'web') {
        componentWithBottomSheet(allList, {
          ...attrs,
          dialogAttrs: {
            title: _this.title || _this.__t('73160'),
            maskClosable: true
          },
        });
      } else {
        componentWithDialog(allList, {
          ...attrs,
          dialogAttrs: {
            title: _this.title || _this.__t('73160'),
            size: 'large',
            closable: true,
            overlayClosable: true,
            escPressClosable: true,
            scrollable: true,
            width: '900px',
          },
        });
      }
    },
  },
};
