



































import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class UspTag extends Vue {
  @Prop() content!: string
  @Prop() icon!: string
  @Prop({ default: '#00B371;' }) background!: string
  @Prop() tip!: { title: string, content: string }

  visible = false

  get backgroundStyle() {
    return {
      background: this.background
    }
  }

  handleClick() {
    if (!this.tip) { return }

    this.visible = true
  }
}
