











import { Component, Vue, Prop } from 'nuxt-property-decorator'
import PackageDetailSectionBlank from '~/components/common/package-detail-v2/sections/blank.vue'
import { transformData } from '~/components/common/package-detail-v2/sections/utils'

@Component({
  components: {
    PackageDetailSectionBlank
  }
})
export default class PackageDetailStructured extends Vue {
  @Prop({ default: () => ([]) }) sections!: any[]

  // 转换成 sectionData
  get sectionDataList() {
    return (this.sections || []).map(section => transformData(section))
  }
}
