




import { Component, mixins, Watch } from 'nuxt-property-decorator'
import UspTagList from './usp-tag-list.vue'
import BestPriceUspBanner from './best-price-usp-banner.vue'
import SrvUspBanner from './srv-usp-banner.vue'
import UobUspBanner from './uob-usp-banner.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import PromotionBase from '~/components/experience-booking/experience-activity/promotion/promotion-base'

@Component({
  components: {
    UspTagList,
    BestPriceUspBanner,
    SrvUspBanner,
    UobUspBanner
  }
})
export default class ActivityUspAbtest extends mixins(BusinessBase, PromotionBase) {
  static displayName = 'MobileUsp_emphasize'

  componentName = ''
  uspData = {}

  get bestPriceGuarantee() {
    return this.businessData?.best_price_guarantee
  }

  get isSrvActivity() {
    return this.businessData?.is_srv_activity
  }

  get activityCancellationType() {
    return this.businessData?.activity_cancellation_type
  }

  get price() {
    return this.businessData?.price
  }

  get visible() {
    return !!this.uspEmphasize && !this.isShowPromotionDetail
  }

  get uspEmphasize() {
    return this.businessData.usp_emphasize
  }

  get hasBestPriceGuarantee() {
    return this.bestPriceGuarantee
  }

  get hasFreeCancellation() {
    const activityCancellationType = this.activityCancellationType
    return activityCancellationType && activityCancellationType.length === 1 && activityCancellationType[0] === 9
  }

  get bestPricePackageData() {
    return {
      content: this.$t('23174'),
      icon: 'mobile-activity#usp-star',
      tip: {
        title: this.$t('23174'),
        content: this.$t('23175-bpg')
      },
      // 埋点数据 text, type
      text: 'BPG'
    }
  }

  get freeCancellationData() {
    return {
      content: this.$t('16900-FreeCancellation'),
      icon: 'mobile-activity#usp-price',
      tip: {
        title: this.$t('16900'),
        content: this.$t('26252-refund')
      },
      // 埋点数据 text, type
      text: 'FreeCancellation'
    }
  }

  get srvData() {
    return {
      content: this.$t('17651'),
      icon: 'mobile-activity#srv_xm_colorful',
      background: '#F4333D',
      tip: {
        title: this.$t('17651'),
        content: this.$t('26253-SRV')
      },
      // 埋点数据 text, type
      text: 'SRV'
    }
  }

  get uobData() {
    return {
      content: this.$t('26255-UOB'),
      icon: 'common#icon_service_cards_xs_fill',
      // 埋点数据 text, type
      text: 'UOBDiscount'
    }
  }

  get bpgWithoutPriceData() {
    return {
      ...this.bestPricePackageData,
      content: this.$t('23174')
    }
  }

  @Watch('visible', { immediate: true })
  visibleChange(val: boolean) {
    if (!val) {
      this.hideSection()
    }
  }

  created() {
    switch (this.uspEmphasize) {
      case 'bpg_and_fc': {
        const tagList = []

        if (this.hasBestPriceGuarantee) {
          tagList.push(this.bestPricePackageData)
        }

        if (this.hasFreeCancellation) {
          tagList.push(this.freeCancellationData)
        }

        if (!tagList.length) {
          break
        }

        this.componentName = 'UspTagList'
        this.uspData = {
          tagList,
          type: 'tag'
        }

        break
      }
      case 'bpg_only':
        if (!this.hasBestPriceGuarantee) { break }

        this.componentName = 'UspTagList'

        this.uspData = {
          tagList: [this.bestPricePackageData],
          type: 'tag'
        }

        break
      case 'bpg_without_price':
        if (!this.bestPriceGuarantee) { break }

        this.componentName = 'UspTagList'

        this.uspData = {
          tagList: [this.bpgWithoutPriceData],
          type: 'tag'
        }

        break
      case 'fc_only':
        if (!this.hasFreeCancellation) { break }

        this.componentName = 'UspTagList'

        this.uspData = {
          tagList: [this.freeCancellationData],
          type: 'tag'
        }

        break
      case 'bpg_with_price':
        if (!this.bestPriceGuarantee) { break }

        this.componentName = 'BestPriceUspBanner'
        this.uspData = {
          tagList: [this.bpgWithoutPriceData],
          type: 'banner'
        }

        break
      case 'srv_eligible':
        if (!this.isSrvActivity) { break }

        this.componentName = 'SrvUspBanner'

        this.uspData = {
          tagList: [this.srvData],
          type: 'banner'
        }

        break
      case 'srv_uob':
        if (!this.isSrvActivity) { break }

        this.componentName = 'UobUspBanner'

        this.uspData = {
          tagList: [this.uobData],
          type: 'banner'
        }

        break
      default:
        break
    }
  }
}
