























































import { Component, State, Getter } from 'nuxt-property-decorator'
import AttractionCard from './components/attraction-card.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import AttractionIncludedDesktop from '~/components/experience-booking/experience-activity/package-options/attraction-preview/desktop/attraction-preview-modal.vue'
import AttractionIncludedMobile from '~/components/experience-booking/experience-activity/package-options/attraction-preview/mobile/index.vue'
import AttractionPreview from '~/components/experience-booking/experience-activity/package-options/attraction-preview/mobile/attraction-preview-detail.vue'
import { imageFormat } from '~/share/utils'

const isRetina = true
const isHighQuality = true

@Component({
  components: {
    AttractionCard,
    AttractionIncludedDesktop,
    AttractionIncludedMobile,
    AttractionPreview
  }
})
export default class ActivtityPassAttraction extends BusinessBase {
  @State klook!: Data.Klook;
  @Getter platform!: string;
  @Getter isMobile!: boolean;

  static displayName = 'Pass_attractions';

  openIndex: number = -1;
  open = false;
  openDetail = false;
  // 用户详情，和定位
  standardActivityId = 0;

  get cardList() {
    const _cardList = this.businessData?.pass_standard_card_list || []
    return _cardList.map((card: any) => {
      const { banner } = card
      return {
        ...card,
        banner: imageFormat(banner, `${this.isDesktop ? 'desktop' : 'mobile'}PassAttractionsCard`, isRetina, isHighQuality)
      }
    })
  }

  get standardTotal() {
    return this.businessData?.standard_total || 0
  }

  get activityId() {
    return this.businessData?.activity_id || 0
  }

  handleSwitchShowPackage(index: number) {
    this.openIndex = this.openIndex === index ? -1 : index
  }

  handleToDetail(item: any, flag?: boolean) {
    if (this.isMobile) {
      this.openDetail = true
    } else {
      this.open = true
    }

    this.standardActivityId = flag ? item.id : item
  }

  handleToPreView() {
    this.open = true
    this.standardActivityId = 0
  }

  closeModal() {
    this.open = false
  }

  get displayTitle() {
    return this.$t('76895', { num: this.standardTotal })
  }

  get displayDetialTitle() {
    return this.$t('76898')
  }

  closeDetialModal() {
    this.openDetail = false
    this.standardActivityId = 0
  }
}
