


















import { Vue, Component, State } from 'nuxt-property-decorator'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
@Component
export default class Index extends Vue {
  @State klook!: Data.Klook
  get track() {
    return {
      type: 'module',
      spm: 'OpenDateIntro',
      exposure: false,
      query: {
        evt: this.klook.platform === 'desktop' ? 'mouseenter' : 'click'
      }
    }
  }

  title = ''
  content = ''
  created() {
    this.title = this.$t('49407')
    this.content = this.$t('49414')
  }

  clickTips() {
    this.$alert(this.content, {
      // title: this.title,
      okLabel: this.$t('49415')
    })
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }
}
