
























































import { Component, State } from 'nuxt-property-decorator'
import ActivityFaq from '~/components/experience-booking/experience-activity/faq/faq'
import ActivityMarkdown from '~/components/traveller/activity/activity-markdown.vue'

@Component({
  components: {
    ActivityMarkdown
  }
})
export default class DesktopActivityFaq extends ActivityFaq {
  static displayName = 'DesktopActivity_faq'
  @State klook!: Data.Klook

  isShowMore = true
  isShowMoreBtn = false

  get isWhiteLabelEnv() {
    return !!this.klook?.utilConfig?.isWhiteLabelEnv
  }

  get viewMoreText() {
    return this.isShowMore ? this.$t('city_page_view_more') : this.$t('collapse_all')
  }

  /* eslint-disable-next-line */
  async mounted() {
    /* base里面有个判断，所以这里加个延迟 */
    setTimeout(() => {
      const limit = this.klook.platform === 'desktop' ? 250 : 250
      const markdownDom = (this?.$refs?.markdown as Vue)?.$el
      if (markdownDom && markdownDom.scrollHeight > limit) {
        this.isShowMore = true
        this.isShowMoreBtn = true
      }
    }, 100)
  }

  showMore() {
    this.isShowMore = !this.isShowMore
  }
}
