



































import { Vue, Component, Prop, State, Watch } from 'nuxt-property-decorator'
import KlkStars from '~/components/traveller/activity/stars.vue'
import ActivityReviewsHelpful from '~/components/traveller/activity/activity-reviews/activity-reviews-helpful.vue'
import ReviewUser from '~/components/experience-booking/experience-activity/recent-review/review-user.vue'
import SeeMoreWithLines from '~/components/common/see-more-with-lines.vue'

@Component({
  components: {
    ActivityReviewsHelpful,
    KlkStars,
    ReviewUser,
    SeeMoreWithLines
  }
})
export default class ActivityGalleryCommentItem extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: Number, default: 8 }) contentMaxLine!: number
  @Prop({
    default: () => {
    }
  })
  comment!: any

  @Prop() spm!: string

  @Watch('comment')
  onCommentChanged() {
    this.showTranslationContent = true
  }

  showTranslationContent: boolean = true

  setAvatarUrl(avatar: string) {
    return 'https://cdn.klook.com/upload/img200X200/' + avatar
  }

  changeCommentLike(data: number) {
    this.$emit('changeCommentLike', data)
  }

  changeCommentContent() {
    this.showTranslationContent = !this.showTranslationContent
  }
}
