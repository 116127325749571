










































































































































































































import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Component, Vue, Prop, Watch, namespace, State } from 'nuxt-property-decorator'
import { cloneDeep } from 'lodash'
import HeaderTips from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/header-tips.vue'
import CalendarHeader from '~/components/experience-booking/activity/activity-calendar/mobile/calendar-header.vue'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'
import MiniCalendar from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/mini-calendar.vue'
import RangeDateTime from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/range-date-time/index.vue'
import CalendarPopup from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/calendar-popup.vue'
import TimePopup from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/time-popup.vue'
import MiniTime from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/mini-time.vue'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import eventBus from '~/pages/experience/pay/common/event-bus'
import StatementModal from '~/components/experience-booking/experience-activity/package-options/activity-calendar/mobile/statement-modal.vue'
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'
import { getStandardDateFormat } from '~/share/data/datetime'
// @ts-ignore
import { isWifiInvalidDate, getFormatDate, formatPackageSchedules, getLocalDate, createWorker, threadFn } from '~/share/utils-date.ts'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
const experience = namespace('experience')

export interface Schedule {
  selling_price: number,
  soldout: boolean,
  date: string,
  stocks: {
    from_price: string,
    market_price: string,
    package_id: number,
    selling_price: string,
    stock: number,
    to_price: string
  }[]
  disable?: boolean,
  time_slots?: any[]
}

@Component({
  components: {
    RangeDateTime,
    HeaderTips,
    CalendarHeader,
    MiniCalendar,
    CalendarPopup,
    MiniTime,
    TimePopup,
    CalendarLengends,
    CalenderTips,
    StatementModal
  }
})
export default class BookingCalendar extends Vue {
  @State klook!: Data.Klook
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf
  @Prop({ default: true }) verticalScroll!: boolean
  @Prop() templateId!: number
  @Prop() packageId!: number
  @Prop() currentSelectedPackage!: any
  @Prop() isNewOpenTicket!: boolean
  @Prop() schedules!: Schedule[]
  @Prop() selectedTime!: any
  @Prop() value!: string // date
  @Prop() rangeDate!: Date[] // range date
  @Prop() warn!: string
  // 在mini-xxx里面是固定不显示price的，这里就只控制在popup里面显不显示price
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: true }) isTrackItem!: boolean
  @Prop({ default: () => [] }) legends!: any[]
  @Prop() autoTimeSlot!: boolean
  @Prop({ default: '' }) inhousePage!: string
  @Prop({ default: false }) isPassReserve!: boolean

  dateLimit = 7
  timeLimit = 9
  calendarPopupVisible = false
  timePopupVisible = false
  pickupTimePopupVisible = false
  showStatementVisible = false
  legendsInfo: { title: string, desc: string } = {
    title: '',
    desc: ''
  }

  bsObjSelectTime = getBottomSheetConfig('pickup-return-config')

  dateObj: any = {
    cacheData: {},
    days: 0,
    minStock: 0,
    stocks: [],
    list: []
  }

  timeObj: any = {
    cacheData: {},
    key: '',
    in: {},
    out: {}
  }

  get inhousePageSpm() {
    const inhousePage = this.inhousePage
    return this.inhousePage ? { 'data-spm-page': inhousePage } : {}
  }

  initTimeObj(obj: any) {
    const { calcTimeExtra } = this
    if (calcTimeExtra?.in?.length === 1) {
      const list = calcTimeExtra.in[0]
      obj.in = { ...list, startEndFormatStr: this.getStartEndFormatStr(list) }
    }
    if (calcTimeExtra?.out?.length === 1) {
      const list = calcTimeExtra.out[0]
      obj.out = { ...list, startEndFormatStr: this.getStartEndFormatStr(list) }
    }
    return obj
  }

  hookUpdatedHandler() {
    this.$nextTick(() => {
      const ref: any = this.$refs.wifiSimFooterRef
      if (!ref) {
        return
      }
      const height = ref?.offsetHeight || 0
      if (ref?.parentElement) {
        ref.parentElement.style.minHeight = height && `${height + 2}px`
      }
    })
  }

  showDatePopup() {
    const { dateObj, timeObj } = this
    this.$set(dateObj, 'cacheData', cloneDeep(dateObj))
    this.$set(timeObj, 'cacheData', this.initTimeObj(cloneDeep(timeObj)))
    this.calendarPopupVisible = true
  }

  handleSelectPickupTime(data: any) {
    this.timeObj.in = data
    this.$emit('rangeConfirm', this.getSimCardParams({ startDate: this.value }))
  }

  formatStandardDate(date: string) {
    if (!date) {
      return ''
    }
    const d = getStandardDateFormat(
      date,
      this.$t.bind(this),
      this.klook.language,
      1
    )
    return d
  }

  get calcSelectDateTime() {
    const { timeObj, dateObj, formatStandardDate } = this
    const [startDate, endDate] = dateObj.list || []
    const startTime = (timeObj?.in?.startEndFormatStr || '').replace(/\s/g, '')
    const endTime = (timeObj?.out?.startEndFormatStr || '').replace(/\s/g, '')
    const obj = {
      in: {
        startEndFormatStr: startDate ? `${formatStandardDate(startDate)}${startTime ? ', ' + startTime : ''}` : ''
      },
      out: {
        startEndFormatStr: endDate ? `${formatStandardDate(endDate)}${endTime ? ', ' + endTime : ''}` : ''
      }
    }
    return obj
  }

  get pickupTimeList() {
    const timeIns = this.calcTimeExtra?.in || []
    return timeIns.map((v: any) => ({
      ...v,
      disabled: false,
      date: v.id
    }))
  }

  get validPickupTimeList() {
    return this.pickupTimeList.slice(0, this.timeLimit)
  }

  checkRangeDateValidate(dateList?: string[]) {
    const { dateObj, calcTimeExtra } = this
    const arr = dateList || dateObj.list || []
    const isb = arr.length === 2 && arr.every((v: any) => v)
    if (!isb) {
      return this.$t(calcTimeExtra ? '88950' : '13187')
    }
  }

  checkConfirm({ date }: any = {}) {
    const msg = this.checkRangeDateValidate(date)
    if (msg) {
      this.$toast(msg)
      return false
    }
    if (!this.checkRangeTimeValidate()) {
      this.$toast(this.$t('88950'))
      return false
    }
    return true
  }

  updateRangeDate(dateList: string[]) {
    const { dateObj } = this
    const [startDate, endDate] = dateList || []
    const obj = this.getArrangementIdsAndStocks(startDate, endDate)
    dateObj.list = dateList
    dateObj.minStock = obj.minStock
    dateObj.days = obj.days
    dateObj.stocks = obj.stocks
  }

  getWifiParams() {
    const { dateObj, timeObj } = this
    const [startDate, endDate] = dateObj.list || []
    const specParams = {
      days: this.getTotalBookingDays(),
      date: startDate || '', // YYYY-MM-DD
      start_date: startDate ? `${startDate} 00:00:00` : '', // YYYY-MM-DD HH:mm:ss
      end_date: endDate ? `${endDate} 00:00:00` : '', // YYYY-MM-DD HH:mm:ss
      start_time_type_id: timeObj?.in?.id || 0,
      end_time_type_id: timeObj?.out?.id || 0,
      minStock: dateObj.minStock
    }
    return specParams
  }

  validateSimCardParams() {
    const { timeObj } = this
    const isb = this.calcTimeExtra && !timeObj?.in?.id
    return isb && this.$t('88950')
  }

  getSimCardParams({ startDate }: any) {
    const { timeObj } = this
    const specParams = {
      date: startDate || '', // YYYY-MM-DD
      start_date: startDate ? `${startDate} 00:00:00` : '', // YYYY-MM-DD HH:mm:ss
      start_time_type_id: timeObj?.in?.id || 0,
      end_date: '',
      end_time_type_id: 0,
      validateFn: this.validateSimCardParams
    }
    return specParams
  }

  clickDateTimeConfirm() {
    const date = this.dateObj.cacheData.list
    if (!this.checkConfirm({ date })) {
      return
    }
    this.updateRangeDate(date)
    this.timeObj = cloneDeep(this.calcCacheTimeObj)
    this.$emit('rangeConfirm', this.getWifiParams())
    this.calendarPopupVisible = false
  }

  checkRangeTimeValidate() {
    const ref: any = this.$refs.rangeTimeRef
    if (!ref) {
      return true
    }
    const isb = ref.checkValidate && ref.checkValidate()
    return isb
  }

  hideRangeTimeValidate() {
    this.bsObjSelectTime.visible = false
    this.checkRangeTimeValidate()
  }

  get calcCacheDateObj() {
    return this.dateObj.cacheData || {}
  }

  get calcCacheTimeObj() {
    return this.timeObj.cacheData || {}
  }

  clickSelectTimeHandler(key: string) {
    const { calcCacheTimeObj: timeObj } = this
    if (timeObj.key === key) {
      this.hideRangeTimeValidate()
    } else {
      this.$set(timeObj, 'key', key)
    }
  }

  selectRangeTime(key: string, item: any) {
    const { calcCacheTimeObj: timeObj, calcTimeExtra } = this
    this.$set(timeObj, key, cloneDeep(item))
    if (key === 'in' && !timeObj?.out?.id && calcTimeExtra?.out?.length) {
      this.$set(timeObj, 'key', 'out')
    } else if (key === 'out' && !timeObj?.in?.id && calcTimeExtra?.in?.length) {
      this.$set(timeObj, 'key', 'in')
    } else {
      this.hideRangeTimeValidate()
    }
  }

  get calcTimeExtra() {
    const { currentSelectedPackage } = this
    const obj = currentSelectedPackage?.time_extra || null
    return obj
  }

  getTotalBookingDays() {
    const { dateObj, timeObj } = this
    if (!dateObj.days) {
      return 0
    }
    const increaseIn = timeObj?.in?.increase || 0
    const increaseOut = timeObj?.out?.increase || 0
    return Math.max(dateObj.days + increaseIn + increaseOut, 1)
  }

  get calcFormattedPriceDays() {
    const x = this.getTotalBookingDays()
    return this.$t('3397', { 0: x })
  }

  get calcHeaderText() {
    const { dateObj } = this
    if (!dateObj.days) {
      return this.$t('activity.new.check_availability')
    }
    return this.calcFormattedPriceDays
  }

  get calcDateTitle() {
    const { calcTimeExtra } = this
    return this.$t(calcTimeExtra?.in ? '88950' : '13187')
  }

  clickRangeTimeHandler(key: string) {
    const { bsObjSelectTime, calcCacheTimeObj: timeObj } = this
    this.$set(timeObj, 'key', key)
    bsObjSelectTime.visible = true
  }

  handleShowStatement(payload: any) {
    this.legendsInfo = payload
    this.showStatementVisible = true
  }

  checkAutoTimeSlot() {
    const { autoTimeSlot, selectedTime } = this
    return autoTimeSlot && selectedTime?.time_slot
  }

  @Watch('timeList', { immediate: true })
  timeListChange() {
    // 自动选择逻辑
    const { timeList, isNewOpenTicket } = this
    if (timeList && timeList.length > 0) {
      this.$nextTick(() => {
        // 如果只有一个，则自动选中
        if (timeList.length === 1) {
          this.handleSelectTime(timeList[0])
          return
        }

        // 如果是 openTicket，则自动选第一个合法的
        if (isNewOpenTicket) {
          const validTime = timeList.find((item: any) => !item.disabled)
          this.handleSelectTime(validTime)
          return
        }

        if (this.checkAutoTimeSlot()) {
          // 新增选中缓存
          const obj = timeList.find((o: any) => o.date === this.selectedTime?.date)
          this.handleSelectTime(obj)
          return
        }

        // 如果以上都不是，则清除
        this.handleSelectTime(null)
      })
    }
  }

  get calcHidePickupTimeIcon() {
    const { pickupTimeList, timeLimit } = this
    return pickupTimeList.length <= timeLimit
  }

  get calcHideTimeIcon() {
    const { timeList, timeLimit } = this
    return timeList.length <= timeLimit
  }

  get calcTimeActive() {
    return (item: any) => {
      const { calcCacheTimeObj: timeObj } = this
      const cls = item.id && item.id === timeObj?.[timeObj.key]?.id ? 'time-active' : ''
      return cls
    }
  }

  getStartEndFormatStr(item: any, linkStr = ' - ') {
    return `${item.start}${linkStr}${item.end}`
  }

  get calcTimeExtraList() {
    const { calcCacheTimeObj: timeObj, calcTimeExtra } = this
    const arr = calcTimeExtra?.[timeObj.key] || []
    arr.forEach((item: any) => {
      this.$set(item, 'startEndFormatStr', `${item.start} - ${item.end}`)
    })
    return arr
  }

  getAvailabilityTrackInfo() {
    return this.isTrackItem
      ? {
        'data-spm-item': 'CheckAvailability'
      }
      : {
        'data-spm-module': 'CheckAvailability',
        'data-spm-virtual-item': '__virtual'
      }
  }

  clickPickupTimeHandler() {
    if (this.calcHidePickupTimeIcon) { return false }
    this.pickupTimePopupVisible = true
  }

  clickTimeHandler() {
    if (this.calcHideTimeIcon) { return false }
    this.timePopupVisible = true
  }

  created() {
    eventBus.$off('checkSelectDateAndTime2bus').$on('checkSelectDateAndTime2bus', this.checkSelectDateAndTime2bus)
    this.bsObjSelectTime.title = this.$t('88950')
  }

  beforeDestroy() {
    eventBus.$off('checkSelectDateAndTime2bus', this.checkSelectDateAndTime2bus)
  }

  @Watch('calcSchedules', { deep: true })
  calcSchedulesWatch() {
    this.initData()
  }

  rangeDateList: string[] = []

  shouldDateDisabledFn(d: Date) {
    const { calcSchedules: schedules, rangeDateList, maxDate } = this
    const { min_pax: minPax, min_trip: minDays, max_trip: maxDays } = this.currentSelectedPackage
    const dateStr = getFormatDate(d)
    if (!rangeDateList?.length || rangeDateList?.length === 2) {
      return this.initObjCache[dateStr]
    }
    return this.isInvalidDate(d, { schedules, rangeDateList, minPax, minDays, maxDays, maxDate })
  }

  get maxDate() {
    const len = this.schedules?.length
    return len === 0 ? null : getLocalDate(this.schedules?.[len - 1]?.date)
  }

  isInvalidDate(dateInput: Date, options: any) {
    const dateStr = getFormatDate(dateInput)
    const soldout = this.isSoldout(dateStr)
    if (soldout) {
      return true
    }
    return isWifiInvalidDate(dateInput, options)
  }

  isSoldout(d: string) {
    const date = getFormatDate(d)
    const schedule: any = this.schedulesMap[date]
    // 这里如果选不到日期是disabled，不当做售罄处理
    if (schedule) {
      return schedule.soldout
    }
  }

  get calcSchedules() {
    const arr = formatPackageSchedules(this.schedules)
    return arr
  }

  get schedulesMap(): any {
    return this.schedules?.reduce((acc: any, v: any) => ({ ...acc, [v.date]: v }), {})
  }

  initObjCache: any = null

  initData() {
    // schedules需要补充未返回日期的处理
    // const { calcSchedules: schedules, maxDate } = this
    // const { min_pax: minPax, min_trip: minDays, max_trip: maxDays } = this.currentSelectedPackage
    // const options = { schedules, rangeDateList: [], minPax, minDays, maxDays, maxDate }
    // console.time('initDateDisabledCacheData')
    // const obj = this.initDateDisabledCacheData(options)
    // console.timeEnd('initDateDisabledCacheData')
    // this.initObjCache = obj.initedObj

    const { calcSchedules: schedules, maxDate } = this
    const { min_pax: minPax, min_trip: minDays, max_trip: maxDays } = this.currentSelectedPackage
    const options = { schedules, rangeDateList: [], minPax, minDays, maxDays, maxDate }
    let wk: any = createWorker(threadFn)
    wk.onmessage = (e: any) => {
      this.initObjCache = this.initDateDisabledCacheData(e.data).initedObj
      wk.terminate()
      wk = null
    }
    wk.postMessage(options)
  }

  initDateDisabledCacheData(options: any) {
    const arr = options?.schedules
    if (!arr.length) {
      return {}
    }
    const initedObj = arr.reduce((obj: any, item: any) => {
      const date = new Date(item.date)
      obj[item.date] = this.isInvalidDate(date, options)
      return obj
    }, {})
    return {
      initedObj
    }
  }

  checkSelectDateAndTime2bus() {
    const { value, showTime, selectedTime } = this
    if (!value) {
      this.$toast(this.$t('activity.booking.options.select.date'))
    } else if (showTime && !selectedTime?.time_slot) {
      this.$toast(this.$t('activity.booking.options.select.time'))
    }
  }

  get calcStockSchedules() {
    const arr = this.schedules.filter((obj: any) => {
      return obj?.time_slots?.some((o2: any) => {
        return o2?.stock
      })
    })
    return arr || []
  }

  get filterValidDates() {
    const schedules = this.calcStockSchedules.slice(0, this.dateLimit)
    return schedules
  }

  get hasLegends() {
    return this.schedules.some((item: any) => item?.legend_keys?.length > 0)
  }

  get timeList() {
    const schedule = this.schedules.find(item => item.date === this.value)
    const timeslots = (schedule && schedule.time_slots) || []

    return timeslots.reduce((acc, v: any) => {
      const disabled = this.isTimeDisabled(v)
      if (disabled) {
        return acc
      }
      return [...acc, {
        ...v,
        disabled,
        date: `${this.value} ${v.time_slot}`
      }]
    }, [])
  }

  get validTimeList() {
    return this.timeList.slice(0, this.timeLimit)
  }

  get showTime() {
    // 如果为 openTicket 则不显示
    if (this.isNewOpenTicket) {
      return false
    }

    // 如果只有一个并且为 00：00：00 则不显示
    if (this.timeList?.length === 1) {
      const [time] = this.timeList
      const formatTime = dayjs(time.date, 'YYYY-MM-DD hh:mm:ss')
      return !(formatTime.hour() === 0 && formatTime.minute() === 0 && formatTime.second() === 0)
    }

    return this.timeList?.length > 0
  }

  isTimeDisabled(time: { stock: number }) {
    const { stock } = time
    return !stock || stock < Number(this.currentSelectedPackage?.min_pax || 0)
  }

  // 从第一个可预订日期开始，延后14天
  // 不能晚于schedules的最后一天，所以可能少于14个
  limitDays(limit: number, schedules: Schedule[]) {
    const validDayDate = schedules.find((day: Schedule) => !day.soldout)?.date
    const lastDayDate = schedules[this.schedules.length - 1]?.date
    const arr = []

    if (validDayDate) {
      for (let i = 0; i < limit; i++) {
        const date = dayjs(validDayDate).add(i, 'day')
        if (!date.isAfter(lastDayDate, 'day')) {
          arr.push(date.format('YYYY-MM-DD'))
        }
      }
    } else {
      // 如果没有可预订日期，则默认从今天开始
      for (let i = 0; i < limit; i++) {
        arr.push(dayjs().add(i, 'day').format('YYYY-MM-DD'))
      }
    }

    return arr
  }

  getArrangementIdsAndStocks(startDate: string, endDate: string) {
    const tempArr = this.schedules?.filter((item: any) => {
      const momentDate = dayjs(item.date)
      return momentDate.isSameOrAfter(dayjs(startDate), 'day') && momentDate.isSameOrBefore(dayjs(endDate), 'day')
    })
    const days = tempArr.length
    const stocks = tempArr.map((x: any) => {
      return x.stock
    })
    const minStock = Math.min(...stocks)
    return {
      days,
      stocks,
      minStock
    }
  }

  closeDateHandler() {
    // const { dateObj, timeObj } = this
    // this.$set(this, 'dateObj', dateObj.cacheData)
    // this.$set(this, 'timeObj', timeObj.cacheData)
  }

  clearHandler() {
    this.handleDateChange([])
  }

  handleDateEveryChange(date: any) {
    if (this.sectionActData.isWifi) {
      date.length === 1 && this.handleDateChange(date)
    }
  }

  handleDateChange(date: any) {
    if (this.sectionActData.isWifiSimCard) {
      if (this.sectionActData.isWifi) {
        this.$set(this.dateObj.cacheData, 'list', date)
        setTimeout(() => {
          this.$nextTick(() => {
            this.rangeDateList = date
          })
        }, 1)
        if (!this.calcTimeExtra) {
          if (this.checkRangeDateValidate(date)) {
            return
          }
          this.updateRangeDate(date)
          this.$emit('rangeConfirm', this.getWifiParams())
          this.calendarPopupVisible = false
        }
      } else {
        this.$emit('rangeConfirm', this.getSimCardParams({ startDate: date }))
      }
      return
    }
    this.$emit('input', date)
    this.handleScrollToAnchor()
  }

  handleSelectTime(data: any) {
    // 重置selectedTime
    if (!data) {
      this.$emit('setTime', null)
      return
    }

    const selectedTime = {
      ...data,
      arrangementId: data.arrangement_id || data.arrangementId,
      stock: data.stock,
      date: data.date,
      /**
       * 最多可购买数量
       * 取决于当前库存和套餐设置的最大可购买数量的最大值
       * 因为在unit的计算中要使用到，所以放到这里先处理出来
       */
      maxPurchased: Math.min(data.stock, Number(this.currentSelectedPackage?.max_pax))
    }
    this.$emit('setTime', selectedTime)
  }

  handleScrollToAnchor() {
    if (process.client) {
      setTimeout(() => {
        const node = this.$el?.querySelector('.js-scroll-anchor')
        if (node) {
          node.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
          })
        }
      }, 160)
    }
  }
}
