import { Component, mixins, Ref, namespace } from 'nuxt-property-decorator'
import BookingCoreMixin from '~/components/booking-core/mixins/entry/index-base'
import AutoBookingMixin from '~/components/booking-core/mixins/common/auto-booking'
import { Activity } from '~/types/traveller/activity'

const ActivityModule = namespace('traveller/activity')

@Component
export default class ComboListBase extends mixins(BookingCoreMixin, AutoBookingMixin) {
  @ActivityModule.Getter('currPackageOptionTab') currPackageOptionTab!: Activity.ComboTab
  @Ref() combonOptionsBookingRef!: any

  get isAttrNew() {
    return this.vertical === 'attractions'
  }

  get isInCombo() {
    return !!(this.currPackageOptionTab === 'combo')
  }

  pkgShowMore = {
    init: false,
    len: 8,
    show: false
  }

  get limitedComboPackageList() {
    const { pkgShowMore, comboPackageList = [] } = this
    if (!pkgShowMore.init && comboPackageList.length > pkgShowMore.len) {
      pkgShowMore.init = true
      pkgShowMore.show = true
    }
    return pkgShowMore.show ? comboPackageList.slice(0, pkgShowMore.len) : comboPackageList
  }

  get fromPriceHtml() {
    const price = this.$t('activity_detail_from_price', [12, this.comboPriceShow?.from_price])
    return this.comboPriceShow?.from_price ? price : ''
  }

  get basicData() {
    return {
      is_in_flash_sale: this.isInFlashSale,
      is_line_page: this.isLinePage
    }
  }

  get newBusinessData() {
    return {
      packageList: this.packageList,
      activity_closer_price: this.activityCloserPrice,
      dateDescription: this.dateDescription
    }
  }

  toBook(packageId: number) {
    this.combonOptionsBookingRef.open({
      packageId,
      preview: this.calcPreview,
      translation: this.isTranslation
    })
  }

  pkgsCacheData: any = {}
  pkgDetailObj: any = {
    visible: false,
    direction: 'right',
    pkgData: null
  }

  getPkgCacheData(key: string, pkgData: any) {
    if (!key) {
      return
    }
    const { package_id } = pkgData || {}
    const cacheData = this.pkgsCacheData[package_id] || {}
    return cacheData[key]
  }

  updatePkgDetail(pkgData: any, pkgDetail: any) {
    const { package_id } = pkgData
    const cacheData = this.pkgsCacheData[package_id]
    if (!cacheData) {
      this.$set(this.pkgsCacheData, package_id, {
        pkgDetailData: pkgDetail
      })
    } else {
      this.$set(cacheData, 'pkgDetailData', pkgDetail)
    }
  }

  setPkgDetailStatus(pkgData: any, isb: boolean) {
    this.$set(this.pkgDetailObj, 'pkgData', pkgData)
    this.pkgDetailObj.visible = isb
  }
}
