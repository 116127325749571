




































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import upperFirst from 'lodash/upperFirst'
import ExperienceSection from './index'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { LazyComponent } from '~/components/traveller/lazy-section'
import SectionLoading from '~/components/experience-booking/experience-activity/section/component/section-loading-v2.vue'
import PkgOptionsLoading from '~/components/experience-booking/experience-activity/section/component/pkg-options-loading-v2.vue'

@Component({
  components: {
    PkgOptionsLoading,
    ExperienceSection,
    LazyComponent,
    SectionLoading
  }
})
export default class SectionDTO extends Vue {
  @Prop() sources!: ExperienceActivity.ISources
  @Prop() section!: ExperienceActivity.ISection
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() getAsyncSource!: Function

  pkgOptionsList = [
    'package_option_card_sku',
    'package_option',
    'package_options_spec_type',
    'package_options_group',
    'package_option_card_sku_with_detail'
  ]

  mapLoadingData: any = {
    PkgOptionsLoading: {
      styleType: 'pkg-options-type'
    }
  }

  // 异步源名称列表
  asyncNames: string[] = []

  get loadingWidth() {
    return this.platform === 'desktop' ? '790px' : '100%'
  }

  get rootMargin() {
    return this.platform === 'desktop' ? '2000px' : '400px'
  }

  // 楼层状态
  get state() {
    if (this.sourceName.length !== 1) {
      return 'success'
    }

    const source = this.sources[this.sourceName[0]]

    if (source.load_type === 'sync') {
      return 'success'
    }

    return source.data?.state
  }

  get isCSR() {
    return this.section?.meta?.render_type === 'client'
  }

  get isDivider() {
    return this.section?.meta?.type === 'divider'
  }

  get wrapComp() {
    return this.isCSR && !this.isDivider ? 'LazyComponent' : 'div'
  }

  get loadingComponent() {
    // todo: placeholder 处理
    if (!this.isCSR || this.isDivider) { return '' }

    const component = this.section?.meta?.component

    if (!component) { return '' }

    const name = upperFirst(component) + 'Loading'

    // 如果是套餐选项楼层
    if (this.pkgOptionsList.includes(component)) {
      return 'PkgOptionsLoading'
    }

    // 优先返回注册的平台组件
    const componentName = this.platform === 'desktop' ? `Desktop${name}` : `Mobile${name}`

    if (Vue.component(componentName)) {
      return componentName
    }

    // mobile、desktop都适用的组件
    if (Vue.component(name)) {
      return name
    }

    return 'SectionLoading'
  }

  get metaName() {
    return this.section?.meta?.name
  }

  get sourceName() {
    const sourceNames = this.section?.source?.name || []

    return sourceNames.filter(v => this.sources[v])
  }

  // 合并的数据源
  get mergedSourceData() {
    return this.sourceName.reduce((acc, v) => ({ ...acc, ...(this.sources[v]?.data || {}) }), {})
  }

  get isNavigation() {
    return this.section?.attr?.is_navigation
  }

  get navTitle() {
    const { attr } = this.section
    return attr?.is_navigation ? attr?.nav_title : ''
  }

  get spmName() {
    const name = this.track?.name

    // 现在track里只定义了name
    if (!name) { return }

    const { type, component } = this.section.meta

    return `${name}:${type}::${component}`
  }

  get track() {
    return this.section.meta.track
  }

  // 兼容楼层数据
  get sectionData() {
    const section = this.section
    const { meta, attr } = section
    let { fields } = section
    fields = fields || []
    // fields = [
    //   { key: 'activity_package_promotion', value: 0 },
    //   { key: 'activity_custom_config_hide_credit', value: 1 },
    //   { key: 'activity_custom_config_hide_price_guarantee', value: 1 }
    // ] // fileds test code
    return {
      fields,
      meta: {
        name: meta.name,
        type: meta.type,
        placeholder: meta.placeholder,
        component: meta.component,
        track: meta.track
      },
      data: {
        bottom_button_deep_link: attr?.bottom_button_link,
        bottom_button_title: attr?.bottom_button_title,
        is_navigation: attr?.is_navigation,
        more_deep_link: attr?.explore_link,
        more_title: attr?.explore,
        sub_title: attr?.sub_title,
        title: attr?.title
      },
      attr,
      body: {
        content: {
          data_type: meta.component,
          handler: '',
          load_type: 'sync',
          data: this.mergedSourceData || {}
        }
      }
    }
  }

  /**
   * 请求所有的异步源的数据，并将请求结果合并回数据源
   * @Param params: 请求参数
   */
  async getAsyncSourceList(params?: any, options?: { isClientReport: boolean }) {
    if (!this.asyncNames.length) {
      this.asyncNames = this.sourceName
        .filter(v => this.sources[v]?.load_type === 'async')
    }

    await Promise.all(this.asyncNames.map((name) => {
      return this.getAsyncSource(name, params)
    }))
    options?.isClientReport && this.sendPackageOptionKcp()
  }

  change(data: any) {
    if (!data || typeof data !== 'object') { return }

    const keys = Object.keys(data)
    const that = this

    // 更新对应源中的字段
    let source = that.sources
    for (const name of that.sourceName) {
      const sourceData = that.sources?.[name]?.data

      if (!sourceData) {
        continue
      }

      const sourceDataCopy = { ...sourceData }

      for (const v of keys) {
        const hasKey = Object.keys(sourceData).includes(v)

        if (hasKey) {
          sourceDataCopy[v] = data[v]
        }
      }

      source = {
        ...source,
        [name]: {
          ...this.sources[name],
          data: sourceDataCopy
        }
      }
    }

    this.$emit('update', source)
  }

  handleHide(name: string) {
    this.$emit('remove-section', name)
  }

  get isPackageOptionSection() {
    const { component } = this.section.meta || {}
    return component.includes('package_option')
  }

  sendPackageOptionKcp() {
    if (this.isPackageOptionSection && this.$clientReport?.reportKcp) {
      this.$clientReport.reportKcp() // 手动触发自定义关键内容上报
    }
  }

  mounted() {
    if (!this.isCSR) {
      // 进入页面之后自动请求异步楼层数据
      this.getAsyncSourceList()
    }

    if (this.isNavigation) {
      this.$root.$emit('nav.addSection', {
        name: `.${this.metaName}`,
        title: this.navTitle
      })
    }
  }
}
