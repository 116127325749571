








import { Component, namespace, State, Vue, Getter, Prop } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'
import { Activity } from '~/types/traveller/activity'
import * as sessionStorage from '~/assets/scripts/sessionstorage'
import errorCode from '~/components/traveller/activity/book-now-error-code'
// import beforeAddToCart from '~/components/experience-booking/experience-activity/package-options/desktop/zhenxing-coupon-tip'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { getBookButtonTextId } from '~/share/data/experience/fieldsConfigUtils.js'
import { routerPush, getBookingNowParams } from '~/share/utils'
import eventBus from '~/pages/experience/pay/common/event-bus'

const authModule = namespace('auth')
const experience = namespace('experience')
@Component
export default class BookingOptionsBottomBtns extends Vue {
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf
  @State klook!: Data.Klook
  @Getter isGuestCheckout!: boolean
  @authModule.State isLoggedIn!: boolean
  @Prop({ default: null }) wifiSimParams?: any
  @Prop() msgFn!: Function
  @Prop() spmItem!: string
  @Prop() type!: 'addToCart' | 'bookNow'
  @Prop({ default: '' }) btnContent!: string
  @Prop() selectedTime!: any
  @Prop() currentSelectedPackage!: ExperienceActivity.IPackageCard
  @Prop() templateId!: number
  @Prop() units!: Activity.unit[]
  @Prop() totalUnitCount!: number
  @Prop() packageSelectedDate!: string
  @Prop() activityId!: number
  @Prop() isSoldOut!: boolean
  @Prop() isOpenTicket!: boolean
  @Prop({ default: false }) isJumpOldSettlement!: boolean
  @Prop() passCustomFn!: Function
  @Prop({ default: null }) extraParams!: Record<string, any>

  role: 'bookNow' | 'addToCart' = 'bookNow'

  get calcBookButtonText() {
    return this.$t(getBookButtonTextId(this.currentSelectedPackage?.redeem_status))
  }

  get shoppingCartId() {
    const { shoppingcart_id, shop_id } = this.$route.query
    return parseInt((shoppingcart_id || shop_id) as string) || -1
  }

  get shoppingCartIdStr() {
    const { shoppingcart_id = '', shop_id = '' } = this.$route.query
    return shoppingcart_id || shop_id
  }

  get content() {
    const contents = {
      bookNow: this.calcBookButtonText,
      addToCart: this.$t('activity.label.add_to_cart')
    }
    return this.btnContent || contents[this.role]
  }

  get preOrderKey() { return `activity_pre_order_${this.activityId}` }

  getSessionData(key: string) {
    const sessionData = sessionStorage.getSessionData(this.preOrderKey)

    if (!sessionData) { return null }

    try {
      const data = JSON.parse(sessionData)

      return data && data[key]
    } catch (e) {
      return null
    }
  }

  saveBookingOptions() {
    sessionStorage.setSessionData(this.preOrderKey, JSON.stringify({
      params: this.getParams()
    }))
  }

  toastHandler({ content }: any) {
    this.$toast(content)
    this.$businessLog({
      type: 'FECheckFail',
      tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
      reason: content
    })
  }

  async handleNoLogin() {
    this.sendData()

    // 埋点
    this.$cookies.set('reg_form_buy_btn', 1, { path: '/' })

    await this.$loginSDK({
      purpose: this.role === 'addToCart' ? 'Cart' : 'Booking',
      success: async () => {
        await this.bookRequest()
      },
      fallback: () => {
        // 保存用户选择数据，可以在引导用户登录完成之后回到页面直接下单
        this.saveBookingOptions()
      }
    })
  }

  async handleClick() {
    let tipsFn: Function = this.toastHandler
    if (this.msgFn) {
      tipsFn = this.msgFn
    }

    if (!this.packageSelectedDate) {
      return tipsFn({
        content: this.$t('activity.booking.options.select.date')
      })
    }

    if (this.sectionActData.isWifiSimCard && typeof this.wifiSimParams?.validateFn === 'function') {
      const msg = this.wifiSimParams.validateFn()
      if (msg) {
        return tipsFn({
          content: msg
        })
      }
    }

    if (!this.selectedTime || !this.selectedTime.arrangementId) {
      return tipsFn({
        content: this.$t('activity.booking.options.select.time')
      })
    }

    if (!this.totalUnitCount) {
      return tipsFn({
        content: this.$t('activity.booking.options.select.quantity')
      })
    }

    const minPax = this.currentSelectedPackage.min_pax
    if (this.totalUnitCount < Number(minPax)) {
      return tipsFn({
        content: this.$t('activity.error.price_min', [minPax])
      })
    }

    // guest checkout
    if (this.isGuestCheckout) {
      const channel = this.klook.utilConfig?.f_source_type || ''
      const res = await this.$axios.$post(apis.registerGuest, {
        partner_channel: channel
      })

      if (res.success) {
        this.bookRequest()
      }

      return
    }

    // if (this.role === 'addToCart') {
    //   if (await beforeAddToCart(this)) {
    //     this.role = 'bookNow'
    //   }
    // }

    if (!this.isLoggedIn) {
      await this.handleNoLogin()
      return
    }
    this.$emit('next-btn-complete', JSON.stringify({ CompletedInfo: true }))

    // 如果有 usj timeslots 并且是点击 book now 并且不是购物车，则需要校验
    if (this.extraParams?.timeslots && this.shoppingCartId === -1 && this.role !== 'addToCart') {
      eventBus.$emit('validatePackageOptionUsjTimeslots', this.bookRequest)
    } else {
      this.bookRequest()
    }
  }

  async bookRequest() {
    if (this.passCustomFn) {
      this.passCustomFn(this.getParams())
      return
    }

    const api = this.shoppingCartId === -1 ? (this.role === 'addToCart' ? apis.bookingNow : apis.expPostPreOrderServiceNew) : this.role === 'addToCart' ? apis.addToShoppingCard : apis.postExpEditeShoppingCartAndnew
    let params = this.getParams()

    // 调用 pre_order/new 接口
    if (this.shoppingCartId === -1 && this.role !== 'addToCart') {
      const { arrangement_id, price_items } = params
      params = getBookingNowParams(arrangement_id, price_items, {
        wifiSimParams: this.getWifiSimParams(),
        otherParams: this.extraParams?.timeslots?.length
          ? {
            vertical_param: {
              ...(params.vertical_param || {}),
              supply_api: {
                timeslots: this.extraParams?.timeslots
              }
            }
          }
          : {}
      })
    }

    this.$showLoading({ overlayColor: 'rgba(255, 255, 255, 0.7)' })
    try {
      const res = await this.$axios.$post(api, params)

      this.$hideLoading()

      if (res.success) {
        if (!this.getSessionData('params')) {
          this.sendData()
        }

        // 加入购物车成功埋点
        if (this.role === 'addToCart') {
          const id = res.result?.shoppingcart_id_str || res.result?.shoppingcart_id
          this.$inhouse.track('custom', 'body', {
            spm: 'AddToCartSuccessLoad',
            shoppingcart_id: id
          })
        }

        setTimeout(() => {
          if (this.role === 'bookNow') {
            routerPush(this, {
              name: 'ExperiencePay',
              query: {
                settlement_type: '1',
                shoppingcart_guid: res.result?.pre_order_id,
                travel_pass_unit_no: this.$route?.query?.travel_pass_unit_no,
                travel_pass_url: this.$route?.query?.travel_pass_url
              }
            })
          } else {
            this.shoppingCartId === -1 ? this.$emit('closeModal') : (window.location.href = this.$href('/shoppingcart'))
          }
        })

        return
      }

      if (res?.error?.code === '4001') {
        await this.handleNoLogin()
        return
      }

      if (res.error.code === '01001007027') {
        const option = {
          content: this.$t('16995-price_change_tips'),
          btnYes: this.$t('global.tips.okTxt')
        }
        this.$alert(option.content, {
          okLable: option.btnYes
        }).then(() => {
          this.refreshWithUnits()
        })
        this.$businessLog({
          tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
          type: 'ApiError',
          reason: '价格改变了',
          code: res?.error?.code,
          message: res?.error?.message
        })
        return
      }

      this.handleError(res.error)
      this.$businessLog({
        tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
        type: 'ApiError',
        code: res?.error?.code,
        message: res?.error?.message
      })
    } catch (e) {
      const error = e as any
      this.$hideLoading()
      this.handleError(error)
      this.$businessLog({
        tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
        type: 'JsError',
        name: error?.name,
        stack: error?.stack,
        message: error?.message
      })
    }
  }

  refreshWithUnits() {
    // sessionStorage.setSessionData('activity_booking_options_refresh', JSON.stringify({
    //   autoTop: true,
    //   packageId: this.currentSelectedPackage.package_id,
    //   refresh_date: this.packageSelectedDate,
    //   refresh_time_slot: this.selectedTime.time_slot,
    //   refresh_units: this.units.map((u: Activity.unit) => `${u.priceId},${u.count}`).join(';')
    // }))
    window.location.reload()
  }

  handleError(e: { code: string, message: string }) {
    const resCode = e.code
    if (errorCode.includes(resCode)) {
      this.$confirm(e.message, {
        okLabel: this.$t('global.confirm'),
        cancelLabel: this.$t('global.tips.cancelTxt')
      }).then(({ result: isb }) => {
        if (isb) {
          window.location.reload()
        }
      })
    } else {
      this.$confirm(e.message, {
        okLabel: this.$t('global.confirm')
      })
    }
  }

  getWifiSimParams() {
    let specParams = {}
    if (this.sectionActData.isWifiSimCard) {
      const { start_date, end_date, start_time_type_id, end_time_type_id } = this.wifiSimParams || {}
      specParams = {
        start_date: start_date || '',
        end_date: end_date || '',
        start_time_type_id: start_time_type_id || 0,
        end_time_type_id: end_time_type_id || 0
      }
    }
    return specParams
  }

  getParams() {
    const sessionParams = this.getSessionData('params')

    if (sessionParams) {
      return sessionParams
    }

    const { arrangementId } = this.selectedTime

    const priceItems = this.units.reduce((acc: any[], v) => {
      return v.count > 0 ? [...acc, {
        sku_id: v.skuId,
        price_id: v.priceId,
        count: v.count,
        event_id: v.isInPromotion ? v.eventId : 0 // 促销活动正在进行的时候才传 eventId
      }] : acc
    }, [])

    const specParams = this.getWifiSimParams()

    const params = {
      arrangement_id: arrangementId,
      package_id: this.currentSelectedPackage.package_id,
      template_id: this.templateId,
      is_open_ticket: this.isOpenTicket,
      price_items: priceItems,
      shoppingcart_type: this.role === 'bookNow' ? 1 : 0,
      ...specParams
    }

    if (this.shoppingCartId !== -1) {
      return {
        ...params,
        shoppingcart_id: this.shoppingCartId,
        shoppingcart_id_str: this.shoppingCartIdStr
      }
    }

    return params
  }

  /**
   * 数据上报
   * 未登录时跳转到登录页面前需要上报，登录页回来之后不在上报
   * 登录状态后端校验成功需要上报
   */
  sendData() {
    this.$sendGTMCustomEvent(`Booking Options Page|${this.role === 'addToCart' ? 'Booking Options Page Add To Cart Button Clicked' : 'Booking Options Page Next Button Clicked'}`)
  }

  created() {
    if (this.type) {
      this.role = this.type
    } else {
      this.role = this.$route.query.type === 'book' ? 'bookNow' : 'addToCart'
    }
  }
}
