









































import { Component } from 'nuxt-property-decorator'
import { IconNext, IconWarnCircle } from '@klook/klook-icons'
import Base from './base'
import CalendarPopup from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/calendar-popup.vue'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'

@Component({
  components: {
    IconNext,
    CalendarPopup,
    CalenderTips,
    IconWarnCircle
  }
})
export default class PassCalendar extends Base {
  openCalendar() {
    this.visible = true
    this.getInfo()
  }

  closeCalendar() {
    this.visible = false
  }
}
