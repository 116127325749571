


































import { Component, Prop, namespace, Watch, mixins } from 'nuxt-property-decorator'
import { isEmpty } from 'lodash'
import apis from '~/share/data/apis'
import NewActivityPackageDetail from '~/components/traveller/activity/activity-package-options/desktop/new-package-detail/index.vue'
import { PackageDetailStructured } from '~/components/common/package-detail-v2'
import ExpLoadingState from '~/components/experience/loading-state/index.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import newPackageDetailShowMixin from '~/components/common/package-detail-v2/show-mixin'
import { PkgDetailsSkeleton } from '~/components/common/skeleton-data/index.js'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    ExpLoadingState,
    PackageDetailStructured,
    NewActivityPackageDetail
  }
})
export default class PackageDetailLoading extends mixins(newPackageDetailShowMixin) {
  @ActivityModule.State activityDetail!: any
  @Prop() specStyle!: object
  @Prop() moreType!: 'desktop-type'
  @Prop() hideIniting!: boolean
  @Prop() pkgDetailData!: any
  @Prop() fetching!: any
  @Prop() packageId!: number
  @Prop({ default: 0 }) preview!: number
  @Prop({ default: false }) showActivityTitle!: boolean
  @Prop({ default: '' }) groupPackName!: string
  @Prop({ type: Number, default: 0 }) imageCardSwiperType!: number
  @Prop({ type: String, default: '' }) imageGroupStyle!: string

  PkgDetailsSkeleton = PkgDetailsSkeleton

  @Watch('packageId', { immediate: true })
  packageIdWatch(pid: number) {
    if (pid) {
      this.getPackageDetail()
    }
  }

  loadingState = ''
  pkgDetail: any = null

  get isLinePage() {
    const basic_data = this.activityDetail?.basic_data ?? {}
    return basic_data.is_line_page || false
  }

  async getPackageDetail() {
    const { pkgDetailData, preview, packageId, showStructuredPackageDetail } = this
    if (pkgDetailData) {
      this.setPackageDetail({ status: 'success', detail: pkgDetailData })
      return
    }
    if (!packageId) {
      return
    }
    this.setPackageDetail({ status: 'fetching', detail: null })

    const translation = this.$route.query?.translation === '1'
    // 抢购加上 is_line_page 字段
    const is_line_page = this.isLinePage || false
    const url = showStructuredPackageDetail ? apis.getAttractionPackageDetail : apis.activityPackageDetail
    try {
      const { result, success } = await this.$axios.$get(url, {
        params: {
          preview,
          translation: !!translation,
          package_id_list: packageId,
          publish_status: preview ? 'all' : 'viewable',
          is_line_page: preview ? false : is_line_page,
          page_from: this.currPkgPageFrom || ''
        }
      })
      if (success && !isEmpty(result)) {
        const packageDetail = result[0]
        this.setPackageDetail({ status: 'success', detail: packageDetail })
      } else {
        this.setPackageDetail({ status: 'failure', detail: null })
      }
    } catch (error) {
      this.setPackageDetail({ status: 'failure', detail: null })
    }
  }

  setPackageDetail({ status, detail }: any) {
    this.loadingState = status
    this.$set(this, 'pkgDetail', detail)
    this.$emit('updatePkgDetail', detail)
  }

  get calcTrack() {
    const ext = this.$getExt({
      IsAttrNew: this.showStructuredPackageDetail,
      Vertical: this.currPkgVertical
    })

    const def = {
      action: false,
      type: 'module',
      spm: 'PackageDetailsPopups',
      query: {
        ext,
        trg: 'manual',
        oid: `package_${this.packageId}`
      }
    }
    return def
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  beforeDestroy() {
    // hack module exposure
    this.$inhouse.track('exposure', this.$el.querySelector('.js-exposure'), { force: true })
  }
}
