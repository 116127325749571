

















































import { Component, State, Watch } from 'nuxt-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import Tag from './tag.vue'
import Discounts from './discounts/index.vue'
import Coupons from './coupons/index.vue'
import apis from '~/share/data/apis'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import ExpTagging, { getTaggingExtraTrackObj } from '~/components/experience/tagging/index2.0.vue'

@Component({
  components: {
    Tag,
    Discounts,
    Coupons,
    ExpTagging
  }
})
export default class ExperienceActivityPromoSystemTag extends BusinessBase {
  static displayName = 'Activity_promo_system_tag'
  @State auth!: { isLoggedIn: boolean }

  get calcLineClamp() {
    return this.klook.platform === 'mobile' ? 10 : 5
  }

  getTaggingTarck() {
    const extra = getTaggingExtraTrackObj(this.promotionTags)
    const track = cloneDeep(this.businessData?.track || {}) // 不能往直接往 track 贬值
    track.query = { ...(track.query || {}), ext: encodeURIComponent(JSON.stringify(extra)) }
    return track
  }

  get promotionTags() {
    return this.businessData?.new_promo_tags || []
  }

  get campaignTags() {
    return this.businessData?.campaign_tag_list || []
  }

  promotionData: any = null

  modalObj = {
    size: 'large',
    closable: true,
    scrollable: true,
    overlayClosable: true,
    modalClass: 'modal-style',
    titleAlign: 'left',
    width: '900px'
  }

  promotionObj = {
    visible: false,
    showClose: true,
    title: '',
    height: 'auto'
  }

  @Watch('promotionObj.visible')
  visibleWatch(isb: boolean) {
    const { query } = this.$route
    this.$router.push({
      query: {
        ...query,
        pv: isb ? 'none' : undefined,
        showPromotion: isb ? '1' : undefined
      }
    })
  }

  created() {
    this.promotionObj.title = this.$t('166384')
    this.checkShowPromotion()
  }

  checkShowPromotion() {
    this.$nextTick(() => {
      const { query } = this.$route
      if (query.showPromotion) {
        this.openPromotion()
      }
    })
  }

  get calcDiscounts() {
    const { promo_system_infos } = this.promotionData || {}
    return promo_system_infos || []
  }

  get calcCoupons() {
    const { coupons } = this.promotionData || {}
    return coupons?.items || []
  }

  get calcTnc() {
    const { terms_conditions } = this.promotionData || {}
    return terms_conditions || ''
  }

  get calcTimeZone() {
    const localTimeZone = -new Date().getTimezoneOffset() / 60 // 浏览器渲染：获取本地时区
    return localTimeZone >= 0 ? `+${localTimeZone}` : `${localTimeZone}`
  }

  get visible() {
    return this.promotionTags?.length > 0 || this.campaignTags?.length > 0
  }

  @Watch('visible', { immediate: true })
  visibleChange(val: boolean) {
    if (!val) {
      this.hideSection()
    }
  }

  freshCoupons: boolean = false

  async openPromotion() {
    const { promotionObj } = this
    if (!this.promotionData || this.freshCoupons) {
      this.$showLoading({ overlayColor: 'rgba(255, 255, 255, 0.7)' })
      const { activity_id } = this.businessData || {}
      const res = await this.$axios.$get(apis.experiencePromoSystemTagInfo, { params: { activity_id, time_zone: this.calcTimeZone } })

      if (res.success) {
        this.freshCoupons = false
        this.promotionData = res.result
      }

      this.$hideLoading()
    }
    promotionObj.visible = true
    return promotionObj.visible
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  calcHeight() {
    const target = this.$el.querySelector('.tagging-wrap') as HTMLElement
    const promoSystemBox = document.querySelector('.promotion-system') as HTMLElement
    if (target) {
      const { clientHeight, scrollHeight } = target
      if (clientHeight >= scrollHeight) {
        // 说明只有一行，只有一样，则没有padding-bottom
        promoSystemBox && (promoSystemBox.style.paddingBottom = '0')
      }
    }
  }

  async mounted() {
    this.calcHeight()
    await null
  }
}
