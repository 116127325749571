




































import { Component, Vue, Prop, Getter, State, namespace } from 'nuxt-property-decorator'
import { throttle, isEmpty } from 'lodash'
import BookingBottomBtns from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-bottom-btns.vue'
import BookingBarWrap from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-bar-wrap.vue'
import ExpBookingSettlement from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-settlement'
import { Activity } from '~/types/traveller/activity'
import { ExperienceActivity } from '~/types/experience/activity-section'
import TotalSlot, { getPreTotalPriceData } from '~/components/experience/price-slot/total-slot.vue'
import { formatCurrencySymbolPriceThousands } from '~/share/utils'
import PriceDiscountTips from '~/components/experience/price-slot/discount-tips.vue'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    PriceDiscountTips,
    TotalSlot,
    BookingBarWrap,
    ExpBookingSettlement,
    BookingBottomBtns
  }
})
export default class BookingBottomBar extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: String, default: '' }) fromType!: 'bookNow' | 'addToCart'
  @Prop() hideDiscountTips!: boolean
  @Prop() units!: Activity.unit[]
  @Prop() businessData!: any
  @Prop({ default: false }) isMultiPass!: boolean
  @Prop() currentSelectedPackage!: ExperienceActivity.IPackageCard
  @Prop() selectedTime!: any
  @Prop() packageSchedules!: any
  @Prop() packageSelectedDate!: string
  @Prop() totalUnitCount!: number
  @Getter isGuestCheckout!: boolean
  @Prop() showShoppingCart!: Function
  @Prop({ default: null }) extraParams!: Record<string, any>
  @ActivityModule.State preSettlementApiData!: any
  @ActivityModule.Getter preSettlementTips2getters!: any

  completeInfo = false
  // ModuleExtra: string = JSON.stringify({ CompletedInfo: false })

  get arrangementId() {
    return this.selectedTime?.arrangement_id
  }

  get calcShowPrice() {
    if (!this.arrangementId || isEmpty(this.units)) { return false }
    const count = (this.units || []).reduce((num: number, obj: any) => {
      num = num + (obj.count || 0)
      return num
    }, 0)
    return count > 0
  }

  getCalcPreTotalPrice() {
    const str = formatCurrencySymbolPriceThousands(this.klook.currency, '-')
    if (!this.calcShowPrice) {
      return { sale_price: str }
    }
    const preData = this.preSettlementApiData?.result
    const ref: any = this.$refs.bookingSettlementRef
    const oldPrice = formatCurrencySymbolPriceThousands(this.klook.currency, ref?.price)
    const d = getPreTotalPriceData(this.currentSelectedPackage, preData, {
      oldPriceShow: {
        sale_price: oldPrice
      }
    })
    return d
  }

  clickSavePrice(isb: boolean) {
    const priceSummary = this.bookingSettlementBinds?.preTotalPrice?.price_summary
    this.$emit('clickSavePrice', isb, { priceSummary })
  }

  get itemExtra() {
    const selectedSkuList = (this.units || []).filter((item: Activity.unit) => item.count > 0)
    return this.$getExt({
      CompletedInfo: this.completeInfo,
      Sku_id: selectedSkuList.map((item: Activity.unit) => item.skuId),
      Sku_num: selectedSkuList.map((item: Activity.unit) => item.count)
    })
  }

  get activityId() {
    return this.businessData.activity_id
  }

  get templateId() {
    return this.businessData.template_id
  }

  get isYiFunVoucher() {
    return !!this.businessData?.card_tags?.yifun_voucher
  }

  get isNswActivity() {
    return this.businessData.is_nsw_activity
  }

  get isOpenTicket() {
    return this.businessData.activity_ticket_type === 3
  }

  get packages() {
    return this.businessData.packages
  }

  get isSoldOut() {
    const { packages } = this
    return isEmpty(packages) || packages.every((v: any) => !v.has_stocks)
  }

  get isBtnVisibleAddtocart() {
    const isb = typeof this.businessData.is_support_shopping_cart === 'boolean' ? this.businessData.is_support_shopping_cart : !this.isNswActivity // 有传isSupportShoppingCart则优先使用
    return ![47631, 46820, 48639, 48789, 55826, 49807, 55724].includes(this.activityId) && isb && !this.isGuestCheckout
  }

  get isInPromotion() {
    const promotionEvent = this.currentSelectedPackage?.promotion_price?.promotion_event || {}
    return promotionEvent.status === 5
  }

  get bookingSettlementBinds() {
    return {
      preTotalPrice: this.getCalcPreTotalPrice(),
      units: this.units,
      isInPromotion: this.isInPromotion,
      promotionDetail: this.currentSelectedPackage?.promotion_price?.promotion_event
    }
  }

  get bookingBottomBtnsBinds() {
    return {
      selectedTime: this.selectedTime,
      currentSelectedPackage: this.currentSelectedPackage,
      templateId: this.templateId,
      units: this.units,
      activityId: this.activityId,
      isSoldOut: this.isSoldOut,
      isYiFunVoucher: this.isYiFunVoucher,
      isOpenTicket: this.isOpenTicket,
      selectedPackagePriceSchedule: this.packageSchedules,
      packageSelectedDate: this.packageSelectedDate,
      totalUnitCount: this.totalUnitCount,
      extraParams: this.extraParams
    }
  }

  updateModuleExtra(val: string) {
    // this.ModuleExtra = val
    this.completeInfo = JSON.parse(val).CompletedInfo
  }

  handleCloseModalEvent() {
    this.showShoppingCart && this.showShoppingCart()
  }

  throttleGetFixedWrapInfos = throttle(this.getFixedWrapInfos, 300)
  getFixedWrapInfos() {
    const ref: any = this.$refs?.fixedWrapRef
    if (!ref) { return }
    const infos = {
      height: ref?.$el?.offsetHeight
    }
    this.$emit('updateInfos', infos)
    return infos
  }

  updated() {
    this.throttleGetFixedWrapInfos()
  }
}
