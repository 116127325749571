import { State, Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
// import { Iawards } from '~/components/experience-booking/experience-activity/fnb-activity-awards/type'
import { setImageFormat } from '~/share/data/image'

@Component
export default class MustTry extends BusinessBase {
  @State klook!: Data.Klook

  visable = false

  backgrounds: { [key: string]: string } = {
    one:
      'https://res.klook.com/image/upload/v1704251495/UED_new/Attraction/Attraction_FnB_2312/image_short_3x.jpg',
    multiple:
      'https://res.klook.com/image/upload/v1704251470/UED_new/Attraction/Attraction_FnB_2312/image_high_3x.jpg'
  }

  backgroundsMobile: { [key: string]: string } = {
    one:
      'https://res.klook.com/image/upload/v1704713632/UED_new/Attraction/Attraction_FnB_2312/image_app_awards2_3x.jpg',
    multiple:
      'https://res.klook.com/image/upload/v1704713570/UED_new/Attraction/Attraction_FnB_2312/image_app_awards_3x.jpg'
  }

  get awards() {
    return this.businessData.awards || []
  }

  get isMobile() {
    return this.klook.platform === 'mobile'
  }

  get renderList() {
    return this.awards.slice(0, 2)
  }

  get title() {
    return this.businessData.section_title
  }

  get currentBackground() {
    const lineHeight = this.awards.length > 2 ? 'multiple' : 'one'
    if (this.isMobile) {
      return this.backgroundsMobile[lineHeight]
    } else {
      return this.backgrounds[lineHeight]
    }
  }

  formatPicUrl(url: string) {
    const { webp, platform } = this.klook

    if (platform === 'desktop') {
      return setImageFormat(url, webp)
    }

    return setImageFormat(url, webp)
  }

  close() {
    this.visable = false
  }

  open() {
    this.visable = true
  }
}
