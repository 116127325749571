
































import { Component, Watch, State, Ref } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import reopenContent from '~/components/experience-booking/experience-activity/reopen/content.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    reopenContent
  }
})
export default class Index extends BusinessBase {
  @State klook!: Data.Klook
  @Ref() insiderRef!: any
  static displayName = 'DesktopActivity_mini_info'

  insiderObj = {
    status: ''
  }

  clickShowMore() {
    const { insiderObj } = this
    if (insiderObj.status) {
      insiderObj.status = insiderObj.status === 'more' ? 'less' : 'more'
    }
  }

  get calcTrack() {
    const { activityPath } = this.$route.params
    const aid = (activityPath || '').split('-')[0]
    return {
      type: 'module',
      spm: 'Mini_Info_Section',
      query: {
        oid: `activity_${aid}`
      }
    }
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get platform() {
    return this.klook.platform
  }

  get gShow() {
    const { klookInsiderVisible, reopenVisible } = this
    const isShow = klookInsiderVisible || reopenVisible
    return !!isShow
  }

  @Watch('gShow', { immediate: true })
  gShowChange(val: boolean) {
    if (!val) {
      this.hideSection()
    }
    this.initInsider()
  }

  initInsider() {
    setTimeout(() => {
      const height = this.insiderRef?.offsetHeight
      if (height > 24) {
        this.insiderObj.status = 'more'
      }
    }, 60)
  }

  get klookInsiderVisible() {
    return this.fieldsConfig2section?.klook_insider && this.klookInsider?.klook_insider
  }

  get reopenVisible() {
    return this.fieldsConfig2section?.reopen && [2, 3].includes(this.reopen?.tag)
  }

  getComponentName(type: string) {
    const o: any = {
      icon: 'DynamicIcons',
      markdown: 'DynamicMarkdown',
      address: 'DynamicMap',
      image: 'DynamicImage'
    }
    return o[type] || ''
  }

  get klookInsider() {
    const { klook_insider = '' } = this.businessData
    return {
      klook_insider
    }
  }

  get reopen() {
    return this.businessData
  }
}
