






















import { Component } from 'nuxt-property-decorator'
import Base from '~/components/experience-booking/experience-activity/spa-banner/base/index'

@Component
export default class SpaBanner extends Base {
  static displayName = 'MobileSpa_banner'
}
