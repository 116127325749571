









import { Component, Prop, Vue } from 'nuxt-property-decorator'
import DynamicHorizontalIcons
  from '~/components/experience-booking/experience-activity/dynamic-component/icons/dynamic-horizontal-icons.vue'
import DynamicVerticalIcons
  from '~/components/experience-booking/experience-activity/dynamic-component/icons/dynamic-vertical-icons.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    DynamicHorizontalIcons,
    DynamicVerticalIcons
  }
})
export default class DynamicIcons extends Vue {
  @Prop() content!: ExperienceActivity.IDynamicIcons

  get componentName() {
    switch (this.content?.style?.orientation) {
      case 'horizontal':
        return 'DynamicHorizontalIcons'
      case 'vertical':
        return 'DynamicVerticalIcons'
      default:
        return 'DynamicHorizontalIcons'
    }
  }
}
