var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"pageRightPriceRef",staticClass:"page-banner-right-price",attrs:{"data-init-height":_vm.initHeight}},[(_vm.showStatus !== 'default' && _vm.totalPriceData && _vm.calcPriceDetail)?_c('div',{staticClass:"pkg-content"},[_c('SavingPriceDetails',{staticClass:"custom-right-price-style",attrs:{"style-type":'desktop-right-price-type',"hide-title":true,"hide-dashed":true,"price-summary":_vm.calcPriceDetail}}),_vm._v(" "),_c('div',{staticClass:"total-field-title"},[_vm._v("\n      "+_vm._s(_vm.$t('pay.total_money'))+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"right-price-total"},[_c('TotalSlot',{staticClass:"total-slot-wrap flex-center flex-wrap",attrs:{"poptip-data":_vm.poptipData,"price-data":_vm.totalPriceData.prePriceData}})],1),_vm._v(" "),(_vm.calcCridits.creditsVisible)?_c('div',{staticClass:"right-price-credits"},[(_vm.calcCridits.credits)?[_c('KlkCredit',{attrs:{"data":{ credit: _vm.calcCridits.credits }}})]:_vm._e()],2):_vm._e(),_vm._v(" "),_c('div',{staticClass:"booking-btns-box"},[(_vm.totalPriceData.isBtnVisibleAddtocart)?_c('BookingBottomBtns',_vm._b({staticClass:"booking-btn add-to-card",attrs:{"type":"addToCart","btn-content":_vm.calcBookingBtnsBinds.addToCartText},on:{"closeModal":_vm.totalPriceData.handleCloseModalEvent}},'BookingBottomBtns',Object.assign({}, _vm.calcBookingBtnsBinds, _vm.bindIHTrack({
          exposure: false,
          type: 'module',
          spm: 'AddToCartBtn',
          query: {
            oid: ("package_" + _vm.packageId),
            ext: JSON.stringify({ CompletedInfo: true })
          }
        })),false)):_vm._e(),_vm._v(" "),_c('BookingBottomBtns',_vm._b({staticClass:"booking-btn",attrs:{"btn-content":_vm.totalPriceData.isMultiPass ? _vm.$t('31062-reserve_slot') : '',"type":"bookNow"}},'BookingBottomBtns',Object.assign({}, _vm.calcBookingBtnsBinds, _vm.bindIHTrack({
          exposure: false,
          type: 'module',
          spm: 'BookNowBtn',
          query: {
            oid: ("package_" + _vm.packageId),
            ext: JSON.stringify({ CompletedInfo: true })
          }
        })),false))],1)],1):_c('div',{staticClass:"pkg-content"},[_c('TotalSlot',{staticClass:"total-slot-wrap custom-style",attrs:{"price-data":_vm.calcPriceShow}}),_vm._v(" "),_c('div',{staticClass:"status-btn-box"},[(_vm.published)?_c('div',{staticClass:"banner-select-pkg-btn",attrs:{"data-spm-module":"SelectOptionsBtn?trg=manual"}},[(!_vm.sold_out)?_c('klk-button',{staticClass:"select-btn",attrs:{"block":"","data-spm-item":"__default"},on:{"click":_vm.scrollToPackageOptionsTop}},[_c('span',[_vm._v(_vm._s(_vm.calcTotalPrice ? _vm.$t('79418') : _vm.$t('activity.select.option')))])]):_c('klk-button',{attrs:{"block":"","disabled":""}},[_vm._v("\n          "+_vm._s(_vm.$t('activity.v2.btn.sold_out'))+"\n        ")])],1):(_vm.isWarming)?_c('div',[_c('klk-button',{attrs:{"block":""},on:{"click":_vm.scrollToPackageOptionsTop}},[_vm._v("\n          "+_vm._s(_vm.warmingText)+"\n        ")])],1):_c('div',[_c('klk-button',{attrs:{"type":"primary","disabled":"","block":""}},[_vm._v("\n          "+_vm._s(_vm.suspendedText || _vm.bottom_button || _vm.$t('activity.unpublish_btn_text'))+"\n        ")])],1)]),_vm._v(" "),(_vm.link && _vm.link_title)?_c('div',{staticClass:"package-content-link",attrs:{"data-spm-module":("Things_To_Do_In_City?oid=city_" + _vm.city_id),"data-spm-virtual-item":"__virtual?typ=entry"}},[_c('a',{attrs:{"href":_vm.link}},[_vm._v(_vm._s(_vm.link_title))])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }