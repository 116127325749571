









































import { Component, Prop, State, Vue } from 'nuxt-property-decorator'
import ImageGalleryViewerShadow from './image-gallery-viewer-shadow.vue'
import ReviewUser from '~/components/experience-booking/experience-activity/recent-review/review-user.vue'
import ActivityReviewsHelpful from '~/components/traveller/activity/activity-reviews/activity-reviews-helpful.vue'

@Component({
  components: {
    ReviewUser,
    ActivityReviewsHelpful,
    ImageGalleryViewerShadow
  }
})
export default class ImageGalleryViewerDesc extends Vue {
  @State klook!: Data.Klook
  @Prop() reviewInfo!: any
  @Prop({ default: false }) showBuyNow!: boolean

  setAvatarUrl(avatar: string) {
    return 'https://cdn.klook.com/upload/img200X200/' + avatar
  }
}
