





























































































import { Component, Watch, State, Ref } from 'nuxt-property-decorator'
import get from 'lodash/get'
import { IconNext } from '@klook/klook-icons'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import reopenContent from '~/components/experience-booking/experience-activity/reopen/content.vue'
import DynamicImageMap
  from '~/components/experience-booking/experience-activity/dynamic-component/map/dynamic-image-map.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import Logo from '~/components/experience/card-swiper/logo.vue'

@Component({
  components: {
    reopenContent,
    DynamicImageMap,
    Logo,
    IconNext
  }
})
export default class Index extends BusinessBase {
  @State klook!: Data.Klook
  static displayName = 'MobileActivity_mini_info'

  @Ref() imgRef!: HTMLSelectElement
  @Ref() compMapImgRef!: HTMLSelectElement

  isShowMap = false
  styles = {
    height: '123px'
  }

  bsObj = {
    height: 'auto',
    visible: false,
    transfer: true,
    'show-close': true
  }

  mapVm: any = null

  get calcTrack() {
    const { activityPath } = this.$route.params
    const aid = (activityPath || '').split('-')[0]
    return {
      type: 'module',
      spm: 'Mini_Info_Section',
      query: {
        oid: `activity_${aid}`
      }
    }
  }

  get logoWidth() {
    if (process.client) {
      const width = document.documentElement.clientWidth - 40
      return width * 3 / 16
    }
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  @Watch('bsObj.visible')
  visibleWatch(isb: boolean) {
    if (!isb && this.mapVm?.hideMap) {
      this.mapVm.hideMap()
    }
    isb && this.setStyles()
  }

  get platform() {
    return this.klook.platform
  }

  setStyles() {
    if (this.platform === 'mobile') {
      this.$nextTick(() => {
        const width = this.imgRef ? this.imgRef.offsetWidth : 0
        const height = `${width / 335 * 120}px`
        this.styles.height = height
      })
    }
  }

  getImageUrl(url: string) {
    const size = this.platform === 'mobile' ? 'upload/c_crop,q_80,w_776,h_560' : 'upload/c_crop,q_80,w_1580,h_632'

    return url.replace(/upload/, size)
  }

  get gShow() {
    const { klookInsiderVisible, reopenVisible, addressVisible } = this
    const isShow = klookInsiderVisible || reopenVisible || addressVisible
    return !!isShow
  }

  @Watch('gShow', { immediate: true })
  gShowChange(val: boolean) {
    if (!val) {
      this.hideSection()
    }
  }

  get klookInsiderVisible() {
    return this.fieldsConfig2section?.klook_insider && this.klookInsider?.klook_insider
  }

  get reopenVisible() {
    return this.fieldsConfig2section?.reopen && [2, 3].includes(this.reopen?.tag)
  }

  get addressVisible() {
    return this.fieldsConfig2section?.address_image && this.addressObj?.address_desc
  }

  getComponentName(type: string) {
    const o: any = {
      icon: 'DynamicIcons',
      markdown: 'DynamicMarkdown',
      address: 'DynamicMap',
      image: 'DynamicImage'
    }
    return o[type] || ''
  }

  get addressContent() {
    const { addressImage } = this
    const obj: any = get(addressImage, 'components.0')
    return obj
  }

  get addressObj() {
    const { addressImage } = this
    const obj: any = get(addressImage, 'components.0.data.0', {})
    return obj
  }

  get klookInsider() {
    const { klook_insider = '' } = this.businessData
    return {
      klook_insider
    }
  }

  get reopen() {
    return this.businessData
  }

  get addressImage() {
    return this.businessData
  }

  async clickLinkMapImg() {
    this.isShowMap = true
    await this.$nextTick()
    const ref: any = this.compMapImgRef
    if (ref?.$refs?.dynamicMapWrap?.showMap) {
      ref.$refs.dynamicMapWrap.showMap(this.addressObj)
    }
  }

  handleClickEvent() {
    const { klookInsiderVisible, reopenVisible, addressVisible } = this

    if (!klookInsiderVisible && !reopenVisible && addressVisible) {
      this.clickLinkMapImg()
    } else {
      this.bsObj.visible = true
    }
  }
}
