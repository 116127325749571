























import { Component, Prop, Vue, State, Watch, Getter } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import CardSwiper from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/card-swiper.vue'
import MiniDateButton from '~/components/experience-booking/activity/activity-calendar/mobile/date-button.vue'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import { formatThousands } from '~/share/utils'
import { currencySymbolMap } from '~/share/data/currency'
import { scrollToCenterHorizontal } from '~/share/scroll-polyfill'

@Component({
  components: {
    CardSwiper,
    MiniDateButton,
    CalendarLengends
  }
})
export default class MiniTime extends Vue {
  @State klook!: Data.Klook
  @Prop() customSpm!: any
  @Prop() originalTimeList!: any[]
  @Prop() timeList!: any[]
  @Prop() selectedTime!: any
  @Prop({ default: false }) smooth!: boolean
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: true }) isTrackItem!: boolean
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: false }) useNewScroll!: boolean
  @Getter timeFormatString!: string

  tempTime = ''
  items!: { [prop: string]: HTMLElement }

  getTrack(item: any) {
    const { customSpm } = this
    if (customSpm) {
      return {}
    }
    return this.getTrackInfo(item)
  }

  @Watch('selectedTime', { immediate: true })
  selectedTimeChange(val: any) {
    if (val && val.date && val.date !== this.tempTime) {
      this.slide(val.date, this.smooth)
      this.tempTime = val.date
    }
  }

  get hasLegend() {
    return this.myTimeList.some((item: any) => item.legend_keys?.length > 0)
  }

  get isOverLimit() {
    if (!this.selectedTime?.date || !this.timeList?.length) {
      return false
    }

    return !this.timeList.some((item) => item.date === this.selectedTime.date)
  }

  get myTimeList() {
    // 如果超出了，则展示在最后一个
    if (this.isOverLimit && this.selectedTime) {
      return [...this.timeList, this.selectedTime]
    }
    return this.timeList
  }

  getTrackInfo(item: any) {
    return this.isTrackItem
      ? {
        'data-spm-item': `SelectTime?ext=${JSON.stringify({ time: this.formatTime(item) })}`
      }
      : {
        'data-spm-module': `SelectTime?ext=${JSON.stringify({ time: this.formatTime(item) })}`,
        'data-spm-virtual-item': '__virtual'
      }
  }

  slide(val: string, smooth: boolean = false) {
    setTimeout(() => {
      const node = this.getItem(val)
      if (node) {
        // 新加的功能，只自测过，请小心使用
        if (this.useNewScroll) {
          scrollToCenterHorizontal(this.getWrapper()!, node)
        } else {
          node.scrollIntoView({
            behavior: smooth ? 'smooth' : 'auto',
            inline: 'center',
            block: 'nearest'
          })
        }
      }
    }, 160)
  }

  setTimeFormat(date: any) {
    return dayjs(date).format(this.timeFormatString)
  }

  formatTime(time: any) {
    if (!time || !time.date) {
      return ''
    }

    const t = this.setTimeFormat(time.date)
    const price = ` ${currencySymbolMap[this.klook.currency]} ${formatThousands(time.selling_price)}`
    return time.disabled ? ` ${t} ${this.$t('global.sold_out')}` : `${t}${this.priceVisible ? price : ''}`
  }

  select(item: any) {
    if (item.disable) {
      return null
    }

    this.tempTime = item.date
    this.slide(item.date)
    this.$emit('selectTime', item)
  }

  getWrapper() {
    return this.$el?.querySelector('.card-swiper')
  }

  getItem(date: string) {
    return this.$el?.querySelector(`[data-date="${date}"]`)
  }
}
