








import { Vue, Component, State, Prop } from 'nuxt-property-decorator'

@Component
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: 'initial' }) maxWidth!: string
  @Prop({ default: false }) isSlotText?: boolean

  get calcStyles() {
    const { maxWidth } = this

    return {
      maxWidth
    }
  }

  init() {
    this.$nextTick(() => {
      let ref: any = this.$refs.autoAlignRef

      if (this.isSlotText) {
        const slotRef: any = this.$refs.slotRef
        slotRef && (ref = slotRef)
      }
      if (!ref) {
        return
      }

      const width = ref.scrollWidth || ref.offsetWidth || 0
      const height = ref.scrollHeight || ref.offsetHeight || 0
      this.$emit('updated', {
        width,
        height
      })
    })
  }

  mounted() {
    this.init()
  }

  updated() {
    this.init()
  }
}
