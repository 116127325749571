//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import IconChevronDown from '@klook/klook-icons/lib/IconChevronDown';
import IconChevronUp from '@klook/klook-icons/lib/IconChevronUp';
import IconCopy from '@klook/klook-icons/lib/IconCopy';
import IconInformation from '@klook/klook-icons/lib/IconInformation';
import dayjs from 'dayjs';

import {
  $colorBrandPrimary,
  $colorTextPrimary,
  $colorTextPlaceholder,
} from '@klook/klook-ui/lib/utils/design-token-esm';
import baseMixin from './baseMixin.js';

export default {
  components: {
    IconCopy,
    IconChevronDown,
    IconChevronUp,
    IconInformation,
  },
  mixins: [baseMixin],
  props: {
    couponData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      showMore: false,
      showMoreBtn: false,
    };
  },
  computed: {
    usage() {
      return this.couponData?.usage;
    },
    $colorBrandPrimary() {
      return $colorBrandPrimary;
    },
    $colorTextPrimary() {
      return $colorTextPrimary;
    },
    $colorTextPlaceholder() {
      return $colorTextPlaceholder;
    },
  },

  mounted() {
    this.setShowMoreBtn();
    this.realInhouse &&
      this.realInhouse.track('pageview', this.$el, {
        force: true,
      });
  },
  methods: {
    formatDate(dateString) {
      if (!dateString) return;
      return dayjs(dateString * 1000).format('YYYY-MM-DD HH:mm');
    },
    setShowMoreBtn() {
      setTimeout(() => {
        const moreContent = this.$refs.moreContent;
        const lineHeight = parseInt(
          window.getComputedStyle(moreContent).lineHeight
        );
        const height = moreContent.clientHeight;

        // 计算行数
        const numberOfLines = Math.round(height / lineHeight);

        this.showMoreBtn = numberOfLines > 3;
      }, 100);
    },
    copyText(text, callback) {
      const input = document.createElement('input');
      document.body.appendChild(input);
      input.setAttribute('value', text);
      input.select();
      if (document.execCommand('copy')) {
        document.execCommand('copy');
        callback();
      }
      document.body.removeChild(input);
    },
    copyPromoCode() {
      const promoCode = this.$refs.promoCode;
      if (typeof promoCode.textContent === 'string') {
        this.copyText(promoCode.textContent, () => {
          this.$toast(this.__t('29542'));
        });
      }
    },
  },
};
