

























import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class BaseHorizontailSwiper extends Vue {
  @Prop({ default: 'left' }) direction!: string
  @Prop({ default: 'null' }) swiperKey!: string
  @Prop({ default: false }) disabled!: boolean

  handleBeforeEnter(el: any) {
    el.style.position = 'absolute'
    el.style.top = '0'
  }

  handleEnter() {
    // eslint-disable-next-line
    // console.log('enter', el)
  }

  handleAfterEnter(el: any) {
    el.style.position = 'relative'
    this.$emit('change')
  }

  handleBeforeLeave(el: any) {
    el.style.position = 'relative'
  }

  handleLeave() {
    // eslint-disable-next-line
    // console.log('leave', el)
  }

  handleAfterLeave(el: any) {
    el.style.position = 'absolute'
    el.style.top = '0'
  }
}
