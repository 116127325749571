












import { Component, Prop } from 'nuxt-property-decorator'
import Base, { getPreUnitPriceData, testPriceData } from '../price-slot-base'

export { getPreUnitPriceData, testPriceData }

@Component
export default class Index extends Base {
  @Prop({ default: '' }) styleType!: string | 'up-right' | 'desktop-type' | 'addon-pre-type'
  @Prop({ default: 'unit' }) priceType!: string

  @Prop({
    default() {
      return ['selling_price_text', 'original_price_text', 'original_selling_price_text', 'market_price_text', 'saved_text']
    }
  }) orderList!: string[]
}

