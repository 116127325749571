






































































































































































import { Component, Watch, mixins } from 'nuxt-property-decorator'
import throttle from 'lodash/throttle'
import SwitchableActivityCalendar from '~/components/experience-booking/experience-activity/package-options/activity-calendar/mobile/switchable-activity-calendar-new/switchable-activity-calendar.vue'
import PackageOptionsCalendarWrap from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/calendar/index.vue'
import ShoppingCart from '~/components/experience-booking/experience-activity/activity-shopping-cart/index.vue'
import PackageDetail
  from '~/components/experience-booking/experience-activity/package-options/package-detail/mobile/index.vue'
import BottomBar from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/attribute-bottom-bar/index.vue'
import OpenDateTips from '~/components/experience-booking/experience-activity/open-date-ticket/open-date-tips/index.vue'
import NoneFilterResult from '~/components/experience-booking/experience-activity/package-options/attraction-included/components/none-filter-result.vue'
import SwiperSingleAttributes from '~/components/experience-booking/experience-activity/package-options/mobile/attribute-package-options/swiper-single-attributes.vue'
import SwiperMultiAttributes from '~/components/experience-booking/experience-activity/package-options/mobile/attribute-package-options/swiper-multi-attributes.vue'
import SwiperIndexVisibility from '~/components/experience-booking/experience-activity/package-options/mobile/attribute-package-options/swiper-index-visibility'
import { getTaggingTrackObj } from '~/components/experience/tagging/index.vue'
import PackageOptionsBooking from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/index2.0.vue'
import ActivityPackagePromotion from '~/components/experience-booking/experience-activity/package-options/package-promotion/mobile/index.vue'
import HeroFilter from '~/components/common/hero-filter/mobile/index.vue'
import { nodeScrollTop, windowScrollTop } from '~/share/scroll2/scroll2'
import eventBus from '~/pages/experience/pay/common/event-bus'
import MobileBookingCoreMixin from '~/components/booking-core/mixins/entry/index-base-mobile'

@Component({
  components: {
    HeroFilter,
    PackageOptionsBooking,
    OpenDateTips,
    SwitchableActivityCalendar,
    PackageOptionsCalendarWrap,
    PackageDetail,
    BottomBar,
    ShoppingCart,
    NoneFilterResult,
    SwiperSingleAttributes,
    SwiperMultiAttributes,
    ActivityPackagePromotion
  }
})
export default class PackageOptions extends mixins(MobileBookingCoreMixin, SwiperIndexVisibility) {
  attrDirection = 'left'
  heroPanelVisible = false // hero 页面的 panel 要一直 pin 住
  onWindowScroll: Function = throttle(this.computeHeroPin, 5)

  isPackageScheduleMobile = true

  handlePackageTypeChange(data: string) {
    this.isPackageScheduleMobile = data === 'packageSchedules'
  }

  get isSinglePackage() {
    return this.packageList?.length === 1
  }

  get realPanelShow() {
    return this.packageId && (this.shouldShowPanel || this.heroPanelVisible)
  }

  // 只有一个属性的时候，不需要吸顶
  get shouldShowSingleAttributesPanel() {
    const validateSpec = (this.specList || []).every((item: any) => item.attr.length > 1)
    return this.realPanelShow && validateSpec
  }

  @Watch('realPanelShow', { immediate: true })
  shouldShowPanelChange(val: boolean) {
    eventBus.$emit('toggleGlobalHeaderVisible', !val)
  }

  async mounted() {
    // 单个套餐的时候不展示横滑
    if (!this.isSinglePackage) {
      this.$nextTick(this.createSwiperObserver)
    }

    await this.getActivitySchedulesApiData(this.calcActSchedulesParams)
    this.initQueryData()

    window.addEventListener('scroll', this.onWindowScroll.bind(this))
  }

  destroyed() {
    window.removeEventListener('scroll', this.onWindowScroll.bind(this))
  }

  moreObj: any = {
    maxHeight: '500px',
    status: ''
  }

  get calcShowMoreStyles() {
    const { moreObj } = this
    if (moreObj.status === 'more') {
      return {
        overflow: 'hidden',
        maxHeight: moreObj.maxHeight
      }
    }
    return {
      maxHeight: 'initial'
    }
  }

  initStatus() {
    const { moreObj } = this
    moreObj.status = ''
    setTimeout(() => {
      const ref: any = this.$refs?.pkgDetailRef
      const hNum = parseInt(moreObj.maxHeight) || 0
      if (!ref || (ref && ref?.$el?.offsetHeight <= hNum)) {
        moreObj.status = ''
      } else {
        moreObj.status = 'more'
      }
    })
  }

  clickShowMore(status: string) {
    const { moreObj } = this
    moreObj.status = status
  }

  directionChange(val: string) {
    this.attrDirection = val
  }

  computeHeroPin() {
    if (this.calcActIsHeroPage) {
      // 如果 scrollheight 比属性高，的需要pin住
      const attributesDom = this.$el.querySelector(this.attributesSelector)
      this.heroPanelVisible = (nodeScrollTop(attributesDom) + 100) <= windowScrollTop()
    }
  }

  get calcTaggingTrack() {
    const track = { spm: 'Package_Discount_Section', action: true }
    const list = this.calcPkgSelectedObj?.product_tags?.discount_tags
    const obj = getTaggingTrackObj({
      track,
      list,
      pkg: {
        packageId: this.packageId
      }
    })
    return obj
  }
}
