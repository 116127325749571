



























































import { Component, Vue, State, Prop } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import weekday from 'dayjs/plugin/weekday'
import debounce from 'lodash/debounce'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import ActivityPackageDatePicker
  from '~/components/traveller/activity/activity-calendar/activity-package-date-picker.vue'
import { getStandardDateFormat } from '~/share/data/datetime'
import ActivityCalenderSwitch from '~/components/traveller/activity/activity-calendar/activity-calender-switch.vue'
import { diffDay } from '~/share/utils'
import SwitchableCalendar
  from '~/components/experience-booking/experience-activity/package-options/activity-calendar/switchable-calendar.vue'
import { Schedule } from '~/components/experience-booking/experience-activity/package-options/activity-calendar/types'

dayjs.extend(weekday)

@Component({
  components: {
    ActivityPackageDatePicker,
    ActivityCalenderSwitch,
    SwitchableCalendar,
    CalenderTips,
    CalendarLengends
  }
})

export default class ActivityDatePickerDesktop extends Vue {
  @State klook!: Data.Klook
  @Prop() schedules!: Schedule[]
  @Prop() packageSchedules!: Schedule[]
  @Prop() value!: string
  @Prop() packageId!: number
  @Prop() activityId!: number
  @Prop() templateId!: number
  @Prop() activityPageTemplateId!: number
  @Prop() isPackageSchedule!: boolean
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: '' }) packageName!: string
  @Prop({ default: 0 }) calendarDays!: number

  dateShow: boolean = false

  changePackageType(data: string) {
    this.$emit('changePackageType', data)
  }

  handleDateChange(date: string) {
    if (date === this.value) {
      return
    }

    this.$emit('input', date)
    const day = diffDay(new Date(), new Date(date))
    this.$sendGTMCustomEvent(`Activity Page|Calendar Date Clicked|${this.activityId},${this.templateId}|${day}`)
    this.hideDatePicker()
    this.changeDate(date)
  }

  changeDate(date: any) {
    const millisecond = new Date(date).getTime() - new Date().getTime()

    const days = Math.ceil(millisecond / (1000 * 60 * 60 * 24))

    this.$logquery.service({
      timestamp: Date.now(),
      level: 'I',
      isMasked: true,
      message: JSON.stringify({
        package_id: this.packageId,
        activity_id: this.activityId,
        tag_name: 'desktop_activity_date_difference_select_date',
        date_diff: days
      }),
      tag: 'desktop-activity-date-difference'
    }, {
      headers: { 'X-Platform': this.klook.platform }
    })
  }

  cleanDate() {
    this.$emit('input', '')
  }

  hideDatePicker() {
    this.dateShow = false
    this.packageId > 0 && !this.isPackageSchedule && this.$emit('changePackageType', 'packageSchedules')
  }

  showDatePicker() {
    this.dateShow = true
    this.datePickerSpmTrack()

    const { activityId, activityPageTemplateId } = this
    this.$sendGTMCustomEvent(`Calendar Clicked|Activity Page|${activityId}_${activityPageTemplateId}`)
  }

  datePickerSpmTrack() {
    const dom = document.querySelector('.js-spm-package-date-picker') as HTMLElement
    this.$inhouse.updateBinding(dom, {
      ext: {
        SelectType: 'User'
      }
    })

    const dd = setTimeout(() => {
      this.$inhouse.track('action', '#desktop-package-date-picker')
      clearTimeout(dd)
    }, 500)
  }

  get datePickerButtonContent() {
    if (this.value !== '' && !this.selectDateInOutSideDate()) {
      return this.formatDay(this.value)
    }
    return this.$t('activity.new.check_availability')
  }

  selectDateInOutSideDate() {
    if (this.value !== '') {
      return false
    }
    return true
  }

  formatDay(date: string) {
    const d = getStandardDateFormat(
      date,
      this.$t.bind(this),
      this.klook.language,
      1
    )
    return d
  }

  handleScroll = debounce((e) => {
    const datePicker = this.$refs.activityDatePicker as HTMLElement
    const showDatePickerButton = (this.$refs.showDatePickerButton as Vue)?.$el
    const target = e.target as HTMLElement
    // 点击的元素为 datepicker
    // 点击的元素为 datepicker 的子元素
    // 点击的元素为 查看日历按钮
    const a = this.dateShow &&
      !(target === datePicker ||
        datePicker.contains(target) ||
        showDatePickerButton?.contains(target))
    if (a) {
      this.hideDatePicker()
    }
  })

  watchDatePickerOutsideClick() {
    // 监听全局点击事件
    document.addEventListener('click', this.handleScroll)
  }

  clickOutSideDatePicker(e: any) {
    // 判断用户的点击行为是否在 input 框和弹层上
    // 若不是，则收起弹层

    if (this.dateShow && this.$refs.activityDatePicker) {
      const clickDatePicker = (this.$refs.activityDatePicker as HTMLElement).contains(e.target) ||
        (this.$refs.showDatePickerButton as Vue)?.$el?.contains(e.target)

      if (!clickDatePicker) {
        this.hideDatePicker()
      }
    }
  }

  mounted() {
    this.watchDatePickerOutsideClick()
    document.addEventListener('click', this.clickOutSideDatePicker)
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll)
    document.removeEventListener('click', this.clickOutSideDatePicker)
  }
}

