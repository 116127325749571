


























































import { Component, mixins, Prop, Watch } from 'nuxt-property-decorator'
import { IPackageWithUnitsAndPromotion } from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/package-card-list/type'
import apis from '~/share/data/apis'
import ExpPkgItem from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/pkg-item.vue'
import PackageOptionsSkuBase from '~/components/booking-core/mixins/entry/index-section-sku'
import { getTaggingExtraTrackObj } from '~/components/experience/tagging/index.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import PackageOptionsBooking from '~/components/experience-booking/experience-activity/package-options/desktop/card-sku-with-detail/pkg-options-booking.vue'
import shoppingCartMixin from '~/components/booking-core/mixins/common/shopping-cart'
import CommonContentLoading from '~/components/experience-booking/activity/package-detail/status/common-content-loading.vue'
import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2'
import eventBus from '~/pages/experience/pay/common/event-bus'
import dataUtils from '~/components/booking-core/utils/data-utils'

@Component({
  inheritAttrs: false,
  components: {
    ExpPkgItem,
    PackageOptionsBooking,
    CommonContentLoading
  }
})
export default class PackageCardListWrap extends mixins(PackageOptionsSkuBase, shoppingCartMixin) {
  @Prop({ default: 0 }) preview!: number
  @Prop({ type: String, default: '' }) date!: string
  @Prop({ type: Boolean, default: false }) isLinePage!: boolean
  @Prop({ type: Array, default: () => [] }) packages!: ExperienceActivity.Packages
  @Prop() isPreview!: boolean
  @Prop({ type: Array, default: () => [] }) packagesWithSku!: IPackageWithUnitsAndPromotion[]
  @Prop() businessData!: any

  pkgShowMore = {
    init: false,
    len: 8,
    show: false
  }

  unfoldPkgIdCache = 0
  pkgsCacheData: any = {}
  schedulesUnitsState = ''

  @Watch('state', { immediate: true })
  handleStateChange(state: string) {
    if (state === 'fetching') {
      this.unfoldPkgIdCache = 0
      this.handlerResetPkgCacheData()
    }
  }

  handlerResetPkgCacheData() {
    this.resetAllFoldStatus()
    this.clearRightPriceData()
  }

  clearRightPriceData(data = {}) {
    eventBus.$emit('updateRightPriceData2bus', data || {})
  }

  get limitedAvailablePackageList() {
    const { pkgShowMore, filterPackagesWithSku: availablePackageList } = this
    if (!pkgShowMore.init && availablePackageList.length > pkgShowMore.len) {
      pkgShowMore.init = true
      pkgShowMore.show = true
    }
    return pkgShowMore.show ? availablePackageList.slice(0, pkgShowMore.len) : availablePackageList
  }

  get showMoreText() {
    const len = this.filterPackagesWithSku.length
    return `${this.$t('city_page_view_more')} (${len - this.pkgShowMore.len}+)`
  }

  getIsReady(pkg: any) {
    if (pkg?.package_id === this.unfoldPkgIdCache) {
      return ['success', 'failure'].includes(this.schedulesUnitsState)
    }
    return false
  }

  handleUpdatePackageId(packageId: number) {
    this.$emit('updatePkgId', packageId)
  }

  async mounted() {
    const { query } = this.$route
    const { shop_id: shopId } = query
    if (shopId) {
      const id: number = await this.getShoppingCartList({ shopId })
      if (id) {
        this.handleUpdatePackageId(id)
      }
    }
    const scrollPkg = parseInt(query?.scroll_pkg as string) || 0
    scrollPkg && this.clickPackageHandler({ package_id: scrollPkg }, true)
  }

  async getShoppingCartList(data: any) {
    const res = await this.$axios.$get(apis.shoppingCartList)
    if (res && res.success && res.result) {
      const group = res.result.group
      this.modificationShoppingCart = true
      const { shopId } = data

      if (shopId && group) {
        let shoppingInfoSnapshot: any = null
        group.find((x: any) => {
          shoppingInfoSnapshot = x.items.find((y: any) => {
            return y.shoppingcart_id === Number(shopId)
          })
          return !!shoppingInfoSnapshot
        })
        if (!shoppingInfoSnapshot) {
          return 0
        }
        const shoppingCartPackageId = shoppingInfoSnapshot.package_id
        const packageDetail = this.packages.find((item: any) => {
          return item.package_id === shoppingCartPackageId
        })
        if (!packageDetail) {
          return 0
        }
        for (let i = 0; i < shoppingInfoSnapshot.main_package_specs.length; i++) {
          const item = shoppingInfoSnapshot.main_package_specs[i]
          if (!packageDetail.spec_attr_id.includes(item.attr_id)) {
            return 0
          }
        }
        return shoppingCartPackageId
      }
    }
  }

  getUnitsList(pid: number) {
    const skuList: any[] = this.limitedAvailablePackageList || []
    const item = skuList.find((sku: any) => sku.package_id === pid)
    return item?.units || []
  }

  // 埋点相关 start
  getTaggingExtraTrackObj(pkg: any) {
    const list = [...(pkg?.product_tags?.attribute_tags || []), ...(pkg?.product_tags?.discount_tags || [])]
    const track = getTaggingExtraTrackObj(list)
    return track || {}
  }

  getPkgTrack(pkg: any, index: number, list: any[]) {
    const obj = this.getTaggingExtraTrackObj(pkg)
    const isSuspended = pkg?.publish_info?.publish_status === 2
    const isWarming = pkg?.publish_info?.publish_status === 3
    const ext = JSON.stringify({
      SelectType: 'User', // System/User (系统组合还是用户选取),
      PackageTagIDList: obj?.TagIDList || [],
      PackageTagName: obj?.TagKeyList || [],
      StatusType: isSuspended ? 'Unavailable' : isWarming ? 'ForSalesSoon' : 'Normal'
    })
    return {
      type: 'module',
      spm: 'PackageType_LIST',
      query: {
        oid: `package_${pkg.package_id}`,
        len: list.length,
        idx: index,
        ext
      }
    }
  }
  // 埋点相关end

  resetAllFoldStatus(package_id = 0) {
    Object.keys(this.pkgsCacheData).forEach((key: string) => {
      const pid = Number(key)
      if (package_id !== pid) {
        this.setFoldStatus(pid, false)
      }
    })
  }

  setFoldStatus(pid: number, isUnfold: boolean) {
    const data = this.pkgsCacheData[pid]
    data && this.$set(data, 'isUnfold', isUnfold)
  }

  scrollToPackageOptionsTop(pid: number = 0, mergeOption: any = {}) {
    const d = pid && document.querySelector(`#js-pkg-id-${pid}`)
    const top = nodeScrollTop(d)
    scroll2({
      scrollY: top,
      lockLocation: 61 + 47,
      ...mergeOption
    })
  }

  handlerUnfoldStatus(pkgData: any, isb: boolean) {
    const pid = pkgData?.package_id
    this.unfoldPkgIdCache = pid
    if (isb) {
      const cacheData = this.pkgsCacheData[pid]
      if (isb === cacheData?.isUnfold) {
        return false
      }
      this.resetAllFoldStatus(pid)
      this.$nextTick(() => {
        this.clickPackageHandler(pkgData, isb)
      })
      setTimeout(() => {
        this.scrollToPackageOptionsTop(pid, { duration: 100 }) // duration不能设置为0
      }, 350)
    } else {
      this.clickPackageHandler(pkgData, isb)
    }
  }

  getPkgCacheData(key: string, pkgData: any) {
    if (!key) {
      return
    }
    const { package_id } = pkgData || {}
    const cacheData = this.pkgsCacheData[package_id] || {}
    return cacheData[key]
  }

  setPkgCacheData(key: string, pid: any, data: any) {
    if (!key || !pid) {
      return
    }
    const cacheData = this.pkgsCacheData[pid]
    if (!cacheData) {
      this.$set(this.pkgsCacheData, pid, {
        [key]: data
      })
    } else {
      this.$set(cacheData, key, data)
    }
    return data
  }

  clickPackageHandler(pkgData: any, isb: boolean) {
    const { package_id } = pkgData || {}
    if (!package_id) {
      return
    }
    const cacheData = this.pkgsCacheData[package_id]
    if (isb === cacheData?.isUnfold) {
      return false
    }
    if (!cacheData) {
      this.setPkgCacheData('pkgData', package_id, pkgData)
    }
    this.handleUpdatePackageId(package_id)
    this.setFoldStatus(package_id, isb)
  }

  // emit事件
  setPkgDetailStatus(pkgData: any, isb: boolean) {
    this.$emit('setPkgDetailStatus', pkgData, isb)
  }

  updatePkgDetail(pkgData: any, pkgDetail: any) {
    this.$emit('updatePkgDetail', pkgData, pkgDetail)
  }

  updateSchedulesUnitsState(state: string) {
    this.schedulesUnitsState = state
  }

  getBookingCoreData2section(pid: number) {
    const {
      activityId,
      publishInfo,
      specList,
      activityTicketType,
      isSupportShoppingCart,
      dateDescription,
      packageFilterTagList,
      isTranslation
    } = this.$attrs
    const bookingCore = dataUtils.getBookingCoreData2vm(this)
    bookingCore.packageId = pid
    const payload = {
      ...bookingCore,
      packageId: pid,
      activityId,
      publishInfo,
      specList,
      activityTicketType,
      isSupportShoppingCart,
      dateDescription,
      packageFilterTagList,
      isTranslation
    }
    return payload
  }

  // 应该是活动级的时间的优先级别高于套餐层级的时间,从cacheData里面读时间可能会因为套餐id不变，导致有bug，但是也不能不使用缓存，不使用缓存会有交互bug，导致sku card booking option 展开后，没有记住上一次时间
  getDate(pkg: any) {
    return this.date || this.getPkgCacheData('pkgDate', pkg)
  }
}
