







import { Component, Prop, State } from 'nuxt-property-decorator'
import { IconTime } from '@klook/klook-icons'
import PublishInfoBase from '~/components/common/publish-info/publish-info-base'

@Component({
  components: {
    IconTime
  }
})
export default class ComboWarningSlogan extends PublishInfoBase {
  @Prop({ default: '' }) forceText!: string
  @State((state) => state.klook.platform) platform!: Data.Platform
}
