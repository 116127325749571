





















































import { Component, Prop, Vue, State } from 'nuxt-property-decorator'
import { IPackageItem } from './type'
import PackageCardPromoBelt from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/promo-belt.vue'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'
import CardHeader from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/card-header.vue'
import PackageCardContent from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/package-card-list/package-card-content.vue'
import PriceAndButton from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/price-and-button.vue'
import { currencyExchange } from '~/share/utils'
import ExpTagging, { getTaggingExtraTrackObj } from '~/components/experience/tagging/index.vue'
import PublishInfoBase from '~/components/common/publish-info/publish-info-base'
import CutDown from '~/components/experience/cut-down/index.vue'
import { isLessThanTargetTime } from '~/share/count-down'

@Component({
  components: {
    ExpTagging,
    PackageCardPromoBelt,
    WarmingSlogan,
    CardHeader,
    PackageCardContent,
    PriceAndButton,
    CutDown
  }
})
export default class PackageCard extends PublishInfoBase {
  @State(state => state.klook.currency) currency!: Data.Platform
  @Prop({ default: null }) packageData!: IPackageItem
  @Prop() buttonContent!: string
  @Prop({ default: () => ({}) }) ext!: any
  @Prop() date?: string
  @Prop() spmIndex!: number
  @Prop() spmLength!: number

  get calcTags2attribute() { // mweb活动页-card_sku
    return this.packageData?.product_tags?.attribute_tags || []
  }

  get calcTags2discount() { // mweb活动页-card_sku
    const discountTags = this.packageData?.product_tags?.discount_tags || []
    const tagsList: any = []
    if (discountTags?.length) {
      // 先过滤掉倒推的数据(这里比较特殊)
      const filterTags = discountTags.filter((tag: any) => {
        const inner_vars = tag?.inner_vars || {}
        return !inner_vars?.inner?.derivation_info || !inner_vars?.inner?.derivation_info?.is_derived
      })

      filterTags?.length && filterTags.forEach((item: any) => {
        if (item?.inner_vars?.rate_plan?.count_down_end_time) {
          const flag = isLessThanTargetTime(item.inner_vars.rate_plan.count_down_end_time)
          flag && tagsList.push(item)
        } else {
          tagsList.push(item)
        }
      })
    }

    return tagsList
  }

  get cutDownTimeList() {
    const tags = this.calcTags2discount
    const list: any = []

    if (tags?.length) {
      tags.forEach((item: any) => {
        const endTime = item?.inner_vars?.rate_plan?.count_down_end_time
        endTime && list.push(endTime)
      })
    }

    return list
  }

  get trackExtInfo() {
    const { isWarming, isSuspended } = this
    const priceRef = (this.$refs.price as Vue)
    const packagePriceRef = (priceRef?.$refs?.packagePrice as Vue)?.$refs?.price
    const sellingPrice = (packagePriceRef as any)?.selling || '0'
    const price = sellingPrice.replace(/[^0-9]/ig, '')
    const salesPrice = currencyExchange(price, this.currency) ?? sellingPrice

    const { discount_desc, credits } = this.packageData

    const promotionType: any = {
      4: 'WarmUp',
      5: 'Sales'
    }

    return {
      ...this.ext,
      PromoCard: `${this.event?.tag || 'null'}, ${encodeURIComponent(discount_desc) || 'null'}`,
      Integral: Math.floor(parseFloat(credits.toString())),
      SalesPrice: salesPrice,
      PromotionType: promotionType[this.packageData?.promotion_price?.promotion_event?.status] || 'Normal',
      SelectType: 'User',
      StatusType: isSuspended ? 'Unavailable' : isWarming ? 'ForSalesSoon' : 'Normal'
    }
  }

  getTaggingExtraTrackObj(pkg: any) {
    const list = [...(pkg?.product_tags?.attribute_tags || []), ...(pkg?.product_tags?.discount_tags || [])]
    const track = getTaggingExtraTrackObj(list)
    return track || {}
  }

  get trackInfo() {
    const { isWarming, isSuspended } = this
    const { package_id } = this.packageData
    return {
      oid: `package_${package_id}`,
      idx: this.spmIndex,
      len: this.spmLength,
      ext: {
        ...this.getTaggingExtraTrackObj(this.packageData),
        ...this.trackExtInfo,
        StatusType: isSuspended ? 'Unavailable' : isWarming ? 'ForSalesSoon' : 'Normal'
      }
    }
  }

  get event() {
    return this.packageData?.promotion_price?.promotion_event
  }

  reload() {
    window && window.location.reload()
  }

  // get availableTime() {
  //   const iconList = this.packageData?.icon_list || [] as any
  //   let time = ''
  //   for (const icon of iconList) {
  //     if (icon.icon_type === 'available_date') {
  //       time = icon?.data?.time || ''
  //       break
  //     }
  //   }
  //   return time
  // }
}
