





























import { Vue, Component, Prop, Watch, Ref } from 'nuxt-property-decorator'
import { getCss } from '~/share/utils'
import { CardSwiper } from '~/components/traveller/home-page/card-swiper'
import ExpTagging from '~/components/experience/tagging/index.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    CardSwiper,
    ExpTagging
  }
})
export default class Index extends Vue {
  @Ref() swiperRef!: any
  @Prop() value!: string
  @Prop() list!: any[]

  activeItem: any = null
  showMore = false
  firstInitObj = {
    isInit: false,
    beforeHeight: '',
    afterHeight: ''
  }

  swiperOption = {
    slidesPerView: 'auto',
    freeMode: true,
    observer: true,
    observeParents: true
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  getTagTrack(item: any) {
    const track = item?.track || {}
    const oid = `tag_${track?.tag_id}`
    const ext = encodeURIComponent(JSON.stringify({ TagName: `tag_${track?.tag_key}` }))
    const obj = {
      type: 'module',
      spm: 'Package_Filter',
      query: {
        oid,
        ext
      }
    }
    return obj
  }

  @Watch('list')
  listWatch() {
    this.init()
  }

  @Watch('value')
  activeSectionChange(val: string) {
    if (val) {
      this.slide(val)
    }
  }

  get visible() {
    return this.list && this.list.length
  }

  setShowMore(isb: boolean) {
    this.showMore = isb
    this.$emit('showMore', isb)
    isb || this.slide(this.value)
  }

  getItem(val: any) {
    return this.$el.querySelector(`[data-value="${val}"]`)
  }

  slide(val: string) {
    const str: any = 'instant'
    setTimeout(() => {
      const node = this.getItem(val)

      if (node) {
        node.scrollIntoView({
          behavior: str,
          inline: 'center',
          block: 'nearest'
        })
      }
    })
  }

  clickHandler(item: any) {
    if (this.value === item.tag_key) {
      // 清空
      item = {}
    }
    this.activeItem = item
    this.$emit('change', item.tag_key, { value: this.value })
  }

  get calcShowArrow() {
    const { firstInitObj } = this
    return parseInt(firstInitObj.beforeHeight) > parseInt(firstInitObj.afterHeight)
  }

  init() {
    const { firstInitObj } = this
    firstInitObj.isInit = false
    setTimeout(() => {
      firstInitObj.beforeHeight = getCss(document.querySelector('.tag-filter .card-swiper'), 'height')
      firstInitObj.isInit = true
      setTimeout(() => {
        firstInitObj.afterHeight = getCss(document.querySelector('.tag-filter .card-swiper'), 'height')
      }, 1)
    }, 1)
  }

  mounted() {
    this.init()
  }
}

export const getTestTags = (num: number = 9) => {
  const item = {
    type: 1, // tag icon 样式类型, 具体到一个 模块中是写死的。1:middle; 2:small
    text: 'Display tag',
    text_color: '#212121',
    background_color: '#eeeeee',
    background_alpha: 0, // 背景透明度, 0:不透明；100:透明
    border_color: '#eeeeee', // 边框颜色
    radius: 4, // 圆角值
    right_action: {
      icon: '',
      desc: ''
    },
    tag_key: '', // 辅助字段，tag key
    group_keys: ['product_tag', 'promotion_tag'] // tag_key 的父级 key 列表
  }
  const arr = new Array(num).fill(0).map((_: any, i: number) => {
    return {
      ...item,
      tag_key: `key${i}`
    }
  })

  return arr
}
