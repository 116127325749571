



























import debounce from 'lodash/debounce'
import { Vue, Component, Prop, State, namespace } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'

const AuthModule = namespace('auth')

@Component({
  components: {}
})
export default class ActivityReviewsHelpful extends Vue {
  @State klook!: Data.Klook
  @AuthModule.State isLoggedIn!: boolean
  @State User!: Data.User
  @Prop({ default: () => {} }) comment!: any
  @Prop({ default: 20 }) likeIconWidth!: number | string

  commitAction = 0 // 0：没有按；1：点赞；2：取消点赞
  debounceCommitAction = debounce(this.commitReviewsLike, 400)

  get hasLiked() {
    const { comment } = this
    return comment.has_liked !== undefined ? comment.has_liked : comment.hasLiked
  }

  get likeCount() {
    const { comment } = this
    return comment.like_count !== undefined ? comment.like_count : comment.likeCount
  }

  get reviewId() {
    const { comment } = this
    return comment.id !== undefined ? comment.id : comment.review_id
  }

  async handleNoLogin() {
    await this.$loginSDK({
      purpose: 'CommentLike',
      success: () => {
        this.clickLike()
      }
    })
  }

  clickLike() {
    if (!this.isLoggedIn) {
      this.handleNoLogin()
      return null
    }

    if (this.hasLiked) {
      this.likeRemove()
      this.commitAction -= 1
    } else {
      this.likeAdd()
      this.commitAction += 1
    }

    this.debounceCommitAction()
    this.$sendGTMCustomEvent('Activity Page|Helpful Button Clicked')
  }

  maybeSet(obj: any, field: string, val: unknown) {
    if (obj[field] !== undefined) {
      this.$set(obj, field, val)
    }
  }

  likeAdd() {
    const { comment, likeCount } = this
    this.maybeSet(comment, 'has_liked', true)
    this.maybeSet(comment, 'hasLiked', true)
    this.maybeSet(comment, 'like_count', likeCount + 1)
    this.maybeSet(comment, 'likeCount', likeCount + 1)
  }

  likeRemove() {
    const { comment, likeCount } = this
    this.maybeSet(comment, 'has_liked', false)
    this.maybeSet(comment, 'hasLiked', false)
    this.maybeSet(comment, 'like_count', likeCount - 1)
    this.maybeSet(comment, 'likeCount', likeCount - 1)
  }

  // 提交请求，此函数会被debounce
  // 如果 commitAction 为 1，则点赞
  // 如果 commitAction 为 0，则什么也不做
  // 如果 commitAction 为 -1，则取消点赞
  async commitReviewsLike() {
    const { commitAction } = this
    let res = null
    if (commitAction > 0) {
      res = await this.$axios.$post(apis.activityReviewsLikeAdd, `review_id=${this.reviewId}`)
    } else if (commitAction < 0) {
      res = await this.$axios.$post(apis.activityReviewsLikeRemove, {
        review_id: this.reviewId
      })
    }

    this.commitAction = 0

    if (res?.error?.code === '4001') {
      // 恢复原样
      if (commitAction > 0) {
        this.likeRemove()
      } else if (commitAction < 0) {
        this.likeAdd()
      }
      await this.handleNoLogin()
    }
  }
}
