
































import { Component, mixins, namespace } from 'nuxt-property-decorator'
import ActivityMiniCalendar
  from '~/components/experience-booking/experience-activity/package-options/activity-calendar/mobile/activity-mini-calendar/activity-mini-calendar.vue'
import PackageCard from '~/components/experience-booking/experience-activity/package-options/package-card-list/index.vue'
import CalendarBase from '~/components/experience-booking/experience-activity/package-options/base/calendar-base'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import { ExperienceActivity } from '~/types/experience/activity-section'
import CardBottomBar
  from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/card-bottom-bar/index.vue'
import OpenDateTips from '~/components/experience-booking/experience-activity/open-date-ticket/open-date-tips/index.vue'
import ActivityAutoBookBase from '~/components/experience-booking/experience-activity/package-options/base/activity-auto-book-base'
import ShoppingCart from '~/components/experience-booking/experience-activity/activity-shopping-cart/index.vue'
import { routerPush } from '~/share/utils'
import { Activity } from '~/types/traveller/activity'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    OpenDateTips,
    ActivityMiniCalendar,
    PackageCard,
    CardBottomBar,
    ShoppingCart
  }
})
export default class CardPackageOptions extends mixins(CalendarBase, BusinessBase, ActivityAutoBookBase) {
  static displayName = 'MobilePackage_options_card_type'
  @ActivityModule.Getter('currPackageOptionTab') currPackageOptionTab!: Activity.ComboTab
  @ActivityModule.State activityDetail!: any

  get isLinePage() {
    return this.basicData?.is_line_page ?? false
  }

  get activityId() {
    return this.businessData.activity_id
  }

  get hasSomeOpenDate() {
    const arr = this.packages || []
    return arr.some((o: any) => o.package_ticket_type === 3)
  }

  get packages(): ExperienceActivity.Packages {
    return this.businessData.packages
  }

  get pickupDateDescription() {
    return this.businessData.date_description
  }

  get isInCombo() {
    return this.currPackageOptionTab === 'combo'
  }

  getActivityIdAndPackageId(package_id: number) {
    return {
      activity_id: this.activityId,
      package_id
    }
  }

  toBook(data: ExperienceActivity.IPackageCard) {
    routerPush(this, {
      name: 'ExperienceActivityBookOptions',
      params: {
        activityId: String(this.activityId)
      },
      query: {
        type: 'book',
        act_date: this.date || '',
        packageId: String(data.package_id)
      }
    })
    // this.$router.push({
    //   name: 'ExperienceActivityBookOptions',
    //   params: {
    //     activityId: String(this.activityId)
    //   },
    //   query: {
    //     type: 'book',
    //     act_date: this.date || '',
    //     packageId: String(data.package_id)
    //   }
    // })
  }

  get dateVisible() {
    const isOpenTicket = this.businessData.activity_ticket_type === 3
    return !(isOpenTicket)
  }

  get basicData() {
    return this.activityDetail?.basic_data ?? {}
  }

  async mounted() {
    await this.getSchedules()
  }
}
