



















































import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'
import RecentReviewsV2 from '~/components/experience-booking/experience-activity/review/mobile/recent-reviews-v2.vue'
import ReviewsModal from '~/components/experience-booking/experience-activity/review/mobile/reviews-modal/index.vue'
import ReviewRatingV2 from '~/components/experience-booking/experience-activity/recent-review/review-rating-v2.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    ReviewRatingV2,
    RecentReviewsV2,
    ReviewsModal
  }
})
export default class Reviews extends Vue {
  @Prop() subScoreList!: any[]
  @Prop() bestReviewsList!: any[]
  @Prop() reviewsList!: any[]
  @Prop() track!: any
  @Prop() version!: string
  @Prop() should_show!: boolean
  @Prop() activity_id!: number
  @Prop({ type: Number, default: 0 }) review_count!: number
  @Prop({ type: Number, default: 0 }) total!: number
  @Prop({ type: [Number, String], default: 0 }) score!: number | string

  @Watch('bestReviewsList', { immediate: true })
  bestReviewsListWatch(arr: any[]) {
    if (!arr?.length) {
      this.$emit('hide-section')
    }
  }

  visible = false

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  viewMore() {
    this.visible = true
  }

  close() {
    this.visible = false
  }
}

export const getPropsData2section = (businessData: any, mergeData?: any) => {
  const { track, version, activity_id, should_show, act_sub_score, reviews_v2, review_count, score, total, item } = businessData || {}
  return {
    track,
    version,
    activity_id,
    should_show,
    subScoreList: act_sub_score || [],
    bestReviewsList: reviews_v2 || [],
    review_count,
    score,
    total,
    reviewsList: item || [],
    ...(mergeData || {})
  }
}
