



















































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import RecentReviews from '~/components/experience-booking/experience-activity/review/mobile/recent-reviews.vue'
import ReviewsModal from '~/components/experience-booking/experience-activity/review/mobile/reviews-modal/index.vue'
import ReviewRating from '~/components/experience-booking/experience-activity/recent-review/review-rating.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    ReviewRating,
    RecentReviews,
    ReviewsModal
  }
})
export default class Reviews extends Vue {
  @Prop() businessData!: any

  visible = false

  get calcSubScoreList() {
    return this.businessData?.act_sub_score || []
  }

  get latestBestReview() {
    const recentReviewList = this.businessData.reviews as ExperienceActivity.ILatestBestReview[]
    return recentReviewList || []
  }

  get reviewCount() {
    return this.businessData?.total
  }

  get score() {
    return this.businessData?.score
  }

  get reviewListTotal() {
    return this.businessData?.total
  }

  get reviewList() {
    return this.businessData.item
  }

  get needHideReviews() {
    return this.reviewCount < 10 && this.score < 4
  }

  viewMore() {
    this.visible = true
  }

  close() {
    this.visible = false
  }
}
