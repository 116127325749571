import { Context } from '@nuxt/types'
import apis from '~/share/data/apis'

const {
  experienceActivityDetail, // 楼层化之后的活动详情接口
  experienceFastPassActivityDetail, // express fast pass （扫码快速入口）的活动详情接口
  experienceActivityDetailForWhiteLabel // white label 平台化的活动详情接口
} = apis

function activityDetailApi(ctx: Context) {
  // default activity detail api
  const api = experienceActivityDetail

  // fast pass check out
  if (ctx.store.state.klook.utilConfig?.f_source_type === 'express_check_out') {
    return experienceFastPassActivityDetail
  }

  // white label
  if (ctx.store.state.klook.utilConfig?.whiteLabelUtilConfig) {
    return experienceActivityDetailForWhiteLabel
  }

  return api
}

export default activityDetailApi
