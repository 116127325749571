




















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import LayerHeader from '~/components/traveller/activity/layer/layer-header.vue'

@Component({
  components: {
    LayerHeader
  }
})
export default class ActivityModal extends Vue {
  @Prop() title!: string
  @Prop() visible!: boolean

  close() {
    this.$emit('close')
    this.$emit('update:visible', false)
  }
}
