








































































































import { Component, mixins } from 'nuxt-property-decorator'
import HeaderTips from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/headerTips/index.vue'
import CardHeader from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/card-header.vue'
import BookingCalendar from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/booking-calendar.vue'
import CombonBookingUnit from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/combonBookingUnit/index.vue'
import CombonBookingBase from '~/components/experience-booking/experience-activity/combo-option/booking-options/combo-booking-base'
import ExpTagging from '~/components/experience/tagging/index.vue'
import CalendarList from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/calendarList/index.vue'
import SkuBottomBar from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/bottom-bar/index.vue'
import Skeleton from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/skeleton/index.vue'
import PaymentDetail from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/paymentDetail/index.vue'
import UnitWarn from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/booking-calendar/unitWarn.vue'
import ExpLoadingState from '~/components/experience/loading-state/index.vue'
import Tips from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/discount-tips.vue'
import { BookingOptionsSkeleton } from '~/components/common/skeleton-data/index.js'

@Component({
  components: {
    ExpTagging,
    HeaderTips,
    CardHeader,
    BookingCalendar,
    CombonBookingUnit,
    SkuBottomBar,
    CalendarList,
    Tips,
    Skeleton,
    UnitWarn,
    PaymentDetail,
    ExpLoadingState
  }
})
export default class ComboPackageOptionsBooking extends mixins(CombonBookingBase) {
  BookingOptionsSkeleton = BookingOptionsSkeleton

  get skeletonData() {
    const { BookingOptionsSkeleton } = this
    return {
      ...BookingOptionsSkeleton.mobile.page,
      style: { margin: 0 }
    }
  }

  fixedHackStyle: any = {
    paddingBottom: '94px'
  }

  updateInfos(infos: any) {
    if (!infos) {
      return
    }
    this.fixedHackStyle = {
      paddingBottom: infos.height + 'px'
    }
  }
}
