






import { Vue, Component } from 'nuxt-property-decorator'

@Component
export default class PackageOptionsLoading extends Vue {
}
