














































import { Component, Getter } from 'nuxt-property-decorator'
import { IconDestinationOut, IconHeart } from '@klook/klook-icons'
import ActivityLocation from '../activity-location.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import WishButton from '~/components/experience-booking/experience-activity/wish-button.vue'
import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2'
import CardDeparture from '~/components/experience/activity/activity-card/card-departure.vue'
import CommonScore from '~/components/experience-booking/experience-activity/score-participants/common-score.vue'

@Component({
  components: {
    CommonScore,
    IconDestinationOut,
    CardDeparture,
    WishButton,
    ActivityLocation,
    IconHeart
  }
})
export default class ExperienceScoreParticipants extends BusinessBase {
  @Getter isGuestCheckout!: boolean

  isHeartIconHover = false

  get calcDepartureList() {
    return this.businessData?.departures || []
  }

  get address() {
    const components = this.businessData?.components ?? []
    const [data] = components.filter((item: any) => item.type === 'address')
    if (!data) {
      return ''
    }
    const list = data.data || []
    const addr = list[0] || null
    return addr
  }

  static displayName = 'DesktopScore_participants'
  get activityId() {
    return this.businessData.activity_id
  }

  get reopen() {
    return {
      ...this.businessData
    }
  }

  get isPublished() {
    return this.businessData.published
  }

  get visible() {
    return !this.isGuestCheckout && this.isPublished && !this.klook.utilConfig.whiteLabelUtilConfig
  }

  viewMore() {
    const top = nodeScrollTop(document.querySelector('#reviews'))
    if (top) {
      scroll2({ scrollY: top, lockLocation: 105 })
    }
  }
}
