import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class BookingOptionsUnitsTips extends Vue {
  @Prop() minPax!: number
  @Prop() maxPax!: number

  get tips() {
    // 最小预订数量大于等于2，且最大预订数量为unlimited时
    if (this.minPax > 1 && this.maxPax === 9999) {
      return this.$t('19390-activity.v2.error.package_min_pax', {
        min_pax: '<span class="min_pax">' + this.minPax + '</span>'
      })
    }

    // 最小预订数量为1，且最大预订数量为非unlimited时
    if (this.minPax === 1 && this.maxPax < 9999) {
      return this.$t('activity.v2.error.package_max_pax', [this.maxPax])
    }

    if (this.minPax === this.maxPax && this.minPax >= 0) {
      return this.$t('78960', { number: this.maxPax })
    }

    // 最小预订数量大于等于2，且最大预订数量为非unlimited时
    if (this.minPax > 1 && this.maxPax < 9999) {
      return this.$t('activity.v2.error.package_min_to_max', [this.minPax, this.maxPax])
    }
  }
}
