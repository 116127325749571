


































import { isEmpty } from 'lodash'
import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import RwdSwiper from '~/components/experience/rwd-swiper/index.vue'
import TnaActivityCard from '~/components/tour/tna-activity-card/index.vue'
import { getInhouseExtDataByTags } from '~/components/experience/section/utils'

@Component({
  components: {
    RwdSwiper,
    TnaActivityCard
  }
})
export default class BaseActivityCardsSwiper extends Vue {
  @State klook!: Data.Klook
  @Prop() swiperBind!: any
  @Prop({ default: () => [] }) data!: any[]
  @Prop({ default: 0 }) perView!: number // 选传，用来覆盖本组件的perview
  @Prop() logoWidth!: number
  @Prop({ default: false }) isBigCard!: boolean
  @Prop({ default: null }) getDataNosnippet!: Function | null

  swiperOption = {
    freeMode: true,
    spaceBetween: 20,
    width: 148,
    slidesOffsetAfter: process.client ? -document.body.offsetWidth + 148 + 32 : 0
  }

  // 本组件固定:
  // 小卡片：perView在web是3，在mweb是2，
  // 大卡片：perView在web是2，在mweb是1，
  // 如果是其它情况，需要自己传perView参数，必要的时候还需要用css覆盖
  get defaultPerView() {
    if (this.isBigCard) {
      return this.klook.platform === 'mobile' ? 1 : 2
    }

    return this.klook.platform === 'mobile' ? 2 : 3
  }

  get currentPerView() {
    return this.perView || this.defaultPerView
  }

  getBindObj(item: any) {
    const res = {
      ...this.$attrs
    } as any

    res.isBigCard = !!this.isBigCard

    if (this.getDataNosnippet && item.activity_id) {
      res['data-nosnippet'] = this.getDataNosnippet(item.activity_id)
    }

    return res
  }

  getSpmExt(item: any) {
    const { brand_info = {}, product_tags = {}, extra = {} } = item || {}
    let prop: any = {}
    if (!isEmpty(brand_info) && brand_info?.tags?.length) {
      prop = {
        campaign_tags: brand_info.tags
      }
    }

    if (!isEmpty(product_tags)) {
      prop = { ...prop, ...product_tags }
    }

    prop = {
      ...prop,
      ...extra
    }

    getInhouseExtDataByTags(prop)

    return prop
  }
}
