
import { Component, Vue, State, Prop, Watch } from 'nuxt-property-decorator'
import debounce from 'lodash/debounce'
import apis from '~/share/data/apis'
import NoneSearchResult from '~/components/experience-booking/experience-activity/package-options/attraction-included/components/none-search-result.vue'
import {
  Resopn,
  Itabs,
  Attractionincludedlist
} from '~/components/experience-booking/experience-activity/package-options/attraction-preview/type'

@Component({
  components: {
    NoneSearchResult
  }
})
export default class Index extends Vue {
  @State((state) => state.klook) klook!: Data.Klook
  // 是否渲染搜索区域
  @Prop({ default: false }) renderTop!: boolean
  @Prop() packageId!: number
  @Prop() activityId!: number
  @Prop({ default: false }) isBook!: boolean
  @Prop({ default: '' }) deepLink!: string
  searchString = ''
  loading = false
  isFocus = false
  total = 0
  dataResult: Attractionincludedlist[] = []
  debounceGetActivityList = debounce(this.getActivityList, 300)

  // 后端控制type显示不
  showType = true

  tabs: Itabs[] = []
  active = ''

  groupId: number = 0

  get showNoneSearch() {
    return !this.loading && this.searchString !== '' && this.activityList.length === 0
  }

  get showSearchLoading() {
    return this.loading
  }

  get showTabs() {
    // 如果后端返回如果展示再根据去searchString和tabs去判断
    if (this.showType) {
      return this.searchString === '' || this.tabs.length < 2
    } else {
      return this.showType
    }
  }

  get activityList() {
    const id = this.tabs.find((item) => item.label === this.active)?.value
    return this.dataResult.find((item) => item.group_id === id)?.data_list || []
  }

  onTabChange(key: string) {
    this.active = key
  }

  @Watch('active')
  activeChange(key: string, oldVal: string) {
    // tab 切换再请求，因为初始化active的时候会造成一次请求，所以用watch的方式
    if (oldVal === '') {
      return
    }
    this.groupId = this.tabs.find((item) => item.label === key)?.value || 0
    this.getActivityList(false, this.groupId)
  }

  // activityId更改 请求
  @Watch('activityId', { immediate: true })
  visiableChange() {
    this.getActivityList(true)
  }

  // 关键字搜索的时候，不带active
  @Watch('searchString')
  searchStringChange(val: string) {
    if (val.length > 0) {
      this.initTagValue()
      this.debounceGetActivityList(false)
    }

    if (val.length === 0) {
      this.getActivityList(true)
    }
  }

  initTagValue() {
    if (this.tabs.length > 0) {
      this.active = this.tabs[0].label
    }
  }

  getSpm(item: Itabs) {
    const ext = { FilterType: item.value }
    return `GroupFilter_List?oid=activity_${this.activityId}&ext=${this.$getExt(ext)}&mod=stop`
  }

  async getActivityList(init: boolean = false, groupId?: number) {
    const params = {
      pass_id: this.activityId,
      group_id: groupId,
      include_detail: this.klook.platform === 'mobile',
      keyword: this.searchString,
      language: this.klook.language
    } as any

    if (this.packageId) {
      params.package_id = this.packageId
    }

    this.loading = true
    const res = (await this.$axios.$get(apis.getPassStandardActivityPreviewNew, {
      params
    })) as Resopn

    this.loading = false

    if (res.success) {
      const list = res?.result?.attraction_included_list || []
      const showType = !res?.result?.filter_group_not_show
      this.showType = showType
      this.$emit('setShowType', showType)
      this.dataResult = list
      if (list.length > 0) {
        if (init) {
          this.tabs = list.map((item: any) => ({
            label: item.group_name,
            value: item.group_id
          }))
          this.initTagValue()
          this.total = list[0].data_num || 0
        }
      }

      if (this.searchString.length > 1) {
        this.$nextTick(() => {
          const inputDom = document.getElementById('PassReservationSearchQuery')
          this.$inhouse.track('custom', inputDom, {
            spm: 'AttractionsIncluded.PassReservationSearchQuery',
            ext: {
              Query: this.searchString,
              ActivityAmount: this.activityList.length
            }
          })
        })
      }
    }
  }

  onFocus(event: Event) {
    event.stopPropagation()
    this.isFocus = true
  }

  onBlur(event: Event) {
    event.stopPropagation()
    this.isFocus = false
  }

  handleBook() {
    if (this.activityId === -1) {
      return
    }
    const url = this.deepLink ? this.deepLink : this.$href(`/activity/${this.activityId}/`)
    if (this.klook.platform === 'desktop') {
      window.open(url)
    } else {
      window.location.href = url
    }
  }

  mounted() {
    const inputElement = document.querySelector('.attraction-preview .klk-input input')
    if (inputElement) {
      inputElement.addEventListener('focus', this.onFocus)
      inputElement.addEventListener('blur', this.onBlur)
    }
  }

  beforeDestroy() {
    const inputElement = document.querySelector('.attraction-preview .klk-input input')
    if (inputElement) {
      inputElement.removeEventListener('focus', this.onFocus)
      inputElement.removeEventListener('blur', this.onBlur)
    }
  }
}
