



















import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({})
export default class PassIntroductionBase extends BusinessBase {
  @State klook!: Data.Klook

  static displayName = 'Pass_usp'

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get list(): any[] {
    return this.businessData?.selling_points || []
  }

  handleRnderDesc(desc: string) {
    return desc.replace(/color-text-highlight-orange/g, '#FF5B00')
  }
}
