











import { Component, Vue } from 'nuxt-property-decorator'
import UspBanner from '~/components/traveller/activity/usp-tip/usp-banner.vue'
import { renderMarkdownStr } from '~/assets/scripts/markdown'

@Component({
  components: {
    UspBanner
  }
})
export default class UobUspBanner extends Vue {
  visible = false

  handleClick() {
    this.visible = true
  }

  get content() {
    return renderMarkdownStr(this.$t('26257'))
  }
}
