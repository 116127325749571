import { Vue, Component } from 'nuxt-property-decorator'
import { merge } from 'lodash'
import dataUtils from '~/components/booking-core/utils/data-utils'
import * as dataTypesUtils from '~/components/booking-core/utils/types'
import { getOpenTicketAutoSelectedDate } from '~/share/open-ticket'

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  autoSelectFirstAttr(ref: any) {
    const attrWrapRef = ref || this?.$refs?.attrWrap as any
    if (attrWrapRef?.autoSelectFirstAttr) {
      attrWrapRef.autoSelectFirstAttr()
    }
  }

  // 默认选中sku逻辑，返回为errorTips
  setExpAutoSelectSkuHandler(data: dataTypesUtils.ExpAutoSelectSkuIf): string {
    return dataUtils.setExpAutoSelectSku(data) || ''
  }

  getExpAutoSetFeasibleDate(args: Record<string, any>) {
    return getOpenTicketAutoSelectedDate(args)
  }

  getParticipationDataAndPidByActStock({ date, pid, schedules }: any, options?: any) {
    // 判断选中套餐和当前日期是否有库存
    // 默认优先级：套餐 > 日期，设置isDateHeightLevel = true：套餐 < 日期
    const { isDateHeightLevel, stocksKey } = merge({ isDateHeightLevel: false, stocksKey: 'stocks' }, options || {})
    const obj: any = {
      dateSchedule: null,
      pidSchedule: null,
      pid: 0,
      date: ''
    }
    if (!schedules?.length) {
      return obj
    }
    if (date && pid) {
      const item = schedules.find((item: any) => {
        return item.date === date && item[stocksKey]?.some((o: any) => {
          return o.package_id === pid && o.stock
        })
      })
      if (item) {
        // 当前日期选中的套餐有库存
        obj.dateSchedule = obj.pidSchedule = item
        obj.pid = pid
        obj.date = date
        return obj
      }
    }
    if (date) {
      obj.dateSchedule = schedules.find((item: any) => {
        return item.date === date
      })
      obj.dateSchedule && (obj.date = date) // 确认赋值
    }
    if (pid) {
      obj.pidSchedule = schedules.find((item: any) => {
        return item[stocksKey]?.some((o: any) => {
          return o.package_id === pid && o.stock
        })
      })
      obj.pidSchedule && (obj.pid = pid) // 确认赋值
    }
    if (obj.dateSchedule && obj.pidSchedule) {
      // 日期和套餐，独立计算都有库存，判断优先级，重置参数
      if (isDateHeightLevel) {
        obj.pid = 0
      } else {
        obj.date = ''
      }
    }
    return obj
  }
}
