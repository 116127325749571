


























import { Component } from 'nuxt-property-decorator'
import PassActivityWrap from './components/pass-activity-wrap.vue'
import PassActivityItem from './components/pass-activity-item.vue'
import passActivityBase from '~/components/experience-booking/experience-activity/pass-with-pic/base'
import PassTitle from '~/components/experience-booking/experience-activity/pass-with-pic/components/pass-activity-title.vue'

@Component({
  components: {
    PassActivityWrap,
    PassTitle,
    PassActivityItem
  }
})

export default class Index extends passActivityBase {
  static displayName = 'Pass_without_pic'
}
