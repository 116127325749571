import * as dataActUtils from '~/components/booking-core/utils/data-act'
import * as dataPkgUtils from '~/components/booking-core/utils/data-pkg'
import * as dataUnitUtils from '~/components/booking-core/utils/data-unit'
import * as dataApiUtils from '~/components/booking-core/utils/data-api'
import * as dataPropUtils from '~/components/booking-core/utils/data-prop'

export default {
  ...dataPropUtils,
  ...dataActUtils,
  ...dataPkgUtils,
  ...dataUnitUtils,
  ...dataApiUtils
}
