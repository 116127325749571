






















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { IconChevronDown } from '@klook/klook-icons'

@Component({
  components: {
    IconChevronDown
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: '' }) text!: string
  @Prop() className!: string
  @Prop() showPoptips!: boolean

  isShowPopper = false

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get trigger() {
    return this.showPoptips ? (this.isDesktop ? 'hover' : 'click') : 'none'
  }

  handleShow() {
    this.isShowPopper = true
  }

  handleHide() {
    this.isShowPopper = false
  }
}
