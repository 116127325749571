


































import { Component, Prop, mixins, State, Watch, Provide } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'
import PackageCardTitle from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/title.vue'
import MobilePackageDetailButton from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-button.vue'
import ExperiencePackageDetailModule from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-module.vue'
import ExperienceActivityPackageDetail from '~/components/experience-booking/activity/package-detail/index.vue'
import newPackageDetailShowMixin from '~/components/common/package-detail-v2/show-mixin'

@Component({
  components: {
    PackageCardTitle,
    MobilePackageDetailButton,
    ExperiencePackageDetailModule,
    ExperienceActivityPackageDetail
  }
})
export default class CardHeader extends mixins(newPackageDetailShowMixin) {
  @State klook!: Data.Klook
  @Prop({ default: null }) packageData!: ExperienceActivity.IPackageCard
  @Prop({ type: Boolean, default: false }) isPassActivity!: boolean
  @Prop({ default: '' }) packageSpec!: string[]
  @Prop({ default: true }) isTrackItem!: boolean
  @Prop({ default: '' }) groupPackName!: string
  @Prop({ default: '' }) spmTrack!: string

  detailShow = false
  title: string | string[] = ''

  detailElem: any = null

  @Provide()
  setDetailElem(elem: any = null) {
    this.detailElem = elem
  }

  showPackageDetail() {
    this.detailShow = true
  }

  getDetailsBtnTrackInfo() {
    const { spmTrack } = this
    return this.isTrackItem
      ? {
        'data-spm-module': spmTrack || 'PackageDetailsBtn?trg=manual',
        'data-spm-virtual-item': '__virtual'
      }
      : {
        // 不要问这里为什么是PackageType_LIST，我曾经努力说服过pm和sukie，但是失败了，哎
        'data-spm-module': `PackageType_LIST?oid=package_${this.packageData.package_id}&idx=0&len=1`,
        'data-spm-virtual-item': '__virtual'
      }
  }

  @Watch('packageSpec', { deep: true, immediate: true })
  packageSpecChange(val: any, oldVal: any) {
    if (val !== oldVal) {
      this.setTitle(val)
    }
  }

  setTitle(val: string[]) {
    if (this.isPassActivity) {
      this.title = val?.length ? val : this.packageData.package_name
    } else {
      this.title = this.packageData.package_name
    }
  }
}
