






import { Vue, Component, State, Prop } from 'nuxt-property-decorator'

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop() list!: any[]

  get calcCity() {
    const arr = this.list || []
    if (!arr.length) {
      return ''
    }
    const city = arr.map((city: string) => {
      return `<span class="exp-departure__city">${city}</span>`
    }).join(', ')
    const cmsid = '101040'
    const str = this.$t(cmsid, { city })
    return str
  }
}
