

























import { Vue, Component, Prop } from 'nuxt-property-decorator'
import AttractionPreview from '~/components/experience-booking/experience-activity/package-options/attraction-preview/desktop/index.vue'
import { getModalConfig } from '~/components/experience/load-hander/index.vue'

@Component({
  components: {
    AttractionPreview
  }
})
export default class DetailModal extends Vue {
  @Prop({ default: false }) visible!: boolean;
  @Prop() activityId!: number;
  @Prop() packageId!: number;
  @Prop() total!: number;
  @Prop() standardActivityId!: number;
  @Prop({ default: false }) isBook!: boolean;
  @Prop() title!: string;
  @Prop() info!: any;
  // 跳转到pass，减少301跳转
  @Prop({ default: '' }) deepLink !: string;

  modalConfig = getModalConfig('common-large-config')

  get selfTitle() {
    return this.title || this.$t('76895', { num: this.total || 0 })
  }

  closeModal() {
    this.$emit('closeModal')
  }
}
