


















































































































import { Component, Prop, Watch, Vue, State } from 'nuxt-property-decorator'
import merge from 'lodash/merge'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component
export default class ExperienceActivityBanner extends Vue {
  @Prop() activityBanner!: ExperienceActivity.IBannerItem[]
  @Prop() isVideoPlaying!: boolean
  @Prop() swiperOptions!: any
  @Prop({ default: false }) isTaVersion!: boolean

  @State klook!: Data.Klook

  $bannerSwiper: any
  bannerActive: number = 0
  flag: boolean = false
  videoPlaying: boolean = false
  swiperWidth: number = 960
  slidesOffsetBefore: number = 100
  currentIndex: number = 0
  show: boolean = false
  paginationIndex = -1
  isTouchMoving = false
  slideTimeout: any = null

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get logoWidth() {
    if (process.client) {
      const width = this.isDesktop ? 1160 : document.documentElement.clientWidth
      let logoWidth = 0

      switch (this.ratio) {
        case '16:9':
          logoWidth = width * 3 / 16
          break
        case '4:3':
          logoWidth = width / 4
          break
        default:
          logoWidth = width / 3
          break
      }

      return logoWidth + 'px'
    }

    return 0
  }

  get ratio() {
    if (this.isDesktop) {
      return '16:9'
    }
    return this.isTaVersion ? '1:1' : '4:3'
  }

  get valid() {
    return this.activityBanner?.length
  }

  @Watch('isOnlyOneBanner', { immediate: true })
  isOnlyOneBannerChange(val: any) {
    if (!val || this.isTaVersion) {
      if (this.klook.platform === 'mobile') {
        this.showSwiper()
        return null
      }
      this.$nextTick(() => {
        this.showSwiper()
      })
    }
  }

  get isOnlyOneBanner() {
    return this.activityBanner?.length === 1
  }

  // swiper 自己的 autoplay 在 speed 设置了值的时候，在手动触发之后会停止
  // 所以这里自己实现 autoplay
  // 目前只在 t&a 用到
  get shouldManualAutoplay() {
    return this.isTaVersion
  }

  get bannerSwiperOption() {
    const option = merge({
      slidesPerView: 'auto',
      loop: this.isTaVersion,
      lazy: {
        loadPrevNext: true,
        loadOnTransitionStart: true
      },
      pagination: this.isTaVersion ? false : {
        el: '.activity-swiper-pagination'
      },
      ...(this.shouldManualAutoplay ? {
        speed: 0,
        autoplay: false
      } : {}),
      on: {
        slideChangeTransitionStart: this.slideChange,
        slideChangeTransitionEnd: this.manualAutoplay, // 手动 autoplay
        touchMove: this.handleTouchMove,
        touchEnd: this.handleTouchEnd
      }
    }, this.swiperOptions)

    // merge touchmove
    const originalTouchMove = option.on.touchMove
    if (originalTouchMove !== this.handleTouchMove) {
      option.on.touchMove = () => {
        originalTouchMove()
        this.handleTouchMove()
      }
    }

    const originalSlideEnd = option.on.slideChangeTransitionEnd
    if (originalSlideEnd !== this.manualAutoplay) {
      option.on.slideChangeTransitionEnd = () => {
        originalSlideEnd()
        this.manualAutoplay()
      }
    }

    return option
  }

  manualAutoplay() {
    this.manualAutoplayStop()
    this.manualAutoplayStart()
  }

  // 不要使用此方法，请使用 manualAutoplay
  manualAutoplayStart() {
    if (this.shouldManualAutoplay && process.client) {
      this.slideTimeout = setTimeout(() => {
        this.$bannerSwiper?.slideNext && this.$bannerSwiper.slideNext()
      }, 3000)
    }
  }

  manualAutoplayStop() {
    this.slideTimeout && clearTimeout(this.slideTimeout)
  }

  handleTouchMove() {
    this.isTouchMoving = true
    this.manualAutoplayStop()
  }

  showSwiper() {
    this.show = true
    this.watchMouseOnSwiper()
  }

  get videoIcon() {
    return this.isDesktop ? 'desktop-common#btn-video-normal-new' : 'mobile-activity#banner-video-play'
  }

  @Watch('isVideoPlaying')
  isVideoPlayingChange(val: boolean) {
    this.videoPlaying = val
  }

  @Watch('videoPlaying')
  videoPlayingChange(val: boolean) {
    this.$emit('playing-change', val)
  }

  get videoUrl() {
    if (this.activityBanner && this.activityBanner.length > 0) {
      for (let i = 0; i < this.activityBanner.length; i++) {
        const item = this.activityBanner[i]
        if (item.video_url) {
          return this.formatVideoUrl(item.video_url)
        }
      }
    }
    return ''
  }

  get firstImage() {
    return this.formatImg(this.activityBanner[0])
  }

  get bannerImageUrls() {
    return this.activityBanner?.map((val: any) => {
      return this.formatImg(val)
    })
  }

  get cutSize() {
    const bannerLength = this.activityBanner?.length

    if (this.isDesktop) {
      return bannerLength === 1 ? 'c_fill,w_1160,h_460/q_80/' : 'c_fill,w_960,h_460/q_80/'
    }
    // 1:1  4:3
    return this.isTaVersion ? 'c_fill,w_750,h_750/q_80/' : 'c_fill,w_750,h_563/q_80/'
  }

  formatVideoUrl(url: string) {
    if (this.isTaVersion) {
      let newUrl = url
      while (newUrl.replace(',h_540,', ',h_607,') !== newUrl) {
        newUrl = newUrl.replace(',h_540,', ',h_607,')
      }
      return newUrl
    }

    return url
  }

  formatImg(val: ExperienceActivity.IBannerItem) {
    const watermark = this.hasWaterMark(val) ? 'w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/' : ''
    const suffix = this.formatPicExtension(val.suffix)
    return val.prefix + val.pre_process + this.cutSize + watermark + suffix
  }

  hasWaterMark(item: ExperienceActivity.IBannerItem) {
    // white label 中图片不展示水印
    return item.has_water_mark && !this.klook.utilConfig.whiteLabelUtilConfig
  }

  slideChange() {
    this.currentIndex = (this.$refs.$bannerSwiper as any)?.swiper?.realIndex
    this.setPaginationIndex(this.currentIndex)

    const playVideoButton = document.querySelector(
      '.swiper-slide.swiper-slide-active .js-activity-banner-play-button'
    ) as HTMLElement
    if (playVideoButton) {
      playVideoButton.removeEventListener('click', this.showVideoPlayer)
      playVideoButton.addEventListener('click', this.showVideoPlayer)
    }
  }

  handleTouchEnd() {
    this.isTouchMoving = false
    this.manualAutoplay()
  }

  setPaginationIndex(index: number) {
    if (this.isOnlyOneBanner && process.client) {
      this.paginationIndex = -1
      setTimeout(() => (this.paginationIndex = index), 0)
    } else {
      this.paginationIndex = index
    }
  }

  formatPicExtension(url: string) {
    if (!url) {
      return ''
    }

    const { webp } = this.klook
    if (webp) {
      return url.replace(/.(jpg|png|jpeg)$/, '.webp')
    }

    return url
  }

  showVideoPlayer() {
    this.videoPlaying = true

    this.playVideo()
  }

  hideVideoPlayer() {
    this.videoPlaying = false
  }

  handlePlay() {
    this.videoPlaying = true
  }

  get calcVideoUrl() {
    return this.activityBanner?.find(v => v.video_url)?.video_url
  }

  // 播放 video
  playVideo() {
    const url = this.activityBanner[this.currentIndex]?.video_url || this.activityBanner.find(v => v.video_url)?.video_url

    if (!url) { return }

    const video = this.$refs.activityBannerVideo as HTMLVideoElement

    if (!video.getAttribute('src')) {
      video.src = this.formatVideoUrl(url)
    }
    video.play().catch((error) => {
      console.warn('视频播放失败，请检查url是否正确：', error)
    })
    if (!this.isOnlyOneBanner && this.$bannerSwiper?.autoplay) {
      this.$bannerSwiper.autoplay.stop()
      this.manualAutoplayStop()
    }

    video.addEventListener('ended', () => {
      this.hideVideoPlayer()
      // 延迟 1 秒自动播放下一张
      setTimeout(() => {
        if (this.$bannerSwiper) {
          // swiper 切换到下一张
          this.$bannerSwiper.slideNext()
          // 打开 swiper 自动轮播
          this.$bannerSwiper.autoplay.start()
        }
        this.manualAutoplay()
      }, 1000)
    })
  }

  // 隐藏 video
  stopVideo() {
    (this.$refs.activityBannerVideo as HTMLVideoElement).pause()
    if (!this.isOnlyOneBanner && this.$bannerSwiper) {
      this.$bannerSwiper.autoplay.start()
      this.manualAutoplay()
    }
  }

  // 监听鼠标是否在 swiper 区域，控制 swiper 的滚动和暂停
  watchMouseOnSwiper() {
    if (!this.isOnlyOneBanner) {
      const swiper = (this.$refs.swiperContainer as HTMLElement)

      if (!swiper) { return }

      swiper.addEventListener('mouseover', () => {
        this.$bannerSwiper && this.$bannerSwiper.autoplay.stop()
      })

      swiper.addEventListener('mouseout', () => {
        this.$bannerSwiper && this.$bannerSwiper.autoplay.start()
      })
    }
  }

  handlePause() {
    this.$emit('video-pause')
  }

  handlePaginationClick(index: number) {
    const swiper: any = (this.$refs.$bannerSwiper as any)?.swiper
    swiper?.slideTo && swiper.slideTo(index)
  }
}
