



























import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { CardSwiper, CardSwiperItem } from '~/components/traveller/home-page/card-swiper'
import ActivityBookingStartDate from '~/components/traveller/activity/start-date/activity-booking-start-date.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    CardSwiper,
    CardSwiperItem,
    ActivityBookingStartDate
  }
})
export default class DynamicHorizontalIcons extends Vue {
  @State klook!: Data.Klook
  @Prop() icons!: ExperienceActivity.IDynamicIconData[]
  get isMobile() {
    return this.klook.platform === 'mobile'
  }
}
