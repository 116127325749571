











import { Component, Vue, Prop } from 'nuxt-property-decorator'
import AttrDetail from './attr-detail.vue'
import ActivityPackageBtn
  from '~/components/traveller/activity/activity-package-options/mobile/activity-package-btn.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    ActivityPackageBtn,
    AttrDetail
  }
})
export default class AttrDetailbtn extends Vue {
  @Prop() spec !: ExperienceActivity.ISpec

  visible = false

  get selectedAttr() {
    return this.spec?.attr?.find(v => v.isActive)
  }

  get selectedAttrName() {
    return this.selectedAttr ? this.selectedAttr.name : this.$t('global.select.palceholder')
  }

  handleSelect(attr: any) {
    this.$emit('select', attr, this.spec)
    this.close()
  }

  handleAttrsVisible() {
    this.visible = true
  }

  close() {
    this.visible = false
  }
}
