function beforeAddToCart(that: any) {
  return new Promise((resolve) => {
    if (that) {
      that.$inhouse.track('custom', 'body', {
        spm: 'Activity.Notification_Yifun_AddCart',
        action_type: 'click'
      })
      that.$confirm(that.$t('30895'), '', {
        okLabel: that.$t('30897') as string,
        cancelLabel: that.$t('activity.label.add_to_cart') as string
      }).then((res: {result: boolean, value: any}) => {
        const spm = `Activity.Notification_Yifun_AddCart.${res.result ? 'OK_Btn' : 'AddToCart_Btn'}`
        that.$inhouse.track('custom', 'body', {
          spm,
          action_type: 'click'
        })
        resolve(res.result)
      })
    }
  })
}

export default beforeAddToCart
