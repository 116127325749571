











import { Component, Prop, Watch } from 'nuxt-property-decorator'
import PackageCardList from './package-card-list.vue'
import PackageDetailFailure
  from '~/components/experience-booking/activity/package-detail/status/package-detail-failure.vue'
import CardAttrBase from '~/components/experience-booking/experience-activity/package-options/base/card-attr-base'
import { ExperienceActivity } from '~/types/experience/activity-section'
import PackageCardPlaceholderList
  from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-card-placeholder-list.vue'

interface IPrice {
  credits: number
  package_id: number
  promotion_price: {
    original_selling_price: string,
    selling_price: string,
    discount: number
  }
}

interface IPackageItem extends ExperienceActivity.IPackage {
  original_selling_price?: string,
  promotion_price?: {
    original_selling_price: string,
    selling_price: string,
    discount: number
  },
  status: string
}

@Component({
  components: {
    PackageCardPlaceholderList,
    PackageDetailFailure,
    PackageCardList
  }
})
export default class PackageCardListSection extends CardAttrBase {
  @Prop() date!: string
  @Prop() packages!: ExperienceActivity.Packages
  @Prop() packageId!: number

  state: string = 'fetching'
  unavailablePackageList: IPackageItem[] = []
  availablePackageList: IPackageItem[] = []
  selectedPackageList: IPackageItem[] = []

  get componentName() {
    switch (this.state) {
      case 'success':
        return 'PackageCardList'
      case 'failure':
        return 'PackageDetailFailure'
      default:
        return 'PackageCardPlaceholderList'
    }
  }

  @Watch('date')
  dateChange() {
    this.getPackagePrice()
  }

  get packageList() {
    return this.availablePackageList.concat(this.selectedPackageList).concat(this.unavailablePackageList)
  }

  get itemParams() {
    return this.packagesInSchedule?.map((item) => {
      return {
        actual_price: item.sell_price,
        package_id: item.package_id
      }
    })
  }

  getPackagePrice() {
    this.state = 'fetching'

    const prices: any[] = this.itemParams
    const pricesMap = prices?.reduce((acc, v) => ({ ...acc, [v.package_id]: v }), {} as { [k: number]: IPrice }) || {}
    this.availablePackageList = []
    this.unavailablePackageList = []
    this.selectedPackageList = []

    for (const v of this.packages) {
      const { package_id } = v
      const price = pricesMap[package_id]
      const formatPrice = v
      const isSelected = this.packageId && price?.package_id === this.packageId
      if (isSelected) {
        this.selectedPackageList = this.selectedPackageList.concat({ ...formatPrice, status: 'selected' })
      } else if (price) {
        this.availablePackageList = this.availablePackageList.concat([{ ...formatPrice, status: 'available' }])
      } else {
        this.unavailablePackageList = this.unavailablePackageList.concat({ ...formatPrice, status: 'unavailableDate' })
      }
    }
    this.$nextTick(() => {
      this.state = 'success'
    })
  }

  mounted() {
    this.getPackagePrice()
  }
}
