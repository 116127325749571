











import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import { merge } from 'lodash'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import HeaderTips from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/header-tips.vue'

@Component({
  components: {
    HeaderTips
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop() customPlatform!: string

  get calcPlatform() {
    return this.customPlatform || this.klook.platform
  }

  get calcTrack() {
    const def = {
      action: false,
      type: 'module',
      spm: 'OpenDateReminder'
    }
    return merge(def, this.$attrs.track || {})
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }
}
