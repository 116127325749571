












import { Component, Getter, Prop, State, Vue } from 'nuxt-property-decorator'
import { formatThousands } from '~/share/utils'

@Component
export default class Price extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: '' }) credits!: string | number
  @Prop({ default: '' }) sellPrice!: string
  @Prop({ default: '' }) marketPrice!: string
  @Prop({ default: '' }) fromPrice!: string
  @Prop({ default: '' }) toPrice!: string
  @Prop({ default: 0 }) originalSellingPrice!: string
  @Prop({ default: 0 }) promotionStatus!: number
  @Prop({ default: '' }) desc!: string
  @Prop({ default: '' }) discount!: string
  @Getter useCredits!: boolean
  @Getter isGuestCheckout!: boolean

  get creditsVisible() {
    return this.useCredits && !this.isGuestCheckout
  }

  get creditsNumber() {
    if (this.credits && this.creditsVisible) {
      return Math.floor(parseFloat(this.credits.toString()))
    }
    return null
  }

  get creditsText() {
    if (this.creditsNumber) {
      return `${this.$t('header.Credits')} + ${this.creditsNumber}`
    }
    return null
  }

  currencySymbol(price: number | string) {
    return `${this.klook.currencySymbol}${price}`
  }

  initPrice() {
    const WARM_UP = 4
    const ON_SALE = 5
    const sell = Number(this.sellPrice)
    const market = Number(this.marketPrice)
    if (this.promotionStatus === WARM_UP) {
      return {
        sell: Number(this.originalSellingPrice),
        market
      }
    } else if (this.promotionStatus === ON_SALE) {
      return {
        sell,
        market: Number(this.originalSellingPrice)
      }
    } else {
      return {
        sell,
        market
      }
    }
  }

  formatSelling() {
    const { sell } = this.initPrice()
    const from = Number(this.fromPrice)
    const to = Number(this.toPrice)
    if (isNaN(sell)) {
      return '-'
    }

    if (!sell) {
      return `<b>${this.desc}</b>`
    }

    if (!this.promotionStatus && sell > 0) {
      if (to > from) {
        return this.$t('activity_detail_from_price', [12, this.currencySymbol(sell)])
      }
    }
    const _sell = `<span class='sell-price-box'>${formatThousands(sell)}</span>`
    return `<b>${this.currencySymbol(_sell)}</b>`
  }

  get selling() {
    return this.formatSelling()
  }

  get market() {
    const { market, sell } = this.initPrice()

    return sell < market ? this.currencySymbol(formatThousands(market)) : ''
  }
}
