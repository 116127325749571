





























import { Component, Vue, Prop, Getter, State, namespace } from 'nuxt-property-decorator'
import { throttle, isEmpty } from 'lodash'
import Price from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/bottom-bar/price.vue'
import BookingBottomBtns from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/bottom-bar/booking-bottom-btns.vue'
import BookingBarWrap from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-bar-wrap.vue'
import ExpBookingSettlement from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-settlement'
import { Activity } from '~/types/traveller/activity'
import { ExperienceActivity } from '~/types/experience/activity-section'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    TotalSlot,
    BookingBarWrap,
    ExpBookingSettlement,
    BookingBottomBtns,
    Price
  }
})
export default class BookingBottomBar extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: String, default: 'bookNow' }) fromType!: 'bookNow'
  @Prop() hideDiscountTips!: boolean
  @Prop() units!: Activity.unit[]
  @Prop() businessData!: any
  @Prop() currentSelectedPackage!: ExperienceActivity.IPackageCard
  @Prop() selectedTime!: any
  @Prop() packageSchedules!: any
  @Prop() priceDetail!: any
  @Prop() openPaymentDetail!: () => void
  @Prop() packageSelectedDate!: string
  @Prop() totalUnitCount!: number
  @Prop() validator!: () => boolean
  @Prop() generateParams!: () => any
  @Getter isGuestCheckout!: boolean
  @ActivityModule.State preSettlementApiData!: any
  @ActivityModule.Getter preSettlementTips2getters!: any

  completeInfo = false
  // ModuleExtra: string = JSON.stringify({ CompletedInfo: false })

  get arrangementId() {
    return this.selectedTime?.arrangement_id
  }

  get calcShowPrice() {
    if (!this.arrangementId || isEmpty(this.units)) {
      return false
    }
    const count = (this.units || []).reduce((num: number, obj: any) => {
      num = num + (obj.count || 0)
      return num
    }, 0)
    return count > 0
  }

  get itemExtra() {
    const selectedSkuList = (this.units || []).filter((item: Activity.unit) => item.count > 0)
    return JSON.stringify({
      CompletedInfo: this.completeInfo,
      Sku_id: selectedSkuList.map((item: Activity.unit) => item.skuId),
      Sku_num: selectedSkuList.map((item: Activity.unit) => item.count)
    })
  }

  get activityId() {
    return this.businessData.activity_id
  }

  get templateId() {
    return this.businessData.template_id
  }

  get isYiFunVoucher() {
    return !!this.businessData?.card_tags?.yifun_voucher
  }

  get isNswActivity() {
    return this.businessData.is_nsw_activity
  }

  get isOpenTicket() {
    return this.businessData.activity_ticket_type === 3
  }

  get packages() {
    return this.businessData.packages
  }

  get isSoldOut() {
    return false
  }

  get isInPromotion() {
    const promotionEvent = this.currentSelectedPackage?.promotion_price?.promotion_event || {}
    return promotionEvent.status === 5
  }

  get bookingBottomBtnsBinds() {
    return {
      selectedTime: this.selectedTime,
      currentSelectedPackage: this.currentSelectedPackage,
      templateId: this.templateId,
      activityId: this.activityId,
      isSoldOut: this.isSoldOut,
      isOpenTicket: this.isOpenTicket,
      validator: this.validator
    }
  }

  throttleGetFixedWrapInfos = throttle(this.getFixedWrapInfos, 300)
  getFixedWrapInfos() {
    const ref: any = this.$refs?.fixedWrapRef
    if (!ref) {
      return
    }
    const infos = {
      height: ref?.$el?.offsetHeight
    }
    this.$emit('updateInfos', infos)
    return infos
  }

  updated() {
    this.throttleGetFixedWrapInfos()
  }
}
