import { render, staticRenderFns } from "./paragraph.vue?vue&type=template&id=70713adc&scoped=true&"
import script from "./paragraph.vue?vue&type=script&lang=ts&"
export * from "./paragraph.vue?vue&type=script&lang=ts&"
import style0 from "./paragraph.vue?vue&type=style&index=0&id=70713adc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70713adc",
  null
  
)

export default component.exports