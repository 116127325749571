import { get } from 'lodash'

// 套餐层级数据
export function getPkgSelectedData(packageList: any[], packageId: number) {
  // 获取当前选中套餐的数据
  if (!packageId || !packageList.length) {
    return {}
  }
  return packageList?.find((item: any) => item.package_id === packageId) || {}
}

// 套餐层级数据
export function getPkgIsNewOpenTicket(packageTicketType: number) {
  // 获取当前套餐是否为open ticket
  return packageTicketType === 3
}

export function getPkgIsWarmingOrSuspended(pkgObj: any, key: string = 'publish_info.publish_status') {
  // 获取当前套餐是否为预热状态或暂停销售
  return [2, 3].includes(get(pkgObj, key))
}

export function getActInDatePackages(actScheduleObj: any, validPackages: any[]) {
  // 获取当前选中日期有库存的套餐列表
  if (!actScheduleObj?.stocks) {
    return validPackages
  }
  const list = validPackages.filter((v) => {
    const stocks = actScheduleObj.stocks
    if (!stocks) {
      return false
    }
    const packageStock = stocks.find((item: any) => {
      return item.package_id === v.package_id
    })
    return packageStock ? packageStock.stock > 0 : false
  })
  return list
}

export function getExpExtraUnitList(selectedTime: any, pkgUnitsResult: any) {
  const { arrangementId = 'default', maxPurchased } = selectedTime || {}
  const { prices = [], inventories = {} } = pkgUnitsResult
  // 必买项总最小购买数量
  const requiredPax = prices.filter((v: any) => v.required && (v.price_min_pax || v.min_pax)).reduce((acc: number, v: any) => acc + (v.price_min_pax || v.min_pax), 0)

  const unitList = prices.map((v: any) => {
    /**
     * 判断unit是否售罄
     * unit的库存有两种，虚拟库存stock和共享库存（特殊库存）inventories，都是套餐级别的设置
     * inventories的优先级大于stock，如果设置了inventories，stock的值会返回inventories设置的值
     * 如果inventories没有设置则返回stock的值
     * inventories可以设置套餐中单个unit的库存或者多个unit的库存的总和
     * stock是套餐unit的总库存
     * 必买项的最小购买数量理论上不应该小于库存，因为如果是这样，那么这个日期应该是售罄状态而不可选
     * 如果最小购买数量 大于 虚拟库存去除所有必买项的总的最小购买数量，则该unit售罄
     * 如果unit的最小购买数量 大于 特殊库存 则该unit售罄（实际情况是应该判断同一inventory_id的unit是否有库存，交由后端判断）
     */
    const priceMinPax = v.price_min_pax || v.min_pax || 0
    // 非必买项最大库存
    const notRequiredMaxPax = maxPurchased - requiredPax
    let isUnitSoldout: boolean
    if (notRequiredMaxPax < 0) {
      // 必买项超过库存则所有unit售罄，当前日期售罄
      isUnitSoldout = true
    } else {
      // 当前unit最小购买数量 大于 虚拟库存去所有除必买项的总的最小购买数量
      isUnitSoldout = !(v.required && priceMinPax) && Math.max(priceMinPax, 1) > notRequiredMaxPax
    }
    // 特殊库存
    const isInventorySoldOut = Math.max(priceMinPax, 1) > inventories[v.inventory_id]
    const isSoldOut = isUnitSoldout || isInventorySoldOut
    const count = !isSoldOut && v.required ? priceMinPax : 0
    return {
      redeem_status: v.redeem_status,
      product_tags: v.product_tags,
      priceMinPax,
      priceMaxPax: v.price_max_pax || v.max_pax || 0,
      count,
      credits: v.credits,
      priceId: v.price_id || v.id,
      sellingPrice: v.selling_price || v.price || 0,
      marketPrice: v.market_price || 0,
      originalPrice: v.original_price || 0,
      promotionDiscount: v.discount || 0,
      priceName: v.price_name || v.name,
      isSoldOut,
      isFree: parseFloat(v.selling_price || v.price) === 0,
      specPriceDesc: v.spec_price && v.spec_price.desc,
      requireBuy: v.required && priceMinPax,
      requireMinPax: !v.required && count >= priceMinPax && priceMinPax > 1,
      required: v.required,
      skuId: v.sku_id,
      srvSkuInfo: v.srv_sku_info,
      inventoryId: v.inventory_id,
      eventId: v.promotion_event ? v.promotion_event.event_id : 0,
      isInPromotion: v.promotion_event ? !!v.promotion_event.event_id : false,
      isDefault: arrangementId === 'default' // default 的时候 pc 端不显示价格(防止价格闪一下)
    }
  })
  return unitList
}
