


















































































































































import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { Component, Prop, Watch, State, Getter } from 'nuxt-property-decorator'
import { cloneDeep } from 'lodash'
import { IconChevronDown } from '@klook/klook-icons'
import HeaderTips from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/header-tips.vue'
import CalendarHeader from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/booking-calendar/calendar-header.vue'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'
import MiniCalendar from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/booking-calendar/mini-calendar.vue'
import RangeDateTime from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/range-date-time/index.vue'
import CalendarPopup from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/calendar-popup.vue'
import TimePopup from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/time-popup.vue'
import MiniTime from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/booking-calendar/mini-time.vue'
// import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import eventBus from '~/pages/experience/pay/common/event-bus'
// import StatementModal from '~/components/experience-booking/experience-activity/package-options/activity-calendar/mobile/statement-modal.vue'
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'
import UnitWarn from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/booking-calendar/unitWarn.vue'
import DesktopCalendarPopup from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/booking-calendar/desktopDatePick.vue'
import DesktopTimePopup from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/booking-calendar/desktopTimePopup.vue'
import { getFormatDate, getLocalDate } from '~/share/utils-date'
import Base from '~/components/experience-booking/experience-activity/combo-option/booking-options/combo-time-base'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export interface Schedule {
  selling_price: number
  soldout: boolean
  date: string
  stocks: {
    from_price: string
    market_price: string
    package_id: number
    selling_price: string
    stock: number
    to_price: string
  }[]
  disable?: boolean
  time_slots?: any[]
}

@Component({
  components: {
    RangeDateTime,
    HeaderTips,
    CalendarHeader,
    MiniCalendar,
    CalendarPopup,
    MiniTime,
    TimePopup,
    // CalendarLengends,
    CalenderTips,
    // StatementModal,
    IconChevronDown,
    UnitWarn,
    DesktopCalendarPopup,
    DesktopTimePopup
  }
})
export default class BookingCalendar extends Base {
  @Getter timeFormatString!: string
  @State klook!: Data.Klook
  @Prop({ default: true }) verticalScroll!: boolean
  @Prop() templateId!: number
  @Prop() packageId!: number
  @Prop() index!: number
  @Prop() currentSelectedPackage!: any
  @Prop() isNewOpenTicket!: boolean
  @Prop() schedules!: Schedule[]
  @Prop({ default: {} }) selectedTime!: any
  @Prop() value!: string // date
  @Prop() warn!: string
  // 在mini-xxx里面是固定不显示price的，这里就只控制在popup里面显不显示price
  @Prop({ default: false }) priceVisible!: boolean
  @Prop({ default: true }) isTrackItem!: boolean
  @Prop({ default: () => [] }) legends!: any[]
  @Prop() autoTimeSlot!: boolean
  // 只需要展示覆层
  @Prop({ default: false }) onlyDate!: boolean
  @Prop({ default: false }) selecedTimeHasClear!: boolean

  calendarPopupVisible = false
  timePopupVisible = false
  pickupTimePopupVisible = false
  showStatementVisible = false
  legendsInfo: { title: string; desc: string } = {
    title: '',
    desc: ''
  }

  bsObjSelectTime = getBottomSheetConfig('pickup-return-config')

  dateObj: any = {
    cacheData: {},
    days: 0,
    minStock: 0,
    stocks: [],
    list: []
  }

  timeObj: any = {
    cacheData: {},
    key: '',
    in: {},
    out: {}
  }

  initTimeObj(obj: any) {
    const { calcTimeExtra } = this
    if (calcTimeExtra?.in?.length === 1) {
      const list = calcTimeExtra.in[0]
      obj.in = { ...list, startEndFormatStr: this.getStartEndFormatStr(list) }
    }
    if (calcTimeExtra?.out?.length === 1) {
      const list = calcTimeExtra.out[0]
      obj.out = { ...list, startEndFormatStr: this.getStartEndFormatStr(list) }
    }
    return obj
  }

  shouldDateDisabledFn(d: Date) {
    const dateStr = getFormatDate(d)
    return this.schedulesMap[dateStr].disable || !this.schedulesMap[dateStr]
  }

  showDatePopup() {
    // const { dateObj, timeObj } = this
    // this.$set(dateObj, 'cacheData', cloneDeep(dateObj))
    // this.$set(timeObj, 'cacheData', this.initTimeObj(cloneDeep(timeObj)))
    this.calendarPopupVisible = true
  }

  get dateLimit() {
    return this.klook.platform === 'desktop' ? 6 : 7
  }

  get timeLimit() {
    return this.klook.platform === 'desktop' ? 6 : 9
  }

  get pickupTimeList() {
    const timeIns = this.calcTimeExtra?.in || []
    return timeIns.map((v: any) => ({
      ...v,
      disabled: false,
      date: v.id
    }))
  }

  get validPickupTimeList() {
    return this.pickupTimeList.slice(0, this.timeLimit)
  }

  hideRangeTimeValidate() {
    this.bsObjSelectTime.visible = false
    // this.checkRangeTimeValidate()
  }

  get calcCacheDateObj() {
    return this.dateObj.cacheData || {}
  }

  get calcCacheTimeObj() {
    return this.timeObj.cacheData || {}
  }

  selectRangeTime(key: string, item: any) {
    const { calcCacheTimeObj: timeObj, calcTimeExtra } = this
    this.$set(timeObj, key, cloneDeep(item))
    if (key === 'in' && !timeObj?.out?.id && calcTimeExtra?.out?.length) {
      this.$set(timeObj, 'key', 'out')
    } else if (key === 'out' && !timeObj?.in?.id && calcTimeExtra?.in?.length) {
      this.$set(timeObj, 'key', 'in')
    } else {
      this.hideRangeTimeValidate()
    }
  }

  get calcTimeExtra() {
    const { currentSelectedPackage } = this
    const obj = currentSelectedPackage?.time_extra || null
    return obj
  }

  get calcDateTitle() {
    const { calcTimeExtra } = this
    return this.$t(calcTimeExtra?.in ? '88950' : '13187')
  }

  handleShowStatement(payload: any) {
    this.legendsInfo = payload
    this.showStatementVisible = true
  }

  checkAutoTimeSlot() {
    const { autoTimeSlot, selectedTime } = this
    return autoTimeSlot && selectedTime?.time_slot
  }

  @Watch('timeList', { immediate: true })
  timeListChange() {
    // 自动选择逻辑
    const { timeList, isNewOpenTicket } = this
    if (timeList && timeList.length > 0) {
      this.$nextTick(() => {
        // 如果只有一个，则自动选中,combo中去掉这个逻辑
        // if (timeList.length === 1) {
        //   this.handleSelectTime(timeList[0])
        //   return
        // }

        // 如果是 openTicket，则自动选第一个合法的
        if (isNewOpenTicket) {
          const validTime = timeList.find((item: any) => !item.disabled)
          this.handleSelectTime(validTime)
          return
        }

        if (this.checkAutoTimeSlot()) {
          // 新增选中缓存
          const obj = timeList.find((o: any) => o.date === this.selectedTime?.date)
          this.handleSelectTime(obj)
          return
        }

        // 如果以上都不是，则清除
        this.handleSelectTime(null)
      })
    }
  }

  get calcHidePickupTimeIcon() {
    const { pickupTimeList, timeLimit } = this
    return pickupTimeList.length <= timeLimit
  }

  get showTimeStr() {
    return this.selectedTime.date
      ? this.setTimeFormat(this.selectedTime.date)
      : this.$t('activity.booking.options.select.time')
  }

  get calcHideTimeIcon() {
    const { timeList, timeLimit } = this
    return timeList.length <= timeLimit
  }

  get calcTimeActive() {
    return (item: any) => {
      const { calcCacheTimeObj: timeObj } = this
      const cls = item.id && item.id === timeObj?.[timeObj.key]?.id ? 'time-active' : ''
      return cls
    }
  }

  getStartEndFormatStr(item: any, linkStr = ' - ') {
    return `${item.start}${linkStr}${item.end}`
  }

  get calcTimeExtraList() {
    const { calcCacheTimeObj: timeObj, calcTimeExtra } = this
    const arr = calcTimeExtra?.[timeObj.key] || []
    arr.forEach((item: any) => {
      this.$set(item, 'startEndFormatStr', `${item.start} - ${item.end}`)
    })
    return arr
  }

  getAvailabilityTrackInfo() {
    return this.isTrackItem
      ? {
        'data-spm-item': 'CheckAvailability'
      }
      : {
        'data-spm-module': 'CheckAvailability',
        'data-spm-virtual-item': '__virtual'
      }
  }

  clickPickupTimeHandler() {
    if (this.calcHidePickupTimeIcon) {
      return false
    }
    this.pickupTimePopupVisible = true
  }

  clickTimeHandler() {
    if (this.calcHideTimeIcon) {
      return false
    }
    this.timePopupVisible = true
  }

  created() {
    eventBus
      .$off('checkSelectDateAndTime2bus')
      .$on('checkSelectDateAndTime2bus', this.checkSelectDateAndTime2bus)
    this.bsObjSelectTime.title = this.$t('88950')
  }

  rangeDateList: string[] = []

  get maxDate() {
    const len = this.schedules?.length
    return len === 0 ? null : getLocalDate(this.schedules?.[len - 1]?.date)
  }

  isSoldout(d: string) {
    const date = getFormatDate(d)
    const schedule: any = this.schedulesMap[date]
    // 这里如果选不到日期是disabled，不当做售罄处理
    if (schedule) {
      return schedule.soldout
    }
  }

  get schedulesMap(): any {
    return this.schedules?.reduce((acc: any, v: any) => ({ ...acc, [v.date]: v }), {})
  }

  initObjCache: any = null

  checkSelectDateAndTime2bus() {
    const { value, showTime, selectedTime } = this
    if (!value) {
      this.$toast(this.$t('activity.booking.options.select.date'))
    } else if (showTime && !selectedTime?.time_slot) {
      this.$toast(this.$t('activity.booking.options.select.time'))
    }
  }

  get filterValidDates() {
    const schedules = this.schedules.slice(0, this.dateLimit)
    return schedules
  }

  get hasLegends() {
    return this.schedules.some((item: any) => item?.legend_keys?.length > 0)
  }

  get timeList() {
    const schedule = this.schedules.find((item) => item.date === this.value)
    const timeslots = (schedule && schedule.time_slots) || []

    return timeslots.reduce((acc, v: any) => {
      // const disabled = this.isTimeDisabled(v)
      return [
        ...acc,
        {
          ...v,
          // disabled,
          date: `${this.value} ${v.time_slot}`
        }
      ]
    }, [])
  }

  get validTimeList() {
    return this.timeList.slice(0, this.timeLimit)
  }

  get showTime() {
    // 如果为 openTicket 或者 onlyDate 则不显示
    if (this.isNewOpenTicket) {
      return false
    }

    // 如果只有一个并且为 00：00：00 则不显示
    if (this.timeList?.length === 1) {
      const [time] = this.timeList
      const formatTime = dayjs(time.date, 'YYYY-MM-DD hh:mm:ss')
      return !(formatTime.hour() === 0 && formatTime.minute() === 0 && formatTime.second() === 0)
    }

    return this.timeList?.length > 0
  }

  closeDateHandler() {
    // const { dateObj, timeObj } = this
    // this.$set(this, 'dateObj', dateObj.cacheData)
    // this.$set(this, 'timeObj', timeObj.cacheData)
  }

  clearHandler() {
    this.handleDateChange([])
  }

  handleDateEveryChange() {}

  handleDateChange(date: any) {
    this.$emit('input', date, this.index)
  }

  handleSelectTime(data: any) {
    // 重置selectedTime
    if (!data) {
      this.$emit('setTime', null)
      return
    }

    const selectedTime = {
      ...data,
      arrangementId: data.arrangement_id || data.arrangementId,
      stock: data.stock,
      date: data.date,
      /**
       * 最多可购买数量
       * 取决于当前库存和套餐设置的最大可购买数量的最大值
       * 因为在unit的计算中要使用到，所以放到这里先处理出来
       */
      maxPurchased: Math.min(data.stock, Number(this.currentSelectedPackage?.max_pax))
    }
    this.$emit('setTime', selectedTime)
  }

  // handleScrollToAnchor() {
  //   if (process.client) {
  //     setTimeout(() => {
  //       const node = this.$el?.querySelector('.js-scroll-anchor')
  //       if (node) {
  //         node.scrollIntoView({
  //           behavior: 'smooth',
  //           block: 'start'
  //         })
  //       }
  //     }, 160)
  //   }
  // }
}
