export const BookingDetailSkeleton = {
  desktop: {
    page: {
      left: {
        el: 'skeleton',
        animate: true,
        mode: 'dark',
        children: [
          {
            el: 'div',
            vfor: 6,
            children: [
              {
                el: 'div',
                style: { margin: '0 0 32px 0' },
                children: [
                  { el: 'block', width: 184, height: 26 },
                  { el: 'block', width: '100%', height: 151, radiusSize: 'max' }
                ]
              }
            ]
          }
        ]
      },
      right: {
        el: 'skeleton',
        animate: true,
        mode: 'dark',
        vfor: 3,
        children: [
          {
            el: 'div',
            style: { margin: '0 0 32px 0' },
            children: [
              { el: 'block', width: '100%' },
              { el: 'block', width: '50%' },
              { el: 'block', width: '100%' },
              { el: 'block', width: '50%' }
            ]
          }
        ]
      }
    },
    crossSell: {
      el: 'skeleton',
      animate: true,
      mode: 'dark',
      children: [
        { el: 'block', width: 184, height: 26 },
        {
          el: 'div',
          vfor: 4,
          class: 'flex',
          style: { margin: '0 0 40px 0' },
          children: [
            {
              style: { margin: '0 20px 0 0' },
              children: [
                { el: 'block', width: 275, height: 150, radiusSize: 'max' },
                { el: 'block', width: 275, height: 26 },
                { el: 'block', width: 181, height: 26 }
              ]
            }
          ]
        }
      ]
    }
  },
  mobile: {
    page: {
      el: 'skeleton',
      animate: true,
      mode: 'dark',
      children: [
        {
          vfor: 2,
          children: [
            { el: 'block' },
            { el: 'block', width: '50%' }
          ]
        },
        {
          vfor: 2,
          style: { margin: '16px 0 0 0' },
          children: [
            { el: 'block', height: 300, radiusSize: 'max' }
          ]
        }
      ]
    }
  }
}

export const BaseLineSkeleton = {
  el: 'skeleton',
  animate: true,
  mode: 'light',
  children: [
    { el: 'block', width: '100%' },
    { el: 'block', width: '50%' }
  ]
}

export const BaseBlockSkeleton = {
  mobile: {
    el: 'skeleton',
    animate: true,
    mode: 'light',
    children: [
      {
        el: 'div',
        children: [
          { el: 'block', width: '50%', height: 26 },
          { el: 'block', width: '100%', height: 160, radiusSize: 'max', style: { margin: '16px 0 32px 0' } }
        ]
      }
    ]
  },
  desktop: {
    el: 'skeleton',
    animate: true,
    mode: 'light',
    children: [
      {
        el: 'div',
        children: [
          { el: 'block', width: '184px', height: 26 },
          { el: 'block', width: '100%', height: 160, radiusSize: 'max', style: { margin: '16px 0 32px 0' } }
        ]
      }
    ]
  }
}

export const ShoppingcartSkeleton = {
  mobile: {
    page: {
      el: 'skeleton',
      animate: true,
      mode: 'light',
      style: { padding: '20px' },
      vfor: 5,
      children: [
        {
          el: 'div',
          class: 'flex',
          children: [
            { el: 'block', class: 'flex-none', width: 110, height: 110, radiusSize: 'max' },
            {
              el: 'div',
              class: 'flex-block',
              style: { margin: '0 0 0 12px' },
              children: [
                { el: 'block', width: '100%', height: 26 },
                { el: 'block', width: '50%', height: 26 }
              ]
            }
          ]
        }
      ]
    }
  },
  desktop: {
    page: {
      el: 'skeleton',
      animate: true,
      mode: 'dark',
      vfor: 1,
      children: [
        {
          el: 'div',
          class: 'flex',
          children: [
            {
              el: 'div',
              class: 'flex-none',
              style: { width: '858px' },
              vfor: 4,
              children: [
                { el: 'block', width: 184, height: 26, style: { margin: '32px 0 16px 0' } },
                { el: 'block', width: '100%', height: 151, radiusSize: 'max' }
              ]
            },
            {
              el: 'div',
              class: 'flex-block',
              style: { margin: '74px 0 0 32px' },
              vfor: 2,
              children: [
                { el: 'block', width: '100%', height: 26 },
                { el: 'block', width: '67%', height: 26 }
              ]
            }
          ]
        }
      ]
    }
  }
}

export const PaySkeleton = {
  mobile: {
    page: {
      el: 'skeleton',
      animate: true,
      mode: 'light',
      style: { padding: '20px' },
      vfor: 6,
      children: [{ el: 'block' }, { el: 'block', width: '50%' }]
    }
  },
  desktop: {
    page: {
      el: 'skeleton',
      animate: true,
      mode: 'dark',
      vfor: 1,
      children: [
        {
          el: 'div',
          class: 'flex',
          children: [
            {
              el: 'div',
              class: 'flex-none',
              style: { width: '790px' },
              vfor: 4,
              children: [
                { el: 'block', width: 184, height: 26, style: { margin: '32px 0 16px 0' } },
                { el: 'block', width: '100%', height: 151, radiusSize: 'max' }
              ]
            },
            {
              el: 'div',
              class: 'flex-block',
              style: { margin: '74px 0 0 24px' },
              vfor: 3,
              children: [
                {
                  el: 'div',
                  style: { margin: '0 0 32px 0' },
                  vfor: 2,
                  children: [
                    { el: 'block', width: '100%', height: 26 },
                    { el: 'block', width: '67%', height: 26 }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
}

export const PkgDetailsSkeleton = {
  mobile: {
    page: {
      ...BaseLineSkeleton,
      vfor: 3,
      style: { margin: '0 0 20px 0px' }
    }
  },
  desktop: {
    page: {
      ...BaseBlockSkeleton.desktop,
      vfor: 3
    }
  }
}

export const BookingOptionsSkeleton = {
  mobile: {
    page: {
      ...BaseLineSkeleton,
      vfor: 5,
      style: { margin: '20px 0 0 0' }
    }
  },
  desktop: {
    page: {
      ...BaseBlockSkeleton.desktop,
      vfor: 3
    }
  }
}

export const ExpSearchSkeleton = {
  mobile: {
    page: {
      el: 'skeleton',
      animate: true,
      mode: 'light',
      style: { padding: '0 20px' },
      vfor: 3,
      children: [{ el: 'block', width: '50%', style: { margin: '16px 0 16px 0' } }, { el: 'block' }]
    }
  },
  desktop: {
    page: {
      el: 'skeleton',
      animate: true,
      mode: 'light',
      style: { padding: '20px' },
      vfor: 3,
      children: [{ el: 'block', width: '50%' }, { el: 'block' }]
    }
  }
}

export const ExpListSkeleton = {
  mobile: {
    filter: {
      el: 'skeleton',
      animate: true,
      mode: 'light',
      style: { padding: '20px 0 0 0' },
      vfor: 2,
      children: [
        { el: 'block', height: 196, radiusSize: 'max' },
        { el: 'block' },
        { el: 'block', width: '50%', style: { margin: '0 0 32px 0' } }
      ]
    }
  },
  desktop: {
    filter: {
      el: 'skeleton',
      animate: true,
      mode: 'light',
      vfor: 1,
      children: [{ el: 'block', height: 150, radiusSize: 'max' }, { el: 'block' }, { el: 'block', width: '67%' }]
    }
  }
}

export const ActReviewsSkeleton = {
  mobile: {
    filter: {
      el: 'skeleton',
      animate: true,
      mode: 'light',
      style: { padding: '20px 0' },
      vfor: 8,
      children: [{ el: 'block' }, { el: 'block', width: '50%' }]
    }
  }
}

export const ExpSkeleton = {
  mobile: {
    section: {
      el: 'skeleton',
      animate: true,
      mode: 'light',
      vfor: 2,
      children: [{ el: 'block', width: '50%', height: 26 }, { el: 'block', height: 151, radiusSize: 'max', style: { margin: '0 0 32px 0' } }]
    }
  }
}

export const ActRightPriceSkeleton = {
  mobile: {
    page: {
      ...BaseLineSkeleton,
      vfor: 2,
      style: { margin: '24px 0 0 0' }
    }
  }
}

const PassDetailSkeleton = {
  el: 'skeleton',
  animate: true,
  mode: 'light',
  children: [
    {
      el: 'div',
      children: [
        { el: 'block', width: '100%', height: 197, radiusSize: 'max' },
        {
          el: 'div',
          class: 'flex-block',
          style: { margin: '16px 0 0 0px' },
          vfor: 5,
          children: [
            { el: 'block', width: '100%' },
            { el: 'block', width: '50%' }
          ]
        }
      ]
    }
  ]
}

export const PassSkeleton = {
  mobile: {
    detail: PassDetailSkeleton,
    attLoading: {
      ...BaseBlockSkeleton.mobile
    }
  },
  desktop: {
    detail: PassDetailSkeleton,
    attLoading: {
      ...BaseBlockSkeleton.desktop,
      style: { width: '800px' }
    }
  }
}

export const SectionSkeleton = {
  mobile: {
    base: {
      ...BaseBlockSkeleton.mobile,
      style: { width: '100%' }
    },
    pkgOption: {
      ...BaseBlockSkeleton.mobile,
      style: { width: '100%' }
    }
  },
  desktop: {
    base: {
      ...BaseBlockSkeleton.desktop,
      vfor: 3,
      style: { width: '800px' }
    },
    pkgOption: {
      ...BaseBlockSkeleton.desktop,
      vfor: 3,
      style: { width: '800px' }
    }
  }
}

export const AttSearchPageSkeleton = {
  desktop: {
    page: {
      el: 'skeleton',
      animate: true,
      mode: 'dark',
      class: 'flex',
      style: { display: 'flex', margin: '0 auto', padding: '30px 0 100px 0', width: '1160px' },
      children: [
        { el: 'block', width: 275, height: 546, radiusSize: 'max', style: { margin: '0 20px 0 0' } },
        {
          el: 'div',
          style: { flex: 1 },
          children: [
            { el: 'block', width: '100%', height: 26 },
            {
              el: 'div',
              style: { margin: '0 0 32px 0' },
              vfor: 2,
              children: [
                {
                  el: 'div',
                  class: 'flex-block',
                  style: { width: '100%', display: 'flex', gap: '20px' },
                  vfor: 3,
                  children: [
                    {
                      el: 'div',
                      style: { flex: 1 },
                      children: [
                        { el: 'block', width: '100%', height: 150, radiusSize: 'max' },
                        {
                          el: 'div',
                          style: { margin: '0 0 32px 0' },
                          children: [
                            { el: 'block', width: '100%', height: 26 },
                            { el: 'block', width: '50%', height: 26 }
                          ]
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  }
}
