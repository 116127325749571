
















































































import { Component } from 'nuxt-property-decorator'
import { IconFilter } from '@klook/klook-icons'
import FilterItem from '~/components/common/hero-filter/filter-item.vue'
import PopContent from '~/components/common/hero-filter/filter-pop-content.vue'
import { CardSwiper } from '~/components/traveller/home-page/card-swiper'
import FilterBase from '~/components/common/hero-filter/filter-base'

@Component({
  components: {
    FilterItem,
    CardSwiper,
    PopContent,
    IconFilter
  }
})
export default class HeroFilterMobile extends FilterBase {

}
