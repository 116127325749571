







import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import IndexV1 from '~/components/experience-booking/experience-activity/recent-review/index-v1.vue'
import IndexV2, { getPropsData2section } from '~/components/experience-booking/experience-activity/recent-review/index-v2.vue'

@Component({
  components: {
    IndexV2,
    IndexV1
  }
})
export default class Index extends BusinessBase {
  static displayName = 'DesktopBest_latest_review'
  @State klook!: Data.Klook

  getPropsData2section = getPropsData2section

  get calcIsv2() {
    return this.businessData?.version === 'v2'
  }
}
