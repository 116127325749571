










































import { Component } from 'nuxt-property-decorator'
import IndexMixin from '../mixin'

@Component
export default class ActivityHotelMobile extends IndexMixin {
  static displayName = 'MobileActivity_hotel'

  toggleShowMore: boolean = false

  get rateList() {
    const rateList = (this.lowestPriceInfo || {}).lowest_rate_list || []
    return this.toggleShowMore ? rateList : rateList.slice(0, this.lowestPriceInfo.show_min || 3)
  }

  showRemindModal() {
    this.$alert(this.lowestPriceInfo.remind)
  }
}
