
















import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class SwitchLoadState extends Vue {
  @Prop({ default: 'success' }) state!: 'success' | 'failure' | 'loading'
}
