// import dataUtils from '~/components/booking-core/utils/data-utils'
import * as dataTypesUtils from '~/components/booking-core/utils/types'

export function getExpTotalUnitCount(unitList: any[]) {
  if (!unitList?.length) {
    return 0
  }
  // 所有已选的预定unit的数量
  return unitList.reduce((acc: number, v: any) => acc + v.count, 0)
}

export const getExpUnitsExtra = (unitList: any[], params: dataTypesUtils.UnitsListParamsIf) => {
  // 修改请注意：所有预订流程units数据都跑此方法
  const { maxPurchased, inventories = {}, credits_tag } = params
  const totalUnitCount = getExpTotalUnitCount(unitList)
  if (!Array.isArray(unitList)) {
    return []
  }
  return unitList.map((v: any) => {
    const minCount = v.required ? v.priceMinPax : 0
    const maxCount = Math.min(v.priceMaxPax, maxPurchased - (totalUnitCount - v.count))
    if (v.prevCount !== undefined) {
      const realCount = Math.min(maxCount, Number(inventories[v.skuId]))
      if (v.prevCount > v.count) {
        v.count = v.prevCount > realCount ? 0 : v.prevCount
      } else if (v.prevCount < v.count) {
        v.count = v.prevCount < minCount ? 0 : v.prevCount
      }
      v.prevCount = undefined
    }
    return {
      credits_tag,
      ...v,
      requireMinPax: !v.required && v.count >= v.priceMinPax && v.priceMinPax > 1,
      minCount,
      // 最大可预定的unit数量由unit的最大可预定数量和当前套餐的库存确定
      maxCount
    }
  })
}

export const setExpAutoSelectSku = ({ unit, inventories, selectedTime, preSelectedSkuId, preview, pkgUnitsObj, currentSelectedPackage, $t, passValidate }: dataTypesUtils.ExpAutoSelectSkuIf) => {
  // 默认选中逻辑：
  // 第一次打开会默认选中preSelectedSkuId
  // 第一次打开不带日期：默认选中 => （切换日期）继续默认选中 => （切换日期）不默认选中
  // 第一次打开带日期：默认选中 => （切换日期）不默认选中
  // 注意：这里需要和unit里面的handlePlus保持一致
  if (preSelectedSkuId === unit?.skuId) {
    if (preview && !pkgUnitsObj?.unitList?.length) {
      return
    }
    const totalUnitCount = getExpTotalUnitCount(pkgUnitsObj.unitList)
    const { maxPurchased: defaultMaxPurchased } = selectedTime || {}
    const { max_pax } = (currentSelectedPackage || {}) as any
    const { count, priceMinPax, priceMaxPax, inventoryId, maxCount } = unit
    const maxCount2min = Math.min(priceMaxPax, defaultMaxPurchased - (totalUnitCount - count))
    const maxPurchased = Math.min(maxCount2min, Number(inventories?.[inventoryId]))

    if (totalUnitCount >= max_pax) {
      return $t('activity.v2.error.package_max', [max_pax])
    }
    if (count >= priceMaxPax) {
      if (maxCount) {
        return $t('activity.error.price_max_v2', [maxCount])
      }
      return
    }
    if (count >= maxPurchased || (count === 0 && count + priceMinPax > maxPurchased)) {
      if (!passValidate) {
        return $t('activity.v2.unit_out_of_inventory')
      }
      return
    }
    if (count < priceMaxPax) {
      unit.count = Math.max(priceMinPax, 1)
      return
    }
    return $t('activity.error.price_max_v2', [priceMaxPax])
  }
}
