




import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class Index extends Vue {
  @Prop() item!: any

  get type() {
    return this.item?.type
  }

  get content() {
    return this.item?.content
  }
}
