



















import { Component, Vue } from 'nuxt-property-decorator'
import { config } from '~/components/experience-booking/experience-activity/pass-guide/config'

@Component
export default class PassGuidDescribe extends Vue {
  get list() {
    return config
  }
}
