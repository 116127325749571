import { render, staticRenderFns } from "./reviews-item.vue?vue&type=template&id=5c87d618&scoped=true&"
import script from "./reviews-item.vue?vue&type=script&lang=ts&"
export * from "./reviews-item.vue?vue&type=script&lang=ts&"
import style0 from "./reviews-item.vue?vue&type=style&index=0&id=5c87d618&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c87d618",
  null
  
)

export default component.exports