import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import { markdown2RenderObj } from '~/assets/scripts/markdown'

@Component
export default class ActivityFaq extends BusinessBase {
  @State klook!: Data.Klook

  get activityFaq() {
    const faq = this.businessData?.activity_faq?.faq || []

    const t = faq.map((item: any) => {
      if (!item.question || !item.answer) {
        return ''
      }

      return '## ' + item.question + ' \n\n ' + item.answer
    }).join('\n\n')

    return markdown2RenderObj(t)
  }

  get helpCenter() {
    return this.businessData.help_center
  }

  get activityFaqVisible() {
    return this.businessData?.activity_faq?.faq
  }

  get helpCenterVisible() {
    return this.klook.utilConfig?.faq_klook && this.helpCenter
  }

  get valid() {
    return this.helpCenterVisible || this.activityFaq
  }

  async mounted() {
    if (!this.valid) {
      await this.hideSection && this.hideSection()
    }
  }
}
