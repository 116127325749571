


















































































import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import KlkStars from '~/components/traveller/activity/stars.vue'
import ImagePreview from '~/components/traveller/activity/activity-reviews/desktop/activity-reviews-image-preview.vue'
import ActivityReviewsHelpful from '~/components/traveller/activity/activity-reviews/activity-reviews-helpful.vue'
import ReviewUser from '~/components/experience-booking/experience-activity/recent-review/review-user.vue'
import SeeMoreWithLines from '~/components/common/see-more-with-lines.vue'

@Component({
  components: {
    ImagePreview,
    ActivityReviewsHelpful,
    KlkStars,
    SeeMoreWithLines,
    ReviewUser
  }
})
export default class ActivityCommentItem extends Vue {
  @State klook!: Data.Klook
  @Prop() comment!: any

  showTranslationContent: boolean = true

  get starText() {
    const rating = Number(this.comment?.rating || 0)

    if (rating > 80) {
      return this.$t('review_highly_recommended')
    }

    return rating > 60 ? this.$t('review_good') : ''
  }

  setAvatarUrl(avatar: string) {
    return 'https://cdn.klook.com/upload/img200X200/' + avatar
  }

  changeCommentContent() {
    this.showTranslationContent = !this.showTranslationContent
  }
}
