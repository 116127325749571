














































import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import chunk from 'lodash/chunk'
import get from 'lodash/get'
import { CardSwiper, CardSwiperItem } from '~/components/experience/card-swiper'

@Component({
  components: {
    CardSwiper,
    CardSwiperItem
  }
})
export default class RwdSwiper extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() data!: any[]
  @Prop() swiperBind!: any

  @Prop({ default: 4 }) perView!: number
  @Prop() gap!: number
  @Prop() offset!: number

  get isDesktop() {
    return this.platform === 'desktop'
  }

  get dataList() {
    if (!this.valid) {
      return []
    }

    if (this.isDesktop) {
      return chunk(this.data, this.perView)
    }
  }

  get valid() {
    return this.data && this.data.length
  }

  transitionend() {
    this.$emit('transitionend')
  }

  getCardType() {
    return get(this.$attrs, 'is-big-card', null)
  }
}
