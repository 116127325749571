




























import { Component, Prop, Vue, State, Watch } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import CardSwiper from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/card-swiper.vue'
import { getStandardDateFormat } from '~/share/data/datetime'
import { Schedule } from '~/components/experience-booking/activity/activity-calendar/mobile/types'
import MiniDateButton from '~/components/experience-booking/activity/activity-calendar/mobile/date-button.vue'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import { scrollToCenterHorizontal } from '~/share/scroll-polyfill'

@Component({
  components: {
    CardSwiper,
    MiniDateButton,
    CalendarLengends
  }
})
export default class MiniCalendar extends Vue {
  @State klook!: Data.Klook
  @Prop() originalSchedules!: Schedule[]
  @Prop() schedules!: Schedule[]
  @Prop() value!: string
  @Prop({ default: false }) smooth!: boolean
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: true }) isTrackItem!: boolean
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: false }) useNewScroll!: boolean

  selectedDate = ''
  items!: { [prop: string]: HTMLElement }

  @Watch('value', { immediate: true })
  valueChange(val: string) {
    if (val !== this.selectedDate) {
      this.slide(val, this.smooth)
      this.selectedDate = val
    }
  }

  get hasLegend() {
    return this.originalSchedules.some((item: any) => item?.legend_keys?.length > 0)
  }

  get isOverLimit() {
    if (!this.value) {
      return false
    }

    const length = this.schedules?.length

    if (!length) {
      return false
    }

    return !this.schedules.some((day: Schedule) => dayjs(this.value).isSame(day.date, 'day'))
  }

  get mySchedule() {
    // 如果超出了，则展示在最后一个
    if (this.isOverLimit && this.value) {
      const date = dayjs(this.value).format('YYYY-MM-DD')
      const target = this.originalSchedules.find((day: Schedule) => day.date === date)
      const newDate = target || {
        date,
        selling_price: '',
        soldout: false,
        stocks: [],
        disable: true
      }
      return [...this.schedules, newDate]
    }

    return this.schedules
  }

  getTrackInfo(item: any) {
    return this.isTrackItem
      ? {
        'data-spm-item': `SelectDate?ext=${JSON.stringify({ Date: item.date })}`
      }
      : {
        'data-spm-module': `SelectDate?ext=${JSON.stringify({ Date: item.date })}`,
        'data-spm-virtual-item': '__virtual'
      }
  }

  getDisplayDate(date: string, index: number) {
    if (index < 2) {
      const today = dayjs().format('YYYY-MM-DD')
      const tomorrow = dayjs()
        .add(1, 'day')
        .format('YYYY-MM-DD')

      if (date === today) {
        return this.$t('today')
      } else if (date === tomorrow) {
        return this.$t('search_tomorrow')
      }
    }
    return getStandardDateFormat(date, this.$t.bind(this), this.klook.language, 3)
  }

  // 用 smooth 会有问题，动画被阻断了，暂时不要用
  slide(val: string, smooth: boolean = false) {
    setTimeout(() => {
      const node = this.getItem(val)

      if (node) {
        // 新加的功能，只自测过，请小心使用
        if (this.useNewScroll) {
          scrollToCenterHorizontal(this.getWrapper()!, node)
        } else {
          node.scrollIntoView({
            behavior: smooth ? 'smooth' : 'auto',
            inline: 'center',
            block: 'nearest'
          })
        }
      }
    }, 160)
  }

  formatDate(date: string) {
    return getStandardDateFormat(date, this.$t.bind(this), this.klook.language, 1)
  }

  formatPrice(price: number) {
    return `${this.klook.currencySymbol} ${price}`
  }

  change(item: Schedule) {
    if (item.soldout || item.disable) {
      return null
    }
    const { date } = item
    this.selectedDate = date

    this.slide(date)

    this.$emit('change', date, item)
    this.$emit('input', date, item)
  }

  getWrapper() {
    return this.$el?.querySelector('.card-swiper')
  }

  getItem(date: string) {
    return this.$el?.querySelector(`[data-date="${date}"]`)
  }

  mounted() {
    const a = setTimeout(() => {
      this.slide(this.value, this.smooth)
      clearTimeout(a)
    }, 500)
  }
}
