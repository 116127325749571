


















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class AttractionPreviewPrice extends Vue {
  @Prop({ default: false }) list!: any[];
  @Prop({ default: 'desktop' }) platform!: string;
  @Prop({ default: 'desktop' }) title!: string;
  @Prop({ default: 'desktop' }) note!: string;
  @Prop() handleShowProductNote!: (content: string) => void;

  @Prop() slogan!: string;

  handleShowPacakgePrice(price: string) {
    const str = this.$t('88601') || ''
    return str.replace('{price}', `<span class="package-num">${price}</span>`)
  }
}
