var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.hideHeaderGlobal),expression:"!hideHeaderGlobal"}],staticClass:"activity-header-nav-wrap"},[(_vm.whiteLabelHeader)?_c('div',{ref:"header",staticClass:"j-activity-header activity-header",style:({ top: _vm.headerTop })},[_c('div',{staticClass:"partner_header_box"}),_vm._v(" "),_c('div',{staticClass:"platform_header_box"})]):[_c('client-only',[(_vm.showAppDownload && !_vm.isGuestCheckout)?_c('div',{staticClass:"pos-app-download",style:({ transform: ("translateY(" + _vm.appDownloadHeight + "px)") })},[_c('ExpAppDownload',{style:({ transform: ("translateY(-" + _vm.appDownloadHeight + "px)") }),attrs:{"delay-time":_vm.isFirstAppDownload ? -1 : 3000,"page-key":"mobile-activity-page","el":".pos-app-download"},on:{"translate":_vm.translate,"close":_vm.appDownloadClose}})],1):_vm._e()]),_vm._v(" "),_c('DefaultHeader',{ref:"header",staticClass:"j-activity-header activity-header",style:({ top: _vm.headerTop })})],_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.visible),expression:"visible"}],ref:"nav",staticClass:"j-activity-nav-container activity-nav-container",style:({ top: _vm.navTop })},[_c('div',{staticClass:"nav-horizontal"},[_c('div',{directives:[{name:"swiper",rawName:"v-swiper:$mySwiper",value:(_vm.swiperOption),expression:"swiperOption",arg:"$mySwiper"}],staticClass:"activity-nav-list"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.navList),function(item,index){return _c('div',{directives:[{name:"track",rawName:"v-track:module:TopLocateTo_LIST",value:({
              trg: 'manual',
              idx: index,
              len: _vm.navList.length,
              ext: {
                Type: item.title
              }
            }),expression:"{\n              trg: 'manual',\n              idx: index,\n              len: navList.length,\n              ext: {\n                Type: item.title\n              }\n            }",arg:"module:TopLocateTo_LIST"}],key:item.title + '_' + index,staticClass:"swiper-slide slide-horizontal",class:{active: index === _vm.activeIndex},attrs:{"data-spm-virtual-item":"__virtual"},on:{"click":function($event){return _vm.handleNavClick(item, index)}}},[_vm._v("\n            "+_vm._s(item.title)+"\n          ")])}),0)]),_vm._v(" "),_c('SvgIcon',{directives:[{name:"track",rawName:"v-track:module:TopLocateToMore",value:({
          trg: 'manual'
        }),expression:"{\n          trg: 'manual'\n        }",arg:"module:TopLocateToMore"}],staticClass:"nav-arrow",attrs:{"data-spm-virtual-item":"__virtual","name":"mobile-activity#icon_navigation_chevron_down_m","width":"16","height":"16"},nativeOn:{"click":function($event){return _vm.showNavLayer($event)}}})],1),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.layerVisible),expression:"layerVisible"}],staticClass:"nav-layer",on:{"touchmove":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"nav-mask",on:{"click":_vm.hideLayer}}),_vm._v(" "),_c('div',{staticClass:"nav-layer-list"},_vm._l((_vm.navList),function(item,index){return _c('div',{key:item.title + '_' + index,staticClass:"nav-layer-item",class:{active: index === _vm.activeIndex},on:{"click":function($event){return _vm.handleNavClick(item, index)}}},[_vm._v("\n          "+_vm._s(item.title)+"\n        ")])}),0)])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }