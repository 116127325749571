















import { Component, mixins, Ref, namespace, Prop } from 'nuxt-property-decorator'
import PackageCardList from './fnb-list.vue'
import ShoppingCart from '~/components/experience-booking/experience-activity/activity-shopping-cart/index.vue'
import BookingCoreMixin from '~/components/booking-core/mixins/entry/index-base'
import AutoBookingMixin from '~/components/booking-core/mixins/common/auto-booking'
import { Activity } from '~/types/traveller/activity'
import dataUtils from '~/components/booking-core/utils/data-utils'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    PackageCardList,
    ShoppingCart
  }
})
export default class CardPackageOptionsNew extends mixins(BookingCoreMixin, AutoBookingMixin) {
  @ActivityModule.Getter('currPackageOptionTab') currPackageOptionTab!: Activity.ComboTab
  @Prop() isFnb!: boolean
  @Prop() businessData!: any
  @Ref() activityBookingRef!: any
  bookingCore = {}

  get basicData() {
    return {
      is_in_flash_sale: this.isInFlashSale,
      is_line_page: this.isLinePage
    }
  }

  get isInCombo() {
    return this.currPackageOptionTab === 'combo'
  }

  get business() {
    return {
      activity_id: this.activityId,
      activity_ticket_type: this.activityTicketType,
      date_description: this.dateDescription,
      is_support_shopping_cart: this.isSupportShoppingCart,
      template_id: this.templateId
    }
  }

  getBookingCore() {
    return dataUtils.getBookingCoreData2section({
      businessData: this.businessData,
      basicData: this.basicData,
      $route: this.$route
    })
  }

  mounted() {
    this.initAutoBookingMinxin({
      activityId: this.activityId,
      shopId: parseInt(this.$route.query.shop_id as string),
      shoppingCartHandler: this.showShoppingCart
    })
  }
}
