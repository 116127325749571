








import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class CardSwiper extends Vue {
  handleScroll() {
    this.$emit('scroll-once')
  }
}
