





























import { Component, Vue, Prop, State, Watch, Ref } from 'nuxt-property-decorator'

@Component
export default class SeeMoreWithLines extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: false }) isBtnClick!: boolean
  @Prop({ default: 2 }) maxLines!: number
  @Prop() content!: string[]
  @Prop({ default: false }) hideLess!: boolean
  @Prop({ default: false }) showMoreIcon!: boolean
  @Prop({ default: false }) visible!: boolean // 慎重使用
  @Ref() contentRef!: any

  isShowMore = false
  isShowMoreBtn = false

  get viewMoreText() {
    return this.isShowMore ? this.$t('collapse_all') : this.$t('city_page_view_more')
  }

  // 支持展开后隐藏收起按钮
  get hideMoreButton() {
    return this.isShowMore && this.hideLess
  }

  @Watch('content')
  contentChange() {
    this.$nextTick(this.checkHeight)
  }

  @Watch('visible')
  visibleChange(val: boolean) {
    // 只在没有showmore的情况下检查
    if (val && !this.isShowMoreBtn) {
      this.$nextTick(this.checkHeight)
    }
  }

  mounted() {
    this.checkHeight()
  }

  showMore(isTrue = false) {
    if (this.isBtnClick && !isTrue) {
      return
    }
    if (!this.hideMoreButton) {
      this.isShowMore = !this.isShowMore
    }
  }

  checkHeight() {
    if (process.client) {
      const contentDom = this.contentRef as any
      this.isShowMoreBtn = false
      if (contentDom && contentDom.scrollHeight > contentDom.offsetHeight) {
        this.isShowMoreBtn = true
      }
    }
  }
}
