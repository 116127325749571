
























































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import ReviewUserV2 from '~/components/experience-booking/experience-activity/recent-review/review-user-v2.vue'
import Logo from '~/components/experience/card-swiper/logo.vue'
import { formatAvatarUrl } from '~/share/data/image'
import PrefixImgNew from '~/components/experience/prefix-img/index-new.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    PrefixImgNew,
    ReviewUserV2,
    Logo
  }
})
export default class RecentReviewCard extends Vue {
  @State klook!: Data.Klook
  @Prop() index!: number
  @Prop() avatar!: string
  @Prop() score!: string | number
  @Prop() name!: string
  @Prop() utcTime!: string
  @Prop() content!: string
  @Prop() imageList!: any[]

  getReviewCardTrack(i: number) {
    const ext = encodeURIComponent(JSON.stringify({
      rank: i + 1
    }))
    const obj = {
      type: 'module',
      exposure: true,
      action: true,
      spm: 'Review_Card',
      query: {
        ext
      }
    }
    return this.bindIHTrack(obj)
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get slicedImageList() {
    const arr = this.imageList || []
    return arr?.slice(0, 4)
  }

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get countText() {
    const sub = this.isDesktop ? 4 : 1
    const len = this.imageList?.length
    return len > sub ? `${len - sub}+` : ''
  }

  getAvatarUrl(avatar: string) {
    return formatAvatarUrl(avatar)
  }
}
