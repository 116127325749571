




















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { ISellingpoint } from '../type'

@Component
export default class PassIntroduction extends Vue {
  @Prop() list!: ISellingpoint[]

  get first() {
    return this.list[0]
  }

  get desc() {
    return this.list[0]?.desc?.replace(/color-text-highlight-orange/g, '#FF5B00')
  }
}
