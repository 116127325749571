




import { Component, Vue, State } from 'nuxt-property-decorator'
import { PkgDetailsSkeleton } from '~/components/common/skeleton-data/index.js'

@Component({})
export default class PackageDetailLoading extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  PkgDetailsSkeleton = PkgDetailsSkeleton

  get skeletonData() {
    const { platform, PkgDetailsSkeleton } = this
    return platform === 'desktop' ? PkgDetailsSkeleton.desktop.page : PkgDetailsSkeleton.mobile.page
  }
}
