













import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class ActivityFaqItem extends Vue {
  @Prop() content!: string
}
