












































import { Component, Vue, Prop, State, Ref, Watch } from 'nuxt-property-decorator'
import {
  CombonBookingType,
  Itrial
} from '~/components/experience-booking/experience-activity/combo-option/booking-options/type'
import { formatCurrencySymbolPriceThousands } from '~/share/utils'

@Component({
  components: {}
})
export default class BookingUnit extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: Boolean, default: true }) isTrackModule!: boolean
  @Prop() packageId!: number
  @Prop({ default: 'mweb-type' }) styleType!: 'desktop-type' | 'mweb-type'
  @Prop() preview!: any
  @Prop() currentSelectedPackage!: CombonBookingType
  @Prop({ default: 0 }) totalUnitCount!: number
  @Prop() index!: number
  @Prop({ default: {} }) unit!: any
  @Prop() priceDetail!: Itrial
  @Ref() unitCounterRef!: any

  counterValue = 0
  disabled = false

  @Watch('unit.count')
  countChange(val: number) {
    if (val === this.maxUnit) {
      this.disabled = true
    } else {
      this.disabled = false
    }
  }

  get maxUnit() {
    const max = Math.min(this.currentSelectedPackage.max_pax, this.unit.price_max_pax)
    if (this.totalUnitCount > 0) {
      // 其他 sku的 count
      const otherSKuCount = this.totalUnitCount - (this.unit.count || 0)
      if (otherSKuCount === 0) {
        return max
      }

      // 可以使用的sku count
      const canUseCount = this.currentSelectedPackage.max_pax - this.totalUnitCount
      const canUseMax = canUseCount + this.unit.count
      return canUseMax <= this.unit.price_max_pax ? canUseMax : this.unit.price_max_pax
    }

    return max
  }

  get minUnit() {
    if (this.unit.required) {
      return this.unit.price_min_pax
    }
    return 0
  }

  get showSellingPrice() {
    if (this.priceDetail?.units_info) {
      const price = this.priceDetail?.units_info.find((item) => item.sku_id === this.unit.sku_id)
        ?.selling_price
      return price
    }
  }

  get showFormPrice() {
    return this.unit.price_show.from_price
  }

  get showSalePrice() {
    return this.unit.price_show.sale_price
  }

  get salePrice() {
    if (this.priceDetail?.units_info) {
      const price = this.priceDetail?.units_info.find((item) => item.sku_id === this.unit.sku_id)
        ?.selling_price as number
      // 试算接口返回的是数字，需要进行千分位处理
      return formatCurrencySymbolPriceThousands(this.klook.currency, price)
    }
  }

  get calcTrackInfo() {
    let obj: any = {
      'data-spm-item': 'SelectSkuQuantity?trg=manual'
    }
    if (this.isTrackModule) {
      obj = {
        'data-spm-module': 'SelectSkuQuantity',
        'data-spm-virtual-item': '__virtual?trg=manual'
      }
    }
    return obj
  }

  get calcFormPrice() {
    const isMweb = this.klook.platform === 'mobile'
    const str = this.$t('activity_detail_from_price', [
      isMweb ? 12 : 14,
      `${this.klook.currencySymbol || ''}${this.unit.price_show.from_price}`
    ])

    if (isMweb) {
      return str.replace('333333', '757575;text-align:right')
    } else {
      return str.replace('333333', '212121;text-align:right;padding-right:5px;font-weight: 400;')
    }
  }

  disabledClick(type: string) {
    if (type === 'increase') {
      this.alert(this.$t('30564'))
    } else {
      const { price_min_pax, count, required } = this.unit
      if (required && count <= price_min_pax) {
        this.alert(this.$t('activity.error.required_buy_min', [price_min_pax]))
      }
    }
  }

  hackIncrease() {
    // klk-counter有bug，这里hack一下
    this.counterValue += 1
    this.$nextTick(() => {
      this.counterValue -= 1
      if (this.unit.count === this.maxUnit) {
        this.disabled = true
      } else {
        this.disabled = false
      }
      this.handlePlus()
    })
  }

  hackDecrease() {
    // klk-counter有bug，这里hack一下
    this.counterValue -= 1
    this.$nextTick(() => {
      this.counterValue += 1
      this.handleMinus()
    })
  }

  // combon sku选择器 + + 数量套餐规则
  handlePlus() {
    if (this.disabled) {
      return
    }

    if (this.maxUnit - (this.unit.count || 0) >= 0) {
      this.changeUnitCount('add')
    }
  }

  handleMinus() {
    const { unit, disabled } = this
    if (disabled || unit.count === 0) {
      return
    }
    this.changeUnitCount('minus')
  }

  // hackChangeUnitCount(type: 'add' | 'minus', priceId: number) {
  //   // klk-counter有bug，这里hack一下
  //   this.unit.count += type === 'add' ? 1 : -1
  //   this.$nextTick(() => {
  //     this.unit.count += type === 'add' ? -1 : 1
  //     this.changeUnitCount(type, priceId)
  //   })
  // }

  changeUnitCount(type: 'add' | 'minus') {
    this.$emit('changeUnitCount', { index: this.index, type })
    this.inhouseTrack(type === 'add' ? 'Add' : 'Cut')
  }

  alert(text: string, translate = false) {
    const message = translate ? this.$t(text) : text
    if (this.klook.platform === 'desktop') {
      const ele = document.getElementById('combo-booking-option-desktop') as HTMLElement
      // @ts-ignore
      this.$toast(message, ele)
    } else {
      this.$toast({ message })
    }
  }

  inhouseTrack(type: string) {
    if (this.unitCounterRef) {
      this.$inhouse.updateBinding(this.unitCounterRef.$el, {
        oid: `sku_${this.unit.sku_id}`,
        ext: JSON.stringify({
          Type: type
        })
      })

      setTimeout(() => {
        this.$inhouse.track('action', this.unitCounterRef.$el)
      }, 20)
    }
  }
}
