







































































































import { Component, Prop } from 'nuxt-property-decorator'
import { IconCalendarUnavailable, IconCheckCircle, IconNext } from '@klook/klook-icons'
import { Package } from '../type'
import Price from '~/components/experience-booking/experience-activity/package-options/desktop/group-package-options/components/group-package-price.vue'
import CutDown from '~/components/experience-booking/experience-activity/package-options/mobile/group-package-options/components/cut-down/index.vue'
import { IPackageItem } from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/package-card-list/type'
import ExperiencePackageDetailModule from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-module.vue'
import ExpTagging from '~/components/experience/tagging/index.vue'
import PackageOptionsBooking from '~/components/experience-booking/experience-activity/package-options/desktop/card-sku-with-detail/pkg-options-booking.vue'
import ExpPkgDetailLoading from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/pkg-detail/loading.vue'
import eventBus from '~/pages/experience/pay/common/event-bus'
import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2'
import CardBase from '~/components/experience-booking/experience-activity/package-options/mobile/group-package-options/card.base'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'

@Component({
  components: {
    ExpTagging,
    ExperiencePackageDetailModule,
    CutDown,
    IconCalendarUnavailable,
    Price,
    IconCheckCircle,
    IconNext,
    PackageOptionsBooking,
    ExpPkgDetailLoading,
    WarmingSlogan
  }
})
export default class GroupPackageCard extends CardBase {
  @Prop({ type: Boolean, default: false }) isPassActivity!: boolean
  @Prop() packageData!: IPackageItem
  @Prop({ default: [] }) limitedAvailablePackageList!: any[]
  @Prop() packageDescList!: Package[]
  @Prop() businessData!: any
  @Prop() total!: number
  @Prop() date!: string
  @Prop({ type: Boolean, default: false }) isDisable!: boolean
  @Prop() getPackageSchedulesUnits!: (id: number) => any
  @Prop({ default: {} }) packagesGroupDesc!: any
  @Prop() getBookingCore!: () => any

  bookingCore: any = {}
  detailShow = false
  loading = false
  isUnfold = false
  skuId = 0

  get businessDatas(): any {
    const {
      activity_ticket_type,
      date_description,
      is_support_shopping_cart,
      template_id
    } = this.businessData
    return {
      activity_id: this.businessData.activity_id,
      activity_ticket_type,
      date_description,
      is_support_shopping_cart,
      packages: this.limitedAvailablePackageList,
      template_id
    }
  }

  get calcTags2attribute() {
    // mweb活动页-card_sku
    return this.packageData?.product_tags?.attribute_tags || []
  }

  // 可以展开跳转
  get id() {
    return `group-web-id-${this.packageData.package_id}-${this.packagesGroupDesc.track_id}`
  }

  bookClosed() {
    this.isUnfold = !this.isUnfold
  }

  scrollToPackageOptionsTop() {
    const d = document.getElementById(this.id)
    const ele = document.querySelector('.group-swiper-wapper')
    // 默认高度为： 最顶到&&楼层导航栏高度
    let height = 61 + 47
    if (ele) {
      // 加上导航栏高度再加上padding
      height = height + ele.clientHeight + 24
    }
    const top = nodeScrollTop(d) - (height || 0)
    scroll2({
      scrollY: top,
      duration: 100,
      callback: () => eventBus.$emit('groupLockScroll', false)
    })
  }

  open() {
    this.$nextTick(() => {
      this.isUnfold = true
      eventBus.$emit('groupLockScroll', true)
    })

    setTimeout(() => {
      this.scrollToPackageOptionsTop()
    }, 400)
  }

  toBook() {
    if (this.isDisableSale || this.isDisable) {
      return
    }
    if (!this.isUnfold) {
      this.loading = true
      eventBus.$emit('groupOtherClosed', this.packageData.package_id)
      // const { units } = await this.getPackageSchedulesUnits(this.packageData.package_id)
      // if (units && units.length > 0) {
      const payload = this.getBookingCore()
      payload.packageList = this.limitedAvailablePackageList
      payload.packageId = this.packageData.package_id
      this.bookingCore = payload
      // this.skuId = units[0].sku_id
      this.open()
      // }
      this.loading = false
    } else {
      this.isUnfold = false
    }
  }

  groupOtherClosed(id: Number) {
    if (this.packageData.package_id !== id && this.isUnfold) {
      this.isUnfold = false
    }
  }

  mounted() {
    eventBus.$on('groupOtherClosed', this.groupOtherClosed)
  }

  destroyed() {
    eventBus.$off('groupOtherClosed', this.groupOtherClosed)
  }
}
