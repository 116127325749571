










import { Component, Prop, State, Vue } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { Activity } from '~/types/traveller/activity'
import { getStandardDateFormat } from '~/share/data/datetime'

@Component
export default class PackageCardPromoBelt extends Vue {
  @State klook!: Data.Klook
  @Prop() event!: Activity.promotion_event
  @Prop() bgImage!: string
  @Prop() tag!: string
  @Prop() startTime!: string
  @Prop() endTime!: string
  @Prop() status!: number

  get time(): string {
    const start = Date.parse(this.startTime)
    const end = Date.parse(this.endTime)
    const now = Date.now()

    const $t = this.$t.bind(this)
    let t = ''
    if (now < start) {
      const date = getStandardDateFormat(new Date(start), $t, this.klook.language, 3)
      const time = dayjs(start).format('HH:mm')
      t = this.$t('17822', { date, hhmm: time })
    }
    if (now > start && now < end) {
      const date = getStandardDateFormat(new Date(end), $t, this.klook.language, 3)
      const time = dayjs(end).format('HH:mm')
      t = this.$t('17823', { date, hhmm: time })
    }
    return t
  }
}
