var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"track",rawName:"v-track:module",value:({
    inject: 'section'
  }),expression:"{\n    inject: 'section'\n  }",arg:"module"}],staticClass:"activity-contact js-activity-contact",class:_vm.klook.platform},[_c('div',{staticClass:"activity-contact__content"},[_vm._v("\n    "+_vm._s(_vm.$t('12893'))+"\n  ")]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShow),expression:"isShow"}],staticClass:"chat-btn-container"},[_c('ChatEntrance',{attrs:{"inline":_vm.platform === 'desktop',"spm-name":null,"object-id":_vm.activityId,"extra-info":{
        activityName: _vm.title,
        isSRV: _vm.isSrvActivity
      }},on:{"chat-entrance-show":_vm.entranceAppeared}},[_c('klk-button',_vm._b({attrs:{"type":"secondary","data-spm-item":"__default"}},'klk-button',{
          block: false,
          size: _vm.klook.platform === 'mobile' ? 'small' : 'normal'
        },false),[_c('span',{staticClass:"flex-center"},[_c('SvgIcon',{attrs:{"name":"mobile-activity#icon_service_chat_fill_m","size":_vm.klook.platform === 'mobile' ? 16 : 24,"color":"#FF5B00"}}),_vm._v(" "),_c('span',{staticClass:"btn-text"},[_vm._v(_vm._s(_vm.$t('5110-chat_with_us')))])],1)])],1)],1),_vm._v(" "),_c('ChatEntrance',{class:[
      'activity-contact-chat-float',
      _vm.klook.platform,
      _vm.shouldMoveAbove && 'move-above'
    ],attrs:{"object-id":_vm.activityId,"extra-info":{
      activityName: _vm.title,
      isSRV: _vm.isSrvActivity
    },"transfer":true}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }