




import { Component, Vue, Getter, Prop } from 'nuxt-property-decorator'
import type { SubSectionComponent } from '../type'
import {
  SubSectionComponentIconList,
  SubSectionComponentImageSlides,
  SubSectionComponentImage,
  SubSectionComponentList,
  SubSectionComponentOrderedList,
  SubSectionComponentParagraph,
  SubSectionComponentSubTitle,
  SubSectionComponentTitle
} from './markdown-items'

@Component({
  components: {
    SubSectionComponentIconList,
    SubSectionComponentImageSlides,
    SubSectionComponentImage,
    SubSectionComponentList,
    SubSectionComponentOrderedList,
    SubSectionComponentParagraph,
    SubSectionComponentSubTitle,
    SubSectionComponentTitle
  }
})
export default class PackageDetailSection extends Vue {
  @Prop({ default: () => [] }) component!: SubSectionComponent
  @Getter isMobile!: boolean

  compMap = {
    iconList: SubSectionComponentIconList,
    imageSlides: SubSectionComponentImageSlides,
    image: SubSectionComponentImage,
    list: SubSectionComponentList,
    orderedList: SubSectionComponentOrderedList,
    paragraph: SubSectionComponentParagraph,
    subTitle: SubSectionComponentSubTitle,
    title: SubSectionComponentTitle
  }

  getComp(component: SubSectionComponent) {
    const { type } = component
    const comp = this.compMap?.[type]
    return comp
  }
}
