
























































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import SeeMore from '~/components/experience-booking/experience-activity/package-options/attraction-included/components/see-more.vue'
import { approximateThousandsNew } from '~/share/utils'
import { setNewImageSize } from '~/share/data/image'
import Logo from '~/components/experience/card-swiper/logo.vue'

@Component({
  components: {
    SeeMore,
    Logo
  }
})
export default class AttractionIncludedFilter extends Vue {
  @State klook!: Data.Klook
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop({ default: 'view' }) mode!: 'view' | 'edit' | 'book'
  @Prop() size!: 'small' | 'normal'
  @Prop() images!: any[]
  @Prop() total!: number
  @Prop() selectedIdList!: number[]
  @Prop() activityId!: number
  @Prop({ default: '' }) title!: string

  get logoWidth() {
    const isDesktop = this.platform === 'desktop'
    const width = isDesktop ? (this.isSmall ? 50 : 152) : 64

    if (this.ratio === '1:1') {
      return width / 3
    }

    return width * 3 / 16
  }

  get isSmall() {
    return this.size === 'small'
  }

  get ratio() {
    return this.isSmall ? '1:1' : '16:9'
  }

  get isEdit() {
    return this.mode === 'edit'
  }

  get filterTitleText() {
    const textId = this.title || '76895'
    return this.$t(textId, { num: this.total })
  }

  // 默认按 desktop、mobile 来自动分配 size，但是可以自己覆盖 size
  get realSize() {
    if (this.size) {
      return this.size
    }

    return this.platform === 'desktop' ? 'normal' : 'small'
  }

  get countText() {
    return this.total > 5 ? `${approximateThousandsNew(this.total - 4, false)}+` : ''
  }

  getImagesSpmObj() {
    return this.isEdit ? {} : {
      'data-spm-module': 'PackageDetailsAttractionsImage',
      'data-spm-virtual-item': '__virtual'
    }
  }

  getImageSpmObj(index: number) {
    const imgLen = this.images.slice(0, 5).length
    const len = imgLen === 5 ? 5 : (imgLen > 5 ? 4 : imgLen)
    return this.isEdit ? {
      'data-spm-module': index === 4 && this.countText
        ? 'AttractionsImageSeeMore'
        : `AttractionsIncludedLIST?oid=activity_${this.activityId}&idx=${index}&len=${len}`,
      'data-spm-virtual-item': '__virtual'
    } : {}
  }

  handleImagesClick() {
    if (!this.isEdit) {
      this.$emit('open')
    }
  }

  handleImageClick(id: number) {
    if (this.isEdit) {
      if (id) {
        this.$emit('toggle', id)
      } else {
        this.$emit('open')
      }
    }
  }

  formatPicUrl(url: string) {
    const { webp } = this.klook
    if (this.realSize === 'small') {
      setNewImageSize(url, 'image/upload/', 120, 120, webp)
    }
    return setNewImageSize(url, 'image/upload/', 200, 100, webp)
  }
}
