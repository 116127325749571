

























































import { Component, Ref } from 'nuxt-property-decorator'
import PackagePromotionContent from '~/components/experience-booking/experience-activity/package-options/package-promotion/package-promotion-content.vue'
import ActivityPackagePromotionBase
  from '~/components/experience-booking/experience-activity/package-options/package-promotion/base'
import { getModalConfig } from '~/components/experience/load-hander/index.vue'

@Component({
  components: {
    PackagePromotionContent
  }
})
export default class ActivityPromotionInfoDesktop extends ActivityPackagePromotionBase {
  @Ref() promotionContent!: any

  modalObj = getModalConfig('common-medium-config', { 'show-default-footer': true })

  isModalVisible: boolean = false

  handleOpenModal() {
    if (this.promotionFilteredNone) {
      return
    }

    this.$emit('open')
    this.getActivityPromotion()
    this.isModalVisible = true
    this.$sendGTMCustomEvent(`Activity Page|ViewPackageWithPromotion_Click|${this.activityId}|${this.templateId}`)
  }

  handleConfirmModal() {
    this.isModalVisible = false
    this.promotionContent.confirmSelect()
  }

  handleCloseModal() {
    this.isModalVisible = false
  }

  selectPackage(data: number) {
    this.$emit('selectPackage', data)
  }

  mounted() {
    this.$sendGTMCustomEvent(`Activity Page|ViewPackageWithPromotion_Appear|${this.activityId}|${this.templateId}`)
  }
}
