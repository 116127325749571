












































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import AttractionPreviewLine from './attraction-preview-line.vue'

@Component({
  components: {
    AttractionPreviewLine
  }
})
export default class AttractionPreviewTags extends Vue {
  @Prop({ default: false }) list!: any[]
  @Prop({ default: 'desktop' }) platform!: string
  @Prop({ default: '' }) slogan!: string
  @Prop({ default: '' }) note!: string

  visible = false

  clientWidth = 0

  handleShowProductNote() {
    this.visible = true
  }

  scrollDown() {
    setTimeout(() => {
      const poptip = this.$refs.poptip as Vue
      if (poptip) {
        const poptipElement = poptip.$el.querySelector('.klk-poptip-popper-content')
        if (poptipElement) {
          poptipElement.scrollIntoView({
            block: 'center',
            inline: 'nearest',
            behavior: 'smooth'
          })
        }
      }
    }, 100)
  }
}
