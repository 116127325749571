var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('klk-poptip',{attrs:{"content":_vm.content,"trigger":_vm.trigger}},[_c('div',{class:[
      'hero-filter-item',
      _vm.klook.platform,
      _vm.mode,
      _vm.active && 'active',
      _vm.verbose && 'verbose',
      _vm.disableHover && 'disable-hover',
      _vm.disabled && 'disabled'
    ]},[_c('span',{staticClass:"hero-filter-item-text"},[_vm._t("default")],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }