import { render, staticRenderFns } from "./booking-btns.vue?vue&type=template&id=28606c1e&scoped=true&"
import script from "./booking-btns.vue?vue&type=script&lang=ts&"
export * from "./booking-btns.vue?vue&type=script&lang=ts&"
import style0 from "./booking-btns.vue?vue&type=style&index=0&id=28606c1e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28606c1e",
  null
  
)

export default component.exports