































import { Component, Prop, State, Vue } from 'nuxt-property-decorator'
import SectionDTO from '~/components/experience-booking/experience-activity/section/section-dto.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { async2 } from '~/share/utils'
import ActivityNavMobile from '~/components/experience-booking/experience-activity/activity-nav/mobile/index.vue'
import ActivityNavDesktop from '~/components/experience-booking/experience-activity/activity-nav/desktop/index.vue'

@Component({
  components: {
    SectionDTO,
    ActivityNavMobile,
    ActivityNavDesktop
  }
})
export default class SectionList extends Vue {
  @Prop() sources!: ExperienceActivity.ISources
  @Prop() sections!: ExperienceActivity.Sections
  @Prop() preview!: number
  @Prop({ default: false }) hideNav!: boolean
  @State(state => state.klook.platform) platform!: Data.Platform

  sourcesData = this.sources
  // sectionsData = [...this.sections]
  sectionsData = this.handleSections(this.sections)
  sourcesLoadingMap = new Map<string, Promise<any>>()

  // 预处理后端返回的楼层数据(无副作用)
  // 1.删掉相邻的间隔带
  handleSections(sections: ExperienceActivity.Sections) {
    if (!sections) {
      return []
    }
    const res = []
    let lastSectionIsDivider = false
    let id = 0
    for (const section of sections) {
      const tempSectionIsDivider = section?.meta?.type === 'divider'
      if (lastSectionIsDivider && tempSectionIsDivider) {
        continue
      }
      lastSectionIsDivider = tempSectionIsDivider

      res.push({ ...section, uid: id })
      id += 1
    }
    return res
  }

  // 更新楼层数据
  update(data: ExperienceActivity.ISources) {
    this.sourcesData = {
      ...this.sourcesData,
      ...data
    }
  }

  updateSource(name: string, data: any, isFetch?: boolean) {
    const source = this.sourcesData[name]

    const initialData = {
      ...(source.data || {}),
      state: 'loading'
    }

    this.update({
      [name]: {
        ...source,
        is_fetching: isFetch,
        data: {
          ...initialData,
          ...data
        }
      }
    })
  }

  handleFailureSection(name: string) {
    // 接口失败需要隐藏的楼层
    const failureHide = ['survey_info']
    if (failureHide.includes(name)) {
      this.removeSection(name)
    }
  }

  getAsyncSectionParams(name: string, params: any = {}) {
    // 有些楼层、平台组件接口添加额外的参数会报错，所以做处理，不追加preview参数
    const excludePreview = ['survey_info']
    return excludePreview.includes(name) ? params : {
      ...params,
      preview: this.preview
    }
  }

  async createUpdateSourcePromise(name: string, params: any = {}) {
    const source = this.sourcesData[name]
    const { load_url } = source

    const [error, resp] = await async2(
      this.$axios.$get(load_url, {
        params: this.getAsyncSectionParams(name, params)
      })
    )

    if (error) {
      this.handleFailureSection(name)
      this.updateSource(name, { state: 'failure' })
      return false
    }

    const { success, result } = resp

    if (!success) {
      this.handleFailureSection(name)
      this.updateSource(name, { state: 'failure' })
      return false
    }

    this.updateSource(name, { state: 'success', ...(result || {}) })
    return true
  }

  /**
   * 请求单个数据异步源的数据，并将请求结果合并回数据源
   * @Param name: 数据源名称
   * @Param params: 请求参数
   * @result 请求是否成功(一个Promise)
   * @todo 对于同步楼层也要加一个state，方便业务组件对楼层状态的处理
   */
  getAsyncSource(name: string, params: any = {}) {
    const source = this.sourcesData[name]
    const { is_fetching } = source

    if (is_fetching) {
      return this.sourcesLoadingMap.get(name)
    }

    // 不能把这个放到promise里面去
    this.updateSource(name, {}, true)

    // 这里没有以params作为键储存可能会有问题，但是因为之前也没有判断params，所以这次也没有判断
    const res = this.createUpdateSourcePromise(name, params)
    this.sourcesLoadingMap.set(name, res)
    return res

    // if (is_fetching) {
    //   return
    // }

    // const data = {
    //   ...(source.data || {}),
    //   state: 'loading'
    // }

    // const updateSource = (data: any, isFetch?: boolean) => {
    //   this.update({
    //     [name]: {
    //       ...source,
    //       is_fetching: isFetch,
    //       data
    //     }
    //   })
    // }

    // updateSource(data, true)

    // const [error, resp] = await async2(
    //   this.$axios.$get(load_url, {
    //     params: {
    //       ...params,
    //       preview: this.preview
    //     }
    //   })
    // )

    // if (error) {
    //   return updateSource({ ...data, state: 'failure' })
    // }

    // const { success, result } = resp

    // if (!success) {
    //   return updateSource({ ...data, state: 'failure' })
    // }

    // return updateSource({
    //   ...data,
    //   state: 'success',
    //   ...(result || {})
    // })
  }

  get navComponent() {
    return this.platform === 'desktop'
      ? 'ActivityNavDesktop'
      : 'ActivityNavMobile'
  }

  /**
   * 异步楼层请求失败的处理
   *  1. 删除多余的divider（删除后面那个）
   *  2. 删除二级导航内对这个异步楼层的导航
   * @param name 楼层业务名称
   */
  removeSection(name: string) {
    const sectionIndex = this.sectionsData.findIndex(
      v => v.meta?.name === name
    )

    if (sectionIndex < 1) {
      return
    }

    const nextIndex = sectionIndex + 1
    const nextSection = this.sectionsData[nextIndex]

    if (nextSection?.meta?.type === 'divider') {
      this.sectionsData.splice(sectionIndex, 2)
    } else {
      this.sectionsData.splice(sectionIndex, 1)
    }

    this.$root && this.$root.$emit('nav.delSection', `.${name}`)
  }
}
