











































import { Component, Prop, Vue, Ref, Watch } from 'nuxt-property-decorator'

@Component
export default class SinglePop extends Vue {
  @Prop({ default: false }) visible!: boolean
  @Prop({ default: '' }) title!: boolean
  @Prop({ default: false }) animate!: boolean
  @Prop() activityId!: number
  @Ref() singleAttrPopRef!: any

  elementObserver: any = null
  popTextVisible = true

  mounted() {
    if (process.client && this.animate) {
      this.createObserver()
    }
  }

  @Watch('visible')
  visibleChange() {
    this.$nextTick(() => {
      const activeDom = this.singleAttrPopRef?.$el?.querySelector('.single-attr-pop-sheet .attr-btn.active')
      if (activeDom) {
        activeDom.scrollIntoView({
          behavior: 'instant',
          block: 'start',
          inline: 'start'
        })
      }
    })
  }

  beforeDestroy() {
    this.destroyObserver()
  }

  handleClick() {
    this.$emit('update:visible', true)

    if (this.singleAttrPopRef?.$el) {
      setTimeout(() => this.$inhouse.track('pageview', this.singleAttrPopRef.$el, { force: true }), 100)
    }
  }

  handleTransitionend() {
    this.$emit('update:animate', false)
  }

  createObserver() {
    if ('IntersectionObserver' in window) {
      this.elementObserver = new IntersectionObserver(
        entries => entries.forEach((e) => {
          if (e.intersectionRatio > 0) {
            setTimeout(() => (this.popTextVisible = false), 1000)
            this.destroyObserver()
          }
        }),
        { rootMargin: '-320px 0px -320px 0px' }
      )

      this.elementObserver.observe(this.$el)
    }
  }

  destroyObserver() {
    this.elementObserver && this.$el && this.elementObserver.unobserve(this.$el)
  }
}
