



































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { IconWarnCircle } from '@klook/klook-icons'
import ActivityValidity from '~/components/experience-booking/experience-activity/open-date-ticket/icon-tips/activity-validity.vue'

@Component({
  components: {
    IconWarnCircle,
    ActivityValidity
  }
})
export default class OpenDateIconTips extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  @Prop({ default: [] }) usageValidRenderObj!: any
  @Prop({ default: '' }) bottomSheetTitle!: string
  @Prop({ default: false, type: Boolean }) hideClose!: boolean

  usageValidSheetVisible = false
  usageValidModalVisible = false

  get iconSize() {
    return this.platform === 'desktop' ? 16 : 14
  }

  handleIconClick() {
    if (this.platform === 'desktop') {
      this.usageValidModalVisible = true
    } else {
      this.usageValidSheetVisible = true
    }
  }
}
