






















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { HomePage } from '~/types/traveller/home-page'

@Component
export default class CouponListComponent extends Vue {
  @Prop({ default: () => ([]) }) couponList!: HomePage.tag[]
  @Prop({ default: '' }) slotName!: string
}
