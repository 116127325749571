




























import { Prop, Vue, Component } from 'nuxt-property-decorator'
import { Datalist } from '../type'

@Component({})
export default class Previewlist extends Vue {
  @Prop() loading!: boolean;
  @Prop() standardActivityId!: number;
  @Prop() current!: number;

  @Prop({ default: [] }) activityList!: Datalist[];

  getsmg(item: Datalist, index: number, activityList: Datalist[]) {
    return `ActivityCard_LIST?oid=activity_${item.activity_id}&idx=${index}&len=${activityList.length}&mod=stop&evt=click`
  }

  onclick(index: number) {
    this.$emit('activeKeyChange', index)
  }
}
