



















import { Component, Vue } from 'nuxt-property-decorator'
import camelCase from 'lodash/camelCase'
import upperFirst from 'lodash/upperFirst'
import SwitchLoadState from '~/components/setion/experience/components/switch-load-state.vue'
// 注册楼层组件，注册名即是displayName
import SectionFailure from '~/components/setion/k-section-state/k-section-failure/desktop.vue'
import Loading from '~/components/setion/k-section-state/section-loading.vue'
import SectionBase from '~/components/setion/experience/base/section-base'

@Component({
  components: {
    SwitchLoadState,
    SectionFailure,
    Loading
  }
})
export default class MExperienceSectionMain extends SectionBase {
  // 获取业务组件名称，动态渲染组件
  get componentName() {
    const { handler, data_type = '' } = this.sectionContent

    const dataType = data_type.split(':').map(upperFirst).join('_')
    const name = upperFirst(camelCase(handler)) + dataType

    // 优先返回注册的平台组件
    const { platform } = this.klook
    let component = platform === 'desktop' ? `Desktop${name}` : `Mobile${name}`

    if (['PoiDetail', 'PoiList'].includes(this.$route?.name! || '')) {
      component = `Mobile${name}`
    }

    if (Vue.component(component)) {
      return component
    }

    // mobile、desktop都适用的组件
    if (Vue.component(name)) {
      return name
    }

    this.handleHideSection()
    return null
  }

  get componentNameList() {
    if (!this.componentName) {
      return []
    }

    const list = [{
      name: this.componentName, slot: 'default'
    }]

    const placeHolder = this.section?.meta?.placeholder as any

    let loadingComponentName = this.componentName + 'Loading'

    if (placeHolder === false) {
      loadingComponentName = ''
    } else if (!(this.$options.components as any)[loadingComponentName]) {
      loadingComponentName = 'Loading'
    }

    let failureComponentName = this.componentName + 'Failure'
    if (!(this.$options.components as any)[failureComponentName]) {
      // failureComponentName = 'ExperienceCommonFailure'
      failureComponentName = 'SectionFailure'
    }

    return list.concat([{
      name: loadingComponentName, slot: 'loading'
    }, {
      name: failureComponentName, slot: 'failure'
    }])
  }

  get loadingState() {
    return this.state || (this.isAsync ? 'loading' : 'success')
  }

  handleHideSection() {
    this.hideSection && this.hideSection()
  }

  changeSectionState(state: string) {
    this.setSectionState && this.setSectionState(state)
  }

  created() {
    // 添加类型，否则编辑器找不到初值
    const { load_type: loadType = '', data, src = '' } = this.sectionContent

    if (loadType === 'async') {
      this.changeSectionState('loading')
    }

    // 公共处理没有数据隐藏楼层
    const isSyncDataEmpty = loadType === 'sync' && !data
    const isAsyncDataEmpty = loadType === 'async' && !src
    if (!this.sectionContent || isSyncDataEmpty || isAsyncDataEmpty) {
      this.handleHideSection()
    }
  }
}
