











import { Component, Vue, State, Prop } from 'nuxt-property-decorator'
import TextShow from './components/text-show.vue'

@Component({
  components: {
    TextShow
  }
})

export default class CalenderSwitcher extends Vue {
  @State klook!: Data.Klook
  @Prop() value!: boolean
  @Prop() isPackageSchedule!: boolean
  @Prop() packageName!: string
}
