




























import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import toNumber from 'lodash/toNumber'

@Component
export default class CardTitleScore extends Vue {
  @State klook!: Data.Klook
  @Prop() reviewData!: any

  showBookedNumber = true

  get reviewScore() {
    return toNumber(this.reviewData?.score)
  }

  get isNewActivity() {
    return !!this.reviewData?.description
  }

  get reviewNumber() {
    return this.reviewData?.number || ''
  }

  get star() {
    if (Number.isInteger(this.reviewScore)) {
      return `${this.reviewScore}.0`
    }

    return this.reviewScore
  }

  get bookedNumber() {
    return this.reviewData?.booked || ''
  }

  get showScore() {
    return !this.isNewActivity && (this.reviewScore > 0 || this.reviewNumber || this.bookedNumber)
  }
}
