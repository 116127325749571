import { Vue } from 'nuxt-property-decorator'

interface Comp {
  name: string
  content: any
}

/**
 * 业务组件注册函数
 * @param path 业务组件目录路径
 */
export default function register(requireComponent: any, platform?: 'mobile' | 'desktop') {
  // @ts-ignore
  const components = requireComponent.keys().reduce((acc: Comp[], fileName: string) => {
    // 获取组件配置
    const componentConfig = requireComponent(fileName)
    // 获取组件名
    const componentName = componentConfig?.default?.displayName || componentConfig?.displayName

    if (!componentName) { return acc }

    const componentNameLowerCase = componentName.toLocaleLowerCase()

    // 组件适用所有平台
    const isAllPlatform = !/mobile|desktop/.test(componentNameLowerCase)

    // 注册平台相关组件，否则会将两个平台的代码一起打包进来
    const isValid = isAllPlatform || !platform || componentNameLowerCase.includes(platform)
    if (isValid) {
      return acc.concat({
        name: componentName,
        content: componentConfig.default || componentConfig
      })
    } else {
      // eslint-disable-next-line no-console
      // console.error('请为[%s]添加组件名称: static displayName: string = %s', componentName, componentName)
      return acc
    }
  }, [])
  components.forEach((item: Comp) => {
    Vue.component(item.name, item.content)
  })
}
