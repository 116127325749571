import { Vue, Component, Prop, State, Provide } from 'nuxt-property-decorator'
import merge from 'lodash/merge'
import { HomePage } from '~/types/traveller/home-page'
import { DefaultFieldsConfig } from '~/share/data/experience/fieldsConfigUtils.js'
@Component
export default class SectionBase extends Vue {
  @State klook!: Data.Klook
  @Prop() hideSection!: Function
  @Prop() setSectionState!: Function
  @Prop() state!: string
  @Prop() section!: HomePage.SectionItem

  // 楼层数据
  get sectionData() {
    return this.section || {}
  }

  get sectionBody(): HomePage.SectionBody {
    return this.sectionData.body || {}
  }

  get sectionContent(): HomePage.BodyContent {
    return (this.sectionBody as any).content || {}
  }

  get businessData(): any {
    return (this.sectionContent as any).data || {}
  }

  get isAsync() {
    return this.sectionContent.load_type === 'async'
  }

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  handleHideSection() {
    this.hideSection && this.hideSection()
  }

  changeSectionState(state: string) {
    this.setSectionState && this.setSectionState(state)
  }

  @Provide('fieldsConfig2section')
  get fieldsConfig2section() {
    const arr = this.sectionData.fields || []
    const obj = arr.reduce((o: any, o2: any) => {
      o[o2.key] = o2.value
      return o
    }, {})
    let { activity_package_promotion } = obj || {}
    if (typeof activity_package_promotion === 'string') {
      // 特殊处理：desktopBanner
      const activity_package_promotionMaps: any = {
        activity_package_promotion: 1,
        activity_custom_config_hide_promotions_banner: 0
      }
      activity_package_promotion =
        activity_package_promotionMaps[activity_package_promotion] || 0
    }
    return merge({}, DefaultFieldsConfig, obj)
  }

  get sectionVisible() {
    const loadingWithoutPlaceholder =
      (this.section?.meta?.placeholder as any) === false &&
      this.state === 'loading'
    return !loadingWithoutPlaceholder
  }
}
