



























































import { Component, Vue, Prop, Inject } from 'nuxt-property-decorator'
import { FunctionalComponentOptions } from 'vue'
import { SectionTypes, HeadStyle, HeadTag, SectionParams } from '../types'

const HeadTagCom: FunctionalComponentOptions = {
  functional: true,
  props: ['size'],
  render(h, ctx) {
    return h(ctx.props.size || 'h2', ctx.data, ctx.children)
  }
}

@Component({
  components: {
    HeadTagCom
  }
})
export default class Common extends Vue {
  @Prop({ type: String, default: '' }) platform!: string
  @Prop({ type: Number, default: null }) headStyle!: HeadStyle
  @Prop({ type: String, default: '' }) headTag!: HeadTag
  @Prop({ type: Object, default: () => ({}) }) containerData!: SectionTypes.ContainerCommonProps

  @Inject({ default: () => ({}) }) sectionParams!: SectionParams

  moreLoading = false

  get trueSectionParams() {
    const injectSectionParams: SectionParams = this.sectionParams || {}

    // 优先prop 其次inject 再次default
    return {
      platform: this.platform || injectSectionParams.platform || 'desktop',
      headStyle: this.headStyle || injectSectionParams.headStyle || HeadStyle.default,
      headTag: this.headTag || injectSectionParams.headTag || 'h2'
    }
  }

  get wrapperClass() {
    return `common-container-${this.trueSectionParams.platform}`
  }

  get title() {
    return this.containerData.title
  }

  get subtitle() {
    return this.containerData.sub_title
  }

  get cornerButtonSpm() {
    return this.containerData.corner_button_spm || {}
  }

  get moreSpm() {
    return {
      ...(
        this.containerData.more_spm_module ? {
          'data-spm-virtual-item': '__virtual',
          'data-spm-module': this.containerData.more_spm_module
        } : {}
      ),
      ...this.containerData.more_spm || {}
    }
  }

  get cornerButtonDeepLink() {
    return this.containerData.corner_button_deep_link
  }

  get cornerButtonTitle() {
    return this.containerData.corner_button_title
  }

  get cornerTarget() {
    return this.containerData.corner_target || ''
  }

  get showCorner() {
    return this.cornerButtonDeepLink && this.cornerButtonTitle
  }

  get moreTitle() {
    return this.containerData.more_title
  }

  get moreDeepLink() {
    if (this.isLoadMoreMode) {
      return 'javascript:;'
    }

    return this.containerData.more_deep_link
  }

  get showHeader() {
    return this.title || this.subtitle || this.showCorner
  }

  // 加载下一页接口
  get isUseLoadMore() {
    return this.containerData.more_action_type === 'load_more'
  }

  // 假分页
  get isFakePagination() {
    return this.containerData.more_action_type === 'fake_pagination'
  }

  // 加载更多
  get isLoadMoreMode() {
    return this.isUseLoadMore || this.isFakePagination
  }

  get showMore() {
    if (this.moreTitle) {
      if (this.isLoadMoreMode) {
        // 下一页接口模式
        if (this.isUseLoadMore) {
          return !!this.containerData.more_load_link
        }

        // 假分页模式
        if (this.isFakePagination) {
          return !!this.containerData.more_title
        }
      }

      // 跳转链接模式
      return !!this.containerData.more_deep_link
    }

    return false
  }

  async viewMoreHandler(e: Event) {
    if (this.isLoadMoreMode && !this.moreLoading) {
      // 假分页翻页
      if (this.isFakePagination && this.containerData.fake_pagination_handle) {
        this.containerData.fake_pagination_handle()
      }

      // 真分页翻页
      if (this.isUseLoadMore && this.containerData.more_load_handler) {
        this.moreLoading = true
        await this.containerData.more_load_handler().finally(() => {
          this.moreLoading = false
        })
      }
    }
    this.$emit('onClickViewBtn', e)
  }
}
