














import { Vue, Component, Prop } from 'nuxt-property-decorator'
import merge from 'lodash/merge'
import { KlkAtomicBasicTag as KlkPromoTag } from '@klook/klook-card'
import '@klook/klook-card/dist/esm/index.css'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    KlkPromoTag
  }
})
export default class Index extends Vue {
  @Prop({
    type: Object,
    default: null
  }) track!: any

  @Prop({
    type: Object,
    default: null
  }) pkg!: any

  @Prop({
    type: Array,
    default() {
      return []
    }
  }) list!: any[]

  get calcList() {
    return Array.isArray(this.list) ? this.list : []
  }

  getTrackObj(obj: any) {
    return getTaggingTrackObj(obj)
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }
}

export const getTaggingExtraTrackObj = (list: any[]) => {
  const arr = list || []
  const extra: any = arr.reduce((o: any, o2: any) => {
    o.TagIDList.push(o2?.track?.tag_id || '')
    o.TagKeyList.push(o2?.track?.tag_key || '')
    return o
  }, { TagIDList: [], TagKeyList: [] })
  return extra
}

export const getTaggingTrackObj = ({ track, pkg, list }: any) => {
  const mergeTrack = track || {}
  if (!mergeTrack?.spm) { return }
  const { package_id, packageId } = pkg || {}
  const pid = package_id || packageId
  const extra: any = getTaggingExtraTrackObj(list)

  const def = {
    type: 'module',
    spm: '', // 必需外部传入 Package_Product_Tag
    exposure: true,
    action: false,
    query: {
      oid: pid ? `package_${pid}` : undefined,
      ext: encodeURIComponent(JSON.stringify(extra))
    }
  }
  return merge(def, mergeTrack)
}
