










import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class SectionFailure extends Vue {
  @Prop() refresh!: Function

  handleRefresh() {
    this.refresh && this.refresh()
  }
}
