










import { Component, Prop } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import BottomSoldOut from '~/components/experience-booking/experience-activity/bottom-sold-out.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    BottomSoldOut
  }
})
export default class ActivityContact extends BusinessBase {
  @Prop() basicData!: ExperienceActivity.IBasicData

  static displayName = 'MobileBottom_button'

  get content() {
    return this.businessData.bottom_button || this.$t('activity.unpublish_btn_text')
  }

  get link() {
    return this.businessData.link || ''
  }

  get linkTitle() {
    return this.businessData.link_title || ''
  }

  get cityId() {
    return this.basicData.city_id
  }

  get activityId() {
    return this.basicData.activity_id
  }
}
