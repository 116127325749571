


























































































import { Component, Prop, State, namespace } from 'nuxt-property-decorator'
import { Activity } from '~/types/traveller/activity'
import DesktopPriceSlot, { getPreUnitPriceData } from '~/components/experience/price-slot/desktop/index.vue'
import { formatCurrencySymbolPriceThousands } from '~/share/utils'
import ExpTagging from '~/components/experience/tagging/index.vue'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'
import PublishInfoBase from '~/components/common/publish-info/publish-info-base'
import CutDown from '~/components/experience/cut-down/index.vue'
import { isLessThanTargetTime } from '~/share/count-down'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    ExpTagging,
    DesktopPriceSlot,
    WarmingSlogan,
    CutDown
  }
})
export default class PackageOptionsCounter extends PublishInfoBase {
  @ActivityModule.State preSettlementApiData!: any
  @State klook!: Data.Klook
  @Prop() tipsType!: 'tips-flex-column'
  @Prop() selectedTime!: { arrangementId: number; stock: number }
  @Prop() currentSelectedPackage!: any
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: () => ({}) }) unit!: Activity.unit

  get calcPreUnitPrice() {
    const { has_discount } = this.currentSelectedPackage
    const preData = this.preSettlementApiData?.result
    return (unit: any) => {
      const marketPrice = unit.marketPrice || ''
      const isb = marketPrice && Number(unit.sellingPrice) < Number(marketPrice) // 旧样式 原有逻辑
      const d = getPreUnitPriceData({ ...unit, has_discount }, preData, {
        oldPriceShow: {
          market_price_text: isb ? formatCurrencySymbolPriceThousands(this.klook.currency, marketPrice) : '',
          original_price_text: formatCurrencySymbolPriceThousands(this.klook.currency, unit.sellingPrice)
        }
      })
      return d
    }
  }

  getCalcTags2sku(unit: any) {
    // desktop活动页-unit
    const discountTags = unit?.product_tags?.discount_tags || []
    const tagsList: any = []
    if (discountTags?.length) {
      discountTags.forEach((item: any) => {
        if (item?.inner_vars?.rate_plan?.count_down_end_time) {
          const flag = isLessThanTargetTime(item.inner_vars.rate_plan.count_down_end_time)
          flag && tagsList.push(item)
        } else {
          tagsList.push(item)
        }
      })
    }

    return tagsList
  }

  getCutDownlist(unit: any) {
    const tags = this.getCalcTags2sku(unit)
    const list: any = []

    if (tags?.length) {
      tags.forEach((item: any) => {
        const endTime = item?.inner_vars?.rate_plan?.count_down_end_time
        endTime && list.push(endTime)
      })
    }

    return list
  }

  get marketPrice() {
    if (this.unit.isInPromotion) {
      return this.unit.originalPrice
    }

    if (this.unit.marketPrice) {
      return this.unit.marketPrice
    }

    return ''
  }

  get isSoldOut() {
    return this.unit?.isSoldOut && !this.isWarming && !this.isSuspended
  }

  soldOutColor(soldOut: boolean) {
    return soldOut ? 'sold-out-color' : ''
  }

  reload() {
    window && window.location.reload()
  }
}
