













import { Component, Vue, Prop } from 'nuxt-property-decorator'
import UspTag from './usp-tag.vue'

interface TagType {
  icon: string,
  content: string,
  backgroud: string,
  text: string
}

@Component({
  components: {
    UspTag
  }
})
export default class UspTagList extends Vue {
  @Prop() tagList!: TagType[]
  @Prop() type!: string

  getExtra(item: TagType) {
    return this.$getExt({
      Text: item.text,
      type: this.type
    })
  }

  get len() {
    return this.tagList.length
  }
}
