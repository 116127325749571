import { Component, State, Vue, Getter } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { Schedule } from './type'
import { getStandardDateFormat } from '~/share/data/datetime'
@Component
export default class ComboPackageOptionsTime extends Vue {
  @State klook!: Data.Klook
  @Getter timeFormatString!: string

  setTimeFormat(date: any) {
    return dayjs(date).format(this.timeFormatString)
  }

  formatTime(time: any) {
    return time?.date ? this.setTimeFormat(time.date) : ''
  }

  formatDay(date: string) {
    const d = getStandardDateFormat(date, this.$t.bind(this), this.klook.language, 1)
    return d
  }

  isDay(days: Schedule) {
    if (days?.time_slots) {
      if (days?.time_slots?.length > 1) {
        return false
      } else {
        return days.time_slots[0].time_slot === '00:00:00'
      }
    }
  }
}
