import { render, staticRenderFns } from "./empty-status.vue?vue&type=template&id=121d85a6&scoped=true&"
import script from "./empty-status.vue?vue&type=script&lang=ts&"
export * from "./empty-status.vue?vue&type=script&lang=ts&"
import style0 from "./empty-status.vue?vue&type=style&index=0&id=121d85a6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "121d85a6",
  null
  
)

export default component.exports