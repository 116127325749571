import { Vue, Component } from 'nuxt-property-decorator'
import { merge } from 'lodash'
import apis from '~/share/data/apis'
import dataUtils from '~/components/booking-core/utils/data-utils'
import * as dataTypesUtils from '~/components/booking-core/utils/types'

@Component({
  components: {
    //
  }
})

// attraction业务的结构化的pkg-detail
export default class StructuredPkgDetail extends Vue {
  structuredPackageDetailApiDefined: dataTypesUtils.ApiDefinedIf = {
    isCache: true,
    state: '',
    reloadConfig: {
      isReady: true,
      delay: 240,
      state: ''
    },
    url: apis.getAttractionPackageDetail, // 结构化的pkg-detail的url
    params: {
      is_line_page: false,
      translation: false,
      package_id: 0,
      preview: 0
    },
    apiData: null
  }

  get calcStructuredPkgDetailObj() {
    // 当前套餐详情及拓展数据
    const { apiData } = this.structuredPackageDetailApiDefined
    const detail = apiData?.result?.[0]
    if (!detail) {
      return {}
    }
    detail.use_section = true
    return detail
  }

  async getStructuredPackageDetailApiData(paramsData: any, mergeDefined?: any) {
    // 获取套餐详情接口
    const { package_id_list } = paramsData || {}
    if (!package_id_list) {
      return
    }
    const { structuredPackageDetailApiDefined } = this
    structuredPackageDetailApiDefined.params = paramsData
    merge(structuredPackageDetailApiDefined, mergeDefined || {})
    const apiData = await dataUtils.getApiDataHandler(this, structuredPackageDetailApiDefined)
    return apiData
  }
}
