























































import { Vue, Component, State, Prop, Watch } from 'nuxt-property-decorator'

const bigImageStyle = {
  'max-width': '960px',
  width: 'auto',
  height: 'auto',
  position: 'static',
  transform: 'none'
}

@Component({
  components: {}
})

export default class ActivityPackageDetailBigImages extends Vue {
  @State klook!: Data.Klook
  @Prop() images!: any[]
  @Prop({ default: 0 }) index!: number
  visible: boolean = true
  currentIdx: number = 0
  canMove: boolean = false
  imgLeft: number = 0
  imgTop: number = 0
  imageSize: number = 0
  imageStyle: any = {
    'max-width': '960px',
    height: 'auto'
  }

  @Watch('imageSize')
  onImageSizeChange(val: number) {
    if (val) {
      const w = (this.$refs.bigImage as any).naturalWidth
      const h = (this.$refs.bigImage as any).naturalHeight
      this.imageStyle = w > h ? {
        width: 'auto',
        height: '100%'
      } : {
        height: 'auto',
        width: '100%'
      }
    } else {
      this.initSize()
    }
  }

  changeIdx(type: string) {
    this.imageSize = 0
    if (type === 'add') {
      const a = this.currentIdx += 1
      this.currentIdx = a >= this.images.length ? 0 : a
    } else {
      const a = this.currentIdx -= 1
      this.currentIdx = a < 0 ? this.images.length - 1 : a
    }
    this.initSize()
  }

  initSize() {
    if ((this.$refs.bigImage as HTMLImageElement).naturalWidth > 960) {
      this.checkSize()
    } else {
      this.imageStyle = {
        'max-width': '960px',
        width: 'auto',
        height: 'auto',
        left: '50%',
        top: '50%'
      }
    }
  }

  mouseDown(e: any) {
    this.canMove = true
    const moveElemRect = e.target.getBoundingClientRect()
    this.imgLeft = e.clientX - moveElemRect.left // 鼠标按下时和选中元素的坐标偏移:x坐标
    this.imgTop = e.clientY - moveElemRect.top // 鼠标按下时和选中元素的坐标偏移:y坐标
  }

  mouseUp() {
    this.canMove = false
  }

  moveImage(e: any) {
    if (this.canMove) {
      const t = this.$refs.bigImage as HTMLElement
      const moveX = e.clientX - this.imgLeft
      const moveY = e.clientY - this.imgTop

      t.style.left = (moveX + Math.round(t.offsetWidth / 2) - 25) + 'px'
      t.style.top = (moveY + Math.round(t.offsetHeight / 2) - 25) + 'px'
    }
    e.cancelBubble = true
    e.returnValue = false
  }

  // 因为使用了组件库的组件，所以需要隐藏放大缩小按钮中间的 input
  hideElement() {
    this.$nextTick(() => {
      const input = document.querySelector('.activity-package-detail-big-images .footer .klk-counter-input') as HTMLElement
      input.style.display = 'none'
    })
  }

  close() {
    this.visible = false
    this.$emit('close')
  }

  checkSize() {
    if (this.images[this.currentIdx].width > 960) {
      this.imageStyle = bigImageStyle
    }
  }

  mounted() {
    this.visible = true
    this.currentIdx = this.index
    this.hideElement()
    this.checkSize()
  }
}
