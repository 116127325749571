









































































import { Component, State, Watch, Prop, mixins } from 'nuxt-property-decorator'
import PackageDetailModule
  from '~/components/traveller/activity/activity-package-options/desktop/package-detail/detail-module.vue'
import PackageDetailContent
  from '~/components/traveller/activity/activity-package-options/desktop/package-detail/detail-content.vue'
import { PackageDetailStructured } from '~/components/common/package-detail-v2'
import newPackageDetailShowMixin from '~/components/common/package-detail-v2/show-mixin'
import { nodeScrollTop } from '~/share/scroll2/scroll2'
import { Activity } from '~/types/traveller/activity'
import { getCss } from '~/share/utils'

@Component({
  components: {
    PackageDetailModule,
    PackageDetailContent,
    PackageDetailStructured
  }
})

export default class ActivityPackageDetail extends mixins(newPackageDetailShowMixin) {
  @State klook!: Data.Klook
  @Prop() packageId!: number
  @Prop() packageDetail!: Activity.packageDetail
  @Prop() fetchPackageDetailStatus!: string
  @Prop() currentSelectedPackage!: Activity.activityPackage
  @Prop() specList!: Activity.spec[]
  @Prop() isPresale!: boolean
  @Prop({ default: true }) expandAll!: boolean

  detailModuleVisible: boolean = false
  detailsTitleList: HTMLElement[] = []
  packageDetailStyle: any = {}

  specBoxObj: any = {
    isInit: false,
    el: '.js-activity-package-options',
    styles: {
      height: 'auto'
    }
  }

  handleScrollTo(element: any = null) {
    setTimeout(() => {
      const main = document.querySelector('#slide-package-detail') as HTMLElement
      const elem = (document.querySelector(element) as HTMLElement).parentNode as HTMLElement
      if (elem) {
        const offsetTop = elem.offsetTop - 10
        main.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        })
      }
    }, 400)
  }

  @Watch('fetchPackageDetailStatus')
  fetchPackageDetailStatusChange(val: string) {
    if (val === 'success') {
      this.$nextTick(() => {
        this.detailsTitleList = (document.querySelectorAll('#slide-package-detail .act-side-package-detail-title') as any)
        this.resetDetailPosition()
      })
    }
  }

  moduleClose() {
    this.$nextTick(() => {
      this.detailModuleVisible = false
    })
  }

  showModule() {
    this.detailModuleVisible = true
  }

  skipToTitle(idx: number) {
    const detailsDom = (document.querySelectorAll('#slide-package-detail .act-side-package-detail') as any) || []
    const top: number = detailsDom[idx] ? detailsDom[idx].offsetTop : 0
    const d = (document.querySelector('#slide-package-detail') as HTMLElement)
    d.scrollTo(0, top)
  }

  resetDetailPosition() {
    const rightPrice = document.querySelector('.js-scroll-right-price-wrap') as HTMLElement
    if (rightPrice) {
      const rightPriceScrollTop = nodeScrollTop(rightPrice) + rightPrice.offsetHeight
      const packageDetail = this.$refs.activityPackageDetail
      const packageDetailScrollTop = nodeScrollTop(packageDetail)
      if (rightPriceScrollTop > packageDetailScrollTop) {
        this.packageDetailStyle = {
          top: (rightPriceScrollTop - packageDetailScrollTop + 30) + 'px'
        }
      }
    }
  }

  spm(text: string) {
    return text.replace(/(^\s*)|(\s*$)/g, '')
  }

  mounted() {
    this.initCheckSpecBox()
  }

  initCheckSpecBox() {
    const { specBoxObj } = this
    if (process.server || specBoxObj.isInit) {
      return
    }
    const { el, styles } = specBoxObj
    const specDom: any = document.querySelector(el)
    if (!specDom) {
      return
    }
    const height = parseInt(getCss(specDom, 'height'))
    if (height <= 800) {
      styles.height = '800px'
    } else if (height >= 1200) {
      styles.height = '1200px'
    } else {
      styles.height = `${height}px`
    }
    specBoxObj.isInit = true
  }

  handlePickUpClick() {
    if (process.server) {
      return
    }

    this.detailModuleVisible = true
    setTimeout(() => {
      const pickUpSearch: any = document.querySelector('.js-activity-package-detail-module .js-search-pick-up-keywards-desktop')
      pickUpSearch && pickUpSearch.scrollIntoView({ block: 'start' })
    }, 600)
  }
}
