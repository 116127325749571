import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { merge, cloneDeep } from 'lodash'

@Component
export default class Index extends Vue {
  @Prop({ default() { return {} } }) priceData!: any
  @Prop({ default: '' }) priceType!: string
  @Prop({ default: '' }) styleType!: string | 'flex-center'

  @Prop({
    default() {
      return []
    }
  }) orderList!: string[]

  priceMaps: any = {
    unit: [
      { propKey: 'market_price_text', className: 'crossed-price', note: '划线价-旧样式-原页面的市场价' },
      { propKey: 'original_price_text', className: 'salling-price', note: '售卖价-旧样式-原页面的售价' },
      { propKey: 'original_selling_price_text', className: 'before-discount-price', note: '折扣前的价格-新样式' },
      { propKey: 'selling_price_text', className: 'discount-price', note: '折扣价-新样式' },
      { propKey: 'saved_text', className: 'saved-price', note: 'addon-节省价' }
    ]
  }

  get priceMapList() {
    return this.priceMaps[this.priceType]
  }

  get priceList() {
    const { orderList, priceMapList } = this
    // this.priceData = testPriceData // test code
    const pd = this.priceData || {}
    const arr = orderList.filter((propKey: string) => {
      return pd[propKey]
    })
    return arr.map((propKey: string) => {
      const value = pd[propKey]
      const obj = priceMapList.find((o: any) => o.propKey === propKey) || {}
      return {
        ...obj,
        value
      }
    })
  }
}

export function getPreUnitPriceData(unitData: any, preData: any = null, mergeData: any = {}) {
  unitData = unitData && cloneDeep(unitData)
  preData = preData && cloneDeep(preData)
  const defData = {
    _hasDiscount: false,
    _openKey: 'has_discount',
    _priceKey: 'sku_list',
    oldPriceShow: {}
  }
  const data = merge(defData, mergeData)
  data._hasDiscount = !!unitData[data._openKey]
  const { _hasDiscount, _priceKey, oldPriceShow } = data
  const skuList = preData ? preData[_priceKey] : []
  const priceShow = skuList.find((o: any) => o.sku_id === unitData.skuId)
  // if (data) { // test code
  //   return oldPriceShow.original_price_text ? merge(priceShow, oldPriceShow) : priceShow
  // }
  if (!_hasDiscount || !preData) {
    return oldPriceShow.original_price_text ? merge(priceShow, oldPriceShow) : priceShow
  }

  return merge({}, priceShow)
}

export const testPriceData = { market_price_text: '￥280.6', original_price_text: '￥260.6', original_selling_price_text: 'was ￥40', selling_price_text: '￥200.2' }
