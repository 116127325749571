








































import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class Skeleton extends Vue {
  @Prop() loading!: boolean
  @Prop() platform!: string
}
