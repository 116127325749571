

































































































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import StyleDefault from './components/style-default.vue'
import StyleThree from './components/style-three.vue'
import StyleThreeRight from './components/style-three-right.vue'
import StyleThreeLeft from './components/style-three-left.vue'

import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    StyleDefault,
    StyleThree,
    StyleThreeRight,
    StyleThreeLeft
  }
})
export default class ImageGalleryCustomer extends Vue {
  @Prop({ default: () => [] }) images!: any []
  @Prop() photoType!: string

  get inhouseData() {
    return handleIHTrack({
      type: 'module',
      exposure: false,
      spm: 'GalleryPhotoEnlarge',
      query: {
        ext: JSON.stringify({ photo_type: this.photoType })
      }
    })
  }

  preview(id: number) {
    this.$emit('preview', id)
  }
}
