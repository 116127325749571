


















import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component
export default class BreadCrumb extends BusinessBase {
  static displayName = 'BreadCrumb'
  get breadcrumb() {
    return this.businessData
  }

  get cClass() {
    const name = 'activity-bread-crumb'
    return [name, `${name}-${this.klook.platform}`]
  }
}
