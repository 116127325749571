









































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import { IconTriangleDown } from '@klook/klook-icons'
import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2'
import { IGroup } from '~/components/experience-booking/experience-activity/package-options/mobile/group-package-options/type'
import eventBus from '~/pages/experience/pay/common/event-bus'

type IListItem = {
  label: string
  value: number
} & IGroup

@Component({
  components: {
    IconTriangleDown
  }
})
export default class GroupSwiperList extends Vue {
  @Prop() update!: boolean
  @Prop() shouldShowPanel!: boolean
  @Prop() activeKey!: number
  @Prop() activityId!: number
  @Prop() list!: IListItem[]
  @Prop() setActive!: (index: number) => void
  @Prop() date!: string

  visiable = false
  // 是否展示all 按钮
  showAll = false

  isTextOverflowed = false

  eleList: HTMLElement[] = []
  height = '66px'
  fixedTop = 108
  navWidth = 64
  curIndex = 0

  @Watch('activeKey')
  scrollwith(val: number) {
    this.tagScroll(val)
    const curIndex = this.list.findIndex((item) => item.value === this.activeKey)
    this.curIndex = curIndex
  }

  mounted() {
    this.eleList = this.list.map(
      (item) => document.getElementById(`group-swiper-listItem-${item.value}`) as HTMLElement
    )
    eventBus.$on('group-on-active', this.reset)
    this.$nextTick(() => {
      this.checkTextOverflow()
    })
  }

  beforeDestroy() {
    eventBus.$off('group-on-active', this.reset)
  }

  // 当顶部导航栏出现的时候需要滚动
  @Watch('shouldShowPanel')
  checkScroll(val: boolean) {
    if (val) {
      this.checkTextOverflow()
      setTimeout(() => {
        this.tagScroll(this.activeKey)
      }, 300)
    }
  }

  checkTextOverflow() {
    const container = this.$refs.list as HTMLElement
    let total = 0
    let isTextOverflowed = false
    this.eleList.forEach((element) => {
      if (element) {
        const textWidth = element.firstElementChild?.clientWidth as number
        if (textWidth >= 174) {
          isTextOverflowed = true
        }
        total = total + element.clientWidth + 12
      }
    })

    this.isTextOverflowed = isTextOverflowed
    this.height = isTextOverflowed ? '66px' : '45px'
    this.showAll = container.clientWidth - total - this.navWidth < 0
  }

  reset(val: number) {
    this.$emit('update:lockScroll', true)
    this.onItemlick(val)
  }

  onItemlick(activeKey: number) {
    this.setActive(activeKey)
    this.$emit('update:lockScroll', true)
    this.$nextTick(() => {
      this.listScroll(activeKey)
    })
  }

  scrollLeftTo(scroller: HTMLElement, to: number, duration: number) {
    let count = 0
    const from = scroller.scrollLeft
    const frames = duration === 0 ? 1 : Math.round((duration * 1000) / 16)

    function animate() {
      scroller.scrollLeft += (to - from) / frames

      if (++count < frames) {
        window.requestAnimationFrame(animate)
      }
    }
    animate()
  }

  tagScroll(activeKey: number) {
    if (!this.showAll) {
      return
    }
    const swiper = this.$refs.list as HTMLElement
    const element = this.eleList.find((item) => item.id === `group-swiper-listItem-${activeKey}`)
    if (element) {
      // 容器宽度为 calc(100% - 38px)
      const containerWidth = swiper.offsetWidth - this.navWidth
      const elementWidth = element.offsetWidth
      const elementOffsetLeft = element.offsetLeft
      const to = elementOffsetLeft - (containerWidth - elementWidth) / 2
      this.scrollLeftTo(swiper, to, 0.2)
    }
  }

  tagMove() {
    const swiper = this.$refs.list as HTMLElement
    const element = this.eleList[this.curIndex]
    if (element) {
      const nextItemOffset = element.offsetLeft
      this.scrollLeftTo(swiper, nextItemOffset, 0.2)
    }
  }

  listScroll(activeKey: number) {
    // 宽度
    const element = document.getElementById(`group-render-list-${activeKey}`)
    if (element) {
      const index = this.list.findIndex((item) => item.value === activeKey)
      let top = 0
      // 回到第一个tab比较特殊，特殊处理
      const packageOption = document.getElementById('package_options_group')
      if (index === 0) {
        top = nodeScrollTop(packageOption)
      } else {
        top = nodeScrollTop(element) - (this.isTextOverflowed ? 200 : 180)
      }

      scroll2({
        scrollY: top,
        duration: 150,
        callback: () => {
          setTimeout(() => {
            this.$emit('update:lockScroll', false)
          }, 300)
        }
      })
    }
  }

  getSpm(item: IListItem, index: number, length: number) {
    const ext = { GroupTitle: item.name, Groupid: item.track_id }
    return `GroupOption_LIST?&oid=group_${item.track_id}&ext=${this.$getExt(
      ext
    )}&idx=${index}&len=${length}&mod=stop&evt=click`
  }

  getClickItem(ClickType: string) {
    const ext = { ClickType }
    return `GroupOptionAll?ext=${this.$getExt(ext)}&mod=stop&evt=click&trg=manual`
  }

  get preDisabel() {
    return this.curIndex < 1
  }

  get rightDisabel() {
    return this.curIndex >= this.list.length - 2
  }

  movePrev() {
    if (this.preDisabel) {
      return
    }
    this.curIndex = this.curIndex - 4 < 0 ? 0 : this.curIndex - 4
    this.tagMove()

    setTimeout(() => {
      this.$inhouse.track('action', this.$refs.left)
    }, 50)
  }

  moveNext() {
    if (this.rightDisabel) {
      return
    }
    const length = this.list.length - 1
    this.curIndex = this.curIndex + 4 > length ? length : this.curIndex + 4
    this.tagMove()
    setTimeout(() => {
      this.$inhouse.track('action', this.$refs.right)
    }, 50)
  }

  open() {
    this.visiable = true
  }

  onItemListlick(index: number) {
    this.setActive(index)
    this.visiable = false
    this.$emit('update:lockScroll', true)
    setTimeout(() => {
      this.listScroll(index)
    }, 400)
  }
}
