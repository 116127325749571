












import { Component, State, Vue, Prop } from 'nuxt-property-decorator'
import { SubSectionComponentImageSlides } from '../type'
import ImageCardSwiperViewer from '~/components/common/image-card-swiper-viewer/index.vue'

@Component({
  components: {
    ImageCardSwiperViewer
  }
})
export default class ImageSlides extends Vue {
  @Prop({ type: Object, default: {} }) data!: SubSectionComponentImageSlides['data']
  @State klook!: Data.Klook

  fieldKey = {
    img_url: 'image_url',
    img_resize_url: 'image_url',
    title: 'image_desc'
  }

  itemGap = 12

  get imageList() {
    return this.data?.images || []
  }

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get itemWidth() {
    const { isDesktop } = this
    return isDesktop ? 210 : 140
  }

  get showSwitchIcon() {
    return !this.isDesktop
  }

  get imageHeight() {
    const { isDesktop } = this
    return isDesktop ? 118 : 105
  }

  get scrollMode() {
    const { isDesktop } = this
    return !isDesktop
  }
}
