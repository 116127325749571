


















import { Component, namespace, mixins } from 'nuxt-property-decorator'
import SectionList from '~/components/experience-booking/experience-activity/section/section-list.vue'
import register from '~/components/setion/experience/register'
import ExperienceActivityBase from '~/components/experience-booking/experience-activity/experience-activity-base'
import Notice from '~/components/common/notice/notice.vue'
import ScrollToTop from '~/components/common/scroll-top/desktop.vue'
import ClarityBase from '~/components/common/clarity'

register(
  (require as any).context(
    '~/components/experience-booking/experience-activity',
    true,
    /(index|loading|failure).vue$/i
  ),
  'desktop'
)
const HeaderSearch = namespace('common/header-search')
const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    Notice,
    SectionList,
    ScrollToTop,
    WaterMarkModal: () => import('~/components/common/water-mark-modal.vue'),
    WaterMark: () => import('~/components/common/water-mark.vue')
  },
  layout({ store }) {
    const { klook } = store.state
    if (klook.utilConfig.whiteLabelUtilConfig) {
      return 'white-label'
    }
    return 'default'
  },
  middleware: ['previewRedirect']
})
export default class ExperienceHome extends mixins(ExperienceActivityBase, ClarityBase) {
  @ActivityModule.State previewExpired !: boolean
  @HeaderSearch.Action setHeaderSearch!: Function

  get subTypeIds() {
    return {
      activityId: this.basicData.activity_id,
      cityId: this.basicData.city_id,
      countryId: this.basicData.country_id
    }
  }

  get showWaterMark() {
    return this.$route?.params?.preview
  }

  initHeaderSearchData() {
    const cityId = encodeURIComponent(this.basicData.city_id)
    const cityName = encodeURIComponent(this.basicData.city_name)
    this.setHeaderSearch({
      placeholder: this.$t('search_activity_at_city', [
        this.basicData.city_name
      ]),
      searchQuery: `type=city&city=${cityId}&src_location_id=${cityId}&city_name=${cityName}`,
      suggestParams: {
        city_id: this.basicData.city_id
      }
    })
  }

  async mounted() {
    this.initHeaderSearchData()
    await null
  }
}
