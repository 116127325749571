




























































import { Component, Watch, Ref } from 'nuxt-property-decorator'
import { IconNext } from '@klook/klook-icons'
import ActivityMarkdown from '~/components/traveller/activity/activity-markdown.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import { getModalConfig } from '~/components/experience/load-hander/index.vue'

@Component({
  components: {
    ActivityMarkdown,
    IconNext
  }
})
export default class ActivityeligibilityTip extends BusinessBase {
  @Ref() openModalRef!: any
  static displayName = 'Eligibility_hint'

  modalObj = getModalConfig('common-medium-config')

  isShowMore = true
  isShowMoreBtn = false
  openModal: boolean = false

  @Watch('openModal')
  onOpenModalChange(val: boolean) {
    if (val && process.client) {
      this.sendPV()
    }
  }

  created() {
    if (!this.businessData || Object.keys(this.businessData).length === 0) {
      this.hideSection()
    }
  }

  async mounted() {
    if (this.foldStyle === 1 || this.foldStyle === 2 || this.foldStyle === 3) {
      const limit = this.klook.platform === 'desktop' ? 52 : 32

      const sevContent2 = document.getElementById('eligibility-content-2')
      if (sevContent2 && sevContent2.scrollHeight > limit) {
        this.isShowMore = true
        this.isShowMoreBtn = true
      } else if (this.foldStyle === 3) {
        this.isShowMoreBtn = true
      } else {
        this.isShowMore = false
      }
    } else {
      this.isShowMore = false
    }
    await null
  }

  get isMobile() {
    return this.klook.platform === 'mobile'
  }

  get popComponent() {
    return this.isMobile ? 'klk-bottom-sheet' : 'klk-modal'
  }

  get viewMoreText() {
    if (this.foldStyle === 3) {
      return this.businessData.fold_text
    }
    return this.isShowMore ? this.$t('city_page_view_more') : this.$t('collapse_all')
  }

  get spm() {
    return (this.sectionData?.meta?.track as any)?.name || 'PromoText'
  }

  get dataSpmPage() {
    return `${this.spm}?trg=manual`
  }

  get foldStyle() {
    return this.businessData.fold_style === undefined ? 1 : this.businessData.fold_style
  }

  get voucherType() {
    return this.businessData.key
  }

  async showMore() {
    const foldStyle = this.foldStyle
    if (foldStyle === 1) {
      this.isShowMore = !this.isShowMore
    }
    if (foldStyle === 3) {
      const { showTc } = await import('@klook/taiwan-coupon-tc')
      const type: any = {
        guolv_voucher: 1,
        yifun_voucher: 2
      }

      if (type[this.voucherType]) {
        const instance = showTc({
          platform: this.klook.platform,
          type: type[this.voucherType],
          $t: (key: string, ...args: any[]) => this.$t(key, ...args),
          $href: (pathname: string, language?: Data.Language, baseLink?: string) => this.$href(pathname, language, baseLink)
        })

        this.$once('hook:beforeDestroy', () => {
          instance.close()
        })
      }
    }
    if (foldStyle === 2) {
      this.openModal = true
    }
  }

  handleShowMore() {
    if (this.isShowMoreBtn && this.isMobile) {
      this.showMore()
    }
  }

  sendPV() {
    setTimeout(() => this.$inhouse.track('pageview', this.openModalRef?.$el, { force: true }), 100)
  }
}
