






import { Component, Vue, namespace } from 'nuxt-property-decorator'

const ActivityModule = namespace('traveller/activity')

@Component
export default class Index extends Vue {
  @ActivityModule.State activityDetail!: any

  get title() {
    const cityName = this.activityDetail?.basic_data.city_name || ''
    return this.$t('76907', { City_name: cityName })
  }
}
