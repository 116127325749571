



































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { Activity } from '~/types/traveller/activity'

@Component
export default class ActivityPromotionInfo extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform

  @Prop() promotionInfoList!: Activity.promotionInfo[]

  genTag(type: string) {
    const tagMap: Record<string, string> = {
      instant_discount: this.$t('16563')
    }
    return tagMap[type]
  }
}
