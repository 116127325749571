













import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { merge } from 'lodash'

@Component
export default class CommonContentRefresh extends Vue {
  @Prop({ type: String, default: 'part' }) type!: string
  @Prop({ type: String, default: undefined }) size!: string
  @Prop({ type: String, default: undefined }) title!: string
  @Prop({ type: String, default: undefined }) content!: string

  getTypeConfig(type: string) {
    const TypeConfig: any = {
      part: {
        size: 100,
        title: '',
        content: this.$t('loading_failed')
      },
      page: {
        size: 200,
        title: 'textid: Now where did we put that page?',
        content: this.$t('loading_failed')
      }
    }
    const conf = TypeConfig[type] || {}
    return conf
  }

  get calcObj() {
    const { type, size, title, content } = this
    const conf = this.getTypeConfig(type)
    const mergeConf = merge({}, conf, { size, title, content })
    return mergeConf
  }
}
