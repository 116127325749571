









































import { Component, Getter, Prop, State, Vue, Provide } from 'nuxt-property-decorator'
import ExperienceActivityPackageDetail from '~/components/experience-booking/activity/package-detail/index.vue'
import BottomBarSettlement from '~/components/traveller/activity/activity-bottom-bar/mobile/bottom-bar-settlement.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'

@Component({
  components: {
    ExperienceActivityPackageDetail,
    BottomBarSettlement
  }
})
export default class ExperiencePackageDetailModule extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: '' }) confirmText!: string
  @Prop() packageData!: ExperienceActivity.IPackageCard
  @Getter useCredits!: boolean
  @Getter isGuestCheckout!: boolean
  @Prop({ default: false }) expandItinerarySection!: boolean
  @Prop({ default: '' }) groupPackName!: string

  bsObj = getBottomSheetConfig('package-details-config')

  visible: boolean = false

  @Provide('closeDetailModule')
  close() {
    this.visible = false
    const a = setTimeout(() => {
      this.$emit('close')
      this.cacheDetail = null
      clearTimeout(a)
    }, 200)
  }

  get creditsVisible() {
    return this.useCredits && !this.isGuestCheckout
  }

  get isInPromotion() {
    return !!this.event?.status
  }

  get event() {
    return this.packageData?.promotion_price?.promotion_event
  }

  onConfirm() {
    setTimeout(() => {
      this.$emit('confirm')
    }, 200)
  }

  @Provide('onlyShowDetailSheet')
  get onlyShowDetailSheet() {
    return !this.showModule
  }

  componentName: string = 'div'
  showModule: boolean = false

  cacheDetail: any = null

  getDetailSuccess(hasItinerary: boolean, detail = null) {
    this.cacheDetail = detail
    if (hasItinerary) {
      this.componentName = 'div'
      this.showModule = false
    } else {
      this.componentName = 'klk-bottom-sheet'
      this.showModule = true
    }
  }

  mounted() {
    this.visible = true
  }
}
