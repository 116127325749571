import { render, staticRenderFns } from "./section-footer.vue?vue&type=template&id=7fcab07e&scoped=true&"
import script from "./section-footer.vue?vue&type=script&lang=ts&"
export * from "./section-footer.vue?vue&type=script&lang=ts&"
import style0 from "./section-footer.vue?vue&type=style&index=0&id=7fcab07e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fcab07e",
  null
  
)

export default component.exports