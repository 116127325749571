
































import { Component, namespace, Vue, Prop, State } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'

const AuthModule = namespace('auth')

@Component
export default class WishButton extends Vue {
  @State klook!: Data.Klook
  @AuthModule.State isLoggedIn!: boolean
  @Prop() activityId!: number | string
  @Prop() reopen!: any

  favorite = false
  activityReopenModalVisible: boolean = false

  async getReopenActivity(data: {
    activity_id: number | string,
    need_reopen_notice: boolean
  }) {
    const res = await this.$axios.$post(apis.updateReopen, data)
    if (res.success) {
      this.triggerReopenModalVisible(false)
      this.setReopenRemind(res.result?.reopen_notice)
    }
  }

  get modalWidth() {
    return this.klook.platform === 'desktop' ? '' : '76%'
  }

  triggerReopenModalVisible(data: boolean) {
    this.activityReopenModalVisible = data
  }

  setReopenRemind(notice: string) {
    this.$emit('changeReopenRemind', notice)
  }

  async getWishStatus() {
    if (!this.isLoggedIn) {
      return
    }

    const res = await this.$axios.$get(apis.getWishStatus, { params: { id: this.activityId } })
    if (res.success && res.result) {
      this.favorite = res.result.favorite
    }
  }

  async handleNoLogin() {
    await this.$loginSDK({
      purpose: 'Wishlist',
      success: async () => {
        await this.wishList()
      }
    })
  }

  showReopen() {
    return this.reopen && this.reopen.tag === 3
  }

  async updateWishList() {
    if (!this.isLoggedIn) {
      await this.handleNoLogin()
    } else {
      await this.wishList()
    }
  }

  async wishList() {
    const updateApi = {
      cancel: { object_id: `${this.activityId}`, object_type: 'act' },
      add: { object_id: `${this.activityId}`, object_type: 'act' }
    }

    const type = this.favorite ? 'cancel' : 'add'
    const apiUrl = this.favorite ? apis.cancelWishList : apis.addWishList
    const res = await this.$axios.$post(apiUrl, updateApi[type])
    if (res.success) {
      this.favorite = !this.favorite
      const gtmAction = this.favorite ? 'Wishlist Added' : 'Wishlist Unlisted'
      this.$sendGTMCustomEvent(`Activity Page|${gtmAction}`)
      if (!this.showReopen()) {
        return null
      }
      if (this.favorite) {
        this.triggerReopenModalVisible(true)
      } else {
        this.getReopenActivity({
          activity_id: this.activityId,
          need_reopen_notice: this.favorite
        })
      }
    } else if (res?.error?.code === '4001') {
      await this.handleNoLogin()
    }
  }

  closeReopen() {
    this.triggerReopenModalVisible(false)
    this.setReopenRemind('')
  }

  sendReopen() {
    this.getReopenActivity({
      activity_id: this.activityId,
      need_reopen_notice: true
    }).then((res: any) => {
      if (res.success) {
        this.$toast(this.$t('activity_reopen_reminder_4') as string)
      }
    })
  }

  async mounted() {
    await this.getWishStatus()
  }
}
