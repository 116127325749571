



























































































import { Component, Prop, Watch } from 'nuxt-property-decorator'
import AttrBase from '~/components/experience-booking/experience-activity/package-options/base/attr-base'
import PackageOptionsContent
  from '~/components/traveller/activity/activity-package-options/desktop/package-options/package-options-content.vue'
import ActivityMarkdown from '~/components/traveller/activity/activity-markdown.vue'
import ActivityPackageBtn
  from '~/components/traveller/activity/activity-package-options/desktop/package-options/activity-package-btn.vue'
import HasOpenDateTips from '~/components/experience-booking/experience-activity/package-options/desktop/has-open-date-tips.vue'
import ShowMoreBox from '~/components/experience-booking/experience-activity/show-more-box/index.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    ShowMoreBox,
    HasOpenDateTips,
    PackageOptionsContent,
    ActivityMarkdown,
    ActivityPackageBtn
  }
})
export default class PackageAttrDesktop extends AttrBase {
  @Prop() basicData!: ExperienceActivity.IBasicData
  @Prop({ default: false }) showOpenDateTips?: boolean
  @Prop({ default: false }) isAbExperiment!: boolean
  @Prop({ default: '' }) specTitle!: boolean
  @Prop({ default: -1 }) activityPageTemplateId!: number

  moreObj: any = {
    len: 8,
    status: ''
  }

  get specListLen() {
    return this.specList?.[0]?.attr?.length || 0
  }

  @Watch('specListLen', { immediate: true })
  specListLenChange(val: any) {
    if (!this.isSingleSpec || this.moreObj.status === 'less') {
      return
    }

    this.moreObj.status = val > this.moreObj.len ? 'more' : ''
  }

  getStatusList(list: any) {
    const res = list || []
    if (this.moreObj.status === 'more') {
      return res.slice(0, this.moreObj.len)
    }
    return res
  }

  clickShowMore(status: string) {
    const { moreObj } = this
    moreObj.status = status
  }

  getDefaultAttr(attrs: any[]) {
    for (let i = 0; i < attrs.length; i++) {
      if (attrs[i].isActive) {
        return attrs[i].id
      }
    }
    return ''
  }

  isDisabled(attr: any) {
    if (this.isAbExperiment) { return false }
    return this.isAttrDisabled(attr)
  }

  sendGaEvent() {
    const { activity_id, activity_page_template_id } = this.basicData || {}
    this.$sendGTMCustomEvent(`Booking Options Clicked|Activity Page|${activity_id || this.activityId}_${activity_page_template_id || this.activityPageTemplateId}`)
  }
}
