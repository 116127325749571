
























import { Component } from 'nuxt-property-decorator'
import PackageDetailSectionBase from '~/components/common/package-detail-v2/sections/base'
import HeightLimitBlock from '~/components/common/package-detail-v2/components/height-limit-block/index.vue'
import PackageDetailSection from '~/components/common/package-detail-v2/sections/components/index.vue'

@Component({
  components: {
    HeightLimitBlock,
    PackageDetailSection
  }
})
export default class PackageDetailExpand extends PackageDetailSectionBase {
}
