























import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import ActivityCardsListWrap
  from '~/components/experience-booking/experience-activity/activity-cards-list/activity-cards-list-wrap.vue'
import BaseActivityCards from '~/components/experience-booking/experience-activity/activity-cards-list/base-cards.vue'

@Component({
  components: {
    BaseActivityCards,
    ActivityCardsListWrap
  }
})
export default class ActivityUnpublishRecommend extends BusinessBase {
  static displayName = 'MobileUnpublished_recommend_activity'

  get activities() {
    const { related_and_recommend_activities_v2, related_and_recommend_activities } = this.businessData || {}
    return related_and_recommend_activities_v2 || related_and_recommend_activities
  }
}
