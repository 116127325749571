import type { SectionType } from '~/components/common/package-detail-v2/type'
import type { SectionData } from '~/components/common/package-detail-v2/sections/type'
import { FOLD_TYPE_MAP } from '~/components/common/package-detail-v2/const.js'

export function transformData(data: SectionType): SectionData {
  const { title, track, fold_type, components } = data
  return {
    title,
    track,
    foldType: fold_type,
    components: (components || []).reduce((accu: any[], curr: any) => {
      const { type, data, active = false } = curr || {}

      // 加上标题
      accu.push({
        title: data?.title,
        track: data?.track,
        heightLimit: data?.props?.height_limit,
        active: fold_type === FOLD_TYPE_MAP.NOT_FOLDED ? true : active,
        components: []
      })

      // 解析 subSection 里面的组件
      if (type === 'image') {
        pushLast('imageSlides', accu, {
          images: data?.images || []
        })
      } else if (type === 'markdown') {
        renderMarkdown(accu, data?.render_obj)
      }

      return accu
    }, [])
  }
}

export function getLast(arr: any[]) {
  return arr?.length ? arr[arr.length - 1] : null
}

export function pushLast(type: string, arr: any[], data: any) {
  // 如果没有 subSection 就丢弃
  if (!arr?.length) {
    return
  }

  const lastSubSection = getLast(arr)
  const lastComponent = getLast(lastSubSection.components)
  if (['list', 'iconList', 'orderedList'].includes(type)) {
    if (lastComponent?.type === type) {
      // 要合并到上一个里面去
      lastComponent.data.content.push(data)
    } else {
      lastSubSection.components.push({
        type,
        data: {
          content: [data]
        }
      })
    }
  } else {
    lastSubSection.components.push({ type, data })
  }
}

export function renderMarkdown(list: any[], renderObjList: any) {
  for (const obj of (renderObjList || [])) {
    switch (obj.type) {
      case 'title':
        pushLast('title', list, {
          content: obj.content
        })
        break
      case 'sub_title':
      case 'section_title':
        pushLast('subTitle', list, {
          content: obj.content
        })
        break
      case 'item_with_icon':
        pushLast('iconList', list, {
          icon: obj.icon,
          content: obj.content
        })
        break
      case 'item':
        pushLast('list', list, obj.content)
        break
      case 'order_item':
        pushLast('orderedList', list, obj.content)
        break
      case 'paragraph':
        pushLast('paragraph', list, {
          content: obj.content
        })
        break
      case 'image':
        pushLast('image', list, {
          src: obj?.props?.src || obj.src,
          alt: obj?.props?.alt || obj.alt,
          title: obj?.props?.title || obj.title
        })
        break
      default:
        break
    }
  }
}
