









import { Component, Vue } from 'nuxt-property-decorator'
import { IconNext } from '@klook/klook-icons'

@Component({
  components: {
    IconNext
  }
})
export default class ViewMore extends Vue {
}
