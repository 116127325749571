import { Component, Prop, Vue, namespace } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'
import { ExperienceActivity } from '~/types/experience/activity-section'

const ActivityModule = namespace('traveller/activity')

@Component
export default class CalendarBase extends Vue {
  @ActivityModule.State activityDetail!: any
  @Prop({ default: 0 }) preview!: number

  unitsState: string = ''
  activityId!: number
  date: string = ''
  schedulesState = 'fetching'
  isSchedulesNoData = false
  schedulesUnitsState = 'fetching'
  schedules = []
  schedule: ExperienceActivity.ISchedule | null = null
  packageSchedulesUnitsMap: { [key: string]: ExperienceActivity.IPackageSchedulesUnits } = {}

  dateChange(_date: string, schedule: ExperienceActivity.ISchedule) {
    this.schedule = this.getStocks(_date, schedule)
  }

  getStocks(date: string, schedule: ExperienceActivity.ISchedule) {
    const res = this.schedules.filter((item: any) => item.date === date)
    return {
      ...(schedule || {}),
      ...(res[0] as any)
    }
  }

  updateSchedulesUnitsState(v: string) {
    this.schedulesUnitsState = v
    this.$emit('updateSchedulesUnitsState', v)
  }

  async getPackageSchedulesUnits(packageId: number) {
    if (!packageId) {
      return
    }
    this.updateSchedulesUnitsState('fetching')
    // true是不翻译，false是翻译
    const translation = this.$route.query?.translation === '1'
    const key = `${packageId}-${translation}`
    const memory = this.packageSchedulesUnitsMap[key]
    if (memory) {
      this.updateSchedulesUnitsState('success')
      return memory
    }

    this.updateSchedulesUnitsState('fetching')

    let res = null
    try {
      res = await this.$axios.$get(apis.getPackagePriceSchedulesAndUnits, {
        params: {
          translation,
          package_id: packageId,
          preview: this.preview
        }
      })
    } catch (error) {
      this.updateSchedulesUnitsState('failure')
      return
    }

    const { result } = res
    if (res?.success && result) {
      const { schedules } = result
      const d = {
        ...result,
        schedules: schedules?.map((item: ExperienceActivity.IPackageSchedule) => {
          const { time_slots } = item
          const stock = time_slots.reduce((acc: number, val: ExperienceActivity.ITimeSlot) => {
            return acc + val.stock
          }, 0)
          return {
            ...item,
            stock,
            soldout: !(stock)
          }
        })
      }
      this.packageSchedulesUnitsMap[key] = d
      this.updateSchedulesUnitsState('success')
      return d
    }

    this.updateSchedulesUnitsState('failure')
  }

  async getSchedules() {
    // 排队状态不需要调接口
    const is_line_page = this.activityDetail?.basic_data?.is_line_page ?? false
    if (is_line_page) {
      return
    }
    this.schedulesState = 'fetching'
    this.isSchedulesNoData = false
    const { success, result } = await this.$axios.$get(apis.activityPriceSchedules, {
      params: {
        activity_id: this.activityId,
        preview: this.preview
      }
    })
    if (success && result) {
      const { schedules } = result
      if (schedules) {
        this.schedules = schedules.map((v: any) => {
          const { stocks } = v
          return {
            ...v,
            soldout: !stocks.reduce?.((acc: any, stock: any) => acc + stock.stock, 0)
          }
        })
        this.schedulesState = 'success'
        return
      } else {
        this.isSchedulesNoData = true
      }
    }

    this.schedulesState = 'failure'
  }
}
