

















import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ImageGalleryViewer from './image-gallery-viewer.vue'
import ActivityLayer from '~/components/traveller/activity/layer/activity-layer.vue'

@Component({
  components: {
    ActivityLayer,
    ImageGalleryViewer
  }
})
export default class ImageViewer extends Vue {
  @Prop() visible!: boolean
  @Prop() transfer!: boolean
  @Prop() notFixed!: boolean
}
