


































import { Component, Prop, Watch } from 'nuxt-property-decorator'
import { IconTime } from '@klook/klook-icons'
import PublishInfoBase from '~/components/common/publish-info/publish-info-base'
import getCountDownTime from '~/share/count-down'

@Component({
  components: {
    IconTime
  }
})
export default class WarmingSlogan extends PublishInfoBase {
  @Prop({ default: false }) showArrow!: boolean
  @Prop({ default: false }) isAbsolute!: boolean
  @Prop({ default: '' }) size!: string

  timeStr = ''
  interval: any = null
  styleObj: Record<string, string> | null = null

  @Watch('isWarming', { immediate: true })
  isWarmingChange(val: boolean) {
    if (val && this.publishedTime && !this.interval && process.client) {
      this.getTime()
      this.countDown()
      this.$nextTick(this.adjustPosition)
    }
  }

  @Watch('publishedTime', { immediate: true })
  publishedTimeChange() {
    this.$nextTick(this.adjustPosition)
  }

  adjustPosition() {
    if (process.client && this.isAbsolute) {
      const height = (this.$el as any)?.offsetHeight || 0
      if (height > 1) {
        this.styleObj = {
          top: `${1 - height}px`
        }
      }
    }
  }

  getTime() {
    if (!this.publishedTime) {
      this.cleanup()
      this.timeStr = ''
      this.interval = null
      return
    }

    const { days, hours, minutes, seconds } = getCountDownTime(this.publishedTime)
    if (days === '0' && hours === '00' && minutes === '00' && seconds === '00') {
      this.timeStr = ''
    } else if (days === '0') {
      this.timeStr = this.$t('79655', {
        hh_mm_ss: ` ${hours}:${minutes}:${seconds}`
      })
    } else {
      this.timeStr = this.$t('79654', {
        num: ` ${days}`,
        hh_mm_ss: `${hours}:${minutes}:${seconds}`
      })
    }
  }

  countDown() {
    if (process.client) {
      this.interval = setInterval(this.getTime, 1000)
    }
  }

  cleanup() {
    this.interval && clearInterval && clearInterval(this.interval)
  }

  beforeDestroy() {
    this.cleanup()
  }
}
