





































import { Component, Prop, State, Vue, Inject } from 'nuxt-property-decorator'
import Price from '~/components/experience-booking/activity/package-card/mobile/views/price.vue'
import { HomePage } from '~/types/traveller/home-page'
import { DefaultFieldsConfig } from '~/share/data/experience/fieldsConfigUtils.js'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'

@Component({
  components: {
    TotalSlot,
    Price
  }
})
export default class PackagePrice extends Vue {
  @State klook!: Data.Klook
  @Prop() priceShow!: any
  @Prop({ default: '' }) credits!: string | number
  @Prop({ default: '' }) sellPrice!: string | number
  @Prop({ default: '' }) marketPrice!: string | number
  @Prop({ default: '' }) fromPrice!: string | number
  @Prop({ default: '' }) toPrice!: string | number
  @Prop({ default: '' }) promotionStatus!: string
  @Prop({ default: '' }) originalSellingPrice!: string
  @Prop({ default: '' }) discount!: string
  @Inject({ from: 'fieldsConfig2section', default() { return DefaultFieldsConfig } }) fieldsConfig2section!: HomePage.AllFieldsIF
}
