









































import { Component, Prop, Vue, State, Watch } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { CardSwiper } from '~/components/traveller/home-page/card-swiper'
import { getStandardDateFormat } from '~/share/data/datetime'
import { Schedule } from '~/components/experience-booking/activity/activity-calendar/mobile/types'
import MiniDateButton from '~/components/experience-booking/activity/activity-calendar/mobile/date-button.vue'

@Component({
  components: {
    CardSwiper,
    MiniDateButton
  }
})
export default class MiniCalendar extends Vue {
  @State klook!: Data.Klook
  @Prop() schedules!: Schedule[]
  @Prop() value!: string
  @Prop({ default: false }) smooth!: boolean
  @Prop({ default: true }) priceVisible!: boolean

  selectedDate = ''
  items!: { [prop: string]: HTMLElement }

  @Watch('value', { immediate: true })
  valueChange(val: string) {
    if (val !== this.selectedDate) {
      this.slide(val, this.smooth)
      this.selectedDate = val
    }
  }

  get isOverLimit() {
    if (!this.value) {
      return false
    }

    const length = this.schedules?.length

    if (!length) {
      return false
    }

    const lastDay = this.schedules[length - 1].date

    if (new Date(this.value) > new Date(lastDay)) {
      return true
    }
  }

  getDisplayDate(date: string, index: number) {
    if (index < 2) {
      const today = dayjs().format('YYYY-MM-DD')
      const tomorrow = dayjs().add(1, 'day').format('YYYY-MM-DD')

      if (date === today) {
        return this.$t('today')
      } else if (date === tomorrow) {
        return this.$t('search_tomorrow')
      }
    }
    return getStandardDateFormat(
      date,
      this.$t.bind(this),
      this.klook.language,
      3
    )
  }

  /**
   * scrollIntoView 在value改变是使用smooth可能会对不齐
   * 可能是大日历隐藏改变dom阻断了动画
   */
  slide(val: string, smooth: boolean = true) {
    setTimeout(() => {
      const node = this.getItem(val)

      if (node) {
        node.scrollIntoView({
          behavior: smooth ? 'smooth' : 'auto',
          inline: 'center',
          block: 'nearest'
        })
      }
    })
  }

  formatDate(date: string) {
    return getStandardDateFormat(
      date,
      this.$t.bind(this),
      this.klook.language,
      1
    )
  }

  formatPrice(price: number) {
    return `${this.klook.currencySymbol} ${price}`
  }

  change(item: Schedule) {
    if (item.soldout || item.disable) {
      return null
    }
    const { date } = item
    this.selectedDate = date

    this.slide(date)

    this.$emit('change', date, item)
    this.$emit('input', date, item)
  }

  getItem(date: string) {
    return this.$el?.querySelector(`[data-date="${date}"]`)
  }

  mounted() {
    const a = setTimeout(() => {
      this.slide(this.value, this.smooth)
      clearTimeout(a)
    }, 500)
  }
}
