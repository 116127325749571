export const DefaultFieldsConfig = {
  klook_insider: 1,
  reopen: 1,
  address_image: 1,
  address_title: 1,
  activity_package_promotion: 1,
  activity_custom_config_hide_credit: 0,
  activity_custom_config_hide_price_guarantee: 0,
  fold_switch: 0,
  fold_min_height: '',
  show_style_version: 0
}

export const getBookButtonTextId = (key = 0) => {
  const defTextId = 'activity.v2.mobile.btn.summary.book_now' // Book Now
  const idList = [
    { key: 1, textId: '73947' } // 领券下单
  ]
  const tid = (idList.find(o => o.key === key) || {}).textId || defTextId
  return tid
}
