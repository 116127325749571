





















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import DynamicIcons from '~/components/experience-booking/experience-activity/dynamic-component/icons/index.vue'
import DynamicMarkdown from '~/components/experience-booking/experience-activity/dynamic-component/markdown/index.vue'
import DynamicMap from '~/components/experience-booking/experience-activity/dynamic-component/map/index.vue'
import DynamicImage from '~/components/experience-booking/experience-activity/dynamic-component/image/index.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    DynamicIcons,
    DynamicMarkdown,
    DynamicMap,
    DynamicImage
  }
})
export default class DynamicComponent extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() components!: ExperienceActivity.DynamicComponent
  @Prop({ type: Number, default: 800 }) baseWidth!: number

  get cClass() {
    const prefix = 'dynamic-component'
    return [prefix, `${prefix}_${this.platform}`]
  }

  getComponentName(type: string) {
    const o: any = {
      icon: 'DynamicIcons',
      markdown: 'DynamicMarkdown',
      address: 'DynamicMap',
      image: 'DynamicImage'
    }
    return o[type] || ''
  }
}
