




























import { Component } from 'nuxt-property-decorator'
import passActivityBase from './base'
import PassTitle from './components/pass-activity-title.vue'
import PassActivityCard from './pass-activity-card.vue'

@Component({
  components: {
    PassTitle,
    PassActivityCard
  }
})
export default class PassWithPic extends passActivityBase {
  static displayName = 'Pass_with_pic'
}
