






import { Component } from 'nuxt-property-decorator'
import optionsUnitsTips from '~/components/traveller/activity/activity-base/optionsUnitsTips'

@Component
export default class BookingOptionsUnitsTips extends optionsUnitsTips {
}
