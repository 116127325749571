import cloneDeep from 'lodash/cloneDeep'

// export const inHouseTrack = {
// /*
//     不同类型{type}的埋点，可用的 query 参数不同，具体如下：
//     - page - trg, oid, ext
//     - module - trg, oid, ext, idx, len
//     - item - trg, oid, ext, evt, mod, typ
// 示例：
//     data-spm-page='city?trg=auto&oid=city_3&ext={"foo":"bar"}'
//     data-spm-module='card?trg=auto&oid=activity_3&ext={"foo":"bar"}&idx=0&len=9'
//     data-spm-page='login?trg=auto&ext={"foo":"bar"}&typ=entry&evt=click&mod=once'
// */
//   'data-spm-{type}': 'string' // page在页面级别设置，按钮一般用module，列表一般用item,

// }
export const isDev = process.env.NODE_ENV === 'development'
export const handleFilterQuery = (query) => {
  if (typeof query !== 'object') { return {} }
  const defineArr = [
    { key: 'trg', type: 'string', def: 'auto' },
    { key: 'oid', type: 'string', def: undefined },
    { key: 'ext', type: 'string', def: undefined },
    { key: 'evt', type: 'string', def: 'click' },
    { key: 'typ', type: 'string', def: undefined },
    {
      key: 'idx',
      type: 'number',
      def: undefined,
      handleFilter(q) {
        return q.len > 0
      }
    },
    {
      key: 'len',
      type: 'number',
      def: undefined,
      handleFilter(q) {
        return q.len > 0
      }
    }
  ]
  const filterArr = defineArr.filter((o) => {
    if (o.handleFilter) {
      return o.handleFilter(query)
    } else {
      return query[o.key]
    }
  })
  return filterArr.reduce((retObj, o) => {
    retObj[o.key] = query[o.key]
    return retObj
  }, {})
}
// 处理后端下发的track结构：v-bind="handleIHTrack(trackObj)"
export const handleIHTrack = (trackObj) => {
  const obj = {}
  if (!trackObj || typeof trackObj !== 'object') { return obj }
  const selfTrack = cloneDeep(trackObj)
  const { type = '', spm = '', exposure = true, action = true } = selfTrack
  if (!type || !spm) { return obj }
  let { query = {} } = selfTrack
  let queryStr = ''
  let actionQueryStr = ''
  if (typeof query === 'object' && query) {
    query = handleFilterQuery(query)
    const actionFilterArr = ['evt', 'mod', 'typ']
    const actionQuery = actionFilterArr.reduce((obj, key) => {
      const v = query[key]
      if (v) {
        obj[key] = v
      }
      return obj
    }, {})
    actionQueryStr = '?' + Object.keys(actionQuery).reduce((list, key) => {
      const val = actionQuery[key] === undefined ? '' : actionQuery[key]
      list.push(`${key}=${val}`)
      return list
    }, []).join('&')
    exposure || (query.trg = query.trg || 'manual') // WEB SDK取消曝光需要设置trg=manual
    query = handleFilterQuery(query)
    queryStr = '?' + Object.keys(query).reduce((list, key) => {
      if (!actionFilterArr.includes(key)) {
        const val = query[key] === undefined ? '' : query[key]
        list.push(`${key}=${val}`)
      }
      return list
    }, []).join('&')
  }
  switch (type) {
    case 'module':
      obj['data-spm-module'] = spm + queryStr // modele默认带曝光埋点，除非 exposure 设置为false
      if (action) {
        obj['data-spm-virtual-item'] = '__virtual' + actionQueryStr // module默认为带action埋点，除非 action 设置为false
      }
      break
    case 'page':
    case 'item':
      obj[`data-spm-${type}`] = spm + queryStr // 无特殊处理 exposure、action 参数
      break
    default:
      // custom，手动上报未处理
  }
  return obj
}
