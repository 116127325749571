
































import { Component, Prop } from 'nuxt-property-decorator'
import optionsUnitsTips from '~/components/traveller/activity/activity-base/optionsUnitsTips'

@Component
export default class HeaderTips extends optionsUnitsTips {
  @Prop() tipsType!: 'poptip-type'
  @Prop() styleType!: 'big-style'
  @Prop() hideIcon!: boolean
  @Prop() hideMore!: boolean
  @Prop() text!: string
  @Prop({ type: Boolean, default: false }) hideDivider!: boolean
  @Prop({ type: Number, default: 35 }) limit!: number

  isShowMore = false
  isShowMoreBtn = false

  get calcText() {
    return this.text || this.tips
  }

  get viewMoreText() {
    return this.isShowMore ? this.$t('city_page_view_more') : this.$t('collapse_all')
  }

  mounted() {
    setTimeout(() => {
      this.calcHeight()
    }, 50)
  }

  calcHeight() {
    const textDom = this.$el.querySelector('.header-tips-text')
    if (textDom && textDom.scrollHeight > this.limit) {
      this.isShowMore = true
      this.isShowMoreBtn = true
    }
  }

  showMore() {
    this.isShowMore = !this.isShowMore
  }
}
