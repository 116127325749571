





















import { Vue, Component, Prop, Watch } from 'nuxt-property-decorator'
import CommonContentLoading
  from '~/components/experience-booking/activity/package-detail/status/common-content-loading.vue'
import CommonCententReflesh
  from '~/components/experience-booking/activity/package-detail/status/common-content-refresh.vue'
import CommonSkeletonSlots from '~/components/experience-booking/activity/package-detail/status/common-skeleton-slots.vue'

interface LoadingStateIf{
  state: '' | 'fetching' | 'failure' | 'success'
}

@Component({
  components: {
    CommonSkeletonSlots,
    CommonContentLoading,
    CommonCententReflesh
  }
})
export default class Index extends Vue {
  @Prop({ type: Boolean, default: false }) isSuccessVshow?: boolean
  @Prop({ type: String, default: '' }) fetchingSlots?: string | string[]
  @Prop({ type: Boolean, default: false }) hideIniting!: boolean
  @Prop({ type: Boolean, default: false }) hideFetching!: boolean
  @Prop({ type: Boolean, default: false }) hideFailure!: boolean
  @Prop({ type: Boolean, default: false }) keepState!: boolean
  @Prop({ type: Boolean, default: false }) keepFailureState!: boolean
  @Prop({ type: String }) state!: string
  @Prop({ default: false }) isInited!: boolean
  @Prop({ type: Object, default: () => {} }) fetching!: any
  @Prop({ type: Object, default: () => {} }) failure!: any

  firstInited = false
  beforeState = ''

  @Watch('state', { immediate: true })
  stateWatch(str: string) {
    if (!this.firstInited && ['failure', 'success'].includes(str)) {
      this.firstInited = true
    }
    if (['failure', 'success'].includes(str)) {
      this.beforeState = str
    }
  }

  get calcKeepSuceess() {
    const { state, keepState, beforeState } = this
    const isb = state === 'fetching' && keepState && beforeState === 'success'
    return isb
  }

  get calcKeepFailure() {
    const { state, fetching, beforeState, keepFailureState } = this
    const isb = this.firstInited && state === 'fetching' && (fetching?.type === 'overlay' || keepFailureState) && beforeState === 'failure'
    return isb
  }

  created() {
    this.firstInited = this.isInited || false
  }
}
