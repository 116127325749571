












import { Component, Prop, Vue } from 'nuxt-property-decorator'
import Logo from '~/components/experience/card-swiper/logo.vue'

@Component({
  components: {
    Logo
  }
})
export default class GalleryImage extends Vue {
  @Prop({ default: () => [] }) image!: boolean
  @Prop() photoType!: string
  @Prop({ default: 110 / 3 }) logoWidth!: number
}

