






























import { Component, Prop, State, Vue } from 'nuxt-property-decorator'
import PackagePrice from './package-price.vue'
import BookButton from './new-book-button.vue'

import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    PackagePrice,
    BookButton
  }
})

export default class MobilePackageCardPriceAndButton extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: null }) packageData!: ExperienceActivity.IPackageCard
  @Prop({ default: null }) buttonContent!: string

  get isSpaceBetween() {
    return this.isWarmingOrSuspended
  }

  get event() {
    return this.packageData?.promotion_price?.promotion_event
  }

  get discountDesc() {
    if (this.packageData?.promotion_price?.promotion_event?.status === 5) {
      return this.packageData.discount_desc
    }
    return ''
  }

  get isWarmingOrSuspended() {
    return [2, 3].includes(this.packageData?.publish_info?.publish_status)
  }

  get warmingOrSuspendedText() {
    return this.isWarmingOrSuspended ? this.packageData?.publish_info?.publish_status_desc : ''
  }

  toBook() {
    if (!this.isWarmingOrSuspended) {
      this.$emit('toBook')
    }
  }
}
