




















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import get from 'lodash/get'
import NoLazyLoadCard from './no-lazy-load-card.vue'
import LazyLoadCard from './lazy-load-card.vue'
import Logo from './logo.vue'

@Component({
  components: {
    NoLazyLoadCard,
    LazyLoadCard,
    Logo
  }
})
export default class AdaptiveCard extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  @Prop({ type: String }) ratio!: string
  @Prop() image!: string
  @Prop() href!: string
  @Prop() imageType!: string
  @Prop() logoWidth!: number

  get linkStyle() {
    const [width, height] = this.ratio.split(':')
    const percent = Number(width) / Number(height)

    return {
      paddingBottom: `${(1 / percent) * 100}%`
    }
  }

  // 防止a标签嵌套闭合导致的渲染不一致
  handleClick() {
    if (this.href) {
      window.location.href = this.href
    }
  }

  get isDesktop() {
    return this.platform === 'desktop'
  }

  get newImageType() {
    let imgType = this.imageType || ''
    if (!this.isDesktop) {
      const isBigCard = get(this.$attrs, 'isBigCard', false)
      if (isBigCard) {
        imgType = 'experienceMobileActivityCardLarge'
      } else {
        imgType = 'mobileActivityCard'
      }
    }
    return imgType
  }

  get noLazyLoad() {
    return get(this.$attrs, 'noLazyLoad', false)
  }

  get component() {
    return this.noLazyLoad ? 'NoLazyLoadCard' : 'LazyLoadCard'
  }
}
