
export interface activityBreadcrumbDetail {
  countryName: string,
  templateName: string,
  mainTagName: string,
  indexName: string,
  cityName: string,
  langPath: string,
  countryId: number,
  cityId: number,
  countrySeoUrl: string,
  templateId: number,
  mainTagId: string,
  citySeo: string,
  isCityPublish: boolean,
  isCountryPublish: boolean
  frontendTree: frontedTree[]
}

export interface breadcrumbItem {
  id: breadcrumbTypes,
  name: string,
  path: string
}

export interface frontedTree {
  id: number,
  name: string,
  seo_url: string,
  level: number
}

// 此处原来做了id限制，因为下面需要改为动态id，只能用string兼容
export type breadcrumbTypes =
  | 'index'
  | 'country'
  | 'city'

type breadcrumbsInfo = {
  any: breadcrumbItem;
}

function getExperienceLevel(level: number, prefix: string = '') {
  switch (level) {
    case 0:
      return prefix ? '/' + prefix : ''
    case 1:
      return prefix ? '/' + prefix + '-mcate' : '/mcate'
    default:
      return prefix ? '/' + prefix + '-cate' : '/cate'
  }
}

function getExperienceSeo({
  destinationId,
  frontendId,
  destinationSeo,
  frontendSeo
}: {
  destinationId: number,
  frontendId: number,
  destinationSeo: string,
  frontendSeo: string
}) {
  // frontendId: 1 根结点
  if (frontendId === 1) {
    return `${destinationId}-${destinationSeo}`
  }

  return `${destinationId}-${frontendId}-${destinationSeo}-${frontendSeo}`
}

// 获取活动页面包屑数据
export function getActivityBreadcrumbsInfo({
  indexName,
  countryName,
  cityName,
  langPath,
  countryId,
  countrySeoUrl,
  cityId,
  citySeo,
  frontendTree,
  isCityPublish,
  isCountryPublish
}: activityBreadcrumbDetail) {
  const index: breadcrumbItem = {
    id: 'index',
    path: `/${langPath}`,
    name: indexName
  }

  const country: breadcrumbItem = {
    id: 'country',
    path: `/${langPath}coureg/${countryId}-${countrySeoUrl}-things-to-do/`,
    name: countryName
  }

  const city: breadcrumbItem = {
    id: 'city',
    path: `/${langPath}city/${cityId}-${encodeURIComponent(citySeo)}-things-to-do/`,
    name: cityName
  }

  const firstTree = frontendTree?.[0]

  // 后端只返回部分面包屑，前端需自己组装。因需要在breadcrumbs.ts文件中处理seo，此处只能index处理
  const frontList: any[] = frontendTree?.map((item, index) => {
    if (firstTree?.id !== 1) {
      return {
        id: `frontList${index}`,
        path: `/${langPath}city/${cityId}-${encodeURIComponent(citySeo)}-things-to-do?frontend_id_list=${item.id}`,
        name: item.name
      }
    }

    const { level, id, seo_url, name } = item
    const experienceUrl = `/${langPath}experiences`

    let path = ''
    if (isCityPublish) {
      path = `${experienceUrl}${getExperienceLevel(level, 'city')}/${getExperienceSeo({
        destinationSeo: citySeo,
        destinationId: cityId,
        frontendId: id,
        frontendSeo: seo_url
      })}/`
    } else if (isCountryPublish) {
      path = `${experienceUrl}${getExperienceLevel(level, 'coureg')}/${getExperienceSeo({
        destinationSeo: countrySeoUrl,
        destinationId: countryId,
        frontendId: id,
        frontendSeo: seo_url
      })}/`
    } else {
      // 全球页
      path = `${experienceUrl}${getExperienceLevel(level)}/${id}-${seo_url}/`
    }

    return {
      id: `frontList${index}`,
      path,
      name
    }
  })

  return {
    index,
    country,
    city,
    frontList
  }
}

// 面包屑列表
export function getActivityBreadcrumbs(detail: activityBreadcrumbDetail): breadcrumbItem[] {
  const breadcrumbsInfo = getActivityBreadcrumbsInfo(detail)

  const { countryName, cityName, isCityPublish, isCountryPublish } = detail

  const breadcrumbs = [breadcrumbsInfo.index]

  // 新加坡国家城市名一样
  if (countryName.toUpperCase() !== cityName.toUpperCase() && isCountryPublish) {
    breadcrumbs.push(breadcrumbsInfo.country)
  }

  if (isCityPublish) {
    breadcrumbs.push(breadcrumbsInfo.city)
  }

  breadcrumbs.push(...breadcrumbsInfo.frontList)

  return breadcrumbs
}
