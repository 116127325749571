




























import { Component, Vue, namespace } from 'nuxt-property-decorator'
import { Activity } from '~/types/traveller/activity'

const ActivityModule = namespace('traveller/activity')
@Component
export default class EntryList extends Vue {
  @ActivityModule.State fastEntryList !: Activity.fastEntry[]

  showPackageDetail(index: number) {
    this.$emit('show-package-detail', index)
  }
}
