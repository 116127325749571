






























































import { Component, Prop, Watch } from 'nuxt-property-decorator'
import DataLoader from '../components/attraction-preview-detail/loaded-data.vue'
import Base from '~/components/experience-booking/experience-activity/package-options/attraction-preview/attraction-preview-base.vue'
import AttractionPreviewlist from '~/components/experience-booking/experience-activity/package-options/attraction-preview/desktop/attraction-preview-list.vue'
import AttractionPrevieDetail from '~/components/experience-booking/experience-activity/package-options/attraction-preview/components/attraction-preview-detail/index.vue'

@Component({
  components: {
    AttractionPreviewlist,
    AttractionPrevieDetail,
    DataLoader
  }
})
export default class AttractionPreviewDesk extends Base {
  // 渲染搜索区域
  @Prop({ default: false }) renderTop!: boolean
  // 需要滚动
  @Prop({ default: true }) isScroll!: boolean
  // 有的需要跳转book
  @Prop({ default: false }) isBook!: boolean
  @Prop() standardActivityId!: number

  current = 0
  init = true
  isFocus = false

  height = 'auto'
  hackHeight = 13

  created() {
    this.height = this.calcHeight(253)
  }

  get standardId() {
    if (this.activityList.length > 0 && this.activityList[this.current]) {
      return this.activityList[this.current]?.activity_id
    }

    return 0
  }

  checkHeight(y = 45) {
    const container = document.querySelector('.attraction-preview-main')
    if (container) {
      this.height = `calc(90vh - ${container.getBoundingClientRect().top + y}px)`
    }
  }

  calcHeight(height: number) {
    // book按钮需要64的高度
    const h = (height || 0) + (this.hackHeight || 0)
    if (this.isBook) {
      return `calc(90vh - ${h + 64}px)`
    }
    return `calc(90vh - ${h}px)`
  }

  togglehange(vlaue: boolean) {
    if (vlaue) {
      this.height = this.calcHeight(303)
    } else {
      this.height = this.calcHeight(253)
    }
  }

  @Watch('showTabs')
  showTabsChange(vlaue: boolean) {
    if (vlaue) {
      this.height = this.calcHeight(253)
    } else {
      this.height = this.calcHeight(198)
    }
  }

  activeKeyChange(value: number) {
    this.current = value
  }

  srollToView(y: number) {
    const domList = document.querySelector('.attraction-preview-left')
    if (domList) {
      domList.scrollTo({ left: 0, top: y, behavior: 'smooth' })
    }
  }

  @Watch('activityList')
  activityListChange() {
    // 第一次进入，且有standardActivityId，定位到该条
    if (this.standardActivityId && this.init) {
      const index = this.activityList.findIndex((item: any) => item.activity_id === this.standardActivityId)

      this.current = index
      this.srollToView(index * 45)
      this.init = false
    } else {
      this.current = 0
      this.srollToView(0)
    }
    this.$emit('setTotal', this.total)
  }
}
