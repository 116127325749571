import { isEqual } from 'lodash'
import { Vue, Component, Watch, namespace } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section.js'
import { parseUrlQuery } from '~/share/utils'

const experience = namespace('experience')

const CHECK_PARAMS = 'priceCheck'
const API_GET_CHECK_KEY = '/v1/experiencesrv/order/settlement_service/pre_settlement_for_admin'

@Component
export default class Index extends Vue {
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf

  priceCheckParams: any = null

  /**
   * 监听 sku 数量或 日期id 变化，组装查询参数
   * 因为存在只选择日期或sku的场景
   */
  @Watch('calcTotalUnitCount')
  @Watch('calcArrangementId')
  @Watch('wifiSimParams', { deep: true }) // 兼容 wifi sim 卡场景需要选择日期
  watchUnitCount() {
    setTimeout(() => {
      let queryParams: any = {
        start_time: '',
        sku_list: []
      }

      // selectedTime 兼容旧版 mweb package-options 中的字段
      const { currentUnits, pkgSelectedTime, selectedTime } = this as any
      const { date } = pkgSelectedTime || selectedTime || {}
      const skuList = currentUnits.reduce((acc: Array<any>, unit: any) => {
        if (unit.count > 0) {
          acc.push({
            sku_id: unit.skuId || unit.sku_id,
            quantity: unit.count
          })
        }
        return acc
      }, [])
      queryParams.start_time = date
      queryParams.sku_list = skuList

      // 兼容其他业务场景额外的参数
      const { sectionActData, wifiSimParams } = this as any
      if (sectionActData?.isWifi && wifiSimParams) {
        queryParams = {
          ...queryParams,
          start_date: wifiSimParams?.start_date || '',
          end_date: wifiSimParams?.end_date || '',
          start_time_type_id: wifiSimParams?.start_time_type_id || 0,
          end_time_type_id: wifiSimParams?.end_time_type_id || 0
        }

        // 参数不全暂时跳过
        if (!queryParams.end_date) {
          return
        }
      }

      this.priceCheckParams = queryParams
    }, 200)
  }

  /**
   * combo 场景通过监听总价变化
   */
  @Watch('priceDetail.total_price')
  watchComboTotalPrice() {
    setTimeout(() => {
      const queryParams: any = {
        start_time: '',
        sku_list: []
      }

      const { currentUnits } = this as any
      const skuList = currentUnits.reduce((acc: Array<any>, unit: any) => {
        if (unit.count > 0) {
          acc.push({
            sku_id: unit.skuId || unit.sku_id,
            quantity: unit.count
          })
        }
        return acc
      }, [])
      queryParams.sku_list = skuList

      const { priceDetail } = this as any
      if (priceDetail && priceDetail.participant_time) {
        queryParams.start_time = priceDetail.participant_time
      }

      this.priceCheckParams = queryParams
    }, 200)
  }

  /**
   * 通过监听参数变化驱动查询逻辑
   */
  @Watch('priceCheckParams', { deep: true })
  watchPriceCheckParams(val: any, oldVal: any) {
    // 去除重复查询请求
    if (isEqual(val, oldVal)) {
      return
    }

    // 参数合法才进行查询
    if (val.start_time && val.sku_list.length) {
      this.handleSetPriceCheckKey(val)
    }
  }

  /**
   * 价格查询工具获取查询 key 值并回填
   */
  async handleSetPriceCheckKey(queryParams: any) {
    const search = parseUrlQuery()
    // 有选择 sku 且链接中需要携带查询参数才做处理
    if (!(typeof search[CHECK_PARAMS] === 'string')) {
      return
    }

    // 请求接口
    const { success, result } = await this.$axios.$post(API_GET_CHECK_KEY, queryParams)
    const value = success ? result.key || '' : ''

    // 链接参数回填，没有key则清除之前的值
    let url = window.location.href
    const separator = url.includes('?') ? '&' : '?'
    const paramRegex = new RegExp(`([?&])${CHECK_PARAMS}(=[^&]*)?(&|$)`)
    if (paramRegex.test(url)) {
      url = url.replace(paramRegex, `$1${CHECK_PARAMS}${value ? `=${encodeURIComponent(value)}` : ''}$3`)
    } else {
      url += `${separator}${CHECK_PARAMS}=${encodeURIComponent(value)}`
    }
    window.history.replaceState(null, '', url)
  }
}
