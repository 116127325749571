



















































import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component({
  components: {
  }
})
export default class WebCouponCard extends Vue {
  @Prop({ type: Object, default: null }) couponData!: object

  couponCodeClick(data: any) {
    this.$emit('couponCodeClick', data)
  }

  couponStatus(status: string) {
    let _status = ''
    switch (status) {
      case 'tostart':
        _status = 'NotStarted'
        break
      case 'use':
        _status = 'ToBeUsed'
        break
      case 'get':
        _status = 'ToBeRedeemed'
        break
      case 'unusable':
        _status = 'AllOut'
        break
      default:
        break
    }
    return _status
  }
}
