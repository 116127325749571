


















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class PassActivityReview extends Vue {
  @Prop() review!: Record<string, any>
  @Prop({ default: false }) isGrey!: boolean

  get isValid() {
    return this.imgList?.length || this.text
  }

  get imgList() {
    return (this.review?.user_icons || []).reverse()
  }

  get text() {
    return this.review?.booked || ''
  }
}
