













import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
// @ts-ignore
import { NewCouponItem } from '@klook/promo-entrance'
import '@klook/promo-entrance/dist/promo-entrance.esm.css'

@Component({
  components: {
    NewCouponItem
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook

  @Prop({
    type: Array,
    default() {
      return []
    }
  }) couponsList!: any[]

  onRedeemStatusUpdate() {
    this.$emit('onRedeemStatusUpdate')
  }
}
