































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import EntryList from '~/components/experience-booking/activity/package-detail/entry-list.vue'
import { Activity } from '~/types/traveller/activity'
import ExpTagging from '~/components/experience/tagging/index.vue'
import PackageDetailUnoinComponent from '~/components/experience-booking/experience-activity/activity-package-detail/index.vue'

@Component({
  components: {
    ExpTagging,
    EntryList,
    PackageDetailUnoinComponent
  }
})
export default class PackageDetailMain extends Vue {
  @Prop() packageDetail!: Activity.packageDetail
  @Prop({ type: Boolean, default: false }) hideTitle!: boolean
  @Prop({ default: true }) expandAll!: boolean
  @Prop({ default: false }) expandItinerarySection!: boolean
  @Prop({ default: true }) hasBottomButton!: boolean
  @Prop({ type: Boolean, default: false }) isAiTranslation!: boolean

  visible = false
  index = 0

  get calcTags2attribute() { // mweb预订页-新流程-查看所选套餐详情
    return this.packageDetail?.product_tags?.attribute_tags || []
  }

  get activityValidity() {
    const arr = (this.packageDetail as any).usageValidity || []
    return {
      title: (arr.find((o: any) => o.type === 'title') || {}).content,
      rules: arr.filter((o: any) => o.type === 'item').map((o: any) => o.content)
    }
  }

  get isPassActivity() {
    return this.packageDetail?.isPassActivity || false
  }

  get specInfo() {
    const spec = this.packageDetail?.spec || []
    if (spec?.length) {
      const specList = spec.map((item: any) => {
        return (item?.attr || []).map((child: any) => {
          return child?.name || ''
        })
      })
      return specList.flat()
    }
    return []
  }
}
