




















import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component

export default class PriceIntroduction extends BusinessBase {
  @State klook!: Data.Klook
  static displayName = 'Price_info'

  introductions = [
    {
      title: '划线价格',
      content: '划线价格为参考价格，指商品或服务的门市价、服务提供商的指导价、零售价或曾经展示过的销售价格，并非原价，该价格仅供您参考。'
    },
    {
      title: '未划线价格',
      content: '指商品或服务的实时标价，为划线价基础上计算出来的优惠价格。具体成交价格根据商品或服务参加活动，或会员使用优惠券等发生变化，最终以订单结算页价格为准'
    }
  ]
}
