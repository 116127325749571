

















import { Component, Vue, Ref, Prop, Watch } from 'nuxt-property-decorator'

@Component
export default class ActivityViewMore extends Vue {
  @Ref() container!: HTMLElement
  @Prop({ default: 0 }) height!: number
  @Prop({ default: '' }) content!: string

  visible = false
  maxHeight = 0

  @Watch('height', { immediate: true })
  heightChange(val: number) {
    this.maxHeight = val
  }

  get styles() {
    return {
      maxHeight: this.maxHeight + 'px'
    }
  }

  viewMore() {
    this.visible = false
    this.maxHeight = this.container.scrollHeight
  }

  mounted() {
    const scrollHeight = this.container.scrollHeight
    if (
      scrollHeight > this.container.offsetHeight ||
      scrollHeight === Number(this.maxHeight)
    ) {
      this.visible = true
    }
  }
}
