





















import { Vue, Component, Prop, Ref } from 'nuxt-property-decorator'
@Component
export default class ActivityValidity extends Vue {
  @Ref() actValidityRef!: any
  @Prop() title?: string
  @Prop() subTitle?: string
  @Prop({
    type: String,
    default: 'none'
  }) maxHeight!: string

  @Prop({
    type: Array,
    default() {
      return []
    }
  }) rules?: string[]

  styles = {
    paddingBottom: '28px',
    maxHeight: 'none'
  }

  status = '' // more | less

  mounted() {
    (parseFloat(this.maxHeight) > 0) && this.initStatus()
  }

  initStatus() {
    this.$nextTick(() => {
      const ref = this.actValidityRef
      if (!ref) { return false }
      const { styles, maxHeight } = this
      const h = maxHeight ? parseFloat(maxHeight) : 0
      if (h && ref.offsetHeight > h) {
        this.status = 'more'
        styles.maxHeight = maxHeight
      }
    })
  }

  clickBtn() {
    const { status: oldStatus, styles, maxHeight } = this
    if (oldStatus) {
      this.status = oldStatus === 'more' ? 'less' : 'more'
      styles.maxHeight = oldStatus === 'more' ? 'none' : maxHeight
    }
  }

  get calcText() {
    const { status } = this
    let text = ''
    if (status === 'more') {
      text = this.$t('see_more')
    } else if (status === 'less') {
      text = this.$t('17843')
    }
    return text
  }
}
