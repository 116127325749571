import { Component, Prop, Vue, Getter, State } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import { cloneDeep } from 'lodash'
import { currencySymbolMap } from '~/share/data/currency'
import { formatThousands } from '~/share/utils'
import BottomBarSettlement from '~/components/traveller/activity/activity-bottom-bar/mobile/bottom-bar-settlement.vue'
import { Activity } from '~/types/traveller/activity'

@Component({
  components: {
    BottomBarSettlement
  }
})
export default class BookingSettlement extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: 0 }) bookingDays?: number
  @Prop() isSavingPoptip!: boolean
  @Prop() preTotalPrice!: any
  @Prop() units!: Activity.unit[]
  @Prop() isInPromotion!: boolean
  @Prop() promotionDetail!: Activity.promotionDetail
  @Getter useCredits!: boolean
  @Getter isGuestCheckout!: boolean

  get calcVip() {
    const preCredits = this.preTotalPrice?.credits_tag
    if (preCredits) {
      return cloneDeep(preCredits)
    }
    const unit = this.units?.find((o: any) => o?.credits_tag)
    const creditsTag = unit?.credits_tag
    if (creditsTag) {
      return cloneDeep({
        ...creditsTag,
        text: `${creditsTag.text}${this.credits}`
      })
    }
    return false
  }

  get price() {
    if (isEmpty(this.units)) {
      return 0
    }

    // 小数位
    const parts = (this.units[0].sellingPrice || '').toString().split('.')
    const decimal = parts[1] || ''

    const price = this.units.reduce(
      (acc, v) => acc + v.sellingPrice * v.count,
      0
    )

    if (this.bookingDays) {
      return (price * this.bookingDays).toFixed(Number(decimal.length))
    }

    return price.toFixed(Number(decimal.length))
  }

  get credits() {
    const num = parseInt(this.preTotalPrice?.credits)
    if (num >= 0) {
      return num
    }
    if (isEmpty(this.units)) {
      return 0
    }

    const credits = this.units.reduce(
      (acc, v) => acc + parseFloat((v.credits || 0).toString()) * v.count,
      0
    )

    if (this.bookingDays) {
      return parseInt((credits * this.bookingDays).toString())
    }

    return parseInt(credits.toString())
  }

  get promotionDiscountText() {
    if (!this.isInPromotion || isEmpty(this.units)) {
      return ''
    }

    const price = this.units.reduce(
      (acc, v) => acc + v.sellingPrice * v.count,
      0
    )
    const originalPrice = this.units.reduce(
      (acc, v) => acc + v.originalPrice * v.count,
      0
    )
    const discount = Number(originalPrice - price)

    if (discount > 0) {
      // 小数位
      const parts = (this.units[0].sellingPrice || '').toString().split('.')
      const decimal = parts[1] || ''

      return this.$t('16579', {
        0: formatThousands(discount.toFixed(Number(decimal.length))),
        currency: currencySymbolMap[this.klook.currency]
      })
    }

    return ''
  }

  render() {
    const on = {
      ...this.$listeners
    }
    return (
      <BottomBarSettlement
        isSavingPoptip={this.isSavingPoptip}
        preTotalPrice={this.preTotalPrice}
        promotionDiscountText={this.promotionDiscountText}
        sellPrice={this.price}
        credits={this.credits}
        creditsVisible={this.useCredits && !this.isGuestCheckout}
        vip={this.calcVip}
        {...{ on }}
      />
    )
  }
}
