














import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import dayjs from 'dayjs'
import * as helper from './helper'
import apis from '~/share/data/apis'
import localStorageEx from '~/assets/scripts/localstorage'

const localStorage = localStorageEx()
const KLK_NOTICE = 'klk_notices'

@Component
export default class Notice extends Vue {
  @State klook!: Data.Klook
  @Prop() type!: helper.pageType
  @Prop() subTypeIds!: helper.subTypeIds

  // 总的notice列表
  noticeList = []
  noticeMap: { [prop: string]: any } = {}

  get visible() {
    return this.klook.utilConfig?.global_notice
  }

  get valid() {
    return !isEmpty(this.pageNoticeList)
  }

  /**
   * 当前页面下的通知列表
   *
   *  Notice层级
   *  全平台
   *  - 国家页
   *    - 城市页
   *      - fnb
   *      - 活动页(含wifi活动页)
   *    - JR Pass
   *
   *  各层级notice = 当前层级notice + 所有上级notice...
   */
  get pageNoticeList() {
    // 过滤时间未过期的通知
    const dateValidNotices = this.noticeList.filter((v: any) => {
      const [startTime, endTime] = [dayjs(v.start_time), dayjs(v.end_time)]
      return dayjs().isBetween(startTime, endTime)
    })

    const noticeConfig: (string | number)[] = helper.pageNoticeMap[this.type] || []

    // 过滤当前页面需要的notice层级
    const pageNoticeList = noticeConfig.reduce((acc, type) => {
      const typeValidNotices = dateValidNotices.filter((notice: any) => {
        if ('408,445'.includes(type.toString())) {
          return notice.type === type
        }

        const noticeTypeIds = notice[`${type}_ids`]
        const subTypeIds = this.subTypeIds[`${type}Id` as 'activityId' | 'cityId' | 'countryId']

        return !isEmpty(noticeTypeIds) && subTypeIds && noticeTypeIds.includes(String(subTypeIds))
      })

      return [...acc, ...typeValidNotices]
    }, [] as any[])

    // 去除重复
    return [...new Set(pageNoticeList)].sort((a, b) => {
      if (a.type !== b.type) {
        return 0
      }
      return dayjs(b.start_time).diff(a.start_time)
    })
  }

  get renderPageNoticeList() {
    return this.pageNoticeList.map((item) => {
      if (item.content) {
        return { content: item.content.title, link: item.content.url }
      }
    })
  }

  async fetchNotice() {
    const platform = this.klook.platform === 'desktop' ? 8 : 4

    const res = await this.$axios.$get(apis.noticeList, { params: { platform } })

    const { success, result } = res

    if (success && !isEmpty(result)) {
      this.noticeMap[this.klook.language] = result
      localStorage.setItem(KLK_NOTICE, this.noticeMap, 3 * 60 * 1000)

      this.noticeList = result
    }
  }

  async mounted() {
    this.noticeMap = localStorage.getItem(KLK_NOTICE) || {}
    const noticeList = this.noticeMap[this.klook.language]

    if (!isEmpty(noticeList)) {
      this.noticeList = noticeList
    } else {
      await this.fetchNotice()
    }
  }
}
