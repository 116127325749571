




















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import CardScore from './card-score.vue'

@Component({
  components: {
    CardScore
  }
})
export default class Index extends Vue {
  @Prop() data!: any
  @Prop({ type: String, default: 'horizontal' }) direction!: 'horizontal' | 'vertical'

  get subTitle() {
    const { standard_act_name = '', standard_act_num } = this.data || {}
    if (standard_act_num === 1) {
      return this.$t('78233', { attraction_name: standard_act_name })
    }
    return this.$t('76909', { Attraction_name: standard_act_name, num: standard_act_num - 1 })
  }

  get fromPrice() {
    const fromPrice = this.getPrice('from_price')
    if (fromPrice) {
      return this.$t('activity_detail_from_price', ['14px', fromPrice])
    }
    return ''
  }

  getPrice(attr: string) {
    const priceInfo = this.data?.price_show || {}
    return priceInfo[attr]
  }
}
