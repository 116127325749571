

















import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Loading extends Vue {
  @Prop({ default: 1 }) xNumber!: number | string
  @Prop({ default: 1 }) yNumber!: number | string
  @Prop({ default: '100%' }) loadingWidth!: string
}
