
























import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import ActivitySubtitle from '~/components/experience-booking/experience-activity/title/subtitle.vue'

@Component({
  components: {
    ActivitySubtitle
  }
})
export default class ActivityTitle extends BusinessBase {
  static displayName = 'Activity_title'
  @State klook!: Data.Klook

  get iconVisible() {
    // 设计说拿掉
    return false
    // white label 中不展示标题前的 icon
    // return this.klook.platform !== 'mobile' && !this.klook.utilConfig.whiteLabelUtilConfig && this.businessData.icon
  }

  get basicData() {
    return this.$attrs['basic-data'] || null
  }

  get isHeroPage() {
    const basicData = this.basicData as any
    const act_product_type = basicData?.act_product_type ?? ''
    return act_product_type === 'hero_page'
  }

  get titleStyle() {
    // const fields = this.sectionData?.fields ?? []
    // const font = fields.find((item: any) => item.key === 'font')
    // const fontSize = font?.value ?? ''
    // const reg = /^[1-9]\d*$/
    // if (typeof fontSize === 'number') {
    //   return {
    //     fontSize: fontSize + 'px'
    //   }
    // }
    return {}
  }

  get cClass() {
    return `activity-experience-title_${this.klook.platform}`
  }

  get subtitleHeight() {
    return this.isDesktop ? 80 : 160
  }
}
