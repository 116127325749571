import { Component, Prop, State, Vue } from 'nuxt-property-decorator'
import RateItem from './rateitem.vue'
import { HomePage } from '~/types/traveller/home-page'
// import MockData from './mock.json'

export type LowestPriceInfo = {
  title: string,
  description: string,
  remind: string,
  deeplink: string,
  btn_title: string,
  show_min: number,
  lowest_rate_list: LowestRateItem[]
}

export type LowestRateItem = {
  icon_url: string,
  format_price: string,
  text_color: string
}

@Component({
  components: {
    RateItem
  }
})
export default class ActivityHotel extends Vue {
  @Prop() section!: HomePage.SectionItem
  @State(state => state.klook.platform) platform!: Data.Platform

  get lowestPriceInfo(): any {
    const data: any = this.section.body?.content?.data
    if (!data || !data.lowest_rate_list) {
      this.$emit('hide-section')
      return { lowest_rate_list: [] }
    }
    return data
  }

  get dataSpmModule() {
    return `ToHotelVisitHotelSite?hotel_${this.lowestPriceInfo.hotel_id}`
  }

  goHotelDetail() {
    if (!this.lowestPriceInfo.deeplink) {
      return
    }
    const a = document.createElement('a')
    a.href = this.lowestPriceInfo.deeplink
    a.target = this.platform === 'mobile' ? '_self' : '_blank'
    document.body.appendChild(a)
    a.click()
    setTimeout(() => document.body.removeChild(a), 500)
  }
}
