

























import { Vue, Component, State, Prop } from 'nuxt-property-decorator'

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop() isValidate?: boolean
  @Prop() flexType?: string | 'flex-column'
  @Prop() isDate?: boolean
  @Prop() showRight?: boolean
  @Prop() timeData?: any
  @Prop() styleType?: string | 'select-time-type'
  @Prop() autoHideExtra?: boolean
  @Prop() timeExtra?: any
  @Prop({ default: 'bottom' }) placement?: string

  get calcSelectProps() {
    const { placement } = this
    return {
      'max-height': 240 - 16,
      'style-type': 'filled',
      placeholder: this.$t('14651'),
      placement
    }
  }

  get calcValue() {
    return (key: string) => {
      return this.timeData?.[key]?.id || 0
    }
  }

  get calcInList() {
    return this.timeExtra?.in || []
  }

  get calcOutList() {
    return this.timeExtra?.out || []
  }

  rules: any = this.getRules()

  getRules(): any {
    const obj: any = {
      in: {
        hasError: null,
        validate: (data: any, rule: any) => {
          const isb = !this.autoHideExtra || this.calcInList?.length
          if (isb && !data?.id) {
            rule.hasError = new Error(this.$t('global.select.empty_error'))
            return rule?.hasError
          } else {
            rule.hasError = null
          }
        }
      },
      out: {
        hasError: null,
        validate: (data: any, rule: any) => {
          const isb = !this.autoHideExtra || this.calcOutList?.length
          if (isb && !data?.id) {
            rule.hasError = new Error(this.$t('global.select.empty_error'))
            return rule?.hasError
          } else {
            rule.hasError = null
          }
        }
      }
    }
    return obj
  }

  get calcTimeFormatStr() {
    return (key: string) => {
      return this.timeData?.[key]?.startEndFormatStr || ''
    }
  }

  get calcActive() {
    return (key: string) => {
      const { styleType, timeData } = this
      return styleType === 'select-time-type' && timeData?.key === key ? 'on' : ''
    }
  }

  get calcFirstKey() {
    return this.isDate ? this.$t('13541') : this.$t('88952')
  }

  get calcLastKey() {
    return this.isDate ? this.$t('15987') : this.$t('88953')
  }

  checkValidate() {
    const { timeData, rules } = this
    const arr = Object.keys(rules).map((key: string) => {
      const val = timeData[key]
      const isb = rules[key].validate(val, rules[key])
      return !isb
    })
    return arr.every((v) => v)
  }

  clickHandler(key: string, value: number) {
    const { timeExtra, rules } = this
    const obj = timeExtra?.[key]?.find((o: any) => o.id === value) || {}
    rules?.[key] && this.$set(rules[key], 'hasError', !obj)
    this.$emit('clickTime', key, obj)
  }

  mounted() {
    this.isValidate && this.checkValidate()
  }
}
