
































import { Component, Prop, namespace, State, Watch, Ref, mixins } from 'nuxt-property-decorator'
import Scrollbase from './scrollspy'

const ActivityModule = namespace('traveller/activity')

@Component
export default class PackageOptionsCommonTabs extends mixins(Scrollbase) {
  @Prop({ type: Array, default: () => [] }) packageList!: any[]
  @Prop({ type: Object, default: () => {} }) comboInfo!: any
  @Prop({ type: Boolean, default: () => false }) isGroup!: Boolean
  @State((state) => state.klook.platform) platform!: Data.Platform
  @ActivityModule.Action setCurrPackageOptionTab!: Function
  @Ref() comboTab!: any
  active: string = ''

  TAB_NAME = {
    STANDALONE: 'standalone',
    COMBO: 'combo'
  }

  TabType_Track_Map = {
    [this.TAB_NAME.STANDALONE]: 'Standalone',
    [this.TAB_NAME.COMBO]: 'Combo'
  }

  @Watch('active')
  handleChangeTab(val: 'standalone' | 'combo' | '') {
    this.setCurrPackageOptionTab(val)
  }

  @Watch('initPkgType', { immediate: true })
  handleWatchInitPkgType(val: string) {
    this.active = val
  }

  get standalonePkg() {
    return this.packageList || []
  }

  get comboPkgInfo() {
    return this.comboInfo || {}
  }

  get hasComboPkg() {
    return (this.comboPkgInfo?.packages || []).length > 0
  }

  get hasStandalonePkg() {
    return (this.standalonePkg || []).length > 0
  }

  get isOnlyStandalone() {
    return this.hasStandalonePkg && !this.hasComboPkg
  }

  get isOnlyCombo() {
    return !this.hasStandalonePkg && this.hasComboPkg
  }

  get isWith2TypePkg() {
    return this.hasStandalonePkg && this.hasComboPkg
  }

  get comboDiscount() {
    return this.comboPkgInfo?.max_discount_text || ''
  }

  get initPkgType() {
    const { TAB_NAME } = this
    if (this.isOnlyStandalone) {
      return TAB_NAME.STANDALONE
    } else if (this.isOnlyCombo) {
      return TAB_NAME.COMBO
    } else if (this.isWith2TypePkg) {
      return TAB_NAME.STANDALONE
    } else {
      return ''
    }
  }

  get spmExt() {
    const { active: currTab, TAB_NAME, TabType_Track_Map } = this
    return {
      TabType: currTab ? TabType_Track_Map[currTab] : '',
      listIndex: currTab === TAB_NAME.STANDALONE ? 0 : 1,
      listLen: Object.keys(TAB_NAME)?.length || 0
    }
  }

  onChangeTab(currTab: 'standalone' | 'combo') {
    this.active = currTab
  }

  get getSpmTrack() {
    const { listIndex, listLen, TabType } = this.spmExt
    return `PackageTab_LIST?idx=${listIndex}&len=${listLen}&ext=${this.$getExt({
      TabType
    })}`
  }

  beforeDestroy() {
    this.active = this.initPkgType
  }
}
