















































import { Component, Prop, Inject } from 'nuxt-property-decorator'
import UnitPrice from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/unit-price.vue'
import ActivityBookingStartDate from '~/components/traveller/activity/start-date/activity-booking-start-date.vue'
import CreditBase from '~/components/experience-booking/experience-activity/package-options/base/credit-base'

import { Activity } from '~/types/traveller/activity'
import { HomePage } from '~/types/traveller/home-page'
import { DefaultFieldsConfig } from '~/share/data/experience/fieldsConfigUtils.js'
import ExpTagging from '~/components/experience/tagging/index.vue'
import CutDown from '~/components/experience/cut-down/index.vue'
import { isLessThanTargetTime } from '~/share/count-down'

@Component({
  components: {
    ExpTagging,
    UnitPrice,
    ActivityBookingStartDate,
    CutDown
  }
})
export default class PackageCardUnit extends CreditBase {
  @Prop() packageTicketType!: number
  @Prop({ default: () => ({}) }) unit!: Activity.unit
  @Prop({ default: true }) priceVisible!: boolean
  @Inject({ from: 'fieldsConfig2section', default() { return DefaultFieldsConfig } }) fieldsConfig2section!: HomePage.AllFieldsIF
  @Prop() buttonDisabled!: boolean
  @Prop({ default: '' }) disabledText!: string
  @Prop({ default: false }) isWarmingOrSuspended!: boolean

  getCalcTags2sku(unit: any) { // mweb预订页-旧流程-sku
    const discountTags = unit?.product_tags?.discount_tags || []
    const tagsList: any = []
    if (discountTags?.length) {
      discountTags.forEach((item: any) => {
        if (item?.inner_vars?.rate_plan?.count_down_end_time) {
          const flag = isLessThanTargetTime(item.inner_vars.rate_plan.count_down_end_time)
          flag && tagsList.push(item)
        } else {
          tagsList.push(item)
        }
      })
    }

    return tagsList
  }

  getCutDownTimeList(unit: any) {
    const tags = this.getCalcTags2sku(unit)
    const list: any = []

    if (tags?.length) {
      tags.forEach((item: any) => {
        const endTime = item?.inner_vars?.rate_plan?.count_down_end_time
        endTime && list.push(endTime)
      })
    }

    return list
  }

  reload() {
    window && window.location.reload()
  }

  get credits() {
    return this.unit.credits
  }

  get currentButtonText() {
    return this.unit?.isSoldOut ? this.$t('global.sold_out') : this.$t('11888')
  }
}
