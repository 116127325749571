




































import { Component, Prop, State, Vue, Watch } from 'nuxt-property-decorator'
import ImageGalleryViewerDesc from './image-gallery-viewer-desc.vue'
import ActivityLayer from '~/components/traveller/activity/layer/activity-layer.vue'
import KlkStars from '~/components/traveller/activity/stars.vue'
import ImageZoomer from '~/components/traveller/activity/image-zoomer/index.vue'
import ReviewUser from '~/components/experience-booking/experience-activity/recent-review/review-user.vue'

@Component({
  components: {
    ActivityLayer,
    KlkStars,
    ImageZoomer,
    ReviewUser,
    ImageGalleryViewerDesc
  }
})
export default class ImageGalleryViewer extends Vue {
  @State klook!: Data.Klook
  @Prop() images!: any[]
  @Prop({ type: Number, default: 0 }) index!: number
  @Prop() reviewsMap!: {[prop: string]: any}
  @Prop({ default: false }) showBuyNow!: boolean

  $swiper: any = null

  @Watch('index')
  indexChange(val: number) {
    if (val >= 0) {
      this.$swiper.slideTo(val)
    }
  }

  swiperOptions = {
    slidesPerView: 1,
    initialSlide: this.index,
    paginationClickable: true,
    spaceBetween: 16,
    pagination: {
      el: '.swiper-pagination',
      type: 'fraction'
    }
  }

  close() {
    this.$emit('close')
  }

  getReviewInfo(id: number) {
    const review = this.reviewsMap[String(id)]
    return review || {}
  }

  mounted() {
    this.$swiper.on('slideChange', () => {
      this.$emit('slide-change')
    })
  }
}
