
















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class AdaptiveCard extends Vue {
    @Prop({ type: Number, default: 3 / 4 }) ratio!: number
    @Prop() image!: string
    @Prop() href!: string
    @Prop() imageType!: string

    get linkStyle() {
      return {
        paddingBottom: `${1 / this.ratio * 100}%`
      }
    }

    // 防止a标签嵌套闭合导致的渲染不一致
    handleClick() {
      if (this.href) {
        window.location.href = this.href
      }
    }
}
