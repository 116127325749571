




import { Component, Vue, State } from 'nuxt-property-decorator'
import { SectionSkeleton } from '~/components/common/skeleton-data/index.js'

@Component({})
export default class SectionLoading extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  SectionSkeleton = SectionSkeleton

  get skeletonData() {
    const { platform, SectionSkeleton } = this
    return platform === 'desktop' ? SectionSkeleton.desktop.base : SectionSkeleton.mobile.base
  }
}
