










import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IconDestination } from '@klook/klook-icons'
import Map from '~/components/traveller/activity/activity-map/map.vue'

@Component({
  components: {
    IconDestination,
    Map
  }
})
export default class ActivityLocation extends Vue {
  @Prop() map!: string

  mapShow: number = 0
  showMap() {
    this.mapShow += 1
  }
}
