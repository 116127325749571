






















import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import { isLessThanTargetTime } from '~/share/count-down'

@Component
export default class ActivityPackageBtn extends Vue {
  @Prop() openDateTip?: string
  @Prop() content!: string
  @Prop() disabled!: boolean
  @Prop() active!: boolean
  @Prop() unChoose!: boolean
  @Prop() discount!: number
  @Prop() promoTag!: any

  promoTagText = ''

  @Watch('promoTag', { immediate: true })
  onPromoTagChange(val: any) {
    if (val) {
      const endTime = val?.inner_vars?.rate_plan?.count_down_end_time
      if (endTime) {
        const flag = isLessThanTargetTime(endTime)
        flag && (this.promoTagText = val.text)
        this.promoTagText = flag ? val.text : ''
      } else {
        this.promoTagText = val.text
      }
    } else {
      this.promoTagText = ''
    }
  }

  @Watch('discount')
  handleDiscountChange() {
    this.setTextMinWidth()
  }

  get poptipText() {
    if (this.unChoose) {
      return this.$t('activity.date.unavailable')
    }
    if (this.disabled) {
      return this.$t('activity.option.unshot_desc')
    }
    return ''
  }

  setTextMinWidth() {
    if (this.$isServer) {
      return null
    }

    this.$nextTick(() => {
      this.checkLayout()
    })

    // fix 从 booking options 页跳回来 html 需要渲染时间的问题
    setTimeout(() => {
      this.checkLayout()
    }, 1000)
  }

  checkLayout() {
    const domText = this.$refs.text as HTMLElement
    const domDiscount = this.$refs.discount as HTMLElement

    if (domText && domDiscount) {
      const width = domDiscount.scrollWidth || 0
      width && width > 40 && (domText.style.minWidth = `${width - 40}px`)
    }
  }

  setPromoTagWidth() {
    const domBox = this.$refs.box as HTMLElement
    const domPormotag = this.$refs.promoTag as HTMLElement
    if (domBox && domPormotag) {
      if (domPormotag.scrollWidth > 200) {
        domPormotag.style.width =
          domBox.scrollWidth > 200 ? '200px' : '100%'
      } else {
        domPormotag.style.width = domBox.scrollWidth > domPormotag.scrollWidth ? 'auto' : '100%'
      }
    }
  }
}
