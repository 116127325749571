import { Component, namespace, Vue } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'
import * as sessionStorage from '~/assets/scripts/sessionstorage'
import errorCode from '~/components/traveller/activity/book-now-error-code'
import { routerPush, getBookingNowParams } from '~/share/utils'

const authModule = namespace('auth')

interface AutoBooingInitDataIf {
  activityId: number,
  shopId: number,
  shoppingCartHandler: Function
}

@Component
export default class AutoBookingMinxin extends Vue {
  @authModule.State isLoggedIn!: boolean // 是否登录

  autoBookingRole = 'bookNow'

  autoBookingInitData: AutoBooingInitDataIf = {
    activityId: 0,
    shopId: -1,
    shoppingCartHandler: () => {}
  }

  private getPreOrderKey(activityId: number) {
    // 自定义sessionStorage的 key值
    return `activity_pre_order_${activityId}`
  }

  get calcAutoBooingShopId() {
    // shop_id
    const { shopId } = this.autoBookingInitData
    return shopId || -1
  }

  public initAutoBookingMinxin(data: AutoBooingInitDataIf) {
    // 暴露的初始化方法
    this.autoBookingInitData = data
    this.initMounted(data)
  }

  private initMounted(obj: AutoBooingInitDataIf) {
    // 初始化方法：内部实现
    const { activityId, shoppingCartHandler } = obj || {}
    const preOrderKey = this.getPreOrderKey(activityId)
    const preOrderSessionData = sessionStorage.getSessionData(preOrderKey)
    if (preOrderSessionData && this.isLoggedIn) {
      let data = null

      try {
        data = JSON.parse(preOrderSessionData)
      } catch (e) {
        // eslint-disable-next-line
        console.log('preOrderSessionData 解析错误！', preOrderSessionData)
      }

      if (data?.params) {
        this.autoBookingRole = data.params.shoppingcart_type ? 'bookNow' : 'addToCart'
        this.autoBookingRequest(data.params, { shoppingCartHandler })
      }
    }
    sessionStorage.deleteSessionData(preOrderKey)
  }

  async autoBookingRequest(paramsData: any, options: { shoppingCartHandler: Function }) {
    // 检查是否自动预订下单
    const shopId = this.calcAutoBooingShopId
    const api = shopId === -1 ? (this.autoBookingRole === 'addToCart' ? apis.bookingNow : apis.expPostPreOrderServiceNew) : this.autoBookingRole === 'addToCart' ? apis.addToShoppingCard : apis.postExpEditeShoppingCartAndnew
    let params = paramsData
    if (shopId === -1 && this.autoBookingRole !== 'addToCart') {
      const { arrangement_id, price_items } = params
      params = getBookingNowParams(arrangement_id, price_items)
    }
    this.$showLoading({ overlayColor: 'rgba(255, 255, 255, 0.7)' })

    try {
      const res = await this.$axios.$post(api, params)

      this.$hideLoading()

      if (res.success) {
        setTimeout(() => {
          if (this.autoBookingRole === 'bookNow') {
            routerPush(this, {
              name: 'ExperiencePay',
              query: {
                settlement_type: '1',
                shoppingcart_guid: res.result?.pre_order_id
              }
            })
          } else {
            const showShoppingCart = options?.shoppingCartHandler || (() => {
              // eslint-disable-next-line
              console.log('warn: function showShoppingCart undefined.')
            })
            shopId === -1 ? showShoppingCart() : (window.location.href = this.$href('/shoppingcart'))

            // 加入购物车成功埋点
            const id = res.result?.shoppingcart_id_str || res.result?.shoppingcart_id
            this.$inhouse.track('custom', 'body', {
              spm: 'AddToCartSuccessLoad',
              shoppingcart_id: id
            })
          }
        })

        return
      }

      if (res.error.code === '01001007027') {
        const option = {
          content: this.$t('16995-price_change_tips'),
          btnYes: this.$t('global.tips.okTxt')
        }
        this.$alert(option.content, {
          okLable: option.btnYes
        }).then(() => {
          window.location.reload()
        })
        return
      }

      this.autoBookingHandleError(res.error)
    } catch (e) {
      this.$hideLoading()
      this.autoBookingHandleError(e as any)
    }
  }

  private autoBookingHandleError(e: { code: string; message: string }) {
    // 错误处理方法
    const resCode = e.code
    if (errorCode.includes(resCode)) {
      this.$confirm(e.message, {
        okLabel: this.$t('global.confirm'),
        cancelLabel: this.$t('global.tips.cancelTxt')
      }).then(({ result: isb }) => {
        if (isb) {
          window.location.reload()
        }
      })
    } else {
      this.$confirm(e.message, {
        okLabel: this.$t('global.confirm')
      })
    }
  }
}
