




























































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import ImageGalleryMerchant from './image-gallery-merchant/index.vue'
import ImageViewer from './image-viewer.vue'
import ImageGalleryCustomer from './image-gallery-customer/index.vue'
import GalleryImage from './image-gallery-customer/components/gallery-image.vue'
import Logo from '~/components/experience/card-swiper/logo.vue'

// banner新图集组件
@Component({
  components: {
    ImageViewer,
    Logo,
    ImageGalleryCustomer,
    GalleryImage,
    ImageGalleryMerchant
  }
})
export default class ImageGallery extends Vue {
  @Prop({ type: Array, default: () => ([]) }) images !: {src: string}[]
  @Prop({ type: Boolean, default: false }) loading !: boolean
  @Prop({ type: Boolean, default: false }) loadingError !: boolean
  @Prop({ type: Boolean, default: false }) done !: boolean
  @Prop() reviewsMap!: Object
  @Prop() loadImage!: () => any
  @Prop() buyNow!: () => any
  @Prop() transfer!: boolean
  @Prop() photoType!: string
  @Prop({ default: false }) notFixed!: boolean
  @Prop({ default: false }) showBuyNow!: boolean
  @Prop({ type: Array, default: () => ([]) }) imagesGroup !: any[]

  imageViewerVisible = false
  index = 0

  // 为了把 loading 的高度撑起来
  get hasMinHeight() {
    return this.loading && !this.images?.length
  }

  preview(id: number) {
    const index = this.images.findIndex((item: any) => item.image_id === id)
    this.showImage(index)
  }

  showImage(index: number) {
    this.imageViewerVisible = true
    this.index = index
  }

  load() {
    this.loadImage && this.loadImage()
  }

  hideImageViewer() {
    this.imageViewerVisible = false
  }

  handleBuyNow() {
    this.imageViewerVisible = false
    this.$nextTick(() => {
      this.buyNow()
    })
  }
}
