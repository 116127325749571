



























































import { Component, Prop, Vue, State } from 'nuxt-property-decorator'
import AttractionPreviewTag from '../attraction-preview-tag.vue'
import AttractionPreviewPrice from '../attraction-preview-price.vue'
import PassCalendar from '../pass-calender/pass-calendar-mobile.vue'
import PassCalendarDesktop from '../pass-calender/pass-calender-desktop.vue'
import DataLoader from './loaded-data.vue'
import AttractionPrevieBox from './attraction-preview-box.vue'
import PublishAi from '~/components/common/publish-ai/index.vue'
import CardBanner from '~/components/experience-booking/experience-activity/package-options/attraction-preview/components/card-banner.vue'
import { renderMarkdownObj } from '~/assets/scripts/markdown'
import ActMarkdown from '~/components/experience-booking/experience-activity/markdown/index.vue'

@Component({
  components: {
    CardBanner,
    PublishAi,
    DataLoader,
    AttractionPrevieBox,
    AttractionPreviewPrice,
    ActMarkdown,
    AttractionPreviewTag,
    PassCalendar,
    PassCalendarDesktop
  }
})
export default class AttractionPreviewDetail extends Vue {
  @Prop({ default: {} }) data!: any
  @Prop() loading!: boolean
  // 后端会返回是否渲染，默认渲染passTypeName
  @Prop({ default: true }) showType!: boolean

  @State((state) => state.klook.platform) platform!: Data.Platform

  renderMarkDownHtml(content: any) {
    if (!content) {
      return ''
    }
    return renderMarkdownObj(content)
  }

  get activityId() {
    return this.data?.activity_id || 0
  }

  get activityName() {
    return this.data?.activity_name || ''
  }

  get activityBanner() {
    return this.data?.activity_banner || ''
  }

  get passTypeName() {
    return this.data?.pass_type_name || ''
  }

  get packageList() {
    return this.data?.package_list || []
  }

  get tags() {
    return this.data?.product_tags?.attribute_tags || []
  }

  get isAiTranslation() {
    return !!this.data?.is_ai_translation
  }

  get slogan() {
    return this.data?.inclusive
  }

  getSkuid(packages: any) {
    return (packages.sku_list || []).map((sku: any) => sku.sku_id)
  }
}
