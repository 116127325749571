









import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class BaseTransitionOnce extends Vue {
  @Prop({ default: 'fade-up' }) transitionName!: string
  @Prop({ default: false }) disabled!: boolean
  elementObserver: any = null
  visible = false

  mounted() {
    if (process.client && !this.disabled) {
      this.createObserver()
    } else {
      this.visible = true
    }
  }

  beforeDestroy() {
    this.destroyObserver()
  }

  createObserver() {
    if ('IntersectionObserver' in window) {
      this.elementObserver = new IntersectionObserver(
        entries => entries.forEach((e) => {
          // this.visible = e.intersectionRatio > 0
          if (e.intersectionRatio > 0) {
            this.visible = true
            this.destroyObserver()
          }
        })
        // { threshold: [1] }
      )

      this.elementObserver.observe(this.$el)
    }
  }

  destroyObserver() {
    this.elementObserver && this.$el && this.elementObserver.unobserve(this.$el)
  }
}
