























import { Component, Prop, Vue, State } from 'nuxt-property-decorator'
import PackageCardStatus from './package-card-status.vue'
import PackageCardTitle from './title.vue'
import PackageCardInfo from './package-card-info.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import DynamicVerticalIcons
  from '~/components/experience-booking/experience-activity/dynamic-component/icons/dynamic-vertical-icons.vue'

@Component({
  components: {
    PackageCardTitle,
    PackageCardStatus,
    DynamicVerticalIcons,
    PackageCardInfo
  }
})
export default class MobilePackageCardMainContent extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: null }) packageData!: ExperienceActivity.IPackageCard

  get availableDateVisible() {
    return this.packageData?.package_ticket_type !== 3
  }

  get isShowPkgIcons() {
    return this.klook.utilConfig?.show_pkg_card_icons === 1
  }
}

