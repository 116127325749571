
















import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class CommaText extends Vue {
  @Prop() textData!: string[]

  get dataList() {
    return (this.textData || []).reduce((accu, curr) => {
      if (accu.length) {
        accu.push({
          type: 'icon',
          content: ''
        })
      }

      accu.push({
        type: 'text',
        content: curr
      })
      return accu
    }, [] as Record<string, string>[])
  }
}
