import createWrapper from './create-wrapper'
import lazyObserver from './utils/lazy-observer'
import LazyComponent from './components/lazy-component.vue'
import BaseComponent from './components/base-component'
import ScrollNav from './components/scroll-nav/index.vue'
import ttd2Platform from './utils/ttd2Platform'

import CommonContainer from './container/common.vue'

export default createWrapper

export {
  ScrollNav,

  // common类型 container
  CommonContainer,

  // 基础component组件，View组件统一继承
  BaseComponent,

  // 懒加载组件
  LazyComponent,
  lazyObserver,
  ttd2Platform
}

export * from './types'
