






import { Component, Vue } from 'nuxt-property-decorator'
import DotFlashing from '~/components/traveller/activity/activity-bottom-bar/mobile/dot-flashing.vue'

@Component({
  components: {
    DotFlashing
  }
})
export default class ActivityDotFlashing extends Vue {
}
