

































































import { Component, Watch, Prop } from 'nuxt-property-decorator'
import PackageTimeslotBase from '~/components/experience-booking/pay/traveller-info/components/package-timeslot-base'
import PackageTimeslotGroup from '~/components/experience-booking/pay/traveller-info/components/PackageTimeslotGroup.vue'
import PackageTimeslotLoading from '~/components/experience-booking/pay/traveller-info/components/PackageTimeslotLoading.vue'
import PackageTimeslotFailure from '~/components/experience-booking/pay/traveller-info/components/PackageTimeslotFailure.vue'
import eventBus from '~/pages/experience/pay/common/event-bus'

@Component({
  components: {
    PackageTimeslotGroup,
    PackageTimeslotLoading,
    PackageTimeslotFailure
  }
})
export default class PackageTimeslot extends PackageTimeslotBase {
  @Prop({ default: '' }) activityId?: number

  isError = false

  get isDisabled() {
    return !this.packageId || !this.startTime || !this.skus?.length
  }

  @Watch('packageId')
  packageIdChange() {
    this.resetPackageTimeslot()
  }

  @Watch('startTime')
  startTimeChange() {
    this.resetPackageTimeslot()
  }

  @Watch('isDisabled')
  isDisabledChange() {
    this.isError = false
  }

  handleCurrentClosePop() {
    this.isError = false
    this.handleClosePop()
  }

  timeslotError() {
    this.isError = true
    this.$el && this.$el.scrollIntoView()
  }

  validateTimeslot(callback: Function) {
    const currentSelectedId = this.selectedItem?.session_id

    // 没有选 timeslot
    if (!currentSelectedId) {
      this.$toast(this.$t('161244'))
      this.timeslotError()
      return
    }

    // 没有库存
    const currentTimeslot = this.timeslotList.find(item => item.session_id === currentSelectedId)
    if (!currentTimeslot) {
      this.$toast(this.$t('110055'))
      this.resetPackageTimeslot()
      this.timeslotError()
      return
    }

    // 库存不足
    const totalUnitCount = this.skus?.reduce((accu, curr) => {
      return accu + (curr.count || 0)
    }, 0) || 0
    if (totalUnitCount > currentTimeslot.inventory) {
      this.$toast(this.$t('110056'))
      this.timeslotError()
      return
    }

    typeof callback === 'function' && callback()
  }

  mounted() {
    eventBus.$on('validatePackageOptionUsjTimeslots', this.validateTimeslot)
  }

  beforeDestroy() {
    eventBus.$off('validatePackageOptionUsjTimeslots', this.validateTimeslot)
  }
}
