import { Component } from 'nuxt-property-decorator'
import { KlkVerticalCard, KlkHorizontalCard } from '@klook/klook-card'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import '@klook/klook-card/dist/esm/index.css'

import { imageFormat } from '~/share/utils'

@Component({
  components: {
    KlkVerticalCard,
    KlkHorizontalCard
  }
})
export class YouMayAlsoLikeMixins extends BusinessBase {
  get cardList() {
    const { cards } = this.businessData || {}
    // 压缩图片
    return (cards || []).map((item: any) => {
      const { data: { cover_url = '' } } = item
      const coverUrl = imageFormat(cover_url, 'mayYouAlsoLike')
      return {
        ...item,
        data: {
          ...item.data,
          cover_url: coverUrl
        }
      }
    })
  }

  get isShowPromotionTags() {
    return !!this.isDesktop
  }

  get perView() {
    return this.klook.platform === 'mobile' ? 2 : 4
  }

  get standardCardBind() {
    const { isDesktop } = this
    return {
      size: isDesktop ? 'medium' : 'small',
      styleType: isDesktop ? 'default' : 'modern',
      isShowReviewBooked: !!isDesktop
    }
  }

  get templateId() {
    // @ts-ignore
    return this.$attrs?.['basic-data']?.activity_page_template_id || 0
  }

  calcCardData(data: any) {
    const { review_obj = {} } = data
    return {
      ...data,
      review: {
        ...review_obj
      }
    }
  }

  // 非常trick的方案，组件库不支持不展示tag，mweb屏蔽掉promotion_tag 和 general_tag
  getCardShowData(_data: any) {
    const data = this.calcCardData(_data)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { promotion_tag, general_tag, sub_title, ...rest } = data
    const cardShowData = this.isShowPromotionTags ? {
      ...data
    } : {
      ...rest
    }
    return cardShowData
  }

  getCardSpm(card: any, index: number) {
    const idx = index
    const { track_info = {} } = card
    const { object_id: oid, extra = {} } = track_info || {}
    const ext = {
      ...extra,
      Template_id: this.templateId
    }
    return `YouMayAlsoLike::::Activity_LIST?oid=${oid}&idx=${idx}&len=${this.cardList?.length}&ext=${encodeURIComponent(
      JSON.stringify(ext)
    )}`
  }
}
