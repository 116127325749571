import { Vue, Component, State } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { getStandardDateFormat } from '~/share/data/datetime'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

export const getDefDateObj = () => {
  return {
    cacheData: null,
    days: 0,
    minStock: 0,
    stocks: [],
    list: []
  }
}

export const getDefTimeObj = () => {
  return {
    cacheData: null,
    key: '',
    in: {},
    out: {}
  }
}

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  dateObj: any = getDefDateObj()
  timeObj: any = getDefTimeObj()
  wifiSimParams: any = {}
  isRangeTimeValidate = false

  get calcRightPriceDatetime() {
    const { calcPickupDatetime, calcReturnDatetime } = this
    const arr = []
    calcPickupDatetime && arr.push(calcPickupDatetime)
    calcReturnDatetime && arr.push(calcReturnDatetime)
    return arr.join(' - ')
  }

  get calcPickupDatetime() {
    const { dateObj, timeObj, formatStandardDate } = this
    const arr = []
    const date = dateObj.list?.[0]
    date && arr.push(formatStandardDate(date))
    timeObj.in?.id && arr.push(`${timeObj.in.start}-${timeObj.in.end}`)
    return arr.join(', ')
  }

  get calcReturnDatetime() {
    const { dateObj, timeObj, formatStandardDate } = this
    const arr = []
    const date = dateObj.list?.[1]
    date && arr.push(formatStandardDate(date))
    timeObj.out?.id && arr.push(`${timeObj.out.start}-${timeObj.out.end}`)
    return arr.join(', ')
  }

  formatStandardDate(date: string) {
    if (!date) {
      return ''
    }
    const d = getStandardDateFormat(
      date,
      this.$t.bind(this),
      this.klook.language,
      1
    )
    return d
  }

  getDefDateObj() {
    return getDefDateObj()
  }

  getDefTimeObj() {
    return getDefTimeObj()
  }
}
