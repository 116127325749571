




















































































import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import throttle from 'lodash/throttle'
import reduce from 'lodash/reduce'
import isEmpty from 'lodash/isEmpty'
import ImageSwiper from './image-swiper/index.vue'
import apis from '~/share/data/apis'
import { urlTemplate } from '~/share/utils'
import ActivityCommentItemInGallery
  from '~/components/traveller/activity/activity-reviews/desktop/activity-gallery/activity-comment-item-in-gallery.vue'

@Component({
  components: {
    ActivityCommentItemInGallery,
    ImageSwiper
  }
})
export default class ImageGallery extends Vue {
  @Prop({ type: Number, default: 8 }) contentMaxLine!: number
  @Prop({ default: false }) hackCloseBtn?: boolean
  @Prop({ default: '' }) title?: string
  @Prop({ default: false }) dontGetImages?: boolean
  @Prop({ default: () => [] }) bannerList!: any[]
  @Prop() activityId!: number | string
  @Prop() showGallery!: boolean
  @Prop() defaultTag!: number
  @Prop({ default: 0 }) currentIndex!: number
  @Prop({ default: null }) overrideImages!: any[]
  @Prop({ default: null }) overrideReview!: any

  @Watch('showGallery')
  showGalleryChange(val: boolean) {
    if (val) {
      this.currentTab = this.defaultTag || 0
    }
  }

  @Watch('overrideImages', { immediate: true })
  overrideImagesChange(val: any[]) {
    if (val) {
      this.initImages()
    }
  }

  imageList: any [] = []
  reviews: any [] = []
  page: number = 1
  pageSize: number = 8
  total: number = 0
  currentReview: any = null

  mounted() {
    this.getImages(true)
  }

  get calcShowNavTag() {
    const { bannerList, total } = this
    return bannerList && bannerList.length && total
  }

  initImages() {
    if (this.overrideImages?.length && this.overrideReview) {
      this.currentTab = this.defaultTag
      this.imageList = this.overrideImages.map((item: any) => ({ ...item, reviewId: item.review_id }))
      this.currentReview = this.overrideReview
      this.reviews[this.imageList[0].reviewId] = this.overrideReview
      this.total = this.imageList.length
    }
  }

  handleBuyNow() {
    this.$emit('close')
    this.$nextTick(() => {
      this.$emit('buy-now')
    })
  }

  changeCommentLike(data: any) {
    const review = this.reviews[data.reviewId]
    this.$set(review, 'like_count', review.like_count + 1)
    this.$set(review, 'has_liked', true)
  }

  slideChangeTransitionEnd() {
    this.throttleGetImages()
  }

  throttleGetImages: any = throttle(() => {
    this.getImages()
  }, 3000, { trailing: true })

  async getImages(firstLading: boolean = false) {
    if (this.dontGetImages) {
      return
    }
    if (this.overrideImages?.length) {
      return
    }

    const images = await this.getReviewsImages({ page: this.page, pageSize: this.pageSize })
    if (images?.imageTotalCount) {
      this.total = images.imageTotalCount
      const o = this.arrayToObj(images.reviewImagesInfo)
      this.reviews = Object.assign(this.reviews, o)
      this.imageList = this.imageList.concat(images.imageList)
      this.page = this.page + 1
      if (firstLading && this.imageList.length && this.reviews.length) {
        this.currentReview = this.reviews[this.imageList[0].reviewId]
      }
    }
  }

  arrayToObj(array: any) {
    const o: any = {}
    array.forEach((item: any) => {
      o[item.review_id] = item
    })
    return o
  }

  async getReviewsImages(data: { page: number, pageSize: number }) {
    const { activityId } = this
    const res = await this.$axios.$get(urlTemplate(apis.activityReviewGalleryImages, { activityId }), {
      regularUrl: '/v1/usrcsrv/activities/{*}/images/get',
      params: {
        page: data.page,
        limit: data.pageSize
      }
    })

    const { result } = res
    if (res.success && !isEmpty(result)) {
      const { image_total_count: imageTotalCount, review_images_info: reviewImageInfo } = result
      if (imageTotalCount === 0 || isEmpty(reviewImageInfo) || isEmpty(reviewImageInfo)) {
        return {}
      }

      /**
       * 评论reviews和图片image是一对多的关系
       * 通过图片image_id检索review_id，最后通过imageReviewMap得到review的信息
       * reviews的数据保存一份，因为有对数据对操作，这样可以保持所有数据的同步
       * @reviewImagesInfo Array 后端请求数据
       * @imageList Array 图片数组
       * @reviewsMap Object reviews和reviewId的map
       */

      const formatReviews = reduce(reviewImageInfo, (acc, v) => {
        acc.imageList = [...acc.imageList, ...(v?.images ?? []).map((img: any) => ({ ...img, reviewId: v.review_id }))]
        acc.reviewsMap = { ...acc.reviewsMap, [v.review_id]: v }

        return acc
      }, {
        imageList: [] as any[],
        reviewsMap: {}
      })

      return {
        imageTotalCount: result.image_total_count,
        reviewsCount: result.reviews_count,
        reviewImagesInfo: result.review_images_info,
        imageList: formatReviews.imageList,
        reviewsMap: formatReviews.reviewsMap
      }
    }
  }

  BigSwiperSlideChange(idx: number) {
    const reviewId = this.imageList[idx]?.reviewId ?? undefined
    if (!reviewId) {
      this.currentReview = undefined
      return
    }
    this.currentReview = this.reviews[reviewId]
  }

  closeModal() {
    this.$emit('close')
  }

  currentTab: number = 0

  switchTabs(tab: number) {
    this.currentTab = tab
    this.$nextTick(() => {
      const gallery0 = this.$refs[`gallery${tab}`] as any
      gallery0.updateSwiper()
    })
  }
}
