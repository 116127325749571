




































import { Component, Vue, Prop, State, Watch } from 'nuxt-property-decorator'
import ActivityDatePicker from './activity-date-picker.vue'
import { Schedule } from './types'
import CalenderSwitcher
  from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-switcher.vue'

@Component({
  components: {
    ActivityDatePicker,
    CalenderSwitcher
  }
})
export default class SwitchableCalendar extends Vue {
  @State klook!: Data.Klook
  @Prop() schedules!: Schedule[]
  @Prop() packageSchedules!: Schedule[]
  @Prop() packageId!: number
  @Prop(({ default: '' })) packageName!: string
  @Prop(({ default: 0 })) calendarDays?: number
  @Prop({ default: () => [] }) legends!: any[]

  isPackageSchedule = this.packageId > 0
  isLoading: boolean = false
  isActivitySchedule = false
  isMannualChange = false

  @Watch('packageId')
  packageIdChange(val: number) {
    if (!this.isMannualChange && val && !this.isPackageSchedule) {
      this.isPackageSchedule = true
      this.isActivitySchedule = false
    } else {
      this.isActivitySchedule = !val
    }
  }

  @Watch('isPackageSchedule')
  isPackageScheduleChange(val: boolean) {
    if (this.packageSchedules) {
      this.isActivitySchedule = false
      this.$emit('changePackageType', val ? 'packageSchedules' : 'activitySchedules')
    } else {
      this.isPackageSchedule = false
    }
    if (!val) {
      this.$emit('recalcDatePicker')
    }
    this.isMannualChange = true

    this.pretendLoading()
  }

  @Watch('hasSchedule')
  hasScheduleChange(val: boolean) {
    this.isLoading = !val
  }

  get activitySchedules() {
    return this.isPackageSchedule ? this.packageSchedules : this.schedules
  }

  isEmpty(v: any[]) {
    return !v || !v.length
  }

  pretendLoading() {
    if (this.hasSchedule) {
      this.isLoading = true
      const t = setTimeout(() => {
        if (this.hasSchedule) {
          this.isLoading = false
        }
        clearTimeout(t)
      }, 650)
    }
  }

  get hasSchedule() {
    const isPackageScheduleNone = this.isPackageSchedule && this.isEmpty(this.packageSchedules)
    const isScheduleNone = !this.isPackageSchedule && this.isEmpty(this.schedules)

    return !(isPackageScheduleNone || isScheduleNone)
  }
}
