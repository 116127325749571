













import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import PublishAi from '~/components/common/publish-ai/index.vue'
import { queryString } from '~/share/utils'
import eventBus from '~/pages/experience/pay/common/event-bus'

@Component({
  components: {
    PublishAi
  }
})

export default class ActivityTranslateButtonSection extends BusinessBase {
  static displayName = 'Translation_bot'
  @State klook!: Data.Klook

  get isMobile() {
    return this.klook.platform === 'mobile'
  }

  get isSourceText() {
    return this.$route.query.translation === '1'
  }

  get activityId() {
    const { activity_id = '' } = this.businessData || {}
    return activity_id
  }

  selfCompData: any = null

  translateActionHandler() {
    if (this.calcCustomScrollEl) {
      const translation = String(!this.isSourceText ? 1 : 0) // 转string，否则isSourceText判断不正确
      this.$emit('customAiTranslate', { translation })
      return
    }
    this.translateAction()
  }

  translateAction() {
    let href = window.location.href.split('?')[0]
    href += '?' + queryString({
      ...this.$route.query,
      translation: !this.isSourceText ? 1 : 0
    })
    window.location.href = href
  }

  async mounted() {
    await null
    eventBus.$emit('moveContactBallAbove')
  }
}

