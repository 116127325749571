


























import { Component, Vue, Prop, Getter, State, namespace } from 'nuxt-property-decorator'
import Price from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/bottom-bar/price.vue'
import BookingBottomBtns from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/bottom-bar/booking-bottom-btns.vue'
import BookingBarWrap from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-bar-wrap.vue'
import ExpBookingSettlement from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-settlement'
import { Activity } from '~/types/traveller/activity'
import { ExperienceActivity } from '~/types/experience/activity-section'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    TotalSlot,
    BookingBarWrap,
    ExpBookingSettlement,
    BookingBottomBtns,
    Price
  }
})
export default class BookingBottomBar extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: String, default: 'bookNow' }) fromType!: 'bookNow'
  @Prop() hideDiscountTips!: boolean
  @Prop() units!: any[]
  @Prop() currentSelectedPackage!: ExperienceActivity.IPackageCard
  @Prop() selectedTime!: any
  @Prop() packageSchedules!: any
  @Prop() priceDetail!: any
  @Prop() openPaymentDetail!: () => void
  @Prop() packageSelectedDate!: string
  @Prop() totalUnitCount!: number
  @Prop() validator!: () => boolean
  @Prop() generateParams!: () => any
  @Getter isGuestCheckout!: boolean
  @ActivityModule.State preSettlementApiData!: any
  @ActivityModule.Getter preSettlementTips2getters!: any

  completeInfo = false
  // ModuleExtra: string = JSON.stringify({ CompletedInfo: false })

  get bookingBottomBtnsBinds() {
    return {
      currentSelectedPackage: this.currentSelectedPackage,
      validator: this.validator
    }
  }

  get itemExtra() {
    const selectedSkuList = (this.units || []).filter((item: Activity.unit) => item.count > 0)
    return JSON.stringify({
      CompletedInfo: this.completeInfo,
      Sku_id: selectedSkuList.map((item: Activity.unit) => item.skuId),
      Sku_num: selectedSkuList.map((item: Activity.unit) => item.count)
    })
  }
}
