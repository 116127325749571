



















































import { Component, Vue, State, Prop, Watch } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { Schedule } from '../../types'
import { CardSwiper } from '~/components/traveller/home-page/card-swiper'
import { CellDateUnit } from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/calendar/calendar.vue'
import { getStandardDateFormat } from '~/share/data/datetime'

@Component({
  components: {
    CardSwiper
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop() schedules!: Schedule[]
  @Prop() value!: string
  @Prop() visible!: boolean

  calendarDate: null | string = null

  @Watch('value')
  valueChange(val: string | null) {
    this.handleDateChange(val)
  }

  get cellDateList() {
    return this.availableDateList.filter((_, i) => i < 3)
  }

  get availableDateList() {
    const res: CellDateUnit[] = [
      {
        name: this.$t('today'),
        date: dayjs().format('YYYY-MM-DD')
      },
      {
        name: this.$t('search_tomorrow'),
        date: dayjs().add(1, 'day').format('YYYY-MM-DD')
      }
    ]

    const availableArr: any[] = (this.schedules || []).filter((o: any) => {
      o.name = this.formatDate(o.date)
      return !o.soldout && !o.disable
    })

    res.forEach((o: CellDateUnit) => {
      const item = availableArr.find((item: any) => {
        return item.date === o.date
      })
      if (item) {
        item.name = o.name
      }
    })
    return availableArr || []
  }

  handleClick(d: string) {
    const date = !d || d === this.value ? null : d
    this.handleDateChange(date)
  }

  handleCalendarDateCancel() {
    this.calendarDate = null
    this.$emit('input', null)
  }

  handleDateChange(d: string | null) {
    const target = this.cellDateList.find((item: CellDateUnit) => item.date === d)
    if (target || !d) {
      this.calendarDate = null
    } else if (!target && d) {
      this.calendarDate = d
    }
    this.$emit('input', d)
  }

  formatDate(d: string) {
    return getStandardDateFormat(
      d,
      this.$t.bind(this),
      this.klook.language,
      3
    )
  }
}
