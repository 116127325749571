




import { Component, Vue, Prop } from 'nuxt-property-decorator'
import type { SectionType } from '~/components/common/package-detail-v2/type'
import type { SectionData } from '~/components/common/package-detail-v2/sections/type'
import PackageDetailFold from '~/components/common/package-detail-v2/sections/fold.vue'
import PackageDetailExpand from '~/components/common/package-detail-v2/sections/expand.vue'
import { FOLD_TYPE_MAP } from '~/components/common/package-detail-v2/const.js'
import { transformData } from '~/components/common/package-detail-v2/sections/utils'

@Component({
  components: {
    PackageDetailFold,
    PackageDetailExpand
  }
})
export default class PackageDetailSection extends Vue {
  @Prop({ default: () => null }) section!: SectionType

  // 转换成 sectionData
  get sectionData(): SectionData {
    return transformData(this.section)
  }

  get componentName() {
    return this.sectionData.foldType === FOLD_TYPE_MAP.EXPAND ? 'PackageDetailExpand' : 'PackageDetailFold'
  }
}
