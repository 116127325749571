






import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'

// 可以在这里加推荐卡片loading状态

@Component
export default class ActivityCardsListWrap extends Vue {
  @Prop() activities!: any
  @Prop() state!: string

  get valid() {
    const list = Array.isArray(this.activities) ? this.activities : this.activities?.items
    const validList = (list || []).filter((v: any) => !v.sold_out)
    return validList?.length > 0
  }

  @Watch('state', { immediate: true })
  activitiesChange() {
    if (this.state === 'success' && !this.valid) {
      this.hideSection()
    }
  }

  hideSection() {
    const parent = this.$parent as any
    if (parent && parent.hideSection) {
      parent.hideSection()
    }
  }
}
