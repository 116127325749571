





















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { getNewFriendlyDateFormat } from '~/share/data/datetime'
import stars from '~/components/traveller/activity/stars.vue'

@Component({
  components: {
    stars
  }
})
export default class ReviewUser extends Vue {
  @State klook!: Data.Klook
  @Prop() score!: string | number
  @Prop() name!: string
  @Prop() date!: string
  @Prop({ type: [Number, String], default: 100 }) scoreTotal!: number | string

  getTimeText(date: string) {
    return getNewFriendlyDateFormat(date, this.$t.bind(this), this.klook.language)
  }
}
