



























































import { Component, Prop } from 'nuxt-property-decorator'
import qs from 'qs'
import ActivityCouponSection from '../components/activity-coupon-section.vue'
import MwebCouponCard from '../components/mweb-coupon-card.vue'
import { getStandardDateFormat } from '~/share/data/datetime'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import apis from '~/share/data/apis'

@Component({
  components: {
    ActivityCouponSection,
    MwebCouponCard
  }
})
export default class ActivityCoupon extends BusinessBase {
  @Prop() preview!: boolean

  isCouponDetailShow: boolean = false
  isCouponDetailTextShow: boolean = false
  isLoading: boolean = false
  couponDetailText: string = ''
  inhouseTagList: any = []
  refreshCouponList: any = null

  static displayName = 'MobileActivity_tag_coupon_entrance'

  get couponList() {
    const _arr: any = this.refreshCouponList || this.businessData?.items || []
    if (_arr.length === 0) {
      this.$emit('hide-section')
    }
    return _arr
  }

  get couponTitleList() {
    const _arr: any = []
    this.couponList.forEach((item: any, index: number) => {
      if (index < 2) {
        _arr.push(item.coupon_tag)
        this.inhouseTagList.push({ list: item.coupon_batch_id, TagText: encodeURIComponent(item.coupon_tag) })
      }
    })
    return _arr
  }

  couponListEnterClick() {
    this.isCouponDetailShow = true
  }

  getCouponData() {
    this.$axios.$get(apis.getCouponData, {
      params: {
        activity_id: this.businessData.activity_id
      }
    }).then((res: any) => {
      if (res.result) {
        this.refreshCouponList = res.result.items
      }
    }).catch(() => {
      return false
    })
  }

  couponStatus(status: string) {
    let _status = ''
    switch (status) {
      case 'tostart':
        _status = 'NotStarted'
        break
      case 'use':
        _status = 'ToBeUsed'
        break
      case 'get':
        _status = 'ToBeRedeemed'
        break
      case 'unusable':
        _status = 'AllOut'
        break
      default:
        break
    }
    return _status
  }

  couponCodeClick(data: any) {
    if (data.status === 'tostart') {
      // 未开始
      const date = getStandardDateFormat(new Date(data.startTime * 1000), this.$t.bind(this), this.klook.language, 1)
      const time = getStandardDateFormat(new Date(data.startTime * 1000), this.$t.bind(this), this.klook.language, 4)
      this.$toast(this.$t('30239', { date, time }))
      return false
    } else if (data.status === 'use') {
      // 去使用
      this.$toast(this.$t('30646'))
      return false
    } else if (data.status === 'get') {
      // 未领取
      this.isLoading = true
      this.$axios.$post(apis.redeemCouponCode, qs.stringify({
        code: data.code
      })).then((res: any) => {
        if (res.success) {
          this.isLoading = false
          this.$toast(this.$t('30648'))
          this.getCouponData()
          this.setInhousCustom(data, 'Success')
        } else if (res.error) {
          this.isLoading = false
          if (res.error.code === '4001') {
            // 未登录
            window.location.href = this.$href('/signin')
          } else {
            // 错误toast
            this.$toast(res.error.message)
            this.setInhousCustom(data, 'Fail')
          }
        }
      }).catch(() => {
        // 错误toast
        this.isLoading = false
        this.$toast(this.$t('5349'))
        this.setInhousCustom(data, 'Fail')
      })
    }
  }

  setInhousCustom(data: any, type: string) {
    this.$inhouse.track('custom', 'body', {
      spm: 'Activity.PromoCodeRedeem',
      oid: data.batchId,
      action_type: 'click',
      ext: {
        RedeemStatus: type,
        DiscountDesc: data.disDesc,
        SpecialDesc: data.specDesc
      }
    })
  }

  couponDetailTextShow(e: string) {
    this.couponDetailText = e
    this.isCouponDetailTextShow = true
  }
}
