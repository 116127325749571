

































































































































import { Vue, Component, Prop, Ref, Watch } from 'nuxt-property-decorator'
import throttle from 'lodash/throttle'
import { Activity } from '~/types/traveller/activity'
import PackageDetailCollapse from '~/components/common/package-detail-collapse/package-detail-collapse.vue'
import KlkExperienceItinerary from '~/components/common/itinerary/index'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import PublishAi from '~/components/common/publish-ai/index.vue'
import ItineraryFeedBack from '~/components/common/itinerary/feed-back/index.vue'

@Component({
  components: {
    PackageDetailCollapse,
    KlkExperienceItinerary,
    PublishAi,
    ItineraryFeedBack
  }
})
export default class PackageDetailList extends Vue {
  @Prop() sections!: any []
  @Prop() size!: 'large' | 'small'
  @Prop() packageDetail!: Activity.packageDetail
  @Prop({ default: true }) expandAll!: boolean
  @Prop({ default: true }) expandItinerarySection!: boolean
  @Ref() PackageDetailCollapse!: any
  @Prop({ type: Boolean, default: false }) isAiTranslation!: boolean

  visible: boolean = false
  active: string = 'itinerary'
  currentExpands: any [] = []
  currentExpandIndex: number = -1
  animate: string = 'itinerary'

  @Watch('visible')
  visibleChange(v: boolean) {
    if (v) {
      this.$inhouse.track('pageview', '#page-view-spm-wrap', { force: true })
    }
  }

  get feedBackProp() {
    return {
      packageId: this.packageDetail.packageId,
      activityId: this.packageDetail.activityId
    }
  }

  get tabsList() {
    return [
      {
        name: 'itinerary',
        label: this.$t('98295'),
        track: 'Itinerary'
      },
      {
        name: 'details',
        label: this.$t('98296'),
        track: 'MoreDetails'
      }
    ]
  }

  get packageId() {
    return this.packageDetail?.packageId ?? ''
  }

  get pageSpm() {
    return `TNA_PackageDetails?trg=manual&oid=package_${this.packageId}`
  }

  get isDetails() {
    return this.active === 'details'
  }

  get currentExpand() {
    const { currentExpandIndex, detailSections } = this
    return detailSections[currentExpandIndex] || {}
  }

  get itinerarySections() {
    return this.sectionsList.filter((item: any) => item.section_type === 'itinerary')
  }

  get detailSections() {
    return this.sectionsList.filter((item: any) => item.section_type !== 'itinerary').map((item: any, index: number) => {
      return {
        ...item,
        id: `collapse-item-id_${index}`
      }
    })
  }

  get pickupInfos() {
    const detailSections = this.detailSections || []
    let pickupInfos = null
    for (let index = 0; index < detailSections.length; index++) {
      const element = detailSections[index]
      const components = element?.components ?? []
      const data = components.find((item: any) => {
        const pick_up_meet_up = item.data?.pick_up_meet_up ?? null
        return !!pick_up_meet_up
      })
      if (data) {
        const departure_return = data.data?.pick_up_meet_up?.departure_return ?? []
        const pickupInfosData = departure_return.find((item: any) => {
          const pick_up_meet_up = item.pick_up_info
          return !!pick_up_meet_up
        })
        pickupInfos = pickupInfosData?.pick_up_info ?? null
        break
      }
    }
    return pickupInfos
  }

  get currentIcon() {
    const { currentExpandIndex, currentExpands } = this
    return currentExpands.includes(currentExpandIndex) ? 'icon_navigation_chevron_up' : 'icon_navigation_chevron_down'
  }

  get renderList() {
    const excludes = ['whats_included', 'itinerary']
    return this.sectionsList.filter((item: any) => !excludes.includes(item.section_type))
  }

  get sectionsList() {
    return this.sections.map((item: any, index: number) => {
      return {
        ...item,
        itemKey: index
      }
    })
  }

  handleBeforeEnter(el: any) {
    el.style.position = 'absolute'
    el.style.top = '0'
  }

  handleAfterEnter(el: any) {
    el.style.position = 'relative'
  }

  handleBeforeLeave(el: any) {
    el.style.position = 'relative'
  }

  handleAfterLeave(el: any) {
    el.style.position = 'absolute'
    el.style.top = '0'
  }

  getListTrack(data: any) {
    const ext = this.$getExt({
      SectionType: data.section_name_en || ''
    })
    return this.bindIHTrack({
      type: 'module',
      spm: 'PackageDetailSection',
      query: {
        oid: `package_${this.packageId}`,
        ext
      }
    })
  }

  getTabsTrack(key: string, index: number, exposure = true) {
    const ext = this.$getExt({
      TabType: key
    })
    const len = this.itinerarySections.length ? 2 : 1
    return this.bindIHTrack({
      type: 'module',
      spm: 'Tab_LIST',
      exposure,
      query: {
        ext,
        idx: index,
        len
      }
    })
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  grounpTitleClick(data: any) {
    const groupKeys = ['itinerary_return', 'itinerary_departure']
    const { group_key, group_id } = data
    if (groupKeys.includes(group_key)) {
      this.active = 'details'
      this.currentExpandIndex = this.detailSections.findIndex((item: any) => item.section_type === 'pick_up_meet_up')
      this.setCollapseActive()
      this.handleScrollTo(`#group_${group_id}`)
    }
  }

  setAnimate(key: string = '') {
    this.animate = key
  }

  showDetail(index: number, type: string) {
    this.visible = !this.visible
    this.setAnimate()
    this.currentExpandIndex = this.detailSections.findIndex((item: any) => item.itemKey === index)
    this.active = type === 'itinerary' ? 'itinerary' : 'details'
    this.setCollapseActive()
    this.handleScrollTo()
  }

  setCollapseActive() {
    this.PackageDetailCollapse.active = [...new Set([...this.currentExpands, this.currentExpandIndex])]
  }

  handleExpand() {
    const { currentExpandIndex, currentExpands } = this
    let expands = []
    if (currentExpands.includes(currentExpandIndex)) {
      expands = currentExpands.filter((item: any) => item !== currentExpandIndex)
    } else {
      expands = [...currentExpands, currentExpandIndex]
    }
    this.PackageDetailCollapse.active = expands
    this.currentExpands = expands
    this.handleScrollTo()
  }

  handleScrollTo(element: any = null) {
    setTimeout(() => {
      const { currentExpandIndex } = this
      const main = this.$refs.details as HTMLElement
      let elem
      let num = 0
      if (element) {
        elem = document.querySelector(element)?.parentNode
        num = 16
      } else {
        elem = document.querySelector(`#collapse-item-id_${currentExpandIndex}`) as HTMLElement
        num = 0
      }
      if (elem) {
        const offsetTop = elem.offsetTop - num
        main.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        })
      }
    }, 400)
  }

  handleChange(names: any) {
    this.currentExpands = names
  }

  onScroll = throttle(this.handleScroll, 350)

  handleScroll() {
    if (!this.isDetails) {
      return
    }
    const main = this.$refs.details as HTMLElement
    const detailSections = this.detailSections
    let index = -1
    for (let i = 0; i < detailSections.length; i++) {
      const elem = document.querySelector(`#collapse-item-id_${i}`) as HTMLElement
      const constHeight = i === 0 ? 0 : 48
      if (elem && elem.offsetTop - main.scrollTop - constHeight < main.offsetTop) {
        index = i
      }
    }
    this.currentExpandIndex = index
  }

  showItineraryDetail() {
    this.visible = true
    // this.active = 'itinerary'
  }

  mounted() {
    // const main = this.$refs.details as HTMLElement
    // main && main.addEventListener('scroll', this.onScroll)
    const haveIinerary = this.itinerarySections.length > 0
    this.active = haveIinerary ? 'itinerary' : 'details'
  }

  beforeDestory() {
    // const main = this.$refs.details as HTMLElement
    // main && main.removeEventListener('scroll', this.onScroll)
  }
}
