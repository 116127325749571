

































































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import PackageDetailContent from './new-package-content.vue'
import PackContent from '~/components/experience-booking/experience-activity/package-options/attraction-preview/desktop/index.vue'
import { Activity } from '~/types/traveller/activity'

@Component({
  components: {
    PackContent,
    PackageDetailContent
  }
})
export default class NewActivityPackageDetail extends Vue {
  @Prop({ default: {} }) packageDetail!: Activity.packageDetail;
  @Prop({ default: false }) needConvert!: boolean;
  // 抽屉模式不需要滚动条
  @Prop({ default: true }) needScroll!: boolean;
  @Prop() expandAll!: boolean;
  @Prop({ default: false }) showActivityTitle!: boolean
  @Prop({ type: Boolean, default: false }) useImageSwiper!: boolean
  @Prop({ default: '' }) groupPackName!: string
  @Prop({ type: Number, default: 0 }) imageCardSwiperType!: number
  @Prop({ type: String, default: '' }) imageGroupStyle!: string

  active = 'tab1';
  total = 0;

  get payload() {
    if (this.needConvert) {
      const detail = this.packageDetail
      return {
        product_tags: detail.product_tags,
        useSection: detail.use_section || true,
        sectionContent: detail.section_content,
        usageValidity: detail.usage_validity_render_obj,
        enableTranslationButton: detail.enable_translation_button,
        translationButtonStatus: detail.translation_status,
        activityName: detail.activity_name,
        packageName: detail.package_name,
        packageId: detail.id,
        activityId: detail.activity_id,
        packageSubName: detail.package_sub_name,
        passStandardActivityInfo: detail.pass_standard_activity_info
      }
    } else {
      return this.packageDetail
    }
  }

  get getClassName() {
    // 需要右侧滚动条的
    if (this.needScroll) {
      return 'package-scoll'
    } else if (this.isPass) {
      // 抽屉形式又有tabs的
      return 'package-drawer-tabs'
    } else {
      // 抽屉形无tabs的
      return 'package-drawer'
    }
  }

  get activityName() {
    return this.payload?.activityName || ''
  }

  get packageName() {
    return this.payload?.packageName || ''
  }

  // 是pass
  get isPass() {
    return this.payload?.passStandardActivityInfo && this.payload?.passStandardActivityInfo.activity_total > 0
  }

  setTotal(num: number) {
    this.total = num
  }

  getSpm(TabType: string) {
    const ext = { TabType }
    return `PackageDetailTab?oid=package_${this.payload?.packageId}&ext=${this.$getExt(
      ext
    )}&mod=stop&evt=click&trg=manual`
  }
}
