










import { Component, Prop, mixins } from 'nuxt-property-decorator'
import CommonContentRefresh from '~/components/experience-booking/activity/package-detail/status/common-content-refresh.vue'
import CommonContentLoading from '~/components/experience-booking/activity/package-detail/status/common-content-loading.vue'
import PackageDetailEmpty from '~/components/experience-booking/activity/package-detail/status/package-detail-empty.vue'
import { PackageDetailStructured } from '~/components/common/package-detail-v2'
import ExpLoadingState from '~/components/experience/loading-state/index.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import newPackageDetailShowMixin from '~/components/common/package-detail-v2/show-mixin'

@Component({
  components: {
    ExpLoadingState,
    PackageDetailStructured,
    CommonContentRefresh,
    CommonContentLoading,
    PackageDetailEmpty
  }
})
export default class PackageDetailNewWithStatus extends mixins(newPackageDetailShowMixin) {
  @Prop() packageId!: number
  @Prop() status!: string
  @Prop() packageDetail!: any
  @Prop({ default: false }) showActivityName!: boolean

  get componentName() {
    const statusMap = {
      success: 'PackageDetailStructured',
      failure: 'CommonContentRefresh',
      fetching: 'CommonContentLoading'
    }

    // @ts-ignore
    const name = statusMap[this.status]
    if (!this.packageId || !name) {
      return 'PackageDetailEmpty'
    }

    return name
  }

  get calcTrack() {
    const ext = JSON.stringify({
      IsAttrNew: true,
      Vertical: this.currPkgVertical
    })

    const def = {
      action: false,
      type: 'module',
      spm: 'PackageDetailsPopups',
      query: {
        ext,
        oid: `package_${this.packageId}`
      }
    }
    return def
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  handleRefresh() {
    this.$emit('refresh')
  }
}
