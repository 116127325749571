






























import { Component, Prop } from 'nuxt-property-decorator'
import PackageCard from './fnb-package-card.vue'
import PackageOptionsFnbBase from '~/components/experience-booking/experience-activity/package-options/fnb-package-options/index-section-fnb'

@Component({
  components: {
    PackageCard
  }
})
export default class PackageCardListWrap extends PackageOptionsFnbBase {
  @Prop() getBookingCore!: () => any
  @Prop() businessData!: any

  limtd = 4
}
