












import { Component } from 'nuxt-property-decorator'
import Banner from '../banner.vue'
import ActivityWishButton from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/attribute-bottom-bar/activity-wish-button.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({
  components: {
    Banner,
    ActivityWishButton
  }
})
export default class ActivityBanner extends BusinessBase {
  static displayName = 'MobileNew_flow_banner'

  get showStyleVersion() {
    return this.fieldsConfig2section.show_style_version || 0
  }

  get isTaVersion() {
    return this.showStyleVersion === 1
  }
}
