











import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import SavingPriceDetails from '~/components/experience/saving-price-details/index.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import eventBus from '~/pages/experience/pay/common/event-bus'

@Component({
  components: {
    SavingPriceDetails
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: true }) hideDashed!: boolean
  @Prop({ default() { return {} } }) poptipData!: any
  @Prop({ default() { return {} } }) priceSummary!: any

  visible = false

  mounted() {
    eventBus.$off('setShowPriceSummary2bus', this.setShowPriceSummary2bus).$on('setShowPriceSummary2bus', this.setShowPriceSummary2bus)
  }

  beforeDestroy() {
    eventBus.$off('setShowPriceSummary2bus', this.setShowPriceSummary2bus)
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  setShowPriceSummary2bus(isb: boolean) {
    this.clickHandle(isb)
  }

  clickHandle(isb: boolean) {
    this.visible = isb
    const totalFooter: any = document.querySelector('.js-hack-total-footer')
    const jsHack: any = document.querySelector('.js-custom-bottom-sheet-hack')
    try {
      if (totalFooter?.offsetHeight && jsHack) {
        this.$nextTick(() => {
          jsHack.style.bottom = this.visible ? `${totalFooter.offsetHeight}px` : '0'
          jsHack.style.minHeight = this.visible ? '92px' : 'auto'
        })
      }
    } catch (error) {
      // console.log('undefined class js-hack-total-footer.', error)
    }
    this.$emit('clickSavePrice', this.visible)
  }

  get calcTrack() {
    const def = {
      type: 'module',
      spm: 'Savings_Detail'
    }
    return def
  }
}
