






























import { Component, Getter, State, Ref, mixins } from 'nuxt-property-decorator'
import ActivityWishButton from './activity-wish-button.vue'
import ActivityBottomBtn from './activity-bottom-btns.vue'
import ActivitySettlement from './activity-settlement'
import PackageOptionsGuideWrap from './activity-guide-button/package-options-guide-wrap'
import PublishInfoBase from '~/components/common/publish-info/publish-info-base'

@Component({
  components: {
    ActivityWishButton,
    ActivityBottomBtn,
    ActivitySettlement
  }
})
export default class ActivityBottomBooking extends mixins(PackageOptionsGuideWrap, PublishInfoBase) {
  @State(state => state.klook.utilConfig) utilConfig!: any
  @Getter isGuestCheckout!: boolean
  @Ref() settlementRef!: any

  noMarginTop = false

  get wishButtonVisible() {
    return !this.utilConfig?.whiteLabelUtilConfig && !this.isGuestCheckout
  }

  get bottomButtonRole() {
    if (this.isWarming) {
      return 'warming'
    }

    if (this.isSuspended) {
      return 'suspended'
    }

    return this.isGuestCheckout ? 'book' : null
  }

  mounted() {
    this.computeMargin()
  }

  updated() {
    this.computeMargin()
  }

  computeMargin() {
    this.$nextTick(() => {
      this.noMarginTop = !!(this.settlementRef && this.settlementRef.offsetHeight === 0)
    })
  }
}
