































import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import merge from 'lodash/merge'
import cloneDeep from 'lodash/cloneDeep'
import SavingPriceDetailsPoptip from '~/components/experience/saving-price-details/poptip.vue'
import SavingPriceDetailsBottomSheet from '~/components/experience/saving-price-details/bottom-sheet.vue'
import SavingPriceDetailsCustomBs from '~/components/experience/saving-price-details/custom-bs.vue'
import { formatCurrencySymbolPriceThousands } from '~/share/utils'

@Component({
  components: {
    SavingPriceDetailsBottomSheet,
    SavingPriceDetailsCustomBs,
    SavingPriceDetailsPoptip
  }
})
export default class TotalSlot extends Vue {
  @State klook!: Data.Klook

  @Prop() customPlatform!: string
  @Prop({ default() { return {} } }) poptipData!: any
  @Prop({ default: false }) hideSummaryPrice!: boolean
  @Prop({ default: '' }) styleType!: string | 'flex-column' | 'flex-column-reverse' | 'mini-style' | 'ta-left' | 'ta-right' | 'flex-center' | 'flex-end' | 'fs1612-style' | 'fs2416-style'
  @Prop({ default() { return {} } }) priceData!: any
  @Prop({ default: 'total' }) priceType!: string
  @Prop({
    default() {
      // 1、售卖价：sale_price
      // 2、市场价：from_price
      // 3、节省价：save_price
      // 4、划线价：underline_price
      // 五种组合：1、13、14 --- 2、24
      return ['sale_price', 'from_price', 'save_price', 'underline_price']
    }
  }) orderList!: string[]

  priceMaps: any = {
    total: [
      { propKey: 'sale_price', className: 'salling-price', note: '售卖价' },
      { propKey: 'from_price', className: 'market-price', note: '市场价' },
      { propKey: 'save_price', className: 'saving-price', note: '节省价' },
      { propKey: 'underline_price', className: 'crossed-price', note: '划线价' }
    ]
  }

  get calcPlatform() {
    return this.customPlatform || this.klook.platform
  }

  get priceMapList() {
    return this.priceMaps[this.priceType]
  }

  get priceList() {
    const { orderList, priceMapList } = this
    // this.priceData = testTotalPriceData // test code
    const pd = this.priceData || {}
    const arr = orderList.filter((propKey: string) => {
      return pd[propKey]
    })
    return arr.map((propKey: string) => {
      let value = pd[propKey]
      if (propKey === 'from_price') {
        value = `<span class="spec-from-price">${this.$t('activity_detail_from_price', [12, value])}</span>`
      }
      const obj = priceMapList.find((o: any) => o.propKey === propKey) || {}
      return {
        ...obj,
        value
      }
    })
  }
}

export function getPreTotalPriceData(pkgData: any, preData: any = null, mergeData: any = {}) {
  // console.log(111, 'getPreTotalPriceData', pkgData, preData, mergeData)
  const pkgObj = cloneDeep(pkgData || {})
  const defData = {
    _hasDiscount: false,
    _openKey: 'has_discount',
    _priceKey: 'price_show',
    _preKeyMaps: {
      sale_price: 'total_price',
      save_price: 'total_saved'
    },
    oldPriceShow: {}
  }
  const data = merge(defData, mergeData)
  data._hasDiscount = !!pkgObj[data._openKey]
  const { _hasDiscount, _priceKey, _preKeyMaps, oldPriceShow } = data
  const priceShow: any = pkgObj[_priceKey] || {}
  // if (data) { // test code
  //   return (oldPriceShow.sale_price || oldPriceShow.from_price) ? merge({}, priceShow, oldPriceShow) : cloneDeep(priceShow)
  // }
  // console.log(999, !_hasDiscount || !preData, priceShow, oldPriceShow)
  if (!_hasDiscount || !preData) {
    priceShow.save_price = ''
    const initOldData = { underline_price: '', save_price: '' }
    const mergeData = (oldPriceShow.sale_price || oldPriceShow.from_price) ? merge({}, priceShow, initOldData, oldPriceShow) : cloneDeep(priceShow)
    if (mergeData.sale_price) {
      mergeData.from_price = ''
    }
    return mergeData
  }
  const newPreData = cloneDeep(preData || {})
  const mapData = Object.keys(_preKeyMaps).reduce((obj: any, key: any) => {
    const mapKey = _preKeyMaps[key]
    obj[key] = newPreData[mapKey]
    return obj
  }, {})
  const retData = merge({}, mapData, newPreData)
  return retData
}

export function getPriceSummary(pkgData: any, units: any[], klkCurrency: Data.Currency, mergeData: any = {}) {
  // console.log(111, 'getPriceSummary', units)
  const pkgObj = {
    package_name: pkgData?.package_name,
    sku_list: units.filter((o: any) => o.count).map((o: any) => {
      return {
        text: o.priceName,
        value: formatCurrencySymbolPriceThousands(klkCurrency, o.sellingPrice),
        count: `x${o.count}`
      }
    }),
    ...(mergeData || {})
  }
  const obj = {
    package_list: [pkgObj]
  }
  return obj
}

export const testTotalPriceData = { sale_price: '￥200.2', from_price: '￥260.6', save_price: 'saving ￥40', underline_price: '￥280.6' }
