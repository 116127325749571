

















































import { Component, Vue, namespace, Watch, Prop, Ref, State } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { Activity } from '~/types/traveller/activity'

const ActivityModule = namespace('traveller/activity')

type scheduleObject = { [props: string]: Activity.PriceSchedule }

@Component
export default class ActivityPackageDatePicker extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: false }) cleanShow!: boolean
  @Prop() packageId!: number
  @Prop() startDate!: Date
  @Prop() endDate!: Date
  @ActivityModule.State priceSchedules!: Activity.PriceSchedule[]
  @ActivityModule.State activityId!: number
  @ActivityModule.State('packageId') selectedPackageId!: number
  @ActivityModule.Action setPackageSelectedDate!: (data: string) => {}
  @ActivityModule.Getter packageSelectedDate!: string
  @ActivityModule.Getter packagePriceSchedules!: Activity.packagePriceSchedule[]
  @ActivityModule.Getter packagePriceSchedulesObj!: { [prop: string]: Activity.packagePriceSchedule }
  @ActivityModule.Getter priceSchedulesObj!: { [prop: string]: Activity.PriceSchedule }
  @ActivityModule.Getter currentSelectedPackage!: Activity.activityPackage
  @Ref() datePicker!: any

  viewDate: Date = this.getLocalDate()

  nextCount = 0

  soldoutSchedulesObject: scheduleObject = {}

  /**
   * 活动日历包含所有套餐的stock（stocks数组每一项为一个套餐）
   *   日期没给为disabled
   *   给了日期但是stocks为空或者stock内所有套餐的stock为0，是售罄状态
   *   其他为正常状态
   * 套餐日历为某一个套餐的日历，包含套餐在每天所有时段库存time_slots
   *   日期没给为disabled
   *   给了日期但是time_slots的stock都为0，是售罄状态
   */
  @Watch('priceSchedules', { immediate: true })
  priceSchedulesChange() {
    this.priceSchedules.forEach((schedule) => {
      const { date } = schedule

      if (!schedule.stocks) {
        this.soldoutSchedulesObject[date] = schedule
        return
      }

      // 库存为零，售罄
      const stocks = schedule.stocks.reduce((acc, v) => acc + v.stock, 0)

      if (!stocks) {
        this.soldoutSchedulesObject[date] = schedule
      }
    })
  }

  @Watch('packageSelectedDate', { immediate: true })
  onPackageSelectedDateChanged(val: string) {
    this.setView(val)
  }

  setView(date: string) {
    if (this.datePicker) {
      if (date) {
        this.datePicker.setViewDate(this.getLocalDate(date))
      } else {
        this.datePicker.setViewDate(this.minPriceDate)
      }
    }
  }

  @Watch('minPriceDate', { immediate: true })
  minDateChange() {
    this.peroidChange()
  }

  @Watch('maxPriceDate', { immediate: true })
  maxDateChange() {
    this.peroidChange()
  }

  peroidChange() {
    this.$nextTick(() => {
      if (this.datePicker) {
        this.datePicker.setViewDate(this.currentDate ? this.currentDate : this.minPriceDate)
      }
    })
  }

  get schedules() {
    return this.packageId ? this.packagePriceSchedules : this.priceSchedules
  }

  get minPriceDate() {
    return this.getLocalDate(this.schedules[0] && this.schedules[0].date)
  }

  get maxPriceDate() {
    const lastIndex = this.schedules.length - 1
    const lastDate = this.schedules[lastIndex]
    return lastDate && this.getLocalDate(lastDate.date)
  }

  getPrice(date: any) {
    const key = dayjs(date).format('YYYY-MM-DD')

    if (this.packageId) {
      const priceSchedule = this.packagePriceSchedulesObj[key]

      if (!priceSchedule) {
        return ''
      }

      return priceSchedule.selling_price
    }

    const schedule = this.priceSchedulesObj[key]

    return schedule && schedule.selling_price
  }

  changeDate(date: any) {
    this.setPackageSelectedDate(dayjs(date).format('YYYY-MM-DD'))

    if (this.klook.platform === 'desktop') {
      const millisecond = new Date(date).getTime() - new Date().getTime()

      const days = Math.ceil(millisecond / (1000 * 60 * 60 * 24))

      this.$logquery.service({
        timestamp: Date.now(),
        isMasked: true,
        level: 'I',
        message: JSON.stringify({
          package_id: this.selectedPackageId,
          activity_id: this.activityId,
          tag_name: 'desktop_activity_date_difference_select_date',
          date_diff: days
        }),
        tag: 'desktop-activity-date-difference'
      }, {
        headers: { 'X-Platform': this.klook.platform }
      })
    }

    this.$emit('change', date)
  }

  cleanDate() {
    this.setPackageSelectedDate('')
  }

  // Todo: stock为0是soldout，为undefined为disabled
  soldOutDate(d: any) {
    const date = dayjs(d).format('YYYY-MM-DD')

    if (this.packageId) {
      return this.isPackageSoldout(date)
    }

    return this.soldoutSchedulesObject[date]
  }

  isPackageSoldout(date: string) {
    const priceSchedule = this.packagePriceSchedulesObj[date]
    if (!priceSchedule) {
      return false
    }
    const { time_slots } = priceSchedule

    const isEmpty = !time_slots || !time_slots.length
    return isEmpty || time_slots.every(v => !v.stock || v.stock < (this.currentSelectedPackage || {}).minPax)
  }

  disableDate(d: any) {
    const date = dayjs(d).format('YYYY-MM-DD')

    if (this.packageId) {
      return !this.packagePriceSchedulesObj[date]
    }

    return !this.priceSchedulesObj[date]
  }

  shouldDateDisabled(d: any) {
    return this.disableDate(d) || this.soldOutDate(d)
  }

  formatDay(d: string) {
    return dayjs(d).format('D')
  }

  getLocalDate(day?: string) {
    const localeDate = dayjs(day || undefined).toString()
    return new Date(localeDate)
  }

  get currentDate() {
    return this.packageSelectedDate ? this.getLocalDate(this.packageSelectedDate) : null
  }

  mounted() {
    // desktop 上报点击next月份按钮
    this.$el.addEventListener('click', (e: any) => {
      if (!e.target) { return }

      const isPrev = e.target.closest('.klk-date-picker-prev-btn')
      const isNext = e.target.closest('.klk-date-picker-next-btn')

      if (isPrev) { this.nextCount-- }
      if (isNext) { this.nextCount++ }

      if (isNext) {
        this.$logquery.service({
          timestamp: Date.now(),
          isMasked: true,
          level: 'I',
          message: JSON.stringify({
            package_id: this.selectedPackageId,
            activity_id: this.activityId,
            next_month_count: this.nextCount,
            tag_name: 'desktop_activity_date_difference_next_month_click'
          }),
          tag: 'desktop_activity_date_difference'
        }, {
          headers: { 'X-Platform': this.klook.platform }
        })
      }
    })
  }
}
