











import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import MobilePackageOptionCardSku from '~/components/booking-core/package-options/mobile/card-sku/index.vue'
import ConboOption from '~/components/experience-booking/experience-activity/combo-option/combo-list/mobile/index.vue'
import PackageOptionsCommonTabs from '~/components/experience-booking/experience-activity/combo-option/combo-list/common-tabs/index.vue'
import dataUtils from '~/components/booking-core/utils/data-utils'

@Component({
  components: {
    MobilePackageOptionCardSku,
    PackageOptionsCommonTabs,
    ConboOption
  }
})
export default class MobileSkuCardPackageOptionsRoot extends BusinessBase {
  static displayName = 'MobilePackage_option_card_sku'

  get calcBookingCoreData() {
    const { businessData, basicData, $route } = this
    return dataUtils.getBookingCoreData2section({ businessData, basicData, $route })
  }

  get basicData() {
    const basicData = this.$attrs['basic-data']
    return basicData || ({} as any)
  }
}
