

























































import { Component } from 'nuxt-property-decorator'
import ActivityLayer from '~/components/traveller/activity/layer/activity-layer.vue'
import HomeCardSkeleton from '~/components/traveller/home-page/activity-card/skeleton/home-card-skeleton.vue'
import ActivityPackagePromotionBase
  from '~/components/experience-booking/experience-activity/package-options/package-promotion/base'
import PackagePromotionContent from '~/components/experience-booking/experience-activity/package-options/package-promotion/package-promotion-content.vue'

@Component({
  components: {
    ActivityLayer,
    PackagePromotionContent,
    HomeCardSkeleton
  }
})
export default class ActivityPackagePromotion extends ActivityPackagePromotionBase {
  isLayerVisible = false
  // packageId = 2

  handleLayerShow() {
    if (this.promotionFilteredNone) {
      return
    }

    this.getActivityPromotion()
    this.isLayerVisible = true
    this.$sendGTMCustomEvent(`Activity Page|ViewPackageWithPromotion_Click|${this.activityId}|${this.templateId}`)
  }

  handleLayerHide() {
    this.isLayerVisible = false
  }

  mounted() {
    this.$sendGTMCustomEvent(`Activity Page|ViewPackageWithPromotion_Appear|${this.activityId}|${this.templateId}`)
  }
}
