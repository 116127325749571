
















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { setNewImageSize } from '~/share/data/image'
@Component
export default class CardWithImage extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: Object, default: () => ({}) }) packageData!: any
  @Prop({ default: '' }) url!: string
  @Prop({ default: '82px' }) size!: string
  @Prop() noIamges!: Boolean

  get calcStyle() {
    const { size } = this
    return {
      width: size,
      height: size
    }
  }

  formatPicUrl(url: string) {
    const { webp } = this.klook
    return setNewImageSize(url, 'image/upload/', 88 * 3, 88 * 3, webp)
  }

  // 没图
  get showSkeletal() {
    return (!this.packageData?.package_images || !this.packageData?.package_images.length)
  }
}
