






































import { Component, Vue, State, Prop } from 'nuxt-property-decorator'
import { IconCheckCircle } from '@klook/klook-icons'
import { instances } from './utils'
import eventBus from '~/pages/experience/pay/common/event-bus'

@Component({
  components: {
    IconCheckCircle
  }
})
export default class ItineraryFeedBack extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  @Prop({ default: 'small' }) size!: 'large' | 'small'
  @Prop() packageId!: number
  @Prop() activityId!: number

  isClicked: boolean = false
  showFeedBack: boolean = true

  get cClass() {
    return [
      'itinerary-feed-back',
      this.platform,
      this.size
    ]
  }

  get instanceKey() {
    const { packageId, activityId } = this
    const key = `${activityId}_${packageId}`

    return key
  }

  hide() {
    this.showFeedBack = false
  }

  /**
   * 显示规则
   * desktop
   * 1、外层套餐详情和内层套餐详情 视为一个feedback组件， 点击外层按钮，组件消失，打开弹窗后，弹窗内feedback组件也消失
   * 2、切换不同套餐，需要显示feedback组件。但是切换回已经操作过的feedback组件，则无需在显示组件
   *
   * 刷新后需要重新显示
   * **/
  handleClickEvent() {
    this.isClicked = true
    instances.push(this.instanceKey)

    const timer = setTimeout(() => {
      this.hide()
      clearTimeout(timer)
      eventBus.$emit('hideFeedBack', this.instanceKey)
    }, 2000)
  }

  check() {
    if (instances.includes(this.instanceKey)) {
      this.hide()
    } else {
      eventBus.$on('hideFeedBack', (key: string) => {
        if (this.instanceKey === key && this.showFeedBack) {
          this.hide()
        }
      })
    }
  }

  beforeDestroy() {
    eventBus.$off('hideFeedBack')
  }

  mounted() {
    this.check()
  }
}
