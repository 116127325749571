






























































import { Component, Vue, State, Prop } from 'nuxt-property-decorator'
import chunk from 'lodash/chunk'
import { CardSwiper, CardSwiperItem } from '~/components/experience/card-swiper'

@Component({
  components: {
    CardSwiper,
    CardSwiperItem
  }
})
export default class Index extends Vue {
  @State(state => state.klook.platform) platform!: string
  @Prop() data!: any

  get valid() {
    return !!this.data?.length
  }

  get dataList() {
    if (!this.valid) {
      return []
    }

    if (this.platform === 'desktop') {
      return chunk(this.data, 2)
    }
  }
}
