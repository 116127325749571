






















































import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class Index extends Vue {
  @Prop({ default: [] }) skeletonName!:
    | string
    | string[]
    | 'act-date-skeleton'
    | 'pkg-skeleton'
    | 'pkg-details-skeleton'
    | 'sku-booking-skeleton'

  @Prop({ default: false }) animate?: boolean

  get calcNames() {
    const { skeletonName } = this
    if (Array.isArray(skeletonName)) {
      return skeletonName
    }
    if (typeof skeletonName === 'string') {
      return [skeletonName]
    }
    return []
  }
}
