

































import { Vue, Component, State, Prop } from 'nuxt-property-decorator'

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop() flexType?: string | 'flex-column'
  @Prop() isDate?: boolean
  @Prop() showRight?: boolean
  @Prop() timeData?: any
  @Prop() styleType?: string | 'select-time-type'
  @Prop() autoHideExtra?: boolean
  @Prop() timeExtra?: any

  get calcInList() {
    return this.timeExtra?.in || []
  }

  get calcOutList() {
    return this.timeExtra?.out || []
  }

  rules = this.getRules()

  getRules() {
    const obj: any = {
      in: {
        hasError: null,
        validate: (data: any, rule: any) => {
          const isb = !this.autoHideExtra || this.calcInList?.length
          if (isb && !data?.id) {
            rule.hasError = new Error(this.$t('global.select.empty_error'))
            return rule?.hasError
          } else {
            rule.hasError = null
          }
        }
      },
      out: {
        hasError: null,
        validate: (data: any, rule: any) => {
          const isb = !this.autoHideExtra || this.calcOutList?.length
          if (isb && !data?.id) {
            rule.hasError = new Error(this.$t('global.select.empty_error'))
            return rule?.hasError
          } else {
            rule.hasError = null
          }
        }
      }
    }
    return obj
  }

  get calcTimeFormatStr() {
    return (key: string) => {
      return this.timeData?.[key]?.startEndFormatStr || ''
    }
  }

  get calcActive() {
    return (key: string) => {
      const { styleType, timeData } = this
      return styleType === 'select-time-type' && timeData?.key === key ? 'on' : ''
    }
  }

  get calcFirstKey() {
    const { isDate, calcInList } = this
    if (isDate && calcInList?.length) {
      return this.$t('88949')
    }
    return isDate ? this.$t('13541') : this.$t('88952')
  }

  get calcLastKey() {
    const { isDate, calcOutList } = this
    if (isDate && calcOutList?.length) {
      return this.$t('88951')
    }
    return isDate ? this.$t('15987') : this.$t('88953')
  }

  checkValidate() {
    const { timeData, rules } = this
    const arr = Object.keys(rules).map((key: string) => {
      const val = timeData[key]
      const isb = rules[key].validate(val, rules[key])
      return !isb
    })
    return arr.every((v) => v)
  }

  clickHandler(key: string) {
    this.$emit('clickTime', key)
  }
}
