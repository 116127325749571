














































import { Component } from 'nuxt-property-decorator'
import { IconNext, IconWarnCircle } from '@klook/klook-icons'
import Base from './base'
import DesktopCalendarPopup from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/booking-calendar/desktopDatePick.vue'

@Component({
  components: {
    IconNext,
    DesktopCalendarPopup,
    IconWarnCircle
  }
})
export default class PassCalendar extends Base {
  open() {
    if (this.visible) {
      return
    }
    this.visible = true
    this.getInfo()
    this.emitCustomEvent(true)
  }

  closeCalendar() {
    this.visible = false
    this.emitCustomEvent(false)
  }

  emitCustomEvent(value: boolean) {
    const detail = { packageId: this.packageId, value }
    const event = new CustomEvent('openDesktopCalendar', { detail })
    window.dispatchEvent(event)
  }
}
