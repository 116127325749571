





























































































import { Component, Prop, mixins } from 'nuxt-property-decorator'
import { IconCalendarUnavailable, IconCheckCircle, IconNext } from '@klook/klook-icons'
import CutDown from './cut-down/index.vue'
import Price from './group-package-price.vue'
import ExpCutDown from '~/components/experience/cut-down/index.vue'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'
import ExperiencePackageDetailModule from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-module.vue'
import ExpTagging from '~/components/experience/tagging/index.vue'
import CardBase from '~/components/experience-booking/experience-activity/package-options/mobile/group-package-options/card.base'
import newPackageDetailShowMixin from '~/components/common/package-detail-v2/show-mixin'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'

@Component({
  components: {
    IconNext,
    ExpTagging,
    ExperiencePackageDetailModule,
    CutDown,
    ExpCutDown,
    IconCalendarUnavailable,
    TotalSlot,
    Price,
    IconCheckCircle,
    WarmingSlogan
  }
})
export default class GroupPackageCard extends mixins(CardBase, newPackageDetailShowMixin) {
  @Prop() toBook!: (skuId?: number, package_id?: number, name?: string) => void
  @Prop() getPackageSchedulesUnits!: (id: number) => any
  @Prop({ default: {} }) packagesGroupDesc!: any
  @Prop({ default: 'grouping' }) type!: 'grouping' | 'fnbImage' | 'fnbNoImage'

  detailShow = false
  loading = false
  packageSchedulesUnitsMap: Record<string, any> = {}

  get smp() {
    return `PackageDetailsPopups?oid=package_${this.packageData.package_id}&ext=${encodeURIComponent(
      JSON.stringify({
        IsAttrNew: this.showStructuredPackageDetail,
        Vertical: this.currPkgVertical
      })
    )}`
  }

  // 查看详情
  goDetail() {
    this.detailShow = true
  }

  async bookHanlder() {
    if (this.isDisableSale || this.isDisable) {
      return
    }
    if (this.type === 'grouping') {
      this.loading = true
      const { units } = await this.getPackageSchedulesUnits(this.packageData.package_id)
      this.loading = false
      if (units && units.length > 0) {
        this.toBook(
          units[0]?.sku_id,
          this.packageData.package_id,
          `${this.packagesGroupDesc.name}·${this.packageName}`
        )
        this.$inhouse.track('action', this.$refs.card)
      }
    } else {
      this.toBook(this.packageData.package_id)
    }
  }
}
