





































import { Vue, Component, Prop, Ref, State } from 'nuxt-property-decorator'
import { KlkAtomicBasicTag as KlkPromoTag } from '@klook/klook-card'
import Tag from '../tag.vue'
import '@klook/klook-card/dist/esm/index.css'
import { openDeeplink } from '~/components/experience-booking/booking-details/utils'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    Tag,
    KlkPromoTag
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop({
    type: Number,
    default: 5
  }) lineClamp!: number

  @Prop({
    type: String,
    default: '200px'
  }) tagMaxWidth!: string

  @Prop({
    type: String,
    default: '20px'
  }) descLineHeight!: string

  @Prop({
    type: Object,
    default() {
      return {}
    }
  }) data!: any

  @Ref() leftRef!: HTMLSelectElement
  @Ref() tagRef!: HTMLSelectElement
  @Ref() realDescRef!: HTMLSelectElement

  getSpecClass(item: any) {
    const { tag_key, icon } = item || {}
    return (tag_key === 'credits' && icon?.left_icon_url) ? 'credits' : ''
  }

  moreObj: any = {
    status: ''
  }

  tagStyle: any = {
    maxWidth: 'auto'
  }

  get descList() {
    return this.data?.desc_list || []
  }

  get statusMaps() {
    return {
      more: this.$t('see_more'),
      less: this.$t('17843')
    }
  }

  get showArrow() {
    return this.data.action && this.data.action.type > 0
  }

  clickShowMore() {
    const { moreObj } = this
    if (!moreObj.status) { return false }
    moreObj.status = moreObj.status === 'more' ? 'less' : 'more'
  }

  flexWrap = ''
  descStyle: any = {
    lineHeight: '16px',
    marginTop: '0',
    lineClamp: 0,
    maxWidth: 'unset',
    maxHeight: 'unset'
  }

  get calcDescStyle() {
    const { descStyle, moreObj } = this
    const maxHeight = moreObj.status === 'more' ? descStyle.maxHeight : 'unset'
    return {
      ...descStyle,
      maxHeight
    }
  }

  mounted() {
    this.init()
  }

  init() {
    this.$nextTick(() => {
      const { moreObj, descStyle, lineClamp, tagStyle } = this
      descStyle.lineHeight = this.descLineHeight
      const leftWidth = this.leftRef.offsetWidth || 0
      const tagWidth = (this.tagRef as any)?.$el?.offsetWidth || 0
      const tagMaxWidth = parseInt(this.tagMaxWidth) || 0
      if (tagMaxWidth < tagWidth) {
        this.flexWrap = 'wrap'
        tagStyle.maxWidth = this.tagMaxWidth
      } else {
        this.flexWrap = 'nowrap'
        descStyle.maxWidth = leftWidth - tagWidth - 10 + 'px' // 8px为tag外边距, 2为兼容像素
      }

      this.$nextTick(() => {
        const descLineHeight = parseInt(this.descLineHeight)
        const realDescRef = this.realDescRef as any
        const realDescHeight = realDescRef.offsetHeight || 0
        const height = lineClamp * descLineHeight
        const maxHeight = height + descLineHeight + 'px'
        if (realDescHeight > (height + 4)) {
          moreObj.status = 'more'
          descStyle.lineClamp = lineClamp
          descStyle.maxHeight = maxHeight
        }
      })
    })
  }

  clickArrowIcon(item: any) {
    const { action } = item || {}
    if (!action) { return false }
    const { type, deep_link } = action || {}
    switch (type) {
      default:
        openDeeplink(deep_link, this.klook.platform)
    }
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }
}
