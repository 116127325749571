import { Vue, Component, Inject } from 'nuxt-property-decorator'

export const PACKAGE_DETAIL_OPTION_TYPE = {
  OLD_VERSION: 0,
  STRUCTURED_VERSION: 1
}

@Component
export default class PackageDetailShowBase extends Vue {
  // 正在查看的pkg detail所在的act的vertical
  @Inject({ default: () => '' }) getCurrPkgDetailVertical!: Function
  // 当前所在位置
  @Inject({ default: () => '' }) getCurPkgDetailPageFrom!: Function

  // 正在查看的pkg detail的版本
  @Inject({ default: () => 0 }) getCurrPkgDetailOption!: Function

  get currPkgPageFrom() {
    return this.getCurPkgDetailPageFrom && this.getCurPkgDetailPageFrom()
  }

  get currPkgVertical() {
    return this.getCurrPkgDetailVertical && this.getCurrPkgDetailVertical()
  }

  get currPkgDetailOption() {
    return this.getCurrPkgDetailOption && this.getCurrPkgDetailOption()
  }

  get showStructuredPackageDetail() {
    return +this.currPkgDetailOption === +PACKAGE_DETAIL_OPTION_TYPE.STRUCTURED_VERSION
  }
}
