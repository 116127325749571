



























import { Component, Prop } from 'vue-property-decorator'
import Scrollbase from '../scrollspy'
import GroupSwiperList from './group-swiper-list.vue'

@Component({
  components: {
    GroupSwiperList
  }
})
export default class GroupSwiper extends Scrollbase {
  @Prop() swiperList!: any[]
  @Prop() activeKey!: number
  @Prop() activityId!: number
  @Prop() setActive!: (index: number) => void

  // 滚动锁定
  lockScroll = false

  get list() {
    return this.swiperList
  }
}
