























import { Component, Vue, State, Prop } from 'nuxt-property-decorator'
import { getStandardDateFormat } from '~/share/data/datetime'
import { Schedule } from '~/components/experience-booking/activity/activity-calendar/mobile/types'

@Component
export default class CalendarHeader extends Vue {
  @State((state) => state.klook.language) language!: Data.Language
  @State((state) => state.klook.platform) platform!: Data.Platform

  @Prop({ default: false }) titleBold!: boolean
  @Prop({ default: false }) hideDuration!: boolean
  @Prop({ default: false }) hideIcon!: boolean
  @Prop() schedules!: Schedule[]
  @Prop() headerText!: string
  @Prop() durationText!: string

  get duration() {
    if (!this.schedules || !this.schedules.length) {
      return ''
    }

    const [first] = this.schedules
    const last = this.schedules[this.schedules.length - 1]

    return `${this.formatDate(first?.date)} - ${this.formatDate(last?.date)}`
  }

  formatDate(date: string) {
    return getStandardDateFormat(date, this.$t.bind(this), this.language, 3)
  }
}
