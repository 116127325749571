//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import baseMixin from './baseMixin.js';
import CouponCard from './item.vue';

export default {
  inheritAttrs: false,
  components: {
    CouponCard,
  },
  mixins: [baseMixin],
  props: {
    couponList: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    hasTitleSlot() {
      return !!this.$slots['title-slot'];
    },
  },
  mounted() {
    this.realInhouse && this.realInhouse.track('pageview', this.$el, {
      force: true,
    });
  },
};
