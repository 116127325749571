import { Component, namespace, Vue } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'
import * as sessionStorage from '~/assets/scripts/sessionstorage'
import errorCode from '~/components/traveller/activity/book-now-error-code'
import { routerPush, getBookingNowParams } from '~/share/utils'

const authModule = namespace('auth')

@Component
export default class ActivityAutoBookBase extends Vue {
  @authModule.State isLoggedIn!: boolean
  activityId!: number
  shoppingCartVisible = false
  isJumpOldSettlement!: boolean

  role = 'bookNow'

  get preOrderKey() {
    return `activity_pre_order_${this.activityId}`
  }

  get shoppingCartId() {
    return parseInt(this.$route.query.shop_id as string) || -1
  }

  mounted() {
    const preOrderSessionData = sessionStorage.getSessionData(this.preOrderKey)
    if (preOrderSessionData && this.isLoggedIn) {
      let data = null

      try {
        data = JSON.parse(preOrderSessionData)
      } catch (e) {
        console.log('preOrderSessionData 解析错误！', preOrderSessionData)
      }

      if (data?.params) {
        this.role = data.params.shoppingcart_type ? 'bookNow' : 'addToCart'
        this.bookRequest(data.params)
      }
    }
    sessionStorage.deleteSessionData(this.preOrderKey)
  }

  async bookRequest(paramsData: any) {
    const api = this.shoppingCartId === -1 ? (this.role === 'addToCart' ? apis.bookingNow : apis.expPostPreOrderServiceNew) : this.role === 'addToCart' ? apis.addToShoppingCard : apis.postExpEditeShoppingCartAndnew
    let params = paramsData
    if (this.shoppingCartId === -1 && this.role !== 'addToCart') {
      const { arrangement_id, price_items } = params
      params = getBookingNowParams(arrangement_id, price_items)
    }
    this.$showLoading({ overlayColor: 'rgba(255, 255, 255, 0.7)' })

    try {
      const res = await this.$axios.$post(api, params)

      this.$hideLoading()

      if (res.success) {
        setTimeout(() => {
          if (this.role === 'bookNow') {
            routerPush(this, {
              name: 'ExperiencePay',
              query: {
                settlement_type: '1',
                shoppingcart_guid: res.result?.pre_order_id
              }
            })
          } else {
            this.shoppingCartId === -1 ? this.showShoppingCart() : (window.location.href = this.$href('/shoppingcart'))

            // 加入购物车成功埋点
            const id = res.result?.shoppingcart_id_str || res.result?.shoppingcart_id
            this.$inhouse.track('custom', 'body', {
              spm: 'AddToCartSuccessLoad',
              shoppingcart_id: id
            })
          }
        })

        return
      }

      if (res.error.code === '01001007027') {
        const option = {
          content: this.$t('16995-price_change_tips'),
          btnYes: this.$t('global.tips.okTxt')
        }
        this.$alert(option.content, {
          okLable: option.btnYes
        }).then(() => {
          window.location.reload()
        })
        return
      }

      this.handleError(res.error)
    } catch (e) {
      this.$hideLoading()
      this.handleError(e as any)
    }
  }

  handleError(e: { code: string; message: string }) {
    const resCode = e.code
    if (errorCode.includes(resCode)) {
      this.$confirm(e.message, {
        okLabel: this.$t('global.confirm'),
        cancelLabel: this.$t('global.tips.cancelTxt')
      }).then(({ result: isb }) => {
        if (isb) {
          window.location.reload()
        }
      })
    } else {
      this.$confirm(e.message, {
        okLabel: this.$t('global.confirm')
      })
    }
  }

  globalSource: HTMLElement | null = null

  showShoppingCart() {
    this.globalSource = document.querySelector('.global-js-add-to-cart') as HTMLElement
    this.shoppingCartVisible = true
  }
}
