











import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class ReviewsLoading extends Vue {
  elementObserver: any = null

  mounted() {
    this.$emit('mounted')
    if (process.client) {
      this.createObserver()
    }
  }

  beforeDestroy() {
    this.destroyObserver()
  }

  createObserver() {
    if ('IntersectionObserver' in window) {
      this.elementObserver = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          // 如果元素可见
          if (entry.intersectionRatio > 0) {
            this.$emit('show')
          }
        })
      })

      this.elementObserver.observe(this.$el)
    }
  }

  destroyObserver() {
    this.elementObserver && this.$el && this.elementObserver.unobserve(this.$el)
  }
}
