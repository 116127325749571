import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({
  components: {
    AttractionIncludedDesktopPop: () =>
      import(
        '~/components/experience-booking/experience-activity/package-options/attraction-preview/desktop/attraction-preview-modal.vue' /* webpackChunkName: 'attraction-included-desktop-pop' */
      ),
    AttractionIncludedMobilePop: () =>
      import(
        '~/components/experience-booking/experience-activity/package-options/attraction-preview/mobile/index.vue' /* webpackChunkName: 'attraction-included-mobile-pop' */
      )
  }
})
export default class Index extends BusinessBase {
  @State((state) => state.klook.platform) platform!: string

  visible: boolean = false
  activityId: number = -1
  total: number = 0

  get pass_activity() {
    return this.businessData?.pass_activity || []
  }

  get cardData() {
    return this.pass_activity?.[0]
  }

  get popComponent() {
    return this.isDesktop ? 'AttractionIncludedDesktopPop' : 'AttractionIncludedMobilePop'
  }

  get deepLink() {
    return this.cardData?.deep_link || ''
  }

  spmModule(item: any) {
    const len = this.pass_activity.length
    const index = this.pass_activity.findIndex((f: any) => f.pass_act_id === item.pass_act_id)
    return `PassActivityCard_LIST?oid=activity_${item.pass_act_id}&len=${len}&idx=${index}`
  }

  openModal(item: any) {
    const { standard_act_num = 0, pass_act_id = -1 } = item || {}
    this.total = standard_act_num
    this.visible = true
    this.activityId = pass_act_id
  }

  handleBook() {
    if (this.activityId === -1) { return }
    const url = this.$href(`/activity/${this.activityId}`)
    if (this.isDesktop) {
      window.open(url)
    } else {
      window.location.href = url
    }
  }
}
