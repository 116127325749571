interface Theme {
  mode: 'primary' | 'secondary'
  modeColor: 'default' | 'yellow' | 'green' | 'blue' | 'purple'
}

// UI配色方案https://www.figma.com/file/8EuR16b4M7i0yklbN2jxqY/Promo-System?node-id=9585%3A121867&mode=dev
export default function getTheme(mode: Theme['mode'], modeColor: Theme['modeColor']) {
  const primary = {
    default: {
      '--button-color': '#FF5B00',
      '--fill-color': '#FF7426',
      '--border-color': '#FEA171'
    },
    yellow: {
      '--button-color': '#F09B0A',
      '--fill-color': '#FEB439',
      '--border-color': '#FEB439'
    },
    green: {
      '--button-color': '#0B9E65',
      '--fill-color': '#38C78C',
      '--border-color': '#38C78C'
    },
    blue: {
      '--button-color': '#2073F9',
      '--fill-color': '#6390F7',
      '--border-color': '#6390F7'
    },
    purple: {
      '--button-color': '#6056D6',
      '--fill-color': '#9183FC',
      '--border-color': '#9183FC'
    }
  }
  const secondary = {
    default: {
      '--button-color': '#FF5B00',
      '--fill-color': '#FFF0E5',
      '--border-color': '#FEC3A1'
    },
    yellow: {
      '--button-color': '#F09B0A',
      '--fill-color': '#FCF3DE',
      '--border-color': '#FDC96F'
    },
    green: {
      '--button-color': '#0B9E65',
      '--fill-color': '#E6F7F2',
      '--border-color': '#7CD8B1'
    },
    blue: {
      '--button-color': '#2073F9',
      '--fill-color': '#ECF3FC',
      '--border-color': '#93B1F7'
    },
    purple: {
      '--button-color': '#6056D6',
      '--fill-color': '#F1EEFA',
      '--border-color': '#B9B0FE'
    }
  }
  const colorData = { primary, secondary }
  const data = colorData[mode][modeColor]
  return {
    ...data,
    '--currency-font-color': mode === 'primary' ? '#ffffff' : data['--button-color'],
    '--button-font-color': mode !== 'primary' ? '#ffffff' : data['--button-color']
  }
}
