






import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import MobilePackageOptionsWifiSpecType from '~/components/booking-core/package-options/mobile/wifi-spec-type/index.vue'
import dataUtils from '~/components/booking-core/utils/data-utils'

@Component({
  components: {
    MobilePackageOptionsWifiSpecType
  }
})

export default class DesktopPackageOptions extends BusinessBase {
  static displayName = 'MobilePackage_options_wifi_spec_type'

  bookingCoreData: any = {}

  created() {
    const { businessData, basicData, $route } = this
    this.bookingCoreData = dataUtils.getBookingCoreData2section({ businessData, basicData, $route })
  }

  get calcBookingCoreData() {
    const { businessData, basicData, $route } = this
    return dataUtils.getBookingCoreData2section({ businessData, basicData, $route })
  }

  get basicData() {
    const basicData = this.$attrs['basic-data']
    return basicData || {} as any
  }
}
