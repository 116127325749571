import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2'

export default function scrollToPackageOptionsTop(pid: number = 0, mergeOption: any = {}) {
  const selectors = [
    `#js-pkg-id-${pid}`,
    '#package_option_card_sku_with_detail',
    '#activity-package-options',
    '#package_option',
    '.js-activity-package-options',
    '#package_options_group',
    '#package_options_fnb'
  ]
  const d = selectors.map((selector) => document.querySelector(selector)).find((element) => element)
  if (d) {
    const top = nodeScrollTop(d)
    scroll2({
      scrollY: top,
      lockLocation: 61 + 47,
      ...mergeOption
    })
  }
}
