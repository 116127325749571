import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import duration from 'dayjs/plugin/duration'

dayjs.extend(utc)
dayjs.extend(duration)

function formatNumber(num: number) {
  if (num <= 0) {
    return '00'
  }
  return num < 10 ? `0${num}` : `${num}`
}

function formatDays(num: number) {
  return num <= 0 ? '0' : `${num}`
}

export default function getCountDownTime(endTime: string): Record<string, string> {
  const diffTime = dayjs.duration(dayjs.utc(endTime).diff(dayjs.utc()))

  return {
    days: formatDays(diffTime.days()),
    hours: formatNumber(diffTime.hours()),
    minutes: formatNumber(diffTime.minutes()),
    seconds: formatNumber(diffTime.seconds())
  }
}

export function isLessThanTargetTime(targetTime: string) {
  const now = dayjs().utc()
  const target = dayjs.utc(targetTime)
  return target.diff(dayjs(now)) > 0
}

export function getUtcTime(time: string) {
  const now = dayjs().utc()
  const target = dayjs.utc(time)
  return target.diff(now)
}
