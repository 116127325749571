import { get } from 'lodash'

// 活动层级数据
export function getActIsHeroPage(actProductType: string) {
  // 获取当前活动是否为hero_page
  return actProductType === 'hero_page'
}

export function getActValidPackages(packageList: any[], key: string = 'has_stocks') {
  // 获取当前活动下有库存的套餐
  return packageList?.filter((o: any) => o[key]) || []
}

export function getActSomeOpenTicket(packageList: any[], key: string = 'package_ticket_type') {
  // 获取当前活动下所有套餐中是否存在open ticket套餐
  return packageList?.some((o: any) => o[key] === 3)
}

export function getActSomeDiscount(packageList: any[], key: string = 'product_tags.discount_tags.0') {
  // 获取当前活动下所有套餐中是否存在促销套餐
  return packageList?.some((o: any) => {
    return get(o, key, false)
  })
}

export function getActIsMultiSpec(specList: any[]) {
  // 获取当前活动是否为多销售属性
  return specList?.length > 1
}

export function getActIsNewOpenTicket(activityTicketType: number) {
  // 获取当前活动是否为open ticket
  return activityTicketType === 3
}

export const FromKeyDefined = {
  desktop: {
    sku: 'desktop_card-sku-with-detail',
    spec: 'desktop_spec-type',
    wifi: 'desktop_wifi-spec-type'
  },
  mobile: {
    sku: 'mobile_card-sku',
    spec: 'mobile_spec-type',
    left: 'mobile_spec-type-left',
    wifi: 'mobile_wifi-spec-type'
  }
}
