











import { Component, State, Vue, Prop } from 'nuxt-property-decorator'
import { SubSectionComponentIconList } from '../type'

@Component({
  components: {
    //
  }
})
export default class IconList extends Vue {
  @Prop({ type: Object, default: {} }) data!: SubSectionComponentIconList['data']
  @State klook!: Data.Klook

  get iconItems() {
    return this.data?.content || []
  }
}
