


























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import PackageDetailFailure from '~/components/traveller/activity/activity-package-options/mobile/package-detail/status/package-detail-failure.vue'
import PackageOptionsCalendar from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/calendar/calendar.vue'
import DesktopFilterCalendar from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/filter-calendar.vue'
import CommonSkeletonSlots from '~/components/experience-booking/activity/package-detail/status/common-skeleton-slots.vue'

@Component({
  inheritAttrs: false,
  components: {
    CommonSkeletonSlots,
    DesktopFilterCalendar,
    PackageDetailFailure,
    PackageOptionsCalendar
  }
})
export default class PackageOptionsCalendarWrap extends Vue {
  @Prop() styleType!: string
  @Prop({ default: 'fetching' }) state!: string
  @Prop({ default: false }) hideDateFilter!: boolean

  handleReload() {
    this.$emit('reload')
  }
}
