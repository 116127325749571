















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class AttractionPreviewTags extends Vue {
  @Prop({ default: [] }) tagList!: any[];
  @Prop({ default: false }) gray!: boolean;
}
