






import { Component, State, Vue, Prop } from 'nuxt-property-decorator'
import { SubSectionComponentParagraph } from '../type'

@Component({
  components: {
    //
  }
})
export default class Paragraph extends Vue {
  @Prop({ type: Object, default: {} }) data!: SubSectionComponentParagraph['data']
  @State klook!: Data.Klook

  get content() {
    return this.data?.content || ''
  }
}
