import { render, staticRenderFns } from "./activity-package-btn-new.vue?vue&type=template&id=65e8c681&scoped=true&"
import script from "./activity-package-btn-new.vue?vue&type=script&lang=ts&"
export * from "./activity-package-btn-new.vue?vue&type=script&lang=ts&"
import style0 from "./activity-package-btn-new.vue?vue&type=style&index=0&id=65e8c681&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "65e8c681",
  null
  
)

export default component.exports