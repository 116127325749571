//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import klkPlatformCountDown from '@klook/platform-countdown'
import { getStandardDateFormat } from '@klook/klk-traveller-utils/lib/datetime'

export default {
  components: {
    klkPlatformCountDown
  },
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    lazyImage: {
      type: Boolean,
      default: true
    },
    language: {
      type: String,
      default: ''
    }
  },

  computed: {
    date() {
      return getStandardDateFormat(new Date(this.item.end_time_unix * 1000), this.$t.bind(this), this.language || 'mobile', 3)
    }
  },

  methods: {
    trimLeftZero(timeStr) {
      return String(timeStr).replace(/^0/, '')
    }
  }
}
