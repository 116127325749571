











import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import '@klook/klk-member-credit-value/esm/index.css'
import klkMemberCreditValue from '@klook/klk-member-credit-value'
@Component({
  components: {
    klkMemberCreditValue
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @State auth!: { user: Data.User }
  @Prop({ type: Object, default() { return {} } }) data!: Object
  @Prop({ type: Object, default() { return {} } }) poptipConfig!: Object

  get calcData() {
    const { klook, auth, data, poptipConfig } = this
    return {
      poptipConfig,
      visibleRewardEntry: false,
      userInfo: auth.user,
      platform: klook.platform,
      type: 'label', // label | card
      ...(data || {})
    }
  }
}
