









import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IconInformation, IconCautionCircle } from '@klook/klook-icons'

@Component({
  components: {
    IconInformation,
    IconCautionCircle
  }
})
export default class Card extends Vue {
  @Prop({ default: '' }) text!: string
  @Prop({ default: '' }) value!: string
  @Prop({ default: '' }) platform!: string

  get str() {
    if (this.text) {
      return this.text
    }
    return this.$t(this.value ? '106600' : '106599')
  }
}
