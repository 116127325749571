








import { Component } from 'nuxt-property-decorator'
import StyleBase from '../base/index'

@Component
export default class StyleThree extends StyleBase {}
