import { Vue, Component } from 'nuxt-property-decorator'
import { merge } from 'lodash'
import apis from '~/share/data/apis'
import dataUtils from '~/components/booking-core/utils/data-utils'
import * as dataTypesUtils from '~/components/booking-core/utils/types'

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  packageDetailApiDefined: dataTypesUtils.ApiDefinedIf = {
    isCache: true,
    state: '',
    reloadConfig: {
      isReady: true,
      delay: 240,
      state: ''
    },
    url: apis.activityPackageDetail,
    params: {
      is_line_page: false,
      translation: false,
      package_id: 0,
      preview: 0
    },
    apiData: null
  }

  get calcPkgDetailObj() {
    // 当前套餐详情及拓展数据
    const { apiData } = this.packageDetailApiDefined
    const detail = apiData?.result?.[0]
    if (!detail) {
      return {}
    }
    detail.use_section = true
    const obj = {
      product_tags: detail.product_tags,
      useSection: detail.use_section,
      sectionContent: detail.section_content,
      usageValidity: detail.usage_validity_render_obj,
      enableTranslationButton: detail.enable_translation_button,
      translationButtonStatus: detail.translation_status,
      packageName: detail.package_name,
      packageId: detail.id,
      activityId: detail.activity_id,
      packageSubName: detail.package_sub_name,
      passStandardActivityInfo: detail.pass_standard_activity_info,
      spec: detail.spec
    }
    return obj
  }

  async getPackageDetailApiData(paramsData: any, mergeDefined?: any) {
    // 获取套餐详情接口
    const { package_id_list } = paramsData || {}
    if (!package_id_list) {
      return
    }
    const { packageDetailApiDefined } = this
    packageDetailApiDefined.params = paramsData
    merge(packageDetailApiDefined, mergeDefined || {})
    const apiData = await dataUtils.getApiDataHandler(this, packageDetailApiDefined)
    return apiData
  }
}
