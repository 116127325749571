import { cloneDeep } from 'lodash'
import { BookingCoreDataIf } from '~/components/booking-core/utils/types'

export function getBookingCoreData2section({ businessData, basicData, $route }: any): BookingCoreDataIf {
  const {
    activity_id,
    activity_ticket_type,
    best_package_id,
    date_description,
    filters,
    is_nsw_activity,
    is_support_shopping_cart,
    package_filter_tags,
    packages,
    spec,
    template_id,
    membership_info,
    activity_closer_price,
    package_detail,
    packages_with_sku,
    reopen,
    combo_info,
    fnb_packages,
    vertical,
    package_detail_option
  } = businessData || {}
  const { act_product_type, is_line_page, activity_page_template_id, is_in_flash_sale, publish_info } = basicData || {}
  const { params, query } = $route || {}
  const obj: BookingCoreDataIf = {
    reopen,
    activityPageTemplateId: Number(activity_page_template_id) || -1,
    isPreview: params?.preview === 'preview',
    activityId: Number(activity_id) || 0,
    isTranslation: query?.translation === '1',
    publishInfo: publish_info,
    specList: spec || [],
    actProductType: act_product_type || '',
    activityTicketType: Number(activity_ticket_type),
    isLinePage: !!is_line_page,
    packageId: getInitPackageId({ best_package_id, $route }),
    participationDate: $route.query.participation_date,
    packageList: packages || [],
    templateId: Number(template_id) || 0,
    isSupportShoppingCart: !!is_support_shopping_cart,
    dateDescription: date_description || '',
    heroFilterObj: filters,
    packageFilterTagList: package_filter_tags || [],
    membershipInfo: membership_info || {},
    packageDetailList: package_detail || [],
    packagesWithSku: packages_with_sku || [],
    activityCloserPrice: activity_closer_price || {},
    isInFlashSale: is_in_flash_sale,
    comboInfo: combo_info || {},
    isNswActivity: is_nsw_activity,
    vertical,
    packageDetailOption: package_detail_option || 0
  }
  if (fnb_packages) {
    obj.fnbPackages = fnb_packages || []
  }
  return obj
}

export const getBookingCoreData2vm = (vm: any) => {
  const obj = {
    reopen: vm.reopen,
    activityPageTemplateId: vm.activityPageTemplateId,
    isPreview: vm.isPreview,
    activityId: vm.activityId,
    isTranslation: vm.isTranslation,
    publishInfo: vm.publishInfo,
    specList: vm.specList,
    actProductType: vm.actProductType,
    activityTicketType: vm.activityTicketType,
    isLinePage: vm.isLinePage,
    packageId: vm.packageId,
    packageList: vm.packageList,
    templateId: vm.templateId,
    isSupportShoppingCart: vm.isSupportShoppingCart,
    dateDescription: vm.dateDescription,
    heroFilterObj: vm.heroFilterObj,
    packageFilterTagList: vm.packageFilterTagList,
    membershipInfo: vm.membershipInfo,
    packageDetailList: vm.packageDetailList,
    packagesWithSku: vm.packagesWithSku,
    activityCloserPrice: vm.activityCloserPrice,
    isInFlashSale: vm.isInFlashSale,
    vertical: vm.vertical,
    packageDetailOption: vm.packageDetailOption || 0
  }
  return cloneDeep(obj)
}

export const getInitPackageId = (data: { $route: any, best_package_id: number }) => {
  const { $route: { query }, best_package_id: bestPackageId } = data
  let tempPackageId = (query?.package_id || '') as string
  // google ttd wl过来的url，参数package_id=247047_wl, 普通url参数package_id=247047
  if (tempPackageId?.includes('_')) {
    tempPackageId = tempPackageId.split('_')[0]
  }
  const packageId = Number(tempPackageId)
  if (packageId > 0) {
    return packageId
  } else if (bestPackageId > 0) {
    return bestPackageId
  }
  return 0
}
