import { Component, Prop, State, Vue } from 'nuxt-property-decorator'

@Component
export default class PromotionBase extends Vue {
  @Prop() currency!: string
  @Prop() discount!: string
  @Prop() is_subscribed!: boolean
  @Prop() original_selling_price!: string
  @Prop() package_id!: number
  @Prop() selling_price!: string
  @Prop() market_price!: string
  @Prop() promotion_event!: any
  @State klook!: Data.Klook

  get promotionDetail() {
    return {
      currency: this.currency,
      discount: this.discount,
      is_subscribed: this.is_subscribed,
      original_selling_price: this.original_selling_price,
      package_id: this.package_id,
      selling_price: this.selling_price,
      market_price: this.market_price,
      promotion_event: this.promotion_event
    }
  }

  get isWarmUp() {
    return this.promotion_event?.status === 4
  }

  get isInPromotion() {
    const platform = this.klook.platform === 'mobile' ? 'mweb' : 'web'
    const platformList = this.promotion_event?.platforms || []
    return this.promotion_event?.status === 5 && platformList.includes(platform)
  }

  get isShowPromotionDetail() {
    return this.promotion_event?.status === 4 || this.promotion_event?.status === 5
  }
}
