<template>
  <div v-show="isShow" class="image-viewer">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div v-for="(slide, index) in virtualData.slides" :key="index" class="swiper-slide" :style="{ left: `${virtualData.offset}px` }">
          <div class="swiper-zoom-container">
            <img :src="slide.src || slide" class="swiper-lazy" />
          </div>
        </div>
      </div>
    </div>
    <div class="image-viewer-top">
      <slot name="top" :slide="currentSlide" :close="close">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" @click="close">
          <path fill-rule="evenodd" fill="currentColor" d="M12.033 11.33l6.72-6.72a.504.504 0 0 1 .706.001c.196.196.197.51.001.706l-6.72 6.72 6.649 6.648a.504.504 0 0 1-.002.706.498.498 0 0 1-.706.001l-6.648-6.648-6.717 6.718a.504.504 0 0 1-.706-.001.498.498 0 0 1-.001-.706l6.717-6.718-6.789-6.789a.504.504 0 0 1 .001-.706.498.498 0 0 1 .706-.001l6.79 6.79z" />
        </svg>
      </slot>
      <div class="swiper-pagination"></div>
    </div>
    <div class="flex image-viewer-bottom">
      <slot name="footer" :slide="currentSlide"></slot>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper'

export default {
  name: 'ImageViewer',
  props: {
    list: { type: Array, default: () => [] },
    start: { type: Number, default: 0 }
  },
  data() {
    return {
      isShow: true,
      virtualData: { slides: [] },
      currentIndex: 0
    }
  },
  computed: {
    currentSlide() {
      return this.list[this.currentIndex]
    }
  },
  watch: {
    list(newValue) {
      if (this.$swiper) {
        this.$swiper.virtual.removeAllSlides()
        this.$swiper.virtual.appendSlide(newValue)
      } else {
        this.init()
      }
    },
    start(newValue) {
      this.$swiper.slideTo(newValue)
    }
  },
  methods: {
    close() {
      this.isShow = false
    },
    init() {
      const el = this.$el.querySelector('.swiper-container')
      const self = this
      this.$swiper = new Swiper(el, {
        virtual: {
          slides: self.list,
          renderExternal(data) {
            self.virtualData = data
            self.currentIndex = this.realIndex || 0
          }
        },
        spaceBetween: 16,
        zoom: {
          maxRatio: 2,
          minRatio: 1
        },
        initialSlide: this.start,
        pagination: {
          el: this.$el.querySelector('.swiper-pagination'),
          type: 'fraction'
        }
      })
    }
  }
}
</script>

<style lang="scss">
.image-viewer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $color-common-black;
  z-index: 112;

  .flex {
    display: flex;
    align-items: center;
  }

  .swiper-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }

  .swiper-container,
  .swiper-wrapper {
    height: 100%;
  }

  .swiper-slide {
    flex-shrink: 0;
    font-size: 0;
    display: flex;
    align-items: center;
    position: relative;
    margin-right: 16px;
    width: 100%;
    height: 100%;
    overflow: scroll;

    img {
      width: 100%;
    }
  }

  .swiper-zoom-container {
    width: 100%;
  }

  &-top {
    position: absolute;
    top: 0;
    z-index: 1;
    color: $color-text-primary-onDark;
    width: 100%;
    height: 64px;
    background: -webkit-linear-gradient(bottom, transparent, rgba(0, 0, 0, 0.5));

    svg {
      width: 24px;
      height: 24px;
      z-index: 12;
      position: absolute;
      top: 40px;
      left: 16px;
    }
  }

  &-bottom {
    position: absolute;
    bottom: 24px;
    width: 100%;
    color: $color-text-primary-onDark;
    z-index: 1;
  }

  .swiper-pagination {
    position: absolute;
    padding: 0 10px;
    background: $color-bg-overlay-black-mobile;
    border-radius: $radius-xl;
    line-height: 24px;
    color: $color-text-primary-onDark;
    font-size: $fontSize-caption-m;
    top: 40px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
