import { Vue, Component, Prop, Provide } from 'nuxt-property-decorator'
import dataUtils from '~/components/booking-core/utils/data-utils'

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  @Prop({ type: String, default: '' }) participationDate!: string // $route.query.participation_date
  @Prop({ type: Boolean, default: false }) isPreview!: Boolean // $route.params.preview
  @Prop({ type: Number, default: 0 }) activityId!: number // $route.params.activityPath
  @Prop({ type: Boolean, default: false }) isTranslation!: boolean // $route.query.translation === '1'
  @Prop({ type: Object, default: () => ({}) }) publishInfo!: any // basicData.publish_info
  @Prop({ type: Array, default: () => [] }) specList!: any[] // basicData.specList
  @Prop({ type: String, default: '' }) actProductType!: string // basicData.act_product_type
  @Prop({ type: Number, default: 0 }) activityTicketType!: number // basicData.act_product_type
  @Prop({ type: Boolean, default: false }) isLinePage!: Boolean // basicData.is_line_page
  @Prop({ type: Boolean, default: false }) isInFlashSale!: Boolean // basicData.is_in_flash_sale
  @Prop({ type: Number, default: 0 }) activityPageTemplateId!: number // basicData.activity_page_template_id
  @Prop({ type: Number, default: 0 }) packageId!: number // businessData.best_package_id
  @Prop({ type: Array, default: () => [] }) packageList!: any[] // businessData.specList
  @Prop({ type: Number, default: 0 }) templateId!: number // businessData.templateId
  @Prop({ type: Boolean, default: false }) isSupportShoppingCart!: Boolean // businessData.is_support_shopping_cart
  @Prop({ type: Boolean, default: false }) isNswActivity!: Boolean // businessData.is_nsw_activity
  @Prop({ type: String, default: '' }) dateDescription!: string // businessData.date_description
  @Prop({ type: Object }) heroFilterObj!: any[] // businessData.filters
  @Prop({ type: Array, default: () => [] }) packageFilterTagList!: any[] // businessData.package_filter_tags
  @Prop({ type: Object, default: () => ({}) }) membershipInfo!: any // businessData.membership_info
  @Prop({ type: Array, default: () => [] }) packageDetailList?: any[] // businessData.package_detail
  @Prop({ type: Array, default: () => [] }) packagesWithSku?: any[] // businessData.packagesWithSku
  @Prop({ type: Object, default: () => ({}) }) activityCloserPrice!: any // $businessData.activity_closer_price
  @Prop({ type: Array }) fnbPackages?: any[] // $businessData.fnbPackages
  @Prop({ type: Object }) reopen?: object // $businessData.reopen
  @Prop({ type: Object }) comboInfo?: any // $businessData.combo_info
  @Prop({ type: String }) vertical?: string // $businessData.vertical
  @Prop({ type: Number, default: 0 }) packageDetailOption?: number // $businessData.package_detail_option

  @Provide('provideaActivityId')
  get provideaActivityId() {
    return this.activityId
  }

  // 活动层级数据 start
  get calcReopenObj() {
    // 重新开业 数据源
    return this.reopen
  }

  get calcExtraSpecList() {
    // 可组合的销售属性列表及拓展数据
    return this.specList?.map((spec: any) => {
      return {
        ...spec,
        attrs: spec.attr,
        attrSet: new Set((spec.attr || []).map((attr: any) => attr.id))
      }
    })
  }

  get calcExtraPkgList() {
    // 套餐列表及拓展数据
    const arr = this.packageList.map((o: any) => {
      return {
        ...o,
        specAttrIds: o?.spec_attr_id || []
      }
    })
    return arr
  }

  get calcPreview() {
    // preview 整数类型
    return Number(this.isPreview) || 0
  }

  get calcActValidPkgList() {
    // 有库存的套餐列表
    return dataUtils.getActValidPackages(this.packageList)
  }

  get calcActSomeOpenTicket() {
    // 当前活动下所有套餐中是否存在open ticket套餐
    return dataUtils.getActSomeOpenTicket(this.packageList)
  }

  get calcActSomeDiscount() {
    // 当前活动下所有套餐中是否存在促销套餐
    return dataUtils.getActSomeDiscount(this.packageList)
  }

  get calcSpecIdList() {
    // 可组合的销售属性的 id 列表合集
    return this.specList?.map((item: any) => item.id) || []
  }

  get calcActIsMultiSpec() {
    // 当前活动是否为多销售属性
    return dataUtils.getActIsMultiSpec(this.specList)
  }

  get calcActIsMultiSpecWithDiscount() {
    // 当前活动是否为多销售属性且存在促销套餐
    return this.calcActIsMultiSpec && this.calcActSomeDiscount
  }

  get calcActIsSingleSpec() {
    // 当前活动是否为单销售属性
    return !this.calcActIsMultiSpec
  }

  get calcActIsSingleSpecWithDiscount() {
    // 当前活动是否为单销售属性且存在促销套餐
    return this.calcActIsSingleSpec && this.calcActSomeDiscount
  }

  get calcActIsNewOpenTicket() {
    // 当前活动是否为open ticket
    return dataUtils.getActIsNewOpenTicket(this.activityTicketType)
  }

  get calcActIsHeroPage() {
    // 当前活动是否为 hero_page
    return dataUtils.getActIsHeroPage(this.actProductType)
  }
  // 活动层级数据 end

  // 套餐层级数据 start
  get calcPkgSelectedObj() {
    // 当前选中套餐数据
    return dataUtils.getPkgSelectedData(this.packageList, this.packageId)
  }

  get calcPkgIsNewOpenTicket() {
    // 当前套餐是否为open ticket
    return dataUtils.getPkgIsNewOpenTicket(this.calcPkgSelectedObj?.package_ticket_type)
  }

  get calcPkgIsWarmingOrSuspended() {
    // 当前套餐是否为预热状态或暂停销售
    return dataUtils.getPkgIsWarmingOrSuspended(this.calcPkgSelectedObj)
  }

  get calcPkgShowBookingAction() {
    // 组合条件：当前套餐是否展示预订下单按钮操作模块
    return !this.isLinePage && !this.calcPkgIsWarmingOrSuspended
  }

  // combo相关
  get hasCombo() {
    return !!this.comboInfo && this.comboInfo?.packages?.length > 0
  }

  get comboPackageList() {
    return this.comboInfo?.packages || []
  }

  get comboDiscountStr() {
    return this.comboInfo?.max_discount || ''
  }

  get comboPriceShow() {
    return this.comboInfo?.price_show || {}
  }

  @Provide()
  getCurrPkgDetailVertical() {
    return this.vertical || ''
  }

  @Provide()
  getCurrPkgDetailOption() {
    return this.packageDetailOption || 0
  }
  // 套餐层级数据 end

  updateExpPackageId(pid: number = 0) {
    // 更新套餐 id，同步 prop
    this.$emit('update:packageId', pid)
  }

  readonly readonlyInitPid = this.packageId // 只读，用于特殊场景判断：mweb单销售属性只有一个套餐时自动选中，initQueryData方法设置pid判断
}
