









































import { Component, mixins } from 'nuxt-property-decorator'
import PackageCardPlaceholderList from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-card-placeholder-list.vue'
import PackageCard from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/package-card-list/package-card.vue'
import EmptyStatus from '~/components/experience/tag-filter/empty-status.vue'
import PackageDetailFailure from '~/components/experience-booking/activity/package-detail/status/package-detail-failure.vue'
import PackageOptionsSkuBase from '~/components/booking-core/mixins/entry/index-section-sku'

@Component({
  inheritAttrs: false,
  components: {
    EmptyStatus,
    PackageCardPlaceholderList,
    PackageDetailFailure,
    PackageCard
  }
})
export default class PackageCardListWrap extends mixins(PackageOptionsSkuBase) {
  showLimit = 8
  showMore = true

  toggleChange() {
    this.showMore = !this.showMore
  }

  get limitedAvailablePackageList() {
    const { showMore, filterPackagesWithSku: availablePackageList, showLimit } = this
    return showMore ? availablePackageList.slice(0, showLimit) : availablePackageList
  }

  get showMoreText() {
    const len = this.filterPackagesWithSku.length
    return `${this.$t('city_page_view_more')} (${len - this.showLimit}+)`
  }
}
