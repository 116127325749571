import { Vue, Component } from 'nuxt-property-decorator'

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  modificationShoppingCart: boolean = false
  shoppingCartVisible: boolean = false

  globalSource: HTMLElement | null = null

  showShoppingCart() {
    this.globalSource = document.querySelector('.global-js-add-to-cart') as HTMLElement
    this.shoppingCartVisible = true
  }
}
