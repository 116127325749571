









import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { FunctionalComponentOptions, CreateElement, RenderContext } from 'vue'

const NoLazyLoadComp: FunctionalComponentOptions = {
  functional: true,
  props: {
    imgUrl: { type: String },
    coverUseImgTag: { type: Boolean },
    imageAlt: { type: String, default: '' }
  },
  render(h: CreateElement, ctx: RenderContext) {
    const { imgUrl, coverUseImgTag, imageAlt } = ctx.props
    const defaultSlots = (ctx.slots().default || [])

    if (coverUseImgTag) {
      const img = h('img', {
        class: {
          'no-lazy-load-card__img': true
        },
        attrs: {
          ...ctx.data.attrs,
          alt: imageAlt,
          src: imgUrl
        }
      })

      return h(
        'div',
        { slot: 'NoLazyLoad2ImgTag' },
        [img, ...defaultSlots]
      )
    }

    return h(
      'div',
      {
        slot: 'NoLazyLoad2BackgroundImage',
        attrs: ctx.data.attrs,
        style: {
          backgroundImage: `url(${imgUrl})`
        }
      },
      defaultSlots
    )
  }
}

@Component({
  components: {
    NoLazyLoadComp
  }
})
export default class NoLazyLoad extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() imgUrl!: string
  @Prop({ type: Boolean, default: false }) coverUseImgTag!: boolean
  @Prop({ type: String, default: '' }) imageAlt!: string

  get replacedImgUrl() {
    const w = this.platform === 'desktop' ? 413 : 563 // desktop = 275 * 1.5 mobile = 375 * 1.5
    return this.imgUrl
      .replace('image/upload/', `image/upload/q_85/c_fill,w_${w}/`)
  }
}
