









import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IconInformation, IconQuestion } from '@klook/klook-icons'
import OpenDateIconTips from '~/components/experience-booking/experience-activity/open-date-ticket/icon-tips/index.vue'

@Component({
  components: {
    IconInformation,
    IconQuestion,
    OpenDateIconTips
  }
})
export default class DatePopTip extends Vue {
  @Prop({ default: '' }) platform!: string
  @Prop({ default: [] }) usageValidRenderObj!: any
  @Prop({ default: '' }) usageValidityTitle!: string

  get iconComp() {
    const { platform } = this
    return platform === 'desktop' ? 'IconQuestion' : 'IconInformation'
  }

  get iconSize() {
    const { platform } = this
    return platform === 'desktop' ? 16 : 14
  }

  get showOpenDateTips() {
    return this.usageValidRenderObj?.length > 0
  }
}
