

























import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import stars from '~/components/traveller/activity/stars.vue'
import { approximateThousandsNew } from '~/share/utils'

@Component({
  components: {
    stars
  }
})
export default class ReviewRating extends Vue {
  @State klook!: Data.Klook
  @Prop() score!: string | number
  @Prop() count!: number

  approximateThousandsNew = approximateThousandsNew
}
