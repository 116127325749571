




























import { Component, Prop, Watch } from 'nuxt-property-decorator'
import PackageDetailSectionBase from '~/components/common/package-detail-v2/sections/base'
import AttrCollapse from '~/components/common/package-detail-v2/components/collapse/index.vue'
import AttrCollapseItem from '~/components/common/package-detail-v2/components/collapse/item.vue'
import PackageDetailComponent from '~/components/common/package-detail-v2/sections/components/index.vue'

@Component({
  components: {
    AttrCollapse,
    AttrCollapseItem,
    PackageDetailComponent
  }
})
export default class PackageDetailFold extends PackageDetailSectionBase {
  @Prop({ default: 'toggle' }) actionType!: 'toggle' | 'openSheet'
  @Prop({ default: -1 }) sectionIndex!: number
  activeList: string[] = []

  mounted() {
    this.setActiveList()
  }

  @Watch('components', { deep: true })
  handleChange() {
    this.setActiveList()
  }

  setActiveList() {
    const activeList = this.components.reduce((arr: string[], ele: any, index: number) => {
      const { active } = ele
      if (active) {
        arr.push(`${this.sectionIndex}-${index}`)
      }
      return arr
    }, [])
    this.activeList = activeList
  }
}
