


























































import { Component, Prop, Watch } from 'nuxt-property-decorator'
import AttractionIncludedCard from './attraction-preview-card.vue'
import Base from '~/components/experience-booking/experience-activity/package-options/attraction-preview/attraction-preview-base.vue'
import BaseSticky from '~/components/common/base-sticky.vue'

@Component({
  components: {
    AttractionIncludedCard,
    BaseSticky
  }
})
export default class AttractionPreviewList extends Base {
  @Prop() handleToDetail!: (id: number) => void
  @Prop({ default: false }) isBook!: boolean
  visible = false
  note = ''

  handleShowProductNote(payload: string) {
    this.visible = true
    this.note = payload
  }

  @Watch('groupId')
  handleGroupIdChange(newVal: number) {
    this.$emit('handleGroupIdChange', newVal)
  }
}
