









import { Vue, Component, Prop, State, Watch } from 'nuxt-property-decorator'

@Component
export default class ActivityReopen extends Vue {
  @State klook!: Data.Klook
  @Prop() reopen!: any

  tagMaps = [
    { key: 2, name: 'open', textid: '16078' },
    { key: 3, name: 'close', textid: '16077' }
  ]

  get tagData() {
    const obj: any = this.tagMaps.find((o: any) => o.key === this.reopen.tag) || {}
    return obj
  }

  get cClass() {
    if (this.visible) {
      const clsName = this.tagData?.name || ''
      return ['activity-reopen', `activity-reopen-${clsName}`]
      // return ['activity-reopen', 'activity-reopen-close']
    }
    return ['activity-reopen']
  }

  get visible() {
    return [2, 3].includes(this.reopen.tag)
  }

  @Watch('visible', { immediate: true })
  visibleChange(val: boolean) {
    if (!val) {
      this.$emit('disappear')
    }
  }
}
