































































































import { Component, mixins } from 'nuxt-property-decorator'
import ShoppingCart from '~/components/experience-booking/experience-activity/activity-shopping-cart/index.vue'
import PackageAttributes
  from '~/components/experience-booking/experience-activity/package-options/package-attributes/mobile/index.vue'
import PackageDetail
  from '~/components/experience-booking/experience-activity/package-options/package-detail/mobile/index.vue'
import BottomBar from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/attribute-bottom-bar/index.vue'
import ActivityPackagePromotion
  from '~/components/experience-booking/experience-activity/package-options/package-promotion/mobile/index.vue'
import OpenDateTips from '~/components/experience-booking/experience-activity/open-date-ticket/open-date-tips/index.vue'
import NoneFilterResult from '~/components/experience-booking/experience-activity/package-options/attraction-included/components/none-filter-result.vue'
import { getTaggingTrackObj } from '~/components/experience/tagging/index.vue'
import NewPackageOptionsBooking from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/wifi-index2.0.vue'
import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2'
import MobileBookingCoreMixin from '~/components/booking-core/mixins/entry/index-base-mobile'

@Component({
  components: {
    NewPackageOptionsBooking,
    OpenDateTips,
    PackageAttributes,
    PackageDetail,
    BottomBar,
    ActivityPackagePromotion,
    ShoppingCart,
    NoneFilterResult
  }
})
export default class PackageOptions extends mixins(MobileBookingCoreMixin) {
  get calcTaggingTrack() {
    const track = { spm: 'Package_Discount_Section', action: true }
    const list = this.calcPkgSelectedObj?.product_tags?.discount_tags
    const obj = getTaggingTrackObj({
      track,
      list,
      pkg: {
        packageId: this.packageId
      }
    })
    return obj
  }

  async mounted() {
    this.initAutoBookingMinxin({
      activityId: this.activityId,
      shopId: parseInt(this.$route.query.shop_id as string),
      shoppingCartHandler: this.showShoppingCart
    })
    await this.getActivitySchedulesApiData(this.calcActSchedulesParams)
  }

  handleGoToSpec() {
    const unselectedSpecDom = document.querySelector('.activity-spec.unselected-spec')
    if (unselectedSpecDom) {
      this.$toast(this.$t('83359'))
      scroll2({
        scrollY: nodeScrollTop(unselectedSpecDom),
        lockLocation: 148
      })
    }
  }
}
