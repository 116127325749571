













import { Component, mixins, namespace } from 'nuxt-property-decorator'
import PackageOptionsCommonTabs from '~/components/experience-booking/experience-activity/combo-option/combo-list/common-tabs/index.vue'
import dataUtils from '~/components/booking-core/utils/data-utils'
import { Activity } from '~/types/traveller/activity'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    PackageOptionsCommonTabs,
    GroupingDesktop: () =>
      import(
        '~/components/experience-booking/experience-activity/package-options/desktop/group-package-options/index.vue' /* webpackChunkName: 'GroupingDesktop' */
      ),
    GroupingMobile: () =>
      import(
        '~/components/experience-booking/experience-activity/package-options/mobile/group-package-options/group-package-options.vue' /* webpackChunkName: 'GroupingMobile' */
      ),
    ConboOptionDesktop: () =>
      import(
        '~/components/experience-booking/experience-activity/combo-option/combo-list/desktop/index.vue' /* webpackChunkName: 'ConboOptionDesktop' */
      ),
    ConboOptionMobile: () =>
      import(
        '~/components/experience-booking/experience-activity/combo-option/combo-list/mobile/index.vue' /* webpackChunkName: 'ConboOptionMobile' */
      )
  }
})
export default class PackageOptionsGroup extends mixins(BusinessBase) {
  static displayName = 'Package_options_group'
  @ActivityModule.Getter('currPackageOptionTab') currPackageOptionTab!: Activity.ComboTab

  bookingCoreData: any = {}

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get groupComponent() {
    return this.isDesktop ? 'GroupingDesktop' : 'GroupingMobile'
  }

  get combonComponent() {
    return this.isDesktop ? 'ConboOptionDesktop' : 'ConboOptionMobile'
  }

  get basicData() {
    const basicData = this.$attrs['basic-data']
    return basicData || {}
  }

  get isInCombo() {
    return this.currPackageOptionTab === 'combo'
  }

  created() {
    const { businessData, basicData, $route } = this
    this.bookingCoreData = dataUtils.getBookingCoreData2section({ businessData, basicData, $route })
  }

  get calcBookingCoreData() {
    const { businessData, basicData, $route } = this
    return dataUtils.getBookingCoreData2section({ businessData, basicData, $route })
  }
}
