import { Vue, Component } from 'nuxt-property-decorator'
import { getExpUnitsExtra } from '~/components/experience/booking-process-mixin/utils.ts'
import { ExpUnitsExtraIf } from '~/components/experience/booking-process-mixin/types.ts'

@Component
export default class BookingProcessMixin extends Vue {
  get calcExpUnitsExtra() {
    // 修改请注意：所有预订流程units数据都跑此方法
    return ({ unitsMap, totalUnitCount, selectedTime }: ExpUnitsExtraIf) => {
      const { arrangementId, maxPurchased } = selectedTime || {}
      if (arrangementId && unitsMap?.[arrangementId]?.unitList) {
        const { inventories = {} } = unitsMap[arrangementId] || {}
        const params = {
          totalUnitCount,
          maxPurchased,
          inventories
        }
        return getExpUnitsExtra(unitsMap[arrangementId].unitList, params)
      } else if (unitsMap?.default) {
        return unitsMap?.default?.unitList || [] // web没选中日期取默认数据
      } else {
        return []
      }
    }
  }
}
