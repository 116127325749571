








import { Component, Vue } from 'nuxt-property-decorator'
import UspTagList from '~/components/traveller/activity/usp-tip/usp-tag-list.vue'

@Component({
  components: {
    UspTagList
  }
})
export default class UspBanner extends Vue {
  handleClick() {
    this.$emit('click-tag')
  }
}
