/**
 * Created by Liu.Jun on 2021/03/21 16:53.
 * 解耦弹窗组件
 */
import Vue, { CreateElement } from 'vue'
// @ts-ignore
import KlkModal from '@klook/klook-ui/lib/modal/index'

type args = {
  parent?: any,
  injectionOptions?: object,
  dialogAttrs?: object,
  dialogListeners?: any,
  componentAttrs?: object,
  componentListeners?: object,
}

export default (VueComponent: any = null, {
  parent,
  injectionOptions = {},
  dialogAttrs = {},
  dialogListeners = {},
  componentAttrs = {},
  componentListeners = {}
}: args = {}) => {
  const DialogComponentCtor = Vue.extend({
    name: 'DialogComponent',
    data() {
      return {
        open: false,
        componentAttrs
      }
    },
    methods: {
      show() {
        (this as any).open = true
      },
      close() {
        (this as any).open = false
      }
    },
    render(h: CreateElement) {
      const self = this
      return h(KlkModal, {
        on: {
          ...dialogListeners,
          'update:open': function fn(val: boolean) {
            (self as any).open = val
          },
          close: (reason: any) => {
            // 传入的方法先执行
            if (dialogListeners.close) {
              dialogListeners.close.call(null, reason)
            }
            this.$destroy()
            if (this.$el && this.$el.parentElement) {
              this.$el.parentElement.removeChild(this.$el)
            }
          }
        },
        attrs: {
          overlayClosable: true,
          showDefaultFooter: false,
          closable: true,
          open: (this as any).open,
          ...dialogAttrs
        }
      }, [
        h((VueComponent), {
          on: {
            close: () => {
              (this as any).close()
            },
            ...componentListeners
          },
          attrs: {
            ...(this as any).componentAttrs
          }
        })
      ])
    }
  })

  const dialogInstance = (new DialogComponentCtor({
    ...(parent ? { parent } : {}), // 直接传parent 无需手动inject
    ...injectionOptions
  })).$mount()
  document.body.appendChild(dialogInstance.$el)

  dialogInstance.$nextTick(() => {
    (dialogInstance as any).show()
  })

  return dialogInstance
}
