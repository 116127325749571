



























































import { Component, mixins } from 'nuxt-property-decorator'
import { IconChevronDown } from '@klook/klook-icons'
import DesktopComboPkgCard from '~/components/experience-booking/experience-activity/combo-option/combo-list/combo-pkg-card/desktop.vue'
import ExpPkgDetailLoading from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/pkg-detail/loading.vue'
import ComboListBase from '~/components/experience-booking/experience-activity/combo-option/combo-list/combo-list-base.ts'
import CombonOptionsBooking from '~/components/experience-booking/experience-activity/combo-option/booking-options/desktop/index.vue'
import { getTaggingExtraTrackObj } from '~/components/experience/tagging/index.vue'
import eventBus from '~/pages/experience/pay/common/event-bus'

@Component({
  components: {
    DesktopComboPkgCard,
    ExpPkgDetailLoading,
    CombonOptionsBooking,
    IconChevronDown
  }
})
export default class ComboPkg extends mixins(ComboListBase) {
  mounted() {
    setTimeout(() => {
      eventBus.$emit('setIsDelayFlag2bus', { from: 'combo_card_mounted' })
    }, 60)
  }

  getTaggingExtraTrackObj(pkg: any) {
    const list = [...(pkg?.product_tags?.attribute_tags || []), ...(pkg?.product_tags?.discount_tags || [])]
    const track = getTaggingExtraTrackObj(list)
    return track || {}
  }

  getPkgTrack(pkg: any, index: number) {
    const obj = this.getTaggingExtraTrackObj(pkg)
    const isSuspended = pkg?.publish_info?.publish_status === 2
    const isWarming = pkg?.publish_info?.publish_status === 3
    const { comboPackageList } = this
    const ext = JSON.stringify({
      SelectType: 'User',
      PackageTagIDList: obj?.TagIDList || [],
      PackageTagName: obj?.TagKeyList || [],
      StatusType: isSuspended ? 'Unavailable' : isWarming ? 'ForSalesSoon' : 'Normal',
      IsComboPackage: true
    })
    return `PackageType_LIST?oid=${`package_${pkg.package_id}`}&len=${comboPackageList?.length || 0}&idx=${index}&ext=${ext}`
  }

  handleScrollTo(element: any = null) {
    setTimeout(() => {
      const $elem = this.$el as HTMLElement
      const main = $elem.querySelector('.package-detail-content') as HTMLElement
      const elem = main.querySelector(element) as HTMLElement

      if (elem) {
        const offsetTop = elem.offsetTop - 160
        main.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        })
      }
    }, 400)
  }
}
