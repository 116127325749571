import { Component, Vue, Watch } from 'nuxt-property-decorator'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import { windowScrollTop, nodeScrollTop } from '~/share/scroll2/scroll2.ts'

@Component
export default class ActivityNavContainer extends Vue {
  navNameList: string[] = []
  activeSection = null
  navList: any[] = []
  sections: {[prop: string]: {
    name: string,
    title: string,
    el: any
  }} = {}

  created() {
    this.$root.$on('nav.addSection', (name: string, section: Vue) => {
      const props = section.$props

      const title = props && props.title
      this.sections = {
        ...this.sections,
        [name]: {
          name,
          title,
          el: section.$el
        }
      }
      this.navNameList = [...this.navNameList, name]
    })

    this.$root.$on('nav.delSection', (name: string) => {
      this.navNameList = this.navNameList.filter(navName => navName !== name)
    })
  }

  getCurrentPosition() {
    // 如果次级导航是子元素，子类需要暴露一个ref.nav
    const nav = (this.$refs.nav as HTMLElement) || this.$el
    const { top, height } = nav.getBoundingClientRect()
    return top + height
  }

  getLastNodeBottom() {
    const lastNav = this.navList[this.navList.length - 1]
    const lastNavNode = lastNav && lastNav.el

    if (!lastNavNode) {
      return 0
    }

    return nodeScrollTop(lastNavNode) + lastNavNode.getBoundingClientRect().height
  }

  getActiveSection() {
    const navPosition = this.getCurrentPosition()

    const firstNode = this.navList[0] && this.navList[0].el
    const firstNodeScrollTop = nodeScrollTop(firstNode)
    const lastNodeBottom = this.getLastNodeBottom()

    // 第一个nav的顶部到最后一个nav的底部之间
    const scrollTop = windowScrollTop()
    if (scrollTop >= firstNodeScrollTop - navPosition && scrollTop < lastNodeBottom - navPosition) {
      this.activeSection = this.reverseNavList.find(v => windowScrollTop() >= nodeScrollTop(v.el) - navPosition)
    } else {
      this.activeSection = null
    }
  }

  get reverseNavList() {
    return [...this.navList].reverse()
  }

  @Watch('navNameList')
  navChange(val: string[]) {
    if (!isEmpty(val)) {
      this.navList = this.navNameList.map((name, index) => {
        const section = this.sections[name]

        return {
          index,
          ...section
        }
      })
    }
  }

  handleNavScroll = debounce(this.getActiveSection, 300)

  mounted() {
    document.addEventListener('scroll', this.handleNavScroll)
    this.handleNavScroll()
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.handleNavScroll)
  }
}
