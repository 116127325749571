













import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IconCheckCircle } from '@klook/klook-icons'
import BookingStartDate from '~/components/traveller/activity/start-date/booking-start-date.vue'

@Component({
  components: {
    BookingStartDate,
    IconCheckCircle
  }
})
export default class ActivityBookingStartDate extends Vue {
  @Prop() startTime!: string
  @Prop() isUnfold!: Boolean

  getStartTime(date: string) {
    const timeConfig: {
      [key: string]: string
    } = {
      today: this.$t('book.same.day'),
      tomorrow: this.$t('book.tomorrow'),
      soldOut: this.$t('book.time.soldout')
    }

    return timeConfig[date] || this.$t('book.time.text', [date])
  }
}
