



















import { Vue, Component, State } from 'nuxt-property-decorator'

/**
 * HOTEL 业务组 也在使用这个组件  如若修改请通知baoyi
 */

@Component
export default class ActivityContactButton extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
}
