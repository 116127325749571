































































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'
import DynamicComponent
  from '~/components/experience-booking/experience-activity/dynamic-component/dynamic-component.vue'
import DynamicDrawer
  from '~/components/experience-booking/experience-activity/dynamic-component/drawer/index.vue'

@Component({
  components: {
    DynamicComponent,
    DynamicDrawer
  }
})
export default class DynamicComponentCanFold extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() components!: ExperienceActivity.DynamicComponent
  @Prop() foldMinHeight!: string
  @Prop() foldStyleType!: number
  @Prop() title!: string

  showMore = false
  isOpen = false

  get foldHeight() {
    if (this.foldMinHeight) {
      return parseInt(this.foldMinHeight)
    }

    return this.platform === 'desktop' ? 1200 : 800
  }

  get isDrawerFoldType() {
    return this.foldStyleType === 0
  }

  get maxHeight() {
    if (!this.isDrawerFoldType) {
      return this.isOpen ? 'none' : `${this.foldHeight}px`
    }
    return `${this.foldHeight}px`
  }

  get btnText() {
    if (!this.isDrawerFoldType) {
      return !this.isOpen ? this.$t('16893') : this.$t('17826')
    }
    return this.$t('city_page_view_more')
  }

  get btnIcon() {
    if (!this.isDrawerFoldType) {
      return this.isOpen ? 'icon_navigation_chevron_up_xs' : 'icon_navigation_chevron_down_xs'
    }
    return 'icon_navigation_chevron_right_xs'
  }

  get drawerComponent() {
    return this.platform === 'desktop' ? 'DynamicDrawer' : 'klk-bottom-sheet'
  }

  openDrawer() {
    this.isOpen = !this.isOpen
  }

  checkHeight() {
    if (process.client) {
      if (this.$el && this.$el.scrollHeight >= this.foldHeight) {
        this.showMore = true
      }
    }
  }

  mounted() {
    setTimeout(this.checkHeight, 50)
  }
}
