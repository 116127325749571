












































import { Component } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({
  components: {}
})
export default class SharingCoupon extends BusinessBase {
  static displayName = 'Customer_platform_share_coupon'

  created() {
    if (isEmpty(this.businessData)) {
      this.handleHideSection()
    }
  }

  get multiple() {
    return (
      this.businessData.share_coupon &&
      this.businessData.share_coupon.length > 1
    )
  }
}
