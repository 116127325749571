import { ExperienceActivity } from '~/types/experience/activity-section'

export const getOpenTicketAutoSelectedDate = ({ isFirstDate, packageSchedules, currentSelectedPackage, packageSelectedDate }: any) => {
  const packageSchedulesCopy = [...(packageSchedules || [])]
  const validateFeasible = (timeSlots: ExperienceActivity.ITimeSlot) => {
    return (!!timeSlots.stock &&
      currentSelectedPackage &&
      timeSlots.stock >= Number(currentSelectedPackage.min_pax || 0))
  }

  // 如果已选了日期，并且是valid，则继续使用这个日期
  if (packageSelectedDate) {
    const target = packageSchedulesCopy.find((item: any) => item.date === packageSelectedDate)
    if (target && (target.time_slots || []).some(validateFeasible)) {
      return packageSelectedDate
    }
  }

  // 如果没选日期，则自动选日期
  const packagePriceSchedules = isFirstDate ? packageSchedulesCopy : packageSchedulesCopy.reverse() // 倒序：自动选最远的可选的日期
  const schedule = packagePriceSchedules.find((item: any) => (item.time_slots || []).some(validateFeasible))
  return schedule?.date || ''
}
