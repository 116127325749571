





















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { resizeImg } from '~/share/utils'

@Component
export default class PassActivityImg extends Vue {
  @State(state => state.klook.platform) platform!: string
  @Prop() list!: string[]
  @Prop() count!: number

  get imgList() {
    return (this.list || []).reverse()
  }

  isLast(index: number) {
    return (index + 1) === this.imgList.length
  }

  getImgSrc(src: string, index: number) {
    return this.isLast(index) ? `url(${src})` : `linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), url(${src})`
  }

  resizeImg(src: string) {
    return this.platform === 'desktop' ? resizeImg(src, 450, 450) : resizeImg(src, 200, 200)
  }
}
