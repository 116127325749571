


















import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class HeaderAlert extends Vue {
  @Prop() packageId!: number

  visible = false
  text = ''
  timeout = 3000
  timer: any = null

  open(text: string) {
    this.$toast(text)
  }

  // open(text: string) {
  //   this.text = text
  //   this.visible = true

  //   if (this.timer) {
  //     clearTimeout(this.timer)
  //   }

  //   this.timer = setTimeout(() => {
  //     this.visible = false
  //   }, this.timeout)
  // }
}
