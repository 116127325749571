export const pageNoticeMap = {
  all: [408],
  country: [408, 'country'],
  city: [408, 'country', 'city'],
  jrpass: [408, 'country', 'city'],
  fnb: [408, 'country', 'city'],
  activity: [408, 'country', 'city', 'activity'],
  wifi: [408],
  cr: [408, 'country', 445],
  hotel_voucher: [408, 'country', 'city']
}

export type pageType = 'all' | 'country' | 'city' | 'jrpass' | 'fnb' | 'activity' | 'wifi' | 'hotel_voucher'

export interface subTypeIds {
  activityId: number,
  cityId: number,
  countryId: number
}
