import { Component, Vue, Prop } from 'vue-property-decorator'
import { throttle } from 'lodash'
import eventBus from '~/pages/experience/pay/common/event-bus'

@Component
export default class Scrollspy extends Vue {
  @Prop() swiperList!: any[]
  @Prop() activeKey!: number
  @Prop() activityId!: number
  @Prop() setActive!: (index: number) => void

  // 滚动锁定
  lockScroll = false
  attributesObserver: any = null
  packagListObserver: any = null
  listCardObserver: any = null

  topNav: any = null
  rightRrice: any = null
  shouldShowPanel = false
  // 导航栏
  attributesSelector = '.group-swiper-attributes'
  // 整个选购分组
  packageListSelector = '.package_options_group'

  // 防止已进入页面
  flag = true
  scrollOffset = 210

  get list() {
    return this.swiperList
  }

  get throttleScroll() {
    return throttle(this.onScroll, 150)
  }

  createSwiperObserver() {
    if ('IntersectionObserver' in window) {
      const { attributesSelector, packageListSelector } = this
      const attributesDom = document.querySelector(attributesSelector)
      const packageDetailDom = document.querySelector(packageListSelector)
      if (attributesDom && !this.packagListObserver && packageDetailDom) {
        this.packagListObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((e) => {
              const { top } = packageDetailDom?.getBoundingClientRect()
              if (e.intersectionRatio > 0) {
                if (top < 0) {
                  // console.log('package option展现', packageDetailDom?.getBoundingClientRect(), e)
                  // 当package option展现的时候，且导航栏没有在可视区域，吸顶,从下喊道
                  this.shouldShowPanel = true
                  this.reMoveBoxShadow()
                }
              } else {
                this.shouldShowPanel = false
                this.addBoxShadow()
                // console.log('package option消失', packageDetailDom?.getBoundingClientRect(), e)
              }
            })
          },
          {
            threshold: [0, 1]
          }
        )
        this.packagListObserver.observe(packageDetailDom)
      }

      if (packageDetailDom && !this.attributesObserver && attributesDom) {
        this.attributesObserver = new IntersectionObserver(
          (entries) => {
            entries.forEach((e) => {
              const top = packageDetailDom?.getBoundingClientRect().top
              if (e.intersectionRatio === 0) {
                if (top < 0) {
                  // console.log('attributesObserver attributesObserver', top)
                  // 2. 当向下滚动的时候，package option的导航栏不展示消失的时候，且package option在可视区域，才进行操作
                  this.shouldShowPanel = true
                  this.reMoveBoxShadow()
                }
              } else {
                // 1. 这里是防止页面进入，导航栏就展示 ,第一步触发这里
                this.shouldShowPanel = false
                this.addBoxShadow()
                // console.log('attributesObserver 消失', top)
              }
            })
          },
          {
            threshold: [0, 1]
          }
        )
        this.attributesObserver.observe(attributesDom)
      }
    }
  }

  getVisibleTop(el: HTMLElement) {
    return el.getBoundingClientRect().top
  }

  onScroll() {
    // this.hideRightPrice()
    if (!this.lockScroll) {
      const index = this.getCurrentIndexOnScroll()
      const value = this.list[index].value
      this.setActive(value)
    }
  }

  getCurrentIndexOnScroll() {
    for (let index = 0; index < this.list.length; index++) {
      const item = this.list[index]
      const id = `group-render-list-${item.value}`
      const element = document.getElementById(id)

      if (element) {
        const top = this.getVisibleTop(element)
        // header+ 吸顶高度
        if (top > this.scrollOffset) {
          return index === 0 ? 0 : index - 1
        }
      }
    }

    return this.list.length - 1
  }

  destroySwiperObserver() {
    const { attributesSelector, packageListSelector } = this
    if (this.attributesObserver) {
      const attributesDom = this.$el.querySelector(attributesSelector)
      attributesDom && this.attributesObserver.unobserve(attributesDom)
    }
    if (this.packagListObserver) {
      const packageDetailDom = document.querySelector(packageListSelector)
      packageDetailDom && this.packagListObserver.unobserve(packageDetailDom)
    }
  }

  reMoveBoxShadow() {
    if (this.topNav) {
      this.topNav.style.boxShadow = 'none'
    }
  }

  addBoxShadow() {
    if (this.topNav) {
      this.topNav.style.boxShadow = 'rgba(0, 0, 0, 0.1) 0px 1px 6px 0px'
    }
  }

  lockScrollChange(val: boolean) {
    this.lockScroll = val
  }

  initDom() {
    // 这里是因为group 吸顶后，要取消掉导航栏的阴影
    const list = document.querySelectorAll('.page-activity-top-nav-sections')
    if (list) {
      this.topNav = list[0] as HTMLElement
    }

    // group不需要右侧价格
    const rightRrice: any = document.querySelectorAll('.desktop-new-right-price')
    if (rightRrice) {
      this.rightRrice = rightRrice[0]
    }
  }

  hideRightPrice() {
    if (this.rightRrice) {
      this.rightRrice.style.display = 'none'
    }
  }

  async mounted() {
    this.initDom()
    await this.createSwiperObserver()

    document.addEventListener('scroll', this.throttleScroll)
    eventBus.$on('groupLockScroll', this.lockScrollChange)
  }

  beforeDestroy() {
    this.destroySwiperObserver()
    document.removeEventListener('scroll', this.throttleScroll)
    eventBus.$off('groupLockScroll', this.lockScrollChange)
  }
}
