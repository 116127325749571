























import { Component, Vue, Prop, State, namespace } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import { Activity } from '~/types/traveller/activity'
import { getStandardDateFormat } from '~/share/data/datetime'
import ExpTagging from '~/components/experience/tagging/index.vue'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    ExpTagging
  }
})
export default class PackageCardInfo extends Vue {
  @ActivityModule.State activityId!: number
  @ActivityModule.State packageId!: number
  @ActivityModule.State packageDetail !: Activity.packageDetail
  @State klook !: Data.Klook
  @Prop({ default: null }) productTags!: any
  @Prop({ default: '' }) packageSubName!: string
  @Prop({ default: () => [] }) icons!: []
  @Prop({ default: 0 }) credits!: number

  get calcTags2attribute() { // productTags：mewb活动页-普通卡片；packageDetail：mweb预订页-新流程-已选套餐、mweb预订页-新流程-更改套餐
    const { productTags, packageDetail } = this
    const data = productTags || packageDetail?.product_tags
    return data?.attribute_tags || []
  }

  get calcTags2discount() { // productTags：mewb活动页-普通卡片；packageDetail：mweb预订页-新流程-已选套餐、mweb预订页-新流程-更改套餐
    const { productTags, packageDetail } = this
    const data = productTags || packageDetail?.product_tags
    return data?.discount_tags || []
  }

  get sepStyle() {
    return {
      display: 'inline-block',
      width: '2px',
      height: '2px',
      background: 'red',
      margin: '0 4px',
      verticalAlign: 'middle'
    }
  }

  get packageDesc() {
    const list: any = []
    if (this.icons && this.icons.length) {
      for (const key of this.icons) {
        const { icon_type, data: { icon_desc = '', time = '' } } = key
        if (icon_type === 'available_date') {
          const _time = this.getStartTime(this.getDate(time))
          list.push(_time)
        } else {
          list.push(icon_desc)
        }
      }
    }
    const res = this.calcCredits()
    res && list.push(res)
    return list.join('<i class="sep"></i>')
  }

  getDate(time: any) {
    const formattedDate = dayjs(time).format('YYYY-MM-DD')
    if (formattedDate === dayjs().format('YYYY-MM-DD')) {
      return 'today'
    } else if (formattedDate === dayjs().add(1, 'day').format('YYYY-MM-DD')) {
      return 'tomorrow'
    } else {
      return getStandardDateFormat(
        formattedDate,
        this.$t.bind(this),
        this.klook.language
      )
    }
  }

  getStartTime(date: string) {
    const timeConfig: {
      [key: string]: string
    } = {
      today: this.$t('book.same.day'),
      tomorrow: this.$t('book.tomorrow'),
      soldOut: this.$t('book.time.soldout')
    }

    return timeConfig[date] || this.$t('book.time.text', [date])
  }

  calcCredits() {
    const _credit = Math.floor(parseFloat(this.credits.toString()))
    if (_credit > 0) {
      return `${this.$t('2347')} +${_credit}`
    }
    return false
  }
}

