














































































import { Component, Watch, Ref, Getter } from 'nuxt-property-decorator'
import throttle from 'lodash/throttle'
import ActivityNavContainer from '~/components/traveller/activity/activity-top-nav-sections/activity-nav'
import DefaultHeader from '~/components/layouts/default/mobile/header.vue'
import { windowScrollTop, nodeScrollTop } from '~/share/scroll2/scroll2'
import { swiperSlideToCenter } from '~/share/data/vendor'
import ActivityNavBase, { INavItem } from '~/components/experience-booking/experience-activity/activity-nav/activity-nav-base'
import eventBus from '~/pages/experience/pay/common/event-bus'

@Component({
  components: {
    ExpAppDownload: () => import('~/components/experience/app-download/index.vue'),
    ActivityNavContainer,
    DefaultHeader
  }
})
export default class ActivityNav extends ActivityNavBase {
  @Ref() header!: Vue
  @Ref() nav!: Element
  @Getter isGuestCheckout!: boolean

  layerVisible = false
  visible = false
  $mySwiper: any = null
  titleScrollTop = 0
  headerHeight = 48
  headerTop = '0'
  navTop = '0'
  navHeight = 0
  showAppDownload = true
  isFirstAppDownload = false
  appDownloadHeight = 0
  hideHeaderGlobal = false

  swiperOption = {
    slidesPerView: 'auto',
    freeMode: true,
    observer: true,
    observeParents: true
  }

  get navVisible() {
    return this.klook.utilConfig.f_source_type !== 'express_check_out'
  }

  get whiteLabelHeader() {
    return !!this.klook.utilConfig.whiteLabelUtilConfig
  }

  @Watch('activeIndex')
  activeSectionChange(val: number) {
    if (val > -1) {
      this.slideToIndex(val)
    }
  }

  @Watch('visible')
  visibleChange(val: boolean) {
    if (val && !this.navHeight) {
      this.$nextTick(function () {
        this.navHeight = this.nav ? this.nav.getBoundingClientRect().height : 48
      })
    }
  }

  // @Watch('appDownloadHeight')
  // appDownloadHeightWatch(val: number) {
  //   const dom: any = document.querySelector('.layout-default_main')
  //   if (dom) {
  //     dom.style.paddingTop = `${this.headerHeight + val}px`
  //   }
  // }

  translate(data: any) {
    this.appDownloadHeight = data.transform
    const sectionPreWrapper = document.querySelector('.experience-section-pre-block') as HTMLElement
    const sectionListWrapper = document.querySelector('.experience-section-list-block') as HTMLElement
    const layoutDom = document.querySelector('.layout-default_main') as HTMLElement
    if (data?.type === 'expand' && windowScrollTop() < 1) {
      sectionPreWrapper && (sectionPreWrapper.style.transform = `translateY(${data.transform}px)`)
      sectionListWrapper && (sectionListWrapper.style.transform = `translateY(${data.transform}px)`)
      layoutDom && (layoutDom.style.paddingTop = '')

      // 让动画更平滑
      const duration = '320ms'
      sectionPreWrapper && (sectionPreWrapper.style.transitionDuration = duration)
      sectionListWrapper && (sectionListWrapper.style.transitionDuration = duration)
      layoutDom && (layoutDom.style.transitionDuration = duration)
    } else {
      layoutDom && (layoutDom.style.paddingTop = `${data.transform}px`)
      sectionPreWrapper && (sectionPreWrapper.style.transform = '')
      sectionListWrapper && (sectionListWrapper.style.transform = '')
    }
    this.initScrollHandler()
  }

  appDownloadClose() {
    this.appDownloadHeight = 0
    this.showAppDownload = false
    this.$nextTick(() => {
      this.initScrollHandler()
    })
  }

  showNavLayer() {
    this.layerVisible = true
  }

  hideLayer() {
    this.layerVisible = false
  }

  handleNavClick(data: INavItem, index: number) {
    if (this.activeIndex === index) {
      return
    }

    const isNext = index >= this.activeIndex
    this.lockHeight = isNext ? this.navHeight + this.headerHeight : this.headerHeight
    this.debounceClickNav(data)

    this.hideLayer()
  }

  slideToIndex(index: number) {
    swiperSlideToCenter(index, this.$mySwiper)
  }

  // 滑动title显示二级导航
  getTitleNodeOffsetTop() {
    if (this.titleScrollTop) {
      return this.titleScrollTop
    }

    const titleNode = document.querySelector('.j-activity-head-info_title')

    if (!titleNode) {
      // 取不到给一个默认值
      this.titleScrollTop = 350
      return
    }

    this.titleScrollTop = nodeScrollTop(titleNode)
  }

  _scroll = 0

  initScrollHandler() {
    const windowTop = windowScrollTop()
    this.initHeaderHeight()

    // 滑动到标题显示二级导航
    this.getTitleNodeOffsetTop()

    this.visible = this.isHeroPage ? false : windowTop > this.titleScrollTop

    // 向上滚动
    const isScrollUp = windowTop < this._scroll
    this.navTop = isScrollUp ? `${this.appDownloadHeight}px` : `${this.headerHeight + 0.5 + this.appDownloadHeight}px`
    eventBus.$emit('getHeaderNavTop', this.navTop)

    if (windowTop > this.headerHeight && isScrollUp) {
      this.headerTop = `-${this.headerHeight - this.appDownloadHeight}px`
    } else if (this._scroll >= 0) {
      this.headerTop = `${this.appDownloadHeight}px`
    }

    this._scroll = windowTop

    this.lockHeight = isScrollUp ? this.navHeight : this.navHeight + this.headerHeight

    this.initScroll()
  }

  handleCheckVisible() {
    this.isFirstAppDownload = true
    const windowTop = windowScrollTop()
    this.initHeaderHeight()

    // 处理页面防滑动穿透的scrollTop跳动
    if (document.body.style.position === 'fixed' || windowTop === this._scroll) {
      return
    }

    this.initScrollHandler()
  }

  handleScroll = throttle(this.handleCheckVisible, 100)

  initHeaderHeight() {
    const ref = this.header?.$el || this.header
    if (!ref?.getBoundingClientRect) {
      return
    }
    this.headerHeight = ref.getBoundingClientRect().height
  }

  mounted() {
    this.$nextTick(function () {
      this.initHeaderHeight()
    })

    if (this.navVisible) {
      document.addEventListener('scroll', this.handleScroll)
      this.handleScroll()
    }

    eventBus.$off('getHeaderHeight2bus', this.getHeaderHeight2bus).$on('getHeaderHeight2bus', this.getHeaderHeight2bus)
    eventBus.$off('toggleGlobalHeaderVisible', this.toggleGlobalHeaderVisible).$on('toggleGlobalHeaderVisible', this.toggleGlobalHeaderVisible)
  }

  getHeaderHeight2bus(cb: Function, options?: any) {
    const { navTop } = this
    const { from, hideFlag } = options || {}
    switch (from) {
      case 'act-page':
        this.hideHeaderGlobal = hideFlag
        break
      default:
        break
    }

    typeof cb === 'function' && cb(navTop)
  }

  toggleGlobalHeaderVisible(visible: boolean) {
    this.hideHeaderGlobal = !visible
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll)
    eventBus.$off('getHeaderHeight2bus', this.getHeaderHeight2bus)
    eventBus.$off('toggleGlobalHeaderVisible', this.toggleGlobalHeaderVisible)
  }
}
