













import { Component, Vue, State, Prop } from 'nuxt-property-decorator'
import ImageGalleryNew from './image-gallery-new.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    ImageGalleryNew
  }
})
export default class MerchantImageGallery extends Vue {
    @Prop() activityBanner !: ExperienceActivity.IBannerItem[]
    @State klook !: Data.Klook

    get merchantImages() {
      const { webp } = this.klook
      return this.activityBanner.map((v) => {
        const suffix = webp ? v.suffix.replace(/.(jpg|png|jpeg|gif)$/, '.webp') : v.suffix

        return {
          ...v,
          src: `${v.prefix}fl_lossy.progressive,q_65/${suffix}`,
          imageDesc: v.image_desc
        }
      })
    }
}
