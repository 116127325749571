




























































import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import { scrollToPackageOption } from '~/share/scroll2/scroll2'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop() styleType!: 'desktop-right-price-type'
  @Prop() hideTitle!: boolean
  @Prop({ default: '' }) title!: string
  @Prop({ default: false }) hideDashed!: boolean
  @Prop() priceSummary!: any

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get packageList() {
    return this.priceSummary?.package_list || []
  }

  get savingList() {
    return this.priceSummary?.discount_list || []
  }

  get totalList() {
    return this.priceSummary?.total_saving || []
  }

  gotoEditPkg(pkgData: any) {
    const { package_id } = pkgData || {}
    scrollToPackageOption({ firstEl: `#js-pkg-id-${package_id}`, lockLocation: 110 })
  }
}
