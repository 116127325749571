import dayjs from 'dayjs'

const getLocalDate = (day?: any) => {
  const localeDate = dayjs(day || undefined).toString()
  return new Date(localeDate)
}
const getShouldExpendDate = ({
  isPackageSchedule,
  packageId,
  calendarDays
}: {
    isPackageSchedule: boolean,
    packageId: string | number,
    calendarDays?: number,
}) => {
  return Boolean(!!isPackageSchedule && packageId && +packageId > 0 && calendarDays && calendarDays > 0)
}

const getAvilableDateRange = ({
  isPackageSchedule,
  schedules = [],
  packageId,
  calendarDays
}: {
    isPackageSchedule: boolean,
    packageId: string | number,
    schedules: any[],
    calendarDays?: number,
}) => {
  const shouldExpendDate = getShouldExpendDate({ isPackageSchedule, packageId, calendarDays })
  const schedulesLen = schedules.length || 0
  const minDate = shouldExpendDate ? dayjs().format('YYYY-MM-DD') : schedules?.[0]?.date
  const maxDate = calendarDays && calendarDays > 0 && shouldExpendDate ? dayjs().add(calendarDays, 'days').format('YYYY-MM-DD') : (schedulesLen > 0 ? schedules[schedulesLen - 1].date : undefined)
  return {
    minDate: getLocalDate(minDate),
    maxDate: getLocalDate(maxDate)
  }
}

export { getAvilableDateRange, getLocalDate }
