






























































import { Component } from 'nuxt-property-decorator'
import ComboPkgItemBase from './base'
import ExpTagging from '~/components/experience/tagging/index.vue'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'
import DesktopPackageDetailButton from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-button.vue'
import ComboDiscountTag from '~/components/experience-booking/experience-activity/combo-option/combo-list/combo-pkg-card/components/combo-discount-tag.vue'
// import ComboWarningSlogan
//   from '~/components/experience-booking/experience-activity/combo-option/combo-list/combo-pkg-card/components/combo-warming-slogan.vue'

@Component({
  components: {
    ExpTagging,
    WarmingSlogan,
    ComboDiscountTag,
    DesktopPackageDetailButton
  }
})
export default class DesktopComboPkgCard extends ComboPkgItemBase {
  get getPkgDetailTrack() {
    const { packageId } = this
    const ext = JSON.stringify({
      IsComboPackage: true
    })
    return `PackageDetailsBtn?oid=${`package_${packageId}`}&ext=${encodeURIComponent(ext)}`
  }
}
