














import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import { openDeeplink } from '~/components/experience-booking/booking-details/utils'

@Component({
  components: {
    //
  }
})
export default class Index extends BusinessBase {
  static displayName = 'Partner_award'
  @State klook!: Data.Klook

  get calcObj() {
    const { klook, businessData } = this
    const src = klook.platform === 'desktop' ? businessData?.web_url : businessData.mobile_url
    const obj = {
      src,
      deep_link: ''
    }
    return obj
  }

  clickHandler(item: any) {
    openDeeplink(item.deep_link, this.klook.platform)
  }
}
