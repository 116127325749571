



















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import Logo from '~/components/experience/card-swiper/logo.vue'
import { replaceImageSize } from '~/share/data/image'

@Component({
  components: {
    Logo
  }
})
export default class Index extends Vue {
  @Prop() src!: string
  @Prop() height!: number
  @Prop() width!: number
  @Prop({ default: '1:1' }) ratio!: string

  logoWidth = 0

  get cardImgStyle() {
    const { width, height } = this || {}
    const style = {
      width: width ? width + 'px' : 'auto',
      height: height ? height + 'px' : 'auto'
    }

    return style
  }

  get replacedImgSrc() {
    const [width, height] = this.ratio.split(':')
    const percent = Number(width) / Number(height)
    const w = Math.floor(this.height * percent)
    const src = this.src
    const widthAndHeight = `w_${2 * w},h_${2 * this.height}`

    // 后端返回了尺寸
    if (src.includes('c_fill')) {
      return replaceImageSize({
        url: src,
        width: 2 * w,
        height: 2 * this.height
      })
    }

    return src.replace('image/upload/', `image/upload/q_85/c_fill,${widthAndHeight}/`)
  }

  getLogoProps() {
    if (process.client) {
      let logoWidth = 0

      if (this.width) {
        logoWidth = this.width
      } else {
        const { width } = this.$el.getBoundingClientRect()
        logoWidth = width
      }

      if (this.ratio === '16:9') {
        this.logoWidth = logoWidth * 3 / 16
      } else {
        this.logoWidth = logoWidth / 3
      }
    }
  }

  mounted() {
    this.getLogoProps()
  }
}
