import { VueClass } from '@vue/test-utils'
import { Component, Vue, Prop, Watch } from '~/node_modules/nuxt-property-decorator'
import DefaultSectionHeader from '~/components/setion/experience/components/header/header.vue'
import DefaultSectionFooter from '~/components/setion/experience/components/footer/footer.vue'
import DefaultBody from '~/components/setion/experience/components/body.vue'
import DefaultDivider from '~/components/setion/experience/components/divider/index.vue'

interface options {
  SectionHeader: VueClass<Vue>,
  SectionBody: VueClass<Vue>,
  SectionDivider?: VueClass<Vue>,
  SectionFooter: VueClass<Vue>
}

type state = 'success' | 'failure' | 'loading'
type sectionType = 'any' | 'common' | 'tab'

/**
 * 解决home-section的几个不完善的地方
 * 1. 和首页楼层数据耦合
 * 2. 业务组件不能自定义loading，disabled楼层
 */
export default function SectionHoc({
  SectionHeader = DefaultSectionHeader,
  SectionBody = DefaultBody,
  SectionFooter = DefaultSectionFooter,
  SectionDivider = DefaultDivider
}: options) {
  @Component({
    components: {
      SectionHeader,
      SectionBody,
      SectionFooter,
      SectionDivider
    }
  })
  class KSection extends Vue {
    @Prop() section!: any
    @Prop() sectionState!: 'loading' | 'success' | 'failure'
    @Prop({ default: 'any' }) type!: sectionType

    visible: boolean = true
    state = ''

    @Watch('sectionState')
    stateChange(val: string) {
      this.state = val
    }

    get sectionConfig() {
      return {
        // 任意 section 容器组件
        any: ['SectionBody'],
        // 通用容器组件
        common: ['SectionHeader', 'SectionBody', 'SectionFooter'],
        // Tab 容器组件
        tab: ['SectionHeader', 'SectionBody', 'SectionFooter'],
        divider: ['SectionDivider']
      }
    }

    get sectionType() {
      return this.section?.meta?.type || this.type
    }

    // 根据 section 类型，返回 section 所需要的header、body、footer
    get sectionComponents() {
      return this.sectionConfig[this.sectionType as sectionType] || []
    }

    get id() {
      return this.section?.body?.content?.data_type || ''
    }

    // 隐藏整个楼层
    hideSection() {
      this.visible = false

      this.$emit('hide', this.section?.meta?.name)
    }

    setSectionState(state: state) {
      if ('success,failure,loading'.includes(state)) {
        this.state = state
      }
    }

    refresh(params: any) {
      this.state = 'loading'

      this.$emit('refresh', params)
    }

    getComponents() {
      return this.sectionComponents.map((Component: any, index: number) => {
        return <Component
          key={index}
          section={this.section}
          state={this.state}
          setSectionState={this.setSectionState}
          hideSection={this.hideSection}
          refresh={this.refresh}
          {...{ attrs: this.$attrs }}
          {...{ on: this.$listeners }}
        />
      })
    }

    render() {
      if (!this.visible) {
        return null
      }
      // auto_width_section 属性表示楼层 与 section-container 同宽
      const section = this.section.fields || []
      const autoWidth = section.find((item: any) => item.key === 'auto_width_section')
      const autoWidthClass = autoWidth && autoWidth.value === 1 ? 'auto-width-section-class' : ''
      const className = `k-section ${this.id} ${autoWidthClass}`
      return (
        <div class={className} key={this.id}>
          {this.getComponents()}
        </div>
      )
    }
  }

  return KSection
}
