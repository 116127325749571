











import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'

/**
 * 一旦初始化就不会消失（一旦 visible 为 true，则不会再因为 visible 改变）
 */

@Component({
  components: {
    PublishAiText: () => import('~/components/common/publish-ai/publish-ai-text.vue' /* webpackChunkName: 'publish-ai-text' */),
    PublishAiBall: () => import('~/components/common/publish-ai/publish-ai-ball.vue' /* webpackChunkName: 'publish-ai-ball' */),
    PublishAiButton: () => import('~/components/common/publish-ai/publish-ai-button.vue' /* webpackChunkName: 'publish-ai-button' */)
  }
})
export default class PublishAi extends Vue {
  @Prop({ default: false }) visible!: boolean // ball 和 button 的时候，控制切换的时候按钮不消失
  @Prop({ default: 'text' }) type!: 'text' | 'ball' | 'button'
  @Prop({ default: false }) isSourceText!: boolean

  shouldInit = false

  get componentName() {
    return `publish-ai-${this.type}`
  }

  get trackInfo() {
    const info = {
      ext: {
        ClickType: this.isSourceText ? 'Translate' : 'SwitchBack'
      }
    }
    return info
  }

  @Watch('visible', { immediate: true })
  visibleChange(val: boolean) {
    if (val || this.type === 'text') {
      this.shouldInit = true
    }
  }
}
