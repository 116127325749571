






















import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class Index extends Vue {
  @Prop({ default: '' }) status!: '' | 'more' | 'less'
  @Prop({ default: '' }) moreText!: string
  @Prop({ default: '' }) lessText!: string
  @Prop({ default: '' }) moreStyle!: any
  @Prop({ default: false }) hideIcon!: boolean

  get calcIconName() {
    const { status } = this
    let str = ''
    if (status) {
      str = status === 'more' ? 'common#icon_navigation_chevron_down_xxs' : 'common#icon_navigation_chevron_up_xxs'
    }
    return str
  }

  clickBtn() {
    const { status } = this
    status && this.$emit('click', status === 'more' ? 'less' : 'more')
  }

  get calcText() {
    const { status, moreText, lessText } = this
    let text = ''
    if (status === 'more') {
      text = moreText || this.$t('see_more')
    } else if (status === 'less') {
      text = lessText || this.$t('17843')
    }
    return text
  }
}
