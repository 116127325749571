




































import { Component, State, Prop, Watch, Vue } from 'nuxt-property-decorator'
import AttractionPreviewList from './attraction-preview-list.vue'
import AttractionPreview from './attraction-preview-detail.vue'
import ExperienceModal from '~/components/common/experience-modal/mobile.vue'

@Component({
  components: {
    ExperienceModal,
    AttractionPreviewList,
    AttractionPreview
  }
})
export default class AttractionIncludedPop extends Vue {
  @State((state) => state.klook) klook!: Data.Klook;
  @Prop({ default: false }) visible!: boolean;
  @Prop({ default: false }) isBook!: boolean;

  @Prop() activityId!: number;
  @Prop() packageId!: number
  @Prop() total!: number;
  @Prop() title!: string;
  @Prop({ default: '' }) deepLink !: string;

  shouldInit = false;

  showType = true;
  // 选中的活动详情id
  currentPassPackageId = 0;
  openDetail = false;

  groupId: number = 0

  get showInfo() {
    return this.currentPassPackageId > 0
  }

  @Watch('visible')
  visibleChange(val: boolean) {
    if (val) {
      this.shouldInit = true
      this.sendPageView()
    }
  }

  headerLeftClick() {
    this.close()
  }

  close() {
    this.$emit('closeModal')
  }

  sendPageView() {
    if (process.client) {
      setTimeout(() => this.$inhouse.track('pageview', this.$el, { force: true }), 100)
    }
  }

  get selfTitle() {
    return this.title || this.$t('76895', { num: this.total })
  }

  get displayDetialTitle() {
    return this.$t('76898')
  }

  setShowType(payload: boolean) {
    this.showType = payload
  }

  closeDetialModal() {
    this.openDetail = false
    this.currentPassPackageId = 0
  }

  handleToDetail(id: number) {
    this.openDetail = true
    this.currentPassPackageId = id
  }

  handleGroupIdChange(id: number) {
    this.groupId = id
  }
}
