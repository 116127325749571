export default {
  data() {
    return {};
  },
  inject: ['__t'],
  props: {
    mode: {
      type: String,
      default: 'secondary', // primary|'secondary'
    },
    modeColor: {
      type: String,
      default: 'default',
    },
    redeemPage: {
      // page 来源
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isAsyncComponentLoading: {
      // 组件加载
      type: Boolean,
      default: false,
    },
    platform: {
      type: String,
      default: '',
    },
    loginData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    couponExtras: {
      type: Object,
      default: () => {
        return {};
      },
    },
    inhouse: null,
    klook: {
      type: Object,
      default: null,
    },
    purpose: {
      type: String,
      default: 'coupon',
    },
    aid: {
      type: String,
      default: '',
    },
    isMP: {
      type: String,
      default: '',
    },
    language: {
      type: String,
      default: '',
    },
    currency: {
      type: String,
      default: '',
    },
    market: {
      type: String,
      default: '',
    },
    bizName: {
      type: String,
      default: '',
    },
    isLoggedIn: undefined,
    loginMethod: null,
    axios: null,
  },
  computed: {
    realPlatform() {
      return this.platform || this.realKlook.platform;
    },
    realKlook() {
      return this.klook || this.$store?.state?.klook || this.$tetris?.runtime;
    },
    realInhouse() {
      return this.inhouse || this.$inhouse || this.$tetris?.tracker?.inhouse;
    },
    realAxios() {
      return this.axios || this.$axios || this.$tetris?.axios;
    },
  },
  methods: {
    async realIsLoggedIn() {
      if (this.isLoggedIn !== undefined) {
        return this.isLoggedIn;
      }
      if (this.$tetris) {
        return await this.$tetris?.checkIsLogin();
      }
      return this.$store?.state?.auth?.isLoggedIn;
    },
  },
};
