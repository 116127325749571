






















































import { Component, State, Watch } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component
export default class Insider extends BusinessBase {
  static displayName = 'Klook_insider'
  @State(state => state.klook.platform) platform!: Data.Platform
  visible: boolean = false
  arrowIcon: string = 'common#icon_navigation_chevron_down_xxs'
  collapse: boolean = true
  showIcon: boolean = false
  get cClass() {
    return `activity-insider_${this.klook.platform}`
  }

  get klookInsider() {
    return this.businessData.klook_insider
  }

  get modelTitle() {
    return this.$t('49501')
  }

  @Watch('klookInsider', { immediate: true })
  klookInsiderChange(val: boolean) {
    if (!val) {
      this.hideSection()
    }
  }

  showMore() {
    this.collapse = !this.collapse
    this.arrowIcon = this.collapse ? 'common#icon_navigation_chevron_down_xxs' : 'common#icon_navigation_chevron_up_xxs'
  }

  calcHeight() {
    const target = document.querySelector('.activity-insider-content_desc .content') as HTMLElement
    if (target) {
      const { scrollHeight, clientHeight } = target
      this.showIcon = scrollHeight > clientHeight
    }
  }

  showModel() {
    this.platform === 'mobile' && (this.visible = true)
  }

  /* eslint-disable */
  async mounted() {
    // 获取元素的高度进行判断
    this.calcHeight()
  }
}
