





























































































import { Component, Vue, Ref, Watch, Prop, Inject } from 'nuxt-property-decorator'
import { CancelTokenSource } from 'axios'
import apis from '~/share/data/apis'
import { ExperienceActivity } from '~/types/experience/activity-section'
import BaseSticky from '~/components/common/base-sticky.vue'
import ReviewRating from '~/components/experience-booking/experience-activity/recent-review/review-rating.vue'
import ReviewRatingV2 from '~/components/experience-booking/experience-activity/recent-review/review-rating-v2.vue'
import ReviewFilter from '~/components/experience-booking/experience-activity/review/review-filter.vue'
import ReviewBlank from '~/components/experience-booking/experience-activity/review/mobile/reviews-modal/reviews-blank.vue'
import ReviewLoading from '~/components/experience-booking/experience-activity/review/mobile/reviews-modal/reviews-loading.vue'
import ReviewItem from '~/components/experience-booking/experience-activity/review/mobile/reviews-modal/reviews-item.vue'
import ReviewGallery from '~/components/experience-booking/experience-activity/review/mobile/reviews-modal/reviews-gallery.vue'
import { scrollToPackageOption } from '~/share/scroll2/scroll2'
import ExperienceModal from '~/components/common/experience-modal/mobile.vue'
import ExpSubScore from '~/components/experience/sub-score/index.vue'
import ExpLoadingState from '~/components/experience/loading-state/index.vue'
import { ActReviewsSkeleton } from '~/components/common/skeleton-data/index.js'
import CommonContentLoading
  from '~/components/experience-booking/activity/package-detail/status/common-content-loading.vue'

interface Params {
  page: number,
  limit: number,
  star_num: string,
  lang: string,
  sort_type: number,
  only_image: boolean,
  translate: number
}

@Component({
  components: {
    CommonContentLoading,
    ExpLoadingState,
    ExpSubScore,
    ExperienceModal,
    ReviewRating,
    ReviewRatingV2,
    ReviewFilter,
    ReviewBlank,
    ReviewLoading,
    ReviewItem,
    ReviewGallery,
    BaseSticky
  }
})
export default class ReviewsModal extends Vue {
  @Prop() version!: string
  @Prop() subScoreList!: any[]
  @Prop() activityId!: number
  @Prop() reviewGalleryShouldShow!: boolean
  @Prop() reviews!: ExperienceActivity.Reviews
  @Prop() reviewTotal!: number
  @Prop() visible!: boolean
  @Prop() score!: number
  @Prop() reviewCount!: number
  @Prop({ type: Boolean, default: false }) fullscreen!: boolean

  @Ref() content!: Vue

  @Inject({ default: (_: any) => _ }) getScrollElBySectionConfig2provide!: Function

  ActReviewsSkeleton = ActReviewsSkeleton

  page = 0
  pageSize = 8
  loading = false
  loadingError = false
  gallaryVisible = false
  total = 0
  reviewList: ExperienceActivity.Reviews = []
  isPinned = false

  filter = {
    star_num: '',
    lang: '',
    sort_type: 0,
    only_image: false,
    translate: 1
  }

  activityReviewsCancelToken!: CancelTokenSource

  get calcIsv2() {
    return this.version === 'v2'
  }

  @Watch('reviews', { immediate: true })
  reviewsChange() {
    const { reviews, reviewTotal } = this
    this.total = reviewTotal // 这个决定了弹窗要不要请求api

    if (reviews?.length) {
      this.reviewList = reviews
      // 如果服务端成功请求了reviews，客户端从第二页开始请求
      this.page = 1
    }
  }

  @Watch('visible')
  visibleChange(val: boolean) {
    if (val) {
      this.$nextTick(() => {
        this.$inhouse.track('pageview', '#ReviewPage__spm_page', { force: true })
      })
      if (!this.reviewList?.length) {
        this.getReviews()
      }
    }
  }

  get getReviewParams(): Params {
    return {
      page: this.page,
      limit: this.pageSize,
      ...this.filter
    }
  }

  get isNoMore() {
    if (this.total === null) {
      return false
    }

    // 上一页已经超过总数，不请求下一页了
    return Number(this.total) <= this.page * this.pageSize
  }

  async handleFilterChange(e: any) {
    // 防止getReviews之后有push review到state中
    this.activityReviewsCancelToken && this.activityReviewsCancelToken.cancel()

    this.filter = {
      ...this.filter,
      star_num: e.star_num ? e.star_num.join() : '',
      lang: e.lang ? e.lang.join() : '',
      sort_type: e.sort_type ? e.sort_type : 0,
      only_image: e.only_image ? e.only_image : false
    }

    this.page = 1
    await this.replaceActivityReviews()
    this.adjustScrollPosition()
  }

  async getReviews() {
    if (this.loadingState === 'fetching' || this.loading || this.isNoMore) {
      return
    }

    this.loadingError = false
    this.loading = true

    let res: any = null
    try {
      res = await this.fetchActivityReview({ page: this.page + 1 })
    } catch (error) {
      /* eslint-disable-next-line */
      console.log('fetchActivityReview error: ', error)
    }

    this.loading = false

    if (res && res.item) {
      this.reviewList = this.reviewList.concat(res.item)
      this.total = res.total
      this.page += 1
    } else {
      this.loadingError = true
    }
  }

  // 单次请求review, limit 100以内（review接口最大limit是100）
  async fetchActivityReview(params?: any) {
    this.activityReviewsCancelToken = this.$axios.CancelToken.source()

    const { config: axiosConfig = {}, ...rest } = params || {}

    const config = {
      params: {
        ...this.getReviewParams,
        ...rest,
        activity_id: this.activityId
      },
      ...axiosConfig,
      cancelToken: this.activityReviewsCancelToken?.token
    }

    const res = await this.$axios.$get(apis.getActivityReviewsList, config)

    const { result } = res
    if (res.success && result) {
      return result
    } else {
      return null
    }
  }

  async fetchActivityReviewsList(params: any) {
    // review接口最大limit
    const MAX_LIMIT = 100

    const reviewParams = Object.assign({}, this.getReviewParams, params || {})
    const { limit, ...rest } = reviewParams

    const size = Math.floor(limit / MAX_LIMIT)
    const lastSize = limit % MAX_LIMIT

    const paging = new Array(size).fill(MAX_LIMIT).concat(lastSize).filter(v => v)

    const request = paging.map((v, index) => this.fetchActivityReview({ page: index + 1, limit: v, ...rest }))

    const resList = await Promise.all(request)
    if (!resList || !resList.length) { return }
    let totalReview = -1
    const reviews = resList.reduce((acc, v) => {
      if (!v) { return acc }

      const { total, item } = v

      if (totalReview < 0) { totalReview = total }

      if (item) {
        return acc.concat(item)
      }

      return acc
    }, [])

    // 超过了limit后其他结果就不正确了，只保留total
    if (totalReview > -1) {
      return { total: totalReview, item: reviews || [] }
    }
  }

  loadingState = 'success' // 首次打开已经有数据了

  async replaceActivityReviews() {
    this.loadingState = 'fetching'
    const res = await this.fetchActivityReviewsList({
      page: 1,
      limit: this.page * this.pageSize,
      config: { loading: false }
    })

    if (res) {
      const { total, item } = res
      this.total = total || 0
      this.reviewList = item || []
      this.loadingState = 'success'
    } else {
      this.loadingState = 'failure'
    }
  }

  getReviewFilterState(state = '') {
    this.loadingState = state
  }

  close() {
    this.$emit('close')
  }

  showGallery() {
    this.gallaryVisible = true
  }

  closeGallery() {
    this.gallaryVisible = false
  }

  handleBuyNow() {
    this.gallaryVisible = false
    this.$emit('close')

    setTimeout(() => {
      this.$nextTick(() => {
        scrollToPackageOption(this.getScrollElBySectionConfig2provide())
      })
    }, 500)
  }

  // 调整滚动位置，如果pin住的时候需要滚动到最上面pin住的地方
  adjustScrollPosition() {
    if (this.isPinned) {
      const anchorDom = this.$el?.querySelector('.reviews-modal-anchor')
      if (anchorDom) {
        anchorDom.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        })
      }
    }
  }
}
