








































































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import PassActivityPrice from './components/pass-activity-price.vue'
import PassActivityReview from './components/pass-activity-review.vue'
import PassActivityImg from './components/pass-activity-img.vue'

@Component({
  components: {
    PassActivityPrice,
    PassActivityReview,
    PassActivityImg
  }
})
export default class PassActivityCard extends Vue {
  @State(state => state.klook.platform) platform!: string
  @Prop() cardData!: Record<string, any>

  get isDesktop() {
    return this.platform === 'desktop'
  }

  get title() {
    return this.cardData?.pass_act_name || ''
  }

  get subTitleHtml() {
    const { standard_act_name = '', standard_act_num } = this.cardData || {}
    if (standard_act_num === 1) {
      return this.$t('78233', { attraction_name: `<b>${standard_act_name}</b>` })
    }
    return this.$t('76909', {
      Attraction_name: `<b>${standard_act_name}</b>`,
      num: `<b>${standard_act_num - 1}</b>`
    })
  }

  get priceShow() {
    return this.cardData?.price_show
  }

  get count() {
    return this.cardData?.standard_act_num - 1
  }

  get imgList() {
    return this.cardData?.standard_act_images
  }

  get tagText() {
    return this.cardData?.price_show?.tag
  }

  get review() {
    return this.cardData?.review
  }
}
