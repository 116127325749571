













import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class BookingUnitsLoading extends Vue {
  @Prop({ default: 1 }) unitNum?: number
}
