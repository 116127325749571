






import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'

@Component
export default class AttrCollapse extends Vue {
  @Prop() value!: string[]

  childrenMap = new Map<string, any>()

  @Watch('value')
  valueChange() {
    this.updateChildren()
  }

  mounted() {
    this.updateChildren()
  }

  addItem(name: string, item: any) {
    this.childrenMap.set(name, item)
  }

  removeItem(name: string) {
    this.childrenMap.delete(name)
  }

  updateChildren() {
    const val = this.childrenMap.entries()
    for (const [name, ref] of val) {
      if (this.value.includes(name)) {
        ref.toggle(true)
      } else {
        ref.toggle(false)
      }
    }
  }

  toggleItem(name: string) {
    if (this.value.includes(name)) {
      this.value = this.value.filter(item => item !== name)
    } else {
      this.value.push(name)
    }

    this.$emit('change', name)
    this.$emit('input', this.value)
  }
}
