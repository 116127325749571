


















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import BaseCardsList from './base-cards-list.vue'
import BaseCardsSwiper from './base-cards-swiper.vue'

@Component({
  components: {
    BaseCardsList,
    BaseCardsSwiper
  }
})
export default class BaseActivityCards extends Vue {
  @Prop({ default: null }) data!: any
  @State klook!: Data.Klook
  @Prop() logoWidth!: number
  @Prop() ratio!: string

  get customRatio() {
    return this.ratio || this.data?.banner_scale
  }

  get cardType() {
    return this.data?.card_type || -1
  }

  get cardData() {
    const list = Array.isArray(this.data) ? this.data : this.data?.items
    // return (list || []).filter((v: any) => !v.sold_out)
    return list || []
  }

  get isBigCard() {
    return [3, 4].includes(this.cardType)
  }

  get useNewCard() {
    return this.cardType > 0
  }

  get component() {
    return [1, 3].includes(this.cardType) ? 'BaseCardsList' : 'BaseCardsSwiper'
  }
}
