































import { Component, Prop, Vue, State } from 'nuxt-property-decorator'
import MobilePackageDetailButton from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-button.vue'
import ActivityBookingStartDate from '~/components/traveller/activity/start-date/activity-booking-start-date.vue'

@Component({
  components: {
    ActivityBookingStartDate,
    MobilePackageDetailButton
  }
})
export default class PackageCardTitle extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: '' }) title!: string | string[]
  @Prop({ default: '' }) packageSubName!: string
  @Prop({ default: '' }) activityName!: string
  @Prop({ type: Boolean, default: false }) isPassActivity!: boolean
  @Prop({ type: Boolean, default: false }) hideEntry!: boolean
}
