

















import { Vue, Component, Prop } from 'nuxt-property-decorator'
import ActivityMarkdown from '~/components/traveller/activity/activity-markdown.vue'

@Component({
  components: {
    ActivityMarkdown
  }
})
export default class PackageIncluded extends Vue {
  @Prop({ type: Array, default: () => [] }) sections!: any []
}
