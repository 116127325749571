























































import { Component } from 'nuxt-property-decorator'
import IndexMixin from '../mixin'

@Component
export default class AactivityHotelDesktop extends IndexMixin {
  static displayName = 'DesktopActivity_hotel'

  get rateList() {
    return (this.lowestPriceInfo || {}).lowest_rate_list || []
  }

  get firstThreeItems() {
    return this.rateList.slice(0, 3)
  }

  get otherItems() {
    const other = this.rateList.slice(3)
    return {
      items: other,
      price: other[0].format_price
    }
  }
}
