


































import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
// import PackageDetailContent from './package-detail-content.vue'
// import PackageDetailCollapse from '~/components/common/package-detail-collapse/package-detail-collapse.vue'
import MActivityPackageIcons from '~/components/traveller/activity/activity-package-icons/mobile/index.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import ExpTagging from '~/components/experience/tagging/index.vue'
import PackageDetailUnoinComponent from '~/components/experience-booking/experience-activity/activity-package-detail/index.vue'
import BaseTransitionOnce from '~/components/common/base-transition-once.vue'

@Component({
  components: {
    ExpTagging,
    BaseTransitionOnce,
    // PackageDetailContent,
    // PackageDetailCollapse,
    MActivityPackageIcons,
    PackageDetailUnoinComponent
  }
})
export default class PackageDetailMain extends Vue {
  @Prop() packageDetail!: ExperienceActivity.IPackageDetail
  @Prop() isPresale!: any
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop({ default: true }) expandAll!: boolean
  @Prop({ default: false }) isEnableFirstFadeIn!: boolean
  @Prop({ type: Boolean, default: false }) isAiTranslation!: boolean
  @Prop({ type: Number, default: 0 }) imageCardSwiperType!: number

  get calcTags2attribute() { // mweb活动页-销售属性-套餐详情
    const { product_tags } = this.packageDetail as any
    return product_tags?.attribute_tags || []
  }

  get sections() {
    return this.packageDetail?.section_content?.sections
  }

  get icons() {
    return this.packageDetail?.section_content?.icons?.map((v: any) => ({
      iconUrl: v.icon_url,
      iconDesc: v.icon_desc
    }))
  }
}
