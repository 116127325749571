











































































































































import { cloneDeep } from 'lodash'
import { Component, Prop, Watch, Ref, mixins, namespace } from 'nuxt-property-decorator'
import HeaderTips from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/header-tips.vue'
import CardHeader from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/card-header.vue'
import BookingCalendar from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/booking-calendar.vue'
import BookingUnit from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/booking-unit.vue'
import HeaderAlert from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/header-alert.vue'
import PackageTimeslot from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/package-timeslot.vue'
import SkuBottomBar from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/index.vue'
import BookineOptionsSkeleton from '~/pages/experience/booking-options/skeleton.vue'
import ExpLoadingState from '~/components/experience/loading-state/index.vue'
import BookingBase from '~/components/booking-core/mixins/entry/index-base-sku'
import CommonContentLoading from '~/components/experience-booking/activity/package-detail/status/common-content-loading.vue'
import ActivityValidity from '~/components/experience-booking/experience-activity/open-date-ticket/activity-validity/activity-validity-new.vue'
import HasOpenDateTips from '~/components/experience-booking/experience-activity/package-options/desktop/has-open-date-tips.vue'
import ExpTagging from '~/components/experience/tagging/index.vue'
import PriceDiscountTips from '~/components/experience/price-slot/discount-tips.vue'
import SavingPriceDetails from '~/components/experience/saving-price-details/index.vue'
import eventBus from '~/pages/experience/pay/common/event-bus'
import * as types from '~/store/traveller/activity/types'
import { ExperienceActivity } from '~/types/experience/activity-section.js'
import { BookingOptionsSkeleton } from '~/components/common/skeleton-data/index.js'

const experience = namespace('experience')

export interface openPayload {
  skuId: number
  packageId: number
  date: string
  pkgDate: string // YYYY-MM-DD
  selectedTime?: any
  selectedUnits?: any[]
  packageName?: string
}

@Component({
  components: {
    SavingPriceDetails,
    PriceDiscountTips,
    ExpTagging,
    ActivityValidity,
    HasOpenDateTips,
    HeaderTips,
    CardHeader,
    BookineOptionsSkeleton,
    ExpLoadingState,
    CommonContentLoading,
    BookingCalendar,
    BookingUnit,
    HeaderAlert,
    SkuBottomBar,
    PackageTimeslot
  }
})
export default class PackageOptionsBooking extends mixins(BookingBase) {
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf
  @Prop({ default: '' }) imageUrl!: string
  @Prop() autoTimeSlot!: boolean
  @Prop({ default: true }) showUsjTimeslots!: boolean
  @Ref() headerAlertRef!: any

  BookingOptionsSkeleton = BookingOptionsSkeleton
  selectedPackageTimeslot: any = null

  @Watch('bsObj.visible')
  visibleWatch(isb: boolean) {
    if (!isb) {
      this.clearPreSettlementData()
    } else {
      this.$nextTick(() => {
        this.$inhouse.track('pageview', '#booking_options_page_spm', { force: true })
      })
    }
    this.$emit('changeVisible', isb)
  }

  @Watch('pkgSelectedDate')
  packageSelectedDateWatch(str: string) {
    this.calcPkgIsNewOpenTicket || this.$emit('updateDate', str) // open ticket不用同步日期过滤条件
  }

  closePriceSumary() {
    eventBus.$emit('setShowPriceSummary2bus', false)
  }

  @Watch('pkgSelectedTime', { immediate: true })
  async selectedTimeChange(val: any, old: any) {
    if (val && val.arrangementId) {
      if (old?.arrangementId && val.arrangementId === old.arrangementId) {
        return
      }
      await this.fetchPackageUnitsDebounce()
    }
  }

  get showPackageTimeslot() {
    return this.showUsjTimeslots && this.calcPkgSelectedObj?.operations_tag_map?.usj_timeslot && this.fromType !== 'addToCart'
  }

  get packageTimeslotOptions() {
    return {
      guid: '',
      packageId: this.packageId,
      startTime: this.pkgSelectedTime?.date,
      skus: (this.currentUnits || []).reduce((accu, curr) => {
        if (curr.count > 0) {
          accu.push({
            sku_id: curr.skuId,
            count: curr.count
          })
        }
        return accu
      }, [] as any[]),
      activityId: this.activityId,
      selectedItem: this.selectedPackageTimeslot || null,
      pageType: 'BookingOptions'
    }
  }

  get bookingCalendarOptions() {
    return {
      templateId: this.templateId,
      autoTimeSlot: this.autoTimeSlot,
      packageId: this.packageId,
      schedules: this.calcPkgScheduleList,
      warn: this.dateDescription,
      selectedTime: this.pkgSelectedTime,
      currentSelectedPackage: this.calcPkgSelectedObj,
      isNewOpenTicket: this.calcPkgIsNewOpenTicket,
      legends: this.calcPkgLegendList
    }
  }

  get bookingUnitOptions() {
    return {
      selectedTime: this.pkgSelectedTime,
      unitList: this.currentUnits,
      preview: this.calcPreview,
      currentSelectedPackage: this.calcPkgSelectedObj,
      inventories: this.calcUnitInventorieObj,
      totalUnitCount: this.calcTotalUnitCount,
      disabled: !this.calcArrangementId
    }
  }

  get bottomBarBinds() {
    const binds = {
      fromType: this.fromType,
      hideDiscountTips: this.bsObjSavePrice?.visible,
      businessData: {
        activity_id: this.activityId,
        template_id: this.templateId,
        is_nsw_activity: this.isNswActivity,
        is_support_shopping_cart: this.isSupportShoppingCart,
        activity_ticket_type: this.activityTicketType,
        packages: this.packageList
      },
      units: this.currentUnits,
      currentSelectedPackage: this.calcPkgSelectedObj,
      selectedTime: this.pkgSelectedTime,
      packageSchedules: this.calcPkgScheduleList,
      packageSelectedDate: this.pkgSelectedDate,
      totalUnitCount: this.calcTotalUnitCount,
      showShoppingCart: this._showShoppingCart
    } as any

    if (this.showPackageTimeslot) {
      binds.extraParams = {
        timeslots: [this.selectedPackageTimeslot]
      }
    }

    return binds
  }

  async open({ packageId, date, skuId, selectedTime, selectedUnits, pkgDate, packageName }: openPayload) {
    this.cacheOpt.date = date || ''
    this.bsObj.visible = true
    this.preSelectedSkuId = skuId
    this.pkgSelectedDate = date || pkgDate || ''
    this.setExpPkgSelectedTime(selectedTime)
    this.oldPrevUnits = selectedUnits || null
    this.cacheOpt.selectedUnits = cloneDeep(selectedUnits)
    if (packageName) {
      this.groupPackName = packageName
    }
    if (packageId) {
      await this.getPackagePriceScheduleUnits({ package_id: packageId })
    }
  }

  handlePackageTimeslotSelect(value: any) {
    this.selectedPackageTimeslot = value
  }

  clearPreSettlementData(_?: any) {
    this.$store.commit(`traveller/activity/${types.SET_PRE_SETTLEMENT_API_DATA}`, { res: null })
  }

  _showShoppingCart() {
    this.showShoppingCart && this.showShoppingCart()
    this.bsObj.visible = false
  }

  rangeConfirmHandler(obj: any) {
    this.pkgSelectedDate = obj.date || ''
    // this.wifiSimParams = obj
  }
}
