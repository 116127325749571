enum IPackageOption {
  PACKAGE_OPTION_CARD_SKU_WITH_DETAIL = 'package_option_card_sku_with_detail', // PC卡片
  PACKAGE_OPTION = 'package_option', //  特殊类型
  WIFI_PACKAGE_OPTION = 'wifi_package_option', //   Wifi/Sim Card
  PACKAGE_OPTION_CARD_SKU = 'package_option_card_sku', // mweb sku
  PACKAGE_OPTION_CARD = 'package_option_card', //  mweb 卡片类型
  PACKAGE_OPTIONS_SPEC_TYPE_LEFT = 'package_options_spec_type_left', // 特殊滑动
  PACKAGE_OPTIONS_GROUP = 'package_options_group' // Group
}

const SHOW_BEST_PACAKGE_PRICE_INFO = [
  IPackageOption.PACKAGE_OPTION,
  IPackageOption.PACKAGE_OPTION_CARD_SKU,
  IPackageOption.PACKAGE_OPTION_CARD_SKU_WITH_DETAIL,
  IPackageOption.PACKAGE_OPTIONS_SPEC_TYPE_LEFT,
  IPackageOption.PACKAGE_OPTIONS_GROUP
]

export {
  IPackageOption,
  SHOW_BEST_PACAKGE_PRICE_INFO
}
