



















import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import ActivityCardsListWrap
  from '~/components/experience-booking/experience-activity/activity-cards-list/activity-cards-list-wrap.vue'
import RwdSwiper from '~/components/experience/rwd-swiper/index.vue'
import BaseActivityCards from '~/components/experience-booking/experience-activity/activity-cards-list/base-cards.vue'

@Component({
  components: {
    ActivityCardsListWrap,
    RwdSwiper,
    BaseActivityCards
  }
})
export default class RelatedActivity extends BusinessBase {
  static displayName = 'Related_activity'

  get logoWidth() {
    const width = this.isDesktop ? 254 : 168
    return width / 4
  }

  get activities() {
    const { related_activities_v2, related_activities } = this.businessData || {}
    return related_activities_v2 || related_activities
  }
}
