interface UnitsListParamsIF {
  totalUnitCount: number // 已选 unit 总数
  maxPurchased: number
  inventories: any // 库存 { [skuId]: number }
}
export const getExpUnitsExtra = (unitList: any[], params: UnitsListParamsIF) => {
  // 修改请注意：所有预订流程units数据都跑此方法
  const { totalUnitCount, maxPurchased, inventories = {} } = params
  if (!Array.isArray(unitList)) {
    return []
  }
  return unitList.map((v: any) => {
    const minCount = v.required ? v.priceMinPax : 0
    const maxCount = Math.min(v.priceMaxPax, maxPurchased - (totalUnitCount - v.count))
    if (v.prevCount !== undefined) {
      const realCount = Math.min(maxCount, Number(inventories[v.skuId]))
      if (v.prevCount > v.count) {
        v.count = v.prevCount > realCount ? 0 : v.prevCount
      } else if (v.prevCount < v.count) {
        v.count = v.prevCount < minCount ? 0 : v.prevCount
      }
      v.prevCount = undefined
    }
    return {
      ...v,
      requireMinPax: !v.required && v.count >= v.priceMinPax && v.priceMinPax > 1,
      minCount,
      // 最大可预定的unit数量由unit的最大可预定数量和当前套餐的库存确定
      maxCount
    }
  })
}
