














import { Component, Vue, Prop } from 'nuxt-property-decorator'
import WishButton from '~/components/experience-booking/experience-activity/wish-button.vue'

  @Component({
    components: {
      WishButton
    }
  })
export default class ActivityWishButton extends Vue {
    @Prop() activityId!: number
    @Prop({ default: '#ff5722' }) favoriteColor!: string
    @Prop({ default: 'rgba(0, 0, 0, 0.54)' }) normalColor!: string
}
