












import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import CardDeparture from '~/components/experience/activity/activity-card/card-departure.vue'

@Component({
  components: {
    CardDeparture
  }
})
export default class Index extends BusinessBase {
  static displayName = 'MobileActivity_departures'
  @State klook!: Data.Klook

  get calcDepartureList() {
    return this.businessData?.departures || []
  }
}
