














































import { Vue, Component, Watch, State, Prop, Getter } from 'nuxt-property-decorator'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import { currencySymbolMap } from '~/share/data/currency'
import { formatThousands } from '~/share/utils'
import { ExperienceActivity } from '~/types/experience/activity-section'

import activityPackage = ExperienceActivity.activityPackage

@Component
export default class PackageOptionsTime extends Vue {
  @State klook!: Data.Klook
  @Prop() packageSelectedDate!: string
  @Prop() packagePriceSchedules!: any
  @Prop() isNewOpenTicket!: boolean
  @Prop() packageId!: number
  @Prop() currentSelectedPackage!: activityPackage
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: '' }) titleText!: string
  @Prop({ default: () => [] }) legends!: any[]
  @Getter timeFormatString!: string

  isSelect: boolean = false
  valid = false
  defaultTime: number = -1
  selectedTime: any = null
  timeList: ExperienceActivity.ITimeSlot[] = []
  formatLegendsList: any = []

  @Watch('packagePriceSchedules')
  packagePriceSchedulesChange() {
    this.timeListChange()
  }

  @Watch('packageId')
  packageIdChange() {
    this.timeListChange()
  }

  @Watch('packageSelectedDate')
  packageSelectedDateChange() {
    this.timeListChange()
  }

  timeListChange() {
    this.timeList = this.getTimeList()
    const val = this.timeList
    this.valid = false
    this.$nextTick(() => {
      if (isEmpty(val)) {
        this.handleSelect()
        return
      }

      if (val.length === 1) {
        const [time] = val
        const formatTime = dayjs(time.date, 'YYYY-MM-DD hh:mm:ss')
        this.handleSelect(time)
        this.defaultTime = this.timeList?.[0]?.arrangement_id
        this.valid = !(formatTime.hour() === 0 && formatTime.minute() === 0 && formatTime.second() === 0)
        return
      }

      // openticket的时候，默认选中第一个非disabled的时间
      if (this.isNewOpenTicket) {
        const validTime = val.find((item: any) => !item.disabled)
        this.handleSelect(validTime)
        this.defaultTime = validTime?.arrangement_id || -1
        this.valid = false
        return
      }

      this.handleSelect()
      this.valid = true
    })
  }

  get spmTime() {
    if (this.selectedTime?.date) {
      return this.setTimeFormat(this.selectedTime.date)
    }
    return '-'
  }

  getTimeList() {
    if (!this.packageId || !this.packagePriceSchedules) {
      return []
    }

    const packagePriceSchedulesObj = this.packagePriceSchedules?.reduce((acc: any, v: any) => ({ ...acc, [v.date]: v }), {})
    const schedule = packagePriceSchedulesObj[this.packageSelectedDate]

    const timeslots = (schedule && schedule.time_slots) || []

    return timeslots.reduce((acc: any, v: any) => {
      const disabled = this.isDisabled(v)
      if (disabled) {
        return acc
      }
      return [...acc, {
        ...v,
        disabled,
        date: `${this.packageSelectedDate} ${v.time_slot}`
      }]
    }, [])
  }

  optionLabel(time: any) {
    const t = this.setTimeFormat(time.date)
    const p = time?.selling_price
    const price = ` ${currencySymbolMap[this.klook.currency]} ${formatThousands(p === undefined ? '' : p)}`
    return time.disabled ? ` ${t} ${this.$t('global.sold_out')}` : `${t}${this.priceVisible ? price : ''}`
  }

  formatOptionLabel(time: any) {
    let res = this.optionLabel(time)

    if (!time.disabled) {
      const legendkeys = time?.legend_keys || []

      if (legendkeys?.length && this.legendsMap) {
        for (const cur of legendkeys) {
          if (this.legendsMap[cur]) {
            res += ` ${this.legendsMap[cur]?.text}`
            !this.formatLegendsList?.length && (this.formatLegendsList.push(this.legendsMap[cur]))
          }
        }
      }
    }

    return res
  }

  get legendsMap() {
    if (this.legends?.length) {
      return this.legends.reduce((p: any, o: any) => {
        const { key, color, text } = o
        p[key] = {
          color,
          key,
          text
        }
        return p
      }, {})
    }

    return null
  }

  setTimeFormat(date: any) {
    return dayjs(date).format(this.timeFormatString)
  }

  handleSelect(time: any = {}) {
    if (!time.arrangement_id) {
      this.isSelect = false
      this.$emit('resetPackageTime')
      return null
    }
    this.setPackageTime(time)
    this.isSelect = !!time.date
  }

  isDisabled(time: { stock: number }) {
    const { stock } = time
    return !stock || stock < this.currentSelectedPackage?.min_pax
  }

  setPackageTime(data: any) {
    this.selectedTime = {
      ...data,
      arrangementId: data.arrangement_id || data.arrangementId,
      stock: data.stock,
      date: data.date,
      /**
       * 最多可购买数量
       * 取决于当前库存和套餐设置的最大可购买数量的最大值
       * 因为在unit的计算中要使用到，所以放到这里先处理出来
       */
      maxPurchased: Math.min(data.stock, this.currentSelectedPackage?.max_pax)
    }
    this.$emit('setPackageTime', this.selectedTime)
  }
}
