//
//
//
//
//
//

import { create } from './time.ts'

export default {
  name: 'klkPlatformCountDown',
  props: {
    endTime:{ type: Number, required: true },
    type:{ type: Number, default: 1 },
    timeClass: { type: String, default: '' },
    endCallBack: { type: Function, default: () => {} }
  },
  data(){
    return {
      countDownTime : {
        d: '00',
        h: '00',
        m: '00',
        s: '00'
      }
    }
  },
  methods:{
    callback(countDownTime) {
      this.countDownTime = countDownTime
    },
    createTask(endTime){
      return create(endTime, {
        type: this.type,
        callback: this.callback,
        endCallback: this.endCallBack,
        initInterval: !this.$isServer
      })
    }
  },
  created() {
    let removeTask
    if (this.endTime) {
      removeTask = this.createTask(this.endTime)
    }
    this.$watch('endTime',(newVal)=>{
      if(newVal){
        removeTask && removeTask();
        removeTask = this.createTask(this.endTime)
      }
    })
    this.$once('hook:beforeDestroy', () => {
      removeTask && removeTask()
    })
  }
}
