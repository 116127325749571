var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"base-cards-list",class:[
    _vm.klook.platform,
    { 'perview-1': _vm.currentPerView === 1 },
    { 'perview-2': _vm.currentPerView === 2 },
    { 'perview-3': _vm.currentPerView === 3 },
    { 'perview-4': _vm.currentPerView === 4 }
  ]},_vm._l((_vm.data),function(item,index){return _c('TnaActivityCard',_vm._b({directives:[{name:"track",rawName:"v-track:module:Activity_LIST",value:({
      oid: ("activity_" + (item.activity_id)),
      idx: index,
      len: _vm.data.length,
      inject: 'section',
      ext: _vm.getSpmExt(item),
    }),expression:"{\n      oid: `activity_${item.activity_id}`,\n      idx: index,\n      len: data.length,\n      inject: 'section',\n      ext: getSpmExt(item),\n    }",arg:"module:Activity_LIST"}],key:((item.activity_id) + "-" + index),staticClass:"base-cards-list-item",attrs:{"card-data":item,"logo-width":_vm.logoWidth}},'TnaActivityCard',_vm.getBindObj(item),false))}),1)}
var staticRenderFns = []

export { render, staticRenderFns }