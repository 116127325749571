import { Vue, Component } from 'nuxt-property-decorator'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
// import dataUtils from '~/components/booking-core/utils/data-utils'
// import * as dataTypesUtils from '~/components/booking-core/utils/types'
@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }
}
