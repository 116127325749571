






import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import PassActivityItemContent from './pass-activity-item-content.vue'

@Component({
  components: {
    PassActivityItemContent
  }
})
export default class Index extends Vue {
  @State(state => state.klook.platform) platform!: string
  @Prop() item!: any
  @Prop() length!: number // 数据长度

  get direction() {
    return this.length === 1 && this.platform === 'desktop' ? 'horizontal' : 'vertical'
  }
}
