








import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { IconCalendar, IconCautionCircle } from '@klook/klook-icons'

@Component({
  components: {
    IconCalendar,
    IconCautionCircle
  }
})
export default class IconTime extends Vue {
  @Prop({ default: '' }) text!: string
  @Prop({ default: true }) isTime!: boolean
  @State((state) => state.klook.platform) platform!: Data.Platform
}
