











import { Component } from 'nuxt-property-decorator'
import Header from './header.vue'
import SectionBase from '~/components/setion/experience/base/section-base'

@Component({
  components: { Header }
})
export default class SectionHeader extends SectionBase {
  get title() {
    // 兼容处理ttd和标准楼层数据不同层级问题
    let publicTitle: string = ''
    if (this.businessData?.title) {
      publicTitle = this.businessData?.title
    } else {
      publicTitle = this.sectionData?.data?.title
    }
    const title = ([] as any).concat(publicTitle)
    return this.$t.apply(this, title)
  }

  get inhouseModule() {
    const { name = '', type = '' } = this.sectionData?.meta || {}

    return `${name}:${type}:::RightCornerButton`
  }
}
