





import { Vue, Component, Prop, State } from 'nuxt-property-decorator'

@Component
export default class Markdown extends Vue {
  @State klook!: Data.Klook
  @Prop() content!: string | []
  get cssClass() {
    if (this.klook.utilConfig.whiteLabelUtilConfig) {
      return 'white-label-links'
    }
    return ''
  }
}
