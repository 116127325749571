



































import { Component, Vue, Prop, Inject } from 'nuxt-property-decorator'
import { IconChevronDown, IconNext } from '@klook/klook-icons'

@Component({
  components: {
    IconChevronDown,
    IconNext
  }
})
export default class CollapseItem extends Vue {
  @Prop() title!: string
  @Prop() name!: string
  @Prop({ default: 'toggle' }) actionType!: 'toggle' | 'openSheet'
  @Inject({ default: () => {} }) handleOpenSheetItem!: Function

  isActive = false

  mounted() {
    this.$parent && (this.$parent as any).addItem(this.name, this)
  }

  beforeDestroy() {
    this.$parent && (this.$parent as any).removeItem(this.name)
  }

  handleToggle() {
    if (this.actionType === 'openSheet') {
      this.handleOpenSheetItem && this.handleOpenSheetItem(this.name)
    } else {
      this.$parent && (this.$parent as any).toggleItem(this.name)
    }
  }

  toggle(val: boolean) {
    this.isActive = val
  }
}
