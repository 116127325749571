

























































import { Vue, Component, State, Watch, Prop } from 'nuxt-property-decorator'
import { scroll2, nodeScrollTop, scrollToPackageOption } from '~/share/scroll2/scroll2'
import RwdSwiper from '~/components/experience/rwd-swiper/index.vue'
import ReviewRatingV2 from '~/components/experience-booking/experience-activity/recent-review/review-rating-v2.vue'
import RecentReviewCardV2 from '~/components/experience-booking/experience-activity/recent-review/recent-review-card-v2.vue'
import ImageGallery from '~/components/experience-booking/experience-activity/banner/components/image-gallery/index.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    RecentReviewCardV2,
    ImageGallery,
    ReviewRatingV2,
    RwdSwiper
  }
})
export default class ActivityRecentReview extends Vue {
  @Prop() track!: any
  @Prop() review_count!: number
  @Prop() score!: number
  @Prop() reviews_v2!: any[]
  @State klook!: Data.Klook

  currentComment: any = null

  showPreview: boolean = false
  previewIndex: number = -1

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  handleShowPreview(index: number, item: any) {
    this.showPreview = true
    this.previewIndex = index
    this.currentComment = item
  }

  @Watch('reviewList', { immediate: true })
  businessReviewsChange(val: any) {
    if (!val) {
      this.$emit('hide-section')
    }
  }

  get reviewCount() {
    return this.review_count || 0
  }

  get calcScore() {
    return this.score || 5
  }

  get reviewList() {
    return this.reviews_v2 || []
  }

  get shouldHideImage() {
    return this.reviewList.every(
      (review: any) => !(review.review_image?.length || review.review_image_list?.length)
    )
  }

  handleBuyNow() {
    scrollToPackageOption({ lockLocation: 110 })
  }

  skipToReview() {
    const top = nodeScrollTop(document.querySelector('#reviews'))
    if (top) {
      scroll2({ scrollY: top, lockLocation: 105 })
    }
  }
}

export const getPropsData2section = (businessData: any, mergeData?: any) => {
  const { track, review_count, score, reviews_v2 } = businessData || {}
  const obj = {
    track,
    review_count,
    score,
    reviews_v2,
    ...(mergeData || {})
  }
  return obj
}
