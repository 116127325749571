
































































































import { Component, Vue, Watch, State, Prop } from 'nuxt-property-decorator'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'
import MobileCouponList from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-card-coupon.vue'
import { Activity } from '~/types/traveller/activity'
import ExpTagging from '~/components/experience/tagging/index.vue'

@Component({
  components: {
    TotalSlot,
    ExpTagging,
    MobileCouponList
  }
})
export default class ActivityPromotionInfoDesktop extends Vue {
  @State klook!: Data.Klook

  @Prop({ default: 'desktop' }) type!: string
  @Prop({ default: 'success' }) status!: Activity.fetchStatus
  @Prop({ default: [] }) promotionList!: Activity.activityPromotion[]

  @Prop() packageId!: number
  @Prop() activityId!: number
  @Prop() templateId!: number
  @Prop() validPackages!: any
  @Prop() includedPackages!: any
  @Prop() packagesInDate!: any

  selectedEvent: number | string = 1

  getPriceShow(promotion: any) {
    const obj: any = promotion?.price_show || {}
    if (obj.from_price) {
      // 特殊处理 slotScope.value
      obj.sale_price = obj.from_price
      obj.from_price = ''
    }
    return obj
  }

  getCalcTags2discount(promotion: any) { // All 多销售属性-查看含折扣的套餐弹窗
    return promotion?.product_tags?.discount_tags || []
  }

  @Watch('packageId', { immediate: true })
  packageIdChange() {
    this.selectedEvent = this.packageId
  }

  get availablePackageList() {
    return this.packagesInDate.map((pkg: any) => pkg.package_id || pkg.packageId)
  }

  get validPackageList() {
    return this.validPackages.map((pkg: any) => pkg.package_id || pkg.packageId)
  }

  get includedPackageList() {
    if (!this.includedPackages) {
      return this.validPackageList
    }
    return this.includedPackages.map((pkg: any) => pkg.package_id || pkg.packageId)
  }

  get promotionListWithAvailable() {
    // 先筛选出有库存的，然后选出包含的，最后把不在当前日期的设置为不可选
    const list = this.promotionList
      .filter((promotion: any) => this.validPackageList.includes(promotion.package_id))
      .filter((promotion: any) => this.includedPackageList.includes(promotion.package_id))
      .map((promotion: any) => ({
        ...promotion,
        isAvailable: this.availablePackageList.includes(promotion.package_id)
      }))

    // discount大的放上面；is not available放下面
    list.sort((a: any, b: any) => {
      if (a.isAvailable === b.isAvailable) {
        return b.discount - a.discount
      }

      return b.isAvailable ? 1 : -1
    })

    return list
  }

  handleRadioChange(val: number | string) {
    this.selectedEvent = val
  }

  handleEventClick(promotion: any) {
    if (promotion.isAvailable) {
      this.selectedEvent = promotion.package_id

      if (this.type === 'mobile') {
        this.$emit('close')
        this.confirmSelect()
      }
    }
  }

  handleRefreshClick() {
    this.$emit('refresh')
  }

  confirmSelect() {
    if (this.selectedEvent && this.selectedEvent !== this.packageId) {
      // this.selectPackage({ packageId: this.selectedEvent })
      this.$emit('selectPackage', this.selectedEvent)

      this.$inhouse.track('custom', `#js-package-type-${this.selectedEvent}`, {
        PromoTag: true,
        spm: 'Activity.SelectPackage.PackageType',
        action_type: 'click'
      })
      this.$sendGTMCustomEvent(`Activity Page|SelectPackageWithPromotion_Click|${this.activityId}|${this.templateId}`)
    }
  }

  mounted() {
    this.selectedEvent = this.packageId
  }
}
