import Vue, {
  AsyncComponent,
  Component,
  ComponentOptions,
  FunctionalComponentOptions
} from 'vue'

export type VueComponent = Component | AsyncComponent | FunctionalComponentOptions | ComponentOptions<Vue>

export namespace SectionTypes {
  export interface WrapperItemAttrs {
    className?: Record<string, boolean>
    id?: string
  }

  export type ComponentItemObj = {
    viewComponent: VueComponent,
    skeletonComponent?: VueComponent,
    wrapperClass?: string | string[] | {
      [className: string]: boolean
    }
  }

  export type ComponentItem = VueComponent | ComponentItemObj

  // 根据对应的 handler + componentName 决定唯一组件
  export type ComponentConf = {
    [handler: string]: {
      [componentName: string]: SectionTypes.ComponentItem
    }
  }

  export interface ResolvedComponentItem extends ComponentItemObj {
    wrapperClass: {
      [className: string]: boolean
    }
  }

  export type ResolvedComponentConf = Record<string, ResolvedComponentItem>

  export type ContainerConf = {
    [handler: string]: {
      component: any,
      hasChild: boolean
    }
  }

  export type ContainerCommonProps = {
    title?: string
    sub_title?: string

    more_title?: string
    more_deep_link?: string
    more_action_type?: 'load_more' | 'deep_link' | 'fake_pagination'
    more_load_link?: string
    more_load_handler?: () => Promise<void>
    fake_pagination_handle?: () => void
    more_spm_module?: string

    corner_button_title?: string
    corner_button_deep_link?: string
    corner_target?: string

    corner_button_spm?: Record<string, string>
    more_spm?: Record<string, string>
  }

  export type ContainerDividerProps = {
    height: number
  }

  export type ContainerTabProps = {
    height?: number
  }
}

export enum HeadStyle {
  default = 1, // 默认
  klookUi = 2 // klook ui section head
}

export type HeadTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export type CreateOptions = {
  componentConf: SectionTypes.ComponentConf
  platform?: 'desktop' | 'mobile'
  headStyle?: HeadStyle
  headTag?: HeadTag
}

export type SectionParams = Omit<CreateOptions, 'componentConf'>
