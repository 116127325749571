import { Vue, Component } from 'nuxt-property-decorator'

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  tagFilterList: string[] = []

  getTagFilterSpecTitle(packageList: any[]) {
    const num = this.getIncludedTagPackages(packageList)?.length || 0
    return this.tagFilterList?.length ? this.$t('98470', { num }) : this.$t('98480', { num })
  }

  getIncludedTagPackages(packageList: any[]) {
    return this.computeIncludedTagPackages(this.tagFilterList, packageList)
  }

  getTagPkgNum(selectedTags: any[], packageList: any[]) {
    return this.computeIncludedTagPackages(selectedTags, packageList).length
  }

  getPackageTagSetList(packageList: any[]) {
    return (packageList || []).map((pkg: any) => ({
      pkg,
      tagSet: new Set(pkg?.product_tags?.filter_tag_list || [])
    }))
  }

  computeIncludedTagPackages(selectedTags: any[], packageList: any[]) {
    if (!selectedTags?.length) {
      return packageList || []
    }

    const selectedTagList = selectedTags.map(tag => tag?.split?.('::')?.[1]).filter(Boolean)
    const arr = this.getPackageTagSetList(packageList)
    return arr
      .filter(({ tagSet }) => selectedTagList.every(key => tagSet.has(key)))
      .map((item: any) => item.pkg)
  }
}
