
















































import { Component, Prop, mixins, State } from 'nuxt-property-decorator'
import ExperiencePackageDetailModule from './package-detail-module.vue'
import PackageCardPromoBelt from './promo-belt.vue'
import MobilePackageCardPriceAndButton from './price-and-button.vue'
import MobilePackageCardMainContent from './main-content.vue'
import { currencyExchange } from '~/share/utils'
import { ExperienceActivity } from '~/types/experience/activity-section'
import newPackageDetailShowMixin from '~/components/common/package-detail-v2/show-mixin'

@Component({
  components: {
    PackageCardPromoBelt,
    MobilePackageCardMainContent,
    MobilePackageCardPriceAndButton,
    ExperiencePackageDetailModule
  }
})
export default class ExperiencePackageCard extends mixins(newPackageDetailShowMixin) {
  @State(state => state.klook.currency) currency!: Data.Platform
  @Prop({ default: null }) packageData!: ExperienceActivity.IPackageCard
  @Prop() buttonContent!: string
  @Prop() ext!: any
  @Prop() date?: string

  detailShow: boolean = false
  get newModuleExtra() {
    let { date } = this
    if (!date) {
      return
    }
    date = date?.split(' ')[0]
    const ext = JSON.stringify({
      date
    })
    return ext
  }

  get trackInfo() {
    const { package_id } = this.packageData

    const priceRef = this.$refs.price as Vue
    const packagePriceRef = (priceRef?.$refs?.packagePrice as Vue)?.$refs?.price
    const sellingPrice = (packagePriceRef as any)?.selling || '0'

    const price = sellingPrice.replace(/[^0-9]/gi, '')
    const salesPrice = currencyExchange(price, this.currency) ?? sellingPrice

    const { discount_desc, credits } = this.packageData

    const promotionType: any = {
      4: 'WarmUp',
      5: 'Sales'
    }

    return {
      oid: `package_${package_id}`,
      ext: {
        PromoCard: `${this.event?.tag || 'null'}, ${encodeURIComponent(
          discount_desc
        ) || 'null'}`,
        Integral: Math.floor(parseFloat(credits.toString())),
        SalesPrice: salesPrice,
        PromotionType:
          promotionType[
            this.packageData?.promotion_price?.promotion_event?.status
          ] || 'Normal',
        SelectType: 'User',
        ...this.ext
      }
    }
  }

  get event() {
    return this.packageData?.promotion_price?.promotion_event
  }

  get chosenStyle() {
    if (this.packageData.status === 'selected') {
      return 'selected'
    }
    return ''
  }

  showPackageDetail() {
    this.detailShow = true
  }

  closeModal() {
    this.detailShow = false
  }

  toBook() {
    if (this.packageData.status === 'unavailableDate') {
      this.notAvailableDate()
    } else {
      const time = setTimeout(() => {
        this.$emit('toBook', this.packageData)
        clearTimeout(time)
      }, 200)
    }
  }

  notAvailableDate() {
    this.$confirm(this.$t('19452'), {
      okLabel: this.$t('global.confirm'),
      cancelLabel: this.$t('global.tips.cancelTxt')
    }).then(({ result: isb }) => {
      if (isb) {
        const time = setTimeout(() => {
          this.closeModal()
          this.$emit('resetDate', this.packageData)
          this.$emit('toBook', this.packageData)
          clearTimeout(time)
        }, 200)
      }
    })
  }
}
