




















































































import { Component, Prop, State } from 'nuxt-property-decorator'
import Card from './card.vue'
import IconTime from './iconTime.vue'
import BookingCalendar from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/booking-calendar/index.vue'
import {
  Standaloneschedule,
  Istate,
  Schedule
} from '~/components/experience-booking/experience-activity/combo-option/booking-options/type'
import ActMarkdown from '~/components/experience-booking/experience-activity/markdown/index.vue'
import { renderMarkdownObj } from '~/assets/scripts/markdown'
import TimeBase from '~/components/experience-booking/experience-activity/combo-option/booking-options/combo-time-base'
import DatePoptip from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/calendarList/datePoptip.vue'
@Component({
  components: {
    Card,
    BookingCalendar,
    DatePoptip,
    IconTime,
    ActMarkdown
  }
})
export default class CalendarList extends TimeBase {
  @State klook!: Data.Klook
  @Prop({ default: 'date' }) type!: 'unit' | 'date' | 'hint'
  @Prop({ default: '' }) combonPackageId!: number
  @Prop({ default: [] }) standaloneSchedules!: Standaloneschedule[]
  @Prop({ default: {} }) packageIdMap!: any

  @Prop() calcPkgSelectedObj!: any

  get allSku() {
    return this.standaloneSchedules.reduce((sum: string[], item) => sum.concat(item.combo_sku_names), [])
  }

  destroyedBody(item: Standaloneschedule, index: number) {
    if (item.package_ticket_type === 3) {
      return item.disable
    }

    return !item.expanded && !this.getDate(index)
  }

  renderMarkDownHtml(content: any) {
    if (!content) {
      return ''
    }
    return renderMarkdownObj(content)
  }

  getCurrentPackage(index: number) {
    return this.standaloneSchedules[index] || {}
  }

  getCurrentState(index: number) {
    return this.standaloneSchedules[index] || {}
  }

  getDate(index: number) {
    const { packageSelectedDate, packageSelectedTime, schedules } = this.standaloneSchedules[index]
    if (!packageSelectedDate) {
      return
    }
    const days = schedules.find((item) => item.date === packageSelectedDate) as Schedule
    if (this.isDay(days)) {
      return this.formatDay(packageSelectedDate)
    } else {
      if (packageSelectedTime) {
        return this.formatDay(packageSelectedDate) + '  ' + this.setTimeFormat(packageSelectedTime as string)
      }
      return this.formatDay(packageSelectedDate)
    }
  }

  getBookingCalendarOptions(index: number) {
    // const { selectedTime } = this.stateList[index]
    const currentSelectedPackage = this.getCurrentPackage(index)
    const { schedules, package_id, activity_id, selectedTime, selecedTimeHasClear } = currentSelectedPackage
    return {
      templateId: activity_id,
      packageId: package_id,
      schedules,
      selectedTime,
      currentSelectedPackage,
      selecedTimeHasClear,
      index
    }
  }

  isRenderCard(id: number) {
    return this.packageIdMap[id]
  }

  setCardState(index: number, key: keyof Istate, value: never) {
    this.standaloneSchedules[index + 1][key] = value
  }

  toggle(index: number, value: any) {
    this.standaloneSchedules[index].expanded = !value
  }

  setPackageTime(val: any) {
    if (val) {
      this.$emit('setTimeOrDate', val)
    }
  }

  setPackageDay(val: string, index: number) {
    if (val) {
      this.$emit('setTimeOrDate', val, index)
    }
  }

  getSkuName(list: string[]) {
    const name = list.join(',')
    return this.$t('106553', { name })
  }

  alert(text: string, translate = true) {
    const message = translate ? this.$t(text) : text
    if (this.klook.platform === 'desktop') {
      const ele = document.getElementById('combo-booking-option-desktop') as HTMLElement
      // @ts-ignore
      this.$toast(message, ele)
    } else {
      this.$toast({ message })
    }
  }
}
