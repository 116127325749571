


























































































































import { Component, State, Getter, Vue, Prop } from 'nuxt-property-decorator'
import { IconDownCircle, IconUpCircle } from '@klook/klook-icons'

@Component({
  components: {
    IconDownCircle,
    IconUpCircle
  }
})
export default class AttractionCard extends Vue {
  @State klook!: Data.Klook
  @Getter platform!: string
  @Getter isMobile!: boolean
  @Prop({ default: () => ({}) }) data!: any
  @Prop({ default: false }) isOpen!: boolean

  visible: boolean = false

  get tagList() {
    return this.data?.tag?.product_tags?.attribute_tags || []
  }

  get packageList() {
    return this.data?.package_list || []
  }

  get openOptionText() {
    return this.isOpen ? this.$t('88587') : this.$t('88586')
  }

  get openOptionIcon() {
    return this.isOpen ? 'icon_navigation_chevron_up_xs' : 'icon_navigation_chevron_down_xs'
  }

  handleShowPacakgePrice(price: string) {
    const str = this.$t('88601') || ''
    return str.replace('{price}', `<span class="price_text">${price}</span>`)
  }

  handleShowProductNote() {
    this.visible = true
  }
}
