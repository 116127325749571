interface Task {
    id: number
    endTime: number
    callback: TimeCallback
    type: number
    endCallback?: EndCallback
}

interface CountTime {
  d: string|number
  h: string|number
  m: string|number
  s: string|number
}

export type TimeCallback = (countTime: CountTime) => void

export type EndCallback = () => void

export enum Type {
 DAY = 1,
 HOUR = 2,
 SECOND = 3
}

export interface Option {
  type: Type
  callback: TimeCallback
  endCallback?: EndCallback
  initInterval: Boolean
}

let Timer: any

class Time {
  private timeInterval: any = null
  private taskQueue: Task[] = []
  private id: any = 1

  addTask(endTime: number, options: Option) {
    const task: Task = {
      id: this.id,
      endTime,
      callback: options.callback,
      endCallback: options.endCallback,
      type: options.type || Type.DAY
    }
    const removeFunc: Function = () => {
      this.removeTask(task.id)
    }
    const nowTime = Math.floor(new Date().getTime() / 1000)
    const leftTime = task.endTime - nowTime
    const countDowntime = this.calcCountDowntime(leftTime, task.type)
    task.callback && task.callback(countDowntime)
    if (options.initInterval) {
      this.taskQueue.push(task)
      this.id++
      this.init()
    }

    return removeFunc
  }

  removeTask(id: number) {
    const index = this.taskQueue.findIndex(function (item) {
      return id === item.id
    })
    if (~index){
      this.taskQueue.splice(index, 1)
      // 如果队列中没有任何任务了那么就清楚定时器
      if (this.taskQueue.length === 0) {
        this.clearTimeInterval()
      }
    }
  }

  calcCountDowntime(leftTime: number, type: number) {
    if( leftTime < 0 ) {
      return {
        d: "00",
        h: "00",
        m: "00",
        s: "00"
      }
    }
    const d: string | number = Math.floor(leftTime / 60 / 60 / 24)
    let h: string | number = Math.floor(leftTime / 60 / 60 % 24)
    let m: string | number = Math.floor(leftTime / 60 % 60)
    let s: string | number = Math.floor(leftTime % 60)
    // 如果是HOUR类型的就需要累加day
    if (type === Type.HOUR) {
      h = (d && d > 0) ? (d * 24 + h) : h
    }
    if(type === Type.SECOND) {
      s = (m && m > 0) ? (m * 60 + s) : s
    }
    h = h < 10 ? `0${h}` : h
    m = m < 10 ? `0${m}` : m
    s = s < 10 ? `0${s}` : s

    return {
      d, h, m, s
    }
  }

  init() {
    if (this.timeInterval) { return }
    this.timeInterval = setInterval(() => {
      const nowTime = Math.floor(new Date().getTime() / 1000)
      const cache: any = {}
      this.taskQueue.forEach((task) => {
        const leftTime = task.endTime - nowTime
        if (leftTime && leftTime > 0) {
          const type = task.type
          const cacheKey = leftTime + '-' + type
          if (!cache[cacheKey]) {
            const { d, h, m, s } = this.calcCountDowntime(leftTime, type)
            cache[cacheKey] = { d, h, m, s }
          }
          task.callback && task.callback(cache[cacheKey])
        } else {
          task.endCallback && task.endCallback()
          task.callback && task.callback({ d: '0', h: '00', m: '00', s: '00' })
          this.removeTask(task.id)
        }
      })
    }, 1000)
  }

  clearTimeInterval() {
    this.timeInterval && clearInterval(this.timeInterval)
    this.timeInterval = null
  }
}

export function create(endTime: number, options: Option) {
  if (!Timer) {
    Timer = new Time()
  }

  return Timer.addTask(endTime, options)
}
