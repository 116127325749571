










































import { Component, Prop } from 'nuxt-property-decorator'
import Cardimage from './fnb-card.vue'
import PackageOptionsFnbBase from '~/components/experience-booking/experience-activity/package-options/fnb-package-options/index-section-fnb'
import PackageCard from '~/components/experience-booking/experience-activity/package-options/mobile/group-package-options/components/group-package-card.vue'

@Component({
  components: {
    PackageCard,
    Cardimage
  }
})
export default class PackageCardListWrap extends PackageOptionsFnbBase {
  @Prop() toBook!: () => void

  limtd = 3
}
