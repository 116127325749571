import { Component, State, mixins, Vue, Watch } from 'nuxt-property-decorator'
import { CancelTokenSource } from 'axios'
import { cloneDeep, findLastIndex } from 'lodash'
import {
  Standaloneschedule,
  openPayload,
  IUnit,
  CombonBookingType,
  Itrial,
  Unitsinfo,
  errorType,
  Schedule
} from './type'
// @ts-ignore
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'
import apiNameMap from '~/share/data/apis'
import { renderMarkdownObj } from '~/assets/scripts/markdown'
import PriceCheckMixin from '~/components/booking-core/mixins/common/price-check'

let cancelToken: CancelTokenSource | null = null

@Component
export default class ComboPackageOptionsBooking extends mixins(Vue, PriceCheckMixin) {
  @State klook!: Data.Klook
  packageId = 0
  preview = 0
  translation = false
  bsObj = getBottomSheetConfig('fixed-height-config')
  bsObjSavePrice = getBottomSheetConfig({ transfer: false })

  loading = 'fetching'
  trialLoading = 'success'

  // 用skuid为key，挂载count
  skuMap: Record<string, any> = {}
  // 用packageidweikey，挂载日历信息
  packageIdMap: Record<string, any> = {}
  // 测算价格保存
  priceDetail: Itrial | Record<string, any> = {}
  totalUnitCount = 0
  // sku 选择器
  currentUnits: IUnit[] = []
  // combo 套餐级信息
  calcPkgSelectedObj: CombonBookingType | Record<string, any> = {}
  // 日历信息
  standaloneSchedules: Standaloneschedule[] = []

  @Watch('bsObj.visible')
  visiableChange(val: boolean) {
    if (!val) {
      this.resetData()
    }
  }

  // 如果为true，每次count + -都要调用试算接口
  get realTrial() {
    return this.calcPkgSelectedObj.real_trial
  }

  get calcTags2discount() {
    const calcTags2attribute = this.calcPkgSelectedObj?.product_tags?.attribute_tags || []
    const tags = this.calcPkgSelectedObj?.product_tags?.discount_tags || []
    const list: any = []
    if (tags?.length) {
      tags.forEach((tag: any) => {
        const inner_vars = tag?.inner_vars || {}
        if (!inner_vars?.inner?.derivation_info || !inner_vars?.inner?.derivation_info?.is_derived) {
          // 非倒推/直接打到的
          list.push(tag)
        }
      })
    }

    return list.concat(calcTags2attribute)
  }

  get bottomBarBinds() {
    return {
      hideDiscountTips: this.bsObjSavePrice?.visible,
      businessData: {
        activity_id: this.calcPkgSelectedObj.activity_id,
        is_nsw_activity: false,
        is_support_shopping_cart: false,
        activity_ticket_type: this.calcPkgSelectedObj.package_ticket_type
      },
      units: this.currentUnits,
      totalUnitCount: this.totalUnitCount,
      showShoppingCart: false
    }
  }

  // 某一个日历有所有日期时间都是禁止的
  get hasUnitWarning() {
    return this.standaloneSchedules.some((item) => item.allDayDisable)
  }

  get bookingUnitOptions() {
    return {
      preview: this.preview,
      currentSelectedPackage: this.calcPkgSelectedObj,
      totalUnitCount: this.totalUnitCount
    }
  }

  initSkuMap(): void {
    this.packageIdMap = this.currentUnits.reduce((map: any, unit) => {
      if (unit.required && unit.price_min_pax > 0) {
        unit.standalone_info.forEach((standalone) => {
          map[standalone.package_id] = standalone
        })
        return map
      }
    }, {})
  }

  // 过滤掉和当前unit，没有用到的日历，并保存
  keepListWithPackage(packageIdMap?: any): Standaloneschedule[] {
    const list = this.calcPkgSelectedObj.standalone_schedules
      .filter(({ package_id }: Standaloneschedule) =>
        packageIdMap !== undefined ? packageIdMap[package_id] : this.packageIdMap[package_id]
      )
      .map((item: Standaloneschedule) => {
        const foundItem = this.standaloneSchedules.find((data) => data.package_id === item.package_id)
        if (foundItem) {
          return foundItem
        } else {
          return {
            ...item,
            disable: false,
            expanded: true,
            selectedTime: {},
            packageSelectedDate: '',
            packageSelectedTime: '',
            error: false
          }
        }
      })
    return list
  }

  filtersListWithPackage(packageIdMap?: any) {
    let list = this.calcPkgSelectedObj.standalone_schedules.map((item: Standaloneschedule) => ({
      ...item,
      disable: true,
      expanded: false,
      selectedTime: {},
      packageSelectedDate: '',
      error: false
    }))
    if (this.totalUnitCount > 0) {
      list = list.filter(({ package_id }: Standaloneschedule) =>
        packageIdMap ? packageIdMap[package_id] : this.packageIdMap[package_id]
      )
    }
    this.standaloneSchedules = list
  }

  //  totalUnitCount 有改变的时候，计算total和积分逻辑逻辑
  priceChange() {
    if (this.priceDetail.units_info && this.priceDetail.units_info.length > 0) {
      const map: Record<string, any> = {}
      for (let i = 0; i < this.priceDetail.units_info.length; i++) {
        const { sku_id, selling_price, credit } = this.priceDetail.units_info[i]
        if (!map[sku_id]) {
          map[sku_id] = {}
        }
        map[sku_id].selling_price = Number(selling_price)
        map[sku_id].credit = Number(credit)
      }

      let total = 0
      let totalCredit = 0

      for (let i = 0; i < this.currentUnits.length; i++) {
        const { sku_id, count } = this.currentUnits[i]
        if (map[sku_id]) {
          map[sku_id].count = count || 0
          total = total + (map[sku_id].selling_price || 0) * (count || 0)
          totalCredit = totalCredit + (map[sku_id].credit || 0) * (count || 0)
        }
      }

      this.priceDetail.total_credit = totalCredit

      // 前端 + —存在精度丢失的问题，小数点大于3的时候进行处理
      const decimal = total.toString().split('.')[1]
      if (decimal && decimal.length >= 3) {
        this.priceDetail.total_price = total.toFixed(2)
      } else {
        this.priceDetail.total_price = total
      }

      this.priceDetail.units_info = this.priceDetail.units_info.map((item: Unitsinfo) => ({
        ...item,
        count: map[item.sku_id].count
      }))
      this.skuMap = map
    }
  }

  renderMarkDownHtml(content: any) {
    if (!content) {
      return ''
    }
    return renderMarkdownObj(content)
  }

  getSpm() {
    const ext = { Type: 'Combo' }
    return `BookingOptions?oid=package_${this.calcPkgSelectedObj.package_id}&ext=${this.$getExt(
      ext
    )}&trg=manual`
  }

  allHasTime() {
    let result = true
    this.standaloneSchedules.forEach((item) => {
      const { packageSelectedDate, packageSelectedTime, package_ticket_type } = item
      if (!packageSelectedDate) {
        result = false
      }
      const needSelect = package_ticket_type === 0 || package_ticket_type === 1
      item.schedules.forEach((day) => {
        if (packageSelectedDate === day.date && !this.isDay(day) && !packageSelectedTime && needSelect) {
          result = false
        }
        if (!packageSelectedDate && this.isDay(day) && needSelect) {
          result = false
        }
      })
    })

    return result
  }

  hasCompleted() {
    if (this.allHasTime() && this.totalUnitCount > 0) {
      this.getPackagesScheduleTrial()
    }
  }

  openAllCard() {
    this.standaloneSchedules = this.standaloneSchedules.map((item) => ({
      ...item,
      selectedTime: {},
      packageSelectedDate: '',
      packageSelectedTime: '',
      selecedTimeHasClear: false,
      close: true,
      error: false,
      disable: false,
      expanded: true
    }))
  }

  closeAllCard() {
    this.setNoPiece()
    this.standaloneSchedules = this.standaloneSchedules.map((item) => ({
      ...item,
      selectedTime: {},
      packageSelectedDate: '',
      packageSelectedTime: '',
      selecedTimeHasClear: false,
      close: true,
      error: false,
      disable: true,
      expanded: false,
      changeTimeByUser: false
    }))
  }

  resetData() {
    this.currentUnits = []
    this.standaloneSchedules = []
    this.calcPkgSelectedObj = {}
    this.totalUnitCount = 0
    this.skuMap = {}
    this.packageIdMap = {}
    this.priceDetail = {}
  }

  initData(payload: any) {
    const { units, ...rest } = payload
    this.calcPkgSelectedObj = rest || {}
    this.currentUnits = units || []
    //  1. unit是必须的，有最小购买数 2. 只有一个unit，设置数量为1
    const changeUnitCount =
      this.currentUnits.some((item) => item.required && item.price_min_pax > 0) ||
      this.currentUnits.length === 1

    if (changeUnitCount) {
      if (this.currentUnits.length === 1) {
        this.changeUnitCount({ type: 'add', index: 0 })
      } else {
        this.currentUnits.forEach((item: IUnit, index: number) => {
          if (item.required && item.price_min_pax > 0) {
            this.changeUnitCount({ type: 'add', index })
          }
        })
      }
      // 初始化的时候执行检测是否需要执行测算
      this.$nextTick(() => {
        this.hasCompleted()
      })
    } else {
      this.initSkuMap()
      this.$nextTick(() => {
        this.filtersListWithPackage()
      })
    }

    // 初始化后进行埋点上报
    this.$nextTick(() => {
      this.$inhouse.track('pageview', '#combo_options_page_spm', { force: true })
    })
  }

  alert(text: string, translate = true) {
    const message = translate ? this.$t(text) : text
    if (this.klook.platform === 'desktop') {
      const ele = document.getElementById('combo-booking-option-desktop') as HTMLElement
      // @ts-ignore
      this.$toast(message, ele)
    } else {
      this.$toast({ message })
    }
  }

  openPaymentDetail() {
    this.bsObjSavePrice.visible = !this.bsObjSavePrice.visible
  }

  async open({ packageId, preview, translation }: openPayload) {
    this.bsObj.visible = true
    this.packageId = packageId
    this.preview = preview
    this.translation = translation

    if (packageId) {
      await this.getPackagesScheduleUnits()
    }
  }

  // 提交测算的时候即使是日期也需要 + timesolt
  dateWithTimeSolt(date: string, index: number) {
    const day = this.standaloneSchedules[index].schedules.find((item) => item.date === date)
    const time = day?.time_slots[0].time_slot
    return date + ' ' + time
  }

  generateParams(isbooking?: boolean) {
    const payload: any = {
      translation: this.translation,
      sku_list: this.currentUnits
        .filter((item) => item.count > 0)
        .map((item) => ({
          sku_id: item.sku_id,
          count: item.count
        })),
      package_id: this.packageId,
      combo_s: this.standaloneSchedules.map((item, index) => ({
        package_id: item.package_id,
        start_time: item.packageSelectedTime
          ? item.packageSelectedTime
          : this.dateWithTimeSolt(item.packageSelectedDate, index)
      }))
    }
    // 订购接口需要最早出行日期
    if (isbooking) {
      payload.start_time = this.priceDetail.participant_time
      return {
        booking_package_list: [payload]
      }
    }
    return payload
  }

  // 试算接口，存在重复请求，因为日历组件有bug，设置日期的时候也会触发time change，造成重复请求，用cancelToken取消掉
  async getPackagesScheduleTrial() {
    this.trialLoading = 'fetching'

    if (cancelToken) {
      cancelToken.cancel('cancel')
    }
    cancelToken = this.$axios.CancelToken.source()
    try {
      const resp = await this.$axios.$post(apiNameMap.bookingOptionTrial, this.generateParams(), {
        cancelToken: cancelToken?.token
      })
      if (resp.success) {
        this.priceDetail = resp.result
      }
    } catch (error) {}

    this.trialLoading = 'success'
  }

  // combo 套餐请求
  async getPackagesScheduleUnits() {
    this.loading = 'fetching'
    try {
      const resp = await this.$axios.$get(apiNameMap.bookingOptionInfo, {
        params: {
          package_id: this.packageId,
          preview: this.preview,
          translation: this.translation,
          sales_channel: 1
        }
      })
      if (resp.success) {
        this.initData(resp?.result || {})
        this.loading = 'success'
        return
      }
    } catch (e) {}

    this.loading = 'failure'
  }

  // 生成map，判断库存的时候使用
  getSkuRole() {
    const skuMapObj: Record<string, any> = {}
    const packageIdMap: Record<string, any> = {}
    const { currentUnits } = this
    for (const unit of currentUnits) {
      const { count = 0, standalone_info = [] } = unit
      for (const item of standalone_info) {
        const { sku_id, package_id } = item
        if (count > 0) {
          packageIdMap[package_id] = item
          const step = Number(item.count) || 0
          const cur = count * step + (skuMapObj?.[sku_id]?.count || 0)
          skuMapObj[sku_id] = {
            count: cur,
            packageId: package_id
          }
        }
      }
    }

    this.skuMap = skuMapObj
    this.packageIdMap = packageIdMap
    return { skuMap: skuMapObj, packageIdMap }
  }

  //  都是open day的时候，unit + - ，都需要进行价格测算 ，属于特殊情况
  allOpenDay() {
    const allOpenDay =
      this.totalUnitCount > 0 &&
      this.standaloneSchedules.every(
        (item) => item.package_ticket_type === 3 || (item.package_ticket_type === 0 && !item.changeTimeByUser)
      )
    if (allOpenDay) {
      this.getPackagesScheduleTrial()
    }
  }

  changeUnitCount({ type, index }: any) {
    // 1. 先进行totalcount总数+-
    this.totalUnitCountChange(type, index)

    // 2.本次unit + - 包含的套餐关系还有需要展示的日历
    const { skuMap, packageIdMap } = this.getSkuRole()

    // 3. 如果经过测算有真实价格，计算total和积分逻辑逻辑，更新总价和积分
    this.priceChange()

    this.$nextTick(() => {
      // 4.card 展开或者关闭
      if (this.totalUnitCount === 0) {
        this.closeAllCard()
        this.$nextTick(() => {
          this.filtersListWithPackage()
        })
        return
      } else {
        const needOpen = this.totalUnitCount > 0 && this.standaloneSchedules.every((item) => item.disable)
        if (needOpen) {
          this.openAllCard()
        }
      }
      // 5. 库存判断
      this.caleCalendarState(skuMap, packageIdMap, type)
      //  6, 全是openday 特殊规则
      this.hasCompleted()
    })
  }

  totalUnitCountChange(type: string, index: number) {
    let step = 1

    const { count = 0, price_min_pax } = this.currentUnits[index]
    if (type === 'add') {
      // unit 操作的时候要考虑到最小购买数量
      if (typeof price_min_pax === 'number' && count < price_min_pax) {
        // step = price_min_pax > 2 ? 2 : price_min_pax
        step = price_min_pax
      }
      this.currentUnits[index].count = (count || 0) + step
      this.totalUnitCount = this.totalUnitCount + step
    } else {
      if (typeof price_min_pax === 'number' && count <= price_min_pax) {
        this.currentUnits[index].count = 0
      } else {
        this.currentUnits[index].count = (count || 0) - step
      }
      this.totalUnitCount = this.totalUnitCount - step
    }
  }

  // 是否有库存
  hasSufficientStock(stockMap: Record<string, number>, skuMap: Record<string, any>): Record<string, boolean> {
    let disable = false
    let soldout = false
    for (const skuId in skuMap) {
      if (skuId in stockMap) {
        const stock = stockMap[skuId] || 0
        const selectedQuantity = skuMap[skuId].count || 0
        // 如果有任何一个 SKU 的库存大于选择数量，或者库存不为零，都将 disable 设为 false
        if (stock < selectedQuantity) {
          disable = true
          break
        }
      } else {
        disable = true
        break
      }
    }
    for (const skuId in skuMap) {
      if (skuId in stockMap) {
        const stock = stockMap[skuId] || 0

        if (stock === 0) {
          soldout = true
          break
        }
      }
    }

    return { disable, soldout }
  }

  // 回到no pirce
  setNoPiece() {
    this.priceDetail = {}
  }

  scrollIntoView(index: number) {
    const id = `combo-card-${index}`
    const ele = document.getElementById(id)
    if (ele) {
      ele.scrollIntoView({
        block: 'start',
        inline: 'start',
        behavior: 'smooth'
      })
    }
  }

  srollToUnit() {
    const ele = document.querySelector('.booking-content-units')
    if (ele) {
      ele.scrollIntoView({
        block: 'start',
        inline: 'start',
        behavior: 'smooth'
      })
    }
  }

  // combo 提交时候，需要检验的内容
  // https://klook.larksuite.com/docx/Lf05da2dwoz38LxVwmiu2Fsassd 校验规则文档
  validator() {
    const { max_pax } = this.calcPkgSelectedObj
    let result = true
    // 1.存在日历不可用：该日期下无可售sku 请重新修改unit数
    if (this.hasUnitWarning) {
      this.alert('106606')
      this.srollToUnit()
      result = false
      return result
    }

    // 2.总数为0的时候
    if (this.totalUnitCount === 0) {
      this.alert('activity.booking.options.select.quantity')
      result = false
      this.srollToUnit()
      return result
    }

    // 3.总count大于套餐最大购物数
    if (this.totalUnitCount > max_pax) {
      this.alert(this.$t('activity.v2.error.package_max', [max_pax], false))
      result = false
      this.srollToUnit()
      return result
    }

    // 4. 必买未选人数 点击 book now
    if (this.currentUnits.some((item) => item.count === 0 && item.required)) {
      this.alert('activity.error.no_package_selected')
      result = false
      this.srollToUnit()
      return result
    }

    // 5 没有到最小购买数量
    const minPax = this.calcPkgSelectedObj.min_pax
    if (this.totalUnitCount < Number(minPax)) {
      this.alert(this.$t('activity.error.price_min', [minPax]), false)
      result = false
      this.srollToUnit()
      return result
    }

    const hasEorr: errorType[] = []

    // 6. 没有选日期，到位从上到下最早一个未填写的展开区域
    this.standaloneSchedules.forEach((item, index) => {
      const { packageSelectedDate, packageSelectedTime, package_ticket_type } = item
      if (!item.packageSelectedDate) {
        hasEorr.push({ index, type: 'day' })
        return
      }
      item.schedules.forEach((day) => {
        // 7.选择了日期但是没有选择时间
        const needSelect = package_ticket_type === 0 || package_ticket_type === 1
        if (packageSelectedDate === day.date && !packageSelectedTime && needSelect && !this.isDay(day)) {
          hasEorr.push({ index, type: 'time' })
        }
      })
    })

    if (hasEorr.length > 0) {
      //  位从上到下最早一个未填写的展开区域
      const first = hasEorr[0]
      if (first.type === 'time') {
        this.alert('activity.booking.options.select.time')
      } else {
        this.alert('activity.booking.options.select.date')
      }
      result = false

      this.scrollIntoView(first.index)
      //  统一进行状态处理
      this.standaloneSchedules = this.standaloneSchedules.map((item, index) => {
        const isError = hasEorr.findIndex((item) => item.index === index) > -1
        return {
          ...item,
          error: isError,
          expanded: isError || item.expanded
        }
      })
      return result
    }

    // 8. 满足以下两个条件之一，校验不通过，必须没有达到必买数，或者非必选unit
    this.currentUnits.forEach((unit) => {
      if (
        (unit.required && unit.count < unit.price_min_pax) ||
        (!unit.required && unit.count > 0 && unit.count < unit.price_min_pax)
      ) {
        this.alert(this.$t('2115', [unit.price_min_pax]), false)
        result = false
        this.srollToUnit()
      }
    })

    return result
  }

  closePriceSumary() {
    this.bsObjSavePrice.visible = false
  }

  allDisabeld(standaloneSchedules: Standaloneschedule[]) {
    this.setNoPiece()
    this.alert('106606')
    return standaloneSchedules.map((item) => ({
      ...item,
      selectedTime: {},
      packageSelectedDate: '',
      packageSelectedTime: '',
      allDayDisable: true,
      error: false,
      selecedTimeHasClear: false
    }))
  }

  caleCalendarState(skuMap: Record<number, any>, packageIdMap: Record<any, any>, type: string) {
    // 如果某个日历完全不可用，且是操作是++，都是无库存了，不进行库存比较
    if (this.hasUnitWarning && type === 'add') {
      this.alert('106606')
      return
    }
    const newList = this.keepListWithPackage(packageIdMap)
    let standaloneSchedules = cloneDeep(newList)
    const needLoad = newList.length !== this.standaloneSchedules.length || this.realTrial
    for (const package_id in packageIdMap) {
      const curSkuMap: any = {}

      // 过滤掉不属于当前日历的unit sku
      for (const key in skuMap) {
        const val = skuMap[key]
        if (Number(package_id) === val.packageId) {
          curSkuMap[key] = skuMap[key]
        }
      }

      // 找到需要判断库存的日历
      for (let i = 0; i < standaloneSchedules.length; i++) {
        const calculate = standaloneSchedules[i]
        if (Number(package_id) === calculate.package_id) {
          const allDayDisable = this.calculateScheduleStatus(calculate, curSkuMap)
          calculate.allDayDisable = allDayDisable
        }
      }
    }

    // 如果某个日历disable，所有都不可用
    if (standaloneSchedules.some((item) => item.allDayDisable)) {
      standaloneSchedules = this.allDisabeld(standaloneSchedules)
    }

    this.standaloneSchedules = standaloneSchedules
    // 数组有变更的时候或者后端有下发realtrial需要进行测算请求
    if (needLoad) {
      this.$nextTick(() => {
        this.hasCompleted()
      })
    }
  }

  // 库存判断函数
  calculateScheduleStatus(schedule: Standaloneschedule, skuMap: any) {
    // 假设所有天数都不可用
    let allDayDisable = true
    const { packageSelectedDate, packageSelectedTime, package_ticket_type } = schedule
    for (let i = 0; i < schedule.schedules.length; i++) {
      const day = schedule.schedules[i]
      // 假设当前日期时间段都不可用
      let allTimeDisable = true
      const { disable, soldout } = this.hasSufficientStock(day.include_skus, skuMap)

      day.disable = disable
      day.soldout = soldout

      if (!disable) {
        // 只要有一天可以用，设置为false
        allDayDisable = false
        const timeSlots = day?.time_slots || []

        //  如果当天时间段大于1才进行time循环
        if (!this.isDay(day)) {
          for (let j = 0; j < timeSlots.length; j++) {
            const timeSlot = timeSlots[j]
            const timeSlotSotck = this.hasSufficientStock(timeSlot.stocks, skuMap)
            timeSlot.disable = timeSlotSotck.disable
            timeSlot.soldout = timeSlotSotck.soldout
            if (!timeSlotSotck.disable) {
              allTimeDisable = false
            }
            // 只有在有选择时间才进行提示，还有时间取消
            if (
              packageSelectedTime === day.date + ' ' + timeSlot.time_slot &&
              timeSlotSotck.disable &&
              package_ticket_type === 1
            ) {
              this.whenSelectDateDisable(schedule, false)
            }
          }
        } else {
          allTimeDisable = false
        }
      }
      // 当有选择选择日期才进行,时间循环完如果当天时间所有都不用则整天都不可用
      const isb = packageSelectedDate === day.date && (disable || allTimeDisable) && package_ticket_type === 1
      if (isb) {
        this.whenSelectDateDisable(schedule, true)
      }
    }

    // open day也进行时间赋值操作
    this.openDaySelect(schedule)
    return allDayDisable
  }

  // 当有选择时间，然后没有库存的操作
  whenSelectDateDisable(schedule: Standaloneschedule, isDay: boolean) {
    this.setNoPiece()
    //  日期时候的处理场景
    if (isDay) {
      // day 层级库存不足 = 所有下属 timeslot 全部库存不足
      schedule.packageSelectedDate = ''
      schedule.packageSelectedTime = ''
      schedule.selecedTimeHasClear = true
      schedule.selectedTime = {}
      schedule.error = true
      this.alert('106542')
    } else {
      schedule.packageSelectedTime = ''
      schedule.selectedTime = {}
      schedule.error = true
      schedule.selecedTimeHasClear = true
      this.alert('106543')
    }
  }

  // 处理openday默认时间时间
  openDaySelect(schedule: Standaloneschedule) {
    const { package_ticket_type, auto_select_first_date, changeTimeByUser } = schedule
    if (changeTimeByUser) {
      return
    }
    //  open day 默认勾选第一天
    if (package_ticket_type === 0 || package_ticket_type === 3) {
      const earliestAvailableDayIndex = schedule.schedules.findIndex((item: Schedule) => !item.disable)
      // @ts-lint 总是报错用了findLastIndex
      const latestAvailableDayIndex = findLastIndex(schedule.schedules, (item: Schedule) => !item.disable)
      const days =
        schedule.schedules[auto_select_first_date ? earliestAvailableDayIndex : latestAvailableDayIndex]
      if (days) {
        if (this.isDay(days)) {
          schedule.packageSelectedDate = days.date
          schedule.selectedTime = {}
          schedule.packageSelectedTime = ''
        } else {
          const time = days.time_slots[0]
          schedule.packageSelectedDate = days.date
          schedule.selectedTime = { ...time, date: days.date + ' ' + time.time_slot }
          schedule.packageSelectedTime = days.date + ' ' + time.time_slot
        }
      }
    }
  }

  // 判断是by day还是by time
  isDay(days: Schedule) {
    if (days) {
      if (days.time_slots.length > 1) {
        return false
      } else {
        return days.time_slots[0].time_slot === '00:00:00'
      }
    }
  }

  // 日期或者时间设置
  setTimeOrDate(val: any, index: number) {
    // 如果是字符串表示是日期
    if (typeof val === 'string') {
      const days = (this.standaloneSchedules[index]?.schedules || []).find((item) => item.date === val)
      if (days) {
        this.standaloneSchedules[index].error = false
        this.standaloneSchedules[index].selecedTimeHasClear = false
        this.standaloneSchedules[index].selectedTime = {}
        this.standaloneSchedules[index].packageSelectedTime = ''
        this.standaloneSchedules[index].changeTimeByUser = true
        if (this.isDay(days)) {
          this.hasCompleted()
        }
      }
      return
    }
    // 如果是一个对象，有date属性表明是一个时间，
    if (val && val.date) {
      const i = (this.standaloneSchedules || []).findIndex((item) => item.package_id === val.package_id)
      if (val.date === this.standaloneSchedules[i]?.packageSelectedTime) {
        return
      }
      const days = (this.standaloneSchedules[i]?.schedules || []).find(
        (item) => item.date === val.date.split(' ')[0]
      )
      if (days && !this.isDay(days)) {
        this.standaloneSchedules[i].selectedTime = val
        this.standaloneSchedules[i].packageSelectedTime = val.date
        this.standaloneSchedules[i].error = false
        this.standaloneSchedules[i].selecedTimeHasClear = false
        this.standaloneSchedules[i].changeTimeByUser = true
        this.hasCompleted()
      }
    }
  }
}
