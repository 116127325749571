













































































import { Component, State, Ref } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'
import PromotionBannerBase from '~/components/experience-booking/experience-activity/banner/promotion-banner-base'

@Component
export default class MobilePromotionBanner extends PromotionBannerBase {
  @State klook!: Data.Klook
  @Ref() bannerPrice!: HTMLElement

  // @Watch('promotionDetail', { immediate: true })
  // promotionDetailChange() {
  //   // 当文字太长的时候自动缩小字体，且保持一行
  //   this.$nextTick(() => {
  //     const { scrollWidth = 0, offsetWidth = 0 } = this.bannerPrice || {}
  //
  //     if (scrollWidth && offsetWidth && scrollWidth > offsetWidth) {
  //       const fontSize = Math.floor(18 * offsetWidth / scrollWidth)
  //       this.bannerPrice.style.fontSize = `${fontSize}px`
  //     }
  //   })
  // }

  async download() {
    const res = await this.$axios.$get(
      apis.downloadApk,
      { baseURL: process.env.APP_ENV === 'production' ? '/' : process.env.API_URL_BROWSER }
    )

    if (res.success && res.result) {
      window.location.href = res.result.downland_url
    }
  }
}
