











































import { Component, Vue, Prop, Inject } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import AttrsUnselectTips from './attrs-unselect-tips.vue'
import { queryString } from '~/share/utils'
import beforeAddToCart
  from '~/components/experience-booking/experience-activity/package-options/desktop/zhenxing-coupon-tip'
import { scrollToPackageOption, checkScrollStop } from '~/share/scroll2/scroll2'

type role = 'book' | 'addToCart' | 'warming' | 'suspended'

@Component({
  components: {
    AttrsUnselectTips
  }
})
export default class ActivityBottomBtn extends Vue {
  @Prop() activityId!: number
  @Prop() date!: string
  @Prop() packageId!: number
  @Prop() isNswActivity!: boolean
  @Prop() role!: role | role[]
  @Prop() buttonText!: string
  @Prop({ default: false }) isYiFunVoucher!: boolean
  @Prop() clickCustomFn!: Function
  @Prop({ default: null }) isSupportShoppingCart!: boolean
  @Inject({ default: (_: any) => _ }) getScrollElBySectionConfig2provide!: Function

  visible = false

  content: string = ''

  get linkQuery() {
    return {
      act_date: this.date,
      packageId: String(this.packageId)
    }
  }

  get roles() {
    return ([] as string[]).concat(this.role)
  }

  get trackInfo() {
    return {
      trg: 'manual',
      oid: `package_${this.packageId}`,
      ext: {
        date: this.date || undefined,
        CompletedInfo: !!this.packageId
      }
    }
  }

  get isRoleWarming() {
    return this.roles.includes('warming')
  }

  get isRoleSuspended() {
    return this.roles.includes('suspended')
  }

  isBtnVisibleAddtocart(role: role) {
    const isb = typeof this.isSupportShoppingCart === 'boolean' ? this.isSupportShoppingCart : !this.isNswActivity // 有传isSupportShoppingCart则优先使用
    return (isEmpty(this.role) || this.roles.includes(role)) && ![47631, 46820, 48639, 48789, 55826, 49807, 55724, 68784].includes(this.activityId) && isb
  }

  isBtnVisible(role: role) {
    return isEmpty(this.role) || this.roles.includes(role)
  }

  beforeRoute() {
    if (!this.packageId) {
      scrollToPackageOption({
        cb: async (el: Element) => {
          if (el && await checkScrollStop(el)) {
            this.visible = true
          }
        }
      })

      return false
    }

    return true
  }

  routeTo(type: string) {
    if (this.clickCustomFn) {
      const typeObj: any = {
        book: 'bookNow',
        addToCard: 'addToCart'
      }
      this.clickCustomFn(typeObj[type])
      return
    }
    const bookingOptionsUrl = this.$href(`/experiences/tobook/new/${this.activityId}/`) // 问号前拼斜杠减少一次重定向
    window.location.href = bookingOptionsUrl + '?from_flow_key=from_flow_attr&' + queryString({ type, ...this.linkQuery })
  }

  async handleAddToCart() {
    if (!this.beforeRoute()) {
      return
    }
    if (this.isYiFunVoucher) {
      if (await beforeAddToCart(this)) {
        this.routeTo('book')
        return null
      }
    }

    this.routeTo('addToCard')
  }

  handleBookNow() {
    if (!this.beforeRoute()) {
      return
    }

    this.routeTo('book')
  }

  handleCustomButtonClick() {
    if (this.isRoleWarming) {
      scrollToPackageOption(this.getScrollElBySectionConfig2provide)
    }
  }
}
