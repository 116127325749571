

















































































import { IconEnlarge } from '@klook/klook-icons'
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import Logo from '~/components/experience/card-swiper/logo.vue'

@Component({
  components: {
    Logo,
    IconEnlarge
  }
})
export default class ImageGallery extends Vue {
  @Prop() imageList!: any[]
  @Prop() total!: number
  @Prop({ default: 0 }) currentIndex!: number
  // 加上name属性，避免存在多个swiper的时候状态混乱
  // 通过name属性生成不同的类名
  @Prop({ default: '' }) name!: string
  @Prop({ type: Boolean, default: false }) needEnlarge!: boolean // 是否需要放大镜功能

  isScreened: boolean = false
  showEnlargeEntry: boolean = false

  @Watch('currentIndex', { immediate: true })
  currentIndexChange(val: number) {
    this.$nextTick(() => {
      this.$bigImageSwiper && this.$bigImageSwiper.slideTo(val)
    })
  }

  getImgSize(index: number) {
    if (!this.needEnlarge) { return }

    const container = document.querySelector('.js-swiper-container .swiper-wrapper')
    if (container) {
      const { clientWidth: boxWidth, clientHeight: boxHeight } = container
      const source = this.imageList[index]

      if (source) {
        const { width: imageWidth, height: imageHeight } = source

        if (imageWidth && imageHeight) {
          const boxRatio = boxWidth / boxHeight
          const imageRatio = imageWidth / imageHeight
          this.showEnlargeEntry = imageRatio <= boxRatio
        }
      }
    }
  }

  updateSwiper() {
    this.$smallImageSwiper.update()
    this.$bigImageSwiper.update()
  }

  handleScreen() {
    this.isScreened = !this.isScreened
  }

  handleSlideStart() {
    this.showEnlargeEntry = false
    this.isScreened = false
  }

  $bigImageSwiper: any = null
  $smallImageSwiper: any = null
  highlightSwiperSlideIndex: number = 0
  currentReview: any = null

  bigImageSwiperOption: any = {
    navigation: {
      nextEl: '.swiper-next',
      prevEl: '.swiper-prev'
    },
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true
    },
    on: {
      init: () => {
        // ${name}-swiper-next
        if (this.currentIndex === 0) {
          const el = `.${this.name}-swiper-prev`
          this.initSwiper(el)
        }

        const slidesPerGroup = this.bigImageSwiperOption.slidesPerGroup || 1
        if (this.imageList.length <= slidesPerGroup) {
          this.initSwiper(`.${this.name}-swiper-next`)
        }
      },
      slideChangeTransitionStart: this.handleSlideStart,
      slideChange: () => {
        const name = this.name
        this.BigSwiperSlideChange()
        this.slideChangeTransitionEnd('$bigImageSwiper', {
          next: `.${name}-swiper-next`,
          prev: `.${name}-swiper-prev`
        })
      }
    }
  }

  smallImageSwiperOption: any = {
    slidesPerView: 'auto',
    centeredSlides: false,
    navigation: {
      nextEl: '.small-swiper-next',
      prevEl: '.small-swiper-prev'
    },
    lazy: {
      loadPrevNext: true,
      loadOnTransitionStart: true
    },
    spaceBetween: 10,
    slidesPerGroup: 8,
    noSwiping: true,
    on: {
      init: () => {
        // ${name}-small-swiper-next
        const el = `.${this.name}-small-swiper-prev`
        this.initSwiper(el)
        const slidesPerGroup = this.smallImageSwiperOption.slidesPerGroup || 1
        const maxLength = this.name === 'banner-swiper' ? 11 : slidesPerGroup
        if (this.imageList.length <= maxLength) {
          this.initSwiper(`.${this.name}-small-swiper-next`)
        }
      },
      slideChange: () => {
        const name = this.name
        this.slideChangeTransitionEnd('$smallImageSwiper', {
          next: `.${name}-small-swiper-next`,
          prev: `.${name}-small-swiper-prev`
        })
      }
    }
  }

  highlightSwiperSlide(idx: number) {
    this.$bigImageSwiper.slideTo(idx)
    this.highlightSwiperSlideIndex = idx
  }

  BigSwiperSlideChange() {
    const idx = this.$bigImageSwiper.realIndex
    this.highlightSwiperSlideIndex = idx
    this.$smallImageSwiper.slideTo(idx)
    this.$emit('bigSwiperChange', idx)
    this.$nextTick(() => {
      this.getImgSize(idx)
    })
  }

  slideChangeTransitionEnd(swiper: string, elem: any) {
    const selfSwiper = (this as any)[swiper]
    const $next = document.querySelector(elem.next) as any
    const $prev = document.querySelector(elem.prev) as any
    if (selfSwiper.isEnd) {
      $next.style.display = 'none'
    } else {
      $next.style.display = 'block'
    }
    if (selfSwiper.isBeginning) {
      $prev.style.display = 'none'
    } else {
      $prev.style.display = 'block'
    }
    const realIndex = selfSwiper.realIndex

    if (this.imageList.length < this.total && this.imageList.length - realIndex <= 15) {
      this.$emit('slideToEnd')
    }
  }

  initSwiper(swiper: string) {
    this.$nextTick(() => {
      const $el = document.querySelector(swiper) as any
      $el?.style && ($el.style.display = 'none')
    })
  }
}

