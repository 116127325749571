






import { Component, Vue, Prop, State } from 'nuxt-property-decorator'

@Component
export default class CardSwiperItem extends Vue {
  @Prop({ type: Number, default: 1 }) perView!: number // 完全展示在视窗内的个数
  @Prop({ type: Number, default: 12 }) gap!: number
  // 父容器可能不是占满整个页面，给一个修正值
  @Prop({ type: Number, default: 32 }) offset!: number
  @State(state => state.klook.platform) platform!: Data.Platform

  get wrapStyle() {
    if (this.perView === 0) {
      return {
        width: '100%'
      }
    }
    const { length } = this.$attrs

    const dataLength = Number(length)
    let width = ''
    let gapCount = this.perView
    if (dataLength < 3) {
      gapCount -= 1;
      // 默认perview = 1 是大卡，
      // 排除数据长度为1的情况，小卡不存在preView = 1的情况
      (dataLength === 2 && this.perView === 1) && (gapCount = 1)
    }

    // 伪类占据了20px的宽度
    width = `calc((100% - ${gapCount * this.gap + 20}px) / ${this.perView})`
    return {
      width,
      marginRight: `${this.gap}px`
    }
  }
}
