













import { Vue, Component, Prop } from 'nuxt-property-decorator'
import DiscountsItem from './item.vue'

@Component({
  components: {
    DiscountsItem
  }
})
export default class Index extends Vue {
  @Prop({
    type: String,
    default: '200px'
  })
  tagMaxWidth!: string

  @Prop({
    type: Array,
    default() {
      return []
    }
  })
  discountList!: any[]

  @Prop({
    type: Number,
    default: 5
  })
  lineClamp!: number
}
