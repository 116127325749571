










import { Component, Prop } from 'nuxt-property-decorator'
import PublishInfoBase from '~/components/common/publish-info/publish-info-base'

@Component({})
export default class PackageOptionsWarning extends PublishInfoBase {
  @Prop({ default: '' }) forceText!: string
}
