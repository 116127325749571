













































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IconChevronDown, IconChevronUp } from '@klook/klook-icons'

@Component({
  components: {
    IconChevronDown,
    IconChevronUp
  }
})
export default class Card extends Vue {
  @Prop({ default: 0 }) index!: number
  @Prop({ default: false }) disable!: boolean
  @Prop({ default: false }) error!: boolean
  @Prop({ default: '' }) activeName!: string
  @Prop({ default: false }) mark!: boolean
  @Prop({ default: '' }) title!: string
  @Prop({ default: false }) expanded!: boolean
  @Prop({ default: false }) destroyedBody!: boolean
  @Prop() platform!: string

  @Prop({ default: [] }) comboSkuNames!: string[]

  toggle(isbody?: boolean) {
    if (this.mark) {
      return
    }

    if (this.disable) {
      this.$emit('alert', 'activity.booking.options.select.quantity')
      return
    }

    if (isbody && !this.expanded) {
      this.$emit('toggle', this.index, this.expanded)
      return
    }

    this.$emit('toggle', this.index, this.expanded)
  }

  preventClick(e: Event) {
    e.stopPropagation()
    e.preventDefault()
    // 阻止点击事件传播
  }
}
