


















































import { Component, State, Ref, Prop, Watch, mixins } from 'nuxt-property-decorator'
import AttrBase from '~/components/experience-booking/experience-activity/package-options/base/attr-base'
import { CardSwiper } from '~/components/traveller/home-page/card-swiper'
import { nodeScrollTop, scrollToCenter, scroll2 } from '~/share/scroll2/scroll2'
import SinglePop from '~/components/experience-booking/experience-activity/package-options/package-attributes/mobile/single-pop.vue'
import ActivityPackageBtn from '~/components/traveller/activity/activity-package-options/mobile/activity-package-btn-itinerary.vue'
import SingleBase from '~/components/experience-booking/experience-activity/package-options/package-attributes/mobile/single-base'

@Component({
  components: {
    CardSwiper,
    SinglePop,
    ActivityPackageBtn
  }
})
export default class SingleFixedAttributes extends mixins(AttrBase, SingleBase) {
  @State klook!: Data.Klook
  @Prop() scrollEl?: string
  @Prop() visible!: boolean
  @Prop() isDelayedInstantScroll!: boolean
  @Ref() cardSwiperRef!: any

  popVisible = false
  singlePopCloseFunc: Function | null = null

  get filteredAttrList() {
    const { specList } = this

    if (!specList?.[0]?.attr?.length) {
      return []
    }

    return specList[0].attr.filter(attr => attr.isScheduleMatch && attr.isPackageMatch)
  }

  handleSinglePopClose() {}

  @Watch('visible', { immediate: true })
  visibleChange(val: boolean) {
    if (process.client && val) {
      this.$nextTick(() => this.showActiveItem('auto'))
    }
  }

  computeLocation(attr: any) {
    const oldIndex = this.filteredAttrList.findIndex(item => item?.isActive)
    const newIndex = this.filteredAttrList.findIndex(item => item === attr)
    this.$emit('directionChange', oldIndex > newIndex ? 'right' : 'left')
  }

  handleAttrPopSelect(attr: any, index: number, len: number) {
    this.popVisible = false
    setTimeout(() => this.handleAttrSelect(attr, { index, len, type: 'PackageOptionsBring' }), 50)
  }

  handleAttrSelect(attr: any, spmObj: Record<string, string | number> | null = null) {
    this.computeLocation(attr)
    this.handleSelect(attr, this.specList[0], spmObj)
    this.$nextTick(this.showActiveItem)

    if (this.isDelayedInstantScroll) {
      // hero page 专属滚动，配合动画效果的
      // 有左右滚动效果之后，在左右滚动的时候滑动到顶部，这里 300 的延时和左右滚动动画的延时有关
      setTimeout(() => {
        const top = nodeScrollTop(document.querySelector('.js-goto-pkg-detail')) - 60
        window.scrollTo(0, top)
      }, 300)
    } else {
      const { scrollEl } = this
      const config = scrollEl ? {
        lockLocation: Number((document.documentElement.clientHeight || document.body.clientHeight) * 0.1) + 56,
        scrollEl
      } : undefined
      const top = nodeScrollTop(document.querySelector('.js-goto-pkg-detail'), config) - 60
      scroll2({ scrollY: top, lockLocation: config?.lockLocation }, config)
    }
  }

  showActiveItem(behavior: ScrollBehavior = 'smooth') {
    if (this.$el?.querySelector) {
      const childDom = this.$el.querySelector('.single-fixed-attributes-item.is-active') as HTMLElement
      const scrollElm = this.$el.querySelector('.single-fixed-attributes .card-swiper') as HTMLElement
      scrollToCenter(childDom, scrollElm, behavior)
    }
  }
}
