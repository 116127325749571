















import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop() styleType!: string

  get tips() {
    const obj: any = this.$attrs.tips || {}
    return obj
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get calcTrack() {
    const def = {
      type: 'module',
      spm: 'Buy_More'
    }
    return def
  }
}
