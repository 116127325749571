






















import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import { setImageFormat } from '~/share/data/image'

@Component
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: '' }) src!: string
  @Prop({ default: '' }) crop!: string
  @Prop({ default: '1:1' }) whRate!: '1:1' | '4:3' | '16:9'
  @Prop({ default: 0 }) realHeight!: number
  @Prop({ default: 1.5 }) cropScale!: number // 放大比率
  @Prop({ default: false }) showInitImage!: boolean // 是否展示默认加载图
  @Prop({ default: false }) showLoadImage!: boolean // 是否展示加载图
  @Prop({ default: 12 }) loadRate!: number // 加载图缩放比率
  @Prop({ default: '/e_blur:100' }) loadEblur!: string // 加载图裁剪参数
  @Prop({ default: false }) showTransition!: boolean // 设置过渡效果
  @Prop({ default: false }) firstImage!: boolean

  isInitImageLoaded = false
  isLoadImageLoaded = false
  loadImageTransition = ''
  isTargeImageLoaded = true
  targetImageTransition = 'load-image-transition'

  whRateMaps = {
    '1:1': {
      hwRate: 1,
      styles: {
        paddingBottom: '100%'
      }
    },
    '4:3': {
      hwRate: 0.75,
      styles: {
        paddingBottom: '75%'
      }
    },
    '16:9': {
      hwRate: 0.5625,
      styles: {
        paddingBottom: '56.25%'
      }
    }
  }

  loopSetTimeout(loopCount: number, callback: Function) {
    const num = loopCount || 0
    const cb = typeof callback === 'function' ? callback : () => {}
    if (num > 0) {
      setTimeout(() => {
        this.loopSetTimeout(num - 1, cb)
      }, 9)
    } else {
      this.$nextTick(() => {
        cb()
      })
    }
  }

  loadTargetImageHandler() {
    this.isTargeImageLoaded = true
    this.loopSetTimeout(2, () => {
      this.targetImageTransition = 'load-image-transition'
    })
  }

  loadImageHandler() {
    this.isLoadImageLoaded = true
    this.loopSetTimeout(1, () => {
      this.loadImageTransition = 'load-image-transition'
      this.$nextTick(() => {
        this.isInitImageLoaded = false
      })
    })
  }

  get calcShowTransition() {
    return this.showTransition && !this.firstImage
  }

  get calcRateObj() {
    const { whRateMaps, whRate } = this
    let obj: any = whRateMaps[whRate]
    if (!obj && whRate?.split(':')?.length === 2) {
      const arr: number[] = whRate.split(':').map((str) => parseFloat(str))
      const hwRate = parseFloat((arr[1] / arr[0]).toFixed(5))
      const paddingBottom = hwRate * 100 + '%'
      obj = {
        hwRate,
        styles: {
          paddingBottom
        }
      }
    }
    return obj
  }

  get calcShowInitImg() {
    return this.showInitImage && this.isInitImageLoaded
  }

  get calcStyles() {
    const obj = this.calcRateObj?.styles || {}
    const bgObj = this.calcShowInitImg ? { background: '#eee url(https://res.klook.com/image/upload/image_logo_mx7wgd.png) no-repeat center center', backgroundSize: '54px' } : {}
    return { ...obj, ...bgObj }
  }

  get calcLoadStyles() {
    const styles = this.isLoadImageLoaded ? (this.calcRateObj?.styles || {}) : {}
    if (this.showLoadImage) {
      const { calcLoadCrop, src, klook } = this
      const imgSrc = this.getImgSrc(src, { crop: calcLoadCrop, isWebp: klook.webp })
      const loadStyles = { background: `url(${imgSrc}) no-repeat center center`, backgroundSize: '100%' }
      return { ...styles, ...loadStyles }
    }
    return {}
  }

  get calcLoadCrop() {
    if (this.$route.query?.load_crop) {
      return this.$route.query?.load_crop // 支持 query 参数
    }
    const { klook, calcRateObj, realHeight, cropScale } = this
    if (!realHeight) {
      const obj = {
        mobile: '/c_fill,w_414',
        desktop: '/c_fill,w_750'
      }
      return obj[klook.platform]
    }
    const ch = Math.round(realHeight * cropScale / this.calcLoadRate)
    const cw = Math.round(ch / calcRateObj.hwRate)
    const autoCrop = `/c_fill,w_${cw},h_${ch}${this.calcLoadEblur}`
    return autoCrop
  }

  get calcLoadRate() {
    return Number(this.$route.query?.load_rate) || this.loadRate // 支持 query 参数
  }

  get calcLoadEblur() {
    return this.$route.query?.load_eblur || this.loadEblur // 支持 query 参数
  }

  get calcCrop() {
    const { klook, calcRateObj, crop, realHeight, cropScale } = this
    if (crop) {
      return crop
    }
    if (!realHeight) {
      const obj = {
        mobile: '/c_fill,w_800',
        desktop: '/c_fill,w_1920'
      }
      return obj[klook.platform]
    }
    const ch = Math.round(realHeight * cropScale)
    const cw = Math.round(ch / calcRateObj.hwRate)
    const autoCrop = `/c_fill,w_${cw},h_${ch}`
    return autoCrop
  }

  get calcSrc() {
    const { calcCrop, src: simg, klook } = this
    return this.getImgSrc(simg, { crop: calcCrop, isWebp: klook.webp })
  }

  getImgSrc(img: string, options: any) {
    if (!img) {
      return img
    }
    const crop = options?.crop || ''
    const isWebp = options?.isWebp || false
    const regStr = options?.regStr || '//res.klook.com/image/upload'
    const src = crop ? img?.replace(regStr, `${regStr}${crop}`) : img
    return setImageFormat(src, isWebp)
  }

  created() {
    this.initData()
  }

  initData() {
    if (!this.klook.isBot) {
      this.isInitImageLoaded = true
      this.isTargeImageLoaded = this.firstImage || false
      this.targetImageTransition = ''
    }
  }
}
