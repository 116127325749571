














import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import SavingPriceDetails from '~/components/experience/saving-price-details/index.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    SavingPriceDetails
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: false }) hideDashed!: boolean
  @Prop({ default() { return {} } }) poptipData!: any
  @Prop({ default() { return {} } }) priceSummary!: any

  poptipDef = {
    width: 480,
    maxWidth: 480,
    maxHeight: 0
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get calcTrack() {
    const def = {
      type: 'module',
      action: false,
      spm: 'Savings_Detail_Pop_Up'
    }
    return def
  }
}
