




















import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import RwdSwiper from '~/components/experience/rwd-swiper/index.vue'
import RecentReviewCard from '~/components/experience-booking/experience-activity/recent-review/recent-review-card.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    RwdSwiper,
    RecentReviewCard
  }
})
export default class RecentReview extends Vue {
  @State klook!: Data.Klook
  @Prop() score!: number
  @Prop() reviewCount!: number
  @Prop({ default: () => [] }) reviewList!: ExperienceActivity.IReview

  seeMoreReviews() {
    this.$emit('view-more')
  }
}
