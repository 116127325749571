import { Component, mixins, State } from 'nuxt-property-decorator'
import BaseMixin from '~/components/booking-core/mixins/common/base'
import PrivateMixin from '~/components/booking-core/mixins/common/private'
import AttributesMixin from '~/components/booking-core/mixins/common/attributes'
import ShoppingCartMixin from '~/components/booking-core/mixins/common/shopping-cart'
import PkgDetailMixin from '~/components/booking-core/mixins/common/pkg-detail'
// import dataUtils from '~/components/booking-core/utils/data-utils'
// import dataTypesUtils from '~/components/booking-core/utils/types'
@Component({
  components: {
    //
  }
})
export default class Index extends mixins(BaseMixin, PrivateMixin, AttributesMixin, ShoppingCartMixin, PkgDetailMixin) {
  @State klook!: Data.Klook
  // 活动层级数据 start
  // 活动层级数据 end

  // 套餐层级数据 start
  get calcFirstUnselectedSpecId() {
    const specItem = this.calcExtraSpecList.find((item: any) => !this.chosenAttrIds?.find(id => item.attrSet.has(id)))
    return specItem?.id || 0
  }
  // 套餐层级数据 end

  // unit层级数据 start
  // unit层级数据 end

  // 接口请求参数 start
  get calcActSchedulesParams() {
    // 请求活动日历价格库存接口的参数
    const { activityId: activity_id, calcPreview: preview, isLinePage } = this
    return { activity_id, preview, isLinePage }
  }

  get calcPkgSchedulesAndUnitsParams() {
    // 请求套餐日历价格库存接口的参数
    const { pkgSelectedTime, isTranslation: translation, packageId: package_id, calcPreview: preview } = this
    return {
      pkgSelectedTime,
      translation,
      package_id,
      preview
    }
  }

  get calcPkgUnitsParams() {
    // 请求unit价格库存接口的参数
    const { pkgSelectedTime, isTranslation: translation, calcPreview: preview } = this
    return {
      pkgSelectedTime,
      arrangement_id: this.calcArrangementId,
      translation,
      preview
    }
  }

  get calcPkgDetailParams() {
    // 请求套餐详情接口的参数
    const { isTranslation: translation, calcPreview: preview, packageId: package_id, isLinePage: is_line_page } = this
    // 抢购加上 is_line_page 字段
    if (preview) {
      // preview 状态下，使用新接口
      return { preview, translation: !!translation, package_id_list: package_id, publish_status: 'all', is_line_page: false }
    } else {
      return { preview, translation: !!translation, publish_status: 'viewable', package_id_list: package_id, is_line_page }
    }
  }
  // 接口请求参数 end
}
