









import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component({
})
export default class Divider extends Vue {
  @Prop({ type: String, default: 'desktop' }) platform!: string
  @Prop({ type: Object, default: () => ({}) }) containerData!: any
}
