import { Prop, Component } from 'vue-property-decorator'
import { Package, IPackageItem } from './type'
import { isLessThanTargetTime } from '~/share/count-down'
import PublishInfoBase from '~/components/common/publish-info/publish-info-base'

@Component
export default class Card extends PublishInfoBase {
  @Prop({ type: Boolean, default: false }) isFnb!: boolean
  @Prop({ type: Boolean, default: false }) isPassActivity!: boolean
  @Prop({ type: Object, default: () => ({}) }) packageData!: IPackageItem
  @Prop({ type: Array, default: () => [] }) packageDescList!: Package[]
  @Prop() activityId!: number
  // isDisable是指当前日期不可用，mweb整个卡片置灰，web则是过滤不展示
  @Prop({ type: Boolean, default: false }) isDisable!: boolean
  @Prop({ default: () => {} }) packagesGroupDesc!: any
  @Prop({ default: 0 }) preview!: number

  detailShow = false

  get disabledText() {
    if (this.isSuspended) {
      return this.suspendedText
    } else if (this.isWarming) {
      return this.warmingText
    } else {
      return ''
    }
  }

  // 后端数据逻辑
  get isDisableSale() {
    return this.isWarming || this.isSuspended
  }

  get calcTags2attribute() {
    return this.packageData?.product_tags?.attribute_tags || []
  }

  get priceData() {
    return this.packageData?.price_show || {}
  }

  get calcTags2discount() {
    // mweb活动页-card_sku
    const discountTags = this.packageData?.product_tags?.discount_tags || []
    const tagsList: any = []
    if (discountTags?.length) {
      // 先过滤掉倒推的数据(这里比较特殊) ，不能使用sku的逻辑
      // const filterTags = this.isFnb ? discountTags : discountTags.filter((tag: any) => {
      //   const inner_vars = tag?.inner_vars || {}
      //   return !inner_vars?.inner?.derivation_info || !inner_vars?.inner?.derivation_info?.is_derived
      // })

      const filterTags = discountTags
       filterTags?.length &&
       filterTags.forEach((item: any) => {
         if (item?.inner_vars?.rate_plan?.count_down_end_time) {
           const flag = isLessThanTargetTime(item.inner_vars.rate_plan.count_down_end_time)
           flag && tagsList.push(item)
         } else {
           tagsList.push(item)
         }
       })
    }

    return tagsList
  }

  get cutDownTimeList() {
    const tags = this.calcTags2discount
    const list: any = []

    if (tags?.length) {
      tags.forEach((item: any) => {
        const endTime = item?.inner_vars?.rate_plan?.count_down_end_time
        endTime && list.push(endTime)
      })
    }

    return list
  }

  get event() {
    return this.packageData?.promotion_price?.promotion_event
  }

  get packageGroupInfor(): any {
    return (
      (this.packageDescList || []).find((item: Package) => item.package_id === this.packageData.package_id) ||
      this.packageData
    )
  }

  get packageName(): any {
    return this.packageGroupInfor.package_name || this.packageData.package_name
  }

  get packageDesc() {
    return this.packageGroupInfor?.package_desc || []
  }

  reload() {
    window && window.location.reload()
  }

  // 查看详情
  goDetail() {
    this.detailShow = true
  }
}
