










import { Component, State, Vue, Prop } from 'nuxt-property-decorator'
import { IconTriangleUp } from '@klook/klook-icons'
import { SubSectionComponentImage } from '../type'

@Component({
  components: {
    IconTriangleUp
  }
})
export default class Image extends Vue {
  @Prop({ type: Object, default: {} }) data!: SubSectionComponentImage['data']
  @State klook!: Data.Klook

  get isMobile() {
    return this.klook.platform === 'mobile'
  }
}
