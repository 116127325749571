




































































import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import PackageDetailCollapse from '~/components/common/package-detail-collapse/package-detail-collapse.vue'
import { Activity } from '~/types/traveller/activity'
import ActivityValidity from '~/components/experience-booking/experience-activity/open-date-ticket/activity-validity/activity-validity-new.vue'
import AttractionIncluded from '~/components/experience-booking/experience-activity/package-options/attraction-included/desktop/index.vue'
import ExpTagging from '~/components/experience/tagging/index.vue'

@Component({
  components: {
    ExpTagging,
    ActivityValidity,
    PackageDetailCollapse,
    AttractionIncluded
  }
})

export default class PackageDetailContent extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: {} }) packageDetail!: Activity.packageDetail
  @Prop({ default: 'slideDetail' }) type!: 'slideDetail' | 'moduleDetail'
  @Prop({ default: 'normal' }) attractionSize!: 'small' | 'normal'
  @Prop() size!: 'large' | 'small'
  @Prop({ default: true }) expandAll!: boolean
  @Prop({ default: true }) showPackageName!: boolean
  @Prop({ default: false }) showActivityName!: boolean
  @Prop({ default: false }) simplifiedPickUp!: boolean
  @Prop({ type: Number, default: 0 }) imageCardSwiperType!: number
  @Prop({ type: String, default: '' }) imageGroupStyle!: string
  @Prop({ type: Boolean, default: false }) useImageSwiper!: boolean

  get calcTags2attribute() { // desktop活动页-套餐详情
    const { product_tags } = this.packageDetail as any
    return product_tags?.attribute_tags || []
  }

  get specInfo() {
    const spec = this.packageDetail?.spec || []
    if (spec?.length) {
      const specList = spec.map((item: any) => {
        return (item?.attr || []).map((child: any) => {
          return child?.name || ''
        })
      })
      return specList.flat()
    }
    return []
  }

  get passStandardActivityInfo() {
    return this.packageDetail?.passStandardActivityInfo || {}
  }
}
