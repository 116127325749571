
































import { Component, State } from 'nuxt-property-decorator'
import ActivityPromotionInfo from './promotion-info.vue'
import ActivityPromotionInfoContent from './promotion-info-content.vue'
import ActivityLayer from '~/components/traveller/activity/layer/activity-layer.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({
  components: {
    ActivityPromotionInfo,
    ActivityLayer,
    ActivityPromotionInfoContent
  }
})
export default class ActivityPromotionInfoSection extends BusinessBase {
  @State(state => state.klook.platform) platform!: Data.Platform

  static displayName = 'Promotions'

  isLayerVisible = false

  get promotionInfoList() {
    return this.businessData.promotions
  }

  handleLayerShow() {
    this.isLayerVisible = true
  }

  handleLayerHide() {
    this.isLayerVisible = false
  }
}
