








import { Component, Vue, Prop } from 'nuxt-property-decorator'
import BookingStartDate from './booking-start-date.vue'

@Component({
  components: {
    BookingStartDate
  }
})
export default class ActivityBookingStartDate extends Vue {
  @Prop() startTime!: string

  getStartTime(date: string) {
    const timeConfig: {
      [key: string]: string
    } = {
      today: this.$t('book.same.day'),
      tomorrow: this.$t('book.tomorrow'),
      soldOut: this.$t('book.time.soldout')
    }

    return timeConfig[date] || this.$t('book.time.text', [date])
  }
}
