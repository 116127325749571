














import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class CardSwiper extends Vue {
  handleScroll() {
    this.$emit('scroll-once')
  }

  handleTouchStart() {
    this.$emit('touchstart')
  }

  handleTouchMove() {
    this.$emit('touchmove')
  }

  handleTouchEnd() {
    this.$emit('touchend')
  }
}
