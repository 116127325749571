








import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class PackageDetailFailure extends Vue {
  handleClick() {
    this.$emit('fetch-detail')
  }

  mounted() {
    const elem = this.$el as any
    this.$emit('mounted', elem.offsetHeight)
  }
}
