




































import { Component, State, mixins, Prop } from 'nuxt-property-decorator'
import Banner from '../banner/index.vue'
import ImageGallery from '~/components/experience-booking/experience-activity/banner/components/image-gallery/index.vue'

import ActivityRightPrice
  from '~/components/experience-booking/experience-activity/banner/desktop/activity-right-price/index.vue'
import ActivityRightPriceLoading
  from '~/components/experience-booking/experience-activity/banner/desktop/activity-right-price/loading.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { scrollToPackageOption } from '~/share/scroll2/scroll2'
import ScrollMixins from '~/components/experience-booking/experience-activity/banner/desktop/scroll-mixins'

@Component({
  components: {
    ActivityRightPrice,
    ActivityRightPriceLoading,
    Banner,
    ImageGallery
  }
})
export default class ActivityBanner extends mixins(BusinessBase, ScrollMixins) {
  @State klook!: Data.Klook
  @Prop() basicData!: ExperienceActivity.IBasicData

  static displayName = 'DesktopBanner_atlas'

  showGallery: boolean = false
  currentIndex: number = 0
  bannerClick(index: number) {
    if (this.bannerList.length <= 1) {
      return
    }
    this.currentIndex = index
    this.showGallery = true
  }

  get activityId() {
    return this.businessData?.activity_id
  }

  handleBuyNow() {
    scrollToPackageOption({ lockLocation: 110 })
  }

  bannerList: ExperienceActivity.IBannerItem[] = []
  get rightPriceData() {
    return {
      ...this.businessData,
      price: false,
      priceShow: this.businessData?.price?.price_show,
      isActivityPublishInfo: true,
      publishInfo: this.basicData?.publish_info,
      basicData: this.basicData
    }
  }

  get officialImages() {
    const { webp } = this.klook
    const bannerList = this.bannerList || []
    return bannerList.map((item, index) => {
      const suffix = webp ? item.suffix.replace(/.(jpg|png|jpeg|gif)$/, '.webp') : item.suffix
      const watermark = this.hasWaterMark(item) ? 'w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/' : ''
      const src = `${item.prefix}fl_lossy.progressive,q_65/w_1080/${watermark}${suffix}`
      return {
        image_id: index,
        img_resize_url: src,
        img_url: src,
        height: item.height,
        width: item.width
      }
    })
  }

  get cutSize() {
    const bannerLength = this.bannerList?.length
    return bannerLength < 3 ? 'c_fill,w_1160,h_474/q_80/' : 'c_fill,w_1265,h_712/q_80/'
  }

  get bannerImageUrls() {
    return this.bannerList?.map((val: any, index) => {
      return this.formatImg(val, index)
    })
  }

  handleShowAllGallery() {
    this.showGallery = true

    const { activity_id, activity_page_template_id } = this.basicData || {}
    this.$sendGTMCustomEvent(`View Photo Item Clicked|Activity Page|${activity_id}_${activity_page_template_id}`)
  }

  formatImg(val: ExperienceActivity.IBannerItem, index: number) {
    // 'c_fill,w_340,h_228/'
    const cutSize = index === 0 ? this.cutSize : 'c_fill,w_627,h_470/q_80/'
    const watermark = this.hasWaterMark(val) ? 'w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/' : ''
    const suffix = this.formatPicExtension(val.suffix)
    return val.prefix + val.pre_process + cutSize + watermark + suffix
  }

  hasWaterMark(item: ExperienceActivity.IBannerItem) {
    // white label 中图片不展示水印
    return item.has_water_mark && !this.klook.utilConfig.whiteLabelUtilConfig
  }

  formatPicExtension(url: string) {
    if (!url) {
      return ''
    }

    const { webp } = this.klook
    if (webp) {
      return url.replace(/.(jpg|png|jpeg)$/, '.webp')
    }

    return url
  }

  created() {
    this.bannerList = this.businessData.banner_list || []
  }
}
