














































































import { Vue, Component, namespace, State, Prop, Getter, Watch, Inject } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import { HomePage } from '~/types/traveller/home-page'
import { Activity } from '~/types/traveller/activity'
import SelectPackageAttrs
  from '~/components/experience-booking/experience-activity/package-options/desktop/selected-package-attrs.vue'
import scrollToPackageOptionsTop
  from '~/components/traveller/activity/activity-package-options/desktop/package-options/scroll-to-package-options-top'
import apis from '~/share/data/apis'
import ShoppingCart from '~/components/experience-booking/experience-activity/activity-shopping-cart/index.vue'
import * as sessionStorage from '~/assets/scripts/sessionstorage'
import { currencySymbolMap } from '~/share/data/currency'
import { formatThousands, formatCurrencySymbolPriceThousands, routerPush, getBookingNowParams } from '~/share/utils'
import ActivityDotFlashing from '~/components/traveller/activity/activity-bottom-bar/mobile/activity-dot-flashing.vue'
import errorCode from '~/components/traveller/activity/book-now-error-code'
// import beforeAddToCart
//   from '~/components/experience-booking/experience-activity/package-options/desktop/zhenxing-coupon-tip'
import { DefaultFieldsConfig, getBookButtonTextId } from '~/share/data/experience/fieldsConfigUtils.js'
import SavingPriceDetailsPoptip from '~/components/experience/saving-price-details/poptip.vue'
import TotalSlot, { getPreTotalPriceData, getPriceSummary } from '~/components/experience/price-slot/total-slot.vue'
import CutDown from '~/components/experience/cut-down/index.vue'
import { Experience } from '~/types/experience'
import eventBus from '~/pages/experience/pay/common/event-bus'
import { getStandardDateFormat } from '~/share/data/datetime'
import { isLessThanTargetTime } from '~/share/count-down'
import { ExperienceActivity } from '~/types/experience/activity-section'
import KlkCredit from '~/components/experience/klk-credit/index.vue'

const authModule = namespace('auth')
const ActivityModule = namespace('traveller/activity')
const experience = namespace('experience')

@Component({
  components: {
    KlkCredit,
    TotalSlot,
    SavingPriceDetailsPoptip,
    SelectPackageAttrs,
    ActivityDotFlashing,
    ShoppingCart,
    CutDown
  }
})
export default class PackageOptionsBtnGroup extends Vue {
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf
  @State klook!: Data.Klook
  @ActivityModule.State preSettlementApiData!: any
  @authModule.State isLoggedIn!: boolean
  @Getter isGuestCheckout!: boolean
  @Prop({ default: null }) wifiSimParams?: any
  @Prop() basicData!: ExperienceActivity.IBasicData
  @Prop() packages!: any[]
  @Prop({ default: false }) isMultiPass!: boolean
  @Prop({ default: false }) fixedComponent!: boolean
  @Prop({ default: false }) isFixedBtn!: boolean
  @Prop() modificationShoppingCart!: boolean
  @Prop() packageId!: number
  @Prop() activityId!: number
  @Prop({ default: -1 }) activityPageTemplateId!: number
  @Prop() templateId!: number
  @Prop() selectedTime!: { arrangementId: number, stock: number, date: string }
  @Prop() units!: Activity.unit[]
  @Prop() totalUnitCount!: number
  @Prop() currentSelectedPackage!: any
  @Prop() packageSchedules!: { date: string, arrangementId: number }[]
  @Prop() packagePriceSchedules!: { date: string, arrangementId: number }[]
  @Prop() packageSelectedDate!: string
  @Prop() isUsingPackagePriceSchedule!: number
  @Prop() priceSchedulesObj!: { [prop: string]: Activity.PriceSchedule }
  @Prop() isInPromotion!: boolean
  @Prop() fetchPromotionDetailStatus!: string
  @Prop() isNswActivity!: boolean
  @Prop({ default: false }) isJumpOldSettlement!: boolean
  @Prop() spec!: Activity.spec[]
  @Prop() isNewOpenTicket!: boolean
  @Prop({ default: false }) isYiFunVoucher!: boolean
  @Prop({ default: false }) custom_config_hide_credit!: boolean
  @Inject({ from: 'fieldsConfig2section', default() { return DefaultFieldsConfig } }) fieldsConfig2section!: HomePage.AllFieldsIF
  @Prop() vip?: Experience.MembershipInfo
  @Prop({ default: null }) isSupportShoppingCart!: boolean

  role: string = 'bookNow'
  ModuleExtra: string = JSON.stringify({ CompletedInfo: false })
  minSellPrice = ''
  sellPrice = '-'
  loading = false
  source: HTMLElement | null = null
  shoppingCartVisible = false
  timeList: string[] = []

  get isPkgOpenTicket() {
    return this.currentSelectedPackage?.package_ticket_type === 3
  }

  getDatetimeFormat() {
    const { selectedTime, isPkgOpenTicket, sectionActData } = this
    if (sectionActData.isWifiSimCard) {
      return this.wifiSimParams?.datetime_format
    }
    if (isPkgOpenTicket) {
      return this.$t('24717')
    }
    let str = ''
    const { date } = selectedTime || {}
    const arr: any[] = this.packageSchedules || []
    const type = arr?.find((o: any) => o.date === this.packageSelectedDate)?.time_slots?.length === 1 ? 1 : 5
    if (date) {
      const $t = this.$t.bind(this)
      str = getStandardDateFormat(date, $t, this.klook.language, type)
    }
    return str
  }

  getExtraList() {
    const { bookingDays } = this
    const arr: any = []
    if (bookingDays >= 1) {
      arr.push({
        text: this.$t('3485'),
        value: `x${bookingDays}`
      })
    }
    return arr
  }

  get calcPreTotalPrice() {
    const preData = this.preSettlementApiData?.result
    const { selectedTime, allUnitCount } = this
    const isSelectSku = selectedTime && selectedTime.arrangementId
    const extra_list: any = this.getExtraList()
    const d = getPreTotalPriceData(this.currentSelectedPackage, preData, {
      oldPriceShow: {
        price_summary: getPriceSummary(this.currentSelectedPackage, this.units || [], this.klook.currency, { extra_list }),
        sale_price: ((isSelectSku && this.price > 0) || allUnitCount()) ? formatCurrencySymbolPriceThousands(this.klook.currency, this.price) : '',
        from_price: !isSelectSku && this.minSellPrice ? this.minSellPrice : ''
      }
    })

    if (this.calcShowPrice) {
      const { handleCloseModalEvent, isBtnVisibleAddtocart, isMultiPass, bookingBottomBtnsBinds } = this
      const { creditsVisible, credits } = this
      const calcCredits = d?.credits || credits
      const creditsStr = this.$t('activity.new.credits', { account: Math.floor(calcCredits) })
      let tagCredits = { text: creditsStr, tag_key: 'normal-credits', background_color: '#F0FFFA', text_color: '#0DA06A', border_color: '#F0FFFA' }
      const credits_tag = this.currentSelectedPackage?.credits_tag || {}
      if (credits_tag?.text) {
        const text = `${credits_tag.text.split(' ')[0]} +${calcCredits}`
        tagCredits = { ...credits_tag, text }
      }
      eventBus.$emit('updateRightPriceData2bus', {
        creditsObj: { creditsVisible, tagCredits, credits: calcCredits },
        rightPriceDetail: getPriceSummary(this.currentSelectedPackage, this.units || [], this.klook.currency, {
          extra_list,
          datetime_format: this.getDatetimeFormat(),
          package_id: this.currentSelectedPackage.package_id
        }),
        prePriceData: d,
        handleCloseModalEvent,
        bookingBottomBtnsBinds,
        isBtnVisibleAddtocart,
        isMultiPass
      })
    } else {
      eventBus.$emit('updateRightPriceData2bus', {})
    }

    return d
  }

  updated() {
    const { packageId, selectedTime, allUnitCount } = this
    if (!packageId || !selectedTime?.arrangementId || !allUnitCount()) {
      eventBus.$emit('updateRightPriceData2bus', {})
    }
  }

  get isSoldOut() {
    const { packages } = this
    return isEmpty(packages) || packages.every((v: any) => !v.has_stocks)
  }

  toastHandler({ content }: any) {
    this.$toast(content)
    this.$businessLog({
      type: 'FECheckFail',
      tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
      reason: content
    })
  }

  get bookingBottomBtnsBinds() {
    return {
      wifiSimParams: this.wifiSimParams,
      msgFn: this.toastHandler,
      selectedTime: this.selectedTime,
      currentSelectedPackage: this.currentSelectedPackage,
      templateId: this.templateId,
      units: this.units,
      activityId: this.activityId,
      isSoldOut: this.isSoldOut,
      isYiFunVoucher: this.isYiFunVoucher,
      isOpenTicket: this.isNewOpenTicket,
      selectedPackagePriceSchedule: this.packageSchedules,
      packageSelectedDate: this.packageSelectedDate,
      totalUnitCount: this.totalUnitCount
    }
  }

  get calcShowPrice() {
    if (!this.selectedTime?.arrangementId || isEmpty(this.units)) { return false }
    const count = (this.units || []).reduce((num: number, obj: any) => {
      num = num + (obj.count || 0)
      return num
    }, 0)
    return count > 0
  }

  get calcBookButtonText() {
    return this.$t(getBookButtonTextId(this.currentSelectedPackage?.redeem_status))
  }

  // 选择日历日期之后通过schedules接口拿到的库存相关数据来计算展示价格
  get stock() {
    if (this.packageSelectedDate) {
      const schedule = this.priceSchedulesObj[this.packageSelectedDate]

      if (schedule && schedule.stocks) {
        const stock = schedule.stocks.find(v => v.package_id === this.packageId)

        if (stock) {
          return stock
        }
      }
    }
  }

  get newModuleExtra() {
    const obj: any = JSON.parse(this.ModuleExtra) || {}
    const { date } = this.selectedTime
    if (date) {
      obj.date = date.split(' ')[0] || ''
    }
    const skuArr = (this.units || []).filter(o => o.count > 0)
    if (skuArr.length) {
      obj.sku = skuArr.map(o => o.skuId)
      obj.quantitiy = skuArr.map(o => o.count)
    }
    const ext = JSON.stringify(obj)
    return ext
  }

  get creditsVisible() {
    return !this.fieldsConfig2section.activity_custom_config_hide_credit && !this.isGuestCheckout && !this.custom_config_hide_credit && this.credits > 0
  }

  get isBtnVisibleAddtocart() {
    const isb = typeof this.isSupportShoppingCart === 'boolean' ? this.isSupportShoppingCart : !this.isNswActivity // 有传isSupportShoppingCart则优先使用
    return ![47631, 46820, 48639, 48789, 55826, 49807, 55724, 68784].includes(this.activityId) && isb && !this.isGuestCheckout
  }

  @Watch('currentSelectedPackage', { immediate: true })
  currentSelectedPackageChange(val: any) {
    if (!isEmpty(val)) {
      this.getPackageCutDownTime(val)
    }
    this.getSellPrice()
  }

  @Watch('stock', { immediate: true })
  stockChange() {
    this.getSellPrice()
  }

  getPackageCutDownTime(val: any) {
    const discountTags = val?.product_tags?.discount_tags || []
    const list: any = []
    if (discountTags?.length) {
      discountTags.forEach((item: any) => {
        const endTime = item?.inner_vars?.rate_plan?.count_down_end_time
        if (endTime) {
          const flag = isLessThanTargetTime(endTime)
          flag && list.push(endTime)
        }
      })
      this.timeList = list.length ? list : []
    } else {
      this.timeList = []
    }
  }

  reload() {
    window && window.location.reload()
  }

  getSellPrice() {
    // 此为套餐价
    if (this.stock) {
      const { from_price, to_price } = this.stock

      if (from_price === to_price) {
        this.sellPrice = from_price
        this.minSellPrice = ''
      } else {
        this.minSellPrice = `${currencySymbolMap[this.klook.currency]} ${formatThousands(from_price)}`
      }

      return
    }

    if (isEmpty(this.currentSelectedPackage)) {
      this.sellPrice = '-'
      return
    }

    // from_price, to_price 逻辑后端已收拢到 price_show 字段中
    this.minSellPrice = ''

    // const { from_price, to_price } = this.currentSelectedPackage

    // if (from_price === to_price) {
    //   this.sellPrice = from_price
    //   this.minSellPrice = ''
    // } else {
    //   this.minSellPrice = `${currencySymbolMap[this.klook.currency]} ${formatThousands(from_price)}`
    //   this.sellPrice = '-'
    // }
  }

  get usingPriceScheduleStock() {
    return this.isUsingPackagePriceSchedule && this.packageSelectedDate
  }

  get marketPrice() {
    if (this.usingPriceScheduleStock) {
      return 0
    }

    if (this.currentSelectedPackage) {
      const { sell_price, market_price } = this.currentSelectedPackage

      return Number(market_price) > Number(sell_price) ? market_price : 0
    }
    return 0
  }

  get addToCartText() {
    return this.modificationShoppingCart ? this.$t('shoppingcart.confirm_change') : this.$t('activity.label.add_to_cart')
  }

  get bookingDays() {
    return this.wifiSimParams?.days || 0
  }

  get price() {
    if (isEmpty(this.units)) {
      return 0
    }

    // 小数位
    const parts = (this.units[0]?.sellingPrice || '')?.toString().split('.')
    const decimal = parts[1] || ''

    const price = this.units.reduce((acc, v) => acc + v.sellingPrice * v.count, 0)

    if (this.bookingDays) {
      return (price * this.bookingDays).toFixed(Number(decimal.length))
    }

    return price.toFixed(Number(decimal.length))
  }

  get discountText() {
    if (!this.isInPromotion || isEmpty(this.units)) {
      return ''
    }

    const price = this.units.reduce((acc, v) => acc + v.sellingPrice * v.count, 0)
    const originalPrice = this.units.reduce((acc, v) => acc + v.originalPrice * v.count, 0)
    const discount = Number(originalPrice - price)

    if (discount > 0) {
      // 小数位
      const parts = (this.units[0]?.sellingPrice || '')?.toString().split('.')
      const decimal = parts[1] || ''

      return this.$t('16579', {
        0: formatThousands(discount.toFixed(Number(decimal.length))),
        currency: currencySymbolMap[this.klook.currency]
      })
    }

    return ''
  }

  get credits() {
    if (isEmpty(this.units)) {
      return 0
    }
    const credits = this.units.reduce((acc, v) => acc + parseFloat(v.credits?.toString()) * v.count, 0)

    if (this.bookingDays) {
      return parseInt((credits * this.bookingDays).toString())
    }

    return parseInt(credits.toString())
  }

  get shoppingCartId() {
    return parseInt(this.$route.query.shop_id as string) || -1
  }

  get shoppingCartIdStr() {
    return this.$route.query.shop_id || ''
  }

  get preOrderKey() {
    return `activity_pre_order_${this.activityId}`
  }

  getWifiSimParams() {
    let specParams = {}
    if (this.sectionActData.isWifiSimCard) {
      const { start_date, end_date, start_time_type_id, end_time_type_id } = this.wifiSimParams || {}
      specParams = {
        start_date: start_date || '',
        end_date: end_date || '',
        start_time_type_id: start_time_type_id || 0,
        end_time_type_id: end_time_type_id || 0
      }
    }
    return specParams
  }

  getParams() {
    const sessionParams = this.getSessionData('params')

    if (sessionParams) {
      return sessionParams
    }

    const { arrangementId } = this.selectedTime

    const schedule = this.packagePriceSchedules.find(v => v.arrangementId === arrangementId)
    const scheduleEndTime = schedule ? schedule.date : ''

    const priceItems = this.units.reduce((acc: any[], v) => {
      return v.count > 0 ? [...acc, {
        sku_id: v.skuId,
        price_id: v.priceId,
        count: v.count,
        event_id: v.isInPromotion ? v.eventId : 0 // 促销活动正在进行的时候才传 eventId
      }] : acc
    }, [])

    const specParams = this.getWifiSimParams()

    const params = {
      arrangement_id: arrangementId,
      schedule_end_id: arrangementId,
      package_id: this.currentSelectedPackage.package_id,
      template_id: this.templateId,
      is_open_ticket: this.currentSelectedPackage.isOpenTicket,
      schedule_end_time: scheduleEndTime,
      price_items: priceItems,
      shoppingcart_type: this.role === 'bookNow' ? 1 : 0,
      ...specParams
    }

    if (this.shoppingCartId !== -1) {
      return {
        ...params,
        shoppingcart_id: this.shoppingCartId,
        shoppingcart_id_str: this.shoppingCartIdStr
      }
    }

    return params
  }

  allUnitCount() {
    let count = 0
    if (this.units && this.units.length > 0) {
      this.units.forEach((item: any) => {
        count += item.count
      })
    }
    return count
  }

  get calcCredits() {
    const { credits: preCredits } = this.calcPreTotalPrice
    return preCredits || this.credits
  }

  creditsText() {
    const { vip } = this
    const { credits: preCredits } = this.calcPreTotalPrice
    const color = vip?.highlight_color
    let t = this.$t('credits_info.get_credits.booking_text', [preCredits || this.credits]).toString()
    color && (t = t.replace('<b>', `<b style="color: ${color}">`))
    return t
  }

  addToCart() {
    const { activity_id, activity_page_template_id } = this.basicData || {}
    this.$sendGTMCustomEvent(`Add To Cart Clicked|Activity Page|${activity_id || this.activityId}_${activity_page_template_id || this.activityPageTemplateId}`)

    this.$emit('clickButton')
    const dateFun = this.$parent as any
    if (!dateFun.checkDateAndTime()) {
      return
    }
    this.role = 'addToCart'
    this.handleClick()
  }

  handleCloseModalEvent() {
    this.showShoppingCart && this.showShoppingCart()
  }

  showShoppingCart() {
    this.source = this.$el.querySelector('.add-cart') as HTMLElement
    this.shoppingCartVisible = true
  }

  bookNow() {
    const { activity_id, activity_page_template_id } = this.basicData || {}
    this.$sendGTMCustomEvent(`Book Now Clicked|Activity Page|${activity_id || this.activityId}_${activity_page_template_id || this.activityPageTemplateId}`)

    this.$emit('clickButton')
    const dateFun = this.$parent as any
    if (!dateFun.checkDateAndTime()) {
      return
    }

    if (this.sectionActData.isWifiSimCard && typeof this.wifiSimParams?.validateFn === 'function') {
      const msg = this.wifiSimParams.validateFn()
      if (msg) {
        return this.$toast(msg)
      }
    }

    this.role = 'bookNow'
    this.handleClick()
  }

  async handleNoLogin() {
    await this.$loginSDK({
      purpose: this.role === 'addToCart' ? 'Cart' : 'Booking',
      success: async () => {
        await this.bookRequest()
      },
      fallback: () => {
        // 保存用户选择数据，可以在引导用户登录完成之后回到页面直接下单
        this.saveBookingOptions()
      }
    })
  }

  async handleClick() {
    if (!this.totalUnitCount) {
      this.$toast(this.$t('activity.booking.options.select.quantity') as string)
      return
    }

    const minPax = this.currentSelectedPackage.min_pax
    if (this.totalUnitCount < minPax) {
      this.$toast(this.$t('activity.error.price_min', [minPax]) as string)
      return
    }

    // guest checkout
    if (this.isGuestCheckout) {
      if (await this.klook.utilConfig?.whiteLabelUtilConfig) {
        const res = await this.klook.utilConfig.whiteLabelUtilConfig.sdk.login()
        res && this.bookRequest()
      } else {
        const res = await this.$axios.$post(apis.registerGuest)
        res?.success && this.bookRequest()
      }
      return
    }

    // 去除zhanxing券逻辑
    // if (this.role === 'addToCart' &&
    //   this.isYiFunVoucher) {
    //   if (await beforeAddToCart(this)) {
    //     this.role = 'bookNow'
    //   }
    // }

    if (!this.isLoggedIn) {
      await this.handleNoLogin()
      return
    }

    this.bookRequest()
  }

  async bookRequest() {
    this.ModuleExtra = JSON.stringify({ CompletedInfo: true })
    const api = this.shoppingCartId === -1 ? (this.role === 'addToCart' ? apis.bookingNow : apis.expPostPreOrderServiceNew) : this.role === 'addToCart' ? apis.addToShoppingCard : apis.postExpEditeShoppingCartAndnew
    let params = this.getParams()
    if (this.shoppingCartId === -1 && this.role !== 'addToCart') {
      const { arrangement_id, price_items } = params
      params = getBookingNowParams(arrangement_id, price_items, { wifiSimParams: this.getWifiSimParams() })
    }
    this.$showLoading({ overlayColor: 'rgba(255, 255, 255, 0.7)' })
    try {
      const res = await this.$axios.$post(api, params)

      this.$hideLoading()

      if (res.success) {
        // 加入购物车成功埋点
        const id = res.result?.shoppingcart_id_str || res.result?.shoppingcart_id
        if (this.role === 'addToCart') {
          this.$inhouse.track('custom', 'body', {
            spm: 'AddToCartSuccessLoad',
            shoppingcart_id: id
          })
        }

        setTimeout(() => {
          if (this.role === 'bookNow') {
            this.$sendGTMCustomEvent('Activity Page|Book Now Button Clicked')

            routerPush(this, {
              name: 'ExperiencePay',
              query: {
                settlement_type: '1',
                shoppingcart_guid: res.result?.pre_order_id
              }
            })
          } else {
            this.$sendGTMCustomEvent('Activity Page|Add To Cart Button Clicked')
            this.shoppingCartId === -1 ? this.showShoppingCart() : (window.location.href = this.$href('/shoppingcart'))
          }
        })

        return
      }

      // 未登录
      if (res?.error?.code === '4001') {
        await this.handleNoLogin()
        return
      }

      if (res.error.code === '01001007027') {
        this.$businessLog({
          tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
          type: 'ApiError',
          reason: '价格改变了',
          code: res?.error?.code,
          message: res?.error?.message
        })
        this.$confirm(this.$t('16995-price_change_tips'), '', {
          okLabel: this.$t('global.tips.okTxt') as string,
          cancelLabel: this.$t('global.tips.cancelTxt') as string
        }).then((res) => {
          if ((res as any).result) {
            window.location.reload()
          }
        })
        return
      }

      this.$businessLog({
        tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
        type: 'ApiError',
        code: res?.error?.code,
        message: res?.error?.message
      })

      if (errorCode.includes(res?.error?.code)) {
        this.$confirm(res.error.message, {
          okLabel: this.$t('global.confirm'),
          cancelLabel: this.$t('global.tips.cancelTxt')
        }).then(({ result: isb }) => {
          if (isb) {
            window.location.reload()
          }
        })
      } else {
        this.$alert(res.error && res.error.message)
      }
    } catch (e) {
      const error = e as any
      this.$businessLog({
        tag: this.role === 'bookNow' ? 'BookNowBtn' : 'AddToCartBtn',
        type: 'JsError',
        name: error?.name,
        stack: error?.stack,
        message: error?.message
      })

      this.$hideLoading()
      this.$alert(error).then(() => {
        window.location.reload()
      })
    }
  }

  saveBookingOptions() {
    sessionStorage.setSessionData(this.preOrderKey, JSON.stringify({
      params: this.getParams()
    }))
  }

  getSessionData(key: string) {
    const sessionData = sessionStorage.getSessionData(this.preOrderKey)

    if (!sessionData) {
      return null
    }

    try {
      const data = JSON.parse(sessionData)

      return data && data[key]
    } catch (e) {
      return null
    }
  }

  scrollToPackageOptionsTop() {
    scrollToPackageOptionsTop()
  }

  btnClickInHouse(type: string) {
    const packageId = this.packageId || null
    if (type === 'addToCart') {
      if (this.isFixedBtn) {
        return `AddToCartBtn?trg=manual&oid=package_${packageId}&ext=${this.newModuleExtra}`
      } else {
        return `PackageOptionsAddToCartBtn?trg=manual&oid=package_${packageId}&ext=${this.newModuleExtra}`
      }
    } else if (this.isFixedBtn) {
      return `BookNowBtn?trg=manual&oid=package_${packageId}&ext=${this.newModuleExtra}`
    } else {
      return `PackageOptionsBookNowBtn?trg=manual&oid=package_${packageId}&ext=${this.newModuleExtra}`
    }
  }

  mounted() {
    const preOrderSessionData = sessionStorage.getSessionData(this.preOrderKey)
    if (preOrderSessionData) {
      if (this.isLoggedIn) {
        this.role = JSON.parse(preOrderSessionData).params.shoppingcart_type ? 'bookNow' : 'addToCart'
        this.bookRequest()
      }
    }
    sessionStorage.deleteSessionData(this.preOrderKey)
  }
}
