import SectionBody from '~/components/setion/experience/components/body.vue'
import SectionFooter from '~/components/setion/experience/components/footer/section-footer.vue'
import SectionHeader from '~/components/setion/experience/components/header/section-header.vue'
import SectionHoc from '~/components/setion/experience/section-hoc'
import SectionDivider from '~/components/experience-booking/experience-activity/section/component/divider.vue'

export default SectionHoc({
  SectionHeader,
  SectionBody,
  SectionFooter,
  SectionDivider
})
