






import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class Any extends Vue {
  @Prop({ type: String, default: 'desktop' }) platform!: string
}
