




import { Component, Vue, State } from 'nuxt-property-decorator'
import { SectionSkeleton } from '~/components/common/skeleton-data/index.js'

@Component({})
export default class PkgOptionsLoadingV2 extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  SectionSkeleton = SectionSkeleton

  get skeletonData() {
    const { SectionSkeleton, platform } = this
    return platform === 'desktop' ? SectionSkeleton.desktop.pkgOption : SectionSkeleton.mobile.pkgOption
  }
}
