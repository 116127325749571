




















import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import BaseActivityCards from '~/components/experience-booking/experience-activity/activity-cards-list/base-cards.vue'
import ActivityCardsListWrap from '~/components/experience-booking/experience-activity/activity-cards-list/activity-cards-list-wrap.vue'

@Component({
  components: {
    BaseActivityCards,
    ActivityCardsListWrap
  }
})
export default class RecommendActivity extends BusinessBase {
  static displayName = 'Recommend_activity'

  get logoWidth() {
    const width = this.isDesktop ? 275 : 168
    return width / 4
  }

  get ratio() {
    return this.isDesktop ? '16:9' : ''
  }

  get activities() {
    const { recommend_activities_v2, recommend_activities } = this.businessData || {}
    return recommend_activities_v2 || recommend_activities
  }

  get cardType() {
    return this.activities?.card_type
  }

  get perView() {
    if ([3, 4].includes(this.cardType)) {
      return this.klook.platform === 'mobile' ? 1 : 4
    }

    return this.klook.platform === 'mobile' ? 2 : 4
  }

  get cClass() {
    return ['activity-recommend_list', `activity-recommend_list-${this.klook.platform}`]
  }

  getDataNosnippet(aid: number) {
    return [4048, 47952, 32775, 47941].includes(aid)
  }
}
