














import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class ActivityEditorChoice extends Vue {
  static displayName = 'MobileKlook_prefer'
}
