












































































































import isEmpty from 'lodash/isEmpty'
import { Component, Prop, Watch, mixins } from 'nuxt-property-decorator'
import HeaderTips from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/header-tips.vue'
import CardHeader from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/card-header.vue'
import BookingCalendar from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/booking-calendar.vue'
import BookingUnit from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/booking-unit.vue'
import DesktopBookingBtns from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/booking-btns.vue'
import ExpLoadingState from '~/components/experience/loading-state/index.vue'
import BookingOptions from '~/components/booking-core/mixins/entry/index-base-sku'

import ActivityValidity from '~/components/experience-booking/experience-activity/open-date-ticket/activity-validity/index.vue'
import HasOpenDateTips from '~/components/experience-booking/experience-activity/package-options/desktop/has-open-date-tips.vue'
import PriceDiscountTips from '~/components/experience/price-slot/discount-tips.vue'
import SavingPriceDetails from '~/components/experience/saving-price-details/index.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import DesktopPackageDetailButton from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-button.vue'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'
import dataUtils from '~/components/booking-core/utils/data-utils'

export interface openPayload {
  skuId: number
  packageId: number
  date: string
}

@Component({
  components: {
    DesktopPackageDetailButton,
    SavingPriceDetails,
    PriceDiscountTips,
    ActivityValidity,
    HasOpenDateTips,
    HeaderTips,
    CardHeader,
    ExpLoadingState,
    BookingCalendar,
    BookingUnit,
    DesktopBookingBtns,
    CalendarLengends,
    CalenderTips
  }
})
export default class PackageOptionsBooking extends mixins(BookingOptions) {
  @Prop() modificationShoppingCart!: boolean
  @Prop() pkgDetailData!: any
  @Prop({ type: Number, default: 0 }) skuId!: number
  @Prop({ type: String, default: '' }) date!: string
  @Prop({ type: Boolean, default: false }) isUnfold!: boolean
  @Prop({ type: Boolean, default: false }) groupWeb!: boolean
  @Prop() businessData!: any
  @Prop({ default: '' }) inhouseModule!: string

  get calcPkgDetailTrack() {
    const { packageId } = this
    return {
      type: 'module',
      exposure: false,
      spm: 'PackageDetailsBtn',
      query: {
        oid: `package_${packageId}`
      }
    }
  }

  @Watch('packageSchedulesAndUnitsApiDefined.state')
  schedulesUnitsStateWatch(v: string) {
    this.$emit('updateSchedulesUnitsState', v)
  }

  @Watch('pkgSelectedTime', { immediate: true })
  async selectedTimeChange(val: any, old: any) {
    if (val && val.arrangementId) {
      if (old?.arrangementId && val.arrangementId === old.arrangementId && this.platform === 'mobile') {
        return
      }
      await this.fetchPackageUnits()
    }
  }

  @Watch('isUnfold')
  isUnfoldWatch() {
    this.initData()
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get calcPkgPreSettlementTips() {
    return this.getPkgPreSettlementTips2getters(this.packageId)
  }

  @Watch('pkgSelectedDate')
  packageSelectedDateWatch(str: string) {
    this.calcActIsNewOpenTicket || this.$emit('updateDate', str) // open ticket不用同步日期过滤条件
  }

  get bookingCalendarOptions() {
    return {
      hideTips: true,
      packageId: this.packageId,
      schedules: this.calcPkgScheduleList,
      warn: this.dateDescription,
      selectedTime: this.pkgSelectedTime,
      currentSelectedPackage: this.calcPkgSelectedObj,
      calcActIsNewOpenTicket: this.calcActIsNewOpenTicket,
      isNewOpenTicket: this.calcPkgIsNewOpenTicket,
      legends: this.calcPkgLegendList,
      inhouseModule: this.inhouseModule
    }
  }

  get bookingUnitOptions() {
    return {
      customPreType: 'unfold-pre-type',
      packageId: this.packageId,
      styleType: 'desktop-type',
      selectedTime: this.pkgSelectedTime,
      unitList: this.currentUnits,
      preview: this.isPreview ? 1 : 0,
      currentSelectedPackage: this.calcPkgSelectedObj,
      inventories: this.calcUnitInventorieObj,
      totalUnitCount: this.calcTotalUnitCount,
      disabled: !this.calcArrangementId
    }
  }

  get readyPrePrice() {
    if (!this.calcArrangementId || isEmpty(this.currentUnits)) {
      return false
    }
    const count = (this.currentUnits || []).reduce((num: number, obj: any) => {
      num = num + (obj.count || 0)
      return num
    }, 0)
    return count > 0
  }

  get bottomBarBinds() {
    return {
      packageId: this.packageId,
      readyPrePrice: !!this.readyPrePrice,
      isUnfold: this.isUnfold,
      modificationShoppingCart: this.modificationShoppingCart,
      hideDiscountTips: this.bsObjSavePrice?.visible,
      businessData: this.businessData,
      units: this.currentUnits,
      currentSelectedPackage: this.calcPkgSelectedObj,
      selectedTime: this.pkgSelectedTime,
      packageSchedules: this.calcPkgScheduleList,
      packageSelectedDate: this.pkgSelectedDate,
      totalUnitCount: this.calcTotalUnitCount
    }
  }

  // 去掉loading是因为，在action中插入loading会造成全局的抖动
  changeUnitCounts(payload: any) {
    this.changeUnitCount(payload, { hideLoading: true })
  }

  async initData() {
    const { packageId, date, skuId }: openPayload = this
    this.packageId = packageId
    this.preSelectedSkuId = skuId
    this.pkgSelectedDate = date || ''
    if (this.packageId) {
      await this.getPackagePriceScheduleUnits({}, { fromKey: dataUtils.FromKeyDefined.desktop.sku })
    }
  }
}
