









import { Component, Prop } from 'nuxt-property-decorator'
import optionsUnitsTips from '~/components/traveller/activity/activity-base/optionsUnitsTips'

@Component
export default class HeaderTips extends optionsUnitsTips {
  @Prop() text!: string
  @Prop({ type: Boolean, default: false }) hideDivider!: boolean

  get calcText() {
    return this.tips
  }
}
