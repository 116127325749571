
















































import { Component, Prop, State, Vue } from 'nuxt-property-decorator'
import VideoPlayer from '../../components/video-player/index.vue'

@Component({
  components: {
    VideoPlayer
  }
})
export default class ActivityBanner extends Vue {
  @State klook!: Data.Klook

  @Prop() bannerList!: string[]
  @Prop() originBanner!: any[]
  @Prop() activityId!: number | string

  get width() {
    return this.imagesObject?.right?.length ? 842.7 : 1160
  }

  getSpm(index: any) {
    const data = this.originBanner[index] || {}
    const isVideo = !!data.video_url
    const url = isVideo ? data.video_url : this.bannerList[index]
    const ext = {
      url,
      type: isVideo ? 'Video' : 'Photo'
    }
    return `BannerPhoto?oid=activity_${this.activityId}&ext=${this.$getExt(ext)}`
  }

  // 初次渲染的时候不显示背景
  isMounted: boolean = false
  mounted() {
    this.isMounted = true
  }

  showVideo: boolean = false
  videoUrl: string = ''
  showVideoPlayer(url: string) {
    this.showVideo = true
    this.videoUrl = url
  }

  bannerClick(index: number) {
    const bannerList = this.originBanner || []
    const videoUrl = bannerList[index]?.video_url ?? ''
    if (videoUrl) {
      this.showVideoPlayer(videoUrl)
      return
    }
    this.$emit('banner-click', index)
  }

  closeVideo() {
    this.showVideo = false
  }

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get videoIcon() {
    return this.isDesktop ? 'desktop-common#btn-video-normal-new' : 'mobile-activity#banner-video-play'
  }

  get imagesObject() {
    const bannerList = this.bannerList || []
    if (bannerList.length < 3) {
      let specCrop = bannerList[0] || ''
      const originItem = this.originBanner[0]
      if (originItem?.pre_process?.includes('u_activities:')) {
        specCrop = specCrop?.replace(/ar_(\d+):(\d+)/, 'ar_1160:474') // 不裁剪
      }
      return {
        left: specCrop,
        right: []
      }
    }
    return {
      left: bannerList[0],
      right: [bannerList[1], bannerList[2]]
    }
  }
}
