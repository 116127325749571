






import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { BaseBlockSkeleton, PkgDetailsSkeleton } from '~/components/common/skeleton-data/index.js'

@Component({
  components: {
  }
})
export default class PackageCardPlaceholderList extends Vue {
  @Prop() packageList!: any[]

  PkgDetailsSkeleton = PkgDetailsSkeleton

  get list() {
    const length = this.packageList?.length || 1
    return Array(length).fill(0)
  }

  get BaseBlockSkeleton() {
    return {
      ...BaseBlockSkeleton,
      vfor: this.list?.length
    }
  }
}
