



















import { Component, Vue, Ref, Prop, State } from 'nuxt-property-decorator'

@Component
export default class AttractionPreviewBox extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  @Prop() activityId!: number
  @Prop() packageId!: number
  @Prop({ default: false }) auto!: boolean
  @Ref('contentRef') contentRef!: HTMLElement
  @Prop({ default: false }) loading!: boolean
  @Prop() title!: string

  contentHeight = '560px'
  isOverflow = false
  isExpanded = false
  style = {}

  mounted() {
    setTimeout(() => {
      this.checkOverflow()
    }, 50)
    window.addEventListener('openDesktopCalendar', this.openDesktopCalendar)
  }

  beforeDestroy() {
    window.removeEventListener('openDesktopCalendar', this.openDesktopCalendar)
  }

  openDesktopCalendar(event: Event) {
    const customEvent = event as CustomEvent
    const { packageId, value } = customEvent.detail
    // 只需处理有折叠按钮的情况，打开日历的时候同时展开,当前关闭的时候才进行展开
    if (this.isOverflow && this.packageId === packageId && value && !this.isExpanded) {
      this.toggleContent()
    }
  }

  toggleContent() {
    this.isExpanded = !this.isExpanded
    this.contentHeight = this.isExpanded ? 'auto' : '560px'
    this.$nextTick(() => {
      const style: any = { height: this.isExpanded ? 'auto' : '560px' }
      if (this.isOverflow) {
        style.overflow = this.isExpanded ? 'inherit' : 'hidden'
      }

      this.style = style
    })
  }

  checkOverflow() {
    if (this.contentRef) {
      // 如果只有一个默认展开
      if (this.auto) {
        this.style = { height: 'auto', paddingBottom: '16px' }
      } else {
        this.isOverflow = this.contentRef.scrollHeight > 560
        // 大于560
        if (this.isOverflow) {
          this.style = { height: this.contentHeight, overflow: 'hidden' }
        } else {
          this.style = { height: 'auto', paddingBottom: '16px' }
        }
      }
    }
  }
}
