





import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class DynamicCollapseMarkdown extends Vue {
  @Prop({ default: null }) content: any
}
