















































import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import LineUpBottomBar from '../line-up-bottom-bar/index.vue'

import ActivityBottomBooking from './activity-bottom-booking.vue'
import ActivityPackageDetailGuideButton
  from './activity-guide-button/package-detail-guide-button.vue'
import PackageOptionsGuideButton
  from './activity-guide-button/package-options-guide-button.vue'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'
import CutDown from '~/components/experience/cut-down/index.vue'
import { isLessThanTargetTime } from '~/share/count-down'
import HeroPageBottomBar from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/hero-page-bottom-bar/index.vue'

import { EasyIntersectionObserver } from '~/share/utils'

@Component({
  components: {
    ActivityBottomBooking,
    ActivityPackageDetailGuideButton,
    PackageOptionsGuideButton,
    LineUpBottomBar,
    WarmingSlogan,
    CutDown,
    HeroPageBottomBar
  }
})
export default class BottomBar extends Vue {
  // 排队
  @Prop({ default: () => {} }) basicData!: any
  @Prop({ default: () => [] }) packages!: any

  showCutDown = false
  timeList = []

  @Watch('packageId', { immediate: true })
  onPackageIdChange(val: any) {
    if (val) {
      const packageDetail = this.packages.find((item: any) => item.package_id === val)
      if (packageDetail) {
        const discountTags = packageDetail?.product_tags?.discount_tags || []
        this.getCutDownTimeList(discountTags)
      }
    } else {
      this.clear()
    }
  }

  get isHeroPage() {
    const basicData = this.basicData as any
    const act_product_type = basicData?.act_product_type ?? ''
    return act_product_type === 'hero_page'
  }

  // hero page 获取最低价格套餐
  get lowestPricePackage() {
    if (!this.isHeroPage) {
      return null
    }
    const packages = this.packages || []
    const first = packages[0] || null
    if (!first) {
      return null
    }
    let min = +first.from_price
    let currentPackage = first
    packages.forEach((item: any) => {
      const from_price = +(item.from_price || 0)
      if (from_price < min) {
        currentPackage = item
        min = from_price
      }
    })
    return currentPackage
  }

  get packageId() {
    return this.$attrs?.['package-id']
  }

  get lineUp() {
    return this.basicData.is_in_flash_sale
  }

  get lineUpBook() {
    return this.basicData.is_line_page
  }

  get isActivityPublishInfo() {
    // 如果没有选择套餐，那么就使用 activity 层级的 publishInfo
    return !(this.$attrs?.['selected-package'] as any)?.package_id
  }

  get publishInfo() {
    if (this.isActivityPublishInfo) {
      return this.basicData?.publish_info
    }
    return (this.$attrs?.['selected-package'] as any)?.publish_info
  }

  obServer: EasyIntersectionObserver | null = null

  showHeroBottom: boolean = false

  target = '.package_options_spec_type_left'

  detailHeight = 0

  mounted() {
    this.createObserver()
  }

  beforeDestroy() {
    if (this.obServer) {
      this.obServer.disconnect()
      this.obServer = null
    }
  }

  createObserver() {
    if (this.isHeroPage) {
      this.obServer = new EasyIntersectionObserver(this.target, ([{ isIntersecting, boundingClientRect }]) => {
        const { top } = boundingClientRect
        this.showHeroBottom = !isIntersecting && top > 0
      }, {
        threshold: 0,
        rootMargin: '-60px'
      })
    }
  }

  getCutDownTimeList(val: any) {
    if (val?.length) {
      const list: any = []

      val.forEach((item: any) => {
        const endTime = item?.inner_vars?.rate_plan?.count_down_end_time
        if (endTime) {
          const flag = isLessThanTargetTime(endTime)
          flag && list.push(endTime)
        }
      })

      if (list.length) {
        this.showCutDown = true
        this.timeList = list
      } else {
        this.clear()
      }
    } else {
      this.clear()
    }
  }

  reload() {
    this.showCutDown = false
    window && window.location.reload()
  }

  clear() {
    this.showCutDown = false
    this.timeList = []
  }
}
