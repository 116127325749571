import { Component, Vue, Prop, Watch, Getter } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'

interface PackageTimeslotItem {
  session_id: string
  games: any[]
  inventory: number
}

interface SelectedPackageTimeslot {
  shoppingcart_guid?: string
  session_id: string
  origin_session_label: string
  games: any[]
}

@Component
export default class PackageTimeslotBase extends Vue {
  @Getter isMobile!: boolean
  @Prop({ default: '' }) guid?: string
  @Prop({ default: 0 }) packageId!: number
  @Prop({ default: '' }) startTime!: string
  @Prop({ default: () => ([]) }) skus!: Record<string, number>[]
  @Prop({ default: null }) selectedItem!: SelectedPackageTimeslot
  @Prop({ default: false }) outOfStockGoBack?: boolean
  @Prop({ default: 'Payment' }) pageType?: string

  errorMessage = ''
  timeslotList: PackageTimeslotItem[] = []
  popVisible = false
  loading = false
  isError = false
  // 弹窗里面选中的值
  selectedItemInPop: SelectedPackageTimeslot | null = null

  formData = {
    timeslot: ''
  }

  get selectedIdInPop() {
    return this.selectedItemInPop?.session_id || null
  }

  get skuCount() {
    return (this.skus || []).reduce((accu, curr) => {
      return accu + (curr?.count || 0)
    }, 0)
  }

  @Watch('selectedItem', { immediate: true })
  selectedItemChange(val: any) {
    this.formData.timeslot = (val?.games || []).reduce((accu: string, curr: any) => {
      const time = this.addSpace(curr?.time_range_label)
      const other = this.addSpace(curr?.texts?.join(' ') || '')
      const text = `${curr?.name}${time}${other}`
      return `${accu}${this.addSpace(text)}`
    }, '').trim()
  }

  @Watch('popVisible')
  popVisibleChange(v: boolean) {
    if (v) {
      this.$inhouse.track('pageview', '.js-package-timeslot-page', { force: true })
    }
  }

  async handleOpenPop() {
    this.errorMessage = ''
    this.isError = false
    this.popVisible = true
    this.selectedItemInPop = this.selectedItem
    await this.getPackageTimeslot()
    this.scrollCheckedIntoView()
  }

  getPageSpm() {
    return `USJ_Timeslot?trg=manual&oid=package_${this.packageId}&ext=${encodeURIComponent(JSON.stringify({
      ParticipationDate: this.startTime,
      Type: this.pageType,
      SkuCount: this.skuCount
    }))}`
  }

  handleClosePop() {
    const { session_id, origin_session_label } = this.selectedItemInPop || {}
    if (session_id && session_id !== this.selectedItem?.session_id && origin_session_label !== this.selectedItem?.origin_session_label) {
      this.$emit('select', this.selectedItemInPop)
    }
    this.popVisible = false
  }

  handleSelectChange(item: any) {
    this.selectedItemInPop = {
      ...item,
      shoppingcart_guid: this.guid
    }
    // this.handleClosePop()
  }

  getPackageTimeslotParams() {
    const { packageId, startTime, skus, guid } = this
    return {
      shoppingcart_guid: guid,
      package_id: packageId,
      start_time: startTime,
      skus
    }
  }

  async getPackageTimeslot() {
    this.loading = true
    const item = this.getPackageTimeslotParams()
    const res = await this.$axios.$post(apis.getSupplierTimeslots, {
      items: [item]
    })
    this.loading = false

    if (res?.success && res?.result) {
      const target = (res?.result?.items || []).find((item: any) => item.package_id === this.packageId)
      this.timeslotList = target?.items || []

      // 如果所选id在列表中没有，则重置掉
      if (!this.timeslotList.find(item => item.session_id === this.selectedItem?.session_id)) {
        this.resetPackageTimeslot()
      }

      this.$inhouse.track('custom', '.js-package-timeslot-page', {
        spm: 'USJ_Timeslot.USJ_Timeslot_Number',
        ObjectId: `package_${item.package_id}`,
        ParticipationDate: this.startTime,
        Type: this.pageType,
        TimeslotId: this.selectedItem?.session_id,
        TimeSlotNumber: this.timeslotList?.length || 0
      })
    } else {
      this.timeslotList = []
      if (['P52116', 'P52117'].includes(res?.error?.code) && this.outOfStockGoBack) {
        this.$alert(res?.error?.message, '', {
          okLabel: this.$t('13876')
        }).then(() => {
          if (process.client) {
            window.history.back()
          }
        })
      } else {
        this.errorMessage = res?.error?.message || this.$t('client_server_failure')
        this.$toast(this.errorMessage)
      }

      this.$inhouse.track('custom', '.js-package-timeslot-page', {
        spm: 'USJ_Timeslot.USJ_timeslot_error',
        ObjectId: `package_${item.package_id}`,
        ParticipationDate: this.startTime,
        Type: this.pageType,
        TimeslotId: this.selectedItem?.session_id,
        ErrorCode: res?.error?.code,
        ErrorMessage: res?.error?.message
      })
    }
  }

  scrollCheckedIntoView() {
    if (process.client) {
      this.$nextTick(() => {
        const checkedDom = document.querySelector('.package-timeslot-group .package-timeslot-group-wrap.selected')
        checkedDom && checkedDom.scrollIntoView({ block: 'center' })
      })
    }
  }

  resetPackageTimeslot() {
    this.$emit('select', {
      shoppingcart_guid: this.guid,
      session_id: ''
    })
    this.selectedItemInPop = null
  }

  getSelectedItem() {
    return this.selectedItem
  }

  addSpace(text: string) {
    return text ? ` ${text}` : ''
  }
}
