


















import { Prop, Vue, Component } from 'nuxt-property-decorator'

@Component
export default class DynamicDrawer extends Vue {
  @Prop() title!: string
  @Prop() drawerWidth!: string
  @Prop({ default: false }) visible!: boolean

  get drawerVisible() {
    return this.visible
  }

  set drawerVisible(val: boolean) {
    this.$emit('update:visible', val)
  }
}
