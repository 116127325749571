























import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import ReviewsModal from '~/components/experience-booking/experience-activity/review/mobile/reviews-modal/index.vue'
import CommonScore from '~/components/experience-booking/experience-activity/score-participants/common-score.vue'

@Component({
  components: {
    CommonScore,
    ReviewsModal
  }
})
export default class ExperienceScoreParticipants extends BusinessBase {
  static displayName = 'MobileScore_participants'

  visible = false

  get calcSubScoreList() {
    return this.businessData?.act_sub_score || []
  }

  get basicData() {
    return this.$attrs['basic-data'] || null
  }

  viewMore() {
    this.visible = true
  }
}
