





























import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import Logo from '~/components/experience/card-swiper/logo.vue'
import ImageGallery from '~/components/experience-booking/experience-activity/banner/components/image-gallery/index.vue'

type Transition = 'fade' | 'slide';

@Component({
  components: {
    ImageGallery,
    Logo
  }
})
export default class ActivityImagePreviewDesktop extends Vue {
  transition: Transition = 'slide'
  showPreview: boolean = false
  previewIndex: number = -1
  @Prop() comment!: any
  @Prop({
    default: []
  })
  readonly reviewImage!: any

  @Watch('reviewImage')
  reviewImageChange() {
    this.hidePreview()
  }

  get activityId() {
    return this.comment?.activity_id
  }

  highLightImg(index: number) {
    return [this.showPreview && this.previewIndex === index ? 'active' : this.showPreview ? 'deactive' : '']
  }

  handleShowPreview(index: number) {
    this.showPreview = true
    this.previewIndex = index
  }

  hidePreview() {
    this.showPreview = false
  }

  swiperChange(index: number) {
    this.previewIndex = index
  }
}
