import { Vue, Component } from 'nuxt-property-decorator'
// import dataUtils from '~/components/booking-core/utils/data-utils'
// import * as dataTypesUtils from '~/components/booking-core/utils/types'
@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  isPackageSchedule: boolean = true // 标记是否为套餐日历价格库存，否则为活动日历价格库存
  setExpPkgScheduleType(type: string) {
    // 设置日历价格库类型方法
    this.isPackageSchedule = type === 'packageSchedules'
  }

  // unit层级数据 start
  preSelectedSkuId: number = 0 // 标记选中 sku
  // unit层级数据 end
  // 活动层级数据 start
  // 活动层级数据 end

  // 套餐层级数据 start
  pkgSelectedDate = '' // 当前选中的日期
  pkgSelectedTime: any = {} // 当前选中

  setExpPkgSelectedTime(data?: any) {
    // 设置选中 timeslot 数据
    this.$set(this, 'pkgSelectedTime', data || {})
  }

  get calcArrangementId() {
    // arrangementId
    return this.pkgSelectedTime?.arrangement_id || this.pkgSelectedTime?.arrangementId
  }

  get calcMaxPurchased() {
    // 套餐的unit合计最大可购买数量
    return this.pkgSelectedTime?.maxPurchased
  }
  // 套餐层级数据 end
}
