

















import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import ActivityCardsListWrap
  from '~/components/experience-booking/experience-activity/activity-cards-list/activity-cards-list-wrap.vue'
import RwdSwiper from '~/components/experience/rwd-swiper/index.vue'
import BaseActivityCards from '~/components/experience-booking/experience-activity/activity-cards-list/base-cards.vue'

@Component({
  components: {
    ActivityCardsListWrap,
    RwdSwiper,
    BaseActivityCards
  }
})
export default class SameCityActivity extends BusinessBase {
  static displayName = 'Same_city_activity'

  get activities() {
    const { same_city_activities } = this.businessData || {}
    return same_city_activities
  }
}
