
































import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import ExpPioItem from '~/components/experience-booking/experience-activity/activity-cards-list/poi-theme-list/item.vue'
import { openDeeplink } from '~/components/experience-booking/booking-details/utils'
import BaseActivityCards from '~/components/experience-booking/experience-activity/activity-cards-list/base-cards.vue'

@Component({
  components: {
    BaseActivityCards,
    ExpPioItem
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop() list!: any[]
  @Prop({ type: Boolean, default: false }) lazyLoad!: boolean

  get calcList() {
    return this.list || []
  }

  get desktopList() {
    return this.calcList.reduce((acc, curr, index) => {
      const idx = Math.floor(index / 5)
      if (Array.isArray(acc[idx])) {
        acc[idx].push(curr)
      } else {
        acc[idx] = [curr]
      }
      return acc
    }, [])
  }

  clickHandler(item: any) {
    const { deep_link } = item || {}
    if (!deep_link) {
      return
    }
    openDeeplink(deep_link, this.klook.platform)
  }
}
