




















import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import PrefixImgNew from '~/components/experience/prefix-img/index-new.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    PrefixImgNew
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: Object, default: null }) poiObj!: any
  @Prop({ type: Boolean, default: false }) lazyLoad!: boolean

  get calcImg() {
    const { url: src, alt } = this.poiObj?.image || {}
    return { src, alt }
  }

  get target() {
    return this.klook.platform === 'desktop' ? '_blank' : '_self'
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }
}
