








import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import { merge } from 'lodash'

const TypeConfig: any = {
  overlay: {
    showOverlay: true,
    overlayColor: 'rgba(255, 255, 255, 0)'
  }
}

@Component
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: Boolean, default: false }) hideLoadingIcon!: boolean
  @Prop({ type: String, default: '' }) type!: string | 'overlay'
  @Prop({ type: Boolean, default: undefined }) showOverlay!: boolean
  @Prop({ type: Boolean, default: undefined }) overlayColor!: boolean

  get calcObj() {
    const { type, showOverlay, overlayColor } = this
    const conf = TypeConfig[type] || {}
    return merge({}, conf, { showOverlay, overlayColor })
  }
}
