import { render, staticRenderFns } from "./booking-units-loading.vue?vue&type=template&id=20656ac0&scoped=true&"
import script from "./booking-units-loading.vue?vue&type=script&lang=ts&"
export * from "./booking-units-loading.vue?vue&type=script&lang=ts&"
import style0 from "./booking-units-loading.vue?vue&type=style&index=0&id=20656ac0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20656ac0",
  null
  
)

export default component.exports