




import { Component, State, Vue, Prop } from 'nuxt-property-decorator'
import { SubSectionComponentTitle } from '../type'

@Component({
  components: {
    //
  }
})
export default class SubTitle extends Vue {
  @Prop({ type: Object, default: {} }) data!: SubSectionComponentTitle['data']
  @State klook!: Data.Klook

  get title() {
    return this.data?.content || ''
  }
}
