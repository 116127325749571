














import { Component, Vue, namespace, State, Prop } from 'nuxt-property-decorator'

const ActivityModule = namespace('traveller/activity')

@Component
export default class ActivityCalenderSwitch extends Vue {
  @State klook!: Data.Klook
  @Prop() isPackagePrice!: boolean
  @ActivityModule.Action getPackagePriceSchedule!: Function
  @ActivityModule.State activityId!: number
  @ActivityModule.State templateId!: number

  async handleCalendarChange(val: true) {
    this.$emit('switchCalendarLoading', true)

    if (val) {
      const success = await this.getPackagePriceSchedule()

      if (success) {
        this.setIsPackagePrice(true)
      }
    } else {
      this.setIsPackagePrice(false)
    }

    // loading组件有.45s的动画，希望在动画结束之后更改页面结构
    const timer = setTimeout(() => {
      this.$emit('switchCalendarLoading', false)
      clearTimeout(timer)
    }, 500)

    const eventAction = val ? 'SelectedPackageCalendarOn_Click' : 'SelectedPackageCalendarOff_Click'
    this.$sendGTMCustomEvent(`Activity Page|${eventAction}|${this.activityId}|${this.templateId}`)
  }

  setIsPackagePrice(val: boolean) {
    const timer = setTimeout(() => {
      this.$emit('isPackagePrice', val)
      clearTimeout(timer)
    }, 450)
  }
}
