



























import { Component, Vue, Prop } from 'nuxt-property-decorator'

interface PriceData {
  underline_price: string // 划线价
  from_price: string // 起始价
  sale_price: string // 售卖价（有可能不是价格，也可能是暂停销售）
  save_price: string // 节省的价格
  free_text: string
}

@Component
export default class CardPrice extends Vue {
  @Prop({ type: Object, default: () => ({}) }) priceData!: PriceData
  @Prop({ type: Number, default: 14 }) priceFromFontSize!: number
  @Prop({ type: Boolean, default: false }) isSoldOut!: boolean
  @Prop({ type: Boolean, default: false }) isDisable!: boolean

  get fromPrice() {
    const price = this.$t('activity_detail_from_price', [20, this.priceData.from_price])
    if (this.isDisable) {
      return price.replace('#333333', '#A8A8A8;font-weight: 400')
    }
    return price.replace('#333333', '#333333;font-weight: 400')
  }
}
