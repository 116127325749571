








import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import reopenContent from '~/components/experience-booking/experience-activity/reopen/content.vue'

@Component({
  components: {
    reopenContent
  }
})
export default class ActivityReopen extends BusinessBase {
  static displayName = 'Reopen'

  handleDisappear() {
    this.hideSection && this.hideSection()
  }
}
