import { render, staticRenderFns } from "./dynamic-vertical-icons.vue?vue&type=template&id=f657a0a8&scoped=true&"
import script from "./dynamic-vertical-icons.vue?vue&type=script&lang=ts&"
export * from "./dynamic-vertical-icons.vue?vue&type=script&lang=ts&"
import style0 from "./dynamic-vertical-icons.vue?vue&type=style&index=0&id=f657a0a8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f657a0a8",
  null
  
)

export default component.exports