




























































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { IconChevronDown } from '@klook/klook-icons'
import PackageCard from './group-package-card.vue'
import LoadingError from './loading-error.vue'
import { IPackageItem } from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/package-card-list/type'
import eventBus from '~/pages/experience/pay/common/event-bus'

@Component({
  components: {
    PackageCard,
    IconChevronDown,
    LoadingError
  }
})
export default class PackageCardRenderList extends Vue {
  @Prop({ default: 0 }) preview!: number
  @Prop() date!: string
  // 列表的描述
  @Prop({ default: {} }) packagesGroupDesc!: any
  // 分组的详细信息
  @Prop({ default: [] }) packageDescList!: any
  @Prop() packages!: IPackageItem[]
  // 可用的日期
  @Prop() packagesInSchedule!: IPackageItem[]
  @Prop() getBookingCore!: () => void
  @Prop() index!: number
  @Prop() getPackageSchedulesUnits!: (id: number) => void
  @Prop() businessData!: any

  close = false
  state: string = 'success'
  height = 'auto'

  get packagesGroupDescList() {
    return this.packagesGroupDesc.desc || []
  }

  get id() {
    return `group-render-list-${this.packagesGroupDesc.track_id}`
  }

  get isOperations() {
    return this.packagesGroupDesc?.is_ops
  }

  get limitedAvailablePackageList() {
    // 可以用id
    const ids = (this.packagesInSchedule || []).map((item) => item.package_id)
    if (this.close) {
      // 关闭的时候需要确保 第一个是可以用的，需要按照status的枚举进行排序，available在前面
      return this.packages.filter((item) => ids.includes(item.package_id)).slice(0, 1) || []
    } else {
      return this.packages.filter((item) => ids.includes(item.package_id)) || []
    }
  }

  get total() {
    const ids = (this.packagesInSchedule || []).map((item) => item.package_id)
    return (this.packages.filter((item) => ids.includes(item.package_id)) || []).length
  }

  get showMore() {
    return this.$t('100504', { num: this.total })
  }

  toggleChange() {
    this.close = !this.close
    this.$nextTick(() => {
      if (this.close) {
        eventBus.$emit('group-on-active', this.packagesGroupDesc.track_id)
      }
    })
  }

  getSpm(item: any, index: number, length: number) {
    const ext = { GroupTitle: this.packagesGroupDesc.name, Groupid: this.packagesGroupDesc.track_id }
    return `PackageType_LIST?oid=package_${item.package_id}&idx=${index}&len=${length}&ext=${this.$getExt(
      ext
    )}&mod=stop&evt=click&trg=manual`
  }

  mounted() {
    this.close = this.index !== 0
  }
}
