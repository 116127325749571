













import { Vue, Component, State } from 'nuxt-property-decorator'
import KlookExperienceCard from '@klook/klook-experience-card'
import '@klook/klook-experience-card/dist/esm/index.css'

// enum ThemeEnum {
//   MODERN = 'modern',
//   NORMAL = 'normal'
// }

@Component({
  components: {
    KlookExperienceCard
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  // *** 下面的props直接透传即可 start
  // @Prop() cardData!: any
  // // 卡片裁剪类型
  // @Prop() imageType!: string
  // // 卡片类型
  // @Prop({ default: 'large' }) type!: 'large' | 'small' | 'no-limit'
  // @Prop({ default: ThemeEnum.NORMAL }) theme!: ThemeEnum
  // @Prop() cardType!: number
  // @Prop() logoWidth!: number
  // @Prop({ type: Boolean, default: false }) needBanner!: boolean
  // *** 下面的props直接透传即可 end

  get calcBindData() {
    const excludeAttr = ['data-spm-module', 'data-module']
    const vBindObj: any = {}

    ;(Object.entries(this.$attrs) || []).forEach(([k, v]) => {
      if (!excludeAttr.includes(k)) {
        vBindObj[k] = v
      }
    })
    return vBindObj
  }
}
