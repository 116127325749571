




















import { Component, Prop, Vue, State } from 'nuxt-property-decorator'

@Component
export default class Footer extends Vue {
  @State klook!: Data.Klook
  @Prop() moreTitle!: string
  @Prop() moreDeepLink!: string

  get link() {
    return this.moreDeepLink ? this.$href(this.moreDeepLink) : 'javascript:void(0);'
  }

  get target() {
    return this.klook.platform === 'desktop' ? { target: '__blank' } : {}
  }
}
