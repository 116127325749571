





















































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IconChevronDown, IconChevronUp } from '@klook/klook-icons'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import ExpTagCredits from '~/components/experience/tag-credits/index.vue'
import { Itrial } from '~/components/experience-booking/experience-activity/combo-option/booking-options/type'
import PaymentDetail from '~/components/experience-booking/experience-activity/combo-option/booking-options/components/paymentDetail/index.vue'
import { formatCurrencySymbolPriceThousands } from '~/share/utils'
import KlkCredit from '~/components/experience/klk-credit/index.vue'

@Component({
  components: {
    ExpTagCredits,
    IconChevronDown,
    IconChevronUp,
    PaymentDetail,
    KlkCredit
  }
})
export default class Price extends Vue {
  @Prop() priceDetail!: Itrial
  @Prop({ default: {} }) klook!: Data.Klook
  @Prop() openPaymentDetail!: () => void
  @Prop() totalUnitCount!: number

  expanded = false

  poptipDef = {
    width: 400,
    maxHeight: 0,
    trigger: 'hover',
    preventOverflow: true
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  toggle() {
    this.expanded = !this.expanded
    if (this.expanded) {
      this.track()
    }
    this.openPaymentDetail()
  }

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get calcShowPrice() {
    if (
      (this.priceDetail.total_price || typeof this.priceDetail.total_price === 'number') &&
      this.totalUnitCount > 0
    ) {
      return true
    }
  }

  get calcShowcredit() {
    if (this.totalUnitCount > 0 && this.priceDetail.total_credit > 0) {
      return true
    }
    return false
  }

  get calcPrice() {
    return formatCurrencySymbolPriceThousands(this.klook.currency, this.priceDetail.total_price)
  }

  get calcShowDetail() {
    return this.totalUnitCount > 0 && this.priceDetail?.units_info && this.priceDetail?.units_info.length > 0
  }

  get getCalcPreTotalPrice() {
    if (this.totalUnitCount === 0) {
      return `${this.klook.currencySymbol}-`
    }
    return this.priceDetail.total_price ? `${this.klook.currencySymbol}` : `${this.klook.currencySymbol}-`
  }

  track() {
    this.$inhouse.track('action', this.$refs.priceDetail)
  }

  poptipChange() {
    this.expanded = !this.expanded
    if (this.expanded) {
      this.track()
    }
  }
}
