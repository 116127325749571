
























































import { Vue, Component, State, Prop, Ref } from 'nuxt-property-decorator'
import desktopDetailContentOfSection
  from '~/components/traveller/activity/activity-package-options/desktop/package-detail/detail-content-of-section.vue'
import ActivityValidity from '~/components/experience-booking/experience-activity/open-date-ticket/activity-validity/activity-validity-new.vue'
import AttractionIncluded from '~/components/experience-booking/experience-activity/package-options/attraction-included/desktop/index.vue'
import ExpTagging from '~/components/experience/tagging/index.vue'
import ShowMoreBox from '~/components/experience-booking/experience-activity/show-more-box/index.vue'
import ViewMore from '~/components/experience-booking/activity/activity-feature-highlight/mobile/view-more.vue'
import PublishAi from '~/components/common/publish-ai/index.vue'

@Component({
  components: {
    ViewMore,
    ShowMoreBox,
    ExpTagging,
    ActivityValidity,
    desktopDetailContentOfSection,
    AttractionIncluded,
    PublishAi
  }
})

export default class PackageDetailContent extends Vue {
  @Ref() showMoreRef!: any
  @State klook!: Data.Klook
  @Prop() specStyle!: object
  @Prop() moreType!: 'desktop-type'
  @Prop() packageDetail!: any
  @Prop({ default: false }) showActivityTitle!: boolean
  @Prop({ default: 'slideDetail' }) type!: 'slideDetail' | 'moduleDetail'
  @Prop({ default: 'normal' }) attractionSize!: 'small' | 'normal'
  @Prop({ default: '230px' }) maxHeight!: string

  get activityName() {
    return this.packageDetail?.activity_name
  }

  get packageName() {
    return this.packageDetail?.package_name
  }

  get calcStyle() {
    const { moreObj, maxHeight } = this
    if (moreObj.status === 'more') {
      return {
        maxHeight,
        overflow: 'hidden'
      }
    }
    return {}
  }

  get calcTags2attribute() { // desktop活动页-unfold_card-套餐详情
    return this.packageDetail?.product_tags?.attribute_tags || []
  }

  get activityValidity() {
    return (this.packageDetail as any)?.usage_validity_render_obj || []
    // const arr = (this.packageDetail as any)?.usage_validity_render_obj || []
    // if (!arr.length) { return null }
    // return {
    //   title: (arr.find((o: any) => o.type === 'title') || {}).content,
    //   rules: arr.filter((o: any) => o.type === 'item').map((o: any) => o.content)
    // }
  }

  get passStandardActivityInfo() {
    return this.packageDetail?.pass_standard_activity_info || {}
  }

  get sections() {
    return this.packageDetail?.section_content?.sections || []
  }

  get isAiTranslation() {
    return this.translateButtonVisible && this.isTranslated
  }

  get translateButtonVisible() {
    return !!this.packageDetail?.enable_translation_button
  }

  get isTranslated() {
    return !this.packageDetail?.translation_status
  }

  moreObj: any = {
    status: ''
  }

  init() {
    const { moreObj, showMoreRef: ref, maxHeight } = this
    const mh = parseFloat(maxHeight)
    const realHeight = ref?.$el?.offsetHeight || 0
    if (realHeight) {
      moreObj.status = realHeight > mh ? 'more' : ''
    }
  }

  clickShowMore(status: string) {
    const { moreObj } = this
    moreObj.status = status
  }

  mounted() {
    this.init()
  }
}
