
















































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import CustomerImageGallery from '~/components/experience-booking/experience-activity/banner/mobile/gallery/customer-image-gallery'
import { urlTemplate, approximateThousandsNew } from '~/share/utils'
import apis from '~/share/data/apis'
import Logo from '~/components/experience/card-swiper/logo.vue'

@Component({
  components: {
    CustomerImageGallery,
    Logo
  }
})
export default class ReviewsGallery extends Vue {
  @Prop() activityId!: number
  @Prop({ default: false }) showBuyNow!: boolean
  @Prop({ type: Boolean, default: false }) fullscreen!: boolean

  visible = false
  loading = false
  total = 0
  images: any[] = []

  get countText() {
    return this.total > 4 ? `${approximateThousandsNew(this.total - 3, false)}+` : ''
  }

  get shouldShow() {
    return Number(this.total) >= 8
  }

  mounted() {
    this.getReviewsImages()
  }

  async getReviewsImages() {
    if (!this.activityId) { return }

    this.loading = true

    const res = await this.$axios.$get(urlTemplate(apis.activityReviewGalleryImages, { activityId: this.activityId }), {
      regularUrl: '/v1/usrcsrv/activities/{*}/images/get',
      params: {
        page: 1,
        limit: 8
      }
    })

    const { result } = res
    if (res.success && result) {
      this.total = result.image_total_count || 0
      this.images = (result.review_images_info || []).reduce((accu: any[], curr: any) => [...accu, ...(curr?.images || [])], [])
      this.loading = false
    }
  }

  handleBuyNow() {
    this.visible = false
    this.$nextTick(() => {
      this.$emit('buy-now')
    })
  }
}
