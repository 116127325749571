import { Component, Vue, Prop } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import { Activity } from '~/types/traveller/activity'
import apis from '~/share/data/apis'

@Component
export default class ActivityPackagePromotionBase extends Vue {
  @Prop() activityId!: number
  @Prop() templateId!: number
  @Prop() packageId!: number
  @Prop() packageSelectedDate!: string
  @Prop() validPackages!: any
  @Prop() includedPackages!: any
  @Prop() packagesInDate!: any

  cachedDate: string | null = null
  fetchStatus: Activity.fetchStatus = 'success'
  promotionList = []

  get promotionFilteredNone() {
    // 没传的话默认为 false
    if (!this.includedPackages) {
      return false
    }
    const promotionPackages = this.includedPackages.filter(
      (pkg: any) => pkg?.product_tags?.discount_tags?.length > 0
    )
    return promotionPackages.length === 0
  }

  // 获取促销套餐(弹出浮层时请求，日期变化时才需要请求)
  async getActivityPromotion() {
    const { activityId, packageSelectedDate } = this
    const params = { activity_id: activityId, date: packageSelectedDate } as any

    if (this.cachedDate === packageSelectedDate) {
      return
    }

    this.cachedDate = packageSelectedDate

    if (packageSelectedDate) {
      params.date = packageSelectedDate
    }

    this.fetchStatus = 'fetching'
    this.promotionList = []
    const { result, success } = await this.$axios.$post(
      apis.activityPromotions,
      params
    )

    if (success && !isEmpty(result)) {
      this.fetchStatus = 'success'
      this.promotionList = result.packages
    } else {
      this.fetchStatus = 'failure'
      this.cachedDate = null
    }
  }
}
