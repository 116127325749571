



















import { Vue, Component, Prop, Ref, State } from 'nuxt-property-decorator'
import ActivityValidityItem from './activity-validity-item.vue'

@Component({
  components: {
    ActivityValidityItem
  }
})
export default class Index extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Ref() actValidityRef!: any
  @Prop({
    type: String,
    default: 'none'
  })
  maxHeight!: string

  @Prop({
    type: Array,
    default: () => []
  })
  data!: any[]

  styles = {
    paddingBottom: '20px',
    maxHeight: 'none',
    overflow: 'hidden'
  }

  status = '' // more | less

  get valid() {
    return this.data?.length
  }

  get isMobile() {
    return this.platform === 'mobile'
  }

  get content() {
    return this.data.filter(item => item.type !== 'sub_title')
  }

  mounted() {
    parseFloat(this.maxHeight) > 0 && this.initStatus()
  }

  initStatus() {
    this.$nextTick(() => {
      const ref = this.actValidityRef
      if (!ref) {
        return
      }
      const { styles, maxHeight } = this
      const h = maxHeight ? parseFloat(maxHeight) : 0
      if (h && ref.offsetHeight > h) {
        this.status = 'more'
        styles.maxHeight = maxHeight
      }
    })
  }

  clickBtn() {
    const { status: oldStatus, styles, maxHeight } = this
    if (oldStatus) {
      this.status = oldStatus === 'more' ? 'less' : 'more'
      styles.maxHeight = oldStatus === 'more' ? 'none' : maxHeight
    }
  }

  get calcText() {
    const { status } = this
    let text = ''
    if (status === 'more') {
      text = this.$t('see_more')
    } else if (status === 'less') {
      text = this.$t('17843')
    }
    return text
  }
}
