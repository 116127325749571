







import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import ReviewsSection from '~/components/experience-booking/experience-activity/review/mobile/index-v1.vue'
import ReviewsSectionV2, { getPropsData2section } from '~/components/experience-booking/experience-activity/review/mobile/index-v2.vue'

@Component({
  components: {
    ReviewsSectionV2,
    ReviewsSection
  }
})
export default class Index extends BusinessBase {
  static displayName = 'MobileReviews'
  @State klook!: Data.Klook

  reviewsData: any = {}

  get calcIsv2() {
    return this.reviewsData?.version === 'v2'
  }

  created() {
    this.reviewsData = getPropsData2section(this.businessData)
  }
}
