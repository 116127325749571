













import { Component } from 'nuxt-property-decorator'
import { YouMayAlsoLikeMixins } from '../../mixins'
import BaseActivityCards from '~/components/experience-booking/experience-activity/activity-cards-list/base-cards.vue'
import ActivityCardsListWrap from '~/components/experience-booking/experience-activity/activity-cards-list/activity-cards-list-wrap.vue'

@Component({
  components: {
    BaseActivityCards,
    ActivityCardsListWrap
  }
})
export default class ActivityYmalMwebVertical extends YouMayAlsoLikeMixins {
  static displayName = 'Activity_ymal_vertical'

  get cClass() {
    return ['activity_ymal_vertical', `activity_ymal_vertical_${this.klook.platform}`]
  }
}
