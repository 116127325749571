









import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { FunctionalComponentOptions, CreateElement, RenderContext } from 'vue'

const LazyLoadImgTag: FunctionalComponentOptions = {
  functional: true,
  props: {
    imgUrl: { type: String },
    imageType: { type: String },
    ratio: { type: String },
    coverUseImgTag: { type: Boolean },
    imageAlt: { type: String, default: '' }
  },
  render(h: CreateElement, ctx: RenderContext) {
    const { imgUrl, imageType, ratio, coverUseImgTag, imageAlt } = ctx.props
    const defaultSlots = (ctx.slots().default || [])
    const commonAttrs = {
      ...ctx.data.attrs,
      ratio,
      'data-img-type': imageType
    }

    if (coverUseImgTag) {
      const img = h('img', {
        class: {
          'lazy-load-card__img': true
        },
        attrs: {
          ...commonAttrs,
          alt: imageAlt
        },
        directives: [{
          name: 'lazy', value: imgUrl
        }]
      })

      return h(
        'div',
        { slot: 'LazyLoad2ImgTag' },
        [img, ...defaultSlots]
      )
    }

    return h(
      'div',
      {
        slot: 'LazyLoad2BackgroundImage',
        attrs: commonAttrs,
        directives: [{
          name: 'lazy', value: imgUrl, arg: 'background-image'
        }]
      },
      defaultSlots
    )
  }
}

@Component({
  components: {
    LazyLoadImgTag
  }
})
export default class LazyLoadCard extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() imgUrl!: string
  @Prop() imageType!: string
  @Prop() ratio!: string
  @Prop({ type: Boolean, default: false }) coverUseImgTag!: boolean
  @Prop({ type: String, default: '' }) imageAlt!: string

  get replacedImgUrl() {
    const w = this.platform === 'desktop' ? 413 : 563 // desktop = 275 * 1.5 mobile = 375 * 1.5
    return this.imgUrl
      .replace('image/upload/', `image/upload/q_85/c_fill,w_${w}/`)
  }
}
