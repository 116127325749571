








import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class Index extends Vue {
  @Prop({ default: () => ({}) }) labelBind?: object

  labelObj = {
    type: 'secondary',
    size: 'small',
    'bg-color': '#E6F7F2',
    'text-color': '#0B9E65'
  }
}
