























import { Component, Vue, Prop, Ref } from 'nuxt-property-decorator'
import SinglePackageAttributes from '~/components/experience-booking/experience-activity/package-options/package-attributes/mobile/single.vue'
import SingleFixedAttributes from '~/components/experience-booking/experience-activity/package-options/package-attributes/mobile/single-fixed.vue'

@Component({
  components: {
    SinglePackageAttributes,
    SingleFixedAttributes
  }
})
export default class SwiperSingleAttributes extends Vue {
  @Prop() shouldPin!: boolean
  @Ref() singleAttrRef!: any
  @Ref() singleFixedAttrRef!: any

  autoSelectFirstAttr() {
    if (this.shouldPin) {
      this.singleFixedAttrRef?.autoSelectFirstAttr && this.singleFixedAttrRef.autoSelectFirstAttr()
    } else {
      this.singleAttrRef?.autoSelectFirstAttr && this.singleAttrRef.autoSelectFirstAttr()
    }
  }
}
