






import { Component, State } from 'nuxt-property-decorator'
import KlkTravellerSurvey from '@klook/klk-traveller-survey'
import '@klook/klk-traveller-survey/esm/index.css'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({
  components: {
    KlkTravellerSurvey
  }
})
export default class SurveyInfo extends BusinessBase {
  @State(state => state.klook.platform) platform!: Data.Platform

  static displayName = 'Survey_info'

  get initSurveyData() {
    return this.businessData
  }
}
