















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import Map from '~/components/traveller/activity/activity-map/map.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    Map
  }
})
export default class DynamicMapWrap extends Vue {
  @State klook!: Data.Klook
  @Prop() content!: ExperienceActivity.IDynamicAddress

  map: ExperienceActivity.IDynamicAddressData = {
    place_id: '',
    address: '',
    address_desc: '',
    image_url: ''
  }

  mapShow: number = 0

  get contentData() {
    return this.content?.data || []
  }

  showMap(item: ExperienceActivity.IDynamicAddressData) {
    this.map = item
    this.mapShow += 1
  }
}
