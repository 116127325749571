


































import { Component } from 'nuxt-property-decorator'
import ActivityFaqItem from '~/components/traveller/activity/activity-faq/mobile/activity-faq-item.vue'
import ActivityMarkdown from '~/components/traveller/activity/activity-markdown.vue'
import ActivityModal from '~/components/experience-booking/experience-activity/activity-modal/index.vue'
import ActivityFaq from '~/components/experience-booking/experience-activity/faq/faq'
@Component({
  components: {
    ActivityFaqItem,
    ActivityMarkdown,
    ActivityModal
  }
})
export default class MobileActivityFaq extends ActivityFaq {
  static displayName = 'MobileActivity_faq'

  visible = false

  get isWhiteLabelEnv() {
    return !!this.klook?.utilConfig?.isWhiteLabelEnv
  }

  showFaqLayer() {
    this.visible = true
  }

  hideFaqLayer() {
    this.visible = false
  }

  goToHelpCenter() {
    window.location.href = this.$href(this.helpCenter.link)
  }
}
