















































import { Component, Vue, Prop, Ref, Watch } from 'nuxt-property-decorator'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import { getModalConfig } from '~/components/experience/load-hander/index.vue'

@Component
export default class SeeMore extends Vue {
  @Prop({ type: Number, default: 136 }) maxHeight!: number
  @Prop({ type: String, default: 'mobile' }) platform!: Data.Platform
  @Prop({ type: String, default: '' }) title!: string
  @Prop() activityId!: string | number
  @Ref() contents!: any

  modalObj = getModalConfig('common-medium-config', { scrollable: true })
  visible: boolean = false

  get contentStyle() {
    return {
      maxHeight: this.maxHeight + 'px'
    }
  }

  get popContentStyle() {
    return this.isMobile ? { minHeight: '40vh' } : { minHeight: '40vh', paddingBottom: '40px' }
  }

  get isMobile() {
    return this.platform === 'mobile'
  }

  @Watch('visible')
  visibleChange(v: boolean) {
    if (v) {
      this.$inhouse.track('pageview', '#pop-content-wrap__page', { force: true })
    }
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get pageSpm() {
    const id = this.activityId
    return `WhatToExpect?oid=activity_${id}&trg=manual`
  }

  changeVisible() {
    if (this.isMobile) {
      this.visible = true
    }
  }

  close() {
    this.visible = false
  }

  showButton: boolean = false

  calcButtonVisible() {
    const height = this.contents.offsetHeight
    this.showButton = height >= this.maxHeight
  }

  mounted() {
    this.calcButtonVisible()
  }
}
