import { Component, mixins, Ref, Watch } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import IndexMixin from '~/components/booking-core/mixins/entry/index-base'
import AutoBookingMixin from '~/components/booking-core/mixins/common/auto-booking'
import dataUtils from '~/components/booking-core/utils/data-utils'

@Component({
  components: {
    //
  }
})
export default class Index extends mixins(IndexMixin, AutoBookingMixin) {
  @Ref() activityBookingRef!: any
  @Ref() attrWrap!: Vue
  @Ref() switchableActivityCalendar!: Vue

  bookingCoreData: any = {}

  currentBtnType = ''
  schedule2self: any = null

  @Watch('pkgSelectedDate')
  dateChangeWatcher(val: string) {
    /**
     * 日期改变时校验套餐是否可选
     * @note 这个是原产线的逻辑，但这里有个问题，如果已选的销售属性没有构成套餐，即使这些销售属性在当前日期下是可选的，也会被清空
     */
    if (val) {
      if (this.schedule2self?.stocks) {
        const currentSchedules = this.calcActInDatePacakges.find((v) => v.package_id === this.packageId)
        if (!currentSchedules) {
          this.clearSelectPackage()
        }
      }
    }
  }

  dateChange(_date: string, schedule: ExperienceActivity.ISchedule) {
    this.schedule2self = this.getStocks(_date, schedule)
  }

  getStocks(date: string, schedule: ExperienceActivity.ISchedule) {
    const res = this.calcScheduleList.filter((item: any) => item.date === date)
    return {
      ...(schedule || {}),
      ...(res[0] as any)
    }
  }

  clickCustomFn(type: string) {
    if (!type) { return false }
    this.currentBtnType = type
    if (!this.packageId) { return }
    this.bookingCoreData = dataUtils.getBookingCoreData2vm(this)
    this.$nextTick(() => {
      this.activityBookingRef?.open && this.activityBookingRef.open({
        date: this.pkgSelectedDate,
        packageId: this.packageId,
        skuId: 0
      })
    })
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get showOpenTicketBySpec() {
    if (this.specList?.length) {
      // 判断每一个spec中是否都只有一个属性
      return this.specList.some((item: any) => item?.attr?.length > 1)
    }

    return false
  }

  get calcBusinessData() {
    const {
      activityId,
      templateId,
      isSupportShoppingCart,
      dateDescription: pickupDateDescription,
      activityTicketType,
      packageList: packages
    } = this
    return {
      activityId,
      templateId,
      isSupportShoppingCart,
      pickupDateDescription,
      activityTicketType,
      packages
    }
  }

  get calcBasicData() {
    return {
      activity_id: this.activityId,
      is_line_page: this.isLinePage,
      act_product_type: this.actProductType,
      is_in_flash_sale: this.isInFlashSale,
      publish_info: this.publishInfo
    }
  }

  get pkgDetail() {
    const arr = this.packageDetailList || []
    return arr[0]
  }

  get calcSchedule() {
    const { pkgSelectedDate: date, priceSchedulesObj, schedule2self } = this
    return date ? priceSchedulesObj[date] : schedule2self
  }

  get priceSchedulesObj() {
    const { calcScheduleList } = this
    return calcScheduleList.reduce((acc: any, v: any) => ({ ...acc, [v.date]: v }), {})
  }

  @Watch('packageId', { immediate: true })
  async packageIdChange(val: number) {
    if (!val) {
      return
    }
    if (val) {
      // 存在attr没有选完所以package不存在但情况
      this.chosenAttrIds = this.getPackageAttrId(val)
    }

    const res = await this.getPackageSchedulesAndUnitsApiData(this.calcPkgSchedulesAndUnitsParams)

    // 将日历接口获取到的最优价通知到外层模版来覆盖 pacakgeList 中的 sale_price 或 from_price
    const { selling_price } = res?.result || {}
    if (selling_price) {
      this.$emit('updatePackageList', { sellingPrice: selling_price })
    }
  }

  getPackageAttrId(packageId: number) {
    const pkg = this.packageList?.find((v) => v.package_id === packageId)
    return pkg ? pkg.spec_attr_id : []
  }

  selectPackage(id: number) {
    this.updateExpPackageId(id)
  }

  clearSelectPackage() {
    this.chosenAttrIds = []
    this.updateExpPackageId()
  }

  handleClear() {
    this.clearSelectPackage()
  }

  attrChange(
    chosenAttrIds: number[],
    packageId: number,
    attr: ExperienceActivity.IAttr,
    spmObj: Record<string, string | number> | null = null
  ) {
    this.chosenAttrIds = chosenAttrIds
    this.updateExpPackageId(packageId)

    // 日期不匹配，清空日期
    if (!attr.isScheduleMatch) {
      this.clearDate()
    }

    this.trackPackageId({ packageId, spmObj })
  }

  clearDate() {
    this.pkgSelectedDate = ''
    this.switchableActivityCalendar && ((this.switchableActivityCalendar as any).value = null)
  }

  trackPackageId(
    { packageId, type, spmObj }: { packageId?: number | null; type?: string; spmObj?: Record<string, string | number> | null } = {
      packageId: this.packageId,
      type: 'User'
    }
  ) {
    // 默认值
    packageId = packageId ?? this.packageId
    type = type || (spmObj?.type as string) || 'User'

    const credits = this.calcPkgSelectedObj?.credits
    const packageOptionsNode = this.attrWrap?.$el

    if (!packageId || !packageOptionsNode) {
      return
    }

    // 处理自动选中的问题
    const selectedStepSet = new Set(this.calcSpecIdList || [])
    for (const item of (this.userSelectedSpec || [])) {
      selectedStepSet.delete(item)
      selectedStepSet.add(item)
    }

    this.$inhouse.track('custom', 'body', {
      spm: 'Activity.PackageType_LIST_Click',
      objectId: `package_${packageId}`,
      object_id: `package_${packageId}`,
      listLen: spmObj?.len ?? -1,
      listIndex: spmObj?.index ?? -1,
      Integral: credits ? Math.floor(credits) : 'null',
      SelectType: type,
      PromoCard: -1,
      SalesPrice: -1,
      PromotionType: -1,
      action_type: type === 'User' ? 'click' : null,
      SelectedStep: Array.from(selectedStepSet).map((item) => (this.calcSpecIdList.indexOf(item) + 1)),
      AttributeID: Array.from(selectedStepSet)
    })
  }

  handleDeleteAttrs(list: number[]) {
    this.chosenAttrIds = this.chosenAttrIds.filter((id) => !list.includes(id))

    // 如果不构成套餐了，那么重置packageId
    if (this.chosenAttrIds?.length !== this.specList?.length) {
      this.updateExpPackageId()
    }
  }

  handleSelectSpec(list: number[]) {
    this.userSelectedSpec = [...(list || [])]
  }
}
