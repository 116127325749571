














import { Component, Prop, State, Vue } from 'nuxt-property-decorator'

@Component
export default class ChatEntranceIcon extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: String, default: '' }) icon !: string
  @Prop({ type: String, default: '' }) text !: string

  get imageRatio() {
    if (this.text) {
      return this.klook.platform === 'desktop' ? 'desktopChatEntranceIconWithText' : 'mobileChatEntranceIconWithText'
    } else {
      return this.klook.platform === 'desktop' ? 'desktopChatEntranceIcon' : 'mobileChatEntranceIcon'
    }
  }
}
