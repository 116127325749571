























































































import { Component, Prop } from 'nuxt-property-decorator'
import ActivityBookingStartDate from './activity-booking-start-date.vue'
import PackageCardTitle from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/title.vue'
import ExpTagging, { getTaggingExtraTrackObj } from '~/components/experience/tagging/index.vue'
import DesktopPackageDetailButton from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-button.vue'
import UnitsJoinInfo from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/units-join-info.vue'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import PublishInfoBase from '~/components/common/publish-info/publish-info-base'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'
import CutDown from '~/components/experience/cut-down/index.vue'
import { isLessThanTargetTime } from '~/share/count-down'

@Component({
  components: {
    TotalSlot,
    UnitsJoinInfo,
    ActivityBookingStartDate,
    DesktopPackageDetailButton,
    ExpTagging,
    PackageCardTitle,
    WarmingSlogan,
    CutDown
  }
})
export default class PkgItem extends PublishInfoBase {
  @Prop() pkgDetailData!: any
  @Prop() pkgData!: any
  @Prop() units!: any[]
  @Prop() pkgTrack!: any
  @Prop({ type: Boolean, default: false }) isReady!: boolean
  @Prop({ type: Boolean, default: false }) isUnfold!: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get showPointer() {
    return this.isUnfold && !this.isWarming && !this.isSuspended
  }

  get calcPriceShow() {
    return this.pkgData?.price_show
  }

  get calcTags2attribute() { // desktop活动页-unfold_card
    return this.pkgData?.product_tags?.attribute_tags || []
  }

  get cutDownTimeList() {
    const tags = this.calcTags2discount
    const list: any = []

    if (tags?.length) {
      tags.forEach((item: any) => {
        const endTime = item?.inner_vars?.rate_plan?.count_down_end_time
        endTime && list.push(endTime)
      })
    }

    return list
  }

  get calcTags2discount() { // desktop活动页-unfold_card
    const discountTags = this.pkgData?.product_tags?.discount_tags || []
    const tagsList: any = []
    if (discountTags?.length) {
      discountTags.forEach((item: any) => {
        if (item?.inner_vars?.rate_plan?.count_down_end_time) {
          const flag = isLessThanTargetTime(item.inner_vars.rate_plan.count_down_end_time)
          flag && tagsList.push(item)
        } else {
          tagsList.push(item)
        }
      })
    }

    return tagsList
  }

  reload() {
    window && window.location.reload()
  }

  getTaggingExtraTrackObj(pkg: any) {
    const list = [...(pkg?.product_tags?.attribute_tags || []), ...(pkg?.product_tags?.discount_tags || [])]
    const track = getTaggingExtraTrackObj(list)
    return track || {}
  }

  clickPkgDetail(isb: boolean) {
    this.$emit('setPkgDetailStatus', isb)
  }

  setUnfoldStatus(isb: boolean) {
    if (this.isWarming || this.isSuspended || this.disabled) {
      return
    }

    this.$emit('setUnfoldStatus', isb)
  }
}
