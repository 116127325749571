






































































import { Component } from 'nuxt-property-decorator'
import ComboPkgItemBase from './base'
import ExpTagging from '~/components/experience/tagging/index.vue'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'
import ComboDiscountTag from '~/components/experience-booking/experience-activity/combo-option/combo-list/combo-pkg-card/components/combo-discount-tag.vue'
import ComboWarningSlogan from '~/components/experience-booking/experience-activity/combo-option/combo-list/combo-pkg-card/components/combo-warming-slogan.vue'

@Component({
  components: {
    ExpTagging,
    WarmingSlogan,
    ComboDiscountTag,
    ComboWarningSlogan
  }
})
export default class MobilleComboPkgCard extends ComboPkgItemBase {
  get getPkgDetailTrack() {
    const { packageId } = this
    const ext = JSON.stringify({
      IsComboPackage: true
    })
    return `PackageDetailsBtn?oid=${`package_${packageId}`}&ext=${encodeURIComponent(ext)}`
  }
}
