






















import { Component, mixins, namespace } from 'nuxt-property-decorator'
import PackageOptionsCommonTabs from '~/components/experience-booking/experience-activity/combo-option/combo-list/common-tabs/index.vue'
import dataUtils from '~/components/booking-core/utils/data-utils'
import { Activity } from '~/types/traveller/activity'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    PackageOptionsCommonTabs,
    FNBDesktop: () =>
      import(
        '~/components/experience-booking/experience-activity/package-options/fnb-package-options/desktop/index.vue' /* webpackChunkName: 'FNBDesktop' */
      ),
    FNBMobile: () =>
      import(
        '~/components/experience-booking/experience-activity/package-options/fnb-package-options/mobile/index.vue' /* webpackChunkName: 'FNBMobile' */
      ),
    ConboOptionDesktop: () =>
      import(
        '~/components/experience-booking/experience-activity/combo-option/combo-list/desktop/index.vue' /* webpackChunkName: 'ConboOptionDesktop' */
      ),
    ConboOptionMobile: () =>
      import(
        '~/components/experience-booking/experience-activity/combo-option/combo-list/mobile/index.vue' /* webpackChunkName: 'ConboOptionMobile' */
      )
  }
})
export default class PackageOptionsFnb extends mixins(BusinessBase) {
   static displayName = 'Package_options_fnb'
  @ActivityModule.Getter('currPackageOptionTab') currPackageOptionTab!: Activity.ComboTab

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get groupComponent() {
    return this.isDesktop ? 'FNBDesktop' : 'FNBMobile'
  }

  get combonComponent() {
    return this.isDesktop ? 'ConboOptionDesktop' : 'ConboOptionMobile'
  }

  get basicData() {
    const basicData = this.$attrs['basic-data']
    return basicData || {}
  }

  get isInCombo() {
    return this.currPackageOptionTab === 'combo'
  }

  get calcBookingCoreData() {
    const { businessData, basicData, $route } = this
    return dataUtils.getBookingCoreData2section({ businessData, basicData, $route })
  }
}
