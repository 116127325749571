//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import chunk from 'lodash/chunk'
import ImageCard from './image-card.vue'

export default {
  name: 'Entrance',
  components: {
    ImageCard
  },
  props: {
    platform: {
      type: String,
      default: ''
    },
    language: {
      type: String,
      default: ''
    },
    lazyImage: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    }
  },
  computed: {
    realPlatform() {
      return this.platform || this.$store?.state?.klook?.platform || 'mobile'
    },
    realLanguage() {
      return this.platform || this.$store?.state?.klook?.language || 'en-BS'
    },
    isMobile() {
      return this.realPlatform === 'mobile'
    },
    mobileItemStyle() {
      const itemCount = this.items.length // 总数
      const difValue = itemCount > 1 ? 80 : (itemCount - 1) * 10 + 20
      const visibleNum = itemCount > 1 ? 1 : itemCount

      return {
        width: `calc((100% - ${difValue}px) / ${visibleNum})`
      }
    },
    desktopItemStyle() {
      return {
        width: 'calc((100% - (3 * 20px)) / 4)'
      }
    },
    desktopItems() {
      const maxNum = 4
      const itemNum = this.items.length

      if (itemNum >= maxNum) {
        return chunk(this.items, maxNum)
      }

      return [this.items]
    },
    realTitle() {
      return this.title || this.$t('73137')
    }
  },
  methods: {
    gotoPage(url) {
      window.location.href = url
    }
  }
}
