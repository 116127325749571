
































import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import ActivityPackageDetailBigImages
  from '~/components/traveller/activity/activity-package-options/desktop/package-detail/detail-big-images.vue'

@Component({
  components: {
    ActivityPackageDetailBigImages
  }
})

export default class PackageDetailContentImages extends Vue {
  @State klook!: Data.Klook
  @Prop() specStyle!: object
  @Prop({ default: 'slideDetail' }) type!: 'slideDetail' | 'moduleDetail'
  @Prop({ default: [] }) images!: any
  bigImageVisible: boolean = false
  bigImageIndex: number = 0

  get imagesStyle() {
    if (this.specStyle) { return this.specStyle }
    return this.type === 'slideDetail' ? {
      width: '220px'
    } : {
      width: '800px'
    }
  }

  showBigImgModule(index: number) {
    this.bigImageVisible = true
    this.bigImageIndex = index
  }

  closeBigImage() {
    this.$nextTick(() => {
      this.bigImageVisible = false
    })
  }
}
