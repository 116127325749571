

















import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import DesktopPackageOptionsSpecType from '~/components/booking-core/package-options/desktop/spec-type/index.vue'
import PackageOptionsCommonTabs from '~/components/experience-booking/experience-activity/combo-option/combo-list/common-tabs/index.vue'
import ConboOption from '~/components/experience-booking/experience-activity/combo-option/combo-list/desktop/index.vue'
import dataUtils from '~/components/booking-core/utils/data-utils'

@Component({
  components: {
    DesktopPackageOptionsSpecType,
    PackageOptionsCommonTabs,
    ConboOption
  }
})
export default class DesktopPackageOptions extends BusinessBase {
  static displayName = 'DesktopPackage_options_spec_type'

  bookingCoreData: any = {}

  created() {
    const { businessData, basicData, $route } = this
    this.bookingCoreData = dataUtils.getBookingCoreData2section({ businessData, basicData, $route })
  }

  get calcBookingCoreData() {
    const { businessData, basicData, $route } = this
    return dataUtils.getBookingCoreData2section({ businessData, basicData, $route })
  }

  get basicData() {
    const basicData = this.$attrs['basic-data']
    return basicData || ({} as any)
  }

  /**
   * 最优价展示
   * 前端临时处理后端在 package_options 接口中无法计算所有日期的范围的最优价展示问题（接口为 11 天内最有）
   * 历史遗留问题导致存 curPkg 数据结构中存在 from_price，to_price 和 price_show.from_price，price_show.sale_price 内外两层
   * 外层 from_price 值最优价、to_price 指最高价，price_show 中的字段来源逻辑：
   * 1、当 to_price != from_price 时，price_show.from_price = from_price，而 price_show.sale_price = ''
   * 2、当 to_price !== from_price 时，price_show.from_price = ''，而 price_show.sale_price = from_price
   * 理想状态下应该去掉外层字段逻辑，前端都改为使用 price_show 中的价格来展示（字段都带有货币符号不用前端处理）
   */
  handleUpdatePackageList({ sellingPrice }: any) {
    if (!this.bookingCoreData.packageId) {
      return
    }

    // 将日历获取到的最优价覆盖 currentSelectedPackage 数据中的 sale_price 或 from_price
    const curPkg: any = this.businessData.packages?.find((p: any) => p.package_id === this.bookingCoreData.packageId)
    // 最优价覆盖基于后端返回值逻辑
    if (curPkg?.price_show?.sale_price) {
      curPkg.price_show.sale_price = sellingPrice
    }
    if (curPkg?.price_show?.from_price) {
      curPkg.price_show.from_price = sellingPrice
    }
  }
}
