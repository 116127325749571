












































































import { Component, Vue, State, Prop, Watch } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import { Schedule } from '../../types'
import ActivityCalendarDateBtn from './activity-calendar-date-btn.vue'
import { Activity } from '~/types/traveller/activity'
import PackageDetailFailure
  from '~/components/traveller/activity/activity-package-options/mobile/package-detail/status/package-detail-failure.vue'
import SwitchableCalendar
  from '~/components/experience-booking/experience-activity/package-options/activity-calendar/switchable-calendar.vue'
import CalenderTips from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-tips.vue'
import CalendarLengends from '~/components/experience-booking/experience-activity/package-options/activity-calendar/calendar-legend.vue'
import StatementModal from '~/components/experience-booking/experience-activity/package-options/activity-calendar/mobile/statement-modal.vue'
import CommonSkeletonSlots from '~/components/experience-booking/activity/package-detail/status/common-skeleton-slots.vue'

@Component({
  components: {
    CommonSkeletonSlots,
    PackageDetailFailure,
    SwitchableCalendar,
    ActivityCalendarDateBtn,
    CalenderTips,
    CalendarLengends,
    StatementModal
  }
})
export default class SwitchableActivityCalendar extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: '' }) value!: string
  @Prop({ default: true }) isPackageSchedule!: boolean
  @Prop() schedulesState!: Activity.fetchStatus
  @Prop() schedules!: Schedule[]
  @Prop() packageSchedules!: Schedule[]
  @Prop() packageId!: number
  @Prop() warn!: string
  @Prop({ default: () => [] }) legends!: any[]
  @Prop({ default: '' }) packageName!: string
  @Prop({ default: 0 }) calendarDays!: number

  isDatePickerVisible = false
  statementVisible: boolean = false
  legendsInfo: any = {}

  showStatementModal(data: any) {
    this.legendsInfo = data
    this.statementVisible = true
  }

  @Watch('isDatePickerVisible')
  datePickerVisibleChange(val: boolean) {
    if (val && process.client) {
      setTimeout(() => {
        this.calculateHeight()
      }, 80)
    }
  }

  get valid() {
    return !isEmpty(this.packageSchedules)
  }

  showDatePicker() {
    this.isDatePickerVisible = true
  }

  hideDatePicker() {
    this.isDatePickerVisible = false
  }

  handleHeadClick() {
    if (this.schedulesState === 'success') {
      this.showDatePicker()
    }
  }

  reload() {
    this.$emit('reload-schedules')
  }

  updateValue(val: string | null) {
    this.$emit('input', val)
  }

  handleSwiperDateChange(val: string | null) {
    this.updateValue(val)
    if (!val && !this.packageId) {
      this.$refs.switchableCalendar && ((this.$refs.switchableCalendar as any).isActivitySchedule = true)
    }
  }

  handleDateChange(...params: any) {
    this.updateValue(params[0])
    this.hideDatePicker()

    this.$emit.apply(this, params)
  }

  recalcDatePickerHeight() {
    this.$nextTick(() => {
      this.calculateHeight()
    })
  }

  calculateHeight() {
    const wrapperDom = document?.querySelector('.activity-date-picker .active-date-picker-wrap') as any
    const headerDom = document?.querySelector('.activity-date-picker .active-header-wrap') as any
    const pickerDom = document?.querySelector('.activity-date-picker .experience-data-picker') as any
    const wrapperHeight = wrapperDom?.offsetHeight || 0
    const headerHeight = headerDom?.offsetHeight || 0
    const pickerHeight = wrapperHeight - headerHeight - 10
    if (pickerHeight > 300 && pickerDom) {
      pickerDom.style.height = `${pickerHeight}px`
    }
  }
}
