

























































import { Vue, Component, Prop } from 'nuxt-property-decorator'
import { KlkAtomicBasicTag as KlkPromoTag } from '@klook/klook-card'
import '@klook/klook-card/dist/esm/index.css'

@Component({
  components: {
    KlkPromoTag
  }
})
export default class PackageTimeslotGroup extends Vue {
  @Prop({ default: () => ({}) }) list!: any[]
  @Prop() value!: string

  handleClick(item: any) {
    this.$emit('change', item)
  }
}
