




import { Component, Vue, State } from 'nuxt-property-decorator'
import { PassSkeleton } from '~/components/common/skeleton-data/index.js'

@Component({
  components: {}
})
export default class Passguide extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  static displayName = 'Pass_attractionsLoading'

  PassSkeleton = PassSkeleton

  get skeletonData() {
    const { platform, PassSkeleton } = this
    return platform === 'desktop' ? PassSkeleton.desktop.attLoading : PassSkeleton.mobile.attLoading
  }
}
