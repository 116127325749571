





























import { Component, Prop, Vue, State } from 'nuxt-property-decorator'
import Score from '~/components/traveller/activity/activity-head-info/mobile/score.vue'
import { approximateThousandsNew } from '~/share/utils'

@Component({
  components: {
    Score
  }
})
export default class ScoreParticipants extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() score!: number
  @Prop() review_count!: number
  @Prop() participants_format!: string
  @Prop() hot_state!: string
  @Prop() hidden_reviews!: boolean
  @Prop({ type: String, default: 'normal' }) sizeType!: string | 'normal'

  sizeTypeDefined: any = {
    normal: {
      scoreProps: {
        size: 16
      }
    }
  }

  get calcSizeData() {
    const { sizeTypeDefined, sizeType } = this
    const obj = sizeTypeDefined[sizeType] || sizeTypeDefined.normal
    return obj
  }

  approximateThousandsNew = approximateThousandsNew

  get isNew() {
    return this.hot_state === 'new'
  }

  get participantsFormat() {
    return this.participants_format
  }

  visible = false

  get isParticipated() {
    return parseFloat(this.participantsFormat) > 0
  }

  get showSplit() {
    return !this.hidden_reviews && this.participantsFormat
  }

  viewReviews() {
    this.$emit('view-more-reviews')
  }
}
