












































































































import { Component, State, Prop, namespace } from 'nuxt-property-decorator'
import { get } from 'lodash'
import PublishInfoBase from '~/components/common/publish-info/publish-info-base'
import scrollToPackageOptionsTop
  from '~/components/traveller/activity/activity-package-options/desktop/package-options/scroll-to-package-options-top'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'
import SavingPriceDetails from '~/components/experience/saving-price-details/index.vue'
import eventBus from '~/pages/experience/pay/common/event-bus'
import BookingBottomBtns from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/bottom-bar/booking-bottom-btns.vue'
import ExpTagging from '~/components/experience/tagging/index.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { Activity } from '~/types/traveller/activity'
import KlkCredit from '~/components/experience/klk-credit/index.vue'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    KlkCredit,
    ExpTagging,
    BookingBottomBtns,
    SavingPriceDetails,
    TotalSlot
  }
})

export default class ActivityRightPrice extends PublishInfoBase {
  @State klook!: Data.Klook
  @ActivityModule.Getter('currPackageOptionTab') currPackageOptionTab!: Activity.ComboTab
  @Prop() basicData!: ExperienceActivity.IBasicData
  @Prop() priceShow!: any
  @Prop() city_id!: number
  @Prop() published!: any
  @Prop() sold_out!: any
  @Prop() link!: string
  @Prop() link_title!: string
  @Prop() bottom_button!: string

  // 今后可以在standalone 和 combo tab切换时有区别
  get isInCombo() {
    return !!(this.currPackageOptionTab === 'combo')
  }

  get calcTotalPrice() {
    return this.totalPriceData?.prePriceData?.sale_price
  }

  get calcPriceShow() {
    if (this.calcTotalPrice) {
      return {
        sale_price: this.calcTotalPrice
      }
    }
    return this.priceShow
  }

  poptipData = {
    width: 360,
    placement: 'bottom',
    'append-to-body': true,
    'z-index': 1100
  }

  priceSummary: any = null

  initHeight = 0

  scrollToPackageOptionsTop() {
    const { query } = this.$route
    const scrollPkg = this.packageId || parseInt(query?.scroll_pkg as string) || 0
    scrollToPackageOptionsTop(scrollPkg)

    const { activity_id, activity_page_template_id } = this.basicData || {}
    this.$sendGTMCustomEvent(`Select Package Clicked|Activity Page|${activity_id}_${activity_page_template_id}`)
  }

  mounted() {
    eventBus.$off('updateRightPriceData2bus', this.updateRightPriceData2bus).$on('updateRightPriceData2bus', this.updateRightPriceData2bus)
    eventBus.$off('updateRightPriceStatus2bus', this.updateRightPriceStatus2bus).$on('updateRightPriceStatus2bus', this.updateRightPriceStatus2bus)
    setTimeout(() => {
      eventBus.$emit('setIsDelayFlag2bus', { from: 'right_price_updated' })
      const ref: any = this.$refs.pageRightPriceRef
      this.initHeight = ref?.offsetHeight || 0
    })
  }

  beforeDestroy() {
    eventBus.$off('updateRightPriceData2bus', this.updateRightPriceData2bus)
    eventBus.$off('updateRightPriceStatus2bus', this.updateRightPriceStatus2bus)
  }

  showStatus = 'default'

  updateRightPriceStatus2bus(str: string) {
    this.showStatus = str
  }

  totalPriceData: any = null

  updateRightPriceData2bus(data: any) {
    this.$set(this, 'totalPriceData', data || {})
  }

  get calcCridits() {
    return this.totalPriceData?.creditsObj || {}
  }

  get calcBookingBtnsBinds() {
    return this.totalPriceData?.bookingBottomBtnsBinds || {}
  }

  get calcPriceDetail() {
    return this.totalPriceData?.rightPriceDetail
  }

  get packageId() {
    const pid = get(this.calcPriceDetail, 'package_list.0.package_id', '')
    return pid
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }
}

