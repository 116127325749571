
















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { getNewFriendlyDateFormat } from '~/share/data/datetime'

@Component
export default class ReviewUser extends Vue {
  @State klook!: Data.Klook
  @Prop() avatar!: string
  @Prop() name!: string
  @Prop() date!: string

  getTimeText(date: string) {
    return getNewFriendlyDateFormat(date, this.$t.bind(this), this.klook.language)
  }
}
