



























































































































import { Component, Prop, State } from 'nuxt-property-decorator'
import { IconCalendarUnavailable, IconCheckCircle, IconNext } from '@klook/klook-icons'
import Price from '~/components/experience-booking/experience-activity/package-options/desktop/group-package-options/components/group-package-price.vue'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'
import CardBase from '~/components/experience-booking/experience-activity/package-options/mobile/group-package-options/card.base'
import CutDown from '~/components/experience-booking/experience-activity/package-options/mobile/group-package-options/components/cut-down/index.vue'
import ExpCutDown from '~/components/experience/cut-down/index.vue'
import ExperiencePackageDetailModule from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-module.vue'
import ExpTagging from '~/components/experience/tagging/index.vue'
import PackageOptionsBooking from '~/components/experience-booking/experience-activity/package-options/desktop/card-sku-with-detail/pkg-options-booking.vue'
import ExpPkgDetailLoading from '~/components/experience-booking/experience-activity/package-options/desktop/unfold-card/pkg-detail/loading.vue'
import eventBus from '~/pages/experience/pay/common/event-bus'
import { nodeScrollTop, scroll2 } from '~/share/scroll2/scroll2'
import { setNewImageSize } from '~/share/data/image'
import WarmingSlogan from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/warming-slogan.vue'

@Component({
  components: {
    ExpTagging,
    ExperiencePackageDetailModule,
    CutDown,
    ExpCutDown,
    IconCalendarUnavailable,
    TotalSlot,
    Price,
    IconCheckCircle,
    IconNext,
    PackageOptionsBooking,
    ExpPkgDetailLoading,
    WarmingSlogan
  }
})
export default class GroupPackageCard extends CardBase {
  @State klook!: Data.Klook
  @Prop({ default: [] }) limitedAvailablePackageList!: any[]
  @Prop() businessData!: any
  @Prop() total!: number
  @Prop() date!: string
  @Prop() getBookingCore!: () => any
  @Prop({ default: false }) noIamges!: boolean
  @Prop() url!: string

  bookingCore: any = {}
  loading = false
  isUnfold = false
  skuId = 0
  pkgDetailData: any = {}

  get showSkeletal() {
    return !this.packageData?.package_images || !this.packageData?.package_images.length
  }

  get businessDatas(): any {
    return {
      ...this.businessData,
      packages: this.limitedAvailablePackageList
    }
  }

  // 可以展开跳转
  get id() {
    return `js-pkg-id-${this.packageData.package_id}`
  }

  bookClosed() {
    this.isUnfold = !this.isUnfold
  }

  scrollToPackageOptionsTop() {
    const d = document.getElementById(this.id)
    const top = nodeScrollTop(d)
    scroll2({
      scrollY: top - 120,
      duration: 100
    })
  }

  open() {
    this.isUnfold = true
    setTimeout(() => {
      this.scrollToPackageOptionsTop()
    }, 250)
  }

  toBook() {
    if (this.isDisableSale) {
      return
    }
    if (!this.isUnfold) {
      this.loading = true
      eventBus.$emit('groupOtherClosed', this.packageData.package_id)
      const payload = this.getBookingCore()
      payload.packageList = this.limitedAvailablePackageList
      payload.packageId = this.packageData.package_id
      this.bookingCore = payload
      this.loading = false
      this.$nextTick(() => {
        this.open()
      })
    } else {
      this.isUnfold = false
    }
  }

  groupOtherClosed(id: Number) {
    if (this.packageData.package_id !== id && this.isUnfold) {
      this.isUnfold = false
    }
  }

  updatePkgDetail() {
    this.goDetail()
  }

  formatPicUrl(url: string) {
    const { webp } = this.klook
    return setNewImageSize(url, 'image/upload/', 194 * 3, 146 * 3, webp)
  }

  mounted() {
    eventBus.$on('groupOtherClosed', this.groupOtherClosed)
  }

  destroyed() {
    eventBus.$off('groupOtherClosed', this.groupOtherClosed)
  }
}
