import './markdown.scss'
import { Vue, Component, Prop, State } from 'nuxt-property-decorator'

@Component
export default class Markdown extends Vue {
  @State klook!: Data.Klook
  @Prop() content!: string | []

  render() {
    const { content } = this

    if (!content) {
      return null
    }

    let cssClass = 'markdown-content'
    if (this.klook.utilConfig.whiteLabelUtilConfig) {
      cssClass += ' white-label-links'
    }

    return (<div ref="markdown" class={cssClass} domPropsInnerHTML={content}></div>)
  }
}
