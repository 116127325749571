






























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { Dish } from '../type'
import ImageSwiper from '~/components/experience-booking/experience-activity/banner/components/image-gallery/image-swiper/index.vue'

@Component({
  components: {
    ImageSwiper
  }
})
export default class GroupSwiperList extends Vue {
  @Prop() list!: Dish[]
  @Prop() visiable!: boolean
  @Prop() curIndex!: number

  get imageslist() {
    return this.list.map((item) => ({ img_url: item.image_url, img_resize_url: item.image_url }))
  }

  bigSwiperChange(index: number) {
    this.curIndex = index
  }

  closeModal() {
    this.$emit('close')
  }
}
