

























import { Component, Vue, State, Prop, Watch } from 'nuxt-property-decorator'

@Component
export default class VideoPlayer extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: false }) showVideo!: boolean
  @Prop({ default: '' }) videoUrl!: string

  @Watch('showVideo')
  showVideoChange(val: boolean) {
    if (val) {
      // 等video标签初始化完成之后再播放
      this.$nextTick(() => {
        this.initVideo()
        this.video.play()
      })
    }
  }

  handlePlay() {}

  handlePause() {}

  hideVideoPlayer() {
    this.video.pause()
    this.$emit('close')
  }

  video: any = {}

  initVideo() {
    this.video = this.$refs.video as HTMLVideoElement
  }

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }
}

