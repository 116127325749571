




















import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import SavingPriceDetails from '~/components/experience/saving-price-details/index.vue'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

@Component({
  components: {
    SavingPriceDetails
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: true }) hideDashed!: boolean
  @Prop({ default() { return {} } }) poptipData!: any
  @Prop({ default() { return {} } }) priceSummary!: any

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  clickHandle(isb: boolean) {
    this.visible = !isb
    const totalFooter: any = document.querySelector('.js-hack-total-footer')
    const jsHack: any = document.querySelector('.js-custom-bottom-sheet-hack')
    try {
      if (totalFooter?.offsetHeight && jsHack) {
        this.$nextTick(() => {
          jsHack.style.bottom = this.visible ? `${totalFooter.offsetHeight}px` : '0'
          jsHack.style.minHeight = this.visible ? '92px' : 'auto'
        })
      }
    } catch (error) {
      // console.log('undefined class js-hack-total-footer.', error)
    }
  }

  get calcTrack() {
    const def = {
      type: 'module',
      spm: 'Savings_Detail'
    }
    return def
  }

  visible = false

  poptipDef = {
    transfer: true, // body
    visible: false,
    showClose: true
    // width: 480,
    // maxWidth: 480,
    // maxHeight: 0
  }
}
