import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component
export default class SpaBanner extends BusinessBase {
  get list() {
    return this.businessData?.selling_points || []
  }

  get title() {
    return this.businessData?.title || ''
  }

  get icon() {
    return this.businessData?.icon || ''
  }
}
