





























import { Vue, Component, Prop, State } from 'nuxt-property-decorator'
import { IconStar } from '@klook/klook-icons'
import { starAppraise } from '~/share/utils'

@Component({
  components: {
    IconStar
  }
})
export default class KlkStars extends Vue {
  @State klook!: Data.Klook
  @Prop({ type: String, default: '' }) styleType!: '' | 'new-stars-type' | 'stars-v2-type'
  @Prop({
    default: 5
  })
  readonly score!: number

  @Prop({
    default: 14
  })
  readonly size!: number

  @Prop({
    default: 5
  })
  readonly total!: number

  @Prop({
    default: false
  })
  readonly starShow!: boolean

  @Prop({
    default: false
  })
  readonly hideAppraise!: boolean

  get width() {
    return (this.score / this.total) * 100 + '%'
  }

  // 这个 80 有问题，但是我不敢动，怕影响其它地方
  get starAppraise() {
    return starAppraise(this.$t.bind(this), this.score, 80)
  }

  // starAppraise() {
  //   return starAppraise(this.$t.bind(this), this.score, 80)
  // }
}
