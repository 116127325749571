









import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class ReviewsBlank extends Vue {
  @Prop() tips!: string
}
