









import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class DividerLine extends Vue {
  @Prop() attr!: {
    height: number,
    color: string
  }

  get style() {
    if (!this.attr) { return {} }
    let { height, color } = this.attr
    height = this.attr.height > 0.5 ? this.attr.height : 0.5
    color = this.attr.color || '#eee'
    const borderTop = `${height}px solid ${color}`
    return {
      borderTop
    }
  }
}
