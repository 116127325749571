










import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class ViewMore extends Vue {
  handleClick(): void {
    this.$emit('view-more')
  }
}
