























import { Component, Vue, Prop } from 'nuxt-property-decorator'

  @Component({
    components: {
    }
  })
export default class ActivityCouponSection extends Vue {
  @Prop({ type: String, default: '' }) type!: string
  @Prop({ type: Array, default: () => [] }) couponList!: any

  couponListEnterClick() {
    this.$emit('couponListEnterClick')
  }
}
