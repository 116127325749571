import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    //
  }
})
export default class ComboPkgItemBase extends Vue {
  @Prop({ type: Object, default: () => ({}) }) priceShow!: any
  @Prop({ type: Object, default: () => ({}) }) productTags!: any
  @Prop({ type: Object, default: () => ({}) }) comboTag!: any
  @Prop({ type: String, default: '' }) packageName!: string
  @Prop({ type: Number, default: 0 }) packageId!: number
  @Prop({ type: String, default: '' }) trackInfo!: string
  @Prop() publishInfo!: ExperienceActivity.PublishInfo

  get isWarming() {
    return this.publishInfo?.publish_status === 3
  }

  get warmingText() {
    if (this.isWarming) {
      return this.publishInfo?.publish_status_desc || ''
    }
    return ''
  }

  get isSuspended() {
    return this.publishInfo?.publish_status === 2
  }

  get suspendedText() {
    if (this.isSuspended) {
      return this.publishInfo?.publish_status_desc || ''
    }
    return ''
  }

  get disabledText() {
    if (this.isSuspended) {
      return this.suspendedText
    } else if (this.isWarming) {
      return this.warmingText
    } else {
      return ''
    }
  }

  get calcTags2attribute() {
    return this.productTags?.attribute_tags || []
  }

  get isDisableSale() {
    return this.isWarming || this.isSuspended
  }

  get calcTags2discount() {
    // mweb预订页-旧流程-已选套餐
    const tags = this.productTags?.discount_tags || []
    const list: any = []
    if (tags?.length) {
      tags.forEach((tag: any) => {
        const inner_vars = tag?.inner_vars || {}
        if (!inner_vars?.inner?.derivation_info || !inner_vars?.inner?.derivation_info?.is_derived) {
          // 非倒推/直接打到的
          list.push(tag)
        }
      })
    }

    return list
  }

  get discountTag() {
    return this.comboTag || {}
  }

  get priceHtml() {
    const { from_price: fromPrice, sale_price: salePrice } = this.priceShow || {}
    if (salePrice) {
      return `<b>${salePrice}</b>`
    } else if (fromPrice) {
      const price = this.$t('activity_detail_from_price', [14, fromPrice])
      return this.isDisableSale ? price.replace('#333333', '#A8A8A8') : price
    } else {
      return ''
    }
  }

  get comboPkgSelectBtnClass() {
    return 'combo-pkg-select-button'
  }

  reload() {
    window && window.location.reload()
  }

  goDetail(isb: boolean) {
    this.$emit('setPkgDetailStatus', isb)
  }

  selectBtnInhouseTrack() {
    const dom = this.$el.querySelector(`.${this.comboPkgSelectBtnClass}`)
    if (dom) {
      this.$inhouse.track('action', dom)
    }
  }

  bookHanlder() {
    if (this.isDisableSale) {
      return
    }
    this.$emit('toBook', this.packageId)
    this.selectBtnInhouseTrack()
  }
}
