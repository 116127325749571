





















import { Component, Getter, State, Vue, Prop } from 'nuxt-property-decorator'
import ActivityWishButton from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/attribute-bottom-bar/activity-wish-button.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    ActivityWishButton
  }
})
export default class PackageOptionsSoldOut extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @State(state => state.klook.utilConfig) utilConfig!: any
  @Getter isGuestCheckout!: boolean
  @Prop() basicData!: ExperienceActivity.IBasicData

  static displayName = 'Package_option_soldout'

  initBottomButton = false

  get wishButtonVisible() {
    return !this.utilConfig?.whiteLabelUtilConfig && !this.isGuestCheckout
  }

  get activityId() {
    return this.basicData.activity_id
  }

  get cClass() {
    const name = 'package-options-sold-out'

    return [name, `${name}-${this.platform}`]
  }

  mounted() {
    if (this.platform === 'mobile') {
      setTimeout(() => {
        this.initBottomButton = true
      }, 1000)
    }
  }
}
