





















import { Component, State, Vue, Ref } from 'nuxt-property-decorator'
import DynamicMapWrap
  from '~/components/experience-booking/experience-activity/dynamic-component/map/dynamic-map.vue'
import Logo from '~/components/experience/card-swiper/logo.vue'

@Component({
  components: {
    DynamicMapWrap,
    Logo
  }
})
export default class DynamicImageMap extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Ref() actMapImgRef!: HTMLSelectElement

  styles = {
    height: '240px'
  }

  setStyles() {
    if (this.platform === 'mobile') {
      this.$nextTick(() => {
        const width = this.actMapImgRef ? this.actMapImgRef.offsetWidth : 0
        const height = `${width / 335 * 120}px`
        this.styles.height = height
      })
    }
  }

  getImageUrl(url: string) {
    const size = this.platform === 'mobile' ? 'upload/c_crop,q_80,w_776,h_560' : 'upload/c_crop,q_80,w_1580,h_632'

    return url.replace(/upload/, size)
  }

  mounted() {
    this.setStyles()
  }
}
