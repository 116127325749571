











import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class GuideButton extends Vue {
  @Prop() content!: string
  @Prop() visible!: boolean
}
