import { Vue, Component, Prop } from 'nuxt-property-decorator'
import type { SectionData, SubSectionData } from '~/components/common/package-detail-v2/sections/type'

@Component
export default class PackageDetailSectionBase extends Vue {
  @Prop() sectionData!: SectionData

  get title(): string {
    return this.sectionData?.title || ''
  }

  get trackName(): string {
    return this.sectionData?.track?.name || ''
  }

  get components(): SubSectionData[] {
    return this.sectionData?.components || []
  }

  getSpmModule(subSection: any, index: number) {
    const name = subSection?.track?.name
    const content = subSection?.track?.content
    const len = this.components.length
    return name
      ? `${name}?len=${len}&idx=${index}&ext=${encodeURIComponent(JSON.stringify({
        Content: content
      }))}`
      : null
  }

  getSpmVirtualItem(subSection: any) {
    const name = subSection?.track?.name
    return name ? '__virtual' : null
  }
}
