import { Component, Prop, mixins, Watch, namespace } from 'nuxt-property-decorator'
import { action as pkgsAction } from '~/store/experience/pkgs-cache-observable.js'
import apis from '~/share/data/apis'
import { windowScrollTop } from '~/share/scroll2/scroll2'
import newPackageDetailShowMixin from '~/components/common/package-detail-v2/show-mixin'

type Status = 'success' | 'failure' | 'fetching' | 'none'

const ActivityModule = namespace('traveller/activity')
@Component
export default class PackageDetailBase extends mixins(newPackageDetailShowMixin) {
  @Prop() styleType!: 'hide-init-collapse-transition'
  @Prop() cachePkgDetail!: boolean
  @Prop(({ default: 500 })) stateTimeout!: number | string
  @Prop() waitTime!: number
  @Prop() packageId!: number
  @Prop() preview!: number
  @Prop({ default: false }) isEnableSwiperMode!: boolean
  @ActivityModule.State activityDetail!: any

  pkgDetailStyleType = ''

  get calcStateTimeout() {
    const str = this.stateTimeout
    return str && parseInt(str as string)
  }

  get isLinePage() {
    const basic_data = this.activityDetail?.basic_data ?? {}
    return basic_data.is_line_page || false
  }

  state: Status = 'none'
  statusMap = {
    success: 'PackageDetailSuccess',
    failure: 'PackageDetailFailure',
    fetching: 'PackageDetailLoading',
    none: 'PackageDetailEmpty'
  }

  packageDetail: any = null

  @Watch('packageId', { immediate: true })
  packageIdChange() {
    this.$isServer || this.getPackageDetail()
  }

  winTop = 0
  scrollStopTimer: any = null

  async getPackageDetail() {
    if (!this.packageId) {
      return
    }

    if (!this.$isServer) {
      const newTop = windowScrollTop()
      if (this.waitTime && (this.winTop !== newTop)) {
        clearTimeout(this.scrollStopTimer)
        this.scrollStopTimer = setTimeout(() => {
          this.getPackageDetail()
        }, this.waitTime)
        this.winTop = newTop
        return
      }
    }
    this.setStyleType(this.styleType)

    const translation = this.$route.query?.translation === '1'
    // 抢购加上 is_line_page 字段
    const is_line_page = this.isLinePage || false
    let res = null
    const packageId = this.packageId
    const preview = this.preview
    if (this.cachePkgDetail && packageId) {
      const cacheRes = pkgsAction.get(packageId, 'pkgDetail')
      cacheRes?.success && (res = cacheRes)
    }

    if (!res) {
      this.state = 'fetching'
      const params = {
        preview,
        translation: !!translation,
        package_id_list: packageId,
        publish_status: preview ? 'all' : 'viewable',
        is_line_page: !preview && is_line_page
      }
      const url = this.showStructuredPackageDetail ? apis.getAttractionPackageDetail : apis.activityPackageDetail
      res = await this.$axios.$get(url, {
        params
      })
      this.$isServer || pkgsAction.set(packageId, 'pkgDetail', res)
    } else if (!this.isEnableSwiperMode) {
      // 没有 isEnableSwiperMode 需要有一个 fetching 的效果
      this.state = 'fetching'
    }

    if (!res.success) {
      this.setState('failure')
      return
    }

    const { result } = res
    if (res.success && result && result.length) {
      this.packageDetail = result[0]
      this.setState('success')
      return
    }

    this.setState('failure')
  }

  setState(state: Status) {
    if ('success|failure'.includes(state)) {
      if (this.calcStateTimeout) {
        setTimeout(() => {
          this.state = state
          setTimeout(() => {
            this.setStyleType()
          }, 0)
        }, this.calcStateTimeout)
      } else {
        this.state = state
        setTimeout(() => {
          this.setStyleType()
        }, 0)
      }
    }
    this.$emit('updateState', state)
  }

  setStyleType(str = '') {
    this.pkgDetailStyleType = str
  }

  get status() {
    const defaultStatus = 'none'
    if (!this.packageId) { return defaultStatus }

    return this.state || defaultStatus
  }

  get componentName() {
    return this.statusMap[this.status]
  }
}
