




















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'

@Component
export default class HeroFilterItem extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: false }) active!: boolean
  @Prop({ default: false }) verbose!: boolean
  @Prop({ default: false }) disableHover!: boolean
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: 'normal' }) mode!: 'normal' | 'outline' | 'pill'
  @Prop({ default: false }) useKlkPopTip!: boolean
  // @Prop({ default: 'normal' }) size!: 'normal' | 'large'

  get content() {
    if (this.disabled && this.useKlkPopTip) {
      return this.$t('108981')
    }

    return ''
  }

  get trigger() {
    return this.useKlkPopTip ? 'hover' : 'none'
  }
}
