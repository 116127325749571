






import { Component, State } from 'nuxt-property-decorator'
import { ISellingpoint } from './type'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({
  components: {
    PassIntroduceDsktop: () =>
      import(
        '~/components/experience-booking/experience-activity/pass_introduction/desktop/index.vue' /* webpackChunkName: 'PassintroduceDsktop' */
      ),
    PassIntroduceMobile: () =>
      import(
        '~/components/experience-booking/experience-activity/pass_introduction/mobile/index.vue' /* webpackChunkName: 'PassintroduceMobile' */
      )
  }
})
export default class PassIntroductionBase extends BusinessBase {
  @State klook!: Data.Klook

  static displayName = 'Pass_introduction'

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get popComponent() {
    return this.isDesktop ? 'PassIntroduceDsktop' : 'PassIntroduceMobile'
  }

  get list(): ISellingpoint[] {
    return this.businessData?.selling_points || []
  }
}
