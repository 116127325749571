import { checkExperimentHit } from '~/share/utils'

const variantGroupName = 'hotel API'

export default function AB67880(kepler: Data.IKepler, platform: Data.Platform) {
  const experimentName = platform === 'desktop' ? 'SG - Hotel API pages versus Hotel TTD pages' : 'SG - Hotel API pages versus Hotel TTD pages_Mweb'

  const checkExperimentFn: Function = checkExperimentHit(kepler.experimentsGroup, experimentName)
  return checkExperimentFn(variantGroupName)
}
