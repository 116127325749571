






import { Vue, Component, Prop } from 'nuxt-property-decorator'
import CommonSkeletonSlots from '~/components/experience-booking/activity/package-detail/status/common-skeleton-slots.vue'

@Component({
  components: {
    CommonSkeletonSlots
  }
})
export default class Index extends Vue {
  @Prop() styleType!: string
  skeletonNames = ['act-date-skeleton', 'pkg-skeleton', 'pkg-details-skeleton']
}
