


































import { Component, Prop } from 'nuxt-property-decorator'
import { IPackage_group_info } from '../type'
import RenderList from './group-render-list.vue'
import PackageDetailFailure from '~/components/experience-booking/activity/package-detail/status/package-detail-failure.vue'
import PackageCardPlaceholderList from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-card-placeholder-list.vue'
import EmptyStatus from '~/components/experience/tag-filter/empty-status.vue'
import PackageOptionsGroup from '~/components/experience-booking/experience-activity/package-options/base/package-options-group'

@Component({
  inheritAttrs: false,
  components: {
    EmptyStatus,
    PackageCardPlaceholderList,
    PackageDetailFailure,
    RenderList
  }
})
export default class PackageCardListWrap extends PackageOptionsGroup {
  @Prop({ default: 0 }) preview!: number
  @Prop() packageId!: number
  @Prop() toBook!: () => void
  @Prop() getPackageSchedulesUnits!: (id: number) => void
  @Prop() getBookingCore!: () => any
  @Prop() packagesInfo!: any[]
  @Prop() businessData!: any

  state: string = 'success'

  getPackageInfo() {}

  getSpm(item: IPackage_group_info, index: number, length: number) {
    const { group, packages } = item
    const keys = (packages || []).map((item: any) => item.package_id)
    const PackageNumber = this.packages.filter((item) => keys.includes(item.package_id)).length

    const ext = { GroupTitle: group.name, Groupid: group.track_id, PackageNumber }
    return `PackageGroup_LIST?idx=${index}&len=${length}&oid=group_${group.track_id}&ext=${this.$getExt(
      ext
    )}`
  }
}
