




import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class DividerGap extends Vue {
  @Prop() attr!: {
    height: number,
    color: string
  }

  get styles() {
    if (!this.attr) { return {} }

    return {
      height: this.attr.height * 4 + 'px',
      backgroundColor: this.attr.color
    }
  }
}
