























import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import LayerHeader from '~/components/traveller/activity/layer/layer-header.vue'

@Component({
  components: {
    LayerHeader
  }
})
export default class ActivityLayer extends Vue {
  @Prop({ type: String, default: '100%' }) height!: string
  @Prop() headerVisible!: boolean
  @Prop({
    type: String,
    default: 'slide-up',
    validator(value: string): boolean {
      return ['slide-up', 'slide-right', 'fade'].includes(value)
    }
  }) transition!: string

  @Prop() readonly visible!: boolean
  @Prop() overlayClosable!: boolean
  @Prop() title!: string
  @Prop() icon!: string
  @Prop() fixed!: boolean
  @Prop() color!: boolean
  @Prop() transfer!: boolean
  @Prop({ default: false }) notFixed!: boolean

  fixScrollY = 0
  transfered = false

  @Watch('visible')
  visibleChange(val: boolean) {
    if (this.notFixed) {
      return
    }

    if (val) {
      if (document.body.style.position !== 'fixed') {
        // window.pageYOffset（只读） 兼容性最佳，其他比如 window.scrollY document.body.scrollTop 读取会有兼容性问题，详情见 MDN docs
        this.fixScrollY = window.pageYOffset
        // position fixed 防止滚动穿透
        document.body.style.position = 'fixed'
        // body fixed 后保持 body 滚动位置
        document.body.style.top = -this.fixScrollY + 'px'
      }
    } else {
      // 恢复 body position
      document.body.style.position = 'static'
      // 改变 position 重新恢复 scrollTop
      document.body.scrollTop = this.fixScrollY // For Safari
      document.documentElement.scrollTop = this.fixScrollY // For Chrome, Firefox, IE and Opera
    }
  }

  get contentStyle() {
    const style: any = {}

    if (this.height) {
      style.height = this.height
    }

    if (this.color) {
      style.backgroundColor = this.color
    }

    return style
  }

  get headerStyle() {
    if (!this.fixed) {
      return {}
    }

    const height = parseFloat(this.height)
    return {
      top: /%/.test(this.height) ? `${100 - height}%` : `calc(100% - ${height}px)`
    }
  }

  clickMask() {
    if (this.overlayClosable) {
      this.$emit('close')
    }
  }

  close() {
    this.$emit('close')
  }

  mounted() {
    if (this.transfer && !this.transfered) {
      document.body.appendChild(this.$el)
      this.transfered = true
    }
  }

  beforeDestroy() {
    if (this.transfer && this.transfered && this.$el) {
      if (this.$el.parentNode) {
        this.$el.parentNode.removeChild(this.$el)
      }
    }
  }
}
