











import { Component } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import ConboOption from '~/components/experience-booking/experience-activity/combo-option/combo-list/desktop/index.vue'
import DesktopPackageOptionCardSku from '~/components/booking-core/package-options/desktop/card-sku-with-detail/index.vue'
import PackageOptionsCommonTabs from '~/components/experience-booking/experience-activity/combo-option/combo-list/common-tabs/index.vue'
import dataUtils from '~/components/booking-core/utils/data-utils'

@Component({
  components: {
    DesktopPackageOptionCardSku,
    PackageOptionsCommonTabs,
    ConboOption
  }
})

export default class DesktopPackageOptionSkuCard extends BusinessBase {
  static displayName = 'DesktopPackage_option_card_sku_with_detail'

  get calcBookingCoreData() {
    const { businessData, basicData, $route } = this
    return dataUtils.getBookingCoreData2section({ businessData, basicData, $route })
  }

  get basicData() {
    const basicData = this.$attrs['basic-data']
    return basicData || {} as any
  }
}
