












import { Component, Prop } from 'nuxt-property-decorator'
import Base, { getPreUnitPriceData, testPriceData } from '../price-slot-base'

export { getPreUnitPriceData, testPriceData }

@Component
export default class Index extends Base {
  @Prop({ default: 'unit' }) priceType!: string

  @Prop({
    default() {
      return ['market_price_text', 'original_price_text', 'original_selling_price_text', 'selling_price_text']
    }
  }) orderList!: string[]
}
