

























import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class Passguide extends Vue {
  static displayName = 'DesktopPass_introductionLoading'
}
