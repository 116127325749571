



































































import { Component, Prop, State, Vue } from 'nuxt-property-decorator'
import ChatEntranceIcon from '~/components/ceg/chat-entrance/chat-entrance-icon.vue'
import apis from '~/share/data/apis'
import { convertToBackendCode } from '~/share/data/language'
import { parseQueryString } from '~/share/utils'

@Component({
  components: {
    ChatEntranceIcon
  }
})
export default class ChatEntrance extends Vue {
  @State klook!: Data.Klook

  @Prop({ type: [String, Number] }) objectId!: number
  @Prop({ type: Object, default: () => ({}) }) extraInfo!: object
  @Prop({ type: String, default: () => 'AskBtn' }) spmName!: object
  @Prop({ default: false }) transfer?: boolean

  showPopup = false

  chatEntranceConfig: any = {}

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  fetchChatEntranceData() {
    // 请求后端配置接口，灌入data
    this.$axios.$get(apis.getChatEntranceConfig, { params: { page_name: this.$route.meta.trackedPageName } })
      .then((res: Data.Res) => {
        if (res?.success && res.result) {
          const { page_list: pageListConfig, default_config: defaultConfig } = res.result
          const entranceConfig = pageListConfig.find((item: any) => {
            return item.page_name === this.$route.meta.trackedPageName
          })
          if (entranceConfig) {
            const { locales } = entranceConfig
            const openConfig = locales.find((item: any) => item.locale === convertToBackendCode(this.klook.language))

            if (!openConfig) {
              this.$emit('chat-entrance-show', false)
              return
            }

            const { double_check: doubleCheck } = openConfig
            if (!doubleCheck) {
              // 不需要二次确认
              // 如果有页面级别的style配置，就不使用默认配置
              const {
                // Chat Entrance Icon
                icon,
                // Chat Entrance Icon Text，暂时只有中文，暂不启用多语言
                // text,
                // Chat Entrance 跳转链接
                action_link: jumpLink,
                // Chat Entrance Poptip Switch
                bubble_switch: poptipSwitch,
                // Chat Entrance Poptip Text
                bubble_text: poptipText,
                // Chat Entrance Poptip Duration
                bubble_trigger_duration: poptipDuration
              } = !entranceConfig.config ? defaultConfig : entranceConfig.config
              this.chatEntranceConfig = {
                icon,
                text: this.klook.language === 'zh-CN' ? '客服' : '',
                jumpLink,
                poptipSwitch,
                poptipText: poptipText ? poptipText[convertToBackendCode(this.klook.language)] : '',
                poptipDuration
              }
              this.setEntranceDisplayTimer(+poptipDuration)
              if (icon) {
                // 外部要知道组件是否成功显示了，来做额外逻辑
                this.$emit('chat-entrance-show', true)
              } else {
                this.$emit('chat-entrance-show', false)
              }
            } else {
              // 需要二次确认逻辑待迭代
              this.$axios.$get(apis.getDoubleCheckResult,
                { params: { page_name: this.$route.meta.trackedPageName, object_id: this.objectId } })
                .then((res: Data.Res) => {
                  if (res?.success && res.result) {
                    const { is_on: isOn, object_config_info: objectConfigInfo } = res.result
                    if (isOn) {
                      const {
                        icon,
                        action_link: jumpLink,
                        bubble_switch: poptipSwitch,
                        bubble_text: poptipText,
                        bubble_trigger_duration: poptipDuration
                      } = objectConfigInfo || {}

                      this.chatEntranceConfig = {
                        icon,
                        text: this.klook.language === 'zh-CN' ? '客服' : '',
                        jumpLink,
                        poptipSwitch,
                        poptipText: poptipText ? poptipText[convertToBackendCode(this.klook.language)] : '',
                        poptipDuration
                      }
                      this.setEntranceDisplayTimer(+poptipDuration)
                      if (icon) {
                        // 外部要知道组件是否成功显示了，来做额外逻辑
                        this.$emit('chat-entrance-show', true)
                      } else {
                        // 关闭活动详情连带的Contact Section
                        this.$emit('chat-entrance-show', false)
                      }
                    } else {
                      // 关闭活动详情连带的Contact Section
                      this.$emit('chat-entrance-show', false)
                    }
                  }
                })
            }
          }
        }
      }).catch((error) => {
        const module = 'chat-entrance'
        this.$captureSenrtyEvent({
          level: 'error',
          message: `[api] - ${module} | request-catch`,
          fingerprint: [module, 'api', 'request'],
          tags: {
            module_type: 'api',
            module,
            action: 'api'
          },
          extra: {
            url: apis.getChatEntranceConfig,
            message: error?.toString()
          }
        })
      })
  }

  mounted() {
    this.fetchChatEntranceData()
  }

  setEntranceDisplayTimer(timeDuration: number) {
    const timerStart = setTimeout(() => {
      this.showPopup = true
      if (this.isDesktop) {
        // 5s 后消失
        const timerEnd = setTimeout(() => {
          this.showPopup = false
          clearTimeout(timerEnd)
        }, 5 * 1000)
      }
      clearTimeout(timerStart)
    }, timeDuration * 1000)
  }

  toChat() {
    // 同时由于有AB存在，我们在Node层redirect不同版本，因此要带参数处理AB逻辑
    // 目前AB只跑在Activity页面，如果AB全量，只保留一个版本，这个from链接参数可以去除
    const contextParams = encodeURIComponent(JSON.stringify({
      previousPageName: this.$route.meta.trackedPageName,
      previousPageLink: location.href,
      objectId: this.objectId,
      extraInfo: this.extraInfo
    }))
    const jumpURLObj = new URL(this.chatEntranceConfig.jumpLink)
    const from = parseQueryString('from', jumpURLObj.search)
    let jumpPath

    // from参数会在进入chat页面前置条件中，作为AB实验的跳转逻辑判断依据，因此需要链接待上当前跳转页信息
    if (from) {
      // 如果配置链接有from参数
      jumpPath = this.$href(`${jumpURLObj.pathname}${jumpURLObj.search}&context=${contextParams}`)
    } else {
      // 如果配置链接没有from参数
      jumpPath = this.$href(`${jumpURLObj.pathname}?from=${this.$route.meta.trackedPageName}&context=${contextParams}`)
    }
    if (this.isDesktop) {
      window.open(jumpPath)
    } else {
      window.location.href = jumpPath
    }
  }
}
