

















import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { Activity } from '~/types/traveller/activity'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'

@Component({
  components: {
    TotalSlot
  }
})
export default class PackageCardPrice extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: () => ({}) }) unit!: Activity.unit

  get marketPrice() {
    if (this.unit.isInPromotion) {
      return this.unit.originalPrice
    }

    if (this.unit.marketPrice) {
      return this.unit.marketPrice
    }

    return ''
  }
}
