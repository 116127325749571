var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"web-coupon-card"},[_c('div',{staticClass:"coupon-left"},[_c('div',{staticClass:"coupon-title"},[_c('h3',[_vm._v(_vm._s(_vm.couponData.discount_desc))]),_vm._v(" "),_c('klk-poptip',{attrs:{"append-to-body":"","z-index":9999,"content":_vm.couponData.spec_desc,"dark":""}},[_c('klk-icon',{staticClass:"tips",attrs:{"type":"icon_tips_tips","size":16}})],1)],1),_vm._v(" "),_c('div',{staticClass:"coupon-detail"}),_vm._v(" "),_c('div',{staticClass:"coupon-desc"},[_vm._v(_vm._s(_vm.couponData.desc))])]),_vm._v(" "),_c('div',{directives:[{name:"track",rawName:"v-track:item:PromoCodeTab",value:({
      oid: _vm.couponData.coupon_batch_id,
      ext: {
        TabName: _vm.couponStatus(_vm.couponData.status),
        DiscountDesc: encodeURIComponent(_vm.couponData.discount_desc),
        SpecialDesc: encodeURIComponent(_vm.couponData.spec_desc)
      },
      inject: 'section'
    }),expression:"{\n      oid: couponData.coupon_batch_id,\n      ext: {\n        TabName: couponStatus(couponData.status),\n        DiscountDesc: encodeURIComponent(couponData.discount_desc),\n        SpecialDesc: encodeURIComponent(couponData.spec_desc)\n      },\n      inject: 'section'\n    }",arg:"item:PromoCodeTab"}],staticClass:"coupon-right",on:{"click":function($event){return _vm.couponCodeClick({
      code: _vm.couponData.code,
      status: _vm.couponData.status,
      batchId: _vm.couponData.coupon_batch_id,
      specDesc: _vm.couponData.spec_desc,
      startTime: _vm.couponData.start_time_unix,
      disDesc: _vm.couponData.discount_desc})}}},[(_vm.couponData.code)?_c('div',{staticClass:"coupon-code-content"},[_c('div',{staticClass:"code-title"},[_vm._v(_vm._s(_vm.$t('30643')))]),_vm._v(" "),_c('div',{staticClass:"coupon-code"},[_vm._v(_vm._s(_vm.couponData.code))])]):_vm._e(),_vm._v(" "),(['tostart', 'unusable'].includes(_vm.couponData.status))?_c('div',{staticClass:"code-btn-gray"},[_vm._v("\n      "+_vm._s(_vm.couponData.button_title)+"\n    ")]):(_vm.couponData.status === 'use')?_c('div',{staticClass:"code-btn-gray"},[_c('klk-icon',{attrs:{"type":"icon_feedback_success_fill","size":16}}),_vm._v("\n      "+_vm._s(_vm.couponData.button_title)+"\n    ")],1):(_vm.couponData.status === 'get')?_c('klk-button',{staticClass:"code-btn",attrs:{"size":"mini","type":"primary","reverse":""}},[_vm._v(_vm._s(_vm.couponData.button_title))]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }