










































import dayjs from 'dayjs'
import { Component, Vue, Prop, State, Watch, Getter } from 'nuxt-property-decorator'
import { formatThousands } from '~/share/utils'
import { currencySymbolMap } from '~/share/data/currency'

export interface TimePopupSchedule {
  selling_price: number
  soldout: boolean
  date: string
  disable?: boolean
}

@Component({
  components: {}
})
export default class TimePopup extends Vue {
  @State klook!: Data.Klook
  @Prop() packageId!: number
  @Prop() title!: string
  @Prop() visible!: boolean
  @Prop() timeList!: any[]
  @Prop() selectedTime!: any
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: true }) isTrackItem!: boolean
  @Prop({ default: () => [] }) legends!: any[]
  @Getter timeFormatString!: string
  showLimit = 16
  showMore = false
  legendColor = ''
  statementVisible: boolean = false
  legendsInfo: any = {}

  @Watch('visible', { immediate: true })
  visibleChange(val: boolean) {
    if (val) {
      const index = this.timeList.findIndex((item) => this.currentSelectedDate === item.date)
      if (index >= this.showLimit) {
        this.showMore = true
      } else {
        this.showMore = false
      }
      setTimeout(() => {
        const selectedElement = this?.$el?.querySelector('.time-popup-item.selected')
        if (!selectedElement) {
          const availableElement = this?.$el?.querySelector('.time-popup-item:not(.disabled)')
          this.scrollIntoView(availableElement)
        } else {
          this.scrollIntoView(selectedElement)
        }
      }, 50)
    }
  }

  get currentSelectedDate() {
    return this.selectedTime?.date
  }

  get LengendsMap() {
    if (this.legends?.length) {
      return this.legends.reduce((p: any, o: any) => {
        const { key, color, desc } = o
        p[key] = {
          color,
          key,
          desc
        }
        return p
      }, {})
    }

    return null
  }

  get renderList() {
    if (this.timeList.length > this.showLimit && !this.showMore) {
      return this.timeList.slice(0, this.showLimit)
    }
    return this.timeList
  }

  getTrackInfo(item: any) {
    return this.isTrackItem
      ? {
        'data-spm-item': `SelectTime?ext=${JSON.stringify({ time: this.formatTime(item) })}`
      }
      : {
        'data-spm-module': `SelectTime?ext=${JSON.stringify({ time: this.formatTime(item) })}`,
        'data-spm-virtual-item': '__virtual'
      }
  }

  showStatementModal(data: { title: string; desc: string }) {
    this.legendsInfo = data
    this.statementVisible = true
  }

  scrollIntoView(ele: Element | undefined | null) {
    if (ele) {
      ele.scrollIntoView({
        block: 'center',
        inline: 'start',
        behavior: 'smooth'
      })
    }
  }

  setTimeFormat(date: any) {
    return dayjs(date).format(this.timeFormatString)
  }

  formatTime(time: any) {
    return time?.date ? this.setTimeFormat(time.date) : ''
  }

  showLegend(item: any) {
    return !!item?.legend_keys?.length
  }

  getLegendColor(item: any) {
    if (this.legendColor) {
      return this.legendColor
    }

    const legendkeys = item?.legend_keys || []

    if (legendkeys?.length && this.LengendsMap) {
      for (const cur of legendkeys) {
        this.LengendsMap[cur] && (this.legendColor = this.LengendsMap[cur]?.color)
      }
    }

    return this.legendColor
  }

  formatPrice(time: any) {
    if (!time?.date) {
      return ''
    }

    const price = `${currencySymbolMap[this.klook.currency]} ${formatThousands(time.selling_price)}`
    return time.disabled ? `${this.$t('global.sold_out')}` : `${this.priceVisible ? price : ''}`
  }

  select(item: any) {
    if (item.disable) {
      return null
    }

    // 埋点需要一定时间收集信息并发送出去
    setTimeout(() => {
      this.$emit('selectTime', item)
      this.close()
    }, 50)
  }

  close() {
    this.$emit('update:visible', false)
  }
}
