









import { Component, State } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import PassGuideDsktop from '~/components/experience-booking/experience-activity/pass-guide/desktop/index.vue'
import PassGuideMobile from '~/components/experience-booking/experience-activity/pass-guide/mobile/index.vue'

@Component({
  components: {
    PassGuideDsktop,
    PassGuideMobile
  }
})
export default class Index extends BusinessBase {
  @State klook!: Data.Klook
  static displayName = 'Pass_guide'

  get isDesktop() {
    return this.klook.platform === 'desktop'
  }

  get popComponent() {
    return this.isDesktop ? 'PassGuideDsktop' : 'PassGuideMobile'
  }

  get activityId() {
    const basic = this.$attrs['basic-data'] as any
    return basic?.activity_id || 0
  }
}
