










































import { Component, Vue, Prop, State, Watch, Inject } from 'nuxt-property-decorator'
import { HomePage } from '~/types/traveller/home-page'
import { currencySymbolMap } from '~/share/data/currency'
import { formatThousands } from '~/share/utils'
import { DefaultFieldsConfig } from '~/share/data/experience/fieldsConfigUtils.js'
import { ExperienceActivity } from '~/types/experience/activity-section'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'
import { Experience } from '~/types/experience'
import KlkCredit from '~/components/experience/klk-credit/index.vue'

@Component({
  components: {
    KlkCredit,
    TotalSlot
  }
})
export default class BottomBarSettlement extends Vue {
  @State klook!: Data.Klook

  @Prop() vip!: Experience.MembershipInfo
  @Prop() selectedPackage!: ExperienceActivity.IPackage
  @Prop() isInPromotion!: boolean
  @Prop() isActivitySuspended!: boolean
  @Prop() promotionDiscountText!: string
  @Prop() marketPrice!: string
  @Prop() sellPrice!: string
  @Prop() promotionPrice!: string
  @Prop() originalPrice!: string
  @Prop() minSellPrice!: string
  @Prop() discount!: number
  @Prop() credits!: number
  @Prop({ type: [Boolean, Number], default: true }) creditsVisible!: boolean
  @Prop({ default: false }) isOnlyOneLine!: boolean // 是否只有一行（在活动页只需要一行，其它隐藏；在booking option页，分多行，不隐藏）

  @Inject({ from: 'fieldsConfig2section', default() { return DefaultFieldsConfig } }) fieldsConfig2section!: HomePage.AllFieldsIF

  get calcPriceShow() {
    const priceShow = this.selectedPackage?.price_show || {}
    return { ...priceShow, save_price: '' }
  }

  isCreditsLeft = false
  isSpaceForCredits = true

  @Watch('sellPrice', { immediate: true })
  sellPriceChange() {
    this.priceChange()
  }

  @Watch('minSellPrice', { immediate: true })
  minSellPriceChange() {
    this.priceChange()
  }

  @Watch('promotionPrice', { immediate: true })
  promotionPriceChange() {
    this.priceChange()
  }

  priceChange() {
    this.isSpaceForCredits = true
  }

  get calcCredits() {
    return Math.floor(this.credits)
  }

  get minSellPriceText() {
    const price = `${currencySymbolMap[this.klook.currency]} ${formatThousands(this.minSellPrice)}`
    return this.$t('activity_detail_from_price', [12, price])
  }

  get showCredits() {
    return this.isSpaceForCredits && this.credits && this.creditsVisible
  }

  get completeOptionViewPriceVisible() {
    return this.sellPrice === '-' && !this.minSellPrice && !this.isInPromotion && !this.isActivitySuspended
  }

  mounted() {
    this.priceChange()
  }
}
