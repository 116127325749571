






























import { Component, Vue, Prop, Provide, Watch } from 'nuxt-property-decorator'
import { cloneDeep } from 'lodash'
import type { PackageDetailType } from '~/components/common/package-detail-v2/type'
import { PackageDetailStructured } from '~/components/common/package-detail-v2'
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'
import { nodeScrollTop, clientHeight } from '~/share/scroll2/scroll2'

@Component({
  components: {
    PackageDetailStructured
  }
})
export default class MwebAttrPkgDetail extends Vue {
  @Prop({ default: () => null }) packageDetail!: PackageDetailType
  bottomSheetVisible: boolean = false
  bsObj = getBottomSheetConfig('package-details-config')

  bottomSheetPkgDetail: any = null

  activeCollapseItem: string = ''

  bottomSheetClass = 'js-pkg-detail-list-scroll-dom'

  created() {
    this.initBottomSheetPkgDetail()
  }

  @Watch('packageDetail')
  handleChangePkgDetail(val: PackageDetailType) {
    if (val) {
      this.initBottomSheetPkgDetail()
    }
  }

  initBottomSheetPkgDetail() {
    const _copyPckDetail = cloneDeep(this.packageDetail)
    this.bottomSheetPkgDetail = _copyPckDetail
  }

  @Provide()
  handleOpenSheetItem(name: string) {
    this.bottomSheetVisible = true
    this.activeCollapseItem = name
    setTimeout(() => {
      if (process.client) {
        this.handleScrollToCollapseItem(name)
      }
    }, 500)
  }

  @Watch('activeCollapseItem')
  handleChanegActiveCollapse(currVal: string, oldVal: string) {
    const isb = Boolean(currVal)
    const changedVal = isb ? currVal : oldVal
    this.handleSetActiveCollapse(isb, changedVal)
  }

  handleSetActiveCollapse(isb: boolean, changedActiveCollapseItem: string) {
    const { bottomSheetPkgDetail } = this
    const bottomSheetSection = bottomSheetPkgDetail?.section_content?.sections || []
    const [sectionIndex, subSectionIndex] = changedActiveCollapseItem?.split('-')
    // @ts-ignore
    bottomSheetSection?.[sectionIndex]?.components?.[subSectionIndex]?.active = isb
    this.$set(this.bottomSheetPkgDetail.section_content, 'sections', bottomSheetSection)
  }

  handleClose() {
    this.bottomSheetVisible = false
    this.activeCollapseItem = ''
  }

  handleScrollToCollapseItem(activeCollapseItem: string) {
    const sheetItemActionType = 'toggle'
    const bottomSheetClass = `.${this.bottomSheetClass}`
    const collapseItemClass = '.package-detail-fold'
    const collapseItemId = `#${sheetItemActionType}-collapse-item-${activeCollapseItem}`
    const dom = document?.querySelector(`${bottomSheetClass} ${collapseItemClass} ${collapseItemId}`)
    const platformDomClass = `${bottomSheetClass} .klk-bottom-sheet-body`
    const platformDom = document?.querySelector(platformDomClass)
    if (platformDom && dom) {
      const hackHeight = clientHeight() * 0.1 + 64 // klk-bottom-sheet: height: 90% + title height
      const top = nodeScrollTop(dom) + platformDom.scrollTop - hackHeight // 顶部
      platformDom.scrollTo({
        left: 0,
        top,
        behavior: 'smooth'
      })
      // dom.scrollIntoView({ behavior: 'smooth' })
    }
  }
}
