










import { Component, Prop, Vue, State } from 'nuxt-property-decorator'
import { dec2hex } from '~/components/experience/tagging/index.vue'

const tagKeyMaps: any[] = [
  { key: 'tag_key', mapKey: 'tag_key' },
  { key: 'text', mapKey: 'text' },
  { key: 'text_color', mapKey: 'color' },
  { key: 'background_color', mapKey: 'backgroundColor', getterValue: dec2hex },
  { key: 'border_color', mapKey: 'borderColor' },
  {
    key: 'radius',
    mapKey: 'borderRadius',
    getterValue: ({ value }: any) => {
      return `${value}px`
    }
  }
]

@Component({
  components: {
    //
  }
})
export default class ComboDiscountTag extends Vue {
  @Prop({ type: Object, default: () => ({}) }) discountTag!: any
  @State((state) => state.klook.platform) platform!: Data.Platform

  getMapsItem(item: any, maps: any[]) {
    return maps.reduce((o: any, o2: any) => {
      const { key, mapKey, deepKeys, getterValue } = o2
      if (deepKeys && typeof item[key] === 'object') {
        o[mapKey] = this.getMapsItem(item[key], deepKeys)
      } else {
        const value = item[key]
        o[mapKey] = getterValue ? getterValue({ value, item }) : value
      }
      return o
    }, {})
  }

  get calcDiscountTag() {
    if (!this.discountTag.text) {
      return {}
    }
    return this.getMapsItem(this.discountTag, tagKeyMaps)
  }

  get tagBlockStyle() {
    const { color, borderColor, borderRadius, backgroundColor } = this.calcDiscountTag
    return { color, borderColor, borderRadius, backgroundColor }
  }
}
