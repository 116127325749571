
























































import { Component, Vue, Prop, State, Ref, Watch, namespace } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import CardHeader from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/card-header.vue'
import { Activity } from '~/types/traveller/activity'
import UnitPrice from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/components/unit-price.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import eventBus from '~/pages/experience/pay/common/event-bus'
import ExpTagging from '~/components/experience/tagging/index.vue'
import MobilePriceSlot, { getPreUnitPriceData } from '~/components/experience/price-slot/mobile/index.vue'
import { formatCurrencySymbolPriceThousands } from '~/share/utils'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    MobilePriceSlot,
    ExpTagging,
    CardHeader,
    UnitPrice
  }
})
export default class BookingUnit extends Vue {
  @ActivityModule.State preSettlementApiData!: any
  @ActivityModule.State pkgsPrePriceApiData!: any
  @ActivityModule.State pkgsAddonSchedulesAndUnits!: any
  @State klook!: Data.Klook
  @Prop({ default: 0 }) minStock!: number
  @Prop() pkgPreData!: any
  @Prop() customPreType!: string | 'addon-pre-type' | 'unfold-pre-type'
  @Prop({ type: Boolean, default: false }) isTrackModule!: boolean
  @Prop() packageId!: number
  @Prop() styleType!: 'desktop-type'
  @Prop() selectedTime!: { arrangementId: number; stock: number }
  @Prop() unitsMap!: any
  @Prop() unitList!: any
  @Prop() preview!: any
  @Prop() currentSelectedPackage!: ExperienceActivity.IPackageCard
  @Prop() inventories!: { [prop: number]: string }
  @Prop() totalUnitCount!: number
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: false }) disabled!: boolean
  @Prop({ default: '' }) customToastMessage!: string
  @Prop({ default: () => ({}) }) unit!: Activity.unit
  @Ref() unitCounterRef!: any

  counterValue = 0

  @Watch('unit.count', { immediate: true })
  unitCountChange(val: number) {
    this.counterValue = val
  }

  get maxUnit() {
    const units = this.pkgsAddonSchedulesAndUnits[this.packageId]?.result?.units || []
    const { skuId, priceMaxPax } = this.unit || {}
    let max = priceMaxPax

    if (units?.length) {
      const cacheUnit = units.find((item: any) => item.sku_id === skuId)

      if (cacheUnit && !isEmpty(cacheUnit)) {
        const { is_limited = false, limited_count = 0 } = cacheUnit || {}
        if (is_limited && limited_count > 0) {
          max = limited_count
        }
      }
    }

    if (this.minStock) {
      return Math.min(this.minStock, max)
    }

    return max
  }

  get calcTrackInfo() {
    let obj: any = {
      'data-spm-item': 'SelectSkuQuantity?trg=manual'
    }
    if (this.isTrackModule) {
      obj = {
        'data-spm-module': 'SelectSkuQuantity',
        'data-spm-virtual-item': '__virtual?trg=manual'
      }
    }
    return obj
  }

  bindIHTrack(...args: any) {
    return handleIHTrack(...args)
  }

  get calcPreUnitPrice() {
    const { pkgsPrePriceApiData, packageId, pkgPreData } = this
    const { has_discount } = this.currentSelectedPackage
    if (this.customPreType === 'addon-pre-type') {
      const preData = pkgPreData
      return (unit: any) => {
        const d = getPreUnitPriceData({ ...unit, has_discount }, preData)
        return d
      }
    }
    const preData = this.customPreType === 'unfold-pre-type' ? pkgsPrePriceApiData[packageId]?.result : this.preSettlementApiData?.result
    return (unit: any) => {
      const marketPrice = unit.marketPrice || ''
      const isb = marketPrice && Number(unit.sellingPrice) < Number(marketPrice) // 旧样式 原有逻辑
      const d = getPreUnitPriceData({ ...unit, has_discount }, preData, {
        oldPriceShow: {
          market_price_text: isb ? formatCurrencySymbolPriceThousands(this.klook.currency, marketPrice) : '',
          original_price_text: formatCurrencySymbolPriceThousands(this.klook.currency, unit.sellingPrice)
        }
      })
      return d
    }
  }

  get arrangementId() {
    return this?.selectedTime?.arrangementId
  }

  getCalcTags2sku(unit: any) { // mweb活动页-card_sku-选择sku
    return unit?.product_tags?.discount_tags || []
  }

  disabledClick(type: string) {
    if (!this.disabled && type === 'increase') {
      this.alert(this.$t('activity.error.price_max_v2', [this.unit?.maxCount]))
      return
    }

    eventBus.$emit('checkSelectDateAndTime2bus', { message: this.customToastMessage })
  }

  hackIncrease() {
    // klk-counter有bug，这里hack一下
    this.counterValue += 1
    this.$nextTick(() => {
      this.counterValue -= 1
      this.handlePlus()
    })
  }

  hackDecrease() {
    // klk-counter有bug，这里hack一下
    this.counterValue -= 1
    this.$nextTick(() => {
      this.counterValue += 1
      this.handleMinus()
    })
  }

  handlePlus() {
    if (this.disabled) {
      return
    }

    // const dateFun = this.$parent.$parent as any
    const { unit, inventories } = this
    const { max_pax } = this.currentSelectedPackage
    const { priceMaxPax, maxCount, inventoryId } = this.unit
    const maxPurchased = Math.min(maxCount, Number(inventories[inventoryId]))
    // if (!dateFun.checkDateAndTime()) {
    //   return
    // }

    if (this.preview) {
      // preview下处于编辑状态的套餐在unit接口会取不到unit，但日历接口可以拿到 arrangementId
      const units = this.unitsMap?.[this.arrangementId as any] || this.unitList?.length
      if (!units) {
        return this.alert('Cannot add units in unpublished status')
      }
    }

    if (this.totalUnitCount >= Number(this.currentSelectedPackage.max_pax)) {
      this.alert(this.$t('activity.v2.error.package_max', [max_pax]))
    } else if (unit.count >= unit.priceMaxPax) {
      this.alert(this.$t('activity.error.price_max_v2', [unit.maxCount]))
    } else if (
      unit.count >= maxPurchased ||
      (unit.count === 0 && unit.count + unit.priceMinPax > maxPurchased)
    ) {
      this.alert(this.$t('activity.v2.unit_out_of_inventory'))
    } else if (unit.count < unit.priceMaxPax) {
      this.changeUnitCount('add', unit.priceId)
    } else {
      this.alert(this.$t('activity.error.price_max_v2', [priceMaxPax]))
    }
  }

  handleMinus() {
    const { unit, disabled } = this
    if (disabled || unit.count === 0) {
      return
    }

    this.changeUnitCount('minus', unit.priceId)

    const { priceMinPax } = unit
    if (unit.required && unit.count === priceMinPax) {
      this.alert(this.$t('activity.error.required_buy_min', [priceMinPax]))
    }
  }

  hackChangeUnitCount(type: 'add' | 'minus', priceId: number) {
    // klk-counter有bug，这里hack一下
    this.unit.count += type === 'add' ? 1 : -1
    this.$nextTick(() => {
      this.unit.count += type === 'add' ? -1 : 1
      this.changeUnitCount(type, priceId)
    })
  }

  changeUnitCount(type: 'add' | 'minus', priceId: number) {
    this.$emit('changeUnitCount', { priceId, type })
    this.inhouseTrack(type === 'add' ? 'Add' : 'Cut')
  }

  alert(text: string) {
    this.$emit('alert', text)
  }

  inhouseTrack(type: string) {
    if (this.unitCounterRef) {
      this.$inhouse.updateBinding(this.unitCounterRef.$el, {
        oid: `sku_${this.unit.skuId}`,
        ext: JSON.stringify({
          Type: type
        })
      })

      setTimeout(() => {
        this.$inhouse.track('action', this.unitCounterRef.$el)
      }, 20)
    }
  }
}
