












import { Component, Prop } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import DynamicComponent from '~/components/experience-booking/experience-activity/dynamic-component/dynamic-component.vue'
import DynamicComponentCanFold from '~/components/experience-booking/experience-activity/dynamic-component/dynamic-component-can-fold.vue'
import DynamicComponentTa from '~/components/experience-booking/experience-activity/dynamic-component/t-and-a/index.vue'
import DynamicComponentHero from '~/components/experience-booking/experience-activity/dynamic-component/dynamic-component-hero/index.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    DynamicComponent,
    DynamicComponentCanFold,
    DynamicComponentTa,
    DynamicComponentHero
  }
})
export default class DynamicComponentSection extends BusinessBase {
  @Prop() basicData!: ExperienceActivity.IBasicData

  static displayName = 'Dynamic_component'

  get activityId() {
    return this.basicData.activity_id
  }

  get title() {
    // 兼容处理ttd和标准楼层数据不同层级问题
    let publicTitle: string = ''
    if (this.businessData?.title) {
      publicTitle = this.businessData?.title
    } else {
      publicTitle = this.sectionData?.data?.title
    }
    const title = ([] as any).concat(publicTitle)
    return this.$t.apply(this, title)
  }

  get shouldFold() {
    return this.fieldsConfig2section.fold_switch || false
  }

  get foldMinHeight() {
    return this.fieldsConfig2section.fold_min_height || ''
  }

  get showStyleVersion() {
    return this.fieldsConfig2section.show_style_version || 0
  }

  get isHeroPage() {
    return !!this.fieldsConfig2section.is_hero_page
  }

  get foldStyleType() {
    return this.fieldsConfig2section.fold_style_type || 0
  }

  get componentName() {
    // Hero Product Page
    if (this.isHeroPage) {
      return DynamicComponentHero
    }
    // T&A 样式
    if (this.showStyleVersion === 1) {
      return DynamicComponentTa
    }
    return this.shouldFold ? DynamicComponentCanFold : DynamicComponent
  }

  get components() {
    return this.businessData.components
  }
}
