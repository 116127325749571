












import { Vue, Component, Prop, Getter } from 'nuxt-property-decorator'

@Component
export default class PackageTimeslotLoading extends Vue {
  @Getter isMobile!: boolean
  @Prop({ default: () => ({}) }) list!: any[]

  get total() {
    return this.isMobile ? 3 : 5
  }

  get widthList() {
    return this.isMobile ? ['100%', '75%', '50%'] : ['100%', '50%', '25%']
  }
}
