const imageCardSwiperConfig = [
  {
    type: 0
  },
  {
    type: 1,
    itemWidth: '100%',
    itemGap: 0
  },
  {
    type: 3,
    scrollMode: true,
    itemWidth: 'calc((100% - 40px) / 2)'
  },
  {
    type: 6,
    scrollMode: true,
    itemWidth: 'calc((100% - 40px) / 2)',
    style: { margin: '20px 0' }
  }
]

export const getImageCardSwiperOptions = (type: number = 0) => {
  const opt = imageCardSwiperConfig.find((item: any) => item.type === type)
  return opt || {}
}
