import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'

dayjs.extend(utc)

/**
 * 注意，这个base需要区分活动层级和套餐层级，通过isActivityPublishInfo来区分
 * publishInfo 可能为空
 */

@Component
export default class PublishInfoBase extends Vue {
  @Prop({ default: false }) isActivityPublishInfo!: boolean
  @Prop() publishInfo!: ExperienceActivity.PublishInfo

  get isWarming() {
    // return true
    // 活动层级没有预热
    return !this.isActivityPublishInfo && this.publishInfo?.publish_status === 3
  }

  get warmingText() {
    // return 'warming'
    if (this.isWarming) {
      return this.publishInfo?.publish_status_desc || ''
    }
    return ''
  }

  get publishedTime() {
    // return '2022-11-30 08:00:00'
    const time = this.publishInfo?.published_time_utc
    if (this.isWarming && time && dayjs.utc(time).isAfter(dayjs.utc())) {
      return time
    }
    return ''
  }

  get isSuspended() {
    // return true
    if (this.isActivityPublishInfo) {
      return this.publishInfo?.publish_status === 5
    }
    return this.publishInfo?.publish_status === 2
  }

  get suspendedText() {
    // return 'Closed'
    if (this.isSuspended) {
      return this.publishInfo?.publish_status_desc || ''
    }
    return ''
  }
}
