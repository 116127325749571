// 不保证 wrapper 能滚动，需要自己保证
// wrapper: 滚动的父元素
// target: 目标元素
// offset: 自定义的偏移量
export function scrollToCenterHorizontal(wrapper: Element, target: Element, offset = 0, behavior: ScrollBehavior = 'smooth') {
  try {
    const { scrollLeft, scrollWidth } = wrapper
    const { width: wrapperWidth, left: wrapperLeft } = wrapper.getBoundingClientRect()
    const { width: targetWidth, left: targetLeft } = target.getBoundingClientRect()
    const scrollDistance = targetLeft - wrapperLeft + (targetWidth / 2) - (wrapperWidth / 2) + scrollLeft + offset
    wrapper.scrollTo({
      left: Math.min(Math.max(0, scrollDistance), scrollWidth - wrapperWidth),
      behavior
    })
  } catch (error) {
    // eslint-disable-next-line
    console.error('scrollToCenterHorizontal', error)
  }
}

export function scrollToLeftHorizontal(wrapper: Element, target: Element, offset = 0, behavior: ScrollBehavior = 'smooth') {
  try {
    const { scrollLeft, scrollWidth } = wrapper
    const { width: wrapperWidth, left: wrapperLeft } = wrapper.getBoundingClientRect()
    const { left: targetLeft } = target.getBoundingClientRect()
    const scrollDistance = targetLeft - wrapperLeft + scrollLeft + offset
    wrapper.scrollTo({
      left: Math.min(Math.max(0, scrollDistance), scrollWidth - wrapperWidth),
      behavior
    })
  } catch (error) {
    // eslint-disable-next-line
    console.error('scrollToLeftHorizontal', error)
  }
}

export function scrollToRightHorizontal(wrapper: Element, target: Element, offset = 0, behavior: ScrollBehavior = 'smooth') {
  try {
    const { scrollLeft, scrollWidth } = wrapper
    const { width: wrapperWidth, left: wrapperLeft } = wrapper.getBoundingClientRect()
    const { width: targetWidth, left: targetLeft } = target.getBoundingClientRect()
    const scrollDistance = targetLeft - wrapperLeft + scrollLeft - wrapperWidth + targetWidth + offset
    wrapper.scrollTo({
      left: Math.min(Math.max(0, scrollDistance), scrollWidth - wrapperWidth),
      behavior
    })
  } catch (error) {
    // eslint-disable-next-line
    console.error('scrollToRightHorizontal', error)
  }
}
