











import { Component } from 'nuxt-property-decorator'
import Footer from './footer.vue'
import SectionBase from '~/components/setion/experience/base/section-base'

@Component({
  components: { Footer }
})
export default class SectionFooter extends SectionBase {
  get valid() {
    return this.moreTitle && this.state === 'success' && !this.sectionVisible
  }

  get moreTitle() {
    // 兼容处理ttd和标准楼层数据不同层级问题
    let publicButtonTitle: string = ''
    if (this.businessData?.bottom_button_title) {
      publicButtonTitle = this.businessData?.bottom_button_title
    } else {
      publicButtonTitle = this.sectionData?.data?.bottom_button_title
    }
    return this.$t.apply(this, ([] as any).concat(publicButtonTitle))
  }

  get moreDeepLink() {
    // 兼容处理ttd和标准楼层数据不同层级问题
    let publicButtonLink: string = ''
    if (this.businessData?.bottom_button_deep_link) {
      publicButtonLink = this.businessData?.bottom_button_deep_link
    } else {
      publicButtonLink = this.sectionData?.data?.bottom_button_deep_link
    }
    return publicButtonLink
  }

  get link() {
    return this.moreDeepLink ? this.$href(this.moreDeepLink) : 'javascript:void(0);'
  }

  get name() {
    return this.sectionData?.meta?.name || ''
  }

  get inhouseModule() {
    const { type = '' } = this.sectionData?.meta || {}

    return `${this.name}:${type}:::BottomButton`
  }
}
