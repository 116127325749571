



































import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'

@Component
export default class ActivityPackageBtn extends Vue {
  @Prop() openDateTip?: string
  @Prop() content !: string
  @Prop({ type: String, default: '' }) icon !: string
  @Prop({ type: Number, default: 16 }) iconSize !: number
  @Prop() disabled !: boolean
  @Prop() active !: boolean
  @Prop() discount !: number

  @Watch('discount')
  handleDiscountChange() {
    this.setTextMinWidth()
  }

  setTextMinWidth() {
    if (this.$isServer) {
      return null
    }

    this.$nextTick(() => {
      this.checkLayout()
    })

    // fix 从 booking options 页跳回来 html 需要渲染时间的问题
    setTimeout(() => {
      this.checkLayout()
    }, 1000)
  }

  checkLayout() {
    const domText = this.$refs.text as HTMLElement
    const domDiscount = this.$refs.discount as HTMLElement

    if (domText && domDiscount) {
      const width = domDiscount.scrollWidth || 0
      width && (width > 24) && (domText.style.minWidth = `${width - 24}px`)
    }
  }

  mounted() {
    this.setTextMinWidth()
  }
}
