




































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ViewMore from '~/components/experience-booking/activity/activity-feature-highlight/mobile/view-more.vue'
import DynamicPlainMarkdown
  from '~/components/experience-booking/experience-activity/dynamic-component/markdown/dynamic-plain-markdown.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    DynamicPlainMarkdown,
    ViewMore
  }
})
export default class DynamicModalMarkdown extends Vue {
  @Prop() content!: ExperienceActivity.IDynamicMarkdown
  @Prop() title!: string

  visible = false
  viewMoreVisible: boolean = false

  close() {
    this.visible = false
  }

  viewMore() {
    this.visible = true
  }

  initViewMoreVisible() {
    const h = (this.$refs.activitySummary as Vue).$el.clientHeight
    const SUMMARY_HEIGHT_LIMIT = 92
    this.viewMoreVisible = h > SUMMARY_HEIGHT_LIMIT
  }

  mounted() {
    this.initViewMoreVisible()
  }
}
