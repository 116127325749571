






import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { setNewImageSize } from '~/share/data/image'

@Component
export default class DefalutImage extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: '' }) url!: string
  @Prop({ default: '' }) placeholderImage!: string

  formatPicUrl(url?: string) {
    const { webp, platform } = this.klook

    if (platform === 'desktop') {
      return setNewImageSize(url || this.placeholderImage, 'image/upload/', 187 * 3, 140 * 3, webp)
    }
    return setNewImageSize(url || this.placeholderImage, 'image/upload/', 110 * 3, 82 * 3, webp)
  }
}
