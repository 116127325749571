



























import { Component, mixins, Ref, namespace, Prop } from 'nuxt-property-decorator'
import PackageCardList from './fnb-list.vue'
import PackageOptionsBooking from '~/components/experience-booking/experience-activity/package-options/mobile/card-package-options-sku/booking/index2.0.vue'
import ShoppingCart from '~/components/experience-booking/experience-activity/activity-shopping-cart/index.vue'
import CardBottomBar from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/card-bottom-bar/index.vue'
import BookingCoreMixin from '~/components/booking-core/mixins/entry/index-base'
import AutoBookingMixin from '~/components/booking-core/mixins/common/auto-booking'
import { Activity } from '~/types/traveller/activity'
import dataUtils from '~/components/booking-core/utils/data-utils'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    PackageOptionsBooking,
    PackageCardList,
    CardBottomBar,
    ShoppingCart
  }
})
export default class CardPackageOptionsNew extends mixins(BookingCoreMixin, AutoBookingMixin) {
  @ActivityModule.Getter('currPackageOptionTab') currPackageOptionTab!: Activity.ComboTab
  @Ref() activityBookingRef!: any
  @Prop() isFnb!: boolean
  bookingCore = {}
  imageUrl = ''
  get basicData() {
    return {
      is_in_flash_sale: this.isInFlashSale,
      is_line_page: this.isLinePage
    }
  }

  get isInCombo() {
    return this.currPackageOptionTab === 'combo'
  }

  get newBusinessData() {
    return {
      packageList: this.packageList,
      activity_closer_price: this.activityCloserPrice,
      dateDescription: this.dateDescription
    }
  }

  findImage(id: number) {
    const pck = (this.fnbPackages || []).find((item) => item.package_id === id)
    if (pck) {
      return pck.package_images?.[0]?.image_url_host
    }
    return ''
  }

  toBook(packageId: number) {
    const bookingCore = dataUtils.getBookingCoreData2vm(this)
    bookingCore.packageId = packageId
    this.imageUrl = this.findImage(packageId)
    this.bookingCore = bookingCore
    this.$nextTick(() => {
      this.activityBookingRef.open({
        packageId
      })
    })
  }

  mounted() {
    this.initAutoBookingMinxin({
      activityId: this.activityId,
      shopId: parseInt(this.$route.query.shop_id as string),
      shoppingCartHandler: this.showShoppingCart
    })
  }
}
