import { merge } from 'lodash'
import { isSearchBot } from '~/share/utils'
// import { getQueryKey } from '~/share/utils'

export const ErrorReasonsMap = {
  invalidParam: '参数不合法',
  timeout: '请求超时',
  requestFailed: '请求失败',
  eitherActivityOrSitemap: '活动数量为0且未推送siteMap'
}

const GlobalStr = {
  na: 'na'
}

interface CustomDataIf {
  type: string
}

interface CustomOptIf {
  custom_data: CustomDataIf,
  isServer?: boolean,
}

const getDefaultOptions = function () {
  return {
    isServer: false
  }
}
const getDefaultLog = function () {
  return {
    timestamp: Date.now(),
    level: 'I',
    message: {
      subtype: 'exp:fe:exp_custom_log',
      system: 'optimus',
      message: {
        type: '',
        host: '',
        url: '',
        referer: '',
        platform: '',
        user_agent: '',
        is_bot: 0, // 0 | 1
        custom_data: null
      }
    }
  }
}

const getInitOptions = function (options: any) {
  const defOpt = getDefaultOptions() // 获取默认数据
  defOpt.isServer = typeof window === 'undefined' // 设置默认值
  const obj = merge({}, defOpt, options) // 合并所有数据
  return obj
}

const getFilterDefData = function (dataObj: any, defObj: any) {
  const dataArr = Object.keys(dataObj || {})
  const defArr = Object.keys(defObj || {})
  const diffArr = dataArr.filter((key) => {
    return !defArr.includes(key)
  })
  const obj = diffArr.reduce((o: any, key: string) => {
    o[key] = dataObj[key]
    return o
  }, {})
  return obj
}

const getInitLogData = function (ctx: any, customData: any) {
  const defLog: any = getDefaultLog() // 获取默认数据
  const mergeAllData: any = getServerCustomData(ctx, customData) // 合并数据
  const obj: any = defLog.message.message || {}
  const arr = Object.keys(obj)
  arr.forEach((key) => {
    obj[key] = mergeAllData[key] // 赋值公共数据
  })
  obj.custom_data = getFilterDefData(mergeAllData, obj) // 赋值所有自定义数据
  defLog.message.message = JSON.stringify(obj)
  return defLog
}

const getServerCustomData = function (ctx: any, mergeData?: any) {
  const { originalUrl, req = {} } = ctx || {}
  // const deviceObj = getServerDevice(ctx)
  const ua = (ctx.req.headers['user-agent'] || '').toLocaleLowerCase()
  const isMobile = /mobile/.test(ua) && (/iphone|ipad/.test(ua) || /android/.test(ua))
  const isBot = process.client ? ctx?.store?.state?.klook?.isBot : isSearchBot(ua)
  const obj = {
    is_bot: Number(!!isBot),
    host: req.headers['x-klook-host'] || req.headers.host || GlobalStr.na,
    // platform: deviceObj.platform || GlobalStr.na,
    platform: isMobile ? 'mobile' : 'desktop',
    url: originalUrl || req?.url,
    referer: req.headers.referer || GlobalStr.na,
    user_agent: ua,
    ...(mergeData || {})
  }
  return obj
}

// const getServerDevice = function (ctx: any, options = { tablet: true }) {
//   const APP_RE = /klook\/(\d+\.)+\d+/i
//   const IOS_RE = /iPad|iPhone|iPod/i
//   const PHONE_RE = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i
//   const TABLET_RE = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino|android|ipad|playbook|silk/i
//   const DEVICE_RE = options.tablet ? TABLET_RE : PHONE_RE

//   function getDeviceContext(ctx: any) {
//     const ua = ctx.req.headers['user-agent')
//     const platformStatus = ctx.cookies.get('klk_platform_status')
//     const isIOS = IOS_RE.test(ua)
//     const isKlookApp = APP_RE.test(ua)
//     // url上的mpType
//     const queryMp = getQueryKey('mp', ctx.query)
//     const isAdminPreview = ctx.path.includes('/admin_preview/')
//     /**
//      * @mpType: number 小程序环境标识
//      * 非小程序web-view环境：0
//      * 在小程序的web-view环境中取值范围 1: 微信小程序环境 2: 百度小程序环境（小红书）,3:抖音
//     */
//     const mpType = ([[/miniprogram/i, /micromessenger/i], /swan\//i, /toutiaomicroapp/i].findIndex(reg => Array.isArray(reg) ? reg.every(r => r.test(ua)) : reg.test(ua)) + 1) || (process.env.APP_ENV !== 'production' && Number(queryMp))
//     const isMobile = isIOS || isKlookApp || mpType > 0 || DEVICE_RE.test(ua) || isAdminPreview
//     const isForceDesktop = platformStatus === 'desktop'

//     const obj = {
//       isIOS,
//       isKlookApp,
//       isMobile,
//       platform: isForceDesktop ? 'desktop' : (isMobile ? 'mobile' : 'desktop'),
//       platformMp: ['', 'mp-wechat', 'mp-xiaohongshu', 'mp-bytedance'][mpType as number]
//     }
//     return obj
//   }
//   return getDeviceContext(ctx)
// }

export const getServerRedirectData = function (params: any) {
  const { status, redirect_url = '', reason = '' } = params || {}
  const obj = {
    type: 'exp_redirect_log_type',
    status,
    redirect_url,
    reason
  }

  return obj
}

/***
 *使用
import { sentServerLog, getServerRedirectData } from '~/share/exp-custom-log'
sentServerLog(ctx, { custom_data: getServerRedirectData({ status, redirect_url }) })
***/
export const sentServerLog = function (ssrContext: any, options: CustomOptIf) {
  const opt: CustomOptIf = getInitOptions(options)
  const { custom_data } = opt
  // console.log('sentServerLog = ', getInitLogData(ssrContext, custom_data))
  if (!custom_data || !custom_data.type) {
    return
  }
  const { req } = ssrContext || {}
  const { logquery } = req || {}
  if (!logquery || !logquery.general) {
    // 本地开发环境logquery未注册，打印调试可在这之前！
    return
  }
  const logData = getInitLogData(ssrContext, custom_data)
  logquery.general(logData)
  return logData
}
