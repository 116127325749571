























import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import { merge } from 'lodash'

@Component
export default class PromotionTag extends Vue {
  @State klook!: Data.Klook

  @Prop() autoTips?: boolean
  @Prop({ default: () => ({}) }) poptipData?: Object

  showTips = false

  defObj = {
    dark: true,
    flip: true,
    preventOverflow: true
  }

  showTipsHandler(message: string, _: any) {
    const { klook, showTips } = this
    if (klook.platform === 'mobile' && showTips) {
      this.$toast({
        duration: 2000,
        message
      })
      _.preventDefault()
      _.stopPropagation()
      return false
    }
  }

  get calcPoptip() {
    return merge(this.defObj, this.poptipData)
  }

  get customStyle() {
    const { class: myClass, maxWidth, color, borderColor, backgroundColor, borderRadius } = this.$attrs
    return { class: myClass, maxWidth, color, borderColor, backgroundColor, borderRadius }
  }

  get leftIcon() {
    const obj: any = this.$attrs?.leftIcon
    return obj
  }

  get rightIcon() {
    const obj: any = this.$attrs?.right_icon || {}
    return obj
  }

  clickRightIcon(title: string) {
    this.$alert(title)
  }

  initTips() {
    const { autoTips } = this
    if (autoTips) {
      const dom: any = this.$el.querySelector('.js-tag-content-node')
      const parentNode: any = this.$el.querySelector('.js-tag-body-node')
      if (dom && dom.offsetHeight > parentNode.offsetHeight) {
        this.showTips = true
      }
    } else {
      this.showTips = false
    }
  }

  updated() {
    this.initTips()
  }

  mounted() {
    this.initTips()
  }
}
