






import { Component } from 'nuxt-property-decorator'
import PriceGuarantee from '../../price-guarantee.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({
  components: {
    PriceGuarantee
  }
})
export default class ExperiencePriceGuarantee extends BusinessBase {
  static displayName = 'MobilePrice_guarantee'

  get bestPriceGuarantee() {
    return this.businessData.price_guarantee
  }

  get priceGuaranteeVisible() {
    return !this.businessData.custom_config_hide_price_guarantee && this.bestPriceGuarantee
  }
}
