






















































import { Component, Prop, Vue } from 'nuxt-property-decorator'

@Component
export default class LineUpStatus extends Vue {
  @Prop({ default: '00:00:00' }) timeCount!: string
  @Prop({ default: false }) canJoin!: boolean
  @Prop({ default: {} }) info!: any

  get status() {
    return this.info.flash_sale_status.user_status
  }
}
