






import { Vue, Component, Prop } from 'nuxt-property-decorator'
import throttle from 'lodash/throttle'
import { nodeScrollTop, scroll2, windowScrollTop } from '~/share/scroll2/scroll2'

@Component({})
export default class ScrollToTop extends Vue {
  @Prop({ default: () => '0px' }) setBottom!: string // 按钮距离底部距离设置
  @Prop({ default: () => 1800 }) distance!: number // 滑动多少距离后显示
  scrollToTopVisible: boolean = false

  $verticalSearchBox: any = null
  $headerSearchBox: any = null

  headerSearchControl() {
    this.$headerSearchBox = document.querySelector('#js-header-search-box') as HTMLElement
    this.$headerSearchBox.style.display = 'none'
    this.$verticalSearchBox = document.querySelector('#js-desktop-platform-vertical-entrance')
    // this.scrollFun(this.$verticalSearchBox, this.$headerSearchBox)
  }

  scrollFun(verticalSearchBox: any, headerSearchBox: any) {
    if (verticalSearchBox) {
      const top = windowScrollTop()
      const t = nodeScrollTop(verticalSearchBox)
      headerSearchBox.style.display = top > t + 50 ? 'block' : 'none'
    } else {
      headerSearchBox.style.display = 'block'
    }
  }

  destinationText() {
    const destinationText = document.querySelector('#js-destination-box-text') as HTMLElement
    const headerSearchBox = document.querySelector('#js-header-search-box') as HTMLElement
    if (!destinationText) {
      return false
    }
    if (headerSearchBox) {
      destinationText.style.display = headerSearchBox.style.display === 'block' ? 'none' : 'block'
    } else {
      destinationText.style.display = 'block'
    }
  }

  initScroll() {
    // this.scrollFun(this.$verticalSearchBox, this.$headerSearchBox)
    // this.destinationText()
    const top = windowScrollTop()
    if (top > this.distance) {
      this.setScrollToTopVisible(true)
    } else {
      this.setScrollToTopVisible(false)
    }
  }

  setScrollToTopVisible(val: boolean) {
    this.scrollToTopVisible = val
  }

  backToTop() {
    scroll2({
      scrollY: 0
    })
  }

  get throttleScroll() {
    return throttle(this.initScroll, 200)
  }

  mounted() {
    // this.headerSearchControl()
    // this.destinationText()
    window.document.addEventListener('scroll', this.throttleScroll)
  }

  beforeDestroy() {
    window.document.removeEventListener('scroll', this.throttleScroll)
  }
}
