import { Vue, State, namespace, Component } from 'nuxt-property-decorator'
import apis from '~/share/data/apis'

const ActivityModule = namespace('traveller/activity')

@Component
export default class ClaritySdk extends Vue {
  @State klook!: Data.Klook
  @ActivityModule.State activityDetail!: any

  get activityId() {
    return this.activityDetail?.basic_data?.activity_id
  }

  get platform() {
    return this.klook.platform
  }

  async getActivitySdkInfo() {
    const res = await this.$axios.$get(apis.getActivitySdkInfo, {
      params: { activity_id: this.activityId }
    })

    if (res?.success) {
      if (res?.result) {
        this.createScript()
      }
    }
  }

  createScript() {
    // if (this.platform === 'mobile') {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.defer = true
    script.id = 'claritysdk'
    script.textContent = `(function(c,l,a,r,i,t,y){
        c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
        t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
        y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "g27f3n93cw");`

    document.body.appendChild(script)
    // }
  }

  mounted() {
    if (['www.klook.com'].includes(window.location.host)) {
      this.getActivitySdkInfo()
    }
  }

  beforeDestroy() {
    if (typeof window.clarity === 'function') {
      window.clarity('stop')
    }

    const clarityScript = document.getElementById('claritysdk')
    if (clarityScript) {
      document.body.removeChild(clarityScript)
    }
  }
}
