




































































































import { Component, Watch, Prop, State, Ref, mixins } from 'nuxt-property-decorator'
import SpecTitle from './spec-title.vue'
import AttrDetailBtn
  from './attr-detail-btn.vue'
import ActivityPackageBtn
  from '~/components/traveller/activity/activity-package-options/mobile/activity-package-btn-itinerary.vue'
import AttrBase from '~/components/experience-booking/experience-activity/package-options/base/attr-base'
import ShowMoreBox from '~/components/experience-booking/experience-activity/show-more-box/index.vue'
import { CardSwiper } from '~/components/traveller/home-page/card-swiper'
import { isInViewPortAll } from '~/share/utils'
// import { windowScrollTop } from '~/share/scroll2/scroll2'
import { scrollToCenter } from '~/share/scroll2/scroll2'
import SinglePop from '~/components/experience-booking/experience-activity/package-options/package-attributes/mobile/single-pop.vue'
import SingleBase from '~/components/experience-booking/experience-activity/package-options/package-attributes/mobile/single-base'

type state = 'attrUpdate' | 'dateUpdate' | 'normal'

@Component({
  components: {
    CardSwiper,
    ShowMoreBox,
    SpecTitle,
    ActivityPackageBtn,
    AttrDetailBtn,
    SinglePop
  }
})
export default class PackageAttributes extends mixins(AttrBase, SingleBase) {
  @State klook!: Data.Klook
  @Prop({ default: 55 }) waitTime!: number

  @Ref() swiperRef!: any

  winTop = 0
  scrollStopTimer: any = null
  popVisible = false
  showSinglePopAnimate = true

  @Watch('value', { immediate: true })
  activeSectionChange(val: string) {
    if (process.client && val) {
      setTimeout(this.handlerSlide, 100)
    }
  }

  handleAttrPopSelect(attr: any, item: any, index: number, len: number) {
    this.popVisible = false
    this.handleAttrSelect(attr, item, { index, len, type: 'PackageOptionsBring' })
  }

  heroSlideToCenter() {
    this.handlerSlide()
  }

  handlerSlide() {
    const scrollElm = this.$el.querySelector('#js-swiper-dom .card-swiper') as HTMLElement
    const childDom = this.$el.querySelector('#js-swiper-dom .js-active') as HTMLElement
    scrollToCenter(childDom, scrollElm)
  }

  getItem(val: any) {
    return this.$el.querySelector(`[data-id="${val}"]`)
  }

  checkSwiperInView() {
    const el: any = document.querySelector('#js-swiper-dom')
    return isInViewPortAll(el)
  }

  slide(val: string, options?: any) {
    const opt = { behavior: 'instant', ...(options || {}) }
    setTimeout(() => {
      const node = this.getItem(val)

      if (node && this.checkSwiperInView()) {
        node.scrollIntoView({
          inline: 'center',
          block: 'nearest',
          ...opt
        })
      }
    })
  }

  computeLocation(attr: any) {
    const attrList = this.specList?.[0]?.attr || []
    const oldIndex = attrList.findIndex(item => item?.isActive)
    const newIndex = attrList.findIndex(item => item === attr)
    this.$emit('directionChange', oldIndex > newIndex ? 'right' : 'left')
  }

  handleAttrSelect(attr: any, item: any, spmObj: Record<string, string | number> | null = null) {
    this.computeLocation(attr)
    this.handleSelect(attr, item, spmObj)
  }
}
