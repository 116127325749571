<template>
  <CommonContainer
    v-bind="$attrs"
    v-on="$listeners"
  ></CommonContainer>
</template>
<script>
import { Vue, Component } from 'nuxt-property-decorator'
import CommonContainer from './common'

// tab 和common 一个样
@Component({
  components: {
    CommonContainer
  }
})
export default class Tab extends Vue {}
</script>
