































import { Component, Getter, Watch } from 'nuxt-property-decorator'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import { formatThousands } from '~/share/utils'

@Component
export default class CreditBanner extends BusinessBase {
  static displayName = 'MobileCredits_rebate_banner'
  @Getter useCredits!: boolean
  @Getter isGuestCheckout!: boolean

  get valid() {
    return this.creditRebate && this.useCredits && !this.isGuestCheckout
  }

  get href() {
    return this.$href('/credits/landing')
  }

  get creditRebate() {
    return this.businessData.credit_rebate
  }

  get sellingPrice() {
    return this.businessData.selling_price || '0'
  }

  get minRebate() {
    return this.businessData.min_rebate
  }

  get rebatePrice() {
    const rebatePrice = (parseFloat(this.sellingPrice) * parseFloat(this.minRebate) / 100).toFixed(1)
    return formatThousands(rebatePrice)
  }

  get title() {
    return this.$t('Credit.Banner.Title', [this.creditRebate])
  }

  get content() {
    return this.$t('Credit.Banner.Content', [this.klook.currencySymbol, this.rebatePrice])
  }

  @Watch('valid', { immediate: true })
  validChange(val: boolean) {
    if (!val) {
      this.hideSection()
    }
  }
}
