
























import { Component } from 'nuxt-property-decorator'
import Base from '~/components/experience-booking/experience-activity/fnb-activity-awards/base'
import Textlist from '~/components/experience-booking/experience-activity/fnb-activity-awards/components/textlist.vue'

@Component({
  components: {
    Textlist
  }
})
export default class DesktopfnbActivityInfo extends Base {
  static displayName = 'DesktopFnb_awards'
  visable = false
}
