





























import { Component, State, Prop, mixins } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty.js'
import NewActivityPackageDetail from '~/components/traveller/activity/activity-package-options/desktop/new-package-detail/index.vue'
import { Activity } from '~/types/traveller/activity'
import PublishAi from '~/components/common/publish-ai/index.vue'
import { getModalConfig } from '~/components/experience/load-hander/index.vue'
import { PackageDetailStructured } from '~/components/common/package-detail-v2'
import newPackageDetailShowMixin from '~/components/common/package-detail-v2/show-mixin'

@Component({
  components: {
    PublishAi,
    NewActivityPackageDetail,
    PackageDetailStructured
  }
})
export default class PackageDetailModule extends mixins(newPackageDetailShowMixin) {
  @State klook!: Data.Klook
  @Prop() packageDetail!: Activity.packageDetail
  @Prop() currentSelectedPackage!: Activity.activityPackage
  @Prop() specList!: Activity.spec[]
  @Prop() isPresale!: boolean
  @Prop({ default: true }) expandAll!: boolean
  @Prop({ type: Boolean, default: false }) useImageSwiper!: boolean
  @Prop({ type: Number, default: 0 }) imageCardSwiperType!: number
  @Prop({ type: String, default: '' }) imageGroupStyle!: string

  modalConfig = getModalConfig('common-large-config')

  visible: boolean = false
  showAllSpec: boolean = false

  closeModal() {
    this.visible = false
    this.$emit('moduleClose')
  }

  handleScrollTo(element: any = null) {
    setTimeout(() => {
      const $elem = this.$el as HTMLElement
      const main = $elem.querySelector('.package-detail-content') as HTMLElement
      const elem = main.querySelector(element) as HTMLElement
      const head = $elem.querySelector('.klk-modal-content .title') as HTMLElement

      if (elem) {
        const offsetTop = elem.offsetTop - (160 + head.offsetHeight / 2)
        main.scrollTo({
          top: offsetTop,
          behavior: 'smooth'
        })
      }
    }, 400)
  }

  get specs() {
    if (isEmpty(this.currentSelectedPackage)) {
      return []
    }

    const { specAttrIds = [] } = this.currentSelectedPackage
    const specs = this.specList.map((spec) => {
      const { attrs } = spec

      return {
        ...spec,
        attr: attrs.find((attr) => specAttrIds.includes(attr.id)) || {}
      }
    })
    return specs
  }

  get isAiTranslation() {
    return this.translateButtonVisible && this.isTranslated
  }

  get translateButtonVisible() {
    return !!this.packageDetail?.enableTranslationButton
  }

  get isTranslated() {
    return !this.packageDetail?.translationButtonStatus
  }

  mounted() {
    this.visible = true
  }
}
