







import { Vue, Prop, Component } from 'nuxt-property-decorator'
import lazyObserver from '../utils/lazy-observer'

@Component
export default class LazyComponent extends Vue {
  @Prop({ type: Function, default: (_parmas?: any, _options?: any) => Promise.resolve() }) beforeLoad!: (_parmas?: any, _options?: any) => Promise<any>
  @Prop({ type: String, default: '100px' }) rootMargin!: string

  initLoad = false

  mounted() {
    const lazyObserveInstance = lazyObserver({
      rootMargin: this.rootMargin, // 10px触发加载
      callBack: async () => {
        await this.beforeLoad(undefined, { isClientReport: true })
        this.initLoad = true
      },
      observeDom: this.$el
    })

    this.$once('hook:beforeDestroy', () => {
      // 释放
      lazyObserveInstance!.disconnect()
    })
  }
};
