











import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class PromotionTag extends Vue {
  @Prop() maxWidth!: any
  get calcIcon() {
    const { icon } = this.$attrs
    return (icon || {}) as any
  }

  get calcStyle() {
    const { maxWidth } = this
    const { background_color: backgroundColor, text_color: color, border_color: borderColor, radius } = this.$attrs
    return {
      maxWidth,
      backgroundColor,
      color,
      borderColor,
      borderRadius: radius + 'px'
    }
  }
}
