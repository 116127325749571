
























import { Component, Vue } from 'nuxt-property-decorator'
import DynamicMapWrap from '~/components/experience-booking/experience-activity/dynamic-component/map/dynamic-map.vue'

@Component({
  components: {
    DynamicMapWrap
  }
})
export default class DynamicTitleMap extends Vue {}
