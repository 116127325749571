import { Component, namespace, State, Vue } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import flowRight from 'lodash/flowRight'
import { getOperationTagIds, ttdHotelVsStaycationMapping, cacheRequestActivity } from '~/share/data/activity'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { getAntiSpiderHead } from '~/assets/scripts/head'
import { currencyExchange } from '~/share/utils'
import { convertToFrontendCode } from '~/share/data/language'
import { getImageName } from '~/share/data/image'
import * as breadcrumbs from '~/components/traveller/activity/activity-base/breadcrumbs'
import AB67880 from '~/components/traveller/activity/activity-base/AB67880'
import activityDetailApi
  from '~/components/experience-booking/experience-activity/experience-activity-base/activity-detail-api'
import { getServerRedirectData, sentServerLog } from '~/share/exp-custom-log'

const experience = namespace('experience')

interface ISeo {
  title: string,
  description: string
}

const ActivityModule = namespace('traveller/activity')

function escapeSeoUrl(url: string) {
  return url.replace('\u2028', '')
}

@Component({
  validate({ params }) {
    const { preview, activityPath } = params
    // admin配置的seo-url可能有特殊字符
    const validActivityPath = escapeSeoUrl(activityPath)
    // preview 预览admin配置页面
    if (preview) {
      return /\d+/.test(validActivityPath)
    }

    return /^\d+$/.test(validActivityPath) || /^\d+-(.+)?/.test(validActivityPath)
  },
  async fetch(ctx) {
    const { preview, activityPath } = ctx.params
    const { translation } = ctx.query
    const { sales_channels = null } = ctx.query
    // const { store: { dispatch } } = ctx

    const isGuestCheckout = ctx.store.state.klook.utilConfig?.is_guest_checkout
    const { language, ipCountry, currency } = ctx.store.state.klook
    const { $href, $axios } = ctx.app
    /**
     * preview admin编辑完后，预览admin配置的页面，路由参数activityPath为活动id
     * preview为空时是普通活动页，activityPath为id-seoUrl
     */
    const activityPathArray = activityPath.split('-')
    const activityId = preview ? activityPath : activityPathArray[0]
    const partnerType = isGuestCheckout ? 'guest_checkout' : ''

    // 特定ttd hotels重定向到staycation 做分流实验
    const hotelId = (ttdHotelVsStaycationMapping as any)[activityId]

    if (hotelId) {
      const state = ctx?.app?.store?.state
      if (AB67880(state?.common?.kepler, state?.klook?.platform)) {
        const queries = 'check_in_num=1&check_out_num=2&page_source=staycation'
        const staycationUrl = $href(`/hotels/${hotelId}/?${queries}`, language, '')
        ctx.redirect(staycationUrl)
        return
      }
    }

    // 是否使用新模板(灰度测试活动页缓存用)
    let templateType = 0
    const cacheKey = `${language}:${ipCountry}:${currency}`
    if (cacheRequestActivity.includes(cacheKey)) {
      templateType = 1
    }

    const api = activityDetailApi(ctx)

    const activityDetailRes = await $axios.$get(api, {
      params: {
        activity_id: activityId,
        preview: preview ? 1 : 0,
        partner_type: partnerType,
        translation: translation === '1' ? 'true' : 'false',
        __sales_channels: process.env.APP_ENV === 'production' ? null : sales_channels,
        __template_type: templateType
      }
    })

    const activityDetail = activityDetailRes?.result || {}
    // 接口返回校验
    if (!activityDetailRes?.success || isEmpty(activityDetail)) {
      ctx.error({ statusCode: 404 })
      sentServerLog(ctx, { custom_data: getServerRedirectData({ status: 404 }) })
      return
    }

    // activity_page_template_id 意为活动页页面模板 id，和活动的 template_id 不是一个东西
    ctx.store.commit('SET_PAGE_TEMPLATE', `activity_template_v2:${activityDetail?.basic_data?.activity_page_template_id}`)

    activityDetail.preview = preview ? 1 : 0

    activityDetail.version = 1

    ctx.store.commit('traveller/activity/SET_ACTIVITY_DETAIL', activityDetail)
    if (activityDetail?.basic_data?.support_language) {
      const supportLanguage = activityDetail?.basic_data?.support_language.map((v: string) => convertToFrontendCode(v))
      ctx.store.dispatch('syncSupportLanguages', supportLanguage)
    }
  }
})
export default class ExperienceActivityBase extends Vue {
  @experience.Mutation sectionActDataMutations!: Function
  @State klook!: Data.Klook
  @ActivityModule.State activityDetail!: any

  get preview(): number {
    return this.activityDetail?.preview
  }

  get theme(): ExperienceActivity.ITheme {
    return this.activityDetail?.theme
  }

  get language() {
    return this.klook.language
  }

  getSEODiscount() {
    const { discount } = this.basicData || {}

    if (discount >= 6 && discount <= 90) {
      const base = Math.floor(discount / 10)
      const remainder = discount % 10
      const suffix = remainder <= 5 ? 5 : 10
      return 10 * base + (remainder === 0 ? 0 : suffix)
    }

    return ''
  }

  updateSEOMeta({ title, description }: ISeo) {
    // 需要处理语言
    // const languageScope = ['en-SG', 'en-PH', 'vi', 'en-IN']
    // const discount = this.getSEODiscount()

    const [seoTitle, seoDescription] = [title, description]

    // if (discount && languageScope.includes(this.language)) {
    //   // 特殊符号开头不需要修改 seo，括号里的是 USP(unique selling proposition) 内容 更需要被搜索引擎 crawl 到
    //   const specialStartReg = /[([（【]/
    //   const specialReg = /\d+%/
    //   const discountTxt = this.$t('activity.seo.discount', [discount])
    //
    //   if (title.search(specialReg) === -1 && description.search(specialReg) === -1) {
    //     if (title && title.search(specialStartReg) !== 0) {
    //       seoTitle = `${discountTxt} | ${title}`
    //     }
    //
    //     if (description && description.search(specialReg) !== 0) {
    //       seoDescription = `${discountTxt} | ${description}`
    //     }
    //   }
    // }

    return {
      title: seoTitle,
      description: seoDescription
    }
  }

  /***
   * Duplicate Meta Tags - All Activity Pages with Same Language (OnCrawl)
   * https://orion.klook.io/1000021/68/orion/features/1028454/detail
   */
  addSeoCountry({ title, description }: ISeo): ISeo {
    // const seoCountry = languageTitleDescCountryMap[this.language] || ''

    const result = {
      title: '',
      description: description || this.$t('global.seo.title')
    }

    // if (seoCountry) {
    //   return reduce(result, (accu, v, k) => {
    //     const meta = v.includes(this.$t('global.seo.titlesite'))
    //       ? v + seoCountry
    //       : v + this.$t('global.seo.titlesite') + seoCountry
    //
    //     return {
    //       ...accu,
    //       [k]: meta
    //     }
    //   }, {} as ISeo)
    // }
    // if (this.language === 'ja') {
    //   result.title = `${result.title} | KLOOK (クルック)`
    // }
    if (this.isWhiteLabelEnv) {
      result.title = title ? title + ' - ' + this.$t('54096') : this.$t('54096')
    } else {
      result.title = title ? title + ' ' + this.$t('global.seo.titlesite') : this.$t('global.seo.title')
    }
    return result
  }

  head() {
    const activityHead: any = { meta: [], script: [] }

    const {
      seo: seoData,
      sub_category_id,
      leaf_category_id,
      activity_page_template_id,
      price,
      sold_out,
      act_product_type
    } = this.basicData || {}

    const {
      city_id,
      country_id,
      activity_id,
      template_id,
      published,
      country_name,
      city_name,
      card_tags,
      banner_url,
      title: seoTitle,
      description: seoDescription,
      keywords,
      images,
      seo_url,
      video_url,
      last_updated_time,
      review_count,
      latest_best_review,
      selling_price,
      score,
      usage_images,
      bread_crumb,
      hide_water_mark,
      no_index,
      no_hreflang
    } = seoData || {}

    const { currency, webp } = this.klook
    const { path } = this.$route

    const prefix = `https://res.klook.com/images/fl_lossy.progressive,q_65/c_fill,w_1200,h_630/${hide_water_mark ? '' : 'w_80,x_15,y_15,g_south_west,l_Klook_water_br_trans_yhcmh3/'}activities/`
    const encodeTitle = encodeURIComponent(seoTitle)
    const metaBannerImage = { content: `${prefix}${getImageName(banner_url)}/${encodeTitle}.jpg`, hid: getImageName(banner_url) }
    const metaShowedImages = (images || []).map((v: {image_url: string}) => ({
      content: `${prefix}${getImageName(v.image_url)}/${encodeTitle}.jpg`,
      hid: getImageName(v.image_url)
    }))
    const metaImages = [metaBannerImage, ...metaShowedImages].slice(0, 1)

    const seo = {
      title: seoTitle,
      description: seoDescription,
      keywords
    }

    const { title, description } = flowRight(this.addSeoCountry, this.updateSEOMeta)(seo)
    if (!this.klook.utilConfig?.whiteLabelUtilConfig) {
      activityHead.title = title
    }

    activityHead.meta = [
      {
        hid: 'description',
        name: 'description',
        content: description
      },
      ...activityHead.meta
    ]
    if (keywords) {
      activityHead.meta.push({
        hid: 'keywords',
        name: 'keywords',
        content: keywords
      })
    }

    if (!no_hreflang) {
      const link = this.$canonical({ indexable: true })
      activityHead.link = link
    }

    if (no_index) {
      activityHead.meta = [
        this.$robots('noindex,nofollow'),
        ...activityHead.meta
      ]
    }

    // structured data
    if (video_url) {
      activityHead.script.push(breadcrumbs.activityVideo({
        title,
        description,
        lastUpdatedTime: last_updated_time,
        siteName: this.$t('seo.site.name'),
        videoUrl: video_url,
        bannerImage: banner_url,
        webp: !!webp
      }))
    }

    // 活动在当前页面下有发布
    if (published) {
      activityHead.script.push(breadcrumbs.experienceActivityBreadCrumb(bread_crumb))
    }

    if (review_count > 0 && published) {
      const review = latest_best_review?.reviews?.[0]
      activityHead.script.push(breadcrumbs.activityProduce({
        title,
        activityId: activity_id,
        seoDescription: description,
        reviewCount: review_count,
        latestBestReview: review && { rating: review.rating, author: review.user_name, content: review.content, date: review.review_time_utc },
        sellingPrice: Number(selling_price),
        score,
        canonicalPath: this.$route.path,
        currency,
        images,
        usageImages: usage_images,
        bannerImage: banner_url,
        isNoWatermarkActivity: hide_water_mark
      }))
    }

    activityHead.meta = [
      ...activityHead.meta,
      this.$setMeta({ name: 'google', content: 'notranslate' }),
      this.$setMeta({ property: 'og:locale', content: this.$i18n.locale }),
      this.$setMeta({ property: 'og:type', content: 'product' }),
      this.$setMeta({ property: 'og:title', content: title }),
      this.$setMeta({ property: 'og:description', content: description }),
      this.$setMeta({ property: 'og:url', content: `https://${this.klook.host}${path}` }),
      ...metaImages.map(({ content, hid }) => ({ property: 'og:image', content, hid: `og${hid}` })),
      this.$setMeta({ property: 'og:site_name', content: this.$t('seo.site.name').toString() }, true),

      this.$setMeta({ property: 'product:price:amount', content: price?.selling_price }),
      this.$setMeta({ property: 'product:price:currency', content: currency }),

      this.$setMeta({ name: 'twitter:card', content: 'summary_large_image' }),
      this.$setMeta({ name: 'twitter:title', content: title }),
      this.$setMeta({ name: 'twitter:description', content: description }),
      this.$setMeta({ name: 'twitter:site', content: '@KlookTravel' }),
      ...metaImages.map(({ content, hid }) => ({ name: 'twitter:image', content, hid: `twitter${hid}` })),

      this.$setMeta({ name: 'twitter:app:name:iphone', content: this.$t('global.app.name').toString() }, true),
      this.$setMeta({ name: 'twitter:app:id:iphone', content: '961850126' }),
      this.$setMeta({ name: 'twitter:app:url:iphone', content: `klook://activity/${activity_id}` })
    ]

    const activityEtra = {
      page_template_id: activity_page_template_id,
      activity_id,
      sub_category_id,
      leaf_category_id,
      status: sold_out ? 'sold_out' : published ? 'published' : 'unpublished'
    } as Record<string, string | number | string[]>

    if (act_product_type === 'hero_page') {
      activityEtra.PageType = 'TNAHero'
    }

    // if (this.klook.platform === 'mobile') {
    const packageOptionType: Record<string, string> = {
      package_option_card_sku: 'sku',
      package_option: 'attribute',
      package_options_spec_type: 'packagelist',
      package_options_group: 'packagegroup',
      package_option_card_sku_with_detail: 'sku'
    }

    const packageName = (this.sectionsData || [])
      .map((item) => item?.meta.name)
      .filter((item) => Object.keys(packageOptionType).includes(item))[0]

    if (packageName && packageOptionType[packageName]) {
      activityEtra.PackageOptionType = packageOptionType[packageName]
    }
    // }

    // PageView 添加tagNameList埋点信息
    if (!isEmpty(card_tags)) {
      activityEtra.TagNameList = Object.values(card_tags)
    }

    activityHead.bodyAttrs = {
      'data-spm-page': `Activity?oid=activity_${activity_id}&ext=${encodeURIComponent(JSON.stringify(activityEtra))}`
    }

    const antiSpiderHead: any = getAntiSpiderHead(this)
    // 初始化GA埋点custome dimension
    activityHead.script = [
      ...activityHead.script,
      ...(antiSpiderHead.script || []),
      {
        src: 'https://cdn.klook.com/s/dist_web/common/1.12.18/K004978.js',
        type: 'text/javascript'
      },
      this.$insetDataLayer({
        ActId: activity_id,
        ActUrl: seo_url,
        CityId: city_id,
        CountryId: country_id,
        TemplateId: template_id,
        ActPrice: currencyExchange(Number(price?.selling_price), currency).toFixed(1),
        ActAvailability: published ? 'Available' : 'Unavailable',
        operationTag: getOperationTagIds(card_tags).join(',') || -1,
        CountryName: country_name,
        CityName: city_name
      })
    ]

    return activityHead
  }

  get isWhiteLabelEnv() {
    return !!this.klook?.utilConfig?.isWhiteLabelEnv
  }

  get sectionsData(): ExperienceActivity.Sections {
    return this.activityDetail?.sections
  }

  get sourceData(): ExperienceActivity.ISources {
    return this.activityDetail?.source
  }

  get basicData(): ExperienceActivity.IBasicData {
    return this.activityDetail?.basic_data || {}
  }

  mounted() {
    const themeName = this.theme?.name

    if (themeName) {
      import(`~/components/experience-booking/experience-activity/theme/${themeName}.scss`)
    }

    const sdk = this.klook.utilConfig?.whiteLabelUtilConfig?.sdk
    if (sdk) {
      sdk.updateSupportLanguages(this.basicData?.support_language)
      sdk.syncRecentlyViewed({
        vertical_id: this.basicData?.activity_id, // 业务线的活动id，int，必传
        vertical_type: '100', // 搜索的业务线类型id，string，必传，ttd为100
        user_id: this.$store.state?.auth?.user?.user_id || 0 // 用户id，int，没有可以不传或者传0
      })
    }

    this.initRootData()
  }

  initRootData() {
    const { template_id: templateId } = this.basicData || {}
    const isWifi = templateId === 7
    const isSimCard = templateId === 8
    const isWifiSimCard = isWifi || isSimCard
    const sectionActData: any = {
      templateId,
      isWifi,
      isSimCard,
      isWifiSimCard
    }
    this.sectionActDataMutations(sectionActData)
  }
}
