







































































import { Component, Vue, Prop, namespace, Ref, Inject } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import PackageDetailList from '~/components/common/package-detail-list/index.vue'
import PackageDetailCollapse from '~/components/common/package-detail-collapse/package-detail-collapse.vue'

import { Activity } from '~/types/traveller/activity'
import AttractionIncluded from '~/components/experience-booking/experience-activity/package-options/attraction-included/mobile/index.vue'
import ActMarkdown from '~/components/experience-booking/experience-activity/markdown/index.vue'
import KlkItineraryReminder from '~/components/common/itinerary-reminder/index.vue'
import KlkItineraryReminderV2 from '~/components/common/itinerary-reminder-v2/index.vue'
import PackageIncluded from '~/components/common/package-included/index.vue'

import { renderMarkdownObj } from '~/assets/scripts/markdown'
import { handleIHTrack } from '~/components/experience-booking/booking-details/base/inHouseTrack'

const ActivityModule = namespace('traveller/activity')

@Component({
  components: {
    AttractionIncluded,
    ActMarkdown,
    PackageDetailCollapse,
    PackageDetailList,
    KlkItineraryReminder,
    KlkItineraryReminderV2,
    PackageIncluded
  }
})
export default class Index extends Vue {
  @Prop() packageDetail!: Activity.packageDetail
  @Prop({ default: true }) expandAll!: boolean
  @Prop({ default: false }) expandItinerarySection!: boolean
  @ActivityModule.State activityDetail!: any
  @Ref() PackageDetailList!: any
  @Inject({ from: 'setDetailElem', default: () => {} }) setDetailElem!: Function
  @Prop({ type: Boolean, default: false }) isAiTranslation!: boolean
  @Inject({ from: 'onlyShowDetailSheet', default: false }) onlyShowDetailSheet!: boolean
  @Inject({ from: 'closeDetailModule', default: () => () => {} }) closeDetailModule!: Function
  @Prop({ type: Number, default: 0 }) imageCardSwiperType!: number

  reminderSectionData: any = null

  get itinerarySpm() {
    return handleIHTrack({
      type: 'module',
      spm: 'SeeItineraryDetails',
      query: {
        oid: `package_${this.packageDetail.packageId}`
      }
    })
  }

  get valid() {
    return !isEmpty(this.packageDetail)
  }

  get subCategoryId() {
    return this.packageDetail?.sub_category_id ?? ''
  }

  // 有 itinerary 需要使用新的套餐详情样式
  get useNewVersion() {
    const filterSections = this.filterSections || []
    return !!filterSections.find((item: any) => item.section_type === 'itinerary')
  }

  // 兼容数据
  get formatPackageDetail() {
    if (this.valid) {
      const { usage_validity_render_obj, pass_standard_activity_info, usageValidity, passStandardActivityInfo, sectionContent, section_content, activity_id, activityId, package_id, packageId } = this.packageDetail

      return {
        ...this.packageDetail,
        passStandardActivityInfo: pass_standard_activity_info || passStandardActivityInfo,
        usageValidity: usageValidity || usage_validity_render_obj,
        sectionContent: sectionContent || section_content,
        activityId: activity_id || activityId,
        packageId: package_id || packageId
      }
    }

    return {} as Activity.packageDetail
  }

  get renderContent() {
    const content = this.formatPackageDetail?.usageValidity || []
    return renderMarkdownObj(content)
  }

  get sections() {
    return cloneDeep(this.formatPackageDetail?.sectionContent?.sections)
  }

  get includedSections() {
    if (this.sections?.length) {
      return this.sections.filter((section: any) => section.section_type === 'whats_included')
    }

    return []
  }

  get filterSections() {
    if (this.sections?.length) {
      return this.sections.filter((section: any) => section.section_type !== 'itinerary_reminder')
    }

    return []
  }

  get hasReminderSection() {
    if (this.sections?.length) {
      const data = this.sections.find((item: any) => item.section_type === 'itinerary_reminder')

      data && (this.reminderSectionData = data)

      return !!data
    }

    return false
  }

  showItinerary() {
    this.PackageDetailList && this.PackageDetailList.showItineraryDetail()
  }

  mounted() {
    if (this.onlyShowDetailSheet && this.useNewVersion) {
      this.showItinerary()
    }
  }
}
