








































import { Component, Prop, Vue } from 'nuxt-property-decorator'
import ActivityLayer from '~/components/traveller/activity/layer/activity-layer.vue'

@Component({
  components: {
    ActivityLayer
  }
})
export default class PriceGuarantee extends Vue {
  @Prop() bestPriceGuarantee!: string

  static displayName = 'MobilePrice_guarantee'

  guaranteeModalVisible = false

  openGuaranteeModal() {
    this.guaranteeModalVisible = true
  }

  onConfirm() {
    this.guaranteeModalVisible = false
  }
}
