export function swiperSlideToCenter(index: number, swiper: any) {
  if (!swiper) {
    return
  }

  if (!index) {
    return swiper.setTranslate(0)
  }

  const slides = swiper.slides

  if (slides.length === 0) { return }

  const slide = slides[index]
  const lastSlide = slides[slides.length - 1]

  const containerWidth = swiper.$el[0].offsetWidth
  const slideWith = lastSlide.offsetLeft + lastSlide.offsetWidth

  if (containerWidth >= slideWith) {
    return
  }

  let translate = slide.offsetLeft - containerWidth / 2 + slide.offsetWidth / 2
  const diff = translate + containerWidth - slideWith

  if (translate < 0) { translate = 0 }
  if (diff > 0) { translate = translate - diff + 16 }

  swiper.setTranslate(-translate)
}
