

















































import { Vue, Component, State, Watch, Prop } from 'nuxt-property-decorator'
import { scroll2, nodeScrollTop } from '~/share/scroll2/scroll2'
import RwdSwiper from '~/components/experience/rwd-swiper/index.vue'
import ReviewRating from '~/components/experience-booking/experience-activity/recent-review/review-rating.vue'
import RecentReviewCard from '~/components/experience-booking/experience-activity/recent-review/recent-review-card.vue'

@Component({
  components: {
    RwdSwiper,
    ReviewRating,
    RecentReviewCard
  }
})
export default class ActivityRecentReview extends Vue {
  @Prop({ type: Object, default: () => ({}) }) businessData!: any

  @State klook!: Data.Klook

  @Watch('businessData.reviews', { immediate: true })
  businessReviewsChange(val: any) {
    if (!val) {
      this.$emit('hide-section')
    }
  }

  get reviewCount() {
    return this.businessData?.review_count || 0
  }

  get score() {
    return this.businessData?.score || 5
  }

  get reviewList() {
    return this.businessData?.reviews || []
  }

  get shouldHideImage() {
    return this.reviewList.every((review: any) => !(review.review_image?.length || review.review_image_list?.length))
  }

  skipToReview() {
    const top = nodeScrollTop(document.querySelector('#reviews'))
    if (top) {
      scroll2({ scrollY: top, lockLocation: 105 })
    }
  }
}
