








import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { scrollToPackageOption } from '~/share/scroll2/scroll2'
import { currencySymbolMap } from '~/share/data/currency'
import { formatThousands } from '~/share/utils'

@Component
export default class HeroPageBottomBar extends Vue {
  @State klook!: Data.Klook
  @Prop({ default: () => null }) packageData!: any

  get price() {
    const { from_price, to_price, price_show } = this.packageData
    // 最低价
    let price = price_show?.sale_price ?? ''
    if (from_price && from_price !== to_price) {
      price = `${currencySymbolMap[this.klook.currency]} ${formatThousands(from_price)}`
    }
    return this.$t('activity_detail_from_price', [12, price])
      .replace(/color: #\d+;*/, '')
      .replace(/color:#\d+;*/, '')
  }

  booking() {
    scrollToPackageOption()
  }
}
