
















import { Component, State, Prop, Vue } from 'nuxt-property-decorator'
import SeeMore from './see-more/index.vue'
import DynamicComponent
  from '~/components/experience-booking/experience-activity/dynamic-component/dynamic-component.vue'

@Component({
  components: {
    SeeMore,
    DynamicComponent
  }
})
export default class DynamicComponentHero extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop({ type: Array, default: () => [] }) components!: any []
  @Prop({ type: String, default: '' }) title!: string
  @Prop() activityId!: string | number

  get maxHeight() {
    return this.platform === 'mobile' ? 136 : 120
  }
}
