













































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { ImageViewer } from '@klook/klook-experience-image-swiper'

interface Images {
  title: string,
  img_url: string,
  img_resize_url?: string,
  width?: number,
  height?: number
  [key: string]: any
}

@Component({
  components: {
    ImageViewer
  }
})
export default class ImageCardSwiperViewer extends Vue {
  @Prop({ type: String, default: 'desktop' }) platform!: string
  @Prop({ type: String, default: 'inside' }) controllerPosition!: string
  @Prop({ type: Array, default: () => [] }) images!: Images []
  @Prop({ type: [Number, String], default: 'calc((100% - 24px) / 3)' }) itemWidth!: number | string
  @Prop({ type: Number, default: 12 }) itemGap!: number
  @Prop({ type: Boolean, default: false }) scrollMode!: string
  @Prop(
    {
      type: Object,
      default: () => ({
        img_url: 'img_url',
        img_resize_url: 'img_resize_url',
        width: 'width',
        height: 'height',
        title: 'title'
      })
    }
  ) fieldKey!: any

  showViewer: boolean = false
  currentIndex: number = 0
  currentTitle: string = ''

  get isMobile() {
    return this.platform === 'mobile'
  }

  get realFieldKey() {
    const df = {
      img_url: 'img_url',
      img_resize_url: 'img_resize_url',
      width: 'width',
      height: 'height'
    }
    return { ...df, ...this.fieldKey }
  }

  viewerImage(index: number, image: Images) {
    this.showViewer = true
    this.currentIndex = index
    this.currentTitle = image[this.realFieldKey.title]
  }

  localesTranslate() {
    return this.$t.bind(this)
  }

  slideChange(data: any) {
    const { index } = data
    const image = this.images[index]
    this.currentTitle = image?.[this.realFieldKey.title] ?? ''
    this.$emit('slideChange', data)
  }
}
