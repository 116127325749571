




























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import ActivityMarkdown from '~/components/traveller/activity/activity-markdown.vue'
import { renderMarkdownStr } from '~/assets/scripts/markdown'
import { ExperienceActivity } from '~/types/experience/activity-section'
import ActivityModal from '~/components/experience-booking/experience-activity/activity-modal/index.vue'

@Component({
  components: {
    ActivityMarkdown,
    ActivityModal
  }
})
export default class AttrDetail extends Vue {
  @Prop() spec !: ExperienceActivity.ISpec
  @Prop() visible !: boolean

  getAttrTips(tips: string) {
    return renderMarkdownStr(tips)
  }

  isDisabled(attr: any) {
    return !attr.isScheduleMatch || !attr.isPackageMatch
  }

  getDisabledTips(attr: ExperienceActivity.IAttr) {
    const { isPackageMatch, isScheduleMatch } = attr
    if (!isScheduleMatch) { return this.$t('activity.new.package_attr_date_disabled') }

    if (!isPackageMatch) { return this.$t('activity.new.package_attr_disabled') }
  }

  select(attr: any, spec: any) {
    if (attr.isActive) {
      return
    }

    this.$emit('select', attr, spec)
  }

  close() {
    this.$sendGTMCustomEvent('Activity Page|Package Options View Details Back Button Clicked')

    this.$emit('close')
  }
}
