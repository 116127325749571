var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('RwdSwiper',{staticClass:"base-cards-swiper",class:[
    _vm.klook.platform,
    { 'perview-2': _vm.currentPerView === 2 },
    { 'perview-3': _vm.currentPerView === 3 },
    { 'perview-4': _vm.currentPerView === 4 }
  ],attrs:{"swiper-bind":_vm.swiperBind,"per-view":_vm.currentPerView,"data":_vm.data,"is-big-card":_vm.isBigCard},scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [_vm._t("card-item-slot",[_c('TnaActivityCard',_vm._b({directives:[{name:"track",rawName:"v-track:module:Activity_LIST",value:({
          oid: ("activity_" + (item.activity_id)),
          idx: index,
          len: _vm.data.length,
          inject: 'section',
          ext: _vm.getSpmExt(item),
        }),expression:"{\n          oid: `activity_${item.activity_id}`,\n          idx: index,\n          len: data.length,\n          inject: 'section',\n          ext: getSpmExt(item),\n        }",arg:"module:Activity_LIST"}],staticClass:"base-recommend-card",attrs:{"card-data":item,"logo-width":_vm.logoWidth}},'TnaActivityCard',_vm.getBindObj(item),false))],{"item":item,"index":index})]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }