import { Component, Provide } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import { getPath } from '~/share/utils'
import SectionBase from '~/components/setion/experience/base/section-base'
import { getInhouseExtDataByTags } from '~/components/experience/section/utils'

export interface IBusiness {
  getParams: Function
  businessData: Function
  getDataFail: Function
  getData: Function
}

@Component
export class BusinessBase extends SectionBase {
  result: any = []

  get calcCustomScrollEl() {
    // explore page bottom-sheet activity details
    return this.$attrs['custom-scroll-el']
  }

  @Provide()
  getScrollElBySectionConfig2provide() {
    const scrollEl = this.calcCustomScrollEl || ''
    if (!scrollEl) {
      return null
    }
    // lockLocation：目前bottom-sheet都是90%高度
    const lockLocation = this.$isServer ? 0 : Number((document.documentElement.clientHeight || document.body.clientHeight) * 0.1) + 56
    const obj = {
      lockLocation,
      scrollEl
    }
    return obj
  }

  get businessData(): any {
    if (this.sectionContent.load_type === 'async') {
      return this.result || {}
    }
    return this.sectionContent.data || {}
  }

  @Provide('businessName')
  get businessName() {
    return this.sectionData?.meta?.name
  }

  getDataFail() {
    setTimeout(() => {
      this.changeSectionState('failure')
    }, 1000)
  }

  getCardType(key: string) {
    return !!this.businessData[key]?.items
  }

  getRatio(key: string) {
    return this.businessData[key]?.banner_scale || ''
  }

  getCardData(oldKey: string, newKey: string) {
    const oData = this.businessData[oldKey] || []
    const nData = this.businessData[newKey] || {}
    return (nData?.items ?? oData) || []
  }

  getSpmExt(item: any) {
    const { brand_info = {}, product_tags = {} } = item || {}
    let prop = {}
    if (!isEmpty(brand_info) && brand_info?.tags?.length) {
      prop = {
        campaign_tags: brand_info.tags
      }
    }

    if (!isEmpty(product_tags)) {
      prop = { ...prop, ...product_tags }
    }

    getInhouseExtDataByTags(prop)

    return prop
  }

  async getData(params?: any) {
    if (this.isAsync) {
      const { src = '' } = this.sectionContent
      try {
        const res = await this.$axios.$get(getPath(src), { params })
        if (!res.result || !res.success) {
          return this.getDataFail()
        }

        // @ts-ignore
        if (this.validateRes && !this.validateRes(res.result)) {
          this.hideSection && this.hideSection()
          return
        }

        this.changeSectionState('success')
        return res.result
      } catch (e) {
        this.getDataFail()
      }
    }
  }

  getParams() {
    return {}
  }

  async mounted() {
    if (!this.isAsync) {
      return
    }

    const params = await this.getParams()
    this.result = await this.getData(params)
  }
}
