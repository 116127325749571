import { Component, Vue } from 'nuxt-property-decorator'

/**
 * 横滑判断是否需要pin住
 */

@Component
export default class SwiperIndexVisibility extends Vue {
  attributesObserver: any = null
  packageDetailObserver: any = null
  isAttributesVisible = false
  isPackageDetailVisible = false
  attributesSelector = '.js-swiper-attributes'
  packageDetailSelector = '.js-package-detail-for-swiper'

  get calcCustomScrollEl() {
    // explore page bottom-sheet activity details
    return this.$attrs['custom-scroll-el']
  }

  get shouldShowPanel() {
    return !this.isAttributesVisible && this.isPackageDetailVisible
  }

  beforeDestroy() {
    this.destroySwiperObserver()
  }

  createSwiperObserver() {
    if ('IntersectionObserver' in window) {
      const { attributesSelector, packageDetailSelector } = this
      const attributesDom = this.$el.querySelector(attributesSelector)
      const packageDetailDom = document.querySelector(packageDetailSelector)

      if (attributesDom && !this.attributesObserver) {
        this.attributesObserver = new IntersectionObserver(
          entries => entries.forEach((e) => {
            this.isAttributesVisible = e.intersectionRatio > 0
          }),
          {
            rootMargin: this.calcCustomScrollEl ? '-190px 0px 0px' : '-50px 0px 0px',
            threshold: [0, 1]
          }
        )

        this.attributesObserver.observe(attributesDom)
      }

      if (packageDetailDom && !this.packageDetailObserver) {
        this.packageDetailObserver = new IntersectionObserver(
          entries => entries.forEach((e) => {
            this.isPackageDetailVisible = e.intersectionRatio > 0
          }),
          {
            threshold: [0, 1]
          }
        )

        this.packageDetailObserver.observe(packageDetailDom)
      }
    }
  }

  destroySwiperObserver() {
    const { attributesSelector, packageDetailSelector } = this

    if (this.attributesObserver) {
      const attributesDom = this.$el.querySelector(attributesSelector)
      attributesDom && this.attributesObserver.unobserve(attributesDom)
    }

    if (this.packageDetailObserver) {
      const packageDetailDom = document.querySelector(packageDetailSelector)
      packageDetailDom && this.packageDetailObserver.unobserve(packageDetailDom)
    }
  }
}
