










import { Component, Vue, Prop, State } from 'nuxt-property-decorator'

@Component
export default class CardSwiperItem extends Vue {
    @Prop({ type: Number, default: 1 }) perView!: number // 完全展示在视窗内的个数
    @Prop({ type: Number, default: 10 }) gap!: number
    // 父容器可能不是占满整个页面，给一个修正值
    @Prop({ type: Number, default: 32 }) offset!: number
    @State(state => state.klook.platform) platform!: Data.Platform

    get wrapStyle() {
      if (this.perView === 0) {
        return {
          width: '100%'
        }
      }

      const width = `calc(${89.6 / this.perView}% + ${(this.offset / 2) * 0.896 / this.perView}px)`

      // 用margin在flex布局下margin-right会被igonre
      return {
        width,
        paddingRight: `${this.gap}px`
      }
    }
}
