import { Component, Prop, Ref, namespace, State, mixins, Watch } from 'nuxt-property-decorator'
import { debounce, cloneDeep } from 'lodash'
import BookingCoreMixin from '~/components/booking-core/mixins/entry/index-base'
import eventBus from '~/pages/experience/pay/common/event-bus'
// @ts-ignore
import { getBottomSheetConfig } from '~/components/experience/load-hander/index.vue'
import * as types from '~/store/traveller/activity/types'
import { Activity } from '~/types/traveller/activity'
import dataUtils from '~/components/booking-core/utils/data-utils'
import { setNewImageSize } from '~/share/data/image'

const ActivityModule = namespace('traveller/activity')

export interface openPayload {
  skuId: number
  packageId: number
  date: string
  pkgDate: string // YYYY-MM-DD
  selectedTime?: any
  selectedUnits?: any[]
  packageName?: string
}

@Component({})
export default class PackageOptionsBooking extends mixins(BookingCoreMixin) {
  @Prop() isFnb!: boolean
  @Prop() autoTimeSlot!: boolean
  @Prop({ type: String, default: '' }) fromType!: 'bookNow' | 'addToCart'
  @Prop({ default: 'fetching' }) state!: string
  @Prop({ default: '' }) imageUrl!: string
  @Ref() headerAlertRef!: any
  @Prop() showShoppingCart!: () => void
  @ActivityModule.State commonLoadingState!: any
  @ActivityModule.State preSettlementApiData!: any
  @ActivityModule.Action getPreSettlementApiData!: Function
  @ActivityModule.Getter getPkgPreSettlementTips2getters!: any

  @State((state) => state.klook.platform) platform!: string

  bsObj = getBottomSheetConfig('fixed-height-config')
  bsObjSavePrice = getBottomSheetConfig({ transfer: false })

  cacheOpt: any = { date: '', selectedUnits: null }
  oldPrevUnits: any = null
  groupPackName = ''
  fetchPackageUnitsDebounce = debounce(this.fetchPackageUnits, 200)

  @Watch('isLoggedIn')
  isLoggedInWatch(isb: boolean) {
    if (isb) {
      this.platform === 'desktop' && this.fetchPackageUnits({ isLoginSuccessAfter: true })
    }
  }

  async fetchPackageUnits(options?: any) {
    const res = await this.getPackageUnitsApiData(this.calcPkgUnitsParams)
    const params = {
      credits_tag: res?.result?.credits_tag,
      maxPurchased: this.calcMaxPurchased,
      inventories: this.calcUnitInventorieObj
    }
    const unitList = res?.result?.unitList || []
    // 自动选中
    if (this.preSelectedSkuId) {
      unitList.forEach((unit: Activity.unit) => this.autoSelectSku(unit, res?.result?.inventories))
    }

    // 根据skuId自动选中
    const oldUnitList = this.oldPrevUnits || []
    oldUnitList &&
      unitList.forEach((o: any) => {
        const obj = oldUnitList.find((item: any) => {
          return item.skuId === o.skuId
        })
        if (obj && !o.isSoldOut) {
          o.prevCount = obj.count
        }
      })
    let cacheSelectUnits: any[] = []
    if (options?.isLoginSuccessAfter) {
      cacheSelectUnits = cloneDeep(this.currentUnits)
    }
    this.currentUnits = dataUtils.getExpUnitsExtra(unitList, params)
    if (options?.isLoginSuccessAfter) {
      // 登录之后同步 units 已选数量
      this.currentUnits.forEach((item) => {
        const obj: any = cacheSelectUnits.find((o) => {
          return o.skuId && o.skuId === item.skuId
        })
        obj && this.$set(item, 'count', obj.count)
      })
    }
    this.handleGetPreSettlementApiData(options?.isLoginSuccessAfter ? this.currentUnits : unitList)
  }

  async changeUnitCount(
    { priceId, type }: { priceId: number; type: 'add' | 'minus' },
    mergeData?: { hideLoading: boolean }
  ) {
    const { calcPkgSelectedObj: currentSelectedPackage, currentUnits: unitList } = this
    await this.changeExpUnitCount({ priceId, type, currentSelectedPackage, unitList, ...(mergeData || {}) })
    // const isUnfold = this.platform === 'desktop'
    // if (isUnfold) {
    //   const isTrue = !!this.calcArrangementId
    //   setTimeout(() => {
    //     this.handleGetPreSettlementApiData(unitList, isTrue)
    //   }, 1)
    // }
  }

  closePriceSumary() {
    eventBus.$emit('setShowPriceSummary2bus', false)
  }

  clickSavePrice(isb: boolean, obj: any) {
    const { bsObjSavePrice } = this
    bsObjSavePrice.visible = isb
    this.$set(bsObjSavePrice, 'priceSummary', obj?.priceSummary)
  }

  get calcTags2attribute() {
    // mweb预订页-旧流程-已选套餐
    return this.calcPkgSelectedObj?.product_tags?.attribute_tags || []
  }

  get calcTags2discount() {
    // mweb预订页-旧流程-已选套餐
    const tags = this.calcPkgSelectedObj?.product_tags?.discount_tags || []
    const list: any = []
    if (tags?.length) {
      tags.forEach((tag: any) => {
        const inner_vars = tag?.inner_vars || {}
        if (!inner_vars?.inner?.derivation_info || !inner_vars?.inner?.derivation_info?.is_derived) {
          // 非倒推/直接打到的
          list.push(tag)
        }
      })
    }

    return list
  }

  get showBottomBar() {
    return this.currentUnits?.length > 0
  }

  get calcData() {
    const { usage_validity_render_obj } = (this.calcPkgSelectedObj || {}) as any
    const arr = usage_validity_render_obj || []
    return {
      title: (arr.find((o: any) => o.type === 'title') || {}).content,
      rules: arr.filter((o: any) => o.type === 'item').map((o: any) => o.content)
    }
  }

  async getPackagePriceScheduleUnits(paramsData: any = {}, options: { fromKey: string } = { fromKey: '' }) {
    const { fromKey } = options || {}
    const res = await this.getPackageSchedulesAndUnitsApiData({
      ...this.calcPkgSchedulesAndUnitsParams,
      ...(paramsData || {})
    })
    const { result } = res || {}
    if (!result) {
      return null
    }
    // openticket 会自动选日期
    if (this.calcPkgIsNewOpenTicket) {
      this.autoSetFeasibleDate(!!this.calcPkgSelectedObj?.auto_select_first_date)
    }
    const { unitList, inventories } = result || {}
    if (unitList?.length === 1 && [dataUtils.FromKeyDefined.desktop.sku].includes(fromKey)) {
      this.preSelectedSkuId = unitList?.[0]?.skuId
    }
    // 自动选中
    if (this.preSelectedSkuId) {
      unitList.forEach((unit: Activity.unit) => this.autoSelectSku(unit, inventories))
    }
    this.currentUnits = unitList || []

    // 根据skuId自动选中
    const oldUnitList = this.oldPrevUnits || []
    oldUnitList &&
      unitList.forEach((o: any) => {
        const obj = oldUnitList.find((item: any) => {
          return item.skuId === o.skuId
        })
        if (obj && !o.isSoldOut) {
          o.prevCount = obj.count
        }
      })

    const isTrue = !!this.calcArrangementId // hack: 产线刚打开浮层的时候ArrangementId = 'default'
    setTimeout(() => {
      this.handleGetPreSettlementApiData(unitList, isTrue)
    }, 1)
  }

  autoSetFeasibleDate(isFirstDate: boolean) {
    const {
      calcPkgScheduleList: packageSchedules,
      calcPkgSelectedObj: currentSelectedPackage,
      pkgSelectedDate: packageSelectedDate
    } = this
    this.pkgSelectedDate = this.getExpAutoSetFeasibleDate({
      isFirstDate,
      packageSchedules,
      currentSelectedPackage,
      packageSelectedDate
    })
  }

  handleGetPreSettlementApiData(unitList: any[], isTrue = true) {
    const { calcPkgSelectedObj, calcArrangementId } = this
    if (isTrue && calcPkgSelectedObj?.has_discount && calcArrangementId) {
      const params = {
        hideLoading: true,
        page_from: 2, // 调用来源，会影响展示的文案.detail:1; booking_option:2; shopping_card: 3
        arrangement_id: calcArrangementId,
        sku_list: unitList.map((o: any) => {
          return {
            sku_id: o.skuId,
            quantity: o.isSoldOut ? 0 : o.count
          }
        }),
        pid: calcPkgSelectedObj?.package_id
      }
      this.getPreSettlementApiData(params)
    }
  }

  // 默认选中逻辑：
  // 第一次打开会默认选中preSelectedSkuId
  // 第一次打开不带日期：默认选中 => （切换日期）继续默认选中 => （切换日期）不默认选中
  // 第一次打开带日期：默认选中 => （切换日期）不默认选中
  // 注意：这里需要和unit里面的handlePlus保持一致
  autoSelectSku(unit: Activity.unit, inventories: any) {
    const $t = this.$t.bind(this)
    const {
      calcPreview: preview,
      calcPkgUnitsObj: pkgUnitsObj,
      calcPkgSelectedObj,
      calcTotalUnitCount: totalUnitCount,
      pkgSelectedTime: selectedTime,
      preSelectedSkuId
    } = this
    const errorTips = this.setExpAutoSelectSkuHandler({
      unit,
      inventories,
      preview,
      pkgUnitsObj,
      currentSelectedPackage: calcPkgSelectedObj,
      totalUnitCount,
      selectedTime,
      preSelectedSkuId,
      $t
    })
    errorTips && this.alert(errorTips)
  }

  alert(text: string) {
    if (this.platform !== 'desktop') {
      this.headerAlertRef.open(text)
    } else {
      this.$toast(text)
    }
  }

  clearPreSettlementData(_?: any) {
    this.$store.commit(`traveller/activity/${types.SET_PRE_SETTLEMENT_API_DATA}`, { res: null })
  }

  setPackageTime(data: any) {
    const { arrangementId } = this.pkgSelectedTime || {}
    if (arrangementId) {
      this.oldPrevUnits = cloneDeep(this.currentUnits)
    }
    this.setExpPkgSelectedTime(data)
  }

  fixedHackStyle: any = null
  updateInfos(infos: any) {
    if (!infos) {
      return
    }
    this.fixedHackStyle = {
      paddingBottom: infos.height + 'px'
    }
  }

  formatPicUrl() {
    const { webp } = this.klook
    return setNewImageSize(this.imageUrl, 'image/upload/', 350, 196, webp)
  }
}
