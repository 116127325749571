




import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component
export default class AttrsUnselectTips extends Vue {
  @Prop() visible!: boolean
  @Prop() spec!: ExperienceActivity.ISpec[]
  @Prop() chosenAttrIds!: number[]

  get content() {
    // 获取为选择的attr名
    const contentTips = this.spec?.reduce((acc, spec) => {
      const { attr } = spec

      if (attr.some(v => this.chosenAttrIds.includes(v.id))) {
        return acc
      }

      const specName = spec.name

      return acc ? `${acc} / ${specName}` : specName
    }, '')

    return this.$t('activity.new.package_spec_not_valid_tip', [contentTips])
  }

  @Watch('visible', { immediate: true })
  onVisibleChange(val: boolean) {
    if (process.client) {
      val ? this.showToast() : this.$emit('update:visible', false)
    }
  }

  showToast() {
    this.$toast(this.content)

    setTimeout(() => {
      this.$emit('update:visible', false)
    }, 2000)
  }
}
