import { render, staticRenderFns } from "./price.vue?vue&type=template&id=15109306&scoped=true&"
import script from "./price.vue?vue&type=script&lang=ts&"
export * from "./price.vue?vue&type=script&lang=ts&"
import style0 from "./price.vue?vue&type=style&index=0&id=15109306&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15109306",
  null
  
)

export default component.exports