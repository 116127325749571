



























































import { Component, mixins, Prop } from 'nuxt-property-decorator'
import MobileComboPkgCard from '~/components/experience-booking/experience-activity/combo-option/combo-list/combo-pkg-card/mobile.vue'
import ComboListBase from '~/components/experience-booking/experience-activity/combo-option/combo-list/combo-list-base'
import CardBottomBar from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/card-bottom-bar/index.vue'
import ExperiencePackageDetailModule from '~/components/experience-booking/experience-activity/package-options/package-card-list/package-card/package-detail-module.vue'
import CombonOptionsBooking from '~/components/experience-booking/experience-activity/combo-option/booking-options/mobile/index.vue'
import { getTaggingExtraTrackObj } from '~/components/experience/tagging/index.vue'

@Component({
  components: {
    MobileComboPkgCard,
    CardBottomBar,
    ExperiencePackageDetailModule,
    CombonOptionsBooking
  }
})
export default class MobileComboPkg extends mixins(ComboListBase) {
  @Prop() packageOptionClass!: string

  getTaggingExtraTrackObj(pkg: any) {
    const list = [...(pkg?.product_tags?.attribute_tags || []), ...(pkg?.product_tags?.discount_tags || [])]
    const track = getTaggingExtraTrackObj(list)
    return track || {}
  }

  getPkgTrack(pkg: any, index: number) {
    const obj = this.getTaggingExtraTrackObj(pkg)
    const isSuspended = pkg?.publish_info?.publish_status === 2
    const isWarming = pkg?.publish_info?.publish_status === 3
    const { comboPackageList } = this
    const ext = JSON.stringify({
      SelectType: 'User',
      PackageTagIDList: obj?.TagIDList || [],
      PackageTagName: obj?.TagKeyList || [],
      StatusType: isSuspended ? 'Unavailable' : isWarming ? 'ForSalesSoon' : 'Normal',
      IsComboPackage: true
    })
    return `PackageType_LIST?oid=${`package_${pkg.package_id}`}&len=${comboPackageList?.length || 0}&idx=${index}&ext=${ext}`
  }
}
