type Args = {
  root?: any,
  rootMargin?: string,
  threshold?: number | number[],
  once?: Boolean,
  callBack: (target: Element, observer?: IntersectionObserver) => void,
  observeDom: NodeListOf<Element> | Element
}

export default ({
  root = null,
  rootMargin = '0px',
  threshold = [0.01, 0.05], // 容错
  once = true,
  callBack,
  observeDom
}: Args): IntersectionObserver | null => {
  // 不支持 IntersectionObserver 同步触发加载
  if (
    !('IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype)
  ) {
    if (observeDom) {
      if (observeDom instanceof Element) {
        // Element
        callBack(observeDom)
      } else {
        // NodeList
        Array.from(observeDom).forEach(dom => callBack(dom))
      }
    }

    return null
  }

  let targetSet: Set<any> | null = new Set()

  const intersectionObserver = new IntersectionObserver((entries, observer) => {
    if (entries.length <= 0) {
      observer.disconnect()
      targetSet = null
      // console.log('intersectionObserver disconnect')
    }
    entries.forEach((item: IntersectionObserverEntry) => {
      if ((undefined === observer.thresholds) || (item.intersectionRatio > observer.thresholds[0])) {
        if (once) {
          observer.unobserve(item.target)

          if (targetSet) {
            // 可在回调中手动 unobserve
            if (!(targetSet.has(item.target))) {
              setTimeout(() => callBack(item.target, observer))
            }

            targetSet.add(item.target)

            if (targetSet.size === ((observeDom as NodeList).length || 1)) {
              // 全部触发释放
              observer.disconnect()
              targetSet = null
            }
          }
        } else if (callBack) {
          setTimeout(() => callBack(item.target, observer))
        }
      }
    })
  }, {
    root,
    rootMargin,
    threshold
  })

  if (observeDom) {
    if (observeDom instanceof Element) {
      // Element
      intersectionObserver.observe(observeDom)
    } else {
      // NodeList
      Array.from(observeDom).forEach(dom => intersectionObserver!.observe(dom))
    }
  } else {
    // console.error('observeDom不能为空')
  }

  return intersectionObserver
}
