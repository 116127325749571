









import { Component } from 'nuxt-property-decorator'
import Banner from '../banner.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({
  components: {
    Banner
  }
})
export default class ActivityBanner extends BusinessBase {
  static displayName = 'MobileBanner'

  get showStyleVersion() {
    return this.fieldsConfig2section.show_style_version || 0
  }

  get isTaVersion() {
    return this.showStyleVersion === 1
  }
}
