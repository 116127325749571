



























import { Component, Vue, Watch, Prop } from 'nuxt-property-decorator'
import isEmpty from 'lodash/isEmpty'
import throttle from 'lodash/throttle'
import GuideButton from './guide-button.vue'
import { Activity } from '~/types/traveller/activity'
import { nodeScrollTop, clientHeight, windowScrollTop, scroll2 } from '~/share/scroll2/scroll2.ts'
import { ExperienceActivity } from '~/types/experience/activity-section'

@Component({
  components: {
    GuideButton
  }
})
export default class ActivityPackageDetailGuideButton extends Vue {
  @Prop() packageSchedulesState!: Activity.fetchStatus
  @Prop() spec!: ExperienceActivity.Specs
  @Prop() packageId!: number

  visible = false
  borderTop = 0
  borderBottom = 0
  viewportHeight = 0
  detailPosition = 0

  _navHeight = 0

  @Watch('packageSchedulesState')
  statusChange(val: Activity.fetchStatus) {
    if (val === 'success') {
      this.checkVisible()
    }
  }

  @Watch('packageId')
  packageIdChange() {
    this.checkVisible()
  }

  // 只有一个销售属性
  get invalid() {
    if (isEmpty(this.spec)) { return true }

    if (this.spec.length === 1) {
      const { attr } = this.spec[0]

      return isEmpty(attr) || attr.length < 2
    }

    return false
  }

  handleScroll = throttle(this.checkVisible, 500)

  checkVisible() {
    if (this.invalid || !this.packageId) {
      this.visible = false
      return
    }

    const nodeAttrs = document.getElementsByClassName('activity-spec')
    if (!nodeAttrs.length) { return false }
    const nodeSecondAttr = nodeAttrs[0]
    // 上边界
    this.borderTop = nodeScrollTop(nodeSecondAttr) + 30

    const nodeIcons = document.getElementsByClassName('activity-package-detail_icons')
    if (!nodeIcons.length) { return false }

    const nodeIcon = nodeIcons[0]
    // 下边界
    this.borderBottom = nodeScrollTop(nodeIcon) + nodeIcon.getBoundingClientRect().height

    const bottomBar = document.getElementsByClassName('activity-bottom-bar')
    const bottomBarHeight = bottomBar.length ? bottomBar[0].getBoundingClientRect().height : 0
    this.viewportHeight = clientHeight() - bottomBarHeight

    this.visible = this.borderTop - this.viewportHeight - windowScrollTop() < 0 && this.borderBottom - windowScrollTop() - this.viewportHeight > 0
  }

  get isFetching() {
    return this.packageSchedulesState === 'fetching'
  }

  get content() {
    return this.isFetching ? '' : this.$t('activity_page.view_package_detail')
  }

  getNavHeight() {
    if (this._navHeight) { return }

    const header = document.querySelector('.j-activity-header')
    const nav = document.querySelector('.j-activity-nav-container')

    if (!header || !nav) { return 0 }

    this._navHeight = header.getBoundingClientRect().height + nav.getBoundingClientRect().height
  }

  translateToDetail() {
    if (this.isFetching) { return }

    if (!this.detailPosition) {
      this.getNavHeight()

      const nodePackageDetail = document.querySelector('#goto-activity-package-detail')
      if (!nodePackageDetail) {
        return
      }

      // this.detailPosition = nodeScrollTop(nodePackageDetail) - nodePackageDetail.getBoundingClientRect().height - this._navHeight
      this.detailPosition = nodeScrollTop(nodePackageDetail) - this._navHeight
    }

    scroll2({
      scrollY: this.detailPosition
    })
  }

  mounted() {
    document.addEventListener('scroll', this.handleScroll)
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll)
  }
}
