















import { Vue, Component, Prop } from 'nuxt-property-decorator'

@Component
export default class BookingBarWrap extends Vue {
  @Prop({ default: true }) priceVisible!: boolean
  @Prop({ default: false }) isSoldOut!: boolean

  visible = false
}
