









import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'
import DynamicImageMap
  from '~/components/experience-booking/experience-activity/dynamic-component/map/dynamic-image-map.vue'
import DynamicTitleMap
  from '~/components/experience-booking/experience-activity/dynamic-component/map/dynamic-title-map.vue'

@Component({
  components: {
    DynamicImageMap,
    DynamicTitleMap
  }
})
export default class DynamicMap extends Vue {
  @Prop() content!: ExperienceActivity.IDynamicAddress

  get componentName() {
    switch (this.content?.style?.address_type) {
      case 'image':
        return 'DynamicImageMap'
      case 'title':
        return 'DynamicTitleMap'
      default:
        return 'DynamicImageMap'
    }
  }
}
