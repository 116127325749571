









































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import { IconTriangleDown } from '@klook/klook-icons'

@Component({
  components: {
    IconTriangleDown
  }
})
export default class ReviewFilterSort extends Vue {
  @Prop() value!: string
  @Prop() sortData!: any[]

  visible = false

  open() {
    this.visible = true
  }

  get text() {
    let text = ''
    this.sortData.forEach((item) => {
      const { name, val } = item
      if (this.value === val) {
        text = name
      }
    })
    return text
  }

  handleClick(val: number) {
    this.$emit('input', val)
    this.visible = false
    this.$emit('change')
  }
}
