











import { Vue, Component, Prop, State } from 'nuxt-property-decorator'

@Component
export default class LoadingError extends Vue {
  @State((state) => state.klook.platform) platform!: Data.Platform
  @Prop() content!: string
}
