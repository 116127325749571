


























import { Component, Getter, State, Vue, Prop } from 'nuxt-property-decorator'
import ActivityWishButton from '~/components/experience-booking/experience-activity/package-options/bottom-bar/mobile/attribute-bottom-bar/activity-wish-button.vue'

@Component({
  components: {
    ActivityWishButton
  }
})
export default class PackageOptionsSoldOut extends Vue {
  @State(state => state.klook.platform) platform!: Data.Platform
  @State(state => state.klook.utilConfig) utilConfig!: any
  @Getter isGuestCheckout!: boolean
  @Prop() linkTitle!: string
  @Prop() link!: string
  @Prop() content!: string
  @Prop() cityId!: number
  @Prop() activityId!: number

  get wishButtonVisible() {
    return !this.utilConfig?.whiteLabelUtilConfig && !this.isGuestCheckout
  }

  get showLink() {
    return this.link && this.linkTitle
  }

  mounted() {
    document.body.style.paddingBottom = this.showLink ? '100px' : '60px'
  }
}
