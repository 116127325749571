











import { Vue, Component, State, Prop, namespace } from 'nuxt-property-decorator'
import { cloneDeep } from 'lodash'
import KlkInternalLinking from '@klook/klk-internal-linking'
import '@klook/klk-internal-linking/dist/esm/index.css'
import { fetchInternalLink } from '~/share/utils'

const experience = namespace('experience')

interface ModuleDataIf{
  title: string
  seo_static_link: any[]
}

@Component({
  components: {
    KlkInternalLinking
  }
})
export default class Index extends Vue {
  @State klook!: Data.Klook
  @experience.State expInternalLinkObj2state!: ModuleDataIf
  @experience.State countryId!: number
  @experience.State cityId!: number
  @experience.State frontendId!: number
  @Prop() moduleData!: ModuleDataIf

  get calcModuleData() {
    const obj = this.moduleData || this.expInternalLinkObj2state || {}

    return cloneDeep(obj)
  }

  getParams() {
    const { countryId, cityId, frontendId } = this
    if (['ExperienceAroundTheWorld'].includes(this.$route.name as string)) {
      return {}
    }

    const obj = {
      category_id: frontendId,
      country_id: countryId,
      city_id: cityId
    }

    return obj
  }

  async initData() {
    if (this.moduleData) { return }

    const res = await fetchInternalLink(this.getParams(), this.$axios)
    this.$store.commit('experience/SET_EXP_INTERNAL_LINK_OBJ', res?.result)
  }

  async mounted() {
    await this.initData()
  }
}
