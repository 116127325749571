
















import { Component, Vue, State, Prop } from 'nuxt-property-decorator'
import ActivityPrice from './activity-price.vue'
import UspBanner from '~/components/traveller/activity/usp-tip/usp-banner.vue'
import { currencySymbolMap } from '~/share/data/currency'
import { formatThousands } from '~/share/utils'

@Component({
  components: {
    ActivityPrice,
    UspBanner
  }
})
export default class BestPriceUspBanner extends Vue {
  @State klook!: Data.Klook
  @Prop() price!: {
    market_price: string,
    selling_price: string,
    from_price: string,
    price_desc: string
  }

  get marketPrice() {
    return this.price.market_price
  }

  get sellingPrice() {
    return this.price.selling_price
  }

  get fromPrice() {
    return this.price.from_price
  }

  get priceDesc() {
    return this.price.price_desc
  }

  get priceDiff() {
    if (!this.marketPrice) {
      return
    }

    // 这里是活动价格
    const marketPrice = Number(this.marketPrice)
    const fromPrice = Number(this.fromPrice)
    let priceDiff: string | number = this.getPriceDiff(marketPrice, fromPrice)

    if (priceDiff <= 0) { return }

    const priceArr = this.fromPrice.toString().split('.')
    const tofixedLength = priceArr[1]?.length || 0

    priceDiff = priceDiff.toFixed(tofixedLength)

    const priceDiffDesc = `${currencySymbolMap[this.klook.currency]} ${formatThousands(priceDiff)}`

    return this.$t('26260', { crossedregularprice_sellingprice: priceDiffDesc })
  }

  getPriceDiff(a: number, b: number) {
    return (a * 100 - b * 100) / 100
  }
}
