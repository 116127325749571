



















































import { Component, Prop, State } from 'nuxt-property-decorator'
import DefalutImage from '../defalutImage/index.vue'
import { Dish } from '../type'
import { galleryUrl } from '../data'
import ImageSwiper from './image-gallery.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'

@Component({
  components: {
    ImageSwiper,
    DefalutImage
  }
})
export default class FnbMustTry extends BusinessBase {
  static displayName = 'DesktopFnb_recommend_dishes'

  @Prop() update!: boolean

  @State klook!: Data.Klook

  get list(): Dish[] {
    return this.businessData.dishes || []
  }

  get isText() {
    return this.list.every((item) => !item.image_url)
  }

  get title() {
    return this.businessData.section_title || ''
  }

  get placeholderImage() {
    return this.businessData.placeholder_image || ''
  }

  get textList() {
    return this.list.map((item) => item.title)
  }

  visiable = false
  curIndex = 0
  eleList: any[] = []

  step = 4

  scrollLeftTo(scroller: HTMLElement, to: number, duration: number) {
    let count = 0
    const from = scroller.scrollLeft
    const frames = duration === 0 ? 1 : Math.round((duration * 1000) / 16)

    function animate() {
      scroller.scrollLeft += (to - from) / frames

      if (++count < frames) {
        window.requestAnimationFrame(animate)
      }
    }
    animate()
  }

  tagMove() {
    const swiper = this.$refs.list as HTMLElement

    const eleList = this.$refs.list as any
    const list = eleList.querySelectorAll('.must-try-listItem')
    const element = list[this.curIndex]
    if (element) {
      const nextItemOffset = element.offsetLeft
      this.scrollLeftTo(swiper, nextItemOffset, 0.2)
    }
  }

  get imageslist() {
    return this.list.map((item) => ({ ...item, image_url: item.image_url || galleryUrl }))
  }

  get showButton() {
    return this.list.length > 4
  }

  get preDisabel() {
    return this.curIndex < 2
  }

  get rightDisabel() {
    return this.curIndex >= this.list.length - 4
  }

  movePrev() {
    const { step } = this
    this.curIndex = this.curIndex - step < 0 ? 0 : this.curIndex - step
    this.tagMove()
  }

  moveNext() {
    const { step } = this
    const length = this.list.length - 1
    this.curIndex = this.curIndex + step > length ? length : this.curIndex + step
    this.tagMove()
  }

  onItemListlick(index: number) {
    this.curIndex = index
    this.visiable = true
  }

  closeModal() {
    this.visiable = false
  }
}
