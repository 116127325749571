

































import { Vue, Component, State, Prop } from 'nuxt-property-decorator'
import KlkExperienceItinerary from '~/components/common/itinerary/index'
import ActivityMarkdown from '~/components/traveller/activity/activity-markdown.vue'
import PackageDetailContentImages
  from '~/components/traveller/activity/activity-package-options/desktop/package-detail/detail-images.vue'

@Component({
  components: {
    ActivityMarkdown,
    PackageDetailContentImages,
    KlkExperienceItinerary
  }
})

export default class DesktopDetailContentOfSection extends Vue {
  @State klook!: Data.Klook
  @Prop() sections!: any []
  @Prop({ default: 'slideDetail' }) type!: 'slideDetail' | 'moduleDetail'
  @Prop() size!: 'large' | 'small'
  @Prop() specStyle!: object

  updated() {
    this.$emit('updated')
  }
}
