import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { currencySymbolMap } from '~/share/data/currency'
import { formatThousands } from '~/share/utils'

@Component
export default class SingleBase extends Vue {
  @State klook!: Data.Klook
  @Prop() includedPackages!: ExperienceActivity.Packages
  @Prop({ default: '' }) specTitle!: string
  @Prop({ default: false }) showSinglePop!: boolean

  get packageAttrToPriceMap() {
    return (this.includedPackages || []).reduce((accu, curr) => {
      if (curr?.spec_attr_id?.length === 1) {
        accu[curr.spec_attr_id[0]] = curr
      }
      return accu
    }, {} as Record<number, any>)
  }

  // 和 BottomBarSettlement 的逻辑保持一致
  getPackagePrice(attr: number, size = 12) {
    const pkg = this.packageAttrToPriceMap[attr]

    if (!pkg) {
      return ''
    }

    const { from_price, to_price, price_show } = pkg

    // 最低价
    if (from_price && from_price !== to_price) {
      const price = `${currencySymbolMap[this.klook.currency]} ${formatThousands(from_price)}`
      return this.$t('activity_detail_from_price', [size, price])
        .replace(/color: #\d+;*/, '')
        .replace(/color:#\d+;*/, '')
    }

    return price_show?.sale_price || ''
  }
}
