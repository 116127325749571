

















import { Vue, Component, State, Prop, Watch } from 'nuxt-property-decorator'
import { KlkMap, KlkMapMarker, MapTypes } from '@klook/map'
import '@klook/map/dist/esm/index.css'

@Component({
  components: {
    KlkMapMarker,
    KlkMap
  }
})

export default class ActivityMapLayer extends Vue {
  @State klook!: Data.Klook
  @State(state => state.klook.ipCountry === 'CN' ? 'mapbox' : 'google') mapType!: 'mapbox' | 'google'
  @Prop() showContentType!: string | 'static-content-type'
  @Prop() hidePoptip!: boolean
  @Prop({ default: '' }) address: string | undefined
  @Prop({ default: '' }) addressDesc: string | undefined
  @Prop() show: boolean | undefined
  @Prop({ default: 16 }) zoom!: number
  @Prop() hideMapModalFun: Function| null | undefined

  map: any = null

  @Watch('show')
  onShowChange() {
    this.showMap()
  }

  configMaps: any = {
    'static-content-type': {
      interactive: 'cooperative',
      fullscreenControl: true
    }
  }

  get mapConfig() {
    // 本地开发调试地图可配置测试token，但打包代码不可对外暴露
    const localhostGoogleConfig = process.env.DEV_GOOGLE_CONFIG || {}
    const showContentTypeConfig = this.configMaps[this.showContentType] || {}
    return {
      language: this.klook.language,
      type: this.mapType,
      height: '100%',
      interactive: 'greedy',
      zoom: 16,
      center: this.cloneAddress,
      ...showContentTypeConfig,
      ...localhostGoogleConfig
    }
  }

  get cloneAddress() {
    return this.address ? this.address.split(',').reverse().join(',') : ''
  }

  mapShow: boolean = false

  showMap() {
    this.$emit('beforeShowMap', true, this)
    this.mapShow = true
    this.initMap()
  }

  hideMap() {
    this.$emit('beforeShowMap', false, this)
    typeof this.hideMapModalFun === 'function' && this.hideMapModalFun()
    this.mapShow = false
  }

  initMap() {
    const map = this.$refs.mapRef as any
    map?.$getMapTool && map.$getMapTool.then(($map: MapTypes.MapTool) => {
      this.map = $map

      // 隐藏默认点marker，其他地图元素也可以用这个方法
      if (typeof this.map?.map?.setOptions === 'function') {
        const noPoi = [
          {
            featureType: 'poi',
            elementType: 'labels.icon',
            stylers: [
              { visibility: 'off' }
            ]
          }
        ]
        this.map.map.setOptions({ styles: noPoi })
      }
    })
  }

  mounted() {
    this.address && this.showMap()
  }
}

