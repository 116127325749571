








import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class PassActivityPrice extends Vue {
  @Prop() info!: Record<string, string>

  get fromPrice() {
    const fromPrice = this.info?.from_price
    return fromPrice ? this.$t('activity_detail_from_price', ['14px', fromPrice]) : ''
  }

  get salePrice() {
    return this.info?.sale_price
  }

  get underlinePrice() {
    return this.info?.underline_price
  }
}
