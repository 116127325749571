





























import { Component, Vue, Prop } from 'nuxt-property-decorator'
import PackageCard from './package-card/index.vue'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { getTaggingExtraTrackObj } from '~/components/experience/tagging/index.vue'

@Component({
  components: {
    PackageCard
  }
})
export default class PackageCardList extends Vue {
  @Prop() packageList!: ExperienceActivity.IPackageCard[]
  @Prop({ type: Number, default: 8 }) loadMoreLimitNumber!: number
  isUnavailableCard = false
  showUnavailableFlag = false
  clonePackageList: ExperienceActivity.IPackageCard[] = []

  getTaggingExtraTrackObj(pkg: any) {
    const list = [...(pkg?.product_tags?.attribute_tags || []), ...(pkg?.product_tags?.discount_tags || [])]
    const track = getTaggingExtraTrackObj(list)
    return track || {}
  }

  get len() {
    return this.packageList?.length || 0
  }

  get isHaveUnavailableCard() {
    return this.packageList.some(v => v.status === 'unavailableDate') && !this.isUnavailableCard
  }

  get isAvailableItemOverLoadMoreNumber() {
    return this.availableDateList.length && this.availableDateList.length > this.loadMoreLimitNumber
  }

  get availableDateList() {
    return this.packageList.filter(v => v.status !== 'unavailableDate')
  }

  getCardVisible(card: ExperienceActivity.IPackageCard) {
    return card.status !== 'unavailableDate' || this.isUnavailableCard
  }

  showUnavailableCard() {
    const list = this.packageList.filter(v => v.status === 'unavailableDate')
    this.isUnavailableCard = true
    this.clonePackageList = this.clonePackageList.concat(list)
  }

  loadMore() {
    const list = this.availableDateList.slice(this.loadMoreLimitNumber)
    this.clonePackageList = this.clonePackageList.concat(list)
    this.showUnavailableFlag = true
  }

  init() {
    if (this.isAvailableItemOverLoadMoreNumber) {
      this.clonePackageList = this.availableDateList.slice(0, this.loadMoreLimitNumber)
    } else {
      this.clonePackageList = this.availableDateList
      this.showUnavailableFlag = !!this.isHaveUnavailableCard
    }
  }

  mounted() {
    this.init()
  }
}
