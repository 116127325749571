
















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import AdaptiveCard from '~/components/traveller/home-page/card-swiper/adaptive-card.vue'
import CardSwiperItem from '~/components/traveller/home-page/card-swiper/card-swiper-item.vue'

@Component({
  components: {
    AdaptiveCard,
    CardSwiperItem
  }
})
export default class AdaptiveCardSwiperItem extends Vue {
  @Prop({ type: Number, default: 3 / 4 }) ratio!: number
  @Prop({ type: Number, default: 1 }) perView!: number
  @Prop() image!: string
  @Prop() href!: string
  @Prop({ type: Number, default: 12 }) gap!: number
  @Prop() imageType!: string
}
