import { Vue, Component } from 'vue-property-decorator'
import debounce from 'lodash/debounce'
import { clientHeight, nodeScrollTop, windowScrollTop } from '~/share/scroll2/scroll2.ts'

@Component
export default class PackageOptionsGuideWrap extends Vue {
  visible = false
  isScrolled = false
  boundary = 0
  threshold = 0

  get guideVisible() {
    // guide 一开始不显示，在滚动后才显示
    return this.visible && this.isScrolled
  }

  checkVisible(isMountedCheck = false) {
    this.isScrolled = this.isScrolled || !isMountedCheck

    if (!this.boundary) {
      const calendarHeader = document.querySelector('.package_option')

      if (!calendarHeader) {
        return false
      }

      const bottomBar = document.getElementsByClassName('activity-bottom-bar')
      const bottomBarHeight = bottomBar.length ? bottomBar[0].getBoundingClientRect().height : 0
      const viewportHeight = clientHeight() - bottomBarHeight

      this.boundary = this.threshold + nodeScrollTop(calendarHeader) - viewportHeight
    }

    return windowScrollTop() - this.boundary < 0
  }

  footerDom: any = null

  checkVisibleScrolling() {
    const { footerDom } = this
    this.visible = this.checkVisible()
    if (!footerDom) {
      this.footerDom = document.querySelector('.js-activity-bottom-bar')
    }
    footerDom && (document.body.style.paddingBottom = footerDom.offsetHeight + 'px')
  }

  handleScroll = debounce(this.checkVisibleScrolling, 10)

  mounted() {
    this.visible = this.checkVisible(true)
    document.addEventListener('scroll', this.handleScroll)
  }

  beforeDestroy() {
    document.removeEventListener('scroll', this.handleScroll)
  }
}
