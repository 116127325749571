


















import { Component, Prop } from 'nuxt-property-decorator'
import BottomBar from './bottom-bar.vue'
import { formatThousands } from '~/share/utils'
import PromotionBase from '~/components/experience-booking/experience-activity/promotion/promotion-base'
import TotalSlot from '~/components/experience/price-slot/total-slot.vue'
/**
 * 促销价（最大折扣价）：
 *  只和套餐相关，促销套餐的折扣最大的unit的所有timeslot的最低价，所有timeslot的折扣是相同的
 *  促销价和出行日期无关，如果套餐促销，那么该套餐所有可选出行日期都享受统一折扣
 * 最低价（日历价格）：
 *  套餐最低价是套餐所有unit，所有timeslot中的最低价格(套餐日历价格)
 *  活动最低价是所有套餐最低价的最低价(活动日历价格)
 */

@Component({
  components: {
    TotalSlot,
    BottomBar
  }
})
export default class CardBottomBar extends PromotionBase {
  @Prop({ default: '.package_options_card_type' }) observeTarget!: string
  @Prop() activity_closer_price!: {
    price_show: any, // 新价格数据字段
    from_price: number,
    to_price: number,
    market_price: number,
    selling_price: string
  }

  // 排队
  @Prop({ default: () => {} }) basicData!: any

  get activity_price() {
    return this.activity_closer_price || {}
  }

  priceText(price: number) {
    return `${this.klook.currencySymbol} ${formatThousands(price)}`
  }

  get from_price() {
    return this.activity_price?.from_price
  }

  get to_price() {
    return this.activity_price?.to_price
  }

  get sell_price() {
    return this.activity_price?.selling_price
  }

  get calcPreTotalPrice() {
    const obj = this.activity_price?.price_show || {}
    return { ...obj, save_price: '' }
  }

  get sellingPrice() {
    if (this.from_price === this.to_price) {
      return this.from_price
    }

    if (this.from_price) {
      const p = `${this.priceText(Number(this.from_price))}`
      return this.$t('activity_detail_from_price', [12, p])
    }

    return this.activity_price?.price_show?.sale_price || ''
  }

  get activityMarketPrice() {
    if (this.isInPromotion && this.promotionDetail) {
      const { selling_price, original_selling_price } = this.promotionDetail
      return Number(selling_price) < Number(original_selling_price) ? original_selling_price : ''
    }

    return Number(this.from_price) < Number(this.activity_price?.market_price) ? this.activity_price?.market_price : ''
  }
}
