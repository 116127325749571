






import { Vue, Component, State, Prop } from 'nuxt-property-decorator'

@Component

export default class MiniDateButton extends Vue {
  @State klook!: Data.Klook
  @Prop() active!: boolean
  @Prop() disable!: boolean

  mounted() {
  }
}
