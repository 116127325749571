









import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class Index extends Vue {
  @Prop({ type: Number }) width!: number
  @Prop({ type: String }) ratio!: string
}
