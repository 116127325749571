
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import GalleryImage from '~/components/experience-booking/experience-activity/banner/mobile/gallery/image-gallery-customer/components/gallery-image.vue'

@Component({
  components: {
    GalleryImage
  }
})
export default class StyleBase extends Vue {
  @Prop() images!: any
  @Prop() spm!: any

  preview(id: number) {
    this.$emit('preview', id)
  }
}
