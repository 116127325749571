









import { Component } from 'nuxt-property-decorator'
import SectionBase from '~/components/setion/experience/base/section-base'
import DividerGap from '~/components/experience-booking/experience-activity/section/component/divider-gap.vue'
import DividerLine from '~/components/setion/experience/components/divider/index.vue'

@Component({
  components: {
    DividerGap,
    DividerLine
  }
})
export default class SectionDivider extends SectionBase {
  get componentName() {
    const name = (this.section as any)?.meta?.component

    if (!name) { return }

    switch (name) {
      case 'line':
        return 'DividerLine'
      case 'gap':
        return 'DividerGap'
      default:
        break
    }
  }

  get attr() {
    return (this.section as any)?.attr
  }

  get platform() {
    return 'section-divider-' + this.klook.platform
  }
}
