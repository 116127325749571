






import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class BaseSticky extends Vue {
  @Prop({ default: '-1px' }) top!: string
  elementObserver: any = null

  mounted() {
    if (process.client) {
      this.createObserver()
    }
  }

  beforeDestroy() {
    this.destroyObserver()
  }

  createObserver() {
    if ('IntersectionObserver' in window) {
      this.elementObserver = new IntersectionObserver(
        entries => entries.forEach((e) => {
          e.target.classList.toggle('is-pinned', e.intersectionRatio < 1)
          this.$emit('change', e.intersectionRatio < 1)
        }),
        { threshold: [1] }
      )

      this.elementObserver.observe(this.$el)
    }
  }

  destroyObserver() {
    this.elementObserver && this.$el && this.elementObserver.unobserve(this.$el)
  }
}
