import { Component, Vue, Getter } from 'nuxt-property-decorator'

@Component
export default class CardAttrBase extends Vue {
  @Getter useCredits!: boolean
  @Getter isGuestCheckout!: boolean

  get credits() {
    return 0
  }

  get creditsVisible() {
    return this.useCredits && !this.isGuestCheckout
  }

  get creditsNumber() {
    if (this.credits && this.creditsVisible) {
      return Math.floor(parseFloat(this.credits.toString()))
    }
    return null
  }

  get creditsText() {
    if (this.creditsNumber) {
      return `${this.$t('header.Credits')} +${this.creditsNumber}`
    }
    return null
  }
}
