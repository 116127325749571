



































import { Component, Vue, Prop, State, Watch } from 'nuxt-property-decorator'

@Component
export default class SeeMoreWithLines extends Vue {
  @Prop({ type: String, default: '' }) observeTarget!: string
  @Prop({ type: Number, default: 1200 }) limitHeight!: number
  @Prop({ type: Boolean, default: false }) expandItinerarySection!: boolean
  @Prop({ default: () => ({}) }) spm!: any
  @State(state => state.klook.platform) platform!: Data.Platform

  showMore = false
  visible = false
  recordSectionIndex = 0

  get isMobile() {
    return this.platform === 'mobile'
  }

  @Watch('visible')
  onVisibleChange(val: boolean) {
    val && this.scrollTo()
  }

  mounted() {
    this.checkHeight()
  }

  checkHeight() {
    if (process.client && this.observeTarget && this.$el?.querySelector && !this.expandItinerarySection) {
      this.$nextTick(() => {
        const dom = this.$el.querySelector(this.observeTarget) as HTMLElement
        const body = this.$el.querySelector('.see-more-with-pop_inner-body') as HTMLElement

        if (dom.scrollHeight > this.limitHeight && this.isMobile) {
          this.showMore = true
          body.style.maxHeight = this.limitHeight + 'px'
          this.getRecordSectionIndex()
        }
      })
    }
  }

  scrollTo() {
    if (process.client) {
      this.$nextTick(() => {
        const doms = this.$el.querySelectorAll('.see-more-with-pop_custom-pop .itinerary-day-block')

        if (doms?.length) {
          const dom = doms[this.recordSectionIndex] || null
          dom && dom.scrollIntoView({
            block: 'start'
          })
        }
      })
    }
  }

  getRecordSectionIndex() {
    this.$nextTick(() => {
      const targets = Array.from(this.$el.querySelectorAll('.itinerary-day-block')) || []

      if (targets?.length) {
        let totalHeight = 0
        for (let i = 0; i < targets.length; i++) {
          const { height = 0 } = targets[i].getBoundingClientRect()
          totalHeight += height
          if (totalHeight > this.limitHeight) {
            this.recordSectionIndex = i
            break
          }
        }
      }
    })
  }
}
