
































import { Component, Vue, Prop } from 'nuxt-property-decorator'
import FilterItem from '~/components/common/hero-filter/filter-item.vue'

@Component({
  components: {
    FilterItem
  }
})
export default class HeroFilterPopContent extends Vue {
  @Prop() list!: FilterItem[]
  @Prop() selected!: string[]
}
