import { Component, mixins, Watch, namespace } from 'nuxt-property-decorator'
import { isEmpty, throttle, cloneDeep } from 'lodash'
import apis from '~/share/data/apis'
import * as types from '~/store/traveller/activity/types'
import { nodeScrollTop, scroll2, windowScrollTop } from '~/share/scroll2/scroll2'
import { ExperienceActivity } from '~/types/experience/activity-section.js'
import { Activity } from '~/types/traveller/activity'
import eventBus from '~/pages/experience/pay/common/event-bus'
import IndexMixin from '~/components/booking-core/mixins/entry/index-base'
import WifiBaseMixin from '~/components/booking-core/mixins/common/wifi-base'
import StructuredPkgDetailMixin from '~/components/booking-core/mixins/common/structured-pkg-detail'
import { setApiState, apiState } from '~/components/booking-core/utils/data-api'
import dataUtils from '~/components/booking-core/utils/data-utils'
import { PACKAGE_DETAIL_OPTION_TYPE } from '~/components/common/package-detail-v2/show-mixin'

const experience = namespace('experience')

@Component({
  components: {
    //
  }
})
export default class Index extends mixins(IndexMixin, WifiBaseMixin, StructuredPkgDetailMixin) {
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf
  bottomFixedBtn: boolean = false

  @Watch('packageId', { immediate: true })
  async packageIdChange(val: number) {
    this.initWifiSimData()
    this.$store.commit(`traveller/activity/${types.SET_PRE_SETTLEMENT_API_DATA}`, null)
    if (val) {
      await this.getPackagePriceScheduleUnits()
      // 这里要请求sku的product-tag
      this.selectPackage(val)
    } else {
      // this.bottomFixedBtn = false
    }
    this.isPackageSchedule = true
  }

  @Watch('bottomFixedBtn')
  bottomFixedBtnChange(val: boolean) {
    val ? this.pageBottomFixedPlaceHolder() : this.removeBottomFixedPlaceHolder()
  }

  @Watch('pkgSelectedDate')
  packageSelectedDateChange(val: any) {
    this.setPackageSelectedDate(val)
  }

  @Watch('isLoggedIn')
  isLoggedInWatch(isb: boolean) {
    if (isb) {
      this.fetchPackageUnits({ isLoginSuccessAfter: true })
    }
  }

  @Watch('calcArrangementId', { immediate: true })
  arrangementIdChange(val: number) {
    if (val) {
      this.fetchPackageUnits()
    }
  }

  get calcIsReloadStatus() {
    const { calcArrangementId, packageUnitsApiDefined } = this
    if (calcArrangementId) {
      return packageUnitsApiDefined?.state !== 'fetching'
    } else {
      return true
    }
  }

  get addToCartOrBookButton() {
    const vip = this.membershipInfo || {}
    const {
      isSupportShoppingCart,
      modificationShoppingCart,
      packageId,
      activityId,
      templateId,
      pkgSelectedTime: selectedTime,
      currentUnits: units,
      calcTotalUnitCount: totalUnitCount,
      calcPkgSelectedObj: currentSelectedPackage,
      calcPkgScheduleList: packageSchedules,
      pkgSelectedDate: packageSelectedDate,
      priceSchedulesObj,
      calcActScheduleList: schedules,
      isPackageSchedule,
      calcActIsNewOpenTicket: isNewOpenTicket,
      activityPageTemplateId
    } = this
    return {
      isSupportShoppingCart,
      wifiSimParams: { ...this.wifiSimParams, datetime_format: this.calcRightPriceDatetime },
      vip,
      packagePriceSchedules: this.calcActScheduleList,
      isUsingPackagePriceSchedule: this.isPackageSchedule,
      modificationShoppingCart,
      packageId,
      activityId,
      templateId,
      selectedTime,
      units,
      totalUnitCount,
      currentSelectedPackage,
      packageSchedules,
      packageSelectedDate,
      priceSchedulesObj,
      schedules,
      isPackageSchedule,
      isNewOpenTicket,
      activityPageTemplateId
    }
  }

  get priceSchedulesObj() {
    const { calcScheduleList } = this
    return calcScheduleList.reduce((acc: any, v: any) => ({ ...acc, [v.date]: v }), {})
  }

  get showOpenDateTips() {
    // 混合套餐：选中od和日期时提示
    if (this.calcActIsNewOpenTicket) { return false }
    if (!this.pkgSelectedDate) { return false }
    const { package_ticket_type } = this.calcPkgSelectedObj || {}
    return package_ticket_type === 3
  }

  get isRealLoading() {
    // 这里loading太复杂了，建议重构，现在只能hack
    const apiDefined = this.calcArrangementId ? this.packageUnitsApiDefined : this.packageSchedulesAndUnitsApiDefined
    return this.getIsLoadingForApiDefined(apiDefined)
  }

  get showWarning() {
    // 如果没有unit并且是预热或者暂停销售则出现此提示
    const { calcPkgIsWarmingOrSuspended, packageId, currentUnits, isRealLoading } = this
    return calcPkgIsWarmingOrSuspended && packageId && currentUnits?.length === 0 && isRealLoading
  }

  get calcTimeExtra() {
    const { calcPkgSelectedObj } = this
    const obj = calcPkgSelectedObj?.time_extra || null
    return obj
  }

  checkRangeDateValidate(dateList?: string[]) {
    const { dateObj, calcTimeExtra } = this
    const arr = dateList || dateObj.list || []
    const isb = arr.length === 2 && arr.every((v: any) => v)
    if (!isb) {
      return this.$t(calcTimeExtra ? '88950' : '13187')
    }
  }

  checkRangeTimeValidate() {
    const { timeObj, calcTimeExtra } = this
    if (calcTimeExtra?.in?.length && !timeObj?.in?.id) {
      return false
    }
    if (calcTimeExtra?.out?.length && !timeObj?.out?.id) {
      return false
    }
    return true
  }

  initWifiSimData() {
    if (this.sectionActData.isWifi) {
      this.pkgSelectedDate = ''
      this.$set(this, 'dateObj', this.getDefDateObj())
      this.$set(this, 'timeObj', this.getDefTimeObj())
    }
  }

  checkDateAndTime() {
    if (this.sectionActData.isWifi) {
      if (!this.checkConfirm()) {
        const ref: any = this.$refs.pickupReturnDateTimeRef
        ref && this.checkPackageComplete('date', { ref, callback: ref.showDatePicker.bind(null, 1, true, null), scrollConfig: { lockLocation: 88 } })
        return false
      }
    }
    if (!this.pkgSelectedDate) {
      this.checkPackageComplete('date')
      return false
    }
    if (!this.calcArrangementId) {
      this.checkPackageComplete('time')
      return false
    }
    return true
  }

  checkConfirm() {
    const { sectionActData } = this
    let msg: any = ''
    if (sectionActData.isWifi) {
      msg = this.checkRangeDateValidate()
    } else {
      const { dateObj, pkgSelectedDate } = this
      if (!pkgSelectedDate || !dateObj.list?.length) {
        msg = this.$t('88950')
      }
    }
    if (msg) {
      this.$toast(msg)
      return false
    }
    if (!this.checkRangeTimeValidate()) {
      this.isRangeTimeValidate = true
      this.$toast(this.$t('88950'))
      return false
    } else {
      this.isRangeTimeValidate = false
    }
    return true
  }

  handlePlus(unit: any) {
    const { isLinePage, calcPreview, calcPkgSelectedObj, calcTotalUnitCount, currentUnits: units, calcUnitInventorieObj } = this
    if (isLinePage) {
      return
    }

    const { max_pax } = calcPkgSelectedObj || {}
    const { priceMaxPax, maxCount, inventoryId } = unit
    const maxPurchased = Math.min(maxCount, Number(calcUnitInventorieObj[inventoryId]))
    // 此场景暂未使用先屏蔽
    // if (this.minStock) {
    //   maxPurchased = Math.min(maxPurchased, this.minStock)
    // }
    if (!this.checkDateAndTime()) {
      return
    }

    if (calcPreview) {
      // preview下处于编辑状态的套餐在unit接口会取不到unit，但日历接口可以拿到 arrangementId
      if (!units) {
        return this.$toast('Cannot add units in unpublished status')
      }
    }

    if (calcTotalUnitCount >= calcPkgSelectedObj.max_pax) {
      this.$toast(this.$t('activity.v2.error.package_max', [max_pax]) as string)
    } else if (unit.count >= unit.priceMaxPax) {
      this.$toast(this.$t('activity.error.price_max_v2', [unit.maxCount]) as string)
    } else if (
      unit.count >= maxPurchased ||
      (unit.count === 0 && unit.count + unit.priceMinPax > maxPurchased)
    ) {
      this.$toast(this.$t('activity.v2.unit_out_of_inventory') as string)
    } else if (unit.count < unit.priceMaxPax) {
      this.changeUnitCount({ type: 'add', priceId: unit.priceId })
    } else {
      this.$toast(this.$t('activity.error.price_max_v2', [priceMaxPax]) as string)
    }
    this.$sendGTMCustomEvent(`Activity Page|Quantity Selected|${unit.isInPromotion}`)
  }

  handleMinus(unit: any) {
    const { isLinePage } = this
    if (isLinePage || unit.count === 0) {
      return
    }

    this.changeUnitCount({ type: 'minus', priceId: unit.priceId })

    const { priceMinPax } = unit
    if (unit.required && unit.count === priceMinPax) {
      this.$toast(this.$t('activity.error.required_buy_min', [priceMinPax]) as string)
    }
    this.$sendGTMCustomEvent(`Activity Page|Quantity Selected|${unit.isInPromotion}`)
  }

  alert(text: string) {
    this.$toast(text)
  }

  setPackageSelectedDate(date: string) {
    if (!date) {
      this.setExpPkgSelectedTime()
      return
    }

    const { calcActInDatePacakges } = this
    const currentSchedules = calcActInDatePacakges.find((v: any) => v.package_id === this.packageId)

    if (!currentSchedules) {
      this.setChosenAttributeIds([])
    }
  }

  setChosenAttributeIds(attrs: any []) {
    const { specList, packageList, packageId } = this

    // 只有单个销售属性默认选中
    const singleAttrs = specList.reduce((acc: any, spec: any) => {
      const { attrs } = spec
      if (attrs && attrs.length === 1) {
        return [...acc, attrs[0].id]
      }

      return acc
    }, [] as number[])

    const currentChosenIds = Array.from(new Set([...attrs, ...singleAttrs]))
    this.setAttributes(currentChosenIds)

    // 选中attr后设置packageId
    let selectedPackageId = null
    if (this.isAttrsSelectedAll()) {
      // 选择了全部销售属性，此时可以获得packageId了
      if (currentChosenIds.length === specList.length) {
        const selectedPackage = packageList.find((v: any) => {
          const { specAttrIds } = v

          return [...specAttrIds].sort().join(',') === [...currentChosenIds].sort().join(',')
        })
        if (selectedPackage && selectedPackage.package_id !== packageId) {
          selectedPackageId = selectedPackage.package_id
        }
      }
    }
    // 其他情况会清空选中的package
    this.selectPackage(selectedPackageId)
  }

  isAttrsSelectedAll() {
    const { specList, chosenAttrIds } = this
    return specList.length === chosenAttrIds.length
  }

  autoSetFeasibleDate(isFirstDate: boolean) {
    const {
      calcPkgScheduleList: packageSchedules,
      calcPkgSelectedObj: currentSelectedPackage,
      pkgSelectedDate: packageSelectedDate
    } = this
    this.pkgSelectedDate = this.getExpAutoSetFeasibleDate({
      isFirstDate,
      packageSchedules,
      currentSelectedPackage,
      packageSelectedDate
    })
  }

  async getShoppingCartList(data: any) {
    const res = await this.$axios.$get(apis.shoppingCartList)
    if (res && res.success && res.result) {
      const group = res.result.group
      this.modificationShoppingCart = true
      const { shopId } = data

      if (shopId && group) {
        let shoppingInfoSnapshot: any = null
        group.find((x: any) => {
          shoppingInfoSnapshot = x.items.find((y: any) => {
            return y.shoppingcart_id === Number(shopId)
          })
          return !!shoppingInfoSnapshot
        })
        if (!shoppingInfoSnapshot) {
          return 0
        }
        const shoppingCartPackageId = shoppingInfoSnapshot.package_id
        const packageDetail = this.packageList.find((item: any) => {
          return item.package_id === shoppingCartPackageId
        })
        if (!packageDetail) {
          return 0
        }
        for (let i = 0; i < shoppingInfoSnapshot.main_package_specs.length; i++) {
          const item = shoppingInfoSnapshot.main_package_specs[i]
          if (!packageDetail.spec_attr_id.includes(item.attr_id)) {
            return 0
          }
        }
        return shoppingCartPackageId
      }
    }
  }

  async fetchPackageUnits(options?: any) {
    const { calcArrangementId: arrangementId } = this
    if (!arrangementId) {
      return
    }
    const res = await this.getPackageUnitsApiData(this.calcPkgUnitsParams)
    const { result } = res || {}
    if (res?.success && !isEmpty(result) && !isEmpty(result.prices) && !isEmpty(result.inventories)) {
      const { unitList, inventories } = result || {}
      if (this.preSelectedSkuId) {
        unitList.forEach((unit: Activity.unit) => this.autoSelectSku(unit, inventories, { passValidate: this.sectionActData.isWifi }))
      }
      const params = {
        maxPurchased: this.calcMaxPurchased,
        inventories: this.calcUnitInventorieObj
      }
      let cacheSelectUnits: any[] = []
      if (options?.isLoginSuccessAfter) {
        cacheSelectUnits = cloneDeep(this.currentUnits)
      }
      this.currentUnits = dataUtils.getExpUnitsExtra(unitList, params)
      if (options?.isLoginSuccessAfter) {
        // 登录之后同步 units 已选数量
        this.currentUnits.forEach((item) => {
          const obj: any = cacheSelectUnits.find((o) => {
            return o.skuId && (o.skuId === item.skuId)
          })
          obj && this.$set(item, 'count', obj.count)
        })
      }
      const { currentUnits: units, calcPkgSelectedObj } = this
      if (calcPkgSelectedObj?.has_discount) {
        const params = {
          page_from: 2, // 调用来源，会影响展示的文案.detail:1; booking_option:2; shopping_card: 3
          arrangement_id: arrangementId,
          sku_list: units.map((o: any) => {
            return {
              sku_id: o.skuId,
              quantity: o.isSoldOut ? 0 : o.count
            }
          })
        }
        await this.getPreSettlementApiData(params)
      }
    }
    return res
  }

  async changeUnitCount({ priceId, type }: { priceId: number, type: 'add' | 'minus' }) {
    const { calcPkgSelectedObj: currentSelectedPackage, currentUnits: unitList } = this
    await this.changeExpUnitCount({ priceId, type, currentSelectedPackage, unitList })
  }

  autoDateClickDom() {
    this.autoClick('#desktop-package-date-picker')

    this.updateDatePickerSpm()
  }

  updateDatePickerSpm() {
    const dom = document?.querySelector('.js-spm-package-date-picker') as HTMLElement
    dom && this.$inhouse.updateBinding(dom, {
      ext: {
        SelectType: 'System'
      }
    })
  }

  autoTimeClickDom() {
    this.autoClick('#desktop-package-time-picker')
  }

  autoClick(id: string) {
    const datePicker = document?.querySelector(id) as HTMLElement
    datePicker && datePicker.click()
  }

  pageBottomFixedPlaceHolder() {
    this.$nextTick(() => {
      const body = document?.querySelector('body') as HTMLElement
      const ref: any = this.$refs.bottomFixedBtnRef
      if (!body || !ref?.offsetHeight) { return }
      const height = ref.offsetHeight
      body.insertAdjacentHTML(
        'beforeend',
        `<div class="js-bottom-fixed-btn" style="width: 100%; height: ${height}px"></div>`
      )
    })
  }

  removeBottomFixedPlaceHolder() {
    const placeholder = document?.querySelector('.js-bottom-fixed-btn') as HTMLElement
    if (placeholder) {
      placeholder.remove()
    }
  }

  attrChange(
    chosenAttrIds: number[],
    packageId: number,
    attr: ExperienceActivity.IAttr,
    spmObj: Record<string, string | number> | null = null
  ) {
    this.chosenAttrIds = chosenAttrIds
    this.updateExpPackageId(packageId)

    // 日期不匹配，清空日期
    if (!attr.isScheduleMatch) {
      this.clearDate()
    }

    this.trackPackageId({ packageId, spmObj })
  }

  clearDate() {
    // this.switchableActivityCalendar && ((this.switchableActivityCalendar as any).value = null)
  }

  trackPackageId(
    { packageId, type, spmObj }: { packageId?: number | null; type?: string; spmObj?: Record<string, string | number> | null } = {
      packageId: this.packageId,
      type: 'User'
    }
  ) {
    // 默认值
    packageId = packageId ?? this.packageId
    type = type || (spmObj?.type as string) || 'User'

    const credits = this.calcPkgSelectedObj?.credits
    const attrWrapRef = this?.$refs?.attrWrap as any
    const packageOptionsNode = attrWrapRef?.$el as any

    if (!packageId || !packageOptionsNode) {
      return
    }

    // 处理自动选中的问题
    const { calcSpecIdList: allSpecIds } = this
    const selectedStepSet = new Set(allSpecIds || [])
    for (const item of (this.userSelectedSpec || [])) {
      selectedStepSet.delete(item)
      selectedStepSet.add(item)
    }

    this.$inhouse.track('custom', 'body', {
      spm: 'Activity.PackageType_LIST_Click',
      objectId: `package_${packageId}`,
      object_id: `package_${packageId}`,
      listLen: spmObj?.len ?? -1,
      listIndex: spmObj?.index ?? -1,
      Integral: credits ? Math.floor(credits) : 'null',
      SelectType: type,
      PromoCard: -1,
      SalesPrice: -1,
      PromotionType: -1,
      action_type: type === 'User' ? 'click' : null,
      SelectedStep: Array.from(selectedStepSet).map((item) => (allSpecIds.indexOf(item) + 1)),
      AttributeID: Array.from(selectedStepSet)
    })
  }

  bookBtnScroll = throttle(this.watchBookBtnScroll, 150)

  watchBookBtnScroll() {
    const d = (this.$refs.packageOptionsBtnGroup as any)
    if (d && d.$el && d.$el.textContent) {
      const a = nodeScrollTop(d.$el)
      const w = windowScrollTop()
      this.bottomFixedBtn = w > a - 70
    }
  }

  selectPackage(packageId: number | null) {
    if (packageId) {
      this.updateExpPackageId(packageId)
      // 设置销售属性
      const activityPackage = this.packageList.find((v: any) => v.package_id === packageId)
      if (activityPackage) {
        this.setAttributes(activityPackage.spec_attr_id)
        // const showStructuredPackageDetail = this.getCurrPkgDetailVertical?.() === 'attractions'
        const showStructuredPackageDetail = +this.getCurrPkgDetailOption?.() === +PACKAGE_DETAIL_OPTION_TYPE.STRUCTURED_VERSION
        // 获取packageDetail
        if (showStructuredPackageDetail) {
          this.getStructuredPackageDetailApiData(this.calcPkgDetailParams)
        } else {
          this.getPackageDetailApiData(this.calcPkgDetailParams)
        }
      }
    } else {
      this.updateExpPackageId()
    }
  }

  setPackageTime(data: any) {
    this.setExpPkgSelectedTime(data)
  }

  setAttributes(attrIds: any []) {
    this.chosenAttrIds = [...(attrIds || [])]
  }

  // 默认选中逻辑：
  // 第一次打开会默认选中preSelectedSkuId
  // 第一次打开不带日期：默认选中 => （切换日期）继续默认选中 => （切换日期）不默认选中
  // 第一次打开带日期：默认选中 => （切换日期）不默认选中
  // 注意：这里需要和unit里面的handlePlus保持一致
  autoSelectSku(unit: Activity.unit, inventories: any, options?: any) {
    const { passValidate } = options || {}
    const $t = this.$t.bind(this)
    const { calcPreview: preview, calcPkgUnitsObj: pkgUnitsObj, calcPkgSelectedObj: currentSelectedPackage, calcTotalUnitCount: totalUnitCount, pkgSelectedTime: selectedTime, preSelectedSkuId } = this
    const errorTips = this.setExpAutoSelectSkuHandler({ unit, inventories, preview, pkgUnitsObj, currentSelectedPackage, totalUnitCount, selectedTime, preSelectedSkuId, $t, passValidate })
    errorTips && this.alert(errorTips)
  }

  checkPackageComplete(type: 'date' | 'time', options?: any) {
    const { ref, callback, scrollConfig } = options || {}
    const date = this.$refs.desktopPackageDate as any
    const time = this.$refs.desktopPackageTime as any
    const d = ref || (type === 'date' ? date : time)
    const fun = callback || (type === 'date' ? this.autoDateClickDom : this.autoTimeClickDom)
    if (!d) {
      return null
    }
    try {
      const h = nodeScrollTop(d.$el)
      scroll2({
        scrollY: h,
        lockLocation: 130,
        callback: fun,
        ...(scrollConfig || {})
      })
    } catch (e) {
    }
  }

  async getPackagePriceScheduleUnits(paramsData: any = {}) {
    const res = await this.getPackageSchedulesAndUnitsApiData({ ...this.calcPkgSchedulesAndUnitsParams, ...(paramsData || {}) })
    if (!res?.result) {
      return null
    }
    const { unitList, inventories, selling_price } = res.result || {}
    if (unitList?.length === 1) {
      this.preSelectedSkuId = unitList[0]?.skuId // 只有一个unit时自动选中
    }
    // openticket 会自动选日期
    if (this.calcActIsNewOpenTicket) {
      this.autoSetFeasibleDate(!!this.calcPkgSelectedObj?.auto_select_first_date)
    }

    if (!this.calcArrangementId && unitList?.length) {
      if (this.preSelectedSkuId) {
        unitList.forEach((unit: Activity.unit) => this.autoSelectSku(unit, inventories, { passValidate: this.sectionActData.isWifi }))
      }
    }
    this.currentUnits = unitList || []

    // 将日历接口获取到的最优价通知到外层模版来覆盖 pacakgeList 中的 sale_price 或 from_price
    if (selling_price) {
      this.$emit('updatePackageList', { sellingPrice: selling_price })
    }

    // fix: 由于没有延迟，导致 selectedTime 的变更比 getPackagePriceScheduleUnits 后执行，所以加一个延迟
    // 更好的修复方法是把 watch packageid 改为 watch selectedTime，但是改动太大
    // 服务端没有响应式，所以不需要这个延迟
    if (process.client) {
      await this.$nextTick()
    }
  }

  resetPackageTime() {
    this.setPackageTime({})
  }

  scrollWatch() {
    document.addEventListener('scroll', this.bookBtnScroll)
  }

  created() {
    const { packageId } = this
    const items = this.packageDetailList || []
    if (packageId && items && items[0]) {
      // 当前套餐详情：爬虫支持服务端渲染
      this.packageDetailApiDefined.apiData = { success: true, result: items }
      setApiState(this.packageDetailApiDefined, apiState.success, false)
    }
  }

  async mounted() {
    setTimeout(() => {
      eventBus.$emit('setIsDelayFlag2bus', { from: 'spec_type_mounted' })
    }, 60)
    await this.getActivitySchedulesApiData(this.calcActSchedulesParams)
    this.initQueryData()
    const { shop_id: shopId } = this.$route.query
    if (shopId) {
      const id: number = await this.getShoppingCartList({ shopId })
      if (id) {
        this.updateExpPackageId(id)
      }
    }
    this.scrollWatch()
  }

  beforeDestory() {
    document.removeEventListener('scroll', this.bookBtnScroll)
  }
}
