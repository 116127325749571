















import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator'
import getCountDownTime, { isLessThanTargetTime, getUtcTime } from '~/share/count-down'

@Component
export default class CutDown extends Vue {
  @Prop({ type: Array, default: () => [] }) timeList!: string[]
  @Prop({ type: String, default: 'normal' }) size!: 'small' | 'normal'
  @Prop() disabled!: boolean

  endTime: any = ''
  timeStr: string = ''
  interval: any = null
  show: boolean = false

  @Watch('timeList', { immediate: true })
  onTimeListChange(val: any) {
    if (val?.length) {
      if (val.length === 1) {
        const diffTime = isLessThanTargetTime(val[0])
        diffTime && (this.endTime = val[0])
      } else {
        const list = this.bubbleSort(val)
        this.endTime = list.find((item: string) => isLessThanTargetTime(item))
      }
    } else {
      this.clear()
    }

    this.getTime()
    this.countDown()
  }

  @Watch('show')
  onTimeStrChange(val: string) {
    if (!val) {
      this.$emit('reload')
    }
  }

  getTime() {
    if (this.endTime) {
      if (!this.show) {
        this.show = true
      }
      const { days, hours, minutes, seconds } = getCountDownTime(this.endTime)
      if (days === '0' && hours === '00' && minutes === '00' && seconds === '00') {
        this.timeStr = ''
        this.show = false
      } else if (days === '0') {
        this.timeStr = this.$t('80660', {
          hh_mm_ss: ` ${hours}:${minutes}:${seconds}`
        })
      } else {
        this.timeStr = this.$t('80659', {
          num: ` ${days}`,
          hh_mm_ss: `${hours}:${minutes}:${seconds}`
        })
      }
    } else {
      this.clear()
    }
  }

  bubbleSort(arr: string[]) {
    const len = arr.length
    let flag = false

    for (let i = 0; i < len - 1; i++) {
      for (let j = 0; j < len - 1 - i; j++) {
        if (getUtcTime(arr[j + 1]) < getUtcTime(arr[j])) {
          const middle = arr[j + 1]
          arr[j + 1] = arr[j]
          arr[j] = middle
          flag = true
        }
      }

      if (!flag) {
        break
      }
    }

    return arr
  }

  clear() {
    this.cleanup()
    this.timeStr = ''
    this.interval = null
    this.endTime = ''
  }

  countDown() {
    if (process.client) {
      this.interval = setInterval(this.getTime, 1000)
    }
  }

  cleanup() {
    this.interval && clearInterval && clearInterval(this.interval)
  }

  beforeDestroy() {
    this.cleanup()
  }
}
