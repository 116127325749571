



























import { Component, Vue, Prop, State } from 'nuxt-property-decorator'
import Table from './components/table/index.vue'
import { IconItemType } from './type'
import HeightLimitBlock from '~/components/common/package-detail-v2/components/height-limit-block/index.vue'

@Component({
  components: {
    HeightLimitBlock,
    Table
  }
})
export default class PackageDetailIconItems extends Vue {
  @State klook!: Data.Klook

  @Prop({ type: Array, default: [] }) iconItems!: IconItemType[]

  get platform() {
    return this.klook.platform
  }

  getNeedHeightLimit(item: IconItemType) {
    const { props = {} } = item
    return !!props?.height_limit
  }
}
