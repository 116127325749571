export const apiState = {
  init: '',
  success: 'success',
  failure: 'failure',
  fetching: 'fetching'
}

export const getIsLoadingForApiState = function (state: string) {
  // 获取请求接口的状态
  return [apiState.success, apiState.failure].includes(state)
}
export const setApiState = (apiDefined: any, state: string, isReload: boolean) => {
  apiDefined.state = state
  if (apiDefined?.reloadConfig) {
    const config = apiDefined.reloadConfig
    const isReady = [apiState.success, apiState.failure].includes(state)
    if (isReload) {
      setTimeout(() => {
        config.state = state
        config.isReady = isReady
      }, config.delay || 0)
    } else {
      config.state = state
      config.isReady = isReady
    }
  }
}
export async function getApiDataHandler(vm: any, vmData?: any) {
  // get通用方法，处理状态和缓存
  if (typeof vm?.$axios?.$get !== 'function') {
    return
  }
  let apiData: any = null
  const apiDefined = vmData || {}
  const { cancelTokenConfig } = apiDefined
  if (cancelTokenConfig?.cancelToken) {
    cancelTokenConfig.cancelToken.cancel('cancel')
  }

  cancelTokenConfig && (cancelTokenConfig.cancelToken = vm.$axios.CancelToken.source())
  setApiState(apiDefined, apiState.fetching, false)
  if (!apiDefined?.apiDataCacheObj) {
    vm.$set(apiDefined, 'apiDataCacheObj', {}) // inited
  }
  const cacheObj = apiDefined.apiDataCacheObj
  const cacheKey = typeof apiDefined.getCacheKey === 'function' ? apiDefined.getCacheKey(apiDefined) : JSON.stringify(apiDefined.params || {})
  const cacheApiData = cacheObj[cacheKey]
  const hasCacheData = cacheApiData?.success
  try {
    apiData = hasCacheData ? cacheApiData : await vm.$axios.$get(apiDefined.url, {
      params: apiDefined.params,
      cancelToken: cancelTokenConfig?.cancelToken?.token,
      regularUrl: cancelTokenConfig?.regularUrl
    })
    setApiState(apiDefined, getApiState(apiData), true)
    vm.$nextTick(() => {
      setTimeout(() => {
        vm.$set(apiDefined, 'refreshState', getApiState(apiData))
      })
    })
  } catch (error) {
    setApiState(apiDefined, apiState.failure, true)
  }
  apiDefined.apiData = apiData
  if (process.client && !hasCacheData && apiDefined?.isCache) {
    vm.$set(cacheObj, cacheKey, apiData)
  }
  return apiData
}
export function getApiState(apiData: any) {
  // 获取请求的成功失败状态
  return apiData?.success ? apiState.success : apiState.failure
}
