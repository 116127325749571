




























































































import { Vue, Component, Prop, Watch, State } from 'nuxt-property-decorator'
import cloneDeep from 'lodash/cloneDeep'
import ItineraryFeedBack from '~/components/common/itinerary/feed-back/index.vue'
import KlkExperienceItinerary from '~/components/common/itinerary/index'
import ActivityMarkdown from '~/components/traveller/activity/activity-markdown.vue'
import PackageDetailContentImages
  from '~/components/traveller/activity/activity-package-options/desktop/package-detail/detail-images.vue'
import SeeMoreWithPop from '~/components/common/itinerary/see-more-with-pop.vue'
import { updateDataSpm } from '~/share/utils'
import ImageCardSwiperViewer from '~/components/common/image-card-swiper-viewer/index.vue'
import Table from '~/components/common/package-detail-v2/components/table/index.vue'

import { getImageCardSwiperOptions } from '~/components/common/package-detail-collapse/utils/index'

@Component({
  components: {
    ImageCardSwiperViewer,
    ActivityMarkdown,
    PackageDetailContentImages,
    KlkExperienceItinerary,
    SeeMoreWithPop,
    ItineraryFeedBack,
    Table
  }
})

export default class PackageDetailCollapse extends Vue {
  @State klook!: Data.Klook
  @State(state => state.klook.platform) platform!: Data.Platform
  @Prop() sections!: any []
  @Prop({ default: 'slideDetail' }) type!: 'slideDetail' | 'moduleDetail'
  @Prop() size!: 'large' | 'small'
  @Prop({ default: true }) expandAll!: boolean // 默认不使用后端的 is_fold
  @Prop({ default: () => ({}) }) packateDetail!: any
  @Prop({ default: false }) expandItinerarySection!: boolean
  @Prop({ default: false }) simplifiedPickUp!: boolean
  // 默认第一个打开
  @Prop() defaltValue!: number[]
  @Prop({ type: Boolean, default: false }) useImageSwiper!: boolean
  @Prop({ type: Number, default: 0 }) imageCardSwiperType!: number
  @Prop({ type: String, default: '' }) imageGroupStyle!: string
  @Prop({ type: Object, default: null }) pickupInfos!: any

  active: number[] = [];
  cloneActive: number[] = []

  @Watch('defaltValue')
  setValue() {
    if (this.defaltValue) {
      this.active = this.defaltValue
    }
  }

  fieldKey = {
    img_url: 'image_url',
    img_resize_url: 'image_url',
    title: 'image_desc'
  }

  get imageCardSwiperOptions() {
    return getImageCardSwiperOptions(this.imageCardSwiperType)
  }

  imageGalleryObj: any = {
    title: '',
    dontGetImages: true,
    showGallery: false,
    currentIndex: 0,
    bannerList: [],
    ons: {
      close: () => {
        const { imageGalleryObj } = this
        imageGalleryObj.itineraryImages = []
        imageGalleryObj.currentIndex = 0
        imageGalleryObj.showGallery = false
      }
    }
  }

  get calcAid() {
    return this.packateDetail?.activityId || 0
  }

  get feedBackProp() {
    return {
      packageId: this.packateDetail.packageId,
      activityId: this.calcAid,
      size: this.size
    }
  }

  initPickUp(data: any = {}) {
    const {
      pick_up_meet_up:
      {
        departure_return = [],
        pois = [],
        package_id = 0,
        departure_more = ''
      }
    } = data

    return {
      package_id,
      days: departure_return,
      departure_more,
      pois: {
        poi_data: pois
      }
    }
  }

  getOfficialImages(list: any[]) {
    const arr = list || []
    return arr.map((item, index) => {
      const { src } = item
      return {
        image_id: index,
        img_resize_url: src,
        img_url: src
      }
    })
  }

  showImageFnHandler(imgsList: any[], title: string, index?: number) {
    const { imageGalleryObj } = this
    imageGalleryObj.bannerList = this.getOfficialImages(imgsList)
    imageGalleryObj.showGallery = true
    imageGalleryObj.title = title
    imageGalleryObj.currentIndex = index || 0
  }

  get spm() {
    return `SeeItineraryDetails?oid=package_${this.packateDetail.packageId}`
  }

  @Watch('sections', { immediate: true })
  sectionsChange(list: any[]) {
    if (list?.length) {
      list.forEach((section, index) => {
        if (this.expandAll) {
          this.active.push(index)
        } else if (!section?.is_fold) {
          this.active.push(index)
        }
      })

      this.cloneActive = cloneDeep(this.active)
      this.handleChange(cloneDeep(this.active))
    }
  }

  handleChange(name: any) {
    this.$emit('change', name)
  }

  trackInfo(item: any, customExt: any = {}) {
    const ext: any = { SectionType: item.section_name_en, ...customExt } || {}

    return `SectionCollapse?ext=${encodeURIComponent(JSON.stringify(ext))}`
  }

  handleItemClickEvent($event: any, item: any, index: number) {
    const isFold = this.active?.length < this.cloneActive?.length
    const isCollapseItemrHeader = ($event.target as HTMLElement).closest('.klk-collapse-item-header')

    this.cloneActive = cloneDeep(this.active)
    if (isCollapseItemrHeader) {
      const target = (this.$refs.collapseItem as any)[index]
      const ext = { CollapseType: isFold ? 'Fold' : 'Unfold' }

      updateDataSpm('data-spm-module', target.$el as Element, this.trackInfo(item, ext))

      setTimeout(() => {
        this.$inhouse.track('action', target.$el)
      }, 10)
    }
  }

  grounpTitleClick(data: any) {
    const groupKeys = ['itinerary_return', 'itinerary_departure']
    const { group_key, group_id } = data
    if (groupKeys.includes(group_key)) {
      const index = this.sections.findIndex((item: any) => item.section_type === 'pick_up_meet_up')
      this.active.push(index)
      this.$emit('grounpTitleClick', `#group_${group_id}`)
    }
  }

  getTabelData(component: any) {
    return component?.data?.display_table
  }
}
