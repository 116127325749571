















import { Component, Prop, Vue, State } from 'nuxt-property-decorator'
import { currencySymbolMap } from '~/share/data/currency'
import { formatThousands } from '~/share/utils'

@Component
export default class ActivityPrice extends Vue {
  @State klook!: Data.Klook
  @Prop() sellingPrice!: string
  @Prop() fromPrice!: number
  @Prop() toPrice!: number
  @Prop() marketPrice!: string
  @Prop() specPriceDesc!: string

  get sellingPriceText() {
    // 这里是活动价格
    if (this.fromPrice) {
      return this.$t('activity_detail_from_price', [14, this.priceText(this.fromPrice)])
    } else if (this.sellingPrice) {
      return `<b>${this.priceText(Number(this.sellingPrice))}</b>`
    }
  }

  priceText(price: number) {
    return `${currencySymbolMap[this.klook.currency]} ${formatThousands(price)}`
  }
}
