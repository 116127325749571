





























import { Vue, Component, State, namespace, Prop, Watch } from 'nuxt-property-decorator'
import { ExperienceActivity } from '~/types/experience/activity-section'
import { checkEventIncludeId } from '~/share/utils.ts'

const experience = namespace('experience')

@Component({
  components: {
    //
  }
})
export default class Index extends Vue {
  @experience.State sectionActData!: ExperienceActivity.sectionActDataIf
  @State klook!: Data.Klook
  @Prop() borderType?: string | 'require-border-type'
  @Prop() title!: string
  @Prop() subTitle?: string
  @Prop() pickupDatetime!: string
  @Prop() returnDatetime!: string
  @Prop() formatDays!: string
  @Prop() hideReturn?: boolean

  @Watch('showDatePickerSlot', { immediate: true })
  visibleWatch(v: boolean) {
    this.showDatePickerSlot ? document.addEventListener('click', this.clickEventListener) : document.removeEventListener('click', this.clickEventListener)
    this.$emit('showDatePicker', v)
  }

  showDatePickerSlot = false

  setDatePicker(isb = false, cb: Function) {
    this.showDatePicker(1, isb, cb)
  }

  showDatePicker(time?: number, isb = true, cb?: Function) {
    setTimeout(() => {
      this.clickDatePickerHandler(isb) // 防止被clickEventListener监听事件关闭
      typeof cb === 'function' && cb(isb)
    }, time || 0)
  }

  clickDatePickerHandler(isb = true) {
    this.showDatePickerSlot = isb
  }

  clickEventListener(e: any) {
    if (e && this.showDatePickerSlot) {
      this.showDatePickerSlot = checkEventIncludeId(e.target, 'js-show-date-picker-slot')
    }
  }
}
