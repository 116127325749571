



























import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class AttractionPreviewLine extends Vue {
  @Prop() packageItem!: any

  isOverflow = false

  mounted() {
    this.isOverflow = this.checkOverflow()
  }

  // 这里逻辑是针对sku_price和sku_name进行相加，得出内容宽度，然后看是否换行
  checkOverflow() {
    const list = document.getElementById('$product-info')
    if (list && list.clientWidth > 0) {
      const clientWidth = list.clientWidth
      const text = (this.packageItem.sku_name || '') + (this.packageItem.sku_price || '')
      const tempSpan = document.createElement('span')
      tempSpan.style.visibility = 'hidden'
      tempSpan.style.position = 'absolute'
      tempSpan.style.whiteSpace = 'nowrap'
      tempSpan.style.fontWeight = '600'
      tempSpan.style.lineHeight = '1.5'
      tempSpan.style.fontSize = '16px'
      tempSpan.textContent = text
      document.body.appendChild(tempSpan)
      const textWidth = tempSpan.offsetWidth
      document.body.removeChild(tempSpan)
      // 减去padding，中间虚线的宽度+margin,再减去30
      if (textWidth > ((clientWidth - 15 - 24 - 32 - 30))) {
        return true
      }
    }

    return false
  }
}
