import { render, staticRenderFns } from "./package-detail-success.vue?vue&type=template&id=f241c3c4&"
import script from "./package-detail-success.vue?vue&type=script&lang=ts&"
export * from "./package-detail-success.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports