var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.timeStr || _vm.$scopedSlots.default)?_c('div',{style:(_vm.styleObj)},[_vm._t("default",null,{"text":_vm.timeStr}),_vm._v(" "),(!_vm.$scopedSlots.default)?_c('div',{staticClass:"warming-slogan",class:{
      'show-arrow': _vm.showArrow,
      'warming-slogan-small': _vm.size === 'small',
      'warming-slogan-large': _vm.size === 'large'
    }},[(_vm.size === 'small')?_c('IconTime',{staticClass:"warming-slogan-icon-small",attrs:{"theme":"outline","size":"12"}}):(_vm.size === 'large')?_c('IconTime',{staticClass:"warming-slogan-icon-large",attrs:{"theme":"outline","size":"16"}}):_c('SvgIcon',{staticClass:"warming-slogan-icon",attrs:{"name":"common#icon_warm_time_xxs","width":"20","height":"20"}}),_vm._v(" "),_c('span',{class:[
        'warming-slogan-text',
        { 'warming-slogan-text-small': _vm.size === 'small' },
        { 'warming-slogan-text-large': _vm.size === 'large' }
      ]},[_vm._v("\n      "+_vm._s(_vm.timeStr)+"\n    ")])],1):_vm._e()],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }