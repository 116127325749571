



















import { Component, Vue, Prop } from 'nuxt-property-decorator'
import AttractionPreviewPrice from './attraction-preview-price.vue'
import CardBanner from '~/components/experience-booking/experience-activity/package-options/attraction-preview/components/card-banner.vue'
import AttractionPreviewTag from '~/components/experience-booking/experience-activity/package-options/attraction-preview/components/attraction-preview-tag.vue'

@Component({
  components: {
    CardBanner,
    AttractionPreviewTag,
    AttractionPreviewPrice
  }
})
export default class AttractioPreviewCard extends Vue {
  @Prop({ default: {} }) data!: any;
  @Prop() handleToDetail!: (id: number) => void;
  @Prop() handleShowProductNote!: (content: string) => void;

  get activitBanner() {
    return this.data?.activity_banner || ''
  }

  get activityId() {
    return this.data?.activity_id || 0
  }

  get slogan() {
    return this.data?.inclusive
  }

  get tags() {
    return this.data?.product_tags?.attribute_tags || []
  }

  get priceList() {
    return this.data?.package_list || []
  }

  get activityName() {
    return this.data?.activity_name || ''
  }

  get passTypeName() {
    return this.data?.pass_type_name || ''
  }

  get title() {
    return this.data?.product_title || ''
  }

  get note() {
    return this.data?.product_title_note || ''
  }
}
