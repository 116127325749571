












































import { Component } from 'nuxt-property-decorator'
import { IconDownCircle, IconUpCircle, IconNext } from '@klook/klook-icons'
import DynamicIcons from '~/components/experience-booking/experience-activity/dynamic-component/icons/index.vue'
import { BusinessBase } from '~/components/setion/experience/base/business-base'
import { setNewImageSize } from '~/share/data/image'

export interface Iicons {
  icon: string
  desc: string
  title: string
}

@Component({
  components: {
    DynamicIcons,
    IconNext,
    IconDownCircle,
    IconUpCircle
  }
})
export default class MobilefnbActivityIcon extends BusinessBase {
  static displayName = 'Fnb_activity_icons'

  visible = false

  overflowStates: boolean[] = []

  toggleStates: boolean[] = []

  // 本来想使用activity-icon，但是样式不一样，不能进行复用，造成了数据格式略复杂。
  get list(): Iicons[] {
    return this.businessData.infos || []
  }

  get platform() {
    return this.klook.platform
  }

  open(index: number) {
    if (this.isDesktop) {
      this.setToggle(index)
    } else {
      this.visible = true
    }
  }

  close() {
    this.visible = false
  }

  formatPicUrl(url: string) {
    const { webp, platform } = this.klook

    if (platform === 'desktop') {
      return setNewImageSize(url, 'image/upload/', 20, 20, webp)
    }

    return setNewImageSize(url, 'image/upload/', 16, 16, webp)
  }

  setToggle(index: number) {
    this.$set(this.toggleStates, index, !this.toggleStates[index])
  }

  checkOverflow() {
    this.toggleStates = this.list.map(() => false)
    this.overflowStates = this.list.map((_, index) => {
      const list = this.$refs.contentBox as HTMLElement[]
      const element = list[index] as HTMLElement
      return element ? element.scrollHeight > element.clientHeight : false
    })
  }

  mounted(): any {
    this.$nextTick(() => {
      this.checkOverflow()
    })
  }
}
